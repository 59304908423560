import React, { useState } from 'react';
import { Snackbar as MuiSnackbar, SnackbarProps as MuiSnackbarProps } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';

// todo:
// - add Storybook stories

const getDefaultMessage = (severity: AlertProps['severity']) => {
  switch (severity) {
    case 'success':
      return 'Successfully updated';
    case 'error':
      return 'An error occurred';
    case 'info':
    case 'warning':
    default:
      throw new Error(`no message was passed to a Snackbar with severity of ${severity}`);
  }
};

interface UseSnackbar {
  snackbarOpen: boolean;
  handleSnackbarOpen(): void;
  handleSnackbarClose(): void;
}

export const useSnackbar = (open = false): UseSnackbar => {
  const [snackbarOpen, setSnackbarOpen] = useState(open);

  return {
    snackbarOpen,
    handleSnackbarOpen: () => setSnackbarOpen(true),
    handleSnackbarClose: () => setSnackbarOpen(false),
  };
};

interface SnackbarProps extends MuiSnackbarProps {
  open: boolean;
  handleClose(): void;
  message?: string;
  severity?: AlertProps['severity'];
  autoHideDuration?: number | null;
}

export const Snackbar: React.FC<SnackbarProps> = ({
  open,
  handleClose,
  severity = 'success',
  message = getDefaultMessage(severity),
  autoHideDuration = 6000,
  children,
  ...muiProps
}) => {
  const hasChildren = React.Children.toArray(children).length > 0;
  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      transitionDuration={{ enter: 225, exit: 0 }} //we sometimes modify the snackbar message state in handleClose, which is invoked before the transition
      {...muiProps}
    >
      {hasChildren ? (
        <>{children}</>
      ) : (
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      )}
    </MuiSnackbar>
  );
};
