import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  TextField,
  Button,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import styled from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ErrorBoundary from '../../../components/ErrorBoundary';
import Loader from '../../../components/loader';
import Timer from '../../../components/timer';
import { Status } from '../../../services/cycleTimings/types';
import { CycleTimingItems } from './OldCycleTimingsItems';
import { useCycleTimings } from './oldUseCycleTimings';

/**
 * Cycles-timings page flow:
 * - https://theproscloset.atlassian.net/wiki/spaces/TECH/pages/1916043279/Drivetrain+-+Cycle+Timing
 */

const Root = styled.div`
  margin: 2rem;
`;

const TimerContainer = styled.h1`
  text-align: center;
`;
const WarningContainer = styled.h5`
  margin-bottom: 0;
`;

const SubmitForm = styled.form`
  text-align: center;
`;

const TextCenterContainer = styled.div`
  text-align: center;
`;

const CurrentStation = styled.div`
  border: 3px solid black;
  height: 150px;
  width: 250px;
  cursor: pointer;
  text-align: center;

  p {
    margin-top: 15px;
    font-size: 10px;
    font-weight: bold;
  }

  strong {
    font-size: 98px;
    font-weight: bold;
    color: #787878;
  }
`;

const blankStation = { id: '', description: '' };

const CycleTimings: FC = () => {
  const {
    station,
    setStation,
    stations,
    loading,
    selectNewStation,
    onFormSubmitSearch,
    timingTabs,
    tabValue,
    onTabChange,
    cycleTiming,
    clearCurrentTiming,
    updateTimingStatus,
    removeTimingItem,
    newTimingForExistingDialogOpen,
    newTimingForExistingDialogOnCancel,
    newTimingForExistingDialogOnSubmit,
    addItemToTimingForExistingDialogOpen,
    addItemToTimingForExistingDialogOnCancel,
    addItemToTimingForExistingDialogOnSubmit,
    updateTimingTraining,
  } = useCycleTimings();

  // Select a station first
  if (!station) {
    return (
      <Root>
        <Grid container spacing={5}>
          <Grid item container xs={12} lg={10} alignItems="center" justifyContent="center">
            <Grid item xs={3}>
              <h3>Cycle Timings</h3>
              <FormControl>
                <InputLabel id="select-cycle-timing-station">Station</InputLabel>
                <Autocomplete
                  autoSelect
                  options={[blankStation, ...(stations ?? [])]}
                  getOptionLabel={station => `${station.id} - ${station.description}`}
                  onChange={(_, option) => {
                    setStation(option?.id ?? '');
                  }}
                  value={null}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        placeholder="Select a Station"
                        variant="outlined"
                        autoFocus={true}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Loader loading={loading} />
      </Root>
    );
  }

  let submitButtonText = 'Submit';
  if (cycleTiming && cycleTiming.status !== Status.COMPLETED) {
    submitButtonText = 'Add to Timing';
  } else if (cycleTiming && cycleTiming.status === Status.COMPLETED) {
    submitButtonText = 'Start New Timing';
  }

  return (
    <Root>
      <ErrorBoundary>
        <Grid container spacing={5}>
          <Grid item container xs={12} lg={10} alignItems="center" justifyContent="center">
            <Grid item xs={2}>
              <CurrentStation onClick={selectNewStation}>
                <p>STATION</p>
                <strong>{station}</strong>
              </CurrentStation>
            </Grid>
            <Grid
              item
              container
              xs={10}
              alignItems="center"
              justifyContent="center"
              component={SubmitForm}
              onSubmit={onFormSubmitSearch}
            >
              <Grid item xs={12} style={{ marginBottom: '15px' }}>
                <Tabs value={tabValue} indicatorColor="primary" onChange={onTabChange} centered>
                  {timingTabs.map(t => (
                    <Tab key={t.tabLabel} label={t.tabLabel} />
                  ))}
                </Tabs>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id={timingTabs[tabValue].id}
                  value={timingTabs[tabValue].value}
                  variant="outlined"
                  placeholder={timingTabs[tabValue].placeholder}
                  onChange={timingTabs[tabValue].onChange}
                  style={{ backgroundColor: '#fff' }}
                  autoFocus
                />
              </Grid>
              <Grid item container xs={4} justifyContent="space-around">
                <Button variant="contained" size="large" type="submit">
                  {submitButtonText}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={!cycleTiming}
                  onClick={clearCurrentTiming}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {!!cycleTiming && (
            <>
              <Grid item container xs={12} lg={10} alignItems="center" justifyContent="center">
                <Grid item xs={2} />
                <Grid item xs={10}>
                  <TimerContainer>
                    <Timer
                      key={cycleTiming.id}
                      startDate={cycleTiming.startedAtUtc}
                      seconds={cycleTiming.durationSeconds || 0}
                      timerOn={cycleTiming.status === Status.STARTED}
                    />
                  </TimerContainer>
                </Grid>
              </Grid>
              <Grid item container xs={12} lg={10}>
                <Grid item xs={2} />
                <Grid item xs={10}>
                  <Grid item container xs={12} justifyContent="space-between">
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() =>
                        updateTimingStatus(
                          cycleTiming.status === Status.STARTED ? Status.PAUSED : Status.STARTED,
                        )
                      }
                      disabled={cycleTiming.status === Status.COMPLETED}
                    >
                      {cycleTiming.status === Status.STARTED ? 'Pause' : 'Continue'}
                    </Button>
                    <Grid item component={TextCenterContainer}>
                      <div>
                        <strong>Current Status: {cycleTiming.status}</strong>
                      </div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={e => updateTimingTraining(e.target.checked)}
                            disabled={!cycleTiming || cycleTiming?.status === Status.COMPLETED}
                            checked={!!cycleTiming?.isTraining}
                            color="primary"
                          />
                        }
                        label="Training"
                      />
                    </Grid>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => updateTimingStatus(Status.COMPLETED)}
                      disabled={cycleTiming.status !== Status.STARTED}
                    >
                      Complete
                    </Button>
                  </Grid>
                  <Grid item container xs={12} justifyContent="space-between">
                    <WarningContainer>{`*NOTE* We'll attempt to pause the timer if this page/tab is closed.`}</WarningContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} lg={10}>
                <Grid item xs={2} />
                <Grid item xs={10}>
                  <h2>Current items attached to this timing:</h2>
                  <CycleTimingItems
                    items={cycleTiming.items}
                    onRemoveItem={removeTimingItem}
                    isTimingCompleted={cycleTiming.status === Status.COMPLETED}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Dialog
          open={newTimingForExistingDialogOpen}
          onClose={newTimingForExistingDialogOnCancel}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">New timing?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to start a new timing for an existing timing?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={newTimingForExistingDialogOnCancel} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={newTimingForExistingDialogOnSubmit}
              color="secondary"
              variant="contained"
            >
              Start new timing
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={addItemToTimingForExistingDialogOpen}
          onClose={addItemToTimingForExistingDialogOnCancel}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Exiting completed timing found for item</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to add this item to current timing?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={addItemToTimingForExistingDialogOnCancel} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={addItemToTimingForExistingDialogOnSubmit}
              color="secondary"
              variant="contained"
            >
              Add item to current timing
            </Button>
          </DialogActions>
        </Dialog>
      </ErrorBoundary>
      <Loader loading={loading} />
    </Root>
  );
};

export default CycleTimings;
