import React, { useMemo, useEffect } from 'react';
import { Form, useFormikContext } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from '../../../reducers/types';
import { AddProductAndVariantValues } from './types';
import { CreateConditionAlternates } from '../../../services/catalog/types';
import * as actions from '../../../actions';
import styled from 'styled-components';
import { getAddProductFieldListConfiguration } from '../utils/product/fields';
import { useFieldCategoryConfig } from '../utils/useFieldCategoryConfig';
import { groupFieldListConfiguration } from '../utils/formik/formik-fields';
import FormikField from '../editProduct/FormikField';

export const FormComponent = styled(Form)`
  padding: 20px;
  margin-bottom: 20px;
`;

const AddProductForm: React.FC = () => {
  const productStore = useSelector((state: GlobalState) => state.product);
  const {
    values: { categoryId, submissionId, createConditionAlternates },
    setFieldValue,
  } = useFormikContext<AddProductAndVariantValues>();
  const selectedCategory = useMemo(() => productStore.categories.find(c => c.id === categoryId), [
    categoryId,
    productStore.categories,
  ]);
  const dispatch = useDispatch();

  const { fieldCategoryConfigs } = useFieldCategoryConfig(categoryId ?? undefined);

  const groupedFieldListConfiguration = useMemo(() => {
    const configs = getAddProductFieldListConfiguration({
      productStore,
      fieldCategoryConfigs,
      categoryId: categoryId ?? 0,
      submissionId,
      createConditionAlternates,
      dispatch,
    });
    return groupFieldListConfiguration(configs);
  }, [
    categoryId,
    submissionId,
    createConditionAlternates,
    fieldCategoryConfigs,
    productStore,
    dispatch,
  ]);

  /**
   * Setup dynamic form changes here
   * 1. Reset createConditionAlternates when category changes OR always false if submissionId
   * 2. Set condition to null if createConditionAlternates is true
   * 3. fetch new autosuggest dropdown options based on category ID if it changes
   */
  // 1.
  useEffect(() => {
    setFieldValue(
      'createConditionAlternates',
      !!submissionId
        ? false
        : selectedCategory?.createConditionAlternates ===
            CreateConditionAlternates.ENABLED_DEFAULT_TRUE,
    );
  }, [submissionId, selectedCategory, setFieldValue]);
  // 2.
  useEffect(() => {
    if (createConditionAlternates) {
      setFieldValue('condition', null);
    }
  }, [createConditionAlternates, setFieldValue]);
  // 3.
  // todo: not sure if this is needed anymore for this form?
  useEffect(() => {
    dispatch(actions.getAutosuggestFields({ categoryId }));
  }, [categoryId, dispatch]);
  // 4. If the category changes, null out the subcategory.
  useEffect(() => {
    setFieldValue('productSubCategoryId', null);
  }, [categoryId, setFieldValue]);

  return (
    <>
      {groupedFieldListConfiguration.groups.map(({ displayName, fields, subGroups }) => (
        <React.Fragment key={displayName}>
          <h4>{displayName}</h4>
          {subGroups.length
            ? subGroups.map(subGroup => (
                <React.Fragment key={subGroup.displayName}>
                  <h5 key={subGroup.displayName}>{subGroup.displayName}</h5>
                  {subGroup.fields.map(field => (
                    <FormikField key={field.valueKey} field={field} />
                  ))}
                </React.Fragment>
              ))
            : fields.map(field => <FormikField key={field.valueKey} field={field} />)}
        </React.Fragment>
      ))}
    </>
  );
};

export default AddProductForm;
