import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import GenericModal from '../../components/modals/genericFormModal';
import ImageUpload from '../tradeups/imageUploads';
import axios from '../../utils/axios';
import { compress } from '../../utils/compress';
import { API_URL } from '../../constants';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GenericFormModal from '../../components/modals/genericFormModal';

const alertStyling = {
  background: 'yellow',
  padding: 5,
  borderRadius: 3,
};

// I did some work to make this suck a little less, but more is needed...
// TODO: Make this a tsx and use MaterialUI components
// TODO: Display more Submission info in Modal
const SubmissionReturnForm = ({ id, closeModal }) => {
  const [submitSubmissionId, setSubmitSubmissionId] = useState('');
  const [submissionId, setSubmissionId] = useState(id);
  const [submissionImage, setSubmissionImage] = useState('');
  const [imageUrl1, setImageUrl1] = useState('');
  const [imageLoadStatus1, setLoadStatus1] = useState(0);
  const [imageUrl2, setImageUrl2] = useState('');
  const [imageLoadStatus2, setLoadStatus2] = useState(0);
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [isTradeUp, setIsTradeUp] = useState(false);
  const [hasInsurance, setHasInsurance] = useState(false);
  const [tradeUpDealer, setTradeUpDealer] = useState(undefined);
  const [success, setSuccess] = useState(false);
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    const getReturnSubmission = async submissionId => {
      setLoading(true);
      try {
        const result = await axios.get(
          `${API_URL}/buyers/sybSubmissionById?referenceN=${submissionId}`,
        );
        setSubmissionImage(result.data.items[0].images[0].url);
        if (result.data.dealer) {
          setIsTradeUp(true);
          setTradeUpDealer(result.data.dealer.name);
        }
        if (parseInt(result.data.insuranceCost, 10)) {
          setHasInsurance(true);
        }
      } catch (error) {
        NotificationManager.error(
          `Unable to find submission with id ${submissionId}`,
          'Error',
          3000,
        );
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    if (submissionId) {
      getReturnSubmission(submissionId);
    }
  }, [submissionId]);

  const makeS3Request = (signedUrl, file, side) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = event => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve();
          } else {
            console.error('err', xhr.statusText);
            reject(
              new Error('panic!', {
                status: xhr.status,
                statusText: xhr.statusText,
              }),
            );
          }
        }
      };

      xhr.upload.onprogress = e => {
        if (side === 'imageUrl1') {
          const total = (e.loaded / e.total) * 100;
          setLoadStatus1(total);
        }
        if (side === 'imageUrl2') {
          const total = (e.loaded / e.total) * 100;
          setLoadStatus2(total);
        }
      };
      xhr.open('PUT', signedUrl);
      xhr.setRequestHeader('Content-Type', 'image/jpeg');
      xhr.send(file);
    });

  const handleReturnSubmit = async e => {
    e.preventDefault();
    setErrored(false);
    setSuccess(false);
    setLoading(true);
    try {
      await axios.post(`${API_URL}/tradeup/submissionReturn`, {
        imageUrl1,
        imageUrl2,
        note,
        submissionId,
      });
      setSuccess(true);
    } catch (error) {
      console.error(error);
      setErrored(true);
    } finally {
      setLoading(false);
    }
  };

  const handleImageDrop = async e => {
    const fileType = e.target.type;
    const { name } = e.target;
    const file = e.target.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    const result = await axios.get(`${API_URL}/tradeup/signedUrl?file-type=${fileType}`);
    const { signedRequest, url } = result.data;
    const compressedFile = await compress(file, 100, 1200);
    await makeS3Request(signedRequest, compressedFile, name);
    return url;
  };

  const handleDrag = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const lookupForm = (
    <form
      className="flex-column min-width-form-wrap"
      onSubmit={e => {
        e.preventDefault();
        setSubmissionId(submitSubmissionId);
      }}
    >
      <label htmlFor="submissionId" className="label">
        Submission Id:
      </label>
      <input
        className="text-input margin-bottom"
        type="text"
        value={submitSubmissionId}
        onChange={e => setSubmitSubmissionId(e.target.value)}
        placeholder="Enter submission #"
        id="submissionId-input"
      />
      <input className="classy-button" type="submit" value="Look Up" disabled={loading} />
    </form>
  );

  const returnSubmissionForm = (
    <form className="flex-column min-width-form-wrap" onSubmit={e => handleReturnSubmit(e)}>
      {isTradeUp && <p style={alertStyling}>Submission is trade up to dealer {tradeUpDealer}</p>}
      {hasInsurance && <p style={alertStyling}>Submission has selected insurance</p>}
      <section className="seller-info-wrap flex-column-center">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${id}-content`}
            id={`panel-${id}-header`}
          >
            Driveside Image
          </AccordionSummary>
          <AccordionDetails>
            <img
              src={submissionImage}
              alt={submissionId}
              style={{ width: '100%', height: 'auto' }}
            />
          </AccordionDetails>
        </Accordion>
      </section>
      <section className="seller-info-wrap flex-column-center">
        <h3 className="form-section">Photo Upload:</h3>
        <span className="form-section gray-small">Upload two photos of the damage.</span>
        <section className="flex-row-adjacent">
          <ImageUpload
            clearImage={() => {
              setImageUrl1('');
              setLoadStatus1(0);
            }}
            handleImageDrop={async e => setImageUrl1(await handleImageDrop(e))}
            handleDrag={e => handleDrag(e)}
            dragIn={() => null}
            dragOut={() => null}
            photoHover={false}
            loadStatus={imageLoadStatus1}
            photoUrl={imageUrl1}
            name="imageUrl1"
          />
          <ImageUpload
            clearImage={() => {
              setImageUrl2('');
              setLoadStatus2(0);
            }}
            handleImageDrop={async e => setImageUrl2(await handleImageDrop(e))}
            handleDrag={e => handleDrag(e)}
            dragIn={() => null}
            dragOut={() => null}
            photoHover={false}
            loadStatus={imageLoadStatus2}
            photoUrl={imageUrl2}
            name="imageUrl2"
          />
        </section>
        <textarea
          style={{ width: '100%', margin: '25px 0' }}
          placeholder="Notes"
          rows="5"
          value={note}
          onChange={e => setNote(e.target.value)}
        />
      </section>
      <input className="classy-button" type="submit" value="Submit" disabled={loading} />
    </form>
  );

  return (
    <Box>
      <GenericFormModal
        closeModal={closeModal ?? null}
        form={submissionId ? returnSubmissionForm : lookupForm}
        success={success}
        error={errored}
      />
    </Box>
  );
};

export default SubmissionReturnForm;
