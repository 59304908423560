import React from 'react';
import {
  default as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

export interface Option {
  label: string;
  value: string | number;
}

interface CustomTextFieldProps {
  variant?: 'standard' | 'filled' | 'outlined';
  options?: Option[];
}

export type TextFieldProps = CustomTextFieldProps & MuiTextFieldProps;

export const TextField: React.FC<TextFieldProps> = ({
  variant = 'standard',
  options,
  ...muiProps
}) => (
  <MuiTextField variant={variant} {...muiProps} select={!!options}>
    {options?.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </MuiTextField>
);
