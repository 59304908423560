import React from 'react';
import useLocalStorageState from '../../utils/hooks/useLocalStorageState';

interface PreferenceToggleViewProps {
  localStorageKey: string;
  ToggleOffView: React.ReactElement;
  ToggleOnView: React.ReactElement;
}

export const PreferenceToggleView: React.FC<PreferenceToggleViewProps> = ({
  localStorageKey,
  ToggleOffView,
  ToggleOnView,
}) => {
  const [isToggleOn] = useLocalStorageState<boolean>(localStorageKey, false);

  return isToggleOn ? ToggleOnView : ToggleOffView;
};
