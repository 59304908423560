import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import PropTypes from 'prop-types';
import axios from '../../utils/axios';
import { API_URL } from '../../constants';
import Modal from '../../components/modals/genericFormModal';
import Loader from '../../components/loader';
import './partnerSubmissionDetail.scss';
import StatusDisplay from './detailComponents/statusDisplay';
import SellerInfo from './detailComponents/sellerInfo';
import BikeInfo from './detailComponents/bikeInfo';
import WheelInfo from './detailComponents/wheelInfo';
import DealerInfo from './detailComponents/dealerInfo';
import ShippingInfo from './detailComponents/shippingInfo';
import HistoryInfo from './detailComponents/historyInfo';
import InternalUpdateForm from './partnerForms/internalUpdateForm';
import PreviousSubmissionsTable from './detailComponents/PreviousSubmissionsTable';
import { createYearsArray } from './helper';
import { cycleTimingsContext } from '../service/cycleTimings/cycleTimingsContext';
import { TRADEUP_SEARCH_ROUTE } from './SubmissionListView';

// import ReststrictedZipModal from '../modals/restrictedZipModal';
export const PARTNER_SUBMISSION_DETAIL_ROUTE = '/tradeups/partner';

class QbpTradeupDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disciplines: [],
      loading: true,
      submissionId: '',
      displayModal: false,
      sellerInfo: {
        name: '',
      },
      bikeInfo: {},
      shippingInfo: {},
      submission: { buyer_id: 0 },
      submissionHistory: [],
      submissionPrevious: [],
      dealerInfo: {},
      offerStatus: {},
      image: [],
      rotate: 0,
      expand: false,
      // internal item info
      itemId: null,
      brandConfirmed: null,
      modelConfirmed: null,
      yearConfirmed: null,
      disciplineId: null,
      sizeConfirmed: null,
      wheelsizeConfirmed: null,
      categoryId: null,
      pipeline: null,
      msrp: null,
      // status display update modal fields
      buyerId: 0,
      salePrice: 0,
      offer: 0,
      externalNote: '',
      internalNote: '',
    };
  }
  genericFetchData = async (endpoint, stateKey) => {
    try {
      const url = `${API_URL}${endpoint}`;
      const response = await axios.get(url);
      this.setState({ [stateKey]: response.data });
    } catch (err) {
      console.error(err);
      NotificationManager.error(`${endpoint} failed`, 'Fetch Error');
    } finally {
      this.setState({ loading: false });
    }
  };

  componentDidMount = () => {
    this.genericFetchData('/products/disciplines', 'disciplines');
    this.fetchData();
  };

  UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this._handleEscKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleEscKey, false);
  }

  _handleEscKey = e => {
    if (e.keyCode === 27) {
      this.closeModal();
      this.setState({ showId: false });
    }
  };

  fetchData = async () => {
    const { submissionId } = this.props.match.params;
    this.setState({ submissionId });
    const res = await axios.get(`${API_URL}/tradeup/qbpSubmission/${submissionId}`);
    const notes = await axios.get(`${API_URL}/tradeup/qbpSubmission/notes/${submissionId}`);
    const images = await axios.get(`${API_URL}/tradeup/qbpSubmission/images/${submissionId}`);
    const submissionHistory = await axios.get(
      `${API_URL}/tradeup/qbpSubmission/history/${submissionId}`,
    );
    const submission = res.data;
    const bikePictures = [];
    if (images.data.length > 0) {
      bikePictures.push(images.data[0].url, images.data[1].url);
    }

    const sellerInfo = {
      submissionId,
      name: submission.sellerName || 'n/a',
      email: submission.sellerEmail,
      phone: submission.sellerPhone,
      address: submission.sellerAddress,
      expiration: submission.expiration,
    };
    const sybSubs = await this.fetchSybSubmissions(submission.sellerEmail);
    const partnerSubs = await this.fetchPreviousSubmissions(submission.sellerEmail);
    const allPreviousSubs = [...sybSubs, ...partnerSubs].sort(
      (a, b) => new Date(b.created) - new Date(a.created),
    );
    this.setState({ submissionPrevious: allPreviousSubs });

    const bikeInfo = {
      year: submission.bikeYear,
      make: submission.bikeBrand,
      model: submission.bikeModel,
      serialNumber: submission.bikeSerialNumber,
      description: submission.bikeDescription,
      discipline: submission.bikeDiscipline,
    };

    const offerStatus = {
      offerPrice: submission.offerAmount,
      expectedPrice: submission.expectedPrice,
      status: submission.status,
      created: new Date(submission.createdDate).toLocaleDateString(),
      firstOfferDate: submission.firstOfferDate
        ? new Date(submission.firstOfferDate).toLocaleDateString()
        : 'N/A',
      buyer: submission.buyer,
      notes: notes.data,
      digitalSignature: submission.digitalSignature,
      preferredEmail: submission.preferredEmail,
    };

    const shippingInfo = {
      label: submission.shippingLabelUrl,
      tracking: submission.trackingNumber,
      trackingUrl: submission.trackingUrl,
    };

    const dealerInfo = {
      dealerName: submission.dealerName,
      dealerEmail: submission.dealerEmail,
      dealerAddress: submission.dealerAddress,
      dealerPhone: submission.dealerPhone,
      dealerCity: submission.dealerCity,
      dealerZip: submission.dealerZip,
    };
    this.setState({
      buyerId: submission.buyer_id,
      salePrice: submission.expectedPrice,
      offer: submission.offerAmount,
      submission,
      itemId: submission.Items[0].submitted_item_id,
      submissionHistory: submissionHistory.data,
      dealerInfo,
      sellerInfo,
      offerStatus,
      shippingInfo,
      bikeInfo,
      image: bikePictures,
      brandConfirmed: submission.Items[0].brand_confirmed,
      modelConfirmed: submission.Items[0].model_confirmed,
      yearConfirmed: submission.Items[0].year_confirmed,
      disciplineId: submission.Items[0].dt_discipline_id,
      sizeConfirmed: submission.Items[0].size_confirmed,
      wheelsizeConfirmed: submission.Items[0].wheelsize_confirmed,
      categoryId: submission.Items[0].category_id,
      pipeline: submission.Items[0].pipeline,
      msrp: submission.Items[0].msrp,
      catalogProductId: submission.catalogProductId,
    });
  };

  fetchPreviousSubmissions = async email => {
    try {
      const url = `${API_URL}/tradeup/partnerSubmissionsByEmail/${email}`;
      const response = await axios.get(url);
      if (response.data.length > 1) {
        NotificationManager.warning(
          'Customer has previous submissions',
          'Return Seller',
          20000,
          null,
          true,
        );
        return response.data;
      }
    } catch (e) {
      console.error(e);
    }
    return [];
  };

  fetchSybSubmissions = async email => {
    try {
      const url = `${API_URL}/tradeup/syb/submissionsbyemail/${email}`;
      const response = await axios.get(url, { params: { email } });
      const tupSubs = response.data.map(sub => ({
        ...sub,
        partnerName: 'SYB',
      }));

      if (response.data.length > 0) {
        NotificationManager.warning(
          'Customer has previous SYB submissions',
          'Return Seller',
          20000,
          null,
          true,
        );
        return tupSubs;
      }
    } catch (e) {
      console.error(e);
    }
    return [];
  };

  displayModal = e => {
    this.setState({
      displayModal: true,
      modalImage: this.state.image[e],
    });
  };

  closeModal = () => {
    this.setState({
      displayModal: false,
      displayInternalUpdateModal: false,
      modalImage: '',
    });
  };

  rotate = e => {
    e.stopPropagation();
    this.setState({ rotate: this.state.rotate + 90, displayModal: true });
  };

  render() {
    const {
      dealerInfo,
      shippingInfo,
      sellerInfo,
      bikeInfo: {
        year,
        make,
        model,
        description,
        discipline,
        serialNumber,
        wheelType,
        wheelFamily,
      },
      offerStatus,
      image,
      displayModal,
      modalImage,
      submissionId,
      submissionHistory,
      submissionPrevious,
      catalogProductId,
    } = this.state;
    return (
      <div className="qbp-submission-wrap react-table" style={{ padding: '50px 0' }}>
        <Link to={TRADEUP_SEARCH_ROUTE}>Tradeups</Link>
        <StatusDisplay
          offerStatus={offerStatus}
          submissionId={this.state.submissionId}
          buyerId={this.state.buyerId}
          salePrice={this.state.salePrice}
          offer={this.state.offer}
          externalNote={this.state.externalNote}
          internalNote={this.state.internalNote}
        />
        <SellerInfo submissionId={submissionId} sellerInfo={sellerInfo} />
        {this.state.bikeInfo.make && (
          <BikeInfo
            displayModal={this.displayModal}
            make={make}
            model={model}
            year={year}
            discipline={discipline}
            serialNumber={serialNumber}
            description={description}
            imageGallery={image.reduce((accu, img) => {
              const newObj = { url: img };
              return [...accu, newObj];
            }, [])}
          />
        )}
        {this.state.bikeInfo.wheelType && (
          <WheelInfo wheelFamily={wheelFamily} wheelType={wheelType} />
        )}
        <fieldset style={{ position: 'relative' }} className="list-wrap">
          <legend>Internal Bike Info</legend>
          {!!catalogProductId && (
            <div className="form-wrap">
              <span className="input-label-wrap-status offer-display underline">
                <label className="label doo-label">Item Catalog Product:</label>
                <p>
                  <Link to={`/catalog/products/${catalogProductId}`} target="_blank">
                    {catalogProductId}
                  </Link>
                </p>
              </span>
            </div>
          )}
          <div className="form-wrap">
            <span className="input-label-wrap-status offer-display underline">
              <label className="label doo-label">Year:</label>
              <p>{this.state.yearConfirmed}</p>
            </span>
            <span className="input-label-wrap-status doo-display underline">
              <label className="label">Size:</label>
              <p>{this.state.sizeConfirmed}</p>
            </span>
            <span className="input-label-wrap-status doo-display underline">
              <label className="label">Wheel Size:</label>
              <p>{this.state.wheelsizeConfirmed}</p>
            </span>
          </div>
          <div className="form-wrap">
            <span className="input-label-wrap-status doo-display underline">
              <label className="label">Brand:</label>
              <p>{this.state.brandConfirmed}</p>
            </span>
            <span className="input-label-wrap-status doo-display underline">
              <label className="label">Model:</label>
              <p>{this.state.modelConfirmed}</p>
            </span>
            <span className="input-label-wrap-status doo-display underline">
              <label className="label">MSRP:</label>
              <p>{this.state.msrp}</p>
            </span>
          </div>
          <div className="form-wrap">
            <span className="input-label-wrap-status doo-display underline">
              <label className="label">Pipeline:</label>
              <p>{this.state.pipeline || 'N/A'}</p>
            </span>
            <span className="input-label-wrap-status doo-display underline">
              <label className="label">Bike Discipline:</label>
              <p>
                {this.state.disciplineId &&
                this.state.disciplines.find(d => d.id === this.state.disciplineId)
                  ? this.state.disciplines.find(d => d.id === this.state.disciplineId).discipline
                  : 'N/A'}{' '}
              </p>
            </span>
            <span className="input-label-wrap-status doo-display underline">
              <label className="label">Bike Category:</label>
              <p>{this.state.categoryId || 'N/A'}</p>
            </span>
          </div>
        </fieldset>
        <DealerInfo dealerInfo={dealerInfo} />
        <ShippingInfo shippingInfo={shippingInfo} />
        <section>
          <h3>Submission History</h3>
          <HistoryInfo submissionHistory={submissionHistory} />
        </section>
        {submissionPrevious.length >= 1 && (
          <section>
            <h3>Previous Submissions</h3>
            <PreviousSubmissionsTable previousSubmissions={submissionPrevious} />
          </section>
        )}
        {this.state.showId && (
          <Modal
            form={
              <div>
                <img
                  onClick={() => this.setState({ expand: !this.state.expand })}
                  style={
                    this.state.expand
                      ? {
                          maxWidth: '1500px',
                          width: '70vw',
                          height: 'auto',
                          transform: `rotate(${this.state.rotate}deg)`,
                        }
                      : { transform: `rotate(${this.state.rotate}deg)` }
                  }
                  alt="id detail"
                  className="modal-image id-image"
                  src={this.state.idImg.signedRequest}
                />
                <button className="rotate-btn submit-button" onClick={this.rotate}>
                  ROTATE
                </button>
              </div>
            }
            closeModal={() => this.setState({ showId: false })}
          />
        )}
        {displayModal && (
          <div role="button" tabIndex={0} onClick={this.closeModal} className="backdrop">
            <span className="modal-image-wrap">
              <img
                style={{ transform: `rotate(${this.state.rotate}deg)` }}
                alt="bike detail"
                className="modal-image"
                src={modalImage}
              />
              <button className="rotate-btn submit-button" onClick={this.rotate}>
                ROTATE
              </button>
            </span>
          </div>
        )}
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

QbpTradeupDetailView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ submissionId: PropTypes.string.isRequired }),
  }).isRequired,
};

export default withRouter(QbpTradeupDetailView);
