// a util for parsing the data structure returned by Yup into
// a key-per-field structure that makes rendering errors simpler
export default function parseYupErrors(
  err: {
    inner: {
      path: string;
      message: string;
    }[];
  },
  keyMap: {
    [key: string]: string;
  } = {},
) {
  return Object.fromEntries(
    err.inner.map(({ path, message }) => {
      return [keyMap[path] ?? path, message];
    }),
  );
}
