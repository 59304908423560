import React, { FC, useContext, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Collapse,
  createStyles,
  Fab,
  FormControl,
  FormControlLabel,
  Grow,
  InputLabel,
  LinearProgress,
  makeStyles,
  Switch,
  Tab,
  Tabs,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { CycleTimingItems } from './CycleTimingsItems';
import { Status } from '../../../services/cycleTimings/types';
import { useCycleTimings } from './useCycleTimings';
import FloatingWindow from './FloatingWindow';
import Timer from '../../../components/timer';
import useLocalStorageState from '../../../utils/hooks/useLocalStorageState';
import { cycleTimingsContext } from './cycleTimingsContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    complete: {
      bgcolor: theme.palette.primary.main,
      color: 'white',
    },
    bold: {
      fontFamily: theme.typography.bold,
    },
    active: {
      background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main})`,
    },
    paused: {
      background: `linear-gradient(to right, ${theme.palette.info.dark} 0%, ${theme.palette.primary.main})`,
    },
  }),
);

const blankStation = { id: '', description: '' };

const NewCycleTimings: FC = () => {
  const {
    station,
    setStation,
    stations,
    loading,
    onFormSubmitSearch,
    timingTabs,
    tabValue,
    clearCurrentTiming,
    setTabValue,
    onTabChange,
    cycleTiming,
    updateTimingStatus,
    removeTimingItem,
    updateTimingTraining,
  } = useCycleTimings();

  const [timingStations, setTimingStations] = useLocalStorageState<string[]>('timingStations', []);
  const [addItem, setAddItem] = useState(false);
  const [stationDescription, setStationDescription] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);

  const { cycleTimingSku, cycleTimingSubId } = useContext(cycleTimingsContext);

  let submitButtonText = 'Submit';
  if (cycleTiming && cycleTiming.status !== Status.COMPLETED) {
    submitButtonText = 'Add to Timing';
  }

  const getAutoValue = (timingTabId: string) => {
    if (initialLoad) {
      if (timingTabId === 'skuSearchBox') {
        return cycleTimingSku;
      } else if (timingTabId === 'submissionSearchBox') {
        return 's' + cycleTimingSubId;
      }
      return false;
    }
    return false;
  };

  const clearTextFields = () => {
    setInitialLoad(false);
    timingTabs[tabValue].onInit('');
  };

  const getStationIdentifier = (station: { id: string; description: string }) => {
    return station.id + ' - ' + station.description;
  };

  // Favicon update
  useEffect(() => {
    if (cycleTiming && cycleTiming.status === Status.STARTED) {
      document.querySelector('#favicon')?.setAttribute('href', '/cycleTimingActive.png');
    } else if (cycleTiming && cycleTiming.status === Status.PAUSED) {
      document.querySelector('#favicon')?.setAttribute('href', '/cycleTimingPaused.png');
    } else {
      document.querySelector('#favicon')?.setAttribute('href', '/favicon.png');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleTiming]);

  useEffect(() => {
    const resetFavicon = async () => {
      try {
        document.querySelector('#favicon')?.setAttribute('href', '/favicon.png');
      } catch (err) {}
    };

    window.addEventListener('beforeunload', resetFavicon);

    return () => {
      window.removeEventListener('beforeunload', resetFavicon);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearTextFields();

    // Use timeout to make transition more aparent
    setTimeout(() => {
      setInitialLoad(true);
      if (!!cycleTimingSku) {
        const index = timingTabs.findIndex(({ id }) => id === 'skuSearchBox');
        setTabValue(index);
        timingTabs[index].onInit(cycleTimingSku);
      } else if (!!cycleTimingSubId) {
        const index = timingTabs.findIndex(({ id }) => id === 'submissionSearchBox');
        setTabValue(index);
        timingTabs[index].onInit('s' + cycleTimingSubId);
      }
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cycleTimingSku, cycleTimingSubId]);

  useEffect(() => {
    const stationDescription = stations?.find(s => s.id === station)?.description;
    if (stationDescription) {
      setStationDescription(stationDescription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station]);

  const classes = useStyles();
  const theme = useTheme();

  return (
    <FloatingWindow
      status={
        cycleTiming?.status === Status.STARTED
          ? 'success'
          : cycleTiming?.status === Status.PAUSED
          ? 'info'
          : 'default'
      }
      title={
        <>
          {cycleTiming?.stationId
            ? cycleTiming.stationId +
              ' | ' +
              stationDescription +
              ' - ' +
              (cycleTiming?.items[0].sku ||
                cycleTiming?.items[0].sybSubmissionId ||
                cycleTiming?.items[0].partnerSubmissionId)
            : 'Cycle Timings'}
        </>
      }
      closedText={
        <Typography
          variant="body2"
          style={{
            margin: 0,
            color: theme.palette.common.white,
            lineHeight: 0,
          }}
        >
          {!!cycleTiming && (
            <Box
              style={{
                opacity: 1,
                transition: `opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
                fontSize: '1rem',
              }}
              className={classes.bold}
            >
              <Timer
                key={cycleTiming.id}
                startDate={cycleTiming.startedAtUtc}
                seconds={cycleTiming.durationSeconds || 0}
                timerOn={cycleTiming.status === Status.STARTED}
              />
            </Box>
          )}
        </Typography>
      }
    >
      <Box width="100%" height="6px">
        {loading && <LinearProgress />}
      </Box>
      <Box
        maxHeight="calc(100vh - 48px)"
        style={{ overflowY: 'auto' }}
        mt="-6px"
        bgcolor={theme.palette.common.white}
      >
        {!station ? (
          <Box display="flex" width="100%" flexDirection="column" px={3} pt={2} pb={4}>
            <FormControl>
              <InputLabel id="select-cycle-timing-station">Station</InputLabel>
              <Autocomplete
                autoSelect
                options={[blankStation, ...(stations ?? [])]}
                getOptionLabel={station => getStationIdentifier(station)}
                onChange={(_, option) => {
                  setStation(option?.id ?? '');
                  if (option) {
                    setStationDescription(option.description);
                  }
                  if (option && !timingStations.includes(option.description)) {
                    if (timingStations.length >= 5) {
                      setTimingStations([
                        ...timingStations.slice(1),
                        getStationIdentifier(option) ?? '',
                      ]);
                    } else {
                      setTimingStations([...timingStations, getStationIdentifier(option) ?? '']);
                    }
                  }
                }}
                value={null}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      placeholder="Select a Station"
                      variant="outlined"
                      autoFocus={true}
                    />
                  );
                }}
              />
            </FormControl>
            {timingStations.length > 0 && (
              <Box mt={3} mb={1}>
                <Typography variant="caption" gutterBottom>
                  Or select a recently used station
                </Typography>
              </Box>
            )}
            <Box mb={1} display="flex" flexWrap="wrap" gridGap={theme.spacing(0.5)}>
              {timingStations
                .slice()
                .reverse()
                .map(cat => (
                  <Chip
                    key={cat}
                    label={cat}
                    onClick={() => {
                      const stationId = stations?.find(
                        station => getStationIdentifier(station) === cat,
                      )?.id;
                      if (stationId) {
                        setStation(stationId);
                      }
                    }}
                    onDelete={() => {
                      setTimingStations(timingStations.filter(c => c !== cat));
                    }}
                    color="secondary"
                    variant="outlined"
                  />
                ))}
            </Box>
          </Box>
        ) : (
          <>
            <Box width="100%">
              {!!cycleTiming && (
                <>
                  <Box
                    position="relative"
                    display="flex"
                    justifyContent="space-between"
                    py={2}
                    px={3}
                    alignItems="center"
                  >
                    <Box position="relative">
                      <Typography
                        variant="h3"
                        color={
                          cycleTiming.status === Status.STARTED ? 'textPrimary' : 'textSecondary'
                        }
                        className={classes.bold}
                      >
                        <Timer
                          key={cycleTiming.id}
                          startDate={cycleTiming.startedAtUtc}
                          seconds={cycleTiming.durationSeconds || 0}
                          timerOn={cycleTiming.status === Status.STARTED}
                        />
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      gridGap={theme.spacing(0.75)}
                      alignItems="center"
                      position="absolute"
                      right={theme.spacing(3)}
                    >
                      <Grow in={cycleTiming.status === Status.PAUSED}>
                        <Tooltip title="Return to Station">
                          <Fab
                            color="secondary"
                            size="small"
                            onClick={() => {
                              clearCurrentTiming();
                              clearTextFields();
                            }}
                          >
                            <KeyboardBackspaceIcon fontSize="small" />
                          </Fab>
                        </Tooltip>
                      </Grow>
                      <Fab
                        color="secondary"
                        size="medium"
                        onClick={() => {
                          updateTimingStatus(
                            cycleTiming.status === Status.STARTED ? Status.PAUSED : Status.STARTED,
                          );
                        }}
                      >
                        {cycleTiming.status === Status.STARTED ? <PauseIcon /> : <PlayArrowIcon />}
                      </Fab>
                      <Tooltip title="Complete Timing" enterDelay={500} arrow>
                        <Fab
                          color="primary"
                          aria-label="add"
                          size="medium"
                          onClick={() => {
                            updateTimingStatus(Status.COMPLETED);
                            clearTextFields();
                          }}
                          disabled={
                            !(
                              cycleTiming.status === Status.STARTED ||
                              cycleTiming.status === Status.PAUSED
                            )
                          }
                        >
                          <DoneIcon />
                        </Fab>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box px={3} display="flex" justifyContent="space-between">
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      endIcon={<AddIcon />}
                      onClick={() => setAddItem(!addItem)}
                    >
                      {addItem ? 'Hide Items' : 'Add Item'}
                    </Button>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          onChange={e => updateTimingTraining(e.target.checked)}
                          disabled={!cycleTiming || cycleTiming?.status === Status.COMPLETED}
                          checked={!!cycleTiming?.isTraining}
                          color="primary"
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          color={!!cycleTiming?.isTraining ? 'textPrimary' : 'textSecondary'}
                        >
                          Training
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                  </Box>
                </>
              )}

              <Collapse in={addItem || !!!cycleTiming} timeout={{ enter: 300, exit: 0 }}>
                <Box
                  style={
                    !!cycleTiming
                      ? { borderTop: `solid 1px ${theme.palette.divider}`, marginTop: '1em' }
                      : {}
                  }
                >
                  <Box width="100%" component="form" onSubmit={onFormSubmitSearch} textAlign="left">
                    <Tabs
                      value={tabValue}
                      indicatorColor="secondary"
                      textColor="secondary"
                      onChange={(e, value) => {
                        onTabChange(e, value);
                        setInitialLoad(false);
                      }}
                      variant="fullWidth"
                      centered
                    >
                      {timingTabs.map(t => (
                        <Tab key={t.tabLabel} label={t.tabLabel} />
                      ))}
                    </Tabs>
                    <Box px={3} py={4}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        width="100%"
                        gridGap={theme.spacing(1.5)}
                      >
                        <Typography variant="body1">{stationDescription}</Typography>
                        <Box position="relative">
                          <TextField
                            id={timingTabs[tabValue].id}
                            value={
                              getAutoValue(timingTabs[tabValue].id) || timingTabs[tabValue].value
                            }
                            variant="outlined"
                            placeholder={timingTabs[tabValue].placeholder}
                            onChange={e => {
                              timingTabs[tabValue].onChange(e);
                              setInitialLoad(false);
                            }}
                            style={{ backgroundColor: theme.palette.common.white }}
                            autoFocus
                          />
                          <Grow in={!!getAutoValue(timingTabs[tabValue].id)}>
                            <Box
                              position="absolute"
                              right={0}
                              top={0}
                              bottom={0}
                              display="flex"
                              alignItems="center"
                              px={2}
                            >
                              <Box
                                px={1}
                                py={0.5}
                                borderRadius={theme.shape.borderRadius}
                                bgcolor={theme.palette.primary.main}
                                color={theme.palette.primary.light}
                              >
                                <Typography variant="body2">Auto</Typography>
                              </Box>
                            </Box>
                          </Grow>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="end"
                          width="100%"
                          gridGap={theme.spacing(1)}
                        >
                          <Button variant="text" color="secondary" onClick={() => setStation('')}>
                            Go Back
                          </Button>

                          <Button variant="contained" color="secondary" type="submit">
                            {submitButtonText}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Collapse>
              {!!cycleTiming && (
                <>
                  <Accordion
                    square
                    variant="outlined"
                    style={{
                      backgroundColor: theme.palette.grey[50],
                      border: '0px',
                      overflow: 'hidden',
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="body2">Items attached to this timing</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CycleTimingItems
                        items={cycleTiming.items}
                        onRemoveItem={removeTimingItem}
                        isTimingCompleted={cycleTiming.status === Status.COMPLETED}
                      />
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </FloatingWindow>
  );
};

export default NewCycleTimings;
