import React from 'react';

const Intake = () => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0625 7.125H15v10.3125H0V7.125h.9375V5.25c0-2.5875 2.1-4.6875 4.6875-4.6875.6656 0 1.3031.1406 1.875.3938A4.6313 4.6313 0 019.375.5625c2.5875 0 4.6875 2.1 4.6875 4.6875v1.875zM2.8125 5.25v1.875h1.875V5.25c0-1.0594.3656-2.025.9562-2.8125H5.625c-1.5469 0-2.8125 1.2656-2.8125 2.8125zm9.375 1.875V5.25c0-1.5469-1.2656-2.8125-2.8125-2.8125h-.0188a4.6685 4.6685 0 01.9563 2.8125v1.875h1.875zM7.5 3.1688c-.5719.5156-.9375 1.2562-.9375 2.0812v1.875h1.875V5.25c0-.825-.3656-1.5656-.9375-2.0812z"
      fill="currentColor"
    />
  </svg>
);

export default Intake;
