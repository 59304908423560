import { ProductVariantFrameDimensions as ProductVariantFrameDimension } from '../../../services/catalog/types';
import { extractApiErrorMessage } from '../../utils';

export interface UpcErrorProperties {
  msg?: string;
  upc?: string;
  productId?: number;
  sku?: string;
  ref?: React.RefObject<HTMLDivElement> | null;
}

/*
 capitalize only the first letter of a string, which is useful
 for displaying attributeType names: `wheelSize` -> `WheelSize`.
 (lodash.capitalize forces chars[1-n] to lowercase, which we don't want)
 */
export const displayCase = (word: string): string => {
  return word
    .slice(0, 1)
    .toUpperCase()
    .concat(word.slice(1));
};

const EARLIEST_YEAR = 1990;

const createYearOptions = () => {
  const yearData: { name: string; id: number }[] = [];
  const yearUpperLimit = new Date().getFullYear() + 1;
  for (let i = yearUpperLimit; i >= EARLIEST_YEAR; i--) {
    const currYear = i?.toString();
    yearData.push({ id: Number(currYear), name: currYear });
  }
  return yearData;
};

export const YEAR_OPTIONS = createYearOptions();

// convert array into object with key of frame id so it's easier to lookup
export const getFrameDimensionObj = (
  productVariantFrameDimensions: ProductVariantFrameDimension[] | null,
): {
  [key: string]: ProductVariantFrameDimension;
} =>
  productVariantFrameDimensions?.reduce((initial, frameDimension) => {
    return {
      ...initial,
      [frameDimension.frameDimensionId]: frameDimension,
    };
  }, {}) || {};

type StringCallback = (errMsg: string | string[] | undefined) => void;
export const handleApiError = (
  error: any,
  setError: React.Dispatch<React.SetStateAction<string | string[] | undefined>> | StringCallback,
) => {
  console.error(error);
  setError(extractApiErrorMessage(error));
};

export const DEFAULT_WEIGHT_FIELD_VALUE = ' lbs oz';

const SYB_SUB_PREFIX = 'S';
const PARTNER_SUB_PREFIX = 'P';
export const INVALID_SUB_SOURCE = 0;
export const SYB_SUB_SOURCE = 1;
export const PARTNER_SUB_SOURCE = 2;

type SOURCES = typeof INVALID_SUB_SOURCE | typeof SYB_SUB_SOURCE | typeof PARTNER_SUB_SOURCE;

export const validateSubmissionSource = (submissionId: string): [SOURCES, number] => {
  const submissionPrefix = submissionId[0].toUpperCase();
  const id = parseInt(submissionId.substring(1));

  if (isNaN(id)) return [INVALID_SUB_SOURCE, id];

  if (submissionPrefix === SYB_SUB_PREFIX) {
    return [SYB_SUB_SOURCE, id];
  } else if (submissionPrefix === PARTNER_SUB_PREFIX) {
    return [PARTNER_SUB_SOURCE, id];
  } else return [INVALID_SUB_SOURCE, id];
};

export const getDuplicateUpcError = (
  error: string | string[] | undefined,
): UpcErrorProperties | undefined => {
  if (error && typeof error === 'string') {
    try {
      return JSON.parse(error);
    } catch {
      return;
    }
  }
  return;
};
