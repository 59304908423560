import React, { useState } from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  useTheme,
} from '@material-ui/core';
import { Button } from '../../../../../../components/library';
import ErrorIcon from '@material-ui/icons/Error';
import { Edit } from '@material-ui/icons';

interface UpdateSerialNumberProps {
  isBikeStolen: boolean;
  allowUpdateStolenSerialNumber: boolean;
  setAllowUpdateStolenSerialNumber: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FixSerialNumber: React.FC<UpdateSerialNumberProps> = ({
  isBikeStolen,
  allowUpdateStolenSerialNumber,
  setAllowUpdateStolenSerialNumber,
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const theme = useTheme();

  return (
    <Grow in={isBikeStolen && !allowUpdateStolenSerialNumber}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={'rgb(255 255 255 / 0.5)'}
        position="absolute"
        zIndex={2}
        width="100%"
        height="100%"
        style={{
          backdropFilter: 'blur(4px) grayscale(1)',
        }}
        px={-4}
        left={0}
        top={0}
      >
        <Button ordinality="warning" variant="text" onClick={() => setConfirmationModalOpen(true)}>
          <>
            Update Serial Number &nbsp;
            <Edit />
          </>
        </Button>
        <Dialog open={confirmationModalOpen} onClose={() => setConfirmationModalOpen(false)}>
          <Box display="flex" pl={3} pt={3}>
            <Box
              display="flex"
              justifyContent={'center'}
              alignItems="center"
              width={40}
              height={40}
              borderRadius={4}
              bgcolor={theme.palette.error.light}
              flexShrink={0}
            >
              <ErrorIcon color="error" />
            </Box>
            <Box>
              <DialogTitle
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: theme.spacing(2),
                }}
              >
                Are you sure you want to update the serial number?
              </DialogTitle>
              <DialogContent
                style={{
                  paddingLeft: theme.spacing(2),
                }}
              >
                <DialogContentText
                  style={{
                    display: 'flex',
                  }}
                >
                  <Box>
                    The current bike may be stolen. You should only continue if the serial number
                    was incorrectly entered or you have confirmed that it was incorrectly flagged.
                  </Box>
                </DialogContentText>
              </DialogContent>
            </Box>
          </Box>
          <DialogActions>
            <Button
              onClick={() => setConfirmationModalOpen(false)}
              ordinality="secondary"
              variant="text"
              size="small"
            >
              Close
            </Button>
            <Button
              onClick={() => {
                setAllowUpdateStolenSerialNumber(true);
                setConfirmationModalOpen(false);
              }}
              ordinality="primary"
              variant="text"
              size="small"
              autoFocus
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Grow>
  );
};
