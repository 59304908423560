import React from 'react';
import DisplayField, { FieldColor } from './DisplayField';
import { ShopifyStatus } from '../../services/catalog/types';

interface Props {
  status?: ShopifyStatus;
}

const ShopifyStatusField: React.FC<Props> = ({ status }) => {
  if (!status) {
    return null;
  }

  let color: FieldColor;
  if (status === ShopifyStatus.Active) {
    color = 'success';
  } else if (status === ShopifyStatus.Draft) {
    color = 'warning';
  } else if (status === ShopifyStatus.Archived) {
    color = 'danger';
  } else {
    color = 'neutral';
  }

  return <DisplayField label="Shopify Status" value={status} color={color} />;
};

export default ShopifyStatusField;
