import { useState, useCallback, useEffect, SetStateAction } from 'react';
import {
  Product,
  ShopifyStatus,
  Inspection,
  ProductPromotionalText,
  VariationType,
} from '../../../services/catalog/types';
import { getErrorsArray } from '../../utils';
import * as catalogService from '../../../services/catalog/services';

interface UseProduct {
  product?: Product;
  firstVariantInspection?: Inspection;
  shopifyStatus?: ShopifyStatus;
  shopifyPublishReady?: boolean;
  shopifyPublishErrors?: string[];
  productPromotionalTexts?: ProductPromotionalText[];
  variationOptions?: VariationType[];
  error: boolean;
  loading: boolean;
  setLoading: (value: SetStateAction<boolean>) => void;
  refreshProductData: () => Promise<void>;
}

const useProduct = (productId: string): UseProduct => {
  const [product, setProduct] = useState<Product>();
  const [productPromotionalTexts, setProductPromotionalTexts] = useState<
    Array<ProductPromotionalText>
  >();
  const [firstVariantInspection, setFirstVariantInspection] = useState<Inspection>();
  const [shopifyStatus, setShopifyStatus] = useState<ShopifyStatus | undefined>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shopifyPublishReady, setShopifyPublishReady] = useState<boolean>();
  const [shopifyPublishErrors, setShopifyPublishErrors] = useState<string[] | undefined>(undefined);

  const refreshProductData = useCallback(async () => {
    setLoading(true);
    // Reset existing errors if any
    setShopifyPublishErrors(undefined);
    setError(false);
    try {
      await Promise.all([
        catalogService.fetchProduct(Number(productId), {
          onSuccess: res => {
            setProduct(res);
          },
          catchFailure: () => {
            setError(true);
          },
        }),
        catalogService.fetchProductPromotionalTexts(Number(productId), {
          onSuccess: res => {
            setProductPromotionalTexts(res);
          },
          catchFailure: () => {
            setError(true);
          },
        }),
        catalogService.fetchShopifyProductStatus(Number(productId), {
          onSuccess: res => {
            setShopifyStatus(res.status);
          },
          catchFailure: () => {
            setError(true);
          },
        }),
        catalogService.fetchShopifyPublishReady(Number(productId), {
          onSuccess: res => {
            setShopifyPublishReady(res.ready);
          },
          catchFailure: error => {
            if (error?.response?.data?.message) {
              setShopifyPublishErrors(getErrorsArray(error.response.data.message));
            }
            setShopifyPublishReady(false);
          },
        }),
      ]);
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [productId]);

  useEffect(() => {
    refreshProductData();
  }, [refreshProductData]);

  // Retrieve the first variants inspection if exists and update if sku changes
  const firstVariantSku = product?.productVariants?.[0].sku;
  useEffect(() => {
    if (firstVariantSku) {
      catalogService.getInspectionByIdentifier(firstVariantSku, {
        onSuccess: res => setFirstVariantInspection(res),
        catchFailure: axiosException => {
          //404 just means there's no inspection - that's fine
          if (axiosException.response?.status != 404) {
            throw axiosException;
          }
        },
      });
    }
  }, [firstVariantSku]);

  return {
    product,
    firstVariantInspection,
    shopifyStatus,
    shopifyPublishReady,
    shopifyPublishErrors,
    productPromotionalTexts,
    error,
    loading,
    setLoading,
    refreshProductData,
  };
};

export default useProduct;
