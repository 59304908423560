/* eslint-disable react/no-unescaped-entities */
import React, { FC, useState, MouseEvent } from 'react';
import axios from '../utils/axios';
import { API_URL } from '../constants';
import fileDownload from 'js-file-download';
import styles from '../styledComponents/';
import MaterialTable, { QueryResult } from '@material-table/core';
import fecha from 'fecha';
import { DropzoneArea } from 'material-ui-dropzone';
import { handleApiError } from './catalog/utils';
const { PageHeader, PageWrapper, SubmitInput, FlexWrapper, Form, LabelWrapper, Error } = styles;
interface State {
  selectedFile: File | null;
  batches: Batch[];
  totalCount: number;
  selectedBatch: number | null;
}
interface User {
  name: string;
}
interface Batch {
  created: Date;
  modified: Date;
  id: string;
  revisionOfBatchId: string;
  status: string;
  createdByUser: User;
  modifiedByUser: User;
  selectableStatuses: string[];
}

interface LeadsBatchResponse {
  batches: Batch[];
  totalCount: number;
}
const LeadsOnline: FC = () => {
  const initialState: State = {
    selectedFile: null,
    selectedBatch: null,
    batches: [],
    totalCount: 0,
  };
  const [error, setError] = useState<string | string[] | undefined>();
  const [{ selectedFile, selectedBatch }, setState] = useState(initialState);

  const updateState = (state: string, value: any) => {
    setState(prevState => ({ ...prevState, [state]: value }));
  };

  const handleSelect = (file: File[] | null) => {
    if (file) {
      updateState('selectedFile', file[0]);
    }
  };

  const handleUpload = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = new FormData();
    if (selectedFile) {
      data.append('csv', selectedFile, selectedFile.name);
    }
    const url = `${API_URL}/reports/leadsOnline/batches/revisionCsv`;
    try {
      updateState('error', ' ');
      await axios.post(url, data, {
        params: {
          revisionOfBatchId: selectedBatch,
        },
      });
      updateState('selectedBatch', null);
    } catch (err) {
      handleApiError(err, setError);
    }
  };

  const downloadLeadBatch = async (batch: any) => {
    // weird collision with typing from material table row data and default rowdata types
    const batchId = batch.id;
    const url = `${API_URL}/reports/leadsOnline/batches/${batchId}/itemsCsv`;
    try {
      const response = await axios.get(url, { params: batchId });
      updateState('error', ' ');
      if (response) {
        fileDownload(response.data, `leads_online_batch_${batchId}.csv`);
      }
    } catch (err) {
      handleApiError(err, setError);
    }
  };
  const selectBatch = (e: MouseEvent | undefined, rowData: any) => {
    e?.preventDefault();
    updateState('selectedBatch', rowData.id);
  };

  const updateBatchStatus = async (batch: any) => {
    // weird collision with typing from material table row data and default rowdata types
    const url = `${API_URL}/reports/leadsOnline/batches/${batch.id}/status`;
    try {
      updateState('error', ' ');
      await axios.put(
        url,
        { newStatus: batch.status },
        {
          params: {
            batchId: batch.id,
          },
        },
      );
    } catch (err) {
      handleApiError(err, setError);
    }
  };

  const formatDate = (date: Date) => {
    return fecha.format(new Date(date), 'ddd MMM Do, YYYY h:mm a');
  };
  return (
    <PageWrapper>
      <PageHeader>Leads Online</PageHeader>
      <section>
        <span>Leads Online Report Instructions</span>
        <ul>
          <p>
            This tool allows for the management of our submissions to Leads Online, for cooperation
            with law enforcement in their efforts to locate stolen bicycles.
          </p>
          <br />
          <p>
            Our submissions are organized into batches. Batches will be generated on a weekly basis
            by an automated process; each generated batch will contain every item from every
            completed submission, that is not already in an open or completed batch.
          </p>
          <br />
          <li>
            To prepare a batch for upload:
            <ul>
              <li>
                {' '}
                1. Click the "Save CSV" icon to download a csv of the contents of the report.
              </li>
              <li>
                {' '}
                2. Make any necessary edits to the document. Set the "omitted" column to TRUE to
                remove an item from the report.
              </li>
              <li>
                {' '}
                3. Note that any columns to the left of "omitted" are informational or ids - do not
                modify them.
              </li>
              <li>
                {' '}
                4. When ready, save your changes in a new csv and click the batch you want to revise
                to upload a new file. Note: if the item ids in your upload do not match those in the
                batch you are revising, the upload will be rejected.
              </li>
              <li>
                {' '}
                5. The upload will create a new batch, which will be marked as a revision of your
                old batch. You can download the CSV from the new batch to verify its contents match
                what you uploaded.
              </li>
              <li>
                {' '}
                6. When a batch is ready, click the pencil icon and then choose "Ready To Upload"
                from the status dropdown.{' '}
              </li>
              <li>
                {' '}
                7. A scheduled weekly process will look for batches in Ready To Upload, generate the
                properly formatted csv and upload it to LeadsOnline. Once this is successful, the
                batch will be automatically marked as Completed.
              </li>
            </ul>
          </li>
          <br />
          <p>Notes:</p>
          <p>
            If an upload fails, its status will be updated to Upload Failed - consult with tech to
            determine the cause.
          </p>
          <br />
          <p>
            If a batch is marked as Cancelled, its items will be eligible for inclusion in the next
            batch.
          </p>
        </ul>
      </section>
      <FlexWrapper flexDirection={'column'} alignItems={'center'}>
        {selectedBatch && (
          <FlexWrapper margin={'30px'} width={'50em'}>
            <Form onSubmit={handleUpload}>
              <DropzoneArea
                filesLimit={1}
                showFileNames={true}
                useChipsForPreview={true}
                acceptedFiles={['.csv']}
                onChange={file => handleSelect(file)}
              />
              <LabelWrapper>
                <label>Batch #:{selectedBatch}</label>
                <SubmitInput type="submit" value="Upload" disabled={selectedFile ? false : true} />
              </LabelWrapper>
            </Form>
          </FlexWrapper>
        )}
        <Error>{error}</Error>
        <section>
          <MaterialTable
            columns={[
              {
                title: 'Id',
                field: 'id',
                editable: 'never',
              },
              {
                title: 'Created',
                field: 'created',
                editable: 'never',
                render: rowData =>
                  `${formatDate(rowData.created)} by ${rowData.createdByUser?.name}`,
              },
              {
                title: 'Modified',
                field: 'modified',
                editable: 'never',
                render: rowData =>
                  `${formatDate(rowData.modified)} by ${rowData.modifiedByUser?.name}`,
              },
              {
                title: 'Revision',
                field: 'revisionOfBatchId',
                editable: 'never',
              },
              {
                title: 'Status',
                field: 'status',
                lookup: {
                  Cancelled: 'Cancelled',
                  'Ready To Upload': 'Ready To Upload',
                  Pending: 'Pending',
                },
                render: rowData => {
                  return <span>{rowData.status}</span>;
                },
                filtering: true,
              },
            ]}
            title="Leads Batches"
            onRowClick={(e, rowData) => selectBatch(e, rowData)}
            data={query =>
              new Promise<QueryResult<Batch>>(async (resolve, reject) => {
                const url = `${API_URL}/reports/leadsOnline/batches`;
                try {
                  updateState('error', ' ');
                  const { page, pageSize } = query;
                  const response = await axios.get(url, { params: { page, pageSize } });
                  if (response && response.data) {
                    const { batches, totalCount }: LeadsBatchResponse = response.data;
                    resolve({
                      data: batches,
                      page: query.page,
                      totalCount,
                    });
                  }
                } catch (err) {
                  reject(error);
                  handleApiError(err, setError);
                }
              })
            }
            options={{
              sorting: false,
              search: false,
              pageSize: 25,
              pageSizeOptions: [25],
            }}
            actions={[
              {
                icon: 'save_alt',
                tooltip: 'Save CSV',
                onClick: (event, rowData) => {
                  event?.preventDefault();
                  downloadLeadBatch(rowData);
                },
              },
            ]}
            editable={{
              isEditable: (rowData: any) =>
                rowData && rowData.selectableStatuses && rowData.selectableStatuses.length > 0,
              onRowUpdate: newData => {
                return updateBatchStatus(newData);
              },
            }}
          />
        </section>
      </FlexWrapper>
    </PageWrapper>
  );
};

export default LeadsOnline;
