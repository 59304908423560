import React, { Component } from 'react';
import { API_URL } from '../../constants';
import axios from '../../utils/axios';
import MaterialTable, { QueryResult, Query, Column, Options } from '@material-table/core';
import FilterSelect from './tableComponents/FilterSelect';
import FilterMultiSelect from './tableComponents/FilterMultiSelect';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getCatchReleaseFilters,
  getCatchReleasePage,
  getCatchReleaseSort,
  resetCatchReleaseStore,
} from '../../actions';

import { Buyer, Status, Discipline, ClassTier } from '../../typeORM';

interface NumberIndexedLookup {
  [id: number]: string;
}
interface StringIndexedLookup {
  [key: string]: string;
}

class State {
  public buyers: NumberIndexedLookup = {};
  public statuses: NumberIndexedLookup = {};
  public disciplines: NumberIndexedLookup = {};
  public classTiers: StringIndexedLookup = {};

  public tableKey = 0;
  public allFiltersLoaded = false;

  constructor(init?: Partial<State>) {
    Object.assign(this, init);
  }
}

interface Props extends RouteComponentProps {
  // vars from last load
  filters: {
    [key: string]: string | number;
  };
  page: number;
  sort: {
    sortBy: string;
    sortOrder: string;
  };

  // todo: type these payloads more aggressively after converting redux actions to TS
  storeSearch: (search: any) => any;
  storePage: (page: any) => any;
  storeSort: (sort: any) => any;
  resetStore: () => any;
}

interface Submission {
  submissionId: number;
  submissionStatus: string;
  buyerId: string;
  sellerInfo: string;
  item: string;
  expectedValue: number;
  classTier: string;
  cashMargin: number;
  disciplineId: number;
  created: string;
}

interface FetchDataResponse {
  submissions: Submission[];
  totalCount: number;
}

class CatchReleaseListView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = new State();
  }

  componentDidMount() {
    this.fetchBuyers();
    this.fetchStatuses();
    this.fetchDisciplines();
    this.fetchClassTiers();
  }

  setAllFiltersLoaded = () => {
    const allDataLoaded = [
      this.state.buyers,
      this.state.statuses,
      this.state.disciplines,
      this.state.classTiers,
    ].every((stateObj: any) => Object.keys(stateObj).length > 0);

    if (allDataLoaded) {
      this.setState({ allFiltersLoaded: true });
    }
  };

  fetchBuyers = async () => {
    const url = `${API_URL}/tradeup/syb/buyers`;
    const response: void | { data: Buyer[] } = await axios.get(url);

    if (response && response.data) {
      const buyers: NumberIndexedLookup = {};
      for (const b of response.data) {
        buyers[b.id] = b.name;
      }
      this.setState({ buyers }, this.setAllFiltersLoaded);
    }
  };

  fetchStatuses = async () => {
    const url = `${API_URL}/tradeup/statuses`;
    const response: void | { data: Status[] } = await axios.get(url);

    if (response && response.data) {
      const statuses: NumberIndexedLookup = {};
      for (const s of response.data) {
        statuses[s.id] = s.status;
      }
      this.setState({ statuses }, this.setAllFiltersLoaded);
    }
  };

  fetchDisciplines = async () => {
    const url = `${API_URL}/products/disciplines`;
    const response: void | { data: Discipline[] } = await axios.get(url);

    if (response && response.data) {
      const disciplines: NumberIndexedLookup = {};
      for (const d of response.data) {
        disciplines[d.id] = d.discipline;
      }
      this.setState({ disciplines }, this.setAllFiltersLoaded);
    }
  };

  fetchClassTiers = async () => {
    const url = `${API_URL}/tradeup/classTiers`;
    const response: void | { data: ClassTier[] } = await axios.get(url);

    if (response && response.data) {
      const classTiers: StringIndexedLookup = {};
      for (const t of response.data) {
        classTiers[t.tier] = t.tier;
      }
      this.setState({ classTiers }, this.setAllFiltersLoaded);
    }
  };

  formatAsNumber = (str: string) => {
    const parsed = parseInt(str, 10);
    return !parsed || Number.isNaN(parsed) ? null : parsed;
  };

  fetchData = async (args: any): Promise<FetchDataResponse> => {
    const response = await axios.get(`${API_URL}/tradeup/catchrelease/submissions`, {
      params: args,
    });

    if (response && response.data) {
      const submissions: Submission[] = response.data.submissions;
      const { totalCount } = response.data;

      return { submissions, totalCount };
    }
    return { submissions: [], totalCount: 0 };
  };

  handleRowClick = (e = {} as React.MouseEvent, rowData = {} as Submission) => {
    const { submissionId } = rowData;
    const url = `/tradeups/submission/${submissionId}`;
    if (e?.metaKey) {
      const newTab = window.open(url, '_blank');
      if (newTab) newTab.focus();
    } else {
      this.props.history.push(url);
    }
  };

  handleResetAll = () => {
    this.props.resetStore();
    // instead of messing with tableRefs we can force a from-scratch rerender
    // by changing the key value of the table
    this.setState({ tableKey: this.state.tableKey + 1 });
  };

  defaultColumnProps = {
    emptyValue: <span style={{ color: 'silver' }}>--</span>,
    editable: 'never',
    align: 'left',
  } as Partial<Column<Submission>>;

  getTableColumns(): Partial<Column<Submission>>[] {
    const { filters, sort } = this.props;
    let columns: Partial<Column<Submission>>[] = [
      {
        ...this.defaultColumnProps,
        type: 'numeric',
        title: 'ID',
        field: 'submissionId',
      },
      {
        ...this.defaultColumnProps,
        title: 'status',
        field: 'submissionStatusId',
        lookup: this.state.statuses,
        filterComponent: FilterSelect,
      },
      {
        ...this.defaultColumnProps,
        title: 'buyer',
        field: 'buyerId',
        lookup: this.state.buyers,
        filterComponent: FilterSelect,
      },
      {
        ...this.defaultColumnProps,
        title: 'seller',
        field: 'sellerInfo',
      },
      {
        ...this.defaultColumnProps,
        title: 'item',
        field: 'item',
      },
      {
        ...this.defaultColumnProps,
        title: 'discipline',
        field: 'disciplineId',
        lookup: this.state.disciplines,
        filterComponent: FilterMultiSelect,
      },
      {
        ...this.defaultColumnProps,
        title: 'tier',
        field: 'classTier',
        lookup: this.state.classTiers,
        filterComponent: FilterSelect,
      },
      {
        ...this.defaultColumnProps,
        type: 'numeric',
        title: 'ESP',
        field: 'expectedValue',
        filtering: false,
      },
      {
        ...this.defaultColumnProps,
        type: 'numeric',
        title: 'margin',
        field: 'cashMargin',
        render: data => <span>{(data.cashMargin * 100).toFixed(1)}%</span>,
      },
      {
        ...this.defaultColumnProps,
        type: 'date',
        title: 'created',
        field: 'created',
        filtering: false,
      },
    ];

    if (Object.keys(filters).length) {
      columns = columns.map((c: Partial<Column<Submission>>) => {
        if (!c.field) return c;
        // note: change of library from material-table to @material-table/core
        // caused c.field to potentially be an array, according to the type definition.
        const field = Array.isArray(c.field) ? c.field[0] : c.field;

        const rehydratedCol = c;
        if (filters[field]) {
          rehydratedCol.defaultFilter = filters[field];
        }
        if (sort.sortOrder && c.field === sort.sortBy) {
          rehydratedCol.defaultSort = sort.sortOrder as 'asc' | 'desc';
        }
        return rehydratedCol;
      });
    }

    return columns;
  }

  getTableOptions() {
    const options: Options<Submission> = {
      filtering: true,
      search: false,
      debounceInterval: 1000,
      pageSize: 25,
      pageSizeOptions: [25],
      emptyRowsWhenPaging: false,
      initialPage: this.props.page ?? 0,
      doubleHorizontalScroll: false,
    };

    return options;
  }

  render() {
    return (
      <div style={{ maxWidth: '90%', margin: '0 auto', position: 'relative' }}>
        <button
          onClick={this.handleResetAll}
          className="classy-button"
          style={{
            position: 'absolute',
            maxWidth: '8em',
            top: '18px',
            right: '24px', // 24px comes from material-table styles
            zIndex: 11,
          }}
        >
          Reset All
        </button>
        {this.state.allFiltersLoaded && (
          <MaterialTable
            key={this.state.tableKey}
            columns={this.getTableColumns()}
            title="Catch & Release Submissions"
            onRowClick={this.handleRowClick}
            options={this.getTableOptions()}
            data={(query: Query<Submission>) =>
              new Promise<QueryResult<Submission>>(async (resolve, reject) => {
                try {
                  const mappedFilters: any = {};
                  for (const { column, value } of query.filters) {
                    if (column.field) {
                      const field = Array.isArray(column.field) ? column.field[0] : column.field;
                      mappedFilters[field] = value;
                    }
                  }

                  const sortBy = query?.orderBy?.field ?? null;
                  const sortOrder = query?.orderDirection ?? null;

                  this.props.storeSearch(mappedFilters);
                  this.props.storePage(query.page);
                  this.props.storeSort({ sortBy, sortOrder });

                  const result: FetchDataResponse = await this.fetchData({
                    ...mappedFilters,
                    page: query.page,
                    sortBy,
                    sortOrder,
                  });

                  resolve({
                    data: result.submissions,
                    page: query.page,
                    totalCount: result.totalCount,
                  });
                } catch (error) {
                  reject(error);
                }
              })
            }
          />
        )}
      </div>
    );
  }
}

// todo: type these map* payloads more aggressively after converting redux actions to TS
const mapStateToProps = (state: any) => ({
  filters: state.catchReleaseList.filters,
  sort: state.catchReleaseList.sort,
  page: state.catchReleaseList.page,
});

const mapDispatchToProps = (dispatch: (action: any) => {}) => ({
  storeSearch: (search: any) => dispatch(getCatchReleaseFilters(search)),
  storePage: (page: any) => dispatch(getCatchReleasePage(page)),
  storeSort: (sort: any) => dispatch(getCatchReleaseSort(sort)),
  resetStore: () => dispatch(resetCatchReleaseStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CatchReleaseListView));
