import { GET_PRODUCT_FILTERS, GET_PRODUCT_PAGE, GET_PRODUCT_SORT } from '../actions/types';

export default (
  state = {
    filters: {
      searchTerm: '',
      classification: '',
      excludedTerm: '',
      products: [],
      productCategoryId: '',
    },
    sort: [
      {
        id: 'created',
        desc: true,
        page: 0,
      },
    ],
    page: 0,
  },
  action,
) => {
  switch (action.type) {
    case GET_PRODUCT_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case GET_PRODUCT_SORT: {
      return {
        ...state,
        sort: action.payload,
      };
    }
    case GET_PRODUCT_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    default:
      return state;
  }
};
