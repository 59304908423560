import React from 'react';
import PropTypes from 'prop-types';
import { FormatMoney, IsEven } from '../../../utils/helper';

const DepreciationTable = ({ title, data, currentValue: { month } }) => (
  <table className="depreciation-table">
    <caption>{title}</caption>
    <tbody>
      <tr>
        <th>Month</th>
        <th>Value</th>
      </tr>
      {data.map((val, i) => (
        <tr key={val[0]} style={IsEven(i) ? { background: '#fff' } : { background: '#e2e2e2' }}>
          <td style={val[0] === month ? { background: '#00c160' } : {}}>{val[0]}</td>
          <td>${FormatMoney(val[1])}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default DepreciationTable;

DepreciationTable.propTypes = {
  title: PropTypes.string.isRequired,
  currentValue: PropTypes.shape({
    month: PropTypes.number,
  }).isRequired,
};
