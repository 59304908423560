import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button, Container, Link, Paper, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Loader from '../../../components/loader';
import ProcessDialog from './process_dialog';
import AutoProcessDialog from './auto_process_dialog';
import EditDialog from './return_edit_dialog';

import axios from '../../../utils/axios';
import { API_URL } from '../../../constants';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: '#d3d3d3',
    },
  },
  root: {
    marginTop: '30px',
    marginBottom: '30px',
    padding: '30px',
  },
  paper: {
    marginTop: '30px',
    marginBottom: '30px',
    padding: '30px',
  },
  section: {
    padding: '25px',
  },
  button: {
    margin: theme.spacing(1),
  },
});

const baseRows = [
  {
    id: 'id',
    accessor: 'id',
    label: 'ID',
  },
  {
    id: 'order_id',
    accessor: 'order_id',
    label: 'Order ID',
  },
  {
    id: 'source',
    accessor: 'source',
    label: 'Source',
  },
  {
    id: 'sku',
    accessor: 'sku',
    label: 'SKU',
  },
  {
    id: 'status',
    accessor: 'status',
    label: 'Status',
  },
  {
    id: 'reason',
    accessor: 'reason',
    label: 'Reason',
  },
  {
    id: 'blame',
    accessor: 'blame',
    label: 'Blame',
  },
  {
    id: 'title',
    accessor: 'title',
    label: 'Title',
  },
  {
    id: 'notes',
    accessor: 'notes',
    label: 'Notes',
  },
  {
    id: 'created_at',
    accessor: obj => {
      return obj && obj.created_at ? new Date(obj.created_at).toLocaleString() : '';
    },
    label: 'Created',
  },
  {
    id: 'unit_price',
    accessor: obj => `$${parseFloat(obj.unit_price).toFixed(2)}`,
    label: 'Unit Price',
  },
  {
    id: 'total_shipping_price',
    accessor: obj => `$${parseFloat(obj.total_shipping_price).toFixed(2)}`,
    label: 'Total Shipping',
  },
  {
    id: 'order_total',
    accessor: obj => `$${parseFloat(obj.order_total).toFixed(2)}`,
    label: 'Order Total',
  },
  {
    id: 'refunded_amount',
    accessor: obj => `$${parseFloat(obj.refunded_amount).toFixed(2)}`,
    label: 'Refunded Amount',
  },
];

const shopifyRows = [
  {
    id: 'refund_type',
    accessor: obj => (obj && obj.refund_type ? obj.refund_type.toString() : 'Not Available'),
    label: 'Refund Type',
  },
  {
    id: 'gift_card',
    accessor: obj => (obj && obj.gift_card ? obj.gift_card.toString() : 'Not Available'),
    label: 'Gift Card',
  },
];

class Return extends Component {
  constructor(props) {
    super(props);
    this.state = {
      return: {},
      reasons: [],
      blames: [],
      loading: false,
      processDialogOpen: false,
      autoProcessDialogOpen: false,
      editDialogOpen: false,
      processDialogForm: {
        refundedAmount: 0.0,
        error: '',
      },
      autoProcessDialogForm: {
        refundedAmount: 0.0,
        refundType: '',
        error: '',
      },
      autoProcessShopifyData: {
        transactionInfo: {},
        itemInfo: {},
        shippingInfo: {},
      },
      editDialogForm: {
        reasonId: '',
        blameId: '',
        notes: '',
        error: '',
      },
    };
  }

  fetchData = async () => {
    const { id } = this.props.match.params;
    const result = await axios.get(`${API_URL}/service/returns/${id}`);
    this.setState(prevState => ({
      return: result.data,
      editDialogForm: {
        ...prevState.editDialogForm,
        reasonId: result.data.reason_id,
        blameId: result.data.blame_id,
        notes: result.data.notes,
      },
    }));
  };

  fetchExtras = async () => {
    const reasonsRes = await axios.get(`${API_URL}/service/returnReasons`);
    const blamesRes = await axios.get(`${API_URL}/service/returnBlames`);
    this.setState({
      reasons: reasonsRes.data,
      blames: blamesRes.data,
    });
  };

  async componentDidMount() {
    this.setState({ loading: true });
    this.fetchData();
    this.setState({ loading: false });
    this.fetchExtras();
  }

  handleDialogChange = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      processDialogForm: {
        ...this.state.processDialogForm,
        [e.target.name]: value,
      },
    });
  };

  handleAutoDialogChange = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      autoProcessDialogForm: {
        ...this.state.autoProcessDialogForm,
        [e.target.name]: value,
      },
    });
  };

  handleEditDialogChange = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      editDialogForm: {
        ...this.state.editDialogForm,
        [e.target.name]: value,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({
      processDialogOpen: false,
      autoProcessDialogOpen: false,
      editDialogOpen: false,
      processDialogForm: {
        refundedAmount: 0.0,
        error: '',
      },
      autoProcessDialogForm: {
        refundedAmount: 0.0,
        refundType: '',
        error: '',
      },
      autoProcessShopifyData: {
        transactionInfo: {},
        itemInfo: {},
        shippingInfo: {},
      },
      editDialogForm: {
        reasonId: '',
        blameId: '',
        notes: '',
        error: '',
      },
    });
  };

  handleProcessDialogSubmit = async () => {
    this.setState({ loading: true });
    try {
      const { id } = this.props.match.params;
      await axios.post(`${API_URL}/service/completeReturn/${id}`, {
        status: 'processed',
        refundAmount: this.state.processDialogForm.refundedAmount,
        type: 'manual',
      });
      await this.fetchData();
      this.handleDialogClose();
    } catch (error) {
      this.setState(prevState => ({
        processDialogForm: { ...prevState.processDialogForm, error },
      }));
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleAutoProcessDialogSubmit = async () => {
    this.setState({ loading: true });
    try {
      const { id } = this.props.match.params;
      await axios.post(`${API_URL}/service/completeReturn/${id}`, {
        status: 'processed',
        type: 'auto',
        ...this.state.autoProcessDialogForm,
      });
      await this.fetchData();
      this.handleDialogClose();
    } catch (error) {
      if (error.response && error.response.data) {
        this.setState(prevState => ({
          autoProcessDialogForm: {
            ...prevState.autoProcessDialogForm,
            error: error.response.data,
          },
        }));
      }
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleEditSubmit = async () => {
    this.setState({ loading: true });
    try {
      const { id } = this.props.match.params;
      await axios.put(`${API_URL}/service/returns/${id}`, {
        ...this.state.editDialogForm,
      });
      await this.fetchData();
      this.handleDialogClose();
    } catch (error) {
      if (error.response && error.response.data) {
        this.setState(prevState => ({
          editDialogForm: {
            ...prevState.editDialogForm,
            error: error.response.data,
          },
        }));
      }
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleAutoProcessDialogOpen = async () => {
    this.setState({ loading: true });
    const { id } = this.props.match.params;
    const result = await axios.get(`${API_URL}/service/shopifyReturn/${id}`);
    const { refund_line_items, shipping, transactions } = result.data;
    this.setState({
      autoProcessShopifyData: {
        transactionInfo: transactions[0],
        itemInfo: refund_line_items[0],
        shippingInfo: shipping,
      },
      autoProcessDialogOpen: true,
      loading: false,
    });
  };

  handleEditDialogOpen = async () => {
    this.setState(prevState => ({
      editDialogForm: {
        ...prevState.editDialogForm,
        reasonId: this.state.return.reason_id,
        blameId: this.state.return.blame_id,
        notes: this.state.return.notes,
      },
      editDialogOpen: true,
    }));
  };

  renderShopifyAction = classes => {
    return (
      <Paper className="react-table">
        <Grid
          container
          spacing={0}
          className={classes.section}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {shopifyRows.map(k => {
            return (
              <Grid container spacing={2} className={classes.tableRow} key={k.id}>
                <Grid item xs={5}>
                  <Typography>
                    <strong>{k.label}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography>
                    {typeof k.accessor === 'string' || typeof k.accessor === 'undefined'
                      ? `${this.state.return[k.id] || (this.state.loading ? 'Loading...' : '')}`
                      : `${k.accessor(this.state.return) ||
                          (this.state.loading ? 'Loading...' : '')}`}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    );
  };

  renderShipping = classes => {
    return (
      <Paper className="react-table">
        <Grid
          container
          spacing={0}
          className={classes.section}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container spacing={2} className={classes.tableRow}>
            <Grid item xs={5}>
              <Typography>
                <strong>Label</strong>
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Link
                href={this.state.return.label_url}
                color="primary"
                underline="hover"
                target="_blank"
                rel="noopener"
              >
                {this.state.return.tracking_number}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  renderActionButtons = (classes, isShopify) => {
    return (
      <div className="react-table">
        <Grid container spacing={2} className={classes.tableRow} justifyContent="space-between">
          <Grid item>
            <Button
              onClick={this.handleEditDialogOpen}
              variant="contained"
              className={classes.button}
              color="primary"
            >
              Edit
            </Button>
          </Grid>
          {this.state.return.status !== 'processed' && (
            <Grid item>
              <Button
                onClick={() => this.setState({ processDialogOpen: true })}
                variant="contained"
                className={classes.button}
                color="primary"
              >
                Process
              </Button>
              {isShopify && (
                <Button
                  onClick={this.handleAutoProcessDialogOpen}
                  variant="contained"
                  className={classes.button}
                  color="secondary"
                >
                  Auto-Process
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Container maxWidth="lg" className={classes.root}>
        {this.renderActionButtons(classes, this.state.return.source === 'shopify')}
        <Paper className="react-table">
          <Grid
            container
            spacing={0}
            className={classes.section}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            {baseRows.map(k => {
              return (
                <Grid container spacing={2} className={classes.tableRow} key={k.id}>
                  <Grid item xs={5}>
                    <Typography>
                      <strong>{k.label}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>
                      {typeof k.accessor === 'string' || typeof k.accessor === 'undefined'
                        ? `${this.state.return[k.id] || (this.state.loading ? 'Loading...' : '')}`
                        : `${k.accessor(this.state.return) ||
                            (this.state.loading ? 'Loading...' : '')}`}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
        {this.state.return && this.state.return.tracking_number && this.renderShipping(classes)}
        {this.renderShopifyAction(classes)}
        <Loader loading={this.state.loading} />
        <ProcessDialog
          open={this.state.processDialogOpen}
          onClose={this.handleDialogClose}
          onChange={this.handleDialogChange}
          onSubmit={this.handleProcessDialogSubmit}
          formData={this.state.processDialogForm}
        />
        <AutoProcessDialog
          open={this.state.autoProcessDialogOpen}
          onClose={this.handleDialogClose}
          onChange={this.handleAutoDialogChange}
          onSubmit={this.handleAutoProcessDialogSubmit}
          formData={this.state.autoProcessDialogForm}
          shopifyCalculation={this.state.autoProcessShopifyData}
        />
        <EditDialog
          open={this.state.editDialogOpen}
          onClose={this.handleDialogClose}
          onChange={this.handleEditDialogChange}
          onSubmit={this.handleEditSubmit}
          formData={this.state.editDialogForm}
          reasons={this.state.reasons}
          blames={this.state.blames}
        />
      </Container>
    );
  }
}

export default withStyles(styles)(withRouter(Return));
