import React from 'react';
import { useKeyPress } from '../../utils/hooks/useKeyPress';

interface Props {
  displayModal: boolean;
  onClose(): void;
  imageSrc?: string;
}

const ImageModal: React.FC<Props> = ({ imageSrc, displayModal, onClose }) => {
  useKeyPress({ activationKey: 'escape' }, onClose);

  if (displayModal) {
    return (
      <div className="backdrop threesix-backdrop">
        <span className="threesix-image-wrap">
          <button className="close-modal threesix-close" onClick={onClose} />
          <img alt="bike detail" className="modal-image threesix-image" src={imageSrc} />
        </span>
      </div>
    );
  }

  return null;
};

export default ImageModal;
