import { ErrorBus } from '../../../../_shared';
import { useState, useEffect, useCallback } from 'react';
import { getInventoryRecords } from '../../../../../services/service/services';

export interface InventoryRecord {
  sku: string;
  binId: number;
  binCode: string;
  locationId?: number;
  locationCode?: string;
  quantity: number;
}

export interface UseInventoryRecords {
  inventoryRecords?: InventoryRecord[];
  refetchInventoryRecords: () => void;
}

interface UseInventoryRecordsProps {
  sku?: string;
  errorBus: ErrorBus;
}

const busKey = 'useInventoryRecords';

const useInventoryRecords = ({ sku, errorBus }: UseInventoryRecordsProps): UseInventoryRecords => {
  const [inventoryRecords, setInventoryRecords] = useState<
    UseInventoryRecords['inventoryRecords']
  >();
  const { setError } = errorBus;

  const refetchInventoryRecords = useCallback(() => {
    setError(busKey, undefined);
    if (sku) {
      getInventoryRecords(sku, {
        onSuccess: setInventoryRecords,
        catchFailure: error => setError(busKey, error),
      });
    }
  }, [sku, setError]);

  useEffect(refetchInventoryRecords, [refetchInventoryRecords]);

  return {
    inventoryRecords,
    refetchInventoryRecords,
  };
};

export { useInventoryRecords };
