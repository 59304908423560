import React from 'react';
import { NotificationManager } from 'react-notifications';
import { withRouter, NavLink, RouteComponentProps } from 'react-router-dom';
import { API_URL } from '../../constants';
import axios from '../../utils/axios';
import { FormatMoney } from '../../utils/helper';
import './paypal.css';
import Loader from '../../components/loader';
import { Button } from '../../components/library/Button';
import { LoaderOverlay } from '../../components/library/LoaderOverlay';
import { materialTheme } from '../../theme';
import { StripedDataGrid } from '../../styledComponents/datagrid';
import { GridColDef } from '@mui/x-data-grid';
import { PageWrapper } from '../../styledComponents/wrappers';

interface Bill {
  internalId: number;
  createdDate: string;
  email: string;
  note: string;
  amount: string;
  firstName: string;
  lastName: string;
  poNumber: string;
  paymentType: string;
  checked: boolean;
}

export interface PayPalPayoutItem {
  internalId: number;
  poNumber: string;
  name: string;
  nsBillPaymentId: number | null; // id of the BillPayment created in Netsuite
  ppTransactionId: string | null; // id of the Paypal payout for a single Bill
  ppTransactionStatus: string | null; // status of PayPal payout
  completed: boolean; // indicates the transactionId was stored on the NSBillPayment
}
export interface PayPalPayout {
  batchId: string | null; // id of the PayPal batch payout
  batchStatus: string | null; // status of the Paypal batch payout
  payouts: PayPalPayoutItem[]; // internalId is the Map key
  errors: string[];
}

interface PayPalState {
  awaitingPayments: Bill[];
  payout: PayPalPayout | null;
  loading: boolean; // initial fetch of payouts
  processing: boolean; // attempting selected payouts
  success: boolean;
  error: boolean;
  allChecked: boolean;
}

const displayError = (v: string) => (
  <span
    style={{
      color: materialTheme.palette.error.main,
    }}
  >
    {v}
  </span>
);

class PayPal extends React.Component<RouteComponentProps, PayPalState> {
  constructor(props: any) {
    super(props);
    this.state = {
      awaitingPayments: [],
      payout: null,
      loading: false,
      processing: false,
      success: false,
      error: false,
      allChecked: false,
    };
  }

  componentDidMount(): void {
    this.fetchAwaitingPayments();
  }

  fetchAwaitingPayments = async (): Promise<void> => {
    this.setState({ loading: true });
    const url = `${API_URL}/finance/paypalPos`;
    const response = await axios.get(url);
    const awaitingPayments = response ? response.data : null;
    this.setState({
      awaitingPayments: awaitingPayments.map((ap: Bill) => ({
        ...ap,
        checked: false,
      })),
      loading: false,
    });
  };

  submitPayments = async (): Promise<void> => {
    this.setState({ processing: true });
    const url = `${API_URL}/finance/paypalPayout`;
    const { awaitingPayments } = this.state;
    try {
      const selected = awaitingPayments.filter((b: Bill) => b.checked);
      const response = await axios.post(url, selected);
      if (response && response.data.errors.length) {
        NotificationManager.error(response.data.errors.join('|'));
      }
      if (response && response.status < 300) {
        console.dir(response.data.payouts);
        const payout: PayPalPayout = {
          batchId: response.data.batchId,
          batchStatus: response.data.batchStatus,
          payouts: response.data.payouts,
          errors: response.data.errors,
        };

        if (!payout) {
          throw Error('missing payout in reponse data');
        }

        if (payout.batchStatus === 'SUCCESS') {
          this.setState({ success: true });
        } else {
          this.setState({ success: false });
        }

        this.setState(state => ({
          ...state,
          payout,
        }));

        this.fetchAwaitingPayments();
      } else {
        this.setState({ error: true });
      }
    } catch (e) {
      this.setState({ error: true });
      if (e.response) {
        if (e.response.status > 499) {
          NotificationManager.error('There were some errors.');
        } else {
          NotificationManager.error(e.response.data.message);
        }
      }
    } finally {
      this.setState({ processing: false });
    }
  };

  handleCheckChange = (id: number): void => {
    const nAwaitingPayments = this.state.awaitingPayments;
    const node = nAwaitingPayments.find((b: Bill) => b.internalId === id);
    const checked = nAwaitingPayments.filter((b: Bill) => b.checked).length;
    if (node) {
      node.checked = !node.checked;
      if (node.checked && checked >= 10) {
        NotificationManager.info('Only up to 10 selections allowed');
      } else {
        this.setState(prevState => ({
          ...prevState,
          awaitingPayments: [...nAwaitingPayments],
        }));
      }
    }
  };

  handleCheckAllChange = (): void => {
    const currAwaiting = this.state.awaitingPayments;
    let counter = 0;
    const nAwaitingPayments = currAwaiting.map((ap: Bill) => {
      return {
        ...ap,
        checked: counter++ < 10 ? !this.state.allChecked : false,
      };
    });
    if (counter > 10 && !this.state.allChecked) {
      NotificationManager.info('Only up to 10 selections allowed');
    }
    this.setState(prevState => ({
      ...prevState,
      awaitingPayments: nAwaitingPayments,
      allChecked: !prevState.allChecked,
    }));
  };

  columns: GridColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 2,
    },
    {
      headerName: 'PO Number',
      field: 'poNumber',
      flex: 1,
    },
    {
      headerName: 'BillPayment ID',
      field: 'nsBillPaymentId',
      renderCell: ({ value }) => (value !== null ? value : displayError('Failed')),
      flex: 1,
    },
    {
      headerName: 'Payout Status',
      field: 'ppTransactionStatus',
      renderCell: ({ value }) => (value === 'SUCCESS' ? value : displayError(value || 'N/A')),
      flex: 1,
    },
    {
      headerName: 'Transaction ID',
      field: 'ppTransactionId',
      flex: 2,
    },
    {
      headerName: 'Complete',
      field: 'completed',
      renderCell: ({ value }) => (value ? 'Success' : displayError('Failed')),
    },
  ];

  render(): React.ReactElement {
    const { awaitingPayments, loading, allChecked } = this.state;
    return (
      <PageWrapper style={{ overflow: 'hidden' }} className="paypal-wrap">
        <LoaderOverlay loading={this.state.processing} />
        <div className="button-container">
          <Button
            onClick={this.submitPayments}
            variant="contained"
            ordinality="primary"
            disabled={loading || !awaitingPayments.filter((b: Bill) => b.checked).length}
          >
            Submit Payments!!!
          </Button>
          <NavLink className="nav-item navlink-button" to="/paypal/paid">
            View Completed Payments
          </NavLink>
        </div>

        {this.state.payout && this.state?.payout?.payouts?.length > 0 && (
          <div style={{ marginTop: '40px', marginBottom: '60px' }}>
            <div style={{ fontSize: '24px', textAlign: 'center' }}>Payout Status</div>
            <StripedDataGrid
              rows={this.state.payout.payouts}
              columns={this.columns}
              style={{}}
              loading={this.state.loading}
              getRowId={row => `${row.ppTransactionId}-${Math.floor(Math.random() * 10000)}`}
            />
          </div>
        )}

        <table className="react-table paypal" style={{ border: '1px solid' }}>
          <caption>
            Awaiting PayPal Payments
            <Loader loading={loading} />
          </caption>
          <tbody>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={allChecked}
                  style={{ transform: 'scale(1.5)', marginRight: '12px' }}
                  onChange={(): void => this.handleCheckAllChange()}
                />
              </th>
              <th>PO Number</th>
              <th>Name</th>
              <th>Email</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Note</th>
            </tr>
            {awaitingPayments.map((v: Bill) => (
              <tr key={v.internalId}>
                <td style={{ textAlign: 'center' }}>
                  <input
                    type="checkbox"
                    checked={v.checked}
                    style={{ transform: 'scale(1.5)', marginRight: '12px' }}
                    onChange={(): void => this.handleCheckChange(v.internalId)}
                  />
                </td>
                <td>{v.poNumber}</td>
                <td>
                  {v.firstName} {v.lastName}
                </td>
                <td>{v.email}</td>
                <td>{v.paymentType}</td>
                <td>${FormatMoney(v.amount)}</td>
                <td>{v.note}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </PageWrapper>
    );
  }
}

export default withRouter(PayPal);
