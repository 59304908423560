import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Cookies from 'universal-cookie';
import ReCAPTCHA from 'react-google-recaptcha';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { API_URL } from '../constants';
import * as actions from '../actions';
import eye from '../images/eye.svg';
import tpcLogo from '../images/new-logo.svg';

import ForgotPasswordModal from '../components/modals/forgotPassModal';
import '../styles/login.css';

const recaptchaRef = React.createRef();
const forgotPassRecaptchaRef = React.createRef();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      secret: '',
      captcha: null,
      modalResetPasswordToggled: false,
      forgotPassError: false,
      forgotPassErrorMsg: '',
      forgotPassLoading: false,
      forgotPassCaptcha: null,
      error: '',
      viewPass: false,
    };

    this.cookies = new Cookies();
  }

  async componentDidMount() {
    const { ssoToken } = this.props.match.params;
    if (ssoToken) {
      try {
        const res = await axios.post(`${API_URL}/account/refreshToken`, null, {
          headers: {
            Authorization: ssoToken ? `Bearer ${ssoToken}` : '',
          },
        });
        const { user, token } = res.data;
        localStorage.setItem('userPermissions', JSON.stringify(user.permissions));
        this.cookies.remove('token', { path: '/' });
        this.cookies.set('token', token, {
          path: '/',
          expires: new Date(Date.now() + 14400000),
        });

        this.props.fetchAuthenticated(token);
      } catch (error) {
        console.error(error);
      }
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validateFieldsNotEmpty = fields => {
    for (let i = 0; i < fields.length; i++) {
      const fieldName = fields[i];
      if (!this.state[fieldName]) {
        return false;
      }
    }
    return true;
  };

  handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      error: '',
    });

    if (!this.validateFieldsNotEmpty(['email', 'secret', 'captcha'])) {
      this.setState({
        error: 'Please fill in all fields and captcha',
      });
      return;
    }

    const url = `${API_URL}/auth/login`;
    const data = {
      email: this.state.email,
      password: this.state.secret,
      captcha: this.state.captcha,
    };

    try {
      const res = await axios.post(url, data);
      const { token } = res.data;
      if (token) {
        localStorage.setItem('userPermissions', JSON.stringify(res.data.user.permissions));
        this.cookies.remove('token', { path: '/' });
        this.cookies.set('token', token, {
          path: '/',
          expires: new Date(Date.now() + 14400000),
        });

        this.props.fetchAuthenticated(token);
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          this.setState({
            error: 'Invalid credentials',
          });
        }
        if (e.response.status === 500) {
          this.setState({
            error: 'Something went wrong. If you keep seeing the problem please contact TPC',
          });
        }
      }
      recaptchaRef.current.reset();
    }
  };

  togglePasswordResetModal = e => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      modalResetPasswordToggled: !this.state.modalResetPasswordToggled,
    });
  };

  submitForgotPassword = async formState => {
    try {
      this.setState({
        forgotPassError: false,
        forgotPassErrorMsg: '',
        forgotPassLoading: true,
      });

      await axios.post(`${API_URL}/auth/passwordReset`, {
        email: formState.email,
        captcha: this.state.forgotPassCaptcha,
      });
      this.togglePasswordResetModal();
    } catch (e) {
      if (e.response && e.response.status === 403) {
        this.setState({ forgotPassErrorMsg: e.response.data });
      }
      this.setState({ forgotPassError: true });
      forgotPassRecaptchaRef.current.reset();
    }
    this.setState({ forgotPassLoading: false });
  };

  handleCaptchaChange = (value, name) => {
    this.setState({ [name]: value });
  };

  handleSso = e => {
    e.preventDefault();
    window.location.replace(`${API_URL}/auth/sso`);
  };

  togglePass = e => {
    e.preventDefault();
    this.setState({ viewPass: !this.state.viewPass });
  };

  render() {
    const {
      error,
      modalResetPasswordToggled,
      forgotPassLoading,
      forgotPassError,
      forgotPassErrorMsg,
      viewPass,
    } = this.state;
    const { location } = this.props;

    if (this.props.isAuthenticated) {
      return <Redirect to={location.state?.from?.pathname ?? '/'} />;
    }
    return (
      <div className="container">
        <div className="login-header">
          <img alt="The Pro's Closet logo" src={tpcLogo} className="logo-img" />
        </div>
        <div className="panel-heading">
          <p className="welcome-message">Log into your Drivetrain account</p>
        </div>
        <div className="panel-body">
          <form style={{ textAlign: 'center' }} acceptCharset="UTF-8" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input
                className="form-control"
                placeholder="email"
                name="email"
                type="text"
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group password-input-wrap">
              <input
                className="form-control"
                placeholder="Password"
                name="secret"
                type={viewPass ? 'text' : 'password'}
                onChange={this.handleChange}
              />
              <img src={eye} onClick={this.togglePass} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '300px',
                margin: ' 0 auto',
              }}
            >
              <input className="classy-button" type="submit" value="LOGIN" />
              <div className="form-group">
                <button className="classy-button" type="button" onClick={this.handleSso}>
                  SSO LOGIN
                </button>
              </div>
            </div>
            {error && <p className="login-error">{error}</p>}
            <ReCAPTCHA
              ref={recaptchaRef}
              className="captcha-display"
              sitekey="6LdzOnMUAAAAACcwKVTKy1nUEzRrIK7luGNXRm9R"
              onChange={value => this.handleCaptchaChange(value, 'captcha')}
            />
            <div className="forgot">
              <button
                tabIndex={-1}
                className="small-control"
                onClick={this.togglePasswordResetModal}
              >
                Forgot Password?
              </button>
            </div>
          </form>
          {modalResetPasswordToggled && (
            <ForgotPasswordModal
              toggleModal={this.togglePasswordResetModal}
              formSuccess={this.submitForgotPassword}
              loading={forgotPassLoading}
              error={forgotPassError}
              errorMsg={forgotPassErrorMsg}
              onCaptchaChange={this.handleCaptchaChange}
              captchaRef={forgotPassRecaptchaRef}
            />
          )}
        </div>
      </div>
    );
  }
}

Login.defaultProps = {
  isAuthenticated: false,
};

Login.propTypes = {
  isAuthenticated: PropTypes.bool,
  fetchAuthenticated: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ token: PropTypes.string }),
  }).isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth ? state.auth.isAuthenticated : false,
});

export default withRouter(connect(mapStateToProps, actions)(Login));
