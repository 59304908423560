import { useKeyPress } from '../../../../../../utils/hooks/useKeyPress';
import { FailRemedies } from '../../../_shared/types';

// React does not allow conditional hooks. Creating these keypress listeners as a component allows the component to be conditionally called.
export const PointRemedyKeyPressListeners: React.FC<{
  handleRemedyChange: (id: number, pointId: number) => void;
  activePointId: number;
}> = ({ handleRemedyChange, activePointId }) => {
  useKeyPress({ activationKey: 's' }, () =>
    handleRemedyChange(FailRemedies.service, activePointId),
  );
  useKeyPress({ activationKey: 'r' }, () =>
    handleRemedyChange(FailRemedies.replace, activePointId),
  );
  useKeyPress({ activationKey: 'n' }, () => handleRemedyChange(FailRemedies.na, activePointId));

  return null;
};
