import React, { useEffect, useContext, useState, useMemo } from 'react';
import { Formik, FormikProps } from 'formik';
import { FormikIntakeState } from '../../../_shared/types';
import { serviceTicketContext } from '../../../_shared';
import { API_URL } from '../../../../../../constants';
import axios from '../../../../../../utils/axios';

import PrintIcon from '@material-ui/icons/Print';
import {
  Box,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Typography,
  Tab,
} from '@material-ui/core';
import { BikeDetails } from './BikeDetails';
import { Skeleton } from '@material-ui/lab';
import { useInspectionProduct } from '../../ServiceTicketHeader/useInspectionProduct';
import Barcode from '../../../../../../components/barcode';
import ImageCarousel from '../../../../../../components/library/ImageCarousel/ImageCarousel';
import { pageStateContext } from '../../../../../_shared';
import { ModuleWrapper } from '../../styled';
import { Button } from '../../../../../../components/library';
import { NotesTextarea } from '../NotesTextarea';
import { Image } from '../../../../../../services/catalog/types';
import sanitizeHtml from 'sanitize-html';

export const INSPECTION_DETAIL_ROUTE = '/service/inspections/:id';
interface IntakeModuleProps {
  isBikeStolen: boolean;
  isSerialNumberVerified: boolean;
  setIsBikeStolen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSerialNumberVerified: React.Dispatch<React.SetStateAction<boolean>>;
  hasUnsavedData: boolean;
  handleSuccess(): void;
  handleDataChange(dirty: boolean): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    square: {
      borderRadius: 0,
    },
    subHeader: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.primary,
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    subHeaderNoNotes: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.primary,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    noPadding: {
      padding: 0,
    },
    verificationContainer: {
      display: 'flex',
      marginBottom: theme.spacing(2),
      padding: 0,
      borderRadius: theme.spacing(3),
      height: 'calc(100vh - 240px)',
      '@media (min-height:940px)': {
        height: 'calc(940px - 240px)',
      },
    },
    moduleWrapper: {
      padding: '3rem 4rem',
    },
  }),
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

export const IntakeModule = React.forwardRef<FormikProps<FormikIntakeState>, IntakeModuleProps>(
  (
    {
      isBikeStolen,
      setIsBikeStolen,
      hasUnsavedData,
      isSerialNumberVerified,
      setIsSerialNumberVerified,
      handleSuccess,
      handleDataChange,
    },
    ref,
  ) => {
    const {
      errorBus: { setError },
      loadingBus: { setLoading },
    } = useContext(pageStateContext);

    const { inspectionData } = useContext(serviceTicketContext);
    const { inspection, refreshInspectionData } = inspectionData;
    const { sybSubmission } = inspection?.itemProcurement || {};
    const { firstName, lastName, receivedTrackingNumbers, address, city, state, zip } =
      sybSubmission || {};
    const { images } = inspection?.itemProcurement?.sybSubmission?.items[0] ?? {};
    const [open, setOpen] = React.useState(false);
    const [carouselImages, setCarouselImages] = useState<any[]>([]);
    const [tabIndex, setTabIndex] = React.useState(0);

    const { product: inspectionProduct, error: inspectionProductError } = useInspectionProduct(
      inspection?.itemProcurement.productVariantId,
    );

    const initialIntakeValues: FormikIntakeState = useMemo(() => {
      if (!inspection) {
        return {
          inspectionSerialNumber: null,
          intakeNotes: null,
        };
      }
      return {
        inspectionSerialNumber: inspection.serialNumber,
        intakeNotes: inspection.intakeNotes,
      };
    }, [inspection]);

    useEffect(() => {
      if (images?.length) {
        setCarouselImages(images.map((img: Image) => img?.url ?? '') ?? []);
      }
    }, [images]);

    useEffect(() => setError('Product Title', inspectionProductError), [
      inspectionProductError,
      setError,
    ]);

    useEffect(() => {
      if (tabIndex === 0 && !sybSubmission?.internalNote && !!sybSubmission?.notes) {
        setTabIndex(1);
      } else if (
        tabIndex !== 0 &&
        sybSubmission?.notes?.length === 0 &&
        sybSubmission?.internalNote
      ) {
        setTabIndex(0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inspection, tabIndex]);

    const handleIntakeSave = async (values: FormikIntakeState) => {
      if (inspection) {
        // Verify if the user modified the serial number since the page loaded
        const hasSerialNumberChanged = values.inspectionSerialNumber !== inspection.serialNumber;

        // We only want to stamp the intake operator if the serial number has changed
        const hasUpdatedIntakeData = (hasUnsavedData && hasSerialNumberChanged) ?? false;

        const payload = {
          serialNumber: values?.inspectionSerialNumber,
          intakeNotes: values?.intakeNotes,
          statusId: inspection.statusId,
          hasUpdatedIntakeData,
        };

        try {
          setLoading('Intake Save', true);
          await axios.put(`${API_URL}/service/inspections/${inspection.id}`, payload);
          refreshInspectionData();
          handleSuccess();
        } catch (err) {
          setError('handleIntakeSave', new Error(err.response.data.message));
        } finally {
          setLoading('Intake Save', false);
        }
      }
    };

    const internalNotes = inspection?.notes;

    const classes = useStyles();

    return (
      <Formik<FormikIntakeState>
        innerRef={ref}
        initialValues={initialIntakeValues}
        enableReinitialize
        onSubmit={handleIntakeSave}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <ModuleWrapper className={classes.moduleWrapper}>
              <Box mb={8}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                  pb={1}
                  pt={2}
                  mt={2}
                  id="bike-details"
                >
                  <h3>Bike Verification</h3>
                  <Button
                    size="medium"
                    ordinality="secondary"
                    variant="contained"
                    onClick={() => setOpen(true)}
                  >
                    <>
                      Print Label &nbsp;
                      <PrintIcon />
                    </>
                  </Button>
                </Box>
                <Dialog
                  open={open}
                  onClose={() => setOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{'Print Additional Label'}</DialogTitle>
                  <DialogContent>
                    {inspection && (
                      <Barcode
                        text={inspection.sku}
                        title={`${inspectionProduct?.brand?.name ||
                          ''} ${inspectionProduct?.model || ''}`.trim()}
                      />
                    )}
                  </DialogContent>
                  <DialogActions></DialogActions>
                </Dialog>
                <Box className={classes.verificationContainer}>
                  <Box
                    display="flex"
                    height="100%"
                    width="100%"
                    borderRadius={3}
                    border="solid 1px #eee"
                    p={1}
                    position="relative"
                    zIndex={0}
                  >
                    {carouselImages?.length > 0 ? (
                      <ImageCarousel images={carouselImages} showArrows zoomable />
                    ) : (
                      <Skeleton variant="rect" width={'100%'} height={'100%'} />
                    )}
                  </Box>
                  <Box
                    borderRadius={3}
                    border="solid 1px #eee"
                    ml={2}
                    width="300px"
                    flexShrink={0}
                    overflow="auto"
                  >
                    <List dense className={classes.noPadding}>
                      <ListSubheader
                        className={internalNotes ? classes.subHeader : classes.subHeaderNoNotes}
                      >
                        Submission Details
                      </ListSubheader>
                      {sybSubmission && (
                        <>
                          <ListItem>
                            <ListItemText
                              primary={`${firstName} ${lastName}`}
                              secondary={`${address}, ${city}, ${state} ${zip}`}
                            />
                          </ListItem>
                          {!!receivedTrackingNumbers?.length && (
                            <>
                              <Divider />
                              <ListItem>
                                <ListItemText
                                  primary={receivedTrackingNumbers[0].trackingNumber}
                                  secondary="Tracking Number"
                                  style={{ whiteSpace: 'nowrap' }}
                                />
                              </ListItem>
                            </>
                          )}
                        </>
                      )}
                      {
                        <BikeDetails
                          isBikeStolen={isBikeStolen}
                          setIsBikeStolen={setIsBikeStolen}
                          isSerialNumberVerified={isSerialNumberVerified}
                          setIsSerialNumberVerified={setIsSerialNumberVerified}
                          handleDataChange={handleDataChange}
                          handleIntakeSave={handleIntakeSave}
                        />
                      }
                    </List>
                    {(!!sybSubmission?.notes || !!sybSubmission?.internalNote) && (
                      <>
                        <Tabs
                          value={tabIndex}
                          indicatorColor="primary"
                          textColor="primary"
                          onChange={(_e, newValue) => {
                            setTabIndex(newValue);
                          }}
                          className={classes.subHeader}
                          style={{
                            position: 'sticky',
                            borderTop: '0px',
                            top: -1,
                            bottom: -1,
                            zIndex: 1,
                          }}
                        >
                          {sybSubmission?.internalNote && (
                            <Tab
                              label="Internal Notes"
                              style={{ width: '50%', minWidth: 0, whiteSpace: 'nowrap' }}
                            />
                          )}
                          {!!sybSubmission?.notes?.length && (
                            <Tab
                              label="External Notes"
                              style={{ width: '50%', minWidth: 0, whiteSpace: 'nowrap' }}
                            />
                          )}
                        </Tabs>
                        <Box overflow="auto" position="relative">
                          <TabPanel value={tabIndex} index={0}>
                            <Typography variant="body2" gutterBottom>
                              {sybSubmission?.internalNote}
                            </Typography>
                          </TabPanel>
                          <TabPanel value={tabIndex} index={1}>
                            {sybSubmission?.notes?.map((note, index) => (
                              <Box key={index}>
                                <Typography variant="overline" gutterBottom>
                                  {new Date(note?.createdAt).toLocaleDateString()}
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizeHtml(note.entry),
                                    }}
                                  ></span>
                                </Typography>
                                <Box my={1}>
                                  <Divider />
                                </Box>
                              </Box>
                            ))}
                          </TabPanel>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
              <NotesTextarea
                value={values.intakeNotes}
                setValue={e => setFieldValue('intakeNotes', e.target.value)}
                isDisabled={
                  (isSerialNumberVerified && isBikeStolen) ||
                  (!isSerialNumberVerified && !inspection?.serialNumber)
                }
              />
            </ModuleWrapper>
          </form>
        )}
      </Formik>
    );
  },
);
