import React, { FC, useState } from 'react';
import { FormControl, TextField, FormHelperText } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
interface Props {
  id: string;
  errorOnBlur?: boolean;
  helperText?: string;
}
type InputProps = Props & TextFieldProps;
const TextInput: FC<InputProps> = ({ id, label, errorOnBlur, helperText, ...rest }) => {
  const [error, setError] = useState(false);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value || value == '0') {
      setError(true);
    } else setError(false);
  };
  //Todo: Generalize styling here for form controls with styled components
  return (
    <FormControl style={{ width: 275, margin: 5 }}>
      <label>{label}</label>
      <TextField
        style={{ backgroundColor: '#fff' }}
        {...rest}
        id={id}
        error={error}
        onBlur={errorOnBlur ? handleBlur : undefined}
        variant="outlined"
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default TextInput;
