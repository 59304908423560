import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
`;

type ButtonSectionProps = {
  onCancel?(): void;
  productId?: number;
};

const ButtonSection: React.FC<RouteComponentProps & ButtonSectionProps> = ({
  history,
  onCancel,
  productId,
}) => {
  const { resetForm, handleSubmit, dirty, isSubmitting } = useFormikContext();

  const onCancelClick = () => {
    resetForm();
    if (onCancel) {
      onCancel();
    } else {
      history.goBack();
    }
  };

  return (
    <Wrapper>
      <Button onClick={onCancelClick} variant="contained">
        CANCEL
      </Button>
      {productId && (
        <Button component={Link} variant="contained" to={`/catalog/products/${productId}`}>
          View Product
        </Button>
      )}
      <Button
        type="submit"
        disabled={(!dirty && !!productId) || isSubmitting}
        onClick={() => handleSubmit()}
        variant="contained"
      >
        SUBMIT
      </Button>
    </Wrapper>
  );
};

export default withRouter(ButtonSection);
