import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import MaterialTable from '@material-table/core';

import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Delete from '@material-ui/icons/Delete';
import Check from '@material-ui/icons/Check';
import FilterList from '@material-ui/icons/FilterList';
import Remove from '@material-ui/icons/Remove';
import Edit from '@material-ui/icons/Edit';
import Add from '@material-ui/icons/Add';
import Clear from '@material-ui/icons/Clear';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import { API_URL } from '../../constants';
import axios from '../../utils/axios';
import Loader from '../../components/loader';

class UpdateBlogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titles: [],
      title: '',
      suggestions: [],
      blogs: [],
      addMore: true,
      type: 0,
      error: '',
      loading: true,
    };
  }

  // #region lifecycle
  componentDidMount() {
    this.fetchSCTitles();
  }

  // #endregion
  // #region API calls
  fetchSCTitles = async () => {
    try {
      this.setState({ loading: true });
      const res = await axios.get(`${API_URL}/shopify/admin/smartCollectionTitles`);
      const filtered = res.data.filter(v => v.title != null);
      this.setState({ titles: filtered });
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  fetchBlogsByTitle = async title => {
    try {
      this.setState({ loading: true });
      const res = await axios.get(`${API_URL}/shopify/admin/blogsByTitle`, {
        params: {
          title,
        },
      });
      let addMore = true;
      if (res.data.length > 5) addMore = false;
      this.setState({ blogs: res.data, addMore });
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  postBlog = async blog => {
    try {
      this.setState({ loading: true });
      const { title, type } = this.state;
      const { image_url, handle } = blog;
      const body = {
        title: blog.title,
        image_url,
        handle,
        type,
      };
      if (!blog.title || !image_url || !handle)
        this.setState({ error: 'Title, handle, or image url are blank.' });
      else {
        await axios.post(`${API_URL}/shopify/admin/blogs`, body);
        if (title) {
          this.fetchBlogsByTitle(title);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  };
  // #endregion
  // #region auto suggest function
  getSuggestionValue = suggestion => suggestion;
  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getSuggestions = (value = '') => {
    const inputValue = this.escapeRegexCharacters(value.trim().toLowerCase());
    const inputLength = inputValue.length;
    const titles = this.state.titles.map(collection => collection.title);
    return inputLength === 0
      ? []
      : titles.filter(title => title.toLowerCase().slice(0, inputLength) === inputValue);
  };

  onBlur = event => {
    const val = event.target.value;
    if (val) {
      this.setState({ title: val });
      this.getTypeFromTitle();
    }
  };
  onAutoChange = (event, { newValue }) => {
    this.setState({
      title: newValue,
    });
  };
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };
  renderSuggestion = suggestion => <div>{suggestion.replace(/<[^>]*>/g, '')}</div>;
  // #endregion
  // #region form events
  submitForm = async event => {
    const { title } = this.state;
    event.preventDefault();
    try {
      this.getTypeFromTitle();
      await this.fetchBlogsByTitle(title);
      this.setState({ error: '' });
    } catch (error) {
      this.setState({ error: 'That collection could not be found' });
    }
  };
  // #endregion
  // #region table functions
  addBlog = async blog => {
    const res = this.postBlog(blog);
    return res;
  };
  deleteBlog = async blog => {
    const { title } = this.state;
    const { id } = blog;
    const params = { id };
    const res = await axios.delete(`${API_URL}/shopify/admin/blog`, { params });
    await this.fetchBlogsByTitle(title);
    return res;
  };
  editBlog = async blog => {
    const { title } = this.state;
    const { id, image_url, handle } = blog;
    const body = {
      id,
      image_url,
      handle,
      title: blog.title,
    };
    const res = await axios.put(`${API_URL}/shopify/admin/blog`, body);
    await this.fetchBlogsByTitle(title);
    return res;
  };
  getTypeFromTitle = () => {
    const { titles, title } = this.state;
    const currentCollection = titles.filter(curr => curr.title === title)[0];
    if (currentCollection) this.setState({ type: currentCollection.id });
  };
  // #endregion

  render() {
    const { suggestions, title, error } = this.state;
    const inputProps = {
      placeholder: 'Type a smart collection title, e.g. Trek',
      value: title,
      onChange: this.onAutoChange,
      onBlur: this.onBlur,
    };
    const icons = {
      Check,
      DetailPanel: ChevronRight,
      Export: SaveAlt,
      Filter: FilterList,
      FirstPage,
      LastPage,
      NextPage: ChevronRight,
      PreviousPage: ChevronLeft,
      Search,
      ThirdStateCheck: Remove,
      Delete,
      Edit,
      Add,
      Clear,
      Sort: ArrowDownward,
    };

    return (
      <main style={{ marginBottom: '35px' }}>
        <form
          className="tradein-wrap"
          onSubmit={this.submitForm}
          style={{ zIndex: 1.9, position: 'relative' }}
        >
          <section className="bike-info-wrap flex-column-center" style={{ margin: 50 }}>
            <div className="label-wrap">
              <label htmlFor="smart-collection">Collection:</label>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                highlightFirstSuggestion
              />
              <span style={{ color: 'red', padding: 10 }}>{error}</span>
            </div>
          </section>
        </form>
        <section className="react-table" style={{ zIndex: 1, position: 'relative' }}>
          <MaterialTable
            columns={[
              {
                title: 'Collection Tile',
                field: 'collection_title',
                initialEditValue: this.state.title,
                hidden: true,
              },
              { title: 'Title', field: 'title' },
              {
                title: 'Handle',
                field: 'handle',
              },
              {
                title: 'Image URL',
                field: 'image_url',
              },
              {
                title: 'Smart Collection ID',
                field: 'type',
                editable: 'never',
                initialEditValue: this.state.type,
                hidden: true,
              },
              {
                title: 'Id',
                field: 'id',
                editable: 'never',
                hidden: true,
              },
            ]}
            icons={icons}
            editable={{
              onRowAdd: this.state.addMore ? this.addBlog : undefined,
              onRowUpdate: this.editBlog,
              onRowDelete: this.deleteBlog,
            }}
            data={this.state.blogs}
            title="Blogs"
            options={{ paging: false, sorting: false, search: false }}
            cellStyle={{ maxWidth: 60 }}
          />
        </section>
        <Loader loading={this.state.loading} />
      </main>
    );
  }
}

export default UpdateBlogs;
