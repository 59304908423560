import React, { useState, useCallback, useMemo } from 'react';
import FormikTextInput, { TextInputProps } from './FormikTextInput';
import { Switch, FormControlLabel } from '@material-ui/core';
import { useField } from 'formik';
import styled from 'styled-components';

export interface FormikMinMaxFieldProps extends TextInputProps {
  alwaysDisplayText?: boolean;
  header: string;
  unitSuffix: string;
}

interface Args {
  min: number | null;
  max: number | null;
  unit: string;
}

const displayMinMaxValue = ({ min, max, unit }: Args): string => {
  if (!min || !max) {
    return '';
  }
  if (min !== max) {
    return `${min} - ${max}${unit}`;
  }

  return `${min}${unit}`;
};

const MinMaxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const H5 = styled.h5`
  margin-left: 10px;
`;

const FormikMinMaxField: React.FC<FormikMinMaxFieldProps & { name: string }> = ({
  name,
  label: _label,
  alwaysDisplayText,
  header,
  unitSuffix,
  ...rest
}) => {
  const minFieldName = `${name}.minValue`;
  const maxFieldName = `${name}.maxValue`;
  const textFieldName = `${name}.textValue`;
  const [textField] = useField(textFieldName);
  const [minField] = useField(minFieldName);
  const [maxField, , { setValue: setMaxValue }] = useField(maxFieldName);
  const [showTextField, setShowTextField] = useState(
    textField.value && !minField.value && !maxField.value,
  );

  const onMinBlur = useCallback(
    e => {
      minField.onBlur(e);
      if (!maxField.value) {
        setMaxValue(minField.value);
      }
    },
    [minField, maxField.value, setMaxValue],
  );

  const displayValue = useMemo(
    () => displayMinMaxValue({ min: minField.value, max: maxField.value, unit: unitSuffix }),
    [minField.value, maxField.value, unitSuffix],
  );

  if (alwaysDisplayText) {
    return (
      <>
        <h5>{header}</h5>
        <FormikTextInput name={textFieldName} label="Text Value" {...rest} />
      </>
    );
  }

  const trimmedUnitSuffix = unitSuffix.trim();

  return (
    <>
      <h5>
        {header}
        <FormControlLabel
          style={{ marginLeft: 10 }}
          control={
            <Switch
              checked={showTextField}
              onChange={({ target }) => setShowTextField(target.checked)}
            />
          }
          label="Enter Text Value"
        />
      </h5>
      {!showTextField ? (
        <MinMaxWrapper>
          <FormikTextInput
            name={minFieldName}
            type="number"
            label={`Min (${trimmedUnitSuffix})`}
            onBlur={onMinBlur}
            {...rest}
          />
          <FormikTextInput
            name={maxFieldName}
            type="number"
            label={`Max (${trimmedUnitSuffix})`}
            {...rest}
          />
          <H5>{displayValue}</H5>
        </MinMaxWrapper>
      ) : (
        <FormikTextInput name={textFieldName} label="Text Value" {...rest} />
      )}
    </>
  );
};

export default FormikMinMaxField;
