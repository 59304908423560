import React from 'react';

import { Box, IconButton, makeStyles, Theme } from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';

import { useUserName } from '../../../service/serviceTicket/_shared';

import { SubmissionReceivedTrackingNumber } from '../../../../typeORM';

type TrackingNumberProps = {
  editing: boolean;
  setNumbersToBeDeleted: React.Dispatch<React.SetStateAction<number[]>>;
  trackingNumberInfo: SubmissionReceivedTrackingNumber;
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    alignItems: 'center',
    background: 'transparent',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    transition: 'all .3s ease',
  },
  editing: {
    padding: '8px 12px',
    background: 'rgba(0, 0, 0, 0.05)',
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
}));

export const TrackingNumber = (props: TrackingNumberProps) => {
  const { editing, setNumbersToBeDeleted, trackingNumberInfo } = props;

  const { userName } = useUserName(trackingNumberInfo.createdByUserId);

  const onDelete = (id: number) => {
    setNumbersToBeDeleted(prevState => [...prevState, id]);
  };

  const trackingLink = `https://www.google.com/search?q=${trackingNumberInfo.trackingNumber}`;

  const classes = useStyles();
  return (
    <Box
      key={trackingNumberInfo.id}
      className={editing ? `${classes.wrapper} ${classes.editing}` : `${classes.wrapper}`}
    >
      <Box display="flex" flexDirection="column">
        <Box>
          Tracking # Logged:&nbsp;
          <a href={trackingLink} target="_blank" rel="noopener noreferrer">
            {trackingNumberInfo.trackingNumber.slice(-12)}
          </a>
        </Box>
        <Box className={classes.secondaryText} ml={2} mt="5px">
          Added By: {userName}
        </Box>
      </Box>
      {editing && (
        <IconButton onClick={() => onDelete(trackingNumberInfo.id)}>
          <RemoveCircle className={classes.secondaryText} style={{ cursor: 'pointer' }} />
        </IconButton>
      )}
    </Box>
  );
};
