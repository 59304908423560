import {
  CASH_BASED_OFFER_TYPES,
  OFFER_ACCEPTED_STATE,
  Offer,
  SubmissionOfferTypes,
  ReactiveSettings,
} from '../../typeORM';
import { toFixedFloat } from './conversionHelpers';

export function styleStatus(status: string | null = ''): string {
  if (!status) {
    return '';
  }
  let color;
  switch (status.toLowerCase()) {
    case 'declined(user)':
    case 'declined(buyer)':
    case 'declined':
      color = '#ed1c24';
      break;
    case 'initiated':
    case 'resubmitted':
      color = '#086be2';
      break;
    case 'proposed':
      color = '#f7941d';
      break;
    case 'completed':
      color = '#00F710';
      break;
    case 'accepted':
      color = '#00c160';
      break;
    case 'approved':
      color = '#b0c160';
      break;
    case 'pending':
      color = 'magenta';
      break;
    case 'failed':
      color = '#910000';
      break;
    case 'failed:sent':
      color = '#c40000';
      break;
    case 'failed:confirmed':
      color = '#f25c00';
      break;
    case 'received':
      color = '#00BCD4';
      break;
    default:
      color = 'gray';
  }

  return color;
}

export function styleCategoryStatus(status: string | null = ''): string {
  if (!status) {
    return '';
  }
  let color;
  switch (status.toLowerCase()) {
    case 'overage':
      color = '#f88078';
      break;
    case 'target':
      color = '#ffb447';
      break;
    case 'small shortage':
      color = '#fff076';
      break;
    case 'large shortage':
      color = '#7bc67d';
      break;
    case 'hole':
      color = '#7bc67d';
      break;
    case 'unknown':
      color = 'gray';
      break;
    default:
      color = 'gray';
  }

  return color;
}

export function createYearsArray(startYear: number): number[] {
  let nStartYear = startYear;
  const currentYear = new Date().getFullYear();
  const years: number[] = [];
  while (nStartYear <= currentYear + 1) {
    years.push(nStartYear++);
  }
  return years.reverse();
}

export function getAcceptedOffer(offers: Offer[] = []) {
  return offers.find((offer: Offer) => OFFER_ACCEPTED_STATE.includes(offer.offerStatus));
}

export function getMaxCashBasedOffer(offers: Offer[] = []) {
  return offers
    ?.filter(
      offer => !!offer.availableToSeller && CASH_BASED_OFFER_TYPES.includes(offer.offerTypeName),
    )
    .sort((a, b) => b.amountUsd - a.amountUsd)[0];
}
export function getCreditBasedOffer(offers: Offer[] = []) {
  return offers?.find(
    offer => !!offer.availableToSeller && offer.offerTypeName === SubmissionOfferTypes.CREDIT,
  );
}

export function getTradeBasedOffer(offers: Offer[] = []) {
  return offers?.find(
    offer => !!offer.availableToSeller && offer.offerTypeName === SubmissionOfferTypes.TRADE,
  );
}

export function getPartnerVoucherBasedOffer(offers: Offer[] = []) {
  return offers?.find(
    offer =>
      !!offer.availableToSeller && offer.offerTypeName === SubmissionOfferTypes.PARTNER_VOUCHER,
  );
}

export function calculateOfferAmountUsd(
  offerType: SubmissionOfferTypes,
  basisAmountUsd: number,
  reactiveSettings: ReactiveSettings,
  enabled: boolean,
) {
  const offerAdjustmentAmount = reactiveSettings.offerAdjustments[offerType] ?? 0;
  if (reactiveSettings.basisOfferType === offerType) {
    return enabled ? toFixedFloat(basisAmountUsd) : null;
  } else {
    return enabled
      ? toFixedFloat(basisAmountUsd * (offerAdjustmentAmount * 0.01) + basisAmountUsd)
      : null;
  }
}
