import React from 'react';
import PropTypes from 'prop-types';

const successBar = {
  background: '#00c160',
  color: '#fff',
  position: 'absolute',
  width: '100%',
  height: '40px',
  lineHeight: '40px',
  textAlign: 'center',
  bottom: 0,
  marginBottom: '5px',
};

const GenericFormModal = ({ closeModal, form, success, error, style }) => (
  <div className="backdrop offer-decline-backdrop" style={style ?? {}}>
    <div
      style={{ position: 'relative', minHeight: '600px', height: 'inherit' }}
      className="message offer-decline-message relative"
    >
      {closeModal && <button className="close-modal absolute-right" onClick={closeModal} />}
      {form}
      {success && <div style={successBar}>Success!</div>}
      {error && (
        <div style={{ ...successBar, background: 'rgba(230, 0, 0, 0.8)' }}>Error Updating</div>
      )}
    </div>
  </div>
);

GenericFormModal.propTypes = {
  closeModal: PropTypes.func,
  form: PropTypes.shape().isRequired,
  success: PropTypes.bool,
  error: PropTypes.bool,
  style: PropTypes.object,
};

export default GenericFormModal;
