/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from '../../../utils/axios';
import { API_URL } from '../../../constants';
import { styleStatus } from '../helper';
import {
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { DataGrid } from '../../../components/library';
import OfferGroupDialog from './OfferGroupDialog';

const SubmissionHistoryTable = (props: any) => {
  const [users, setUsers] = useState<{ id: number; name: string }[]>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 20,
    page: 0,
  });

  const columns: GridColDef[] = [
    {
      headerName: 'User',
      field: 'lastUpdatedBy',
      disableColumnMenu: true,
      sortable: false,
      flex: 2,
    },
    {
      headerName: 'State',
      field: 'status',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return (
          <span>
            <span style={{ color: styleStatus(params.value as string), margin: '0 5px' }}>
              &#x25cf;
            </span>
            {params.value as string}
          </span>
        );
      },
    },
    {
      headerName: 'Active Offer Group',
      field: 'offerGroup',
      renderCell: OfferGroupDialog,
      disableColumnMenu: true,
      sortable: false,
      flex: 3,
    },
    {
      headerName: 'Expected Value',
      field: 'expectedValue',
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return '';
        }
        return `$${params.value}`;
      },
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'Timestamp',
      field: 'createdAt',
      disableColumnMenu: true,
      valueFormatter: (params: GridValueFormatterParams<Date>) =>
        new Date(params.value).toLocaleString('en-US', {
          timeZone: 'America/Denver',
        }),
      flex: 2,
    },
  ];

  const fetchUsers = async () => {
    const res = await axios.get(`${API_URL}/tradeup/dtUserLookup`);
    if (res) {
      setUsers(res.data);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const histories = props.submissionHistory.map((h: any) => ({
    ...h,
    lastUpdatedBy:
      h.lastUpdatedBy && users.length ? users.find(u => u.id === h.lastUpdatedBy)?.name : '--',
  }));

  return (
    <DataGrid
      key="submission-history"
      disableRowSelectionOnClick={true}
      className="-striped -highlight"
      rows={histories}
      getRowId={row => `${row.id}-${Math.floor(Math.random() * 10000)}`}
      columns={columns}
      initialState={{
        sorting: {
          sortModel: [{ field: 'createdAt', sort: 'desc' }],
        },
      }}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      pageSizeOptions={[5, 10, 20]}
      getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
    />
  );
};

export default SubmissionHistoryTable;
