import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PropTypes from 'prop-types';
import LabelValuePairDisplay from './LabelValuePairDisplay';

const getHighlightedText = (text, higlight) => {
  // Split text on higlight term, include term itself into parts, ignore case
  let parts;
  if (text) {
    parts = text.split(new RegExp(/(keep|without|take|only|switch)/g, 'gi'));
    return (
      <span>
        {parts.map((part, i) => (
          <span key={i}>
            {part.toLowerCase().match(/keep|without|take|only|switch/g) ? (
              <mark>{part}</mark>
            ) : (
              part
            )}
          </span>
        ))}
      </span>
    );
  }
};

const BikeInfo = (
  props = {
    year: '',
    make: '',
    model: '',
    serialNumber: '',
    imageGallery: [],
    description: '',
    condition: '',
  },
) => {
  const {
    year,
    make,
    model,
    discipline,
    serialNumber,
    imageGallery,
    description,
    condition,
    displayModal,
  } = props;
  return (
    <Grid container direction="row" spacing={2} justifyContent="space-evenly">
      <Grid container item xs={4} direction="column">
        <LabelValuePairDisplay
          labelValuePairs={[
            { label: 'Year', value: year },
            { label: 'Brand', value: make },
            { label: 'Model', value: model },
            {
              label: 'Serial Number',
              value: serialNumber,
              url: serialNumber
                ? `https://bikeindex.org/bikes?serial=${serialNumber}&location=you&stolenness=stolen`
                : undefined,
            },
          ]}
          gridSize={{ label: 4, value: 8 }}
        />
      </Grid>
      <Grid item xs={8} className="info-image-wrap">
        <Carousel showThumbs={false} dynamicHeight>
          {imageGallery.map((image, idx) => (
            <div key={image.url} onClick={() => displayModal(idx)}>
              <img alt={`bike image ${idx}`} className="info-bike-image" src={image.url} />
            </div>
          ))}
        </Carousel>
      </Grid>
      <Grid item xs={12}>
        <LabelValuePairDisplay
          labelValuePairs={[
            {
              label: 'Description',
              value: getHighlightedText(description, /keep|without|take|only|switch/g),
            },
          ]}
          gridSize={{ label: 2, value: 10 }}
        />
      </Grid>
    </Grid>
  );
};
// todo: convert to TS, remove propTypes
BikeInfo.propTypes = {
  displayModal: PropTypes.func.isRequired,
  year: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  discipline: PropTypes.string,
  serialNumber: PropTypes.string,
  imageGallery: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default BikeInfo;
