import React from 'react';
import deburr from 'lodash/deburr';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  makeStyles,
} from '@material-ui/core';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import axios from '../../../utils/axios';
import { API_URL } from '../../../constants';

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.sku, query);
  const parts = parse(suggestion.sku, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function FormDialog({
  onClose,
  onChange,
  onReturnCreate,
  onAutosuggestChange,
  open,
  reasons,
  formData: { orderId, source, reasonId, sku, quantity, generateShippingLabel, error },
}) {
  const classes = useStyles();
  const [skuSuggestions, setSkuSuggestions] = React.useState([]);
  const [skuOptions, setSkuOptions] = React.useState([]);
  const [skuDisabled, setSkuDisabled] = React.useState(true);

  function getSuggestions(value) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : skuOptions.filter(suggestion => {
          const keep =
            count < 5 && suggestion.sku.slice(0, inputLength).toLowerCase() === inputValue;

          if (keep) {
            count += 1;
          }

          return keep;
        });
  }

  function getSuggestionValue(suggestion) {
    return suggestion.sku;
  }

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSkuSuggestions(getSuggestions(value));
  };

  const handleSuggestionsClearRequested = () => {
    setSkuSuggestions([]);
  };

  const handleOrderBlur = async () => {
    const results = await axios.get(`${API_URL}/service/orderSkus`, {
      params: {
        source,
        orderId,
      },
    });
    setSkuOptions(results.data);
    setSkuDisabled(false);
  };

  function renderInputComponent(inputProps) {
    const { inputRef = () => {}, ref, ...other } = inputProps;

    return (
      <TextField
        fullWidth
        disabled={skuDisabled}
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
        }}
        {...other}
      />
    );
  }

  const autosuggestProps = {
    renderInputComponent,
    suggestions: skuSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Refund/Return</DialogTitle>
        <DialogContent>
          <form>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="age-simple">Source</InputLabel>
              <Select
                autoFocus
                value={source}
                onChange={onChange}
                inputProps={{
                  name: 'source',
                }}
                fullWidth
              >
                <MenuItem value="" />
                <MenuItem value="ebay">Ebay</MenuItem>
                <MenuItem value="shopify">Shopify</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                margin="dense"
                name="orderId"
                label="Order ID"
                type="text"
                value={orderId}
                onChange={onChange}
                onBlur={handleOrderBlur}
                fullWidth
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <Autosuggest
                {...autosuggestProps}
                inputProps={{
                  id: 'react-autosuggest-simple',
                  label: 'SKU',
                  placeholder: 'SKU',
                  value: sku,
                  onChange: onAutosuggestChange('sku'),
                }}
                theme={{
                  container: classes.container,
                  suggestionsContainerOpen: classes.suggestionsContainerOpen,
                  suggestionsList: classes.suggestionsList,
                  suggestion: classes.suggestion,
                }}
                renderSuggestionsContainer={options => (
                  <Paper {...options.containerProps} square>
                    {options.children}
                  </Paper>
                )}
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                margin="dense"
                name="quantity"
                label="Quantity"
                type="number"
                value={quantity}
                onChange={onChange}
                fullWidth
              />
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="age-simple">Reason</InputLabel>
              <Select
                value={reasonId}
                onChange={onChange}
                inputProps={{
                  name: 'reasonId',
                }}
                fullWidth
              >
                <MenuItem value="" />
                {reasons.map(r => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={generateShippingLabel}
                    onChange={onChange}
                    name="generateShippingLabel"
                    color="primary"
                  />
                }
                label="Generate shipping label?"
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button onClick={onReturnCreate} color="primary">
            Create
          </Button>
        </DialogActions>
        {error && <DialogContentText color="error">{error}</DialogContentText>}
      </Dialog>
    </div>
  );
}
