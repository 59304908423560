import React, { useState, useRef, useEffect } from 'react';
import JsBarcode from 'jsbarcode';
import { Document, Page, Text, View, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 5,
    padding: 5,
    flexGrow: 1,
  },
  image: {
    width: '95%',
    padding: 10,
  },
  text: {
    width: '95%',
    paddingVertical: 10,
    color: '#212121',
    fontSize: 12,
    textAlign: 'center',
  },
  iFrame: { width: 400, height: 400 },
});

interface Props {
  text: string;
  title: string;
}

const Barcode: React.FC<Props> = ({ title, text }) => {
  const [imgUrl, setImgUrl] = useState('');
  const ref = useRef<HTMLImageElement>(null);
  const handleChange = (e: any) => {
    setImgUrl(e.target.src);
  };

  useEffect(() => {
    JsBarcode(ref.current, text, {
      format: 'CODE128',
      height: 130,
      margin: 0,
      textAlign: 'center',
      fontSize: 42,
    });
  }, [text]);

  return (
    <div>
      {imgUrl && (
        <PDFViewer style={styles.iFrame}>
          <Document>
            <Page size="A8" style={styles.page}>
              <View style={styles.section}>
                <Image src={imgUrl} style={styles.image} />
                <Text style={styles.text}>{title}</Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      )}
      <img ref={ref} alt="barcode" onLoad={handleChange} style={{ display: 'none' }} />
    </div>
  );
};

export default Barcode;
