import styled from 'styled-components';
import { colors } from './variables';

const Notification = styled.span`
  margin-top: 0.4em;
  height: 2em;
`;
const Error = styled(Notification)`
  color: ${colors.error};
`;

export { Error };
