import React from 'react';
import styled from 'styled-components';

export interface ProgressBarProps {
  percentageFull: number;
}

const StyledProgressBar = styled.div`
  height: 10px;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  border-radius: 5px;
`;

const StyledProgress = styled.div<{ percentageFull: number }>`
  width: ${({ percentageFull }) => `${percentageFull}%`};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: inherit;
  height: inherit;
`;

const StyledProgressPercentage = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 12px;
  padding-top: 5px;
`;

export const ProgressBar: React.FC<ProgressBarProps> = ({ percentageFull }) => (
  <>
    <StyledProgressBar>
      <StyledProgress percentageFull={percentageFull} />
    </StyledProgressBar>
    <StyledProgressPercentage>{percentageFull}% complete</StyledProgressPercentage>
  </>
);
