import { VariantFieldValues } from './productVariant/types';

export enum CATEGORIES {
  BIKE_MOUNTAIN = 1,
  BIKE_ROAD = 2,
  BIKE_CX = 3,
  BIKE_TRACK = 5,
  BIKE_TT = 6,
  E_BIKE_MOUNTAIN = 95,
  E_BIKE_ROAD = 96,
  BIKE_GRAVEL = 97,
  BOTTOM_BRACKET = 13,
  BRAKES = 15,
  CHAIN_GUIDE = 17,
  CHAIN = 18,
  CRANKSET = 19,
  CHAINRING = 20,
  CASSETTE_FREEWHEELS = 21,
  FRONT_DERAILLEUR = 24,
  FREE_HUB = 25,
  GRIPS_BAR_TAPE = 27,
  MOUNTAIN_BAR = 28,
  HEADSET = 29,
  PEDALS = 32,
  REAR_DERAILLEUR = 33,
  ROAD_BARS = 34,
  WHEELS = 35,
  SEATCLAMP = 38,
  SADDLE = 39,
  ROAD_SHIFTERS = 40,
  SEATPOST = 41,
  STEM = 42,
  TIRES = 43,
  ROAD_FORK = 44,
  SUSPENSION_FORK = 45,
  REAR_SHOCK = 75,
  SKEWERS = 76,
  MOUNTAIN_SHIFTER = 80,
  ROAD_SHIFTER_W_BRAKE = 81,
  SHIFTER_POD = 82,
  ELECTRONIC_SHIFT_ACCESSORY = 83,
  SHIFTERS_MTN = 90,
  SHIFTERS_ROAD = 94,
  FRAME_MOUNTAIN = 7,
  FRAME_ROAD = 8,
  FRAME_CX = 9,
  FRAME_TRACK = 11,
  FRAME_TT = 12,
  FRAME_GRAVEL = 98,
  BASE_LAYER = 51,
  FOOTWEAR = 52,
  GLOVES = 53,
  HELMET = 54,
  JACKET_VEST = 55,
  JERSEY_SHORT_LONG = 56,
  OTHER_APPAREL = 57,
  PROTECTIVE_GEAR = 58,
  PANTS_TIGHTS = 59,
  SHORTS = 60,
  SKIN_SUIT = 61,
  WARMERS = 62,
  BAGS = 63,
  BOTTLE_CAGE = 64,
  BIKE_RACK = 65,
  COMPUTERS = 66,
  CAR_RACK = 67,
  LIGHTS = 68,
  OTHER = 69,
  PUMPS = 70,
  SUNGLASSES = 71,
  TOOL = 72,
  TRAINER = 73,
  WORK_STAND = 74,
  TEST = 77,
  GENERAL_ACCESSORIES = 78,
  BAGS_HYDRATION = 85,
  BAGS_WHEEL = 86,
  HANDLEBAR_TAPE = 87,
  TUBE = 88,
  HUBS = 91,
  HAT = 92,
  WATER_BOTTLES_AND_CAGES = 93,
}

export const CATEGORIES_GROUPED_BY_MAJOR = {
  BIKES: [
    CATEGORIES.BIKE_MOUNTAIN,
    CATEGORIES.BIKE_ROAD,
    CATEGORIES.BIKE_CX,
    CATEGORIES.BIKE_TRACK,
    CATEGORIES.BIKE_TT,
    CATEGORIES.E_BIKE_MOUNTAIN,
    CATEGORIES.E_BIKE_ROAD,
    CATEGORIES.BIKE_GRAVEL,
  ],
  COMPONENTS: [
    CATEGORIES.BOTTOM_BRACKET,
    CATEGORIES.BRAKES,
    CATEGORIES.CHAIN_GUIDE,
    CATEGORIES.CHAIN,
    CATEGORIES.CRANKSET,
    CATEGORIES.CHAINRING,
    CATEGORIES.CASSETTE_FREEWHEELS,
    CATEGORIES.FRONT_DERAILLEUR,
    CATEGORIES.FREE_HUB,
    CATEGORIES.GRIPS_BAR_TAPE,
    CATEGORIES.MOUNTAIN_BAR,
    CATEGORIES.HEADSET,
    CATEGORIES.PEDALS,
    CATEGORIES.REAR_DERAILLEUR,
    CATEGORIES.ROAD_BARS,
    CATEGORIES.SEATCLAMP,
    CATEGORIES.SADDLE,
    CATEGORIES.ROAD_SHIFTERS,
    CATEGORIES.SEATPOST,
    CATEGORIES.STEM,
    CATEGORIES.TIRES,
    CATEGORIES.ROAD_FORK,
    CATEGORIES.SUSPENSION_FORK,
    CATEGORIES.REAR_SHOCK,
    CATEGORIES.SKEWERS,
    CATEGORIES.MOUNTAIN_SHIFTER,
    CATEGORIES.ROAD_SHIFTER_W_BRAKE,
    CATEGORIES.SHIFTER_POD,
    CATEGORIES.ELECTRONIC_SHIFT_ACCESSORY,
    CATEGORIES.SHIFTERS_MTN,
    CATEGORIES.SHIFTERS_ROAD,
  ],
  FRAMES: [
    CATEGORIES.FRAME_MOUNTAIN,
    CATEGORIES.FRAME_ROAD,
    CATEGORIES.FRAME_CX,
    CATEGORIES.FRAME_TRACK,
    CATEGORIES.FRAME_TT,
    CATEGORIES.FRAME_GRAVEL,
  ],
  OTHER: [
    CATEGORIES.BASE_LAYER,
    CATEGORIES.FOOTWEAR,
    CATEGORIES.GLOVES,
    CATEGORIES.HELMET,
    CATEGORIES.JACKET_VEST,
    CATEGORIES.JERSEY_SHORT_LONG,
    CATEGORIES.OTHER_APPAREL,
    CATEGORIES.PROTECTIVE_GEAR,
    CATEGORIES.PANTS_TIGHTS,
    CATEGORIES.SHORTS,
    CATEGORIES.SKIN_SUIT,
    CATEGORIES.WARMERS,
    CATEGORIES.BAGS,
    CATEGORIES.BOTTLE_CAGE,
    CATEGORIES.BIKE_RACK,
    CATEGORIES.COMPUTERS,
    CATEGORIES.CAR_RACK,
    CATEGORIES.LIGHTS,
    CATEGORIES.OTHER,
    CATEGORIES.PUMPS,
    CATEGORIES.SUNGLASSES,
    CATEGORIES.TOOL,
    CATEGORIES.TRAINER,
    CATEGORIES.WORK_STAND,
    CATEGORIES.TEST,
    CATEGORIES.GENERAL_ACCESSORIES,
    CATEGORIES.BAGS_HYDRATION,
    CATEGORIES.BAGS_WHEEL,
    CATEGORIES.HANDLEBAR_TAPE,
    CATEGORIES.TUBE,
    CATEGORIES.HUBS,
    CATEGORIES.HAT,
    CATEGORIES.WATER_BOTTLES_AND_CAGES,
  ],
  WHEELS: [CATEGORIES.WHEELS],
};

export type VariantFieldValuesAndComponentSpecifications = VariantFieldValues & {
  Depth: string;
  'Inner Width': string;
  'Outer Width': string;
};

type CategoryFieldUnits = {
  [key in keyof Partial<VariantFieldValuesAndComponentSpecifications>]: {
    [key: string]: {
      includeCategories?: CATEGORIES[];
    };
  };
};

export const CATEGORY_FIELD_UNITS: CategoryFieldUnits = {
  weight: {
    grams: {
      includeCategories: [...CATEGORIES_GROUPED_BY_MAJOR.WHEELS],
    },
  },
  Depth: {
    mm: {
      includeCategories: [...CATEGORIES_GROUPED_BY_MAJOR.WHEELS],
    },
  },
  'Inner Width': {
    mm: {
      includeCategories: [...CATEGORIES_GROUPED_BY_MAJOR.WHEELS],
    },
  },
  'Outer Width': {
    mm: {
      includeCategories: [...CATEGORIES_GROUPED_BY_MAJOR.WHEELS],
    },
  },
};

export const selectVariantFieldUnitsByCategoryId = (
  variantField: string,
  categoryId: number | null,
): string => {
  let unitValue = '';
  if (categoryId) {
    const measurementType =
      CATEGORY_FIELD_UNITS[
        variantField as keyof Partial<VariantFieldValuesAndComponentSpecifications>
      ];
    if (measurementType) {
      for (const [unitKey, unitCategories] of Object.entries(measurementType)) {
        if (unitCategories?.includeCategories?.includes(categoryId)) {
          unitValue = unitKey;
        }
      }
    }
  }

  return unitValue;
};

export const ACCEPTED_CATEGORIES_FOR_LOOKUP_SPECS = [
  CATEGORIES.BIKE_CX,
  CATEGORIES.BIKE_GRAVEL,
  CATEGORIES.BIKE_MOUNTAIN,
  CATEGORIES.BIKE_ROAD,
  CATEGORIES.BIKE_TRACK,
  CATEGORIES.BIKE_TT,
  CATEGORIES.E_BIKE_MOUNTAIN,
  CATEGORIES.E_BIKE_ROAD,
  CATEGORIES.FRAME_CX,
  CATEGORIES.FRAME_GRAVEL,
  CATEGORIES.FRAME_MOUNTAIN,
  CATEGORIES.FRAME_ROAD,
  CATEGORIES.FRAME_TRACK,
  CATEGORIES.FRAME_TT,
  CATEGORIES.WHEELS,
];
