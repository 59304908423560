import React from 'react';

const ThreeSixty = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5 15.5c4.1421 0 7.5-3.3579 7.5-7.5S12.6421.5 8.5.5 1 3.8579 1 8s3.3579 7.5 7.5 7.5z"
      stroke="currentColor"
      strokeWidth=".8333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 15.5c1.841 0 3.3334-3.3579 3.3334-7.5S10.341.5 8.5001.5C6.659.5 5.1668 3.8579 5.1668 8S6.659 15.5 8.5 15.5z"
      stroke="currentColor"
      strokeWidth=".8333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 8c0 1.841 3.3579 3.3333 7.5 3.3333S16 9.8409 16 8c0-1.841-3.3579-3.3334-7.5-3.3334S1 6.159 1 8z"
      stroke="currentColor"
      strokeWidth=".8333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ThreeSixty;
