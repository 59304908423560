import React, { FC } from 'react';
import MaterialTable, { Column } from '@material-table/core';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { ProductVariantSearchResult, Pipeline } from '../../../services/catalog/types';
import ProductVariantStatusFlag from '../ProductVariantStatusFlag';
import HoverableImageThumbnail from '../productImages/HoverableImageThumbnail';

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Actions = (data: ProductVariantSearchResult) => {
  return (
    <ActionContainer>
      <Button to={`/catalog/products/${data.productId}`} variant="contained" component={RouterLink}>
        View
      </Button>
      <Button
        to={`/catalog/products/${data.productId}/edit`}
        variant="contained"
        component={RouterLink}
      >
        Edit
      </Button>
    </ActionContainer>
  );
};

const getColumns = (pipelines: Pipeline[]): Column<ProductVariantSearchResult>[] => {
  const pipelineMap: { [key: number]: string } = {};
  for (const pipeline of pipelines) {
    pipelineMap[pipeline.id] = pipeline.pipeline;
  }
  return [
    {
      title: 'Image',
      editable: 'never',
      render: data => <HoverableImageThumbnail src={data.imageUrl} identifier={data.sku} />,
    },
    { title: 'Category', field: 'category', editable: 'never' },
    { title: 'Title', field: 'title', editable: 'never' },
    { title: 'Brand', field: 'brand', editable: 'never' },
    {
      title: 'Pipeline',
      editable: 'never',
      render: data => (data.pipelineId && pipelineMap[data.pipelineId]) || '',
    },
    { title: 'Year', field: 'year', editable: 'never' },
    { title: 'Size', editable: 'never', render: data => data.size ?? data.wheelSize ?? '' },
    { title: 'SKU', field: 'sku', editable: 'never' },
    {
      title: 'Status',
      field: 'statusFlagId',
      editable: 'never',
      render: data =>
        data.statusFlagId ? <ProductVariantStatusFlag value={data.statusFlagId} /> : '',
    },
    {
      title: 'Shopify Status',
      field: 'shopifyStatus',
      render: data => data.shopifyStatus ?? 'Not In Shopify',
    },
    { title: '', field: 'actions', editable: 'never', render: Actions },
  ];
};

interface SearchResultsProps {
  results: ProductVariantSearchResult[];
  pipelines: Pipeline[];
  loading: boolean;
  total: number;
  pageSize: number;
  page: number;
  onChangePage: (page: number) => void;
}

const SearchResults: FC<SearchResultsProps> = ({
  results,
  pipelines,
  loading,
  total,
  pageSize,
  page,
  onChangePage,
}) => {
  //quick hack - MaterialTable wants every row of data to have a unique id.  We could
  //avoid this array copy if we added id to the ProductVariantSearchResult type
  const resultsWithIds: (ProductVariantSearchResult & { id: number })[] = results.map(result => ({
    ...result,
    id: result.productVariantId,
  }));
  return (
    <MaterialTable
      isLoading={loading}
      columns={getColumns(pipelines)}
      data={resultsWithIds}
      page={page}
      totalCount={total}
      onPageChange={onChangePage}
      options={{
        paging: true,
        pageSizeOptions: [pageSize],
        pageSize: pageSize,
        sorting: false,
        search: false,
        toolbar: false,
        draggable: false,
      }}
    />
  );
};

export default React.memo(SearchResults);
