// future implementation for darkMode

import * as wrappers from './wrappers';
import * as forms from './forms';
import * as headers from './headers';
import * as notifications from './notifications';
import * as lists from './lists';
import * as datagrid from './datagrid';
export default {
  ...wrappers,
  ...forms,
  ...headers,
  ...notifications,
  ...lists,
  ...datagrid,
};
