import React, { useContext } from 'react';
import { serviceTicketContext } from '../../../_shared/serviceTicketContext';
import { InspectionModule } from '../../../_shared';
import { InspectionStatusEnum } from '../../../../../../services/service/types';
import { Button, FloatingActionBar } from '../../../../../../components/library';
import { FormikProps } from 'formik';
import {
  FormikPointsState,
  FormikServiceState,
  FormikPartsState,
  FormikIntakeState,
  FormikRejectState,
} from '../../../_shared/types';
import { SubmissionStatusIds } from '../../../../../../constants';

interface ServicePlanFooterProps {
  activeModule: InspectionModule | undefined;
  isBikeStolen: boolean;
  isSerialNumberVerified: boolean;
  partsModuleRef: React.RefObject<FormikProps<FormikPartsState>>;
  pointsModuleRef: React.RefObject<FormikProps<FormikPointsState>>;
  serviceModuleRef: React.RefObject<FormikProps<FormikServiceState>>;
  intakeModuleRef: React.RefObject<FormikProps<FormikIntakeState>>;
  rejectModuleRef: React.RefObject<FormikProps<FormikRejectState>>;
  setShouldRelocateAfterSave: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveRejectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldRejectAfterSave: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ServicePlanFooter: React.FC<ServicePlanFooterProps> = ({
  isBikeStolen,
  activeModule,
  partsModuleRef,
  pointsModuleRef,
  serviceModuleRef,
  intakeModuleRef,
  rejectModuleRef,
  setShouldRelocateAfterSave,
  setRemoveRejectionModalOpen,
  setShouldRejectAfterSave,
}) => {
  const { inspectionData } = useContext(serviceTicketContext);
  const { inspection } = inspectionData;
  const { sybSubmission } = inspection?.itemProcurement ?? {};

  const isSerialNumberValid = inspection?.serialNumber && !isBikeStolen;

  const isServicePlanRejected = inspection?.statusId === InspectionStatusEnum['Rejected'];

  const isServicePlanPostPurchase =
    !sybSubmission || sybSubmission?.statusId === SubmissionStatusIds.COMPLETED;

  const resetPostSaveActions = () => {
    setShouldRelocateAfterSave(false);
    setShouldRejectAfterSave(false);
  };

  const handleSubmit = () => {
    switch (activeModule) {
      case InspectionModule.POINTS:
        if (pointsModuleRef.current) {
          pointsModuleRef?.current.handleSubmit();
        }
        break;
      case InspectionModule.PARTS:
        if (partsModuleRef.current) {
          partsModuleRef?.current.handleSubmit();
        }
        break;
      case InspectionModule.SERVICE:
        if (serviceModuleRef.current) {
          serviceModuleRef?.current.handleSubmit();
        }
        break;
      case InspectionModule.INTAKE:
        if (intakeModuleRef.current) {
          intakeModuleRef?.current.handleSubmit();
        }
        break;
      case InspectionModule.REJECT:
        if (rejectModuleRef.current) {
          rejectModuleRef?.current.handleSubmit();
        }
        break;
    }
  };

  return (
    <FloatingActionBar
      primaryActionButton={
        <Button
          ordinality="primary"
          type="button"
          disabled={!isSerialNumberValid}
          onClick={() => {
            resetPostSaveActions();
            setShouldRelocateAfterSave(true);
            handleSubmit();
          }}
        >
          Save and Relocate
        </Button>
      }
      secondaryActionButton={
        <Button
          ordinality="secondary"
          type="button"
          disabled={!isSerialNumberValid}
          onClick={() => {
            resetPostSaveActions();
            handleSubmit();
          }}
          style={{ marginRight: '1rem' }}
        >
          Save Changes
        </Button>
      }
      tertiaryActionButton={
        isServicePlanPostPurchase ? (
          undefined
        ) : isServicePlanRejected ? (
          <Button
            ordinality="warning"
            variant="outlined"
            type="button"
            onClick={() => setRemoveRejectionModalOpen(true)}
            style={{ marginRight: '1rem' }}
          >
            Remove Rejection
          </Button>
        ) : (
          <Button
            ordinality="warning"
            variant="outlined"
            type="button"
            onClick={() => {
              resetPostSaveActions();
              setShouldRejectAfterSave(true);
              handleSubmit();
            }}
            style={{ marginRight: '1rem' }}
          >
            Reject
          </Button>
        )
      }
    />
  );
};
