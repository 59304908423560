import React from 'react';
import {
  default as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core/Checkbox';

export type CheckboxProps = MuiCheckboxProps;

export const Checkbox: React.FC<MuiCheckboxProps> = ({ ...muiProps }) => (
  <MuiCheckbox color="primary" {...muiProps} />
);
