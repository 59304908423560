import React, { useEffect, useMemo, useState } from 'react';
import {
  GridPaginationModel,
  GridRenderCellParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridInputRowSelectionModel,
} from '@mui/x-data-grid';
import { LoaderOverlay, Modal } from '../../../components/library';
import Radio from '@material-ui/core/Radio';
import { Box, Button, IconButton, makeStyles, TextField, Typography } from '@material-ui/core';
import {
  CatalogProductSearchResponse,
  CatalogProductVariantSearchParams,
  Product,
  ProductVariant,
  ShopifyProductStatus,
} from '../../../services/catalog/types';
import * as service from '../../../services/catalog/services';
import { AxiosError } from 'axios';
import ImageModal from '../../../components/catalog/ImageModal';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../reducers/types';
import { YEAR_OPTIONS } from '../utils';
import { debounce } from 'lodash';
import { ACCEPTED_CATEGORIES_FOR_LOOKUP_SPECS } from '../utils/categories';
import CloseIcon from '@material-ui/icons/Close';
import { DataGrid } from '../../../components/library/DataGrid';
import { renderCellExpand } from '../../../components/library/DataGrid/renderCellExpand';

export interface SkuCopyTableRow {
  id: number;
  title: string;
  category: string | undefined;
  brand: string;
  model: string;
  year: number | null;
  size: string | null;
  sku: string;
}
export interface LookupSpecsModalProps {
  open: boolean;
  setIsLookupSpecsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  model: Product['model'];
  brandId: Product['brandId'];
  categoryId: Product['categoryId'] | null;
  sizeClassId: ProductVariant['sizeClassId'];
  year: ProductVariant['year'];
  toProductVariantId: ProductVariant['id'];
  onSkuCopySuccess: () => Promise<void>;
  handleSuccess: (successMessage: string) => void;
  handleError: (errorMessage: string) => void;
}

const useStyles = makeStyles(theme => ({
  input: {
    flexGrow: 1,
    minWidth: '300px',
    [theme.breakpoints.up('lg')]: {
      minWidth: '200px',
    },
  },
  container: {
    height: 'calc(100vh - 400px)',
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100vh - 300px)',
    },
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  inputContainer: {
    paddingBottom: theme.spacing(3),
    display: 'flex',
    gridGap: theme.spacing(2),
    alignItems: 'start',
    justifyContent: 'start',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

export const LookupSpecsModal: React.FC<LookupSpecsModalProps> = ({
  open,
  setIsLookupSpecsModalOpen,
  model: initialModel,
  brandId: initialBrandId,
  categoryId: initialCategoryId,
  sizeClassId: initialSizeClassId,
  year: initialYear,
  toProductVariantId,
  onSkuCopySuccess,
  handleSuccess,
  handleError,
}) => {
  const { categories, brands, sizeClasses } = useSelector((state: GlobalState) => state.product);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridInputRowSelectionModel>();
  const [brandId, setBrandId] = useState<CatalogProductVariantSearchParams['brandId']>(
    initialBrandId,
  );
  const [categoryId, setCategoryId] = useState<
    CatalogProductVariantSearchParams['productCategoryId']
  >(initialCategoryId);
  const [sizeClassId, setSizeClassId] = useState<ProductVariant['sizeClassId']>(initialSizeClassId);
  const [year, setYear] = useState<CatalogProductVariantSearchParams['year']>(initialYear);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [searchResults, setSearchResults] = useState<SkuCopyTableRow[] | undefined>(undefined);
  const [searchQuery, setSearchQuery] = useState(initialModel ?? '');
  const [imageModalSrc, setImageModalSrc] = useState<string | undefined>();
  const [loadingSkuCopy, setLoadingSkuCopy] = useState<boolean>(false);
  const classes = useStyles();

  const fetchProductsForDataGrid = async () => {
    setLoadingSkuCopy(true);
    await service.fetchCatalogProductsSearch(
      {
        query: searchQuery,
        brandId: brandId,
        productCategoryId: categoryId,
        year: year,
        sizeClassId: sizeClassId,
        limit: 100,
        offset: 0,
      },
      {
        onSuccess: (pvSearchResults: CatalogProductSearchResponse) => {
          if (!!pvSearchResults.results) {
            const resultsExcludingTargetProduct =
              pvSearchResults.results
                .filter(
                  result =>
                    result.productVariantId !== toProductVariantId &&
                    result.shopifyStatus === ShopifyProductStatus.active &&
                    ACCEPTED_CATEGORIES_FOR_LOOKUP_SPECS.includes(result.categoryId),
                )
                .map(result => ({
                  id: result.productVariantId,
                  image: result.imageUrl,
                  title: result.title,
                  category: result.category,
                  brand: result.brand,
                  model: result.model,
                  year: result.year,
                  size: result.size,
                  sku: result.sku,
                })) || [];
            setSearchResults(resultsExcludingTargetProduct);
            setLoadingSkuCopy(false);
          } else {
            handleError('No close matches for this variant were found');
          }
        },
        catchFailure: (err: AxiosError | undefined) => {
          console.error('Search for variants error:', err);
          handleError('An error occured while attempting find similar variants');
        },
      },
    );
  };

  const callSkuCopyEndpoint = async (fromProductVariantId: number) => {
    try {
      if (fromProductVariantId) {
        setLoadingSkuCopy(true);
        const res = await service.copySkuAndProductVariantInfo(
          toProductVariantId,
          fromProductVariantId,
        );
        if (res && res.data) {
          setLoadingSkuCopy(false);
          setIsLookupSpecsModalOpen(false);
          handleSuccess('Specs were successfully autofilled');
          await onSkuCopySuccess();
        }
      }
    } catch (e) {
      setLoadingSkuCopy(false);
      handleError('An error occured while attempting to perform SKU copy');
      console.log(e);
    }
  };

  const refetchProducts = useMemo(
    () =>
      debounce(
        ({ searchQuery, categoryId, brandId, year, sizeClassId, fetchProductsForDataGrid }) => {
          if (searchQuery || categoryId || brandId || year || sizeClassId) {
            fetchProductsForDataGrid();
          }
        },
        500,
      ),
    [],
  );

  useEffect(
    () => {
      refetchProducts({
        searchQuery,
        categoryId,
        brandId,
        year,
        sizeClassId,
        fetchProductsForDataGrid,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, categoryId, brandId, year, sizeClassId],
  );

  const onSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    fetchProductsForDataGrid();
  };

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: () => ' ',
      renderCell: (params: GridRenderCellParams) => (
        <Radio
          color="primary"
          value={params.id}
          checked={rowSelectionModel === params.id}
          onClick={() => setRowSelectionModel(params.id)}
        />
      ),
      flex: 1,
    },
    {
      field: 'image',
      headerName: 'Image',
      flex: 2,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <img
          src={params.value as string}
          id={params.value as string}
          onClick={() => setImageModalSrc(params.value as string)}
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'contain',
            cursor: 'zoom-in',
          }}
        />
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 3,
      cellClassName: 'MuiDataGrid-textwrap',
      renderCell: renderCellExpand,
    },
    { field: 'category', headerName: 'Category', flex: 1, renderCell: renderCellExpand },
    { field: 'brand', headerName: 'Brand', flex: 1, renderCell: renderCellExpand },
    { field: 'model', headerName: 'Model', flex: 1, renderCell: renderCellExpand },
    { field: 'year', headerName: 'Year', flex: 1, renderCell: renderCellExpand },
    { field: 'size', headerName: 'Size', flex: 1, renderCell: renderCellExpand },
    { field: 'sku', headerName: 'Sku', flex: 1, renderCell: renderCellExpand },
    {
      field: 'autofill',
      headerName: 'Action',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const currentRow = params.row;
        return rowSelectionModel === currentRow.id ? (
          <Button
            variant="contained"
            color="primary"
            onClick={_event => {
              callSkuCopyEndpoint(params.id as number);
            }}
          >
            Autofill
          </Button>
        ) : null;
      },
    },
  ];

  return (
    <Modal
      open={open}
      handleClose={() => {
        setIsLookupSpecsModalOpen(false);
      }}
      label="any"
      maxWidth="1440px"
    >
      <Box width="100%" px={4} pt={2} pb={4} mx="auto" position="relative">
        <LoaderOverlay loading={loadingSkuCopy} />
        <Box my={3} mr={6}>
          <Typography variant="h4">Lookup Specs for &quot;{initialModel}&quot;</Typography>
        </Box>

        <Box position="absolute" right={0} top={0} mt={2} mr={2}>
          <IconButton
            color="primary"
            onClick={() => {
              setIsLookupSpecsModalOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.inputContainer} component="form" onSubmit={onSubmit}>
          <Box className={classes.input}>
            <TextField
              id="productVariantSearchBox"
              value={searchQuery}
              variant="outlined"
              placeholder="Search by SKU or Title"
              onChange={e => setSearchQuery(e.target.value)}
              style={{ backgroundColor: '#fff' }}
              label="Search"
              autoFocus
            />
          </Box>
          <Box className={classes.input}>
            <Autocomplete
              onChange={(_, option) => {
                setCategoryId(option?.id ? option.id : null);
              }}
              options={[...categories]}
              value={categories.find(o => o.id === categoryId) || null}
              getOptionLabel={option => option?.category || ''}
              style={{ backgroundColor: '#fff' }}
              autoHighlight
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField {...params} placeholder="Category" variant="outlined" label="Category" />
              )}
            />
          </Box>
          <Box className={classes.input}>
            <Autocomplete
              onChange={(_, option) => {
                setBrandId(option?.id ? option.id : null);
              }}
              options={[...brands]}
              value={brands.find(o => o.id === brandId) || null}
              getOptionLabel={option => option?.name || ''}
              style={{ backgroundColor: '#fff' }}
              autoHighlight
              renderInput={(params: any) => (
                <TextField {...params} placeholder="Brand" variant="outlined" label="Brand" />
              )}
            />
          </Box>
          <Box className={classes.input}>
            <Autocomplete
              onChange={(_, option) => {
                setYear(option?.id ? option.id : null);
              }}
              options={[...YEAR_OPTIONS]}
              value={YEAR_OPTIONS.find(o => o.id === year) || null}
              getOptionLabel={option => option?.name || ''}
              style={{ backgroundColor: '#fff' }}
              autoHighlight
              renderInput={(params: any) => (
                <TextField {...params} placeholder="Year" variant="outlined" label="Year" />
              )}
            />
          </Box>
          <Box className={classes.input}>
            <Autocomplete
              onChange={(_, option) => {
                setSizeClassId(option?.id ? option.id : null);
              }}
              options={[...sizeClasses]}
              value={sizeClasses.find(o => o.id === sizeClassId) || null}
              getOptionLabel={option => option?.name || ''}
              style={{ backgroundColor: '#fff' }}
              autoHighlight
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder="Size Class"
                  variant="outlined"
                  label="Size Class"
                />
              )}
            />
          </Box>
        </Box>
        <DataGrid
          height="30rem"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 20]}
          rows={searchResults || []}
          rowHeight={88}
          disableRowSelectionOnClick={true}
          columns={columns}
          rowSelectionModel={rowSelectionModel}
          checkboxSelection={true}
        />
        {!!imageModalSrc && (
          <ImageModal
            displayModal={true}
            onClose={() => setImageModalSrc(undefined)}
            imageSrc={imageModalSrc}
          />
        )}
      </Box>
    </Modal>
  );
};
