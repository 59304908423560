import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { API_URL } from '../../constants';
import { RestResponse, axiosGetWithCallbacks, CallbackOptions } from '../rest-utils';
import {
  Brand,
  Pipeline,
  ProductCategory,
  Submission,
  CatalogProductVariantSearchParams,
  CatalogProductSearchResponse,
  Product,
  AlternateEligibilityBody,
  UpdateProductBody,
  UpdateProductVariantBody,
  UpdateProductVariantComponentSpecifications,
  UpdateProductVariantSingleItemProcurement,
  SizeClass,
  ProductSubCategory,
  CreateProductBody,
  UpdatePVFrameDimensions,
  FrameDimension,
  ProductShopifyStatus,
  ShopifyStatusChangeResponse,
  ProductImageSignedUrlResponse,
  ProductImage,
  UpdateProductImagesImage,
  UpdateVariantImagesImage,
  CreateVariantImagesImage,
  CreateProductImagesImage,
  ProductVariant,
  StatusFlag,
  Inspection,
  InspectionProduct,
  CreateProductVariantBody,
  AdditionalEffect,
  ProductPromotionalText,
  ProductPromotionalPayload,
  AttributeType,
} from './types';
import axios from '../../utils/axios';
import { default as ogAxios, AxiosResponse } from 'axios';

import { AddProductAndVariantValues, AllProductValues } from '../../pages/catalog/addProduct/types';
import { VariantFrameDimension } from '../../pages/catalog/utils/productVariant/types';
import {
  CatalogFieldCategoryConfig,
  CatalogFieldConfig,
} from '../../pages/catalog/utils/formik/types';
import { CatalogConfiguration } from '../../reducers/product-reducer';
import { DEFAULT_WEIGHT_FIELD_VALUE } from '../../pages/catalog/utils';
import { buildProductVariantAttributesArray } from '../../pages/catalog/utils/productVariant/form-utils';

export function fetchDisciplines(
  callbackOptions?: CallbackOptions<{ id: number; discipline: string }[]>,
): Promise<RestResponse<{ id: number; discipline: string }[]>> {
  const url = `${API_URL}/products/disciplines`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchProductCategories(
  callbackOptions?: CallbackOptions<ProductCategory[]>,
): Promise<RestResponse<ProductCategory[]>> {
  const url = `${API_URL}/products/categories`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchPipelines(
  callbackOptions?: CallbackOptions<Pipeline[]>,
): Promise<RestResponse<Pipeline[]>> {
  const url = `${API_URL}/tradeup/syb/pipelines`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchStatusFlags(
  callbackOptions?: CallbackOptions<StatusFlag[]>,
): Promise<RestResponse<StatusFlag[]>> {
  const url = `${API_URL}/catalog/statusFlags`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchBrands(
  callbackOptions?: CallbackOptions<Brand[]>,
): Promise<RestResponse<Brand[]>> {
  const url = `${API_URL}/products/brands`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchAutosuggestFields(
  categoryId?: number,
  callbackOptions?: CallbackOptions<any>,
) {
  const url = `${API_URL}/catalog/autosuggestFields`;
  return axiosGetWithCallbacks(
    url,
    categoryId ? { params: { categoryId } } : undefined,
    callbackOptions,
  );
}

export function fetchSizeClasses(
  callbackOptions?: CallbackOptions<SizeClass[]>,
): Promise<RestResponse<SizeClass[]>> {
  const url = `${API_URL}/products/sizeClasses`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchProductSubCategories(
  callbackOptions?: CallbackOptions<ProductSubCategory[]>,
): Promise<RestResponse<ProductSubCategory[]>> {
  const url = `${API_URL}/products/productSubCategories`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchFrameDimensions(
  callbackOptions?: CallbackOptions<FrameDimension[]>,
): Promise<RestResponse<FrameDimension[]>> {
  const url = `${API_URL}/products/frameDimensions`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchCatalogConfiguration(
  callbackOptions?: CallbackOptions<CatalogConfiguration>,
): Promise<RestResponse<CatalogConfiguration>> {
  const url = `${API_URL}/catalog/configuration`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchSubmission(
  id: number,
  source: number,
  callbackOptions?: CallbackOptions<Submission[]>,
): Promise<RestResponse<Submission[]>> {
  const url = `${API_URL}/catalog/submissions`;
  const params = { id, source };
  return axiosGetWithCallbacks(
    url,
    {
      params,
    },
    callbackOptions,
  );
}

export function fetchCatalogProductsSearch(
  params: CatalogProductVariantSearchParams,
  callbackOptions?: CallbackOptions<CatalogProductSearchResponse>,
): Promise<RestResponse<CatalogProductSearchResponse>> {
  const url = `${API_URL}/catalog/productVariantSearchResults`;

  const scrubbedParams = omitBy(params, isNil);

  return axiosGetWithCallbacks(url, { params: scrubbedParams }, callbackOptions);
}

export function fetchProductPromotionalTexts(
  productId: number,
  callbackOptions?: CallbackOptions<ProductPromotionalText[]>,
): Promise<RestResponse<ProductPromotionalText[]>> {
  const url = `${API_URL}/catalog/${productId}/productPromotionalTexts`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchAttributeTypes(
  callbackOptions?: CallbackOptions<AttributeType[]>,
): Promise<RestResponse<AttributeType[]>> {
  const url = `${API_URL}/catalog/attributeTypes`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchCatalogFieldConfigs(
  callbackOptions?: CallbackOptions<CatalogFieldConfig[]>,
): Promise<RestResponse<CatalogFieldConfig[]>> {
  const url = `${API_URL}/catalog/fieldConfigs`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchCatalogFieldCategoryConfigs(
  productCategoryId: number,
  callbackOptions?: CallbackOptions<CatalogFieldCategoryConfig[]>,
): Promise<RestResponse<CatalogFieldCategoryConfig[]>> {
  const url = `${API_URL}/catalog/fieldCategoryConfigs`;
  return axiosGetWithCallbacks(url, { params: { productCategoryId } }, callbackOptions);
}

//--TODO--CLEANUP//
export function getInspectionByIdentifier(
  sku: string,
  callbackOptions?: CallbackOptions<Inspection>,
): Promise<RestResponse<Inspection>> {
  const url = `${API_URL}/service/inspectionByIdentifier/${sku}`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchProduct(
  id: number,
  callbackOptions?: CallbackOptions<Product>,
): Promise<RestResponse<Product>> {
  const url = `${API_URL}/catalog/products/${id}`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function getInspectionProduct(
  productVariantId: number,
  callbackOptions?: CallbackOptions<InspectionProduct>,
): Promise<RestResponse<InspectionProduct>> {
  const url = `${API_URL}/catalog/productVariants/${productVariantId}/inspectionProduct`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function updateProduct(id: number, body: UpdateProductBody): Promise<void | AxiosResponse> {
  const url = `${API_URL}/catalog/products/${id}`;
  return axios.put(url, body);
}

// created for updating product after variant display order is changed
export function updateShopifyBaseProduct(id: number): Promise<void | AxiosResponse> {
  const url = `${API_URL}/catalog/products/${id}/shopifyBaseProductUpdateRequests`;
  return axios.post(url, {});
}

export function updateProductVariant(
  id: number,
  body: UpdateProductVariantBody,
): Promise<void | AxiosResponse> {
  const url = `${API_URL}/catalog/productVariants/${id}`;
  return axios.put(url, body);
}

export function patchProductVariant(
  id: number,
  body: UpdateProductVariantBody,
): Promise<void | AxiosResponse> {
  const url = `${API_URL}/catalog/productVariants/${id}`;
  return axios.patch(url, body);
}

export function createProductVariant(
  productId: number,
  body: CreateProductVariantBody,
): Promise<AxiosResponse<ProductVariant>> {
  const url = `${API_URL}/catalog/products/${productId}/productVariant`;
  return axios.post(url, body) as Promise<AxiosResponse<ProductVariant>>;
}

export function updateProductVariantComponentSpecifications(
  variantId: number,
  body: UpdateProductVariantComponentSpecifications,
): Promise<void | AxiosResponse> {
  const url = `${API_URL}/catalog/productVariants/${variantId}/productVariantComponentSpecifications`;
  return axios.put(url, body);
}

export function updateProductVariantSingleItemProcurement(
  variantId: number,
  body: UpdateProductVariantSingleItemProcurement,
): Promise<void | AxiosResponse> {
  const url = `${API_URL}/catalog/productVariants/${variantId}/productVariantSingleItemProcurement`;
  return axios.put(url, body);
}

export function copySkuAndProductVariantInfo(
  toProductVariantId: number,
  fromId: number,
): Promise<void | AxiosResponse> {
  const url = `${API_URL}/catalog/productVariants/${toProductVariantId}/copyOfProperties`;
  return axios.put(url, {}, { params: { fromProductVariantId: fromId } });
}

export function updateProductVariantFrameDimensions(
  id: number,
  body?: VariantFrameDimension[],
): Promise<void | AxiosResponse> | undefined {
  const pvFrameDimensionsBody = body
    ?.map(fd =>
      Object.values(fd).map(fbObj => ({
        ...fbObj,
        // When typing in the input the type is string but the BE values are numbers
        // if there is still value don't null it
        minValue:
          typeof fbObj.minValue === 'string' ? parseFloat(fbObj.minValue) : fbObj.minValue ?? null,
        maxValue:
          typeof fbObj.maxValue === 'string' ? parseFloat(fbObj.maxValue) : fbObj.maxValue ?? null,
      })),
    )
    .flat() as UpdatePVFrameDimensions[];

  if (pvFrameDimensionsBody && pvFrameDimensionsBody.length) {
    const url = `${API_URL}/catalog/productVariants/${id}/productVariantFrameDimensions`;
    return axios.put(url, { productVariantFrameDimensions: pvFrameDimensionsBody });
  }

  return;
}

export function updateProductPromotionalTexts(
  id: number,
  body?: ProductPromotionalPayload[],
): Promise<void | AxiosResponse> | undefined {
  const url = `${API_URL}/catalog/${id}/productPromotionalTexts`;
  return axios.put(url, { productPromotionalText: body });
}

// TODO: Update to use Offers - doesn't seem to be effected - double check
export function addProductAndVariants({
  values,
  selectedSubmission,
}: {
  values: AllProductValues | AddProductAndVariantValues;
  selectedSubmission?: Submission;
}): Promise<void | AxiosResponse<{
  createdProducts: Product[];
  additionalEffects: AdditionalEffect[];
  createdServicePlans: Inspection[];
}>> {
  const reqBody: CreateProductBody = {
    title: values.title,
    displayTitle: values.displayTitle ?? null,
    // Yup won't allow undefined or null
    categoryId: values.categoryId as number,
    enteredTrackingNumber: values.enteredTrackingNumber as string,
    brandId: values.brandId as number,
    model: values.model,
    disciplineId: values.disciplineId || null,
    certifiedPreOwned: values.certifiedPreOwned,
    description: values.description,
    sellPreService: values.sellPreService,
    productSubCategoryId: values.productSubCategoryId || null,
    // NOTE: only send condition alternate stuff when needed
    ...(values.createConditionAlternates && {
      createConditionAlternates: values.createConditionAlternates,
      applyPricingToConditionAlternate: values.conditionAlternate,
    }),
    variants: [
      {
        createPurchaseOrder: !!values.createPurchaseOrder,
        submissionId: values.submissionId || null,

        // NOTE: Prices should be numbers going to the BE
        // FE forms sadly give us an empty string ('') if the field is empty so we set that to null
        // The back also validates that costUsd can't be null, but our types allow it
        costUsd: typeof values.costUsd === 'number' ? values.costUsd : null,
        startingPriceUsd:
          typeof values.startingPriceUsd === 'number' ? values.startingPriceUsd : null,
        msrpUsd: typeof values.msrpUsd === 'number' ? values.msrpUsd : null,
        mapPricing: values.mapPricing,
        compareAtPriceUsd:
          typeof values.compareAtPriceUsd === 'number' ? values.compareAtPriceUsd : null,
        promotionalPricingFlag: values.promotionalPricingFlag || false,
        upc: values.upc || null,
        mpn: values.mpn || null,
        qbpId: values.qbpId || null,
        year: values.year || null,
        serialNumber: values.serialNumber || null,
        source: selectedSubmission?.source || null,
        pipelineId: values.pipelineId || null,
        statusFlagId: values.statusFlagId || null,
        condition: values.condition || null,
        // If the default has not changed no need to save that to DB
        weight: values.weight === DEFAULT_WEIGHT_FIELD_VALUE ? null : values.weight,
        frameMaterial: values.frameMaterial || null,
        frameHeadset: values.frameHeadset || null,
        frameRearAxleSpacing: values.frameRearAxleSpacing || null,
        frameRearShockTravel: values.frameRearShockTravel || null,
        drivetrainBrandId: values.drivetrainBrandId || null,
        drivetrainConfiguration: values.drivetrainConfiguration || null,
        drivetrainShiftingTypeId: values.drivetrainShiftingTypeId || null,
        componentIntendedUseId: values.componentIntendedUseId || null,
        sizeClassId: values.sizeClassId || null,
        brakeType: values.brakeType || null,
        gender: values.gender || null,
        material: values.material || null,
        apparelSleeveLength: values.apparelSleeveLength || null,
        frameRearShockTravelRange: values.frameRearShockTravelRange || null,
        tireType: values.tireType || null,
        configurationDetails: values.configurationDetails || null,
        chargerIncluded: values.chargerIncluded || null,
        mileage: values.mileage ?? null,
        keyIncluded: values.keyIncluded || null,
        tubelessCompatibility: values.tubelessCompatibility || null,
        frameRearTriangleMaterial: values.frameRearTriangleMaterial || null,
        electricTopSpeed: values.electricTopSpeed || null,
        productVariantAttributes: buildProductVariantAttributesArray(
          values?.productVariantAttributesByTypeId,
        ),
        fulfillmentProvider: values.fulfillmentProvider || null,
        // TODO TP-5513 followup, when we remove the legacy add product form - we can get rid of these
        color: (values as any).color || null,
        size: (values as any).size || null,
        wheelSize: (values as any).wheelSize || null,
        hazmatInstructions: (values as any).hazmatInstructions || null,
        shippingWeight: (values as any).shippingWeight || null,
        // NOTE: These are not collected on product Add
        // currentSalePriceUsd?: string | null;
        // conditionDescription?: string | null;
      },
    ],
  };
  return axios.post(`${API_URL}/catalog/products`, reqBody);
}

export function fetchShopifyProductStatus(
  id: number,
  callbackOptions?: CallbackOptions<ProductShopifyStatus>,
): Promise<RestResponse<ProductShopifyStatus>> {
  const url = `${API_URL}/catalog/${id}/shopifyStatus`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function fetchShopifyPublishReady(
  id: number,
  callbackOptions?: CallbackOptions<{ ready: boolean }>,
): Promise<RestResponse<{ ready: boolean }>> {
  const url = `${API_URL}/catalog/${id}/shopifyPublishReady`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function updateShopifyPublish(
  productId: number,
): Promise<AxiosResponse<ShopifyStatusChangeResponse>> {
  const url = `${API_URL}/catalog/${productId}/shopifyPublish`;
  return axios.post(url, undefined) as Promise<AxiosResponse<ShopifyStatusChangeResponse>>;
}

export function updateShopifyUnpublish(
  productId: number,
): Promise<AxiosResponse<ShopifyStatusChangeResponse>> {
  const url = `${API_URL}/catalog/${productId}/shopifyUnpublish`;
  return axios.post(url, undefined) as Promise<AxiosResponse<ShopifyStatusChangeResponse>>;
}

export function getImageUploadSignedUrls(
  fileNames: string[],
): Promise<void | AxiosResponse<ProductImageSignedUrlResponse[]>> {
  return axios.post(`${API_URL}/catalog/products/images/signedUrls`, { fileNames });
}

// TP-2955: this uses the original Axios module because our util includes the DT auth header
// and that messes with AWS's signature calculation
export function uploadImageToS3(signedUrl: string, file: File): Promise<void | AxiosResponse<any>> {
  return ogAxios.put(signedUrl, file, { headers: { 'Content-Type': 'image/jpeg' } });
}

export function createProductImages(
  productId: string,
  payload: {
    productImages?: CreateProductImagesImage[];
    variantImages?: CreateVariantImagesImage[];
  },
): Promise<void | AxiosResponse<ProductImage[]>> {
  const url = `${API_URL}/catalog/products/${productId}/images`;
  return axios.post(url, payload);
}

export function fetchProductImages(
  productId: string | number,
  callbackOptions?: CallbackOptions<ProductImage[]>,
): Promise<RestResponse<ProductImage[]>> {
  const url = `${API_URL}/catalog/products/${productId}/images`;
  return axiosGetWithCallbacks(url, undefined, callbackOptions);
}

export function updateProductImages(
  productId: string,
  payload: {
    productImages?: UpdateProductImagesImage[];
    variantImages?: UpdateVariantImagesImage[];
  },
): Promise<void | AxiosResponse<UpdateProductImagesImage[]>> {
  const url = `${API_URL}/catalog/products/${productId}/images`;
  return axios.put(url, payload);
}

export function createBrand(name: string): Promise<AxiosResponse<Brand>> {
  const url = `${API_URL}/products/brand`;
  return axios.post(url, { name }) as Promise<AxiosResponse<Brand>>;
}

export function createShopifyFullExport(productId: number) {
  const url = `${API_URL}/catalog/${productId}/shopifyFullExport`;
  return axios.post(url, undefined);
}

export function updateCategory(productId: number, categoryId: number) {
  const url = `${API_URL}/catalog/products/${productId}/category`;
  return axios.put(url, {
    categoryId,
  });
}

export function checkProductAlternateCreationEligibility(
  sku: string,
  alternate: string,
): Promise<AxiosResponse<AlternateEligibilityBody>> {
  const url = `${API_URL}/catalog/productAlternateEligibility`;
  return axios.get(url, { params: { sku, alternate } }) as Promise<
    AxiosResponse<AlternateEligibilityBody>
  >;
}

export function createProductAlternate(
  sku: string,
  alternate: string,
): Promise<AxiosResponse<Product>> {
  const url = `${API_URL}/catalog/productAlternates`;
  return axios.post(url, { sku, alternate }) as Promise<AxiosResponse<Product>>;
}
