import React, { useState } from 'react';
import DisplayField from './DisplayField';
import styled from 'styled-components';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';
import { ShopifyStatus, UserError } from '../../services/catalog/types';
import { updateShopifyPublish, updateShopifyUnpublish } from '../../services/catalog/services';
import { Button, ButtonGroup, Tooltip, CircularProgress } from '@material-ui/core';

interface Props {
  shopifyPublishErrors?: string[];
  shopifyPublishReady?: boolean;
  shopifyStatus?: ShopifyStatus;
  productId: number;
  onPublishSuccess: () => void;
  dirty?: boolean;
}

export const Ul = styled.ul`
  margin: 10px;

  li {
    list-style: inside;
    padding-bottom: 5px;
    color: #f44336;
  }
`;

const Wrapper = styled.div<{ ready?: boolean }>`
  border: 1px solid ${({ ready }) => (ready ? '#4caf50' : '#f44336')};
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-top: 10px;
`;

const Btn = styled(Button)`
  height: 50px;
`;

const Flex = styled.div`
  display: flex;
`;

const ReadyForShopifyPublish: React.FC<Props> = ({
  shopifyPublishReady,
  shopifyPublishErrors,
  shopifyStatus,
  productId,
  onPublishSuccess,
  dirty,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<UserError[] | string | undefined>();

  const publishToShopify = async (navigateAway?: boolean) => {
    try {
      setLoading(true);
      const { data } = await updateShopifyPublish(productId);

      if (data.productUpdate.userErrors) {
        setError(data.productUpdate.userErrors);
      }

      if (navigateAway && typeof window !== 'undefined') {
        window.location.href = '/catalog/products/';
      } else {
        // Refetch Fresh Data
        onPublishSuccess();
      }
    } catch (error) {
      console.error(error);
      setError('Opps! Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  const unpublishFromShopify = async () => {
    try {
      setLoading(true);
      const { data } = await updateShopifyUnpublish(productId);

      if (data.productUpdate.userErrors) {
        setError(data.productUpdate.userErrors);
      }

      // Refetch Fresh Data
      onPublishSuccess();
    } catch (error) {
      console.error(error);
      setError('Opps! Something went wrong!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper ready={shopifyPublishReady}>
      <Flex>
        <DisplayField
          label="Ready to Publish to Shopify"
          value={shopifyPublishReady ? <CheckCircle color="primary" /> : <Cancel color="error" />}
        />
        {shopifyPublishErrors?.length && (
          <DisplayField
            label="Errors"
            value={
              <Ul>
                {shopifyPublishErrors.map(e => (
                  <li key={e}>{e}</li>
                ))}
              </Ul>
            }
          />
        )}
        {Array.isArray(error) ? (
          <DisplayField
            label="Errors"
            value={
              <Ul>
                {error.map((e, i) => (
                  <li key={i}>
                    {e.field} {e.message}
                  </li>
                ))}
              </Ul>
            }
          />
        ) : error ? (
          <DisplayField label="Errors" value={error} />
        ) : null}
      </Flex>
      {shopifyStatus === ShopifyStatus.Draft ? (
        <ButtonGroup
          disableElevation
          color="secondary"
          variant="outlined"
          disabled={!shopifyPublishReady || dirty || loading}
        >
          <Tooltip arrow title="Publish to Shopify and Return to Item Catalog" enterDelay={500}>
            <Button
              onClick={() => {
                publishToShopify(true);
              }}
            >
              Publish and Return
            </Button>
          </Tooltip>
          <Button variant="contained" onClick={() => publishToShopify()}>
            Publish
          </Button>
        </ButtonGroup>
      ) : shopifyStatus === ShopifyStatus.Active ? (
        <Btn variant="outlined" disabled={dirty || loading} onClick={unpublishFromShopify}>
          {loading ? <CircularProgress /> : 'Unpublish from Shopify'}
        </Btn>
      ) : null}
    </Wrapper>
  );
};

export default ReadyForShopifyPublish;
