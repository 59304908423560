import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField, Paper, InputAdornment, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from 'styled-components';

import { FilterParamKeys } from '../../../reducers/catalogSearchProductsReducer';
import {
  Brand,
  ProductCategory,
  Pipeline,
  StatusFlag,
  ShopifyProductStatus,
} from '../../../services/catalog/types';
import { YEAR_OPTIONS } from '../utils';
import ProductVariantStatusFlag from '../ProductVariantStatusFlag';
import StatusFlagOption from '../editProduct/StatusFlagOption';

type FilterProps = {
  brandId: number | null;
  pipelineId: number | null;
  productCategoryId: number | null;
  statusFlagId: number | null;
  hasImages: boolean | null;
  year: number | null;
  categories: ProductCategory[];
  brands: Brand[];
  pipelines: Pipeline[];
  statusFlags: StatusFlag[];
  shopifyStatus: ShopifyProductStatus | null;
  onFilterChange: (param: FilterParamKeys, value: string | number | null | boolean) => void;
  onClearAll(): void;
};

const Title = styled.h3`
  padding-left: 1rem;
`;

const FilterLabel = styled.div`
  padding-bottom: 5px;
  font-weight: bold;
`;

const Container = styled(Paper)`
  padding: 1rem;
`;

const EMPTY_OPTION = { id: null, name: '', category: '', pipeline: '', status: '' };
const IMAGES_FILTER_OPTIONS = [
  {
    id: null,
    name: 'N/A',
  },
  {
    id: true,
    name: 'Only products and variants with images',
  },
  {
    id: false,
    name: 'Only products and variants without images',
  },
];

const Filters: FC<FilterProps> = ({
  brandId,
  pipelineId,
  productCategoryId,
  statusFlagId,
  year,
  categories,
  brands,
  pipelines,
  statusFlags,
  hasImages,
  shopifyStatus,
  onFilterChange,
  onClearAll,
}) => {
  return (
    <Container style={{ padding: '1rem' }}>
      <Grid item container direction="column" spacing={2} xs={12}>
        <Grid item xs={12}>
          <Title>Filter</Title>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={onClearAll}>
            Clear All
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FilterLabel>Category:</FilterLabel>
          <Autocomplete
            onChange={(_, option) =>
              onFilterChange('productCategoryId', option?.id ? option.id : null)
            }
            options={[EMPTY_OPTION, ...categories]}
            value={categories.find(o => o.id === productCategoryId) || EMPTY_OPTION}
            getOptionLabel={option => option.category}
            style={{ backgroundColor: '#fff' }}
            autoHighlight
            renderInput={(params: any) => (
              <TextField {...params} placeholder="Category" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FilterLabel>Brand:</FilterLabel>
          <Autocomplete
            onChange={(_, option) => onFilterChange('brandId', option?.id ? option.id : null)}
            options={[EMPTY_OPTION, ...brands]}
            value={brands.find(o => o.id === brandId) || EMPTY_OPTION}
            getOptionLabel={option => option.name}
            style={{ backgroundColor: '#fff' }}
            autoHighlight
            renderInput={(params: any) => (
              <TextField {...params} placeholder="Brand" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FilterLabel>Pipeline:</FilterLabel>
          <Autocomplete
            onChange={(_, option) => onFilterChange('pipelineId', option?.id ? option.id : null)}
            options={[EMPTY_OPTION, ...pipelines]}
            value={pipelines.find(o => o.id === pipelineId) || EMPTY_OPTION}
            getOptionLabel={option => option.pipeline}
            style={{ backgroundColor: '#fff' }}
            autoHighlight
            renderInput={(params: any) => (
              <TextField {...params} placeholder="Pipeline" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FilterLabel>Year:</FilterLabel>
          <Autocomplete
            onChange={(_, o) => onFilterChange('year', o?.id ? o.id : null)}
            options={[EMPTY_OPTION, ...YEAR_OPTIONS]}
            value={YEAR_OPTIONS.find(o => o.id === year) || EMPTY_OPTION}
            getOptionLabel={o => o.name}
            style={{ backgroundColor: '#fff' }}
            autoHighlight
            renderInput={(params: any) => (
              <TextField {...params} placeholder="Year" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FilterLabel>Status:</FilterLabel>
          <Autocomplete
            onChange={(_, option) => onFilterChange('statusFlagId', option?.id ? option.id : null)}
            options={[EMPTY_OPTION, ...statusFlags]}
            value={statusFlags.find(o => o.id === statusFlagId) || EMPTY_OPTION}
            getOptionLabel={option => option.status}
            renderOption={option => <StatusFlagOption {...option} />}
            style={{ backgroundColor: '#fff' }}
            autoHighlight
            renderInput={params => {
              params.InputProps.startAdornment = (
                <>
                  <InputAdornment position="start">
                    <ProductVariantStatusFlag value={statusFlagId} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              );
              return <TextField {...params} placeholder="Status" variant="outlined" />;
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FilterLabel>Images:</FilterLabel>
          <Autocomplete
            onChange={(_, option) => onFilterChange('hasImages', option?.id as boolean | null)}
            options={IMAGES_FILTER_OPTIONS}
            value={IMAGES_FILTER_OPTIONS.find(o => o.id === hasImages) || EMPTY_OPTION}
            getOptionLabel={option => option.name}
            style={{ backgroundColor: '#fff' }}
            autoHighlight
            renderInput={params => {
              return <TextField {...params} placeholder="Images" variant="outlined" />;
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FilterLabel>Shopify Status:</FilterLabel>
          <Autocomplete
            onChange={(_, option) => onFilterChange('shopifyStatus', option || null)}
            options={['N/A', ...Object.values(ShopifyProductStatus)]}
            value={shopifyStatus ?? null}
            style={{ backgroundColor: '#fff' }}
            autoHighlight
            renderInput={params => {
              return <TextField {...params} placeholder="Shopify Status" variant="outlined" />;
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Filters;
