import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';

export default class UpdateFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketingPermissions: false,
    };
  }

  componentDidMount() {
    const marketingPermissions = JSON.parse(localStorage.getItem('userPermissions')).includes(10);

    this.setState({ marketingPermissions });
  }

  render() {
    return (
      <section className="home-wrap">
        {this.state.marketingPermissions ? (
          <Fragment>
            <Link className="home-link" to="/marketing/updateCollections">
              <section className="section-wrap">
                <h4>View/Update collections</h4>
                <span>Update filter categories on Shopify</span>
              </section>
            </Link>
          </Fragment>
        ) : (
          <span>
            Sorry, you do not have permissions to update site filters. Talk to your team leader to
            gain access
          </span>
        )}
      </section>
    );
  }
}
