import React, { useState } from 'react';
import PartnerReturnForm from './PartnerReturnForm';
import OfferUpdateForm from '../submissionForms/OfferUpdateForm';
import {
  Buyer,
  DeclineReason,
  Offer,
  ReactiveSettings,
  Status,
  Submission,
  SubmissionOfferTypes,
  SubmissionPaymentProvider,
} from '../../../typeORM';
import { useSnackbar } from '../../../components/library';
import {
  offerTypesConfig,
  PARTNER_VOUCHER_SITE_IDS,
  // TRADEUP_ONLY_SITE_IDS,
} from '../../../constants';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  Grid,
  Tooltip,
} from '@mui/material';
import SubmissionReturnForm from '../../service/SubmissionReturnForm';
import GenericFormModal from '../../../components/modals/genericFormModal';
import {
  FormatDateString,
  LastFourDiscountCode,
  FormatMoney,
  FormatPercentage,
  FormatDiscountCode,
} from '../../../utils/helper';
import { Typography } from '@mui/material';
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  CheckCircleOutline,
  MonetizationOnOutlined,
  ExpandMore,
} from '@material-ui/icons';
import { getAcceptedOffer } from '../helper';

import LabelValuePairDisplay from './LabelValuePairDisplay';

interface OfferBuyerInfoProps {
  submission: Submission;
  disciplineLabel: string | null | undefined;
  // lookups
  buyers: Buyer[];
  declineReasons: DeclineReason[];
  reactiveSettings: ReactiveSettings;
  statuses: Status[];
  // handlers
  putSubmission: (body: any) => Promise<any>;
  setLoading: (loading: boolean) => void;
  triggerRefetch: () => void;
}

const OfferBuyerInfo = (props: OfferBuyerInfoProps) => {
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const { snackbarOpen, handleSnackbarOpen, handleSnackbarClose } = useSnackbar();

  const { disciplineLabel, putSubmission, setLoading, submission, triggerRefetch } = props;

  const isSubmissionPartnerVoucherEligible =
    !!props.submission.partnerSiteId &&
    PARTNER_VOUCHER_SITE_IDS.includes(props.submission.partnerSiteId);
  // const isSubmissionTradeupOnly =
  //   !!props.submission.partnerSiteId &&
  //   TRADEUP_ONLY_SITE_IDS.includes(props.submission.partnerSiteId);

  const returnFormEl = isSubmissionPartnerVoucherEligible ? (
    <GenericFormModal
      closeModal={() => setIsReturnModalOpen(false)}
      form={
        <PartnerReturnForm
          id={submission.id}
          handleSubmit={() => {
            setModalSuccess(false);
            setModalError(false);
          }}
          handleSuccess={() => {
            setModalSuccess(true);
            triggerRefetch();
          }}
          handleError={() => setModalError(true)}
        />
      }
      error={modalError}
      success={modalSuccess}
    />
  ) : (
    //Note: 'as any' is used here because SYBReturnForm declares its property "id = null" in js
    //and typescript 4 VERY HELPFULLY assumes that must mean id is *typed* as 'null | undefined'.
    //To fix, we could convert SYBReturnForm to typescript and declare id as 'number | null | undefined'.
    <SubmissionReturnForm
      id={submission.id as any}
      closeModal={() => setIsReturnModalOpen(false)}
    />
  );

  const handleModalClose = () => {
    setIsUpdateModalOpen(false);
    setModalSuccess(false);
    setModalError(false);
  };

  const displayCustomerPaymentDetails = (offer: Offer) => {
    if (
      offer?.customerPaymentDetails?.payment_provider === SubmissionPaymentProvider.STORE_CREDIT
    ) {
      return (
        <Box sx={{ ml: 2 }}>
          {offer.paymentDate && (
            <Box key={offer.id}>
              <Typography variant="caption" fontWeight="bold">
                Paid On:
                <Typography variant="caption">{` ${FormatDateString(
                  offer.paymentDate,
                )}`}</Typography>
              </Typography>
            </Box>
          )}
          <Typography variant="caption" component="p" fontWeight="bold">
            Gift Cards
          </Typography>
          {offer.customerPaymentDetails?.gift_cards?.map(card => (
            <Tooltip key={card.discount_code} title={FormatDiscountCode(card.discount_code)}>
              <Typography variant="caption" component="p">
                {LastFourDiscountCode(card.discount_code)} - ${FormatMoney(card.amount_usd)}
              </Typography>
            </Tooltip>
          ))}
        </Box>
      );
    } else if (
      offer?.customerPaymentDetails?.consignment_details &&
      offer?.customerPaymentDetails?.payment_provider === SubmissionPaymentProvider.PAYPAL
    ) {
      return (
        <Box>
          <Box sx={{ ml: 2 }}>
            <Box>
              <Typography variant="caption" fontWeight="bold">
                PayPal Email:
              </Typography>{' '}
              {offer.customerPaymentDetails.paypal_email}
            </Box>
            {offer.estimatedPaymentDate && !offer.paymentDate && (
              <Box key={offer.id}>
                <Typography variant="caption" fontWeight="bold">
                  {offer.customerPaymentDetails.consignment_details.sale_details
                    ? 'Estimated '
                    : 'Fallback '}
                  Payment Date:
                  <Typography variant="caption">{` ${FormatDateString(
                    offer.estimatedPaymentDate,
                  )}`}</Typography>
                </Typography>
              </Box>
            )}
            {offer.paymentDate && (
              <Box key={offer.id}>
                <Typography variant="caption" fontWeight="bold">
                  Paid On:
                  <Typography variant="caption">{` ${FormatDateString(
                    offer.paymentDate,
                  )}`}</Typography>
                </Typography>
              </Box>
            )}
            {offer.customerPaymentDetails.consignment_details.sale_details
              ?.consignor_payment_amount_usd && (
              <Typography variant="caption" component="p">
                <Typography variant="caption" fontWeight="bold">
                  Amount Paid:
                </Typography>
                {` $${offer.customerPaymentDetails.consignment_details.sale_details?.consignor_payment_amount_usd}`}
              </Typography>
            )}
          </Box>
          {offer.customerPaymentDetails.consignment_details && (
            <Box>
              <Typography variant="caption" fontWeight="bold">
                Sale Details
              </Typography>
              <Box sx={{ ml: 2 }}>
                <Typography variant="caption" component="p">
                  <Typography variant="caption" fontWeight="bold">
                    Date Listed:
                  </Typography>
                  {` ${FormatDateString(
                    offer.customerPaymentDetails.consignment_details.date_listed,
                  )}`}
                </Typography>
                {offer.customerPaymentDetails.consignment_details.sale_details?.date_sold && (
                  <Typography variant="caption" component="p">
                    <Typography variant="caption" fontWeight="bold">
                      Date Sold:
                    </Typography>
                    {` ${FormatDateString(
                      offer.customerPaymentDetails.consignment_details.sale_details?.date_sold,
                    )}`}
                  </Typography>
                )}
                {offer.customerPaymentDetails.consignment_details.sale_details?.sale_price_usd && (
                  <Typography variant="caption" component="p">
                    <Typography variant="caption" fontWeight="bold">
                      Sale Price:
                    </Typography>
                    {` $${offer.customerPaymentDetails.consignment_details.sale_details?.sale_price_usd}`}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      );
    } else if (
      [
        SubmissionPaymentProvider.PAYPAL,
        SubmissionPaymentProvider.VENMO,
        SubmissionPaymentProvider.CHECK,
      ].includes(offer?.customerPaymentDetails?.payment_provider as SubmissionPaymentProvider)
    ) {
      return (
        <Box sx={{ ml: 2 }}>
          {offer.customerPaymentDetails?.paypal_email && (
            <Box>
              <Typography variant="caption" fontWeight="bold">
                PayPal Email:
              </Typography>{' '}
              {offer.customerPaymentDetails?.paypal_email}
            </Box>
          )}
          {offer.estimatedPaymentDate && !offer.paymentDate && (
            <Box key={offer.id}>
              <Typography variant="caption" fontWeight="bold">
                Estimated Payment Date:
                <Typography variant="caption">{` ${FormatDateString(
                  offer.estimatedPaymentDate,
                )}`}</Typography>
              </Typography>
            </Box>
          )}
          {offer.paymentDate && (
            <Box key={offer.id}>
              <Typography variant="caption" fontWeight="bold">
                Paid On:
                <Typography variant="caption">{` ${FormatDateString(
                  offer.paymentDate,
                )}`}</Typography>
              </Typography>
            </Box>
          )}
        </Box>
      );
    } else if (
      offer?.customerPaymentDetails?.payment_provider === SubmissionPaymentProvider.DEALER_TRADEIN
    ) {
      return (
        <Box>
          {offer.customerPaymentDetails.dealer && (
            <Box>
              <Typography variant="caption" fontWeight="bold">
                Dealer:
                <Typography variant="caption">{` ${offer.customerPaymentDetails.dealer.name} (${offer.customerPaymentDetails.dealer.id})`}</Typography>
              </Typography>
            </Box>
          )}
          {offer.estimatedPaymentDate && !offer.paymentDate && (
            <Box key={offer.id}>
              <Typography variant="caption" fontWeight="bold">
                Estimated Payment Date:
                <Typography variant="caption">{` ${FormatDateString(
                  offer.estimatedPaymentDate,
                )}`}</Typography>
              </Typography>
            </Box>
          )}
          {offer.paymentDate && (
            <Box key={offer.id}>
              <Typography variant="caption" fontWeight="bold">
                Paid On:
                <Typography variant="caption">{` ${FormatDateString(
                  offer.paymentDate,
                )}`}</Typography>
              </Typography>
            </Box>
          )}
        </Box>
      );
    } else if (
      offer?.customerPaymentDetails?.payment_provider === SubmissionPaymentProvider.PARTNER_VOUCHER
    ) {
      return (
        <Box>
          {offer.customerPaymentDetails.dealer && (
            <Box>
              <Typography variant="caption" fontWeight="bold">
                Partner Voucher Sent:
                <Typography variant="caption">
                  {` ${FormatDateString(
                    offer.customerPaymentDetails.partner?.partner_voucher_sent,
                  )}`}
                </Typography>
              </Typography>
            </Box>
          )}
          {offer.estimatedPaymentDate && !offer.paymentDate && (
            <Box key={offer.id}>
              <Typography variant="caption" fontWeight="bold">
                Estimated Payment Date:
                <Typography variant="caption">{` ${FormatDateString(
                  offer.estimatedPaymentDate,
                )}`}</Typography>
              </Typography>
            </Box>
          )}
          {offer.paymentDate && (
            <Box key={offer.id}>
              <Typography variant="caption" fontWeight="bold">
                Paid On:
                <Typography variant="caption">{` ${FormatDateString(
                  offer.paymentDate,
                )}`}</Typography>
              </Typography>
            </Box>
          )}
        </Box>
      );
    } else return null;
  };

  const showReturnButton = !submission.status?.toLowerCase().includes('failed');
  const submissionOffers = submission.activeOfferGroup?.offers;
  const offerFields = Object.keys(offerTypesConfig)
    .reduce(
      (
        result: {
          offerKey: string;
          label: string;
          amountUsd: string | null;
          availableToSeller: boolean;
          offer: Offer | null;
          offerAccepted: boolean;
          consignorSplit: string | null;
          estimatedPaymentRange: {
            minimumAmountUsd: string;
            maximumAmountUsd: string;
          } | null;
        }[],
        key: string,
      ) => {
        const offer = (submissionOffers ?? []).find(offer => offer.offerTypeName === key);
        const amountUsd = offer?.amountUsd ? FormatMoney(offer.amountUsd) : null;
        const acceptedOffer =
          submission?.activeOfferGroup?.offers &&
          getAcceptedOffer(submission.activeOfferGroup.offers);
        const availableToSeller =
          !!offer?.availableToSeller && key !== SubmissionOfferTypes.BASIS_ONLY;
        const consignorSplit = offer?.consignorSplit
          ? FormatPercentage(offer.consignorSplit)
          : null;
        const estimatedPaymentRange =
          offer?.estimatedPaymentRangeMultipliers &&
          submission?.expectedValue &&
          offer?.consignorSplit
            ? {
                minimumAmountUsd: FormatMoney(
                  (
                    parseFloat(submission.expectedValue) *
                    offer?.consignorSplit *
                    offer.estimatedPaymentRangeMultipliers.minimumSalePriceMultiplier
                  ).toFixed(0),
                ),
                maximumAmountUsd: FormatMoney(
                  (
                    parseFloat(submission.expectedValue) *
                    offer?.consignorSplit *
                    offer.estimatedPaymentRangeMultipliers.maximumSalePriceMultiplier
                  ).toFixed(0),
                ),
              }
            : null;
        result.push({
          offerKey: key,
          label: offerTypesConfig[key].label,
          amountUsd,
          availableToSeller,
          offerAccepted: !!acceptedOffer && acceptedOffer.offerTypeName === key,
          consignorSplit,
          estimatedPaymentRange,
          offer: !!acceptedOffer && acceptedOffer.offerTypeName === key ? acceptedOffer : null,
        });
        return result;
      },
      [],
    )
    .sort((a, b) => {
      const av =
        a.offerKey === SubmissionOfferTypes.BASIS_ONLY
          ? 4
          : Number(a.availableToSeller) + Number(a.offerAccepted);
      const bv =
        b.offerKey === SubmissionOfferTypes.BASIS_ONLY
          ? 4
          : Number(b.availableToSeller) + Number(b.offerAccepted);
      return bv - av;
    });

  return (
    <>
      {/* OfferBuyerInfo */}
      <Grid container direction="row" justifyContent="space-evenly">
        <Grid container justifyContent="space-between" alignItems="start" sx={{ mb: 2 }}>
          <Grid item xs={6}>
            {showReturnButton && (
              <Button
                color="success"
                variant="outlined"
                onClick={() => setIsReturnModalOpen(true)}
                sx={{ ml: 1 }}
              >
                Return Submission
              </Button>
            )}
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            <Button color="success" variant="contained" onClick={() => setIsUpdateModalOpen(true)}>
              Update
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          alignItems="start"
          sx={{ mb: 2 }}
        >
          <Grid item xs={4}>
            <LabelValuePairDisplay
              labelValuePairs={[
                { label: 'Buyer', value: submission.buyer?.name },
                { label: 'Status', value: submission.status },
                {
                  label: 'Expected Sale Price',
                  value: `$${FormatMoney(submission.expectedValue)}`,
                },
              ]}
            />
          </Grid>
          <Grid item xs={8}>
            <LabelValuePairDisplay
              labelValuePairs={[
                { label: 'Internal Note', value: submission.internalNote },
                {
                  label: 'Most Recent Msg Subject',
                  value: submission.declineReason ? submission.declineReason.name : undefined,
                },
                {
                  label: 'External Notes',
                  value: submission.notes.length ? (
                    <Box>
                      {submission.notes.map(note => {
                        return (
                          <Accordion key={note.id}>
                            <AccordionSummary sx={{ height: 1 }} expandIcon={<ExpandMore />}>
                              <Typography
                                variant="caption"
                                sx={{
                                  width: '80%',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-inline-box',
                                  WebkitLineClamp: '1',
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {note.type} - {note.entry}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="GrayText"
                                sx={{ display: '-webkit-inline-box', ml: 1 }}
                              >
                                {`- ${new Date(note.createdAt).toLocaleDateString()}`}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <p dangerouslySetInnerHTML={{ __html: note.entry }} />
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </Box>
                  ) : null,
                },
              ]}
              gridSize={{ label: 3, value: 9 }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
          className="doo-display"
        >
          <Typography variant="h6" flexBasis="100%" fontWeight="bold" className="label">
            Offers:
          </Typography>

          {offerFields.map(offerDisplay => {
            return (
              offerDisplay.amountUsd && (
                <Box
                  key={offerDisplay.offerKey}
                  flexBasis="30%"
                  sx={{
                    mr: 1,
                    mb: 1,
                    p: 1,
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    borderColor: offerDisplay.availableToSeller
                      ? offerDisplay.offerAccepted
                        ? '#086BE2'
                        : '#00c160'
                      : offerDisplay.offerKey === SubmissionOfferTypes.BASIS_ONLY
                      ? 'ddd'
                      : '#d80202',
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" className="label">
                    {offerDisplay.availableToSeller ? (
                      offerDisplay.offerAccepted ? (
                        <CheckCircleOutline
                          fontSize="small"
                          style={{ color: '#086BE2' }}
                          color="inherit"
                        />
                      ) : (
                        <VisibilityOutlined fontSize="small" color="primary" />
                      )
                    ) : offerDisplay.offerKey === SubmissionOfferTypes.BASIS_ONLY ? (
                      <MonetizationOnOutlined fontSize="small" color="secondary" />
                    ) : (
                      <VisibilityOffOutlined fontSize="small" color="error" />
                    )}
                    {offerDisplay.label}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      width: 'fit-content',
                      ml: 2,
                    }}
                  >
                    {`$${offerDisplay.amountUsd}`}
                  </Typography>

                  {offerDisplay.consignorSplit && (
                    <Typography sx={{ ml: 2 }} variant="body2">
                      <Typography variant="caption" fontWeight="bold">
                        Consignor Split
                      </Typography>
                      <Typography variant="caption">{` ${offerDisplay.consignorSplit}`}</Typography>
                    </Typography>
                  )}
                  {offerDisplay.estimatedPaymentRange && (
                    <Typography sx={{ ml: 2 }} variant="body2">
                      <Typography variant="caption" fontWeight="bold">
                        Est. Payment Range
                      </Typography>
                      <Typography variant="caption">
                        {` $${offerDisplay.estimatedPaymentRange?.minimumAmountUsd} - $${offerDisplay.estimatedPaymentRange?.maximumAmountUsd}`}
                      </Typography>
                    </Typography>
                  )}
                  {offerDisplay.offer?.customerPaymentDetails && (
                    <Typography sx={{ ml: 2 }} variant="body2">
                      <Typography variant="caption" fontWeight="bold">
                        Payment Details
                      </Typography>
                      <Box>{displayCustomerPaymentDetails(offerDisplay.offer)}</Box>
                    </Typography>
                  )}
                </Box>
              )
            );
          })}
        </Grid>
      </Grid>
      {/* ReturnModal */}
      {/* todo: this modal renders its own modal...wat */}
      {isReturnModalOpen && returnFormEl}
      <Dialog
        open={isUpdateModalOpen}
        onClose={() => setIsUpdateModalOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <Box>
          <OfferUpdateForm
            closeModal={handleModalClose}
            disciplineLabel={disciplineLabel}
            handleError={() => setModalError(true)}
            handleSnackbarOpen={handleSnackbarOpen}
            handleSnackbarClose={handleSnackbarClose}
            handleSuccess={() => setModalSuccess(true)}
            putSubmission={putSubmission}
            setLoading={setLoading}
            snackbarOpen={snackbarOpen}
            submission={submission}
            triggerRefetch={triggerRefetch}
            // lookups
            buyers={props.buyers}
            declineReasons={props.declineReasons}
            reactiveSettings={props.reactiveSettings}
            statuses={props.statuses}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default OfferBuyerInfo;
