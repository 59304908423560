import React, { useMemo } from 'react';
import FormikTextInput, { TextInputProps } from './FormikTextInput';
import { useField } from 'formik';
import styled from 'styled-components';

export interface FormikAppendUnitsProps extends TextInputProps {
  unitSuffix: string;
}

interface Args {
  value: number | null;
  unit: string;
}

const displayUnitValue = ({ value, unit }: Args): string => {
  const onlyContainsNumber = /^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/;
  if (!value) {
    return '';
  } else if (onlyContainsNumber.test(value.toString())) {
    return `${value} ${unit}`;
  }
  return `${value}`;
};

const AppendUnitsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const H5 = styled.h5`
  margin-left: 10px;
  margin-bottom: 6px;
`;

// component displays units in UI but doesn't include them in form submissions since the logic in ca_spec_value_maps already adds units in shopify
const FormikAppendUnits: React.FC<FormikAppendUnitsProps & { name: string }> = ({
  name,
  label,
  unitSuffix,
  ...rest
}) => {
  const valueFieldName = `${name}`;
  const [valueField] = useField(valueFieldName);

  const displayValue = useMemo(
    () => displayUnitValue({ value: valueField.value, unit: unitSuffix }),
    [valueField.value, unitSuffix],
  );

  return (
    <AppendUnitsWrapper>
      <FormikTextInput
        name={valueFieldName}
        type="string"
        label={`${label} (${unitSuffix})`}
        {...rest}
      />
      <H5>{displayValue}</H5>
    </AppendUnitsWrapper>
  );
};

export default FormikAppendUnits;
