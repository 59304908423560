/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import MaterialTable, { Icons } from '@material-table/core';

import deburr from 'lodash/deburr';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Autosuggest from 'react-autosuggest';
import { NotificationManager } from 'react-notifications';
import { UpcSearchModal } from './UpcSearchModal';

import {
  AddBox,
  ArrowUpward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons';
import axios from '../../utils/axios';
import lock from '../../images/lock.svg';
import unlock from '../../images/unlock.svg';
import { Button } from '@material-ui/core';

import { handleApiError } from '../../pages/catalog/utils';

import { API_URL } from '../../constants';
import { validateSkuViaCatalogSearch } from './relocator/multi-relocate';

const searchAPI = (searchTerm: string, locationId: number) =>
  axios.get(`${API_URL}/service/bins?searchTerm=${searchTerm}&locationId=${locationId}`);
//Usage of this debounce library is deprecated - can be replaced with lodash's debounce function
const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 500);

function renderSuggestion(suggestion: any) {
  return <span>{suggestion.code}</span>;
}

interface Xfer {
  id: number;
  fromBin: string;
  bin: string;
  quantityToMove: number;
  sku: string;
}

interface BinSearchResult {
  code: string; //bin code
  quantity: string; //value will be numeric
  item: string; //sku
  locationId: string; //value will be numeric
  binId: string; //value will be numeric
}

interface RelocatorState {
  warehouses: [];
  upcWarning?: string;
  skuBins: BinSearchResult[];
  fromLocation: string;
  locationId: number;
  sku: string;
  quantity: number;
  quantityToMove: number;
  loading: boolean;
  errMsg: string | string[] | undefined;
  fromLocationLocked: boolean;
  locationLocked: boolean;
  binLocked: boolean;
  fromBinLocked: boolean;
  searchTerm: string;
  fromBin: string;
  bin: string;
  binSuggestions: string[];
  cachedOptions: [];
  xferArr: Xfer[];
  upcModalOpen: boolean;
}

interface RelocatorProps {
  sku: string;
  classes: any;
}

export default class MultiRelocator extends React.Component<RelocatorProps, RelocatorState> {
  constructor(props: RelocatorProps) {
    super(props);
    this.state = {
      warehouses: [],
      skuBins: [],
      fromLocation: '',
      locationId: 0,
      sku: props.sku ? props.sku : '',
      loading: false,
      errMsg: '',
      fromLocationLocked: false,
      locationLocked: false,
      fromBinLocked: false,
      binLocked: false,
      searchTerm: '',
      quantity: 0,
      quantityToMove: 1,
      fromBin: '',
      bin: '',
      xferArr: [],
      binSuggestions: [],
      cachedOptions: [],
      upcModalOpen: false,
    };
  }

  async fetchWarehouses() {
    const result = await axios.get(`${API_URL}/service/locations`);
    if (result) {
      this.setState({
        warehouses: result.data,
      });
    }
  }

  deleteXferItem = (xferObj: { id: number }): Promise<void> => {
    const { id } = xferObj;
    const { xferArr } = this.state;
    const newArr = xferArr.filter((x: any) => x.id !== id);
    this.setState({
      xferArr: newArr,
    });
    return Promise.resolve();
  };

  componentDidMount() {
    this.fetchWarehouses();
  }

  resetState = () => {
    this.setState(prevState => ({
      fromLocation: prevState.locationLocked ? prevState.fromLocation : '',
      locationId: prevState.locationLocked ? prevState.locationId : 0,
      bin: prevState.binLocked ? prevState.bin : '',
      fromBin: '',
      skuBins: [],
      sku: '',
      loading: false,
      errMsg: '',
      quantityToMove: 1,
    }));
  };

  handleSubmit = async (e: any) => {
    e.preventDefault();
    this.setState({
      loading: true,
      errMsg: '',
    });

    const { xferArr, locationId, fromLocation } = this.state;

    try {
      const data = {
        xferArr,
        fromLocation,
        locationId,
      };
      await axios.post(`${API_URL}/service/multiRelocate`, data);
      NotificationManager.info(`Inventory successfully moved.`);
      this.setState({ xferArr: [] });
      this.resetState();
    } catch (error) {
      const setError = (errMsg: string | string[] | undefined) => this.setState({ errMsg });
      handleApiError(error, setError);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  //Usage of this library is deprecated - can be replaced with lodash's debounce function
  updateForm = AwesomeDebouncePromise(
    async () => {
      const { sku, fromLocation } = this.state;
      if (sku.length > 7 && fromLocation) {
        try {
          this.setState({
            loading: true,
            upcWarning: undefined,
          });
          const options = {
            params: {
              sku,
              fromLocation,
            },
          };
          const { valid, upcWarning } = await validateSkuViaCatalogSearch(sku);
          this.setState(prevState => ({ ...prevState, upcWarning }));
          if (valid) {
            const result = await axios.get(`${API_URL}/service/skuBins`, options);
            if (result) {
              this.setState(prevState => ({ ...prevState, skuBins: result.data }));
            }
          }
        } catch (error) {
          const setError = (errMsg: string | string[] | undefined) => this.setState({ errMsg });
          handleApiError(error, setError);
        } finally {
          this.setState({
            loading: false,
          });
        }
      } else {
        this.setState(prevState => ({ ...prevState, upcWarning: undefined }));
      }
    },
    500,
    { leading: true },
  );

  handleChange = (e: any) => {
    const targetId: string = e.target.id;
    const targetValue: any = e.target.valueAsNumber || e.target.value;
    this.setState(
      prevState => ({
        ...prevState,
        [targetId]: targetValue,
      }),
      this.updateForm,
    );
  };

  handleKeyDown = (e: any) => {
    if (e.keyCode == 13) e.preventDefault();
  };

  handleBinChange = (_event: any, { newValue }: any) => {
    const trimmedVal = newValue.trim();
    this.setState({
      bin: trimmedVal,
    });
  };

  handleSkuByUpc = (value: string) => {
    this.setState({ sku: value, upcModalOpen: false }, this.updateForm);
  };

  render() {
    const {
      locationId,
      fromLocation,
      sku,
      warehouses,
      loading,
      fromLocationLocked,
      locationLocked,
      binLocked,
      fromBin,
      bin,
      binSuggestions,
      skuBins,
      quantityToMove,
    } = this.state;

    const tableIcons: Icons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    };

    const getSuggestions = async (value: any): Promise<string[]> => {
      const inputValue = deburr(value.trim()).toLowerCase();
      const inputLength = inputValue.length;

      let result: any;
      if (inputLength > 3) {
        if (!this.state.cachedOptions.length) {
          const res = await searchAPIDebounced(inputValue, locationId);
          result = res ? res.data : [];
          this.setState(prevState => ({
            ...prevState,
            cachedOptions: result,
          }));
        } else {
          result = this.state.cachedOptions.filter((suggestion: any) => {
            return suggestion.code.slice(0, inputLength).toLowerCase() === inputValue;
          });
        }
      } else {
        this.setState(prevState => ({
          ...prevState,
          cachedOptions: [],
        }));
      }
      return result || [];
    };

    const getSuggestionValue = (suggestion: any) => {
      return suggestion.code;
    };

    const handleSuggestionsFetchRequested = async ({ value }: any) => {
      const suggestions = await getSuggestions(value);
      this.setState(prevState => ({
        ...prevState,
        binSuggestions: suggestions,
      }));
    };

    const handleSuggestionsClearRequested = () => {
      this.setState(prevState => ({
        ...prevState,
        binSuggestions: [],
      }));
    };

    const addItemToTable = () => {
      const { fromBin, bin, xferArr, sku, quantityToMove } = this.state;
      if (!fromBin) {
        NotificationManager.error('Select a from bin', 'error');
      } else if (!bin) {
        NotificationManager.error('Select a to bin', 'error');
      } else if (!fromLocation) {
        NotificationManager.error('Select a from location', 'error');
      } else if (!locationId) {
        NotificationManager.error('Select a to location', 'error');
      } else if (!sku) {
        NotificationManager.error('Enter a SKU', 'error');
      } else {
        const xferObj: Xfer = {
          fromBin,
          bin,
          id: xferArr.length,
          sku,
          quantityToMove,
        };
        this.setState(prevState => ({ xferArr: [...prevState.xferArr, xferObj] }));
        this.resetState();
      }
    };

    const inputProps = {
      placeholder: 'Enter bin',
      value: bin,
      name: 'bin',
      id: 'bin',
      style: {
        paddingLeft: '5px',
        height: '33px',
        border: '1px solid black',
        width: '300px',
      },
      onChange: this.handleBinChange,
      onKeyDown: this.handleKeyDown,
    };

    return (
      <main
        style={{
          marginTop: '100px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <form className="flex-column min-width-form-wrap" onSubmit={this.handleSubmit}>
          <span>
            <label htmlFor="fromLocationLocked" className="label">
              Lock From Location?
            </label>
            <input
              id="fromLocationLocked"
              type="checkbox"
              checked={fromLocationLocked}
              onChange={() => {
                this.setState({ fromLocationLocked: !fromLocationLocked });
              }}
              onKeyDown={this.handleKeyDown}
            />
          </span>
          <span className="flex-row">
            <select
              style={{ flex: 1 }}
              className="text-input margin-bottom"
              id="fromLocation"
              value={fromLocation}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
            >
              <option value={''} disabled={true}>
                Select From Location
              </option>
              {warehouses.map((w: any) => {
                return (
                  <option key={w.id} value={w.code}>
                    {w.code}
                  </option>
                );
              })}
            </select>
            <img
              style={{ height: '20px', margin: '5px 3px' }}
              src={fromLocationLocked ? lock : unlock}
            />
          </span>
          <span>
            <label htmlFor="locationLocked" className="label">
              Lock Location?
            </label>
            <input
              id="locationLocked"
              type="checkbox"
              checked={locationLocked}
              onChange={() => {
                this.setState({ locationLocked: !locationLocked });
              }}
              onKeyDown={this.handleKeyDown}
            />
          </span>

          <span className="flex-row">
            <select
              style={{ flex: 1 }}
              className="text-input margin-bottom"
              id="locationId"
              value={locationId}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
            >
              <option value={0} disabled={true}>
                Select To Location{' '}
              </option>
              {warehouses.map((w: any) => {
                return (
                  <option key={w.id} value={w.id}>
                    {w.code}
                  </option>
                );
              })}
            </select>
            <img
              style={{ height: '20px', margin: '5px 3px' }}
              src={locationLocked ? lock : unlock}
            />
          </span>
          <span className="flex-row" style={{ justifyContent: 'start' }}>
            <input
              style={{ flex: 1 }}
              className="text-input margin-bottom"
              autoComplete="off"
              type="text"
              placeholder="Enter SKU"
              id="sku"
              value={sku}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
            />
            <Button onClick={() => this.setState({ upcModalOpen: true })}>Search by UPC</Button>
            {this.state.upcModalOpen && (
              <UpcSearchModal
                open={this.state.upcModalOpen}
                handleClose={() => this.setState({ upcModalOpen: false })}
                setSku={this.handleSkuByUpc}
                initialUpc={this.state.sku}
              />
            )}
          </span>
          {this.state.upcWarning && (
            <span className="flex-row" style={{ justifyContent: 'start' }}>
              {this.state.upcWarning === 'INVALID' && (
                <p style={{ color: 'darkred' }}>This is not a valid sku or upc.</p>
              )}
              {this.state.upcWarning === 'MULTIPLE_SKUS' && (
                <p style={{ color: 'darkred' }}>
                  This upc matches multiple skus.
                  <br />
                  Please select one sku via upc search.
                </p>
              )}
            </span>
          )}
          <span className="flex-row">
            <select
              style={{ flex: 1 }}
              disabled={loading}
              className="text-input margin-bottom"
              id="fromBin"
              value={fromBin}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
            >
              <option value={0}>Select From Bin </option>
              {skuBins.map((sb: { code: string; quantity: string }) => {
                return (
                  <option key={sb.code} value={sb.code}>
                    {'Bin: ' + sb.code + ' -- Qty: ' + sb.quantity}
                  </option>
                );
              })}
            </select>
          </span>
          <span className="flex-row" style={{ justifyContent: 'start' }}>
            <input
              style={{ flex: 1 }}
              className="text-input margin-bottom"
              autoComplete="off"
              type="number"
              placeholder="Enter the quantity to move"
              id="quantityToMove"
              value={quantityToMove}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
            />
            <aside
              style={{
                height: '20px',
                width: '20px',
                margin: '5px 3px',
                color: 'white',
              }}
            />
          </span>
          <span>
            <label htmlFor="binLocked" className="label">
              Lock Bin?
            </label>
            <input
              id="binLocked"
              type="checkbox"
              checked={binLocked}
              onChange={() => {
                this.setState({ binLocked: !binLocked });
              }}
              onKeyDown={this.handleKeyDown}
            />
          </span>
          <span className="flex-row margin-bottom" style={{ flex: 1 }}>
            <Autosuggest
              suggestions={binSuggestions}
              onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
              onSuggestionsClearRequested={handleSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
            />
            <img style={{ height: '20px', margin: '5px 3px' }} src={binLocked ? lock : unlock} />
          </span>
          <input
            className="classy-button"
            style={{ textAlign: 'center', marginBottom: 10 }}
            value="Add Item"
            disabled={loading}
            onClick={() => {
              addItemToTable();
            }}
          />
          <input
            className="classy-button"
            value="Submit Relocation"
            type="submit"
            disabled={loading}
          />
          <p style={{ width: 300 }}>
            {Array.isArray(this.state.errMsg) ? this.state.errMsg.join(', ') : this.state.errMsg}
          </p>
        </form>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'From Bin',
              field: 'fromBin',
              editable: 'always',
            },
            {
              title: 'To Bin',
              field: 'bin',
              editable: 'always',
            },
            {
              title: 'Sku',
              field: 'sku',
              editable: 'always',
            },
            {
              title: 'Quantity to Move',
              field: 'quantityToMove',
              editable: 'always',
            },
          ]}
          editable={{
            isDeletable: undefined,
            isEditable: undefined,
            onRowAdd: undefined,
            onRowUpdate: undefined,
            onRowDelete: (oldData: any) => this.deleteXferItem(oldData),
          }}
          title="Summary"
          data={this.state.xferArr}
          options={{ paging: false, sorting: false, search: false }}
          style={{ minWidth: '50%' }}
        />
      </main>
    );
  }
}
