import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    bottom: 0,
    right: 0,
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2rem 4rem',
    backgroundColor: theme.palette.common.white,
    width: '100%',
  },
}));

const ActionButtonWrapper = styled.span`
  margin-left: 1rem;
`;

interface FloatingActionBarProps {
  primaryActionButton?: React.ReactElement;
  secondaryActionButton?: React.ReactElement;
  tertiaryActionButton?: React.ReactElement;
}

export const FloatingActionBar: React.FC<FloatingActionBarProps> = ({
  primaryActionButton,
  secondaryActionButton,
  tertiaryActionButton,
}) => {
  return (
    <Paper elevation={3} classes={useStyles()}>
      <Box>
        {tertiaryActionButton && <ActionButtonWrapper>{tertiaryActionButton}</ActionButtonWrapper>}
      </Box>
      <Box>
        {secondaryActionButton && (
          <ActionButtonWrapper>{secondaryActionButton}</ActionButtonWrapper>
        )}
        <ActionButtonWrapper>{primaryActionButton}</ActionButtonWrapper>
      </Box>
    </Paper>
  );
};
