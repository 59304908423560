import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { getProductFilters } from '../../actions';
import { API_URL } from '../../constants';
import axios from '../../utils/axios';
import DynamicForm from '../../components/dynamic_form';
import { FormatMoney } from '../../utils/helper';
import Modal from '../../components/modals/genericFormModal';
import noImage from '../../images/camera-off.svg';
import * as catalogService from '../../services/catalog/services';
import { renderCellExpand } from '../../components/library/DataGrid/renderCellExpand';

import {
  GridPagination,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import { StripedDataGrid } from '../../styledComponents/datagrid';
import { borderColor } from '@mui/system';
import { Box } from '@material-ui/core';
import { PageWrapper } from '../../styledComponents/wrappers';

const thumbnailStyle = {
  backgroundColor: '#fff',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  position: 'absolute',
  top: 0,
  width: '70%',
  height: '70%',
  border: '2px solid',
};

class ThreeSixtyView extends Component {
  constructor() {
    super();
    this.state = {
      displayModal: false,
      error: false,
      success: false,
      searchNotInitiated: true,
      products: [],
      loading: false,
      productCategoryOptions: [],
      sortModel: [],
      totalCount: 0,
      thumbnail: '',
      displayThumbnail: false,
      thumbnailPosition: { left: 0, top: 0 },
      paginationModel: { page: 0, pageSize: 25 },
      columnVisibilityModel: {
        id: false,
        brokerId: false,
        status: false,
      },
      filters: {
        searchTerm: '',
        conjoined: true,
        excludedTerm: '',
        productCategoryId: '',
      },
    };
  }

  componentDidMount() {
    this.fetchCategories();
    this.retrieveColumnSettings();
  }

  CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ justifyContent: 'right' }}>
        <GridToolbarColumnsButton />
        <GridPagination sx={{ mx: 4 }} />
      </GridToolbarContainer>
    );
  }

  columns = () => [
    {
      headerName: 'SKU',
      field: 'sku',
      flex: 1,
      renderCell: renderCellExpand,
      sortable: false,
    },
    {
      headerName: 'Images',
      field: 'images',
      flex: 1,
      renderCell: params => (
        <div style={{ height: '30px', marginTop: '-10px' }}>
          <span onMouseEnter={this.handleHover} onMouseLeave={this.handleMouseOut}>
            <img
              alt="thumbnail of bike"
              style={{ display: 'block', height: '40px', margin: '0 auto' }}
              src={this.parseImageUrl(params.value)}
            />
          </span>
        </div>
      ),
      sortable: false,
    },
    {
      headerName: 'Title',
      field: 'title',
      flex: 3,
      renderCell: renderCellExpand,
      sortable: false,
    },
    {
      headerName: 'Brand',
      field: 'brand',
      flex: 1,
      renderCell: renderCellExpand,
      sortable: false,
    },
    {
      headerName: 'Cost',
      field: 'cost',
      flex: 1,
      renderCell: params => {
        renderCellExpand(params);
        return `$${FormatMoney(params.value)}`;
      },
    },
    {
      headerName: 'Starting Price',
      field: 'starting_price',
      flex: 1,
      renderCell: params => {
        renderCellExpand(params);
        return `$${FormatMoney(params.value)}`;
      },
    },
    {
      headerName: 'UPC',
      field: 'upc',
      flex: 1,
      renderCell: renderCellExpand,
      sortable: false,
    },
    {
      headerName: 'Sold Price (last)',
      field: 'last_sold_price',
      flex: 1,
      renderCell: params => {
        renderCellExpand(params);
        return `$${FormatMoney(params.value)}`;
      },
      defaultSortDesc: true,
    },
    {
      headerName: 'Created On',
      field: 'product_created',
      flex: 1,
      renderCell: params => {
        renderCellExpand(params);
        return new Date(params.value).toLocaleDateString('en-US', { timeZone: 'America/Denver' });
      },
      defaultSortDesc: true,
    },
    {
      headerName: 'Published On',
      field: 'published_on',
      flex: 1,
      renderCell: params => {
        renderCellExpand(params);
        return params.value
          ? new Date(params.value).toLocaleDateString('en-US', { timeZone: 'America/Denver' })
          : 'N/A';
      },
      defaultSortDesc: true,
    },
    {
      headerName: 'Days to Sale',
      field: 'days_to_sale',
      flex: 1,
      defaultSortDesc: true,
    },
    {
      headerName: 'ESPA', // Estimated Sale Price, Adjusted
      field: 'espa',
      flex: 1,
      renderCell: params => {
        renderCellExpand(params);
        return params.value || params.value === 0 ? `${params.value}%` : '';
      },
      defaultSortDesc: true,
    },
    {
      headerName: 'Sold Date (last)',
      field: 'last_sold_date',
      flex: 1,
      renderCell: params => {
        renderCellExpand(params);
        return params.value
          ? new Date(params.value).toLocaleDateString('en-US', { timeZone: 'America/Denver' })
          : 'N/A';
      },
      defaultSortDesc: true,
    },
  ];

  storeColumnSettings = columnVisibilityModel => {
    this.setState({ columnVisibilityModel: columnVisibilityModel });
    const stringColumns = JSON.stringify(columnVisibilityModel);
    window.localStorage.setItem('360columns', stringColumns);
  };

  retrieveColumnSettings = () => {
    const settings = window.localStorage.getItem('360columns');
    const parsedSettings = JSON.parse(settings);
    if (parsedSettings) {
      this.setState({ columnVisibilityModel: parsedSettings });
    }
  };

  parseImageUrl = (string = '') => {
    const url = string?.split('ITEMIMAGEURL1=');
    const imageLink = url && url[1] ? url[1].split(',')[0] : noImage;
    return imageLink;
  };

  handleHover = e => {
    const regex = /amazonaws/g;
    if (e.target.src) {
      const match = e.target.src.match(regex);
      if (match) {
        this.setState({
          thumbnail: e.target.src,
          displayThumbnail: true,
          thumbnailPosition: { left: e.clientX + 30, top: 30 },
        });
      }
    }
  };

  handleMouseOut = () => {
    this.setState({ displayThumbnail: false });
  };

  fetchCategories = async () => {
    catalogService.fetchProductCategories({
      onSuccess: productCategories => {
        const productCategoryOptions = productCategories.map(pc => ({
          value: pc.id,
          display: pc.category,
        }));
        this.setState({
          productCategoryOptions: productCategoryOptions,
        });
      },
    });
  };

  fetchProducts = debounce(async () => {
    this.setState({ loading: true, searchNotInitiated: false });
    const { filters, sortModel, paginationModel } = this.state;
    const sortBy = sortModel?.[0]?.field ?? 'product_created';
    const sortOrder = sortModel?.[0]?.sort.toUpperCase() ?? 'DESC';

    const url = `${API_URL}/products/searchCatalog`;
    const response = await axios.get(url, {
      params: {
        page: paginationModel.page,
        searchTerm: filters.searchTerm,
        conjoined: filters.conjoined,
        excludedTerm: filters.excludedTerm,
        productCategoryId: filters.productCategoryId,
        sortBy,
        sortOrder,
      },
    });
    const { searchResults, count, pages } = response.data;
    this.setState({
      products: searchResults,
      loading: false,
      pages,
      totalCount: count,
    });
  }, 600);

  buildImage = () => {
    return (
      <div
        style={{
          ...thumbnailStyle,
          ...this.state.thumbnailPosition,
          backgroundImage: `url(${this.state.thumbnail})`,
        }}
      />
    );
  };

  // Filter functions
  storeFilters(name, value) {
    const newFilters = { ...this.state.filters, [name]: value };
    this.setState({
      paginationModel: { ...this.state.paginationModel, page: 0 },
      filters: newFilters,
    });
    this.fetchProducts();
  }

  handleFilterChange = e => {
    const { name, type, checked } = e.target;
    const value = type === 'checkbox' ? checked : e.target.value;
    let clearFilters = false;
    if (name === 'searchTerm' && !!!value) {
      clearFilters = window.confirm('Do you want to clear your search filters?');
    }
    if (!clearFilters) {
      // Clears out text input if select input changes
      if (name === 'productCategoryId') {
        this.storeFilters(name, value, true);
      } else {
        this.storeFilters(name, value);
      }
    } else {
      this.handleFilterClear(e);
    }
  };

  handleFilterSubmit = e => {
    e.preventDefault();
    this.storeFilters(this.state.filters);
    this.fetchProducts();
  };

  handleFilterClear = e => {
    e.preventDefault();
    this.setState(prevState => ({
      ...prevState,
      products: [],
      searchNotInitiated: true,
      totalCount: undefined,
      filters: {
        searchTerm: '',
        conjoined: true,
        excludedTerm: '',
        productCategoryId: '',
      },
    }));
  };
  // End of filter functions

  getCategoryField() {
    return {
      placeholder: 'Narrow search by category',
      type: 'select',
      name: 'productCategoryId',
      label: 'Category',
      value: this.state.filters.productCategoryId,
      onChange: this.handleFilterChange,
      options: this.state.productCategoryOptions,
    };
  }

  render() {
    const { displayModal, displayThumbnail } = this.state;
    return (
      <PageWrapper style={{ overflow: 'hidden' }}>
        <DynamicForm
          fields={[
            {
              type: 'checkbox',
              name: 'conjoined',
              label: 'Inclusive Search',
              tooltip: 'Search for ALL terms. Uncheck to search for ANY term.',
              flagField: true,
              value: this.state.filters.conjoined,
              onChange: this.handleFilterChange,
            },
            {
              placeholder: 'Search by title or SKU',
              type: 'text',
              name: 'searchTerm',
              label: 'Names',
              value: this.state.filters.searchTerm,
              onChange: this.handleFilterChange,
            },
            {
              placeholder: 'Filter out terms',
              type: 'text',
              name: 'excludedTerm',
              label: 'Excluded',
              value: this.state.filters.excludedTerm,
              onChange: this.handleFilterChange,
            },
            this.getCategoryField(),
          ]}
          onChange={this.handleFilterChange}
          onSubmit={this.handleFilterSubmit}
          onClear={this.handleFilterClear}
        />
        <Box sx={{ width: '98%', height: '100%', mx: 'auto' }}>
          <StripedDataGrid
            sx={{ border: 0, borderBottom: 2, borderRadius: 0, borderColor: '#aaa' }}
            height={!!!this.state.products.length ? '30rem' : 'auto'}
            searchNotInitiated={this.state.searchNotInitiated}
            columns={this.columns()}
            getRowId={row => row.sku}
            rows={this.state.products}
            rowCount={this.state.totalCount}
            loading={this.state.loading}
            pagination
            paginationMode="server"
            paginationModel={this.state.paginationModel}
            onPaginationModelChange={paginationModel => {
              this.setState({ paginationModel: paginationModel });
              this.fetchProducts();
            }}
            pageSizeOptions={[25]}
            sortingMode="server"
            sortingOrder={['desc', 'asc', null]}
            sortModel={this.state.sortModel}
            onSortModelChange={model => {
              this.setState({ sortModel: model });
              this.fetchProducts();
            }}
            onRowClick={params => window.open(`/products/360view/${params.row.sku}`, '_blank')}
            disableDensitySelector={true}
            disableColumnFilter={true}
            disableColumnMenu={true}
            columnVisibilityModel={this.state.columnVisibilityModel}
            onColumnVisibilityModelChange={newModel => this.storeColumnSettings(newModel)}
            hideFooter={true}
            slots={{
              toolbar: this.CustomToolbar,
            }}
          />
        </Box>
        {displayThumbnail && this.buildImage()}
      </PageWrapper>
    );
  }
}

export default withRouter(ThreeSixtyView);
