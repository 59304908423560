import React from 'react';
import PropTypes from 'prop-types';
import { styleStatus } from '../helper';
import { DataGrid } from '../../../components/library';

const columns = [
  {
    headerName: 'Buyer',
    field: 'buyer',
    flex: 2,
  },
  {
    headerName: 'Offer State',
    field: 'offerState',
    renderCell: ({ value }) => (
      <span>
        <span style={{ color: styleStatus(value), margin: '0 5px' }}>&#x25cf;</span>
        {value}
      </span>
    ),
    flex: 1,
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 3,
  },
  {
    headerName: 'Expected Sale Price',
    field: 'expectedPrice',
    flex: 1,
  },
  {
    headerName: 'Offer Amount',
    field: 'offerAmount',
    flex: 1,
  },
  {
    headerName: 'Created',
    field: 'created',
    valueFormatter: ({ value }) =>
      new Date(value).toLocaleDateString('en-US', { timeZone: 'America/Denver' }),
    flex: 2,
  },
];
const historyInfo = ({ submissionHistory }) => (
  <DataGrid rows={submissionHistory} columns={columns} getRowId={row => Date.parse(row.created)} />
);

export default historyInfo;

historyInfo.propTypes = {
  submissionHistory: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
