import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import { withRouter, RouteComponentProps, Link as RouterLink, Prompt } from 'react-router-dom';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { PageWrapper } from '../../../styledComponents/wrappers';
import { PageHeader } from '../../../styledComponents/headers';
import * as actions from '../../../actions';
import EditProductVariant from './EditProductVariant';
import ButtonSection from './ButtonSection';
import {
  updateProduct,
  patchProductVariant,
  updateShopifyBaseProduct,
  updateProductPromotionalTexts,
} from '../../../services/catalog/services';
import Loader from '../../../components/loader';
import { EditProductValues, promotionalTextType } from '../utils/types';
import { GlobalState } from '../../../reducers/types';
import DisplayField from '../../../components/catalog/DisplayField';
import { showAllPromoTextCategories } from '../utils/formFieldsMap';
import { editProductValidation } from '../utils/validation';
import ReadyForShopifyPublish from '../../../components/catalog/ReadyForShopifyPublish';
import useProduct from '../utils/useProduct';
import { handleApiError, displayCase } from '../utils';
import { cleanupNewlines } from '../utils/buildPromotionalTextsPayload';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Tooltip,
  createStyles,
  makeStyles,
  Theme,
  ButtonGroup,
} from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { ErrorMessage } from '../../../components/library';
import {
  Button,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import CircleSuccess from '../../../components/icons/circleSuccess';
import EditProductVariantForm, { FormComponent } from './EditProductVariantForm';
import {
  ProductVariant,
  CopyAndSaveFieldValueToAllVariants,
  ProductPromotionalPayload,
  ProductPromotionalText,
  ProductVariation,
} from '../../../services/catalog/types';
import { Snackbar, useSnackbar } from '../../../components/library/Snackbar';
import ImagesPreview from '../../../components/catalog/ImagesPreview';
import ReorderableVariantAccordion from './ReorderableVariantAccordion';
import { LookupSpecsModal } from './LookupSpecsModal';
import { InspectionStatusEnum } from '../../../services/service/types';
import { InspectionStatusBadge } from '../../service/serviceTicket/InspectionStatusBadge';
import { cycleTimingsContext } from '../../service/cycleTimings/cycleTimingsContext';
import { StickyHeader } from '../../../components/library/StickyHeader/StickyHeader';
import { NewOrExistingVariant } from '../utils/productVariant/types';
import { useFieldCategoryConfig } from '../utils/useFieldCategoryConfig';
import { fieldCategoryConfigContext } from '../utils/productVariant/field-context';
import { getEditProductFieldListConfiguration } from '../utils/product/fields';
import { groupFieldListConfiguration } from '../utils/formik/formik-fields';
import FormikField from './FormikField';
import ShopifyStatusField from '../../../components/catalog/ShopifyStatusField';

const ModalBody = styled.div`
  background-color: white;
  padding: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inspectionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      maxWidth: '185px',
    },
    statusContainer: {
      margin: theme.spacing(0, 1.25),
    },
  }),
);

const EditProduct: React.FC<RouteComponentProps<{ productId: string }>> = ({ match, location }) => {
  const skuParam = new URLSearchParams(location.search).get('sku');
  const productId = match.params.productId;
  const {
    product,
    firstVariantInspection,
    shopifyStatus,
    error: productError,
    shopifyPublishErrors,
    shopifyPublishReady,
    loading,
    setLoading,
    refreshProductData,
    productPromotionalTexts,
  } = useProduct(productId);

  const classes = useStyles();

  const productStore = useSelector((state: GlobalState) => state.product);

  const [error, setError] = useState<string | string[] | undefined>();
  const [isNewVariantModalOpen, setIsNewVariantModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<EditProductValues>({
    model: '',
    brandId: 0,
    title: '',
    displayTitle: '',
    disciplineId: null,
    productSubCategoryId: null,
    variations: null,
    categoryId: null,
    certifiedPreOwned: false,
    description: null,
    sellPreService: false,
    longDescription: '',
    features: '',
    headline: '',
    upgrades: '',
    masterMechanicsNotes: '',
  });
  const [newProductVariant, setNewProductVariant] = useState<NewOrExistingVariant | undefined>();
  const [createdVariant, setCreatedVariant] = useState<ProductVariant | undefined>();
  const [reorderVariantsEnabled, setReorderVariantsEnabled] = useState<boolean>(false);
  const [accordionExpanded, setAccordionExpanded] = useState<string | undefined>(
    skuParam ?? undefined,
  );
  const [isLookupSpecsModalOpen, setIsLookupSpecsModalOpen] = useState<boolean>(false);
  const [isLookupTooltipOpen, setIsLookupTooltipOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { snackbarOpen, handleSnackbarOpen, handleSnackbarClose } = useSnackbar();
  const [specsModalSuccess, setSpecsModalSuccess] = useState<string | undefined>(undefined);
  const [specsModalError, setSpecsModalError] = useState<string | undefined>(undefined);
  const [showAllPromoTexts, setShowAllPromoTexts] = useState<boolean>(false);
  const { setCycleTimingSku } = useContext(cycleTimingsContext);

  const {
    snackbarOpen: errorSnackbarOpen,
    handleSnackbarOpen: handleErrorSnackbarOpen,
    handleSnackbarClose: handleErrorSnackbarClose,
  } = useSnackbar();
  const {
    snackbarOpen: successSnackbarOpen,
    handleSnackbarOpen: handleSuccessSnackbarOpen,
    handleSnackbarClose: handleSuccessSnackbarClose,
  } = useSnackbar();

  const handleLookupSuccess = (successMessage: string) => {
    setSpecsModalSuccess(successMessage);
    handleSuccessSnackbarOpen();
  };

  const handleLookupError = (errorMessage: string) => {
    setSpecsModalError(errorMessage);
    handleErrorSnackbarOpen();
  };

  // get the plain text content
  const findTextForTypeId = (
    productPromotionalText: ProductPromotionalText[] | undefined,
    typeIdToMatch: number,
  ): string | undefined => {
    return productPromotionalText?.find(ppt => ppt.promotionalTextTypeId === typeIdToMatch)?.content
      ?.text;
  };

  // get the JSON content in a format already parsed to be used by Formik textareas
  const findParsedContentForTypeId = (
    productPromotionalText: ProductPromotionalText[] | undefined,
    typeIdToMatch: number,
  ): string | undefined => {
    const field = productPromotionalText?.find(ppt => ppt.promotionalTextTypeId === typeIdToMatch);
    return field?.parsedContent || field?.content.text;
  };

  useEffect(() => {
    if (productError) {
      setError('Product not found');
    }
  }, [productError]);

  useEffect(() => {
    dispatch(actions.getInitialItemCatalogDataOneDispatch());
  }, [dispatch]);

  const extractVariationAttributeIds = (productVariations: ProductVariation[]) => {
    if (productVariations?.length) {
      return productVariations.map(pvar => ({
        id: pvar.attributeType.id,
        name: displayCase(pvar.attributeType.name),
      }));
    }
    return [];
  };

  useEffect(() => {
    if (product) {
      setInitialValues(initialValues => ({
        ...initialValues,
        model: product.model || '',
        brandId: product.brandId,
        title: product.title || '',
        displayTitle: product.displayTitle || '',
        disciplineId: product.disciplineId ?? null,
        productSubCategoryId: product.productSubCategoryId ?? null,
        variations: extractVariationAttributeIds(product.productVariations) ?? null,
        disabledCategoryId: product.categoryId ?? null,
        categoryId: product.categoryId ?? null,
        certifiedPreOwned: product.certifiedPreOwned ?? null,
        description: product.description ?? null,
        sellPreService: product.sellPreService ?? false,
      }));
      setShowAllPromoTexts(showAllPromoTextCategories.includes(product.categoryId));
      dispatch(actions.getAutosuggestFields({ categoryId: product.categoryId }));

      if (product?.productVariants?.length) {
        setCycleTimingSku(product?.productVariants[0].sku);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, dispatch]);

  useEffect(() => {
    if (productPromotionalTexts) {
      setInitialValues(initialValues => {
        return {
          ...initialValues,
          headline: findTextForTypeId(productPromotionalTexts, 1) || '',
          longDescription: findTextForTypeId(productPromotionalTexts, 2) || '',
          features: findParsedContentForTypeId(productPromotionalTexts, 3) || '',
          upgrades: findParsedContentForTypeId(productPromotionalTexts, 4) || '',
          masterMechanicsNotes: findParsedContentForTypeId(productPromotionalTexts, 5) || '',
        };
      });
    }
  }, [productPromotionalTexts]);

  const onSubmit = async (values: EditProductValues) => {
    try {
      // Reset errors
      setError(undefined);
      if (product) {
        // code that used to validate that variants had data populated for all variation attributes,
        // using the old variation types model, removed from here.
        // TODO: do we need to re-instate this validation using the new variation model?

        const variationAttributeTypeIds = values.variations?.map(pvar => pvar.id) || [];

        await updateProduct(product.id, {
          ...values,
          displayTitle: values.displayTitle || null,
          model: values.model || null,
          disciplineId: values.disciplineId || null,
          productSubCategoryId: values.productSubCategoryId || null,
          variationTypeIds: variationAttributeTypeIds,
        });

        const productPromotionalPayloads: Array<ProductPromotionalPayload> = [];
        productPromotionalPayloads.push({
          promotionalTextTypeId: promotionalTextType['longDescription'],
          content: { text: cleanupNewlines(values?.longDescription) },
        });
        productPromotionalPayloads.push({
          promotionalTextTypeId: promotionalTextType['features'],
          content: { text: cleanupNewlines(values?.features) },
        });
        if (showAllPromoTexts) {
          productPromotionalPayloads.push({
            promotionalTextTypeId: promotionalTextType['headline'],
            content: { text: cleanupNewlines(values?.headline) },
          });
          productPromotionalPayloads.push({
            promotionalTextTypeId: promotionalTextType['upgrades'],
            content: { text: cleanupNewlines(values?.upgrades) },
          });
          productPromotionalPayloads.push({
            promotionalTextTypeId: promotionalTextType['masterMechanicsNotes'],
            content: {
              text: cleanupNewlines(values?.masterMechanicsNotes),
            },
          });
        }

        await updateProductPromotionalTexts(product.id, productPromotionalPayloads);
        // Recall APIs on save
        await refreshProductData();
        handleSnackbarOpen();
      }
    } catch (err) {
      handleApiError(err, setError);
    }
  };

  const groupedFieldListConfiguration = useMemo(() => {
    const configs = getEditProductFieldListConfiguration({
      productStore,
      categoryId: product?.categoryId ?? 0,
      dispatch,
    });
    return groupFieldListConfiguration(configs);
  }, [productStore, product?.categoryId, dispatch]);

  const onAddNewVariant = () => {
    if (product?.productVariants) {
      const newVariant: NewOrExistingVariant = {
        ...product.productVariants[0],
        id: null,
        sku: '',
        // current sale price gets set on BE based on starting price
        currentSalePriceUsd: null,
      };
      setNewProductVariant(newVariant);
      setIsNewVariantModalOpen(true);
    }
  };

  const copyAndSaveFieldValueToAllVariants: CopyAndSaveFieldValueToAllVariants = useCallback(
    async (field, value) => {
      if (!!product) {
        setLoading(true);
        const body = { [field]: value };
        try {
          await Promise.all(product.productVariants.map(pv => patchProductVariant(pv.id, body)));
          // Recall APIs on save
          await refreshProductData();
        } catch (err) {
          handleApiError(err, setError);
        } finally {
          setLoading(false);
        }
      }
    },
    [product, refreshProductData, setLoading],
  );

  const handleProductVariantsReorder = async (productVariantsDisplayOrder?: ProductVariant[]) => {
    if (!!product && !!productVariantsDisplayOrder) {
      setLoading(true);

      try {
        let productVariantOrderUpdated = false;

        await Promise.all(
          productVariantsDisplayOrder.map(async (pv, orderIndex) => {
            if (pv.displayOrder !== orderIndex) {
              productVariantOrderUpdated = true;
              await patchProductVariant(pv.id, { displayOrder: orderIndex });
            }
            return;
          }),
        );
        if (productVariantOrderUpdated) {
          await updateShopifyBaseProduct(product.id);
        }
        // Recall APIs on save
        await refreshProductData();
      } catch (err) {
        handleApiError(err, setError);
      } finally {
        setReorderVariantsEnabled(false);
        setLoading(false);
        handleSnackbarOpen();
      }
    }
  };

  const handleClose = () => setIsNewVariantModalOpen(false);

  const handleDialogClose = () => {
    setCreatedVariant(undefined);
    handleClose();
  };

  const onAccordionClick = (sku: string, isExpanded: boolean) => {
    setAccordionExpanded(isExpanded ? sku : undefined);
  };

  const [productVariantsDisplayOrder, setProductVariantOrder] = useState(product?.productVariants);

  useEffect(() => {
    if (product?.productVariants) {
      setProductVariantOrder(product?.productVariants);
    }
  }, [product]);

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination || !productVariantsDisplayOrder) return;

    const items = Array.from(productVariantsDisplayOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setProductVariantOrder(items);
  };

  let status = '';
  if (firstVariantInspection) {
    status = InspectionStatusEnum[firstVariantInspection?.status];
  }

  const { fieldCategoryConfigs } = useFieldCategoryConfig(product?.categoryId);

  return (
    <PageWrapper>
      <PageHeader>Edit Product</PageHeader>

      {product && (
        <StickyHeader
          title={product.title}
          actions={
            <Box flexShrink={0}>
              <ButtonGroup>
                <Tooltip
                  open={isLookupTooltipOpen}
                  onOpen={() => setIsLookupTooltipOpen(true)}
                  onClose={() => setIsLookupTooltipOpen(false)}
                  title={
                    product?.productVariants.length !== 1
                      ? 'This feature is disabled for any product with more than 1 variant.'
                      : ''
                  }
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setIsLookupSpecsModalOpen(true);
                    }}
                    disabled={product?.productVariants.length !== 1}
                  >
                    Lookup Specs
                  </Button>
                </Tooltip>
                <Button
                  variant="outlined"
                  to={`/catalog/products/${product.id}`}
                  component={RouterLink}
                >
                  View Product
                </Button>
              </ButtonGroup>
            </Box>
          }
          mx={-4}
        />
      )}

      <Loader loading={loading} />

      {product && (
        <>
          <ImagesPreview images={product.images} productId={product.id} />
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={editProductValidation}
            validateOnChange={false}
            enableReinitialize
          >
            {({ dirty }) => (
              <>
                {isLookupSpecsModalOpen && (
                  <LookupSpecsModal
                    open={isLookupSpecsModalOpen}
                    setIsLookupSpecsModalOpen={setIsLookupSpecsModalOpen}
                    model={product.model}
                    brandId={product.brandId}
                    categoryId={product.categoryId}
                    sizeClassId={product?.productVariants[0]?.sizeClassId || null}
                    year={product?.productVariants[0]?.year || null}
                    toProductVariantId={product.productVariants[0]?.id}
                    onSkuCopySuccess={refreshProductData}
                    handleSuccess={handleLookupSuccess}
                    handleError={handleLookupError}
                  />
                )}
                <Prompt when={dirty} message="Are you sure you want to exit without saving?" />
                <ReadyForShopifyPublish
                  shopifyPublishErrors={shopifyPublishErrors}
                  shopifyPublishReady={shopifyPublishReady}
                  shopifyStatus={shopifyStatus}
                  productId={product.id}
                  onPublishSuccess={refreshProductData}
                  dirty={dirty}
                />

                <FormComponent>
                  <>
                    <ShopifyStatusField status={shopifyStatus} />
                    {groupedFieldListConfiguration.groups.map(
                      ({ displayName, fields, subGroups }) => (
                        <React.Fragment key={displayName}>
                          <h4>{displayName}</h4>
                          {subGroups.length
                            ? subGroups.map(subGroup => (
                                <React.Fragment key={subGroup.displayName}>
                                  <h5 key={subGroup.displayName}>{subGroup.displayName}</h5>
                                  {subGroup.fields.map(field => (
                                    <FormikField key={field.valueKey} field={field} />
                                  ))}
                                </React.Fragment>
                              ))
                            : fields.map(field => (
                                <FormikField key={field.valueKey} field={field} />
                              ))}
                        </React.Fragment>
                      ),
                    )}
                    {!!firstVariantInspection && (
                      <>
                        <h4>Service Plan</h4>
                        <Box className={classes.inspectionContainer}>
                          <DisplayField
                            label="Drivetrain Service Plan"
                            value={firstVariantInspection.id}
                            url={`/service/service-plans/${firstVariantInspection.id}`}
                          />
                          <Box className={classes.statusContainer}>
                            <InspectionStatusBadge
                              label={status}
                              statusId={firstVariantInspection?.status}
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                    <ErrorMessage error={error} />
                    <ButtonSection productId={product.id} />
                  </>
                </FormComponent>
                <>
                  <Button
                    variant="outlined"
                    onClick={onAddNewVariant}
                    disabled={product.productVariations.length === 0}
                  >
                    Add New Variant
                  </Button>
                  <span style={{ marginLeft: 10 }}>Add a variation type to add new variant</span>
                </>

                <Snackbar open={snackbarOpen} handleClose={handleSnackbarClose} />

                {specsModalError && (
                  <Snackbar
                    open={errorSnackbarOpen}
                    handleClose={() => {
                      handleErrorSnackbarClose();
                      setSpecsModalError(undefined);
                    }}
                    message={specsModalError}
                    severity="error"
                  />
                )}
                {specsModalSuccess && (
                  <Snackbar
                    open={successSnackbarOpen}
                    handleClose={() => {
                      handleSuccessSnackbarClose();
                      setSpecsModalSuccess(undefined);
                    }}
                    message={specsModalSuccess}
                    severity="success"
                  />
                )}
                {product.productVariants.length > 1 && (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: 10,
                        alignItems: 'center',
                      }}
                    >
                      <strong>Shopify Display Order</strong>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={reorderVariantsEnabled}
                            onChange={() => {
                              setReorderVariantsEnabled(!reorderVariantsEnabled);
                              setAccordionExpanded(undefined);
                            }}
                            name="reorderVariants"
                            color="primary"
                          />
                        }
                        label="Reorder Variants"
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => handleProductVariantsReorder(productVariantsDisplayOrder)}
                        disabled={!reorderVariantsEnabled}
                      >
                        Confirm reorder
                      </Button>
                    </div>
                  </div>
                )}
                {!reorderVariantsEnabled ? (
                  <fieldCategoryConfigContext.Provider value={{ fieldCategoryConfigs }}>
                    <div>
                      {product.productVariants.map(productVariant => {
                        const expanded =
                          accordionExpanded === productVariant.sku ||
                          product.productVariants.length === 1;

                        return (
                          <EditProductVariant
                            productVariant={productVariant}
                            key={productVariant.id}
                            expanded={expanded}
                            product={product}
                            refreshProductData={refreshProductData}
                            onAccordionClick={onAccordionClick}
                            copyAndSaveFieldValueToAllVariants={
                              product.productVariants.length > 1
                                ? copyAndSaveFieldValueToAllVariants
                                : undefined
                            }
                          />
                        );
                      })}
                    </div>
                  </fieldCategoryConfigContext.Provider>
                ) : (
                  <div>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="productVariants">
                        {provided => (
                          <div
                            className="productVariants"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {productVariantsDisplayOrder?.map((productVariant, i) => {
                              return (
                                <Draggable
                                  key={productVariant.id}
                                  draggableId={productVariant.id.toString()}
                                  index={i}
                                >
                                  {provided => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginTop: 10,
                                        }}
                                      >
                                        <DragIndicatorIcon />
                                        <ReorderableVariantAccordion
                                          productVariant={productVariant}
                                          key={productVariant.id}
                                          product={product}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}
                <span></span>
                <ReadyForShopifyPublish
                  shopifyPublishErrors={shopifyPublishErrors}
                  shopifyPublishReady={shopifyPublishReady}
                  shopifyStatus={shopifyStatus}
                  productId={product.id}
                  onPublishSuccess={refreshProductData}
                  dirty={dirty}
                />
              </>
            )}
          </Formik>
          <Modal
            open={isNewVariantModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="add-new-varaint-modal"
          >
            <div className="backdrop">
              <ModalBody>
                <h2 id="modal-title">Add New Variant</h2>
                {newProductVariant && (
                  <fieldCategoryConfigContext.Provider value={{ fieldCategoryConfigs }}>
                    <EditProductVariantForm
                      productVariant={newProductVariant}
                      product={product}
                      refreshProductData={refreshProductData}
                      onCancel={handleClose}
                      onSubmitSuccess={pv => {
                        if (pv) {
                          setCreatedVariant(pv);
                        } else {
                          handleClose();
                        }
                      }}
                    />
                  </fieldCategoryConfigContext.Provider>
                )}
              </ModalBody>
            </div>
          </Modal>
          <Dialog open={!!createdVariant} onClose={handleDialogClose}>
            <DialogTitle>
              <CircleSuccess /> Success!
            </DialogTitle>
            <DialogContent>
              {!!createdVariant && (
                <DialogContentText id="alert-dialog-description" component="div">
                  <em>{`"${createdVariant.sku}" `}</em>has been created.
                  <div>
                    View Variant:
                    <div>
                      <RouterLink to={`/catalog/products/${product.id}`}>
                        SKU: {createdVariant.sku}
                      </RouterLink>
                    </div>
                  </div>
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              {!!createdVariant && (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleDialogClose();
                    setAccordionExpanded(createdVariant.sku);
                  }}
                >
                  Done
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </>
      )}
    </PageWrapper>
  );
};

export default withRouter(EditProduct);
