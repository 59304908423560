import { Submission } from './types';

export const getDrivetrainCategoryId = (submission: {
  type?: string;
  items: [
    {
      bikeCategory?: {
        drivetrainFrameCategoryId?: number | null;
        drivetrainId?: number | null;
      };
    },
  ];
}) => {
  let drivetrainCategoryId: number | null = null;
  if (submission?.items[0]?.bikeCategory) {
    if (submission?.type === 'frame') {
      drivetrainCategoryId = submission?.items[0]?.bikeCategory?.drivetrainFrameCategoryId ?? null;
    } else if (submission?.type === 'bike') {
      drivetrainCategoryId = submission?.items[0]?.bikeCategory?.drivetrainId ?? null;
    }
  }
  return drivetrainCategoryId;
};

export const isLookupSubmissionDisabled = (
  showSubIdWithTrackingNumber?: boolean,
  hasTrackingNumber?: boolean,
  hasSubmissionId?: boolean,
) => {
  if (showSubIdWithTrackingNumber && hasTrackingNumber && !hasSubmissionId) {
    return true;
  } else if (!hasTrackingNumber && !hasSubmissionId) {
    return true;
  }
  return false;
};

export const hasAlternateSubmissions = (
  primarySubmission: {
    id?: number;
  },
  submissions: Submission[],
) => {
  if (!submissions?.length) {
    return false;
  }
  if (submissions?.length === 1 && primarySubmission?.id === submissions[0]?.id) {
    return false;
  }
  return true;
};

export const conventionalStartingPrice = (expectedValue: number | null | undefined) => {
  if (!expectedValue) {
    return null;
  }
  let defaultStartingPriceFloatingPoint: number;
  if (Math.floor(expectedValue) % 10 == 0) {
    //subtract one cent - i.e. 100.XX ->
    defaultStartingPriceFloatingPoint = Math.floor(expectedValue) - 0.01;
  } else {
    //set cents to .99
    defaultStartingPriceFloatingPoint = Math.floor(expectedValue) + 0.99;
  }
  //Adding / subtracting decimal values may have caused floating point errors -
  //round them off here
  return Math.round(defaultStartingPriceFloatingPoint * 100) / 100;
};

// TODO: Update to use Offers
export const formatSubmissionForAddProductView = (
  submission: Submission,
  enteredTrackingNumber: string | null = null,
) => {
  return {
    drivetrainBikeCategoryId: getDrivetrainCategoryId(submission),
    enteredTrackingNumber,
    submission: {
      id: submission?.id,
      expectedValue: submission?.expectedValue,
      defaultStartingPrice: conventionalStartingPrice(submission?.expectedValue),
      source: 1,
      activeOfferGroup: submission?.activeOfferGroup,
      items: [
        {
          ...submission?.items[0],
          ...{
            serialNumber: submission?.serialNumber,
            brand:
              submission?.items[0]?.confirmedBrand?.trim() || submission?.items[0]?.brand?.trim(),
            confirmedBrand: submission?.items[0]?.confirmedBrand?.trim(),
            title: [
              submission?.items[0]?.year,
              submission?.items[0]?.confirmedBrand || submission?.items[0]?.brand,
              submission?.items[0]?.confirmedModel || submission?.items[0]?.model,
              submission?.items[0]?.size,
            ]
              .filter(s => !!s)
              .join(' '),
          },
        },
      ],
    },
  };
};
