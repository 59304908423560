import React, { useMemo, useCallback, useState, useRef } from 'react';
import { useFormikContext } from 'formik';
import { TextField, Button } from '../../../../../../components/library';
import { FormikPointsState } from '../../../_shared/types';
import { InspectionPoint, FEInspectionCategory } from '../../../../../../services/service/types';
import { Grades, FailRemedies } from '../../../_shared/types';
import { InspectionPointStatusIcon } from '../InspectionPointStatusIcon';
import { InspectionCriteriaTooltip } from '../InspectionCriteriaTooltip';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
} from '@material-ui/core';
import {
  CategoryHeaderWrapper,
  useEditablePointCategoryTableStyles,
  usePointStatusStyles,
} from '../styled';
import { PointRemedyKeyPressListeners } from './PointRemedyKeyPressListeners';

interface EditablePointCategoryTableProps {
  category: FEInspectionCategory;
}

export const EditablePointCategoryTable: React.FC<EditablePointCategoryTableProps> = ({
  category,
}) => {
  const { values, setFieldValue, errors, setFieldError } = useFormikContext<FormikPointsState>();
  const classes = useEditablePointCategoryTableStyles();
  const statusCellClasses = usePointStatusStyles();
  const [quickRemedy, setQuickRemedy] = useState(false);
  const [dataId, setDataId] = useState(0);

  const typeIdsInCategory: number[] = useMemo(
    () => category.inspectionPointTypes.map(type => type.id),
    [category],
  );

  const handleGradeChange = (value: number, id: number) => {
    if (Grades.fail === value) {
      setQuickRemedy(true);
      setDataId(id);
    } else {
      setQuickRemedy(false);
    }
    if (Grades.na === value) {
      setFieldValue(`points[${id}]`, {
        ...values.points[id],
        gradeId: value,
        failRemedyId: undefined,
        note: undefined,
      });
    } else {
      setFieldValue(`points[${id}].gradeId`, value);
    }
    setFieldError(`points[${id}]`, undefined);
  };

  const activeRemedyInputRef = useRef<HTMLDivElement>(null);

  const handleRemedyChange = useCallback(
    (value: number, id: number) => {
      if (value) {
        setFieldValue(`points[${id}].failRemedyId`, value);
        setFieldError(`points[${id}]`, undefined);
        activeRemedyInputRef.current?.focus();
      }
    },
    [setFieldValue, setFieldError],
  );

  const setAllToNa = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    for (const [key, val] of Object.entries(values.points)) {
      // only clear points in this table's category
      if (typeIdsInCategory.includes((val as InspectionPoint).inspectionPointTypeId)) {
        setFieldValue(`points[${key}]`, {
          ...val,
          gradeId: Grades.na,
          failRemedyId: undefined,
          note: undefined,
        });
      }
    }
  };

  return (
    <TableContainer>
      <CategoryHeaderWrapper>
        <h3>{category.category}</h3>
        <Button ordinality="primary" variant="text" onClick={setAllToNa}>
          Set All To N/A
        </Button>
      </CategoryHeaderWrapper>
      <Table className={classes.table} aria-label="inspections-table">
        {/* todo: style category header */}
        <TableHead className={classes.th}>
          <TableRow className={classes.row}>
            <TableCell className={classes.thCell}>Status</TableCell>
            <TableCell className={classes.thCell}>Inspection Point</TableCell>
            <TableCell className={`${classes.thCell} ${classes.centeredCell}`}>Pass</TableCell>
            <TableCell className={`${classes.thCell} ${classes.centeredCell}`}>Fail</TableCell>
            <TableCell className={`${classes.thCell} ${classes.centeredCell}`}>N/A</TableCell>
            <TableCell className={classes.thCell}>Fail Remedy</TableCell>
            <TableCell className={classes.thCell}>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {category.points.map((point, idx) => {
            const dataPointKey = point.inspectionPointTypeId;
            const dataPoint = values.points[dataPointKey];
            const statusCellClass = dataPoint.gradeId
              ? statusCellClasses[Grades[dataPoint.gradeId] as keyof typeof statusCellClasses]
              : '';

            const isPointFailed = dataPoint?.gradeId === Grades.fail;
            const hasPointRemedyError = !!errors.points?.[dataPointKey]?.failRemedyId;

            return (
              <TableRow className={classes.row} key={dataPointKey} tabIndex={idx}>
                {/* Status */}
                <TableCell className={`${classes.centeredCell} ${statusCellClass}`}>
                  <InspectionPointStatusIcon gradeId={dataPoint.gradeId} />
                </TableCell>

                {/* Point name */}
                <TableCell className={classes.leftAlignedCell} component="th" scope="row">
                  {point.description}
                  <InspectionCriteriaTooltip
                    passCriteria={point.passCriteria}
                    failCriteria={point.failCriteria}
                  />
                </TableCell>

                {/* Grade - Pass */}
                <TableCell
                  className={`${classes.centeredCell} ${classes.clickable}`}
                  onClick={() => handleGradeChange(Grades.pass, dataPointKey)}
                >
                  <Radio
                    name={`radio-pass-fail-${dataPointKey}`}
                    value={Grades.pass}
                    checked={dataPoint?.gradeId === Grades.pass}
                    onChange={() => handleGradeChange(Grades.pass, dataPointKey)}
                    color="default"
                  />
                </TableCell>

                {/* Grade - Fail */}
                <TableCell
                  className={`${classes.centeredCell} ${classes.clickable}`}
                  onClick={() => handleGradeChange(Grades.fail, dataPointKey)}
                >
                  <Radio
                    name={`radio-pass-fail-${dataPointKey}`}
                    value={Grades.fail}
                    checked={isPointFailed}
                    onChange={() => handleGradeChange(Grades.fail, dataPointKey)}
                    color="default"
                  />
                </TableCell>

                {/* Grade - N/A */}
                <TableCell
                  className={`${classes.centeredCell} ${classes.clickable}`}
                  onClick={() => handleGradeChange(Grades.na, dataPointKey)}
                >
                  <Radio
                    name={`radio-pass-fail-${dataPointKey}`}
                    value={Grades.na}
                    checked={dataPoint?.gradeId === Grades.na}
                    onChange={() => handleGradeChange(Grades.na, dataPointKey)}
                    color="default"
                  />
                </TableCell>

                {/* Remedy */}
                <TableCell className={classes.centeredCell}>
                  <TextField
                    id={`outlined-select-remedy-${dataPointKey}`}
                    inputRef={dataId === dataPointKey ? activeRemedyInputRef : undefined}
                    value={dataPoint.failRemedyId ?? ''}
                    // e.target.value is actually a number here through some Formik magic, but TS thinks it's a string.
                    onChange={e => handleRemedyChange(parseInt(e.target.value, 10), dataPointKey)}
                    disabled={!isPointFailed}
                    variant="outlined"
                    size="small"
                    label="Remedy"
                    onBlur={() => setQuickRemedy(false)}
                    onFocus={() => setQuickRemedy(true)}
                    options={[
                      { label: 'Service', value: FailRemedies.service },
                      { label: 'Replace', value: FailRemedies.replace },
                      { label: 'No Action', value: FailRemedies.na },
                    ]}
                    error={
                      hasPointRemedyError ||
                      // TP-3571: highlight remedy input when point is failed
                      (isPointFailed && !dataPoint.failRemedyId)
                    }
                  />
                </TableCell>

                {/* Notes */}
                <TableCell className={classes.centeredCell}>
                  <TextField
                    id={`outlined-select-note-${dataPointKey}`}
                    value={dataPoint.note ?? ''}
                    onChange={e => setFieldValue(`points[${dataPointKey}].note`, e.target.value)}
                    variant="outlined"
                    size="small"
                    label="Notes"
                    multiline={true}
                    rowsMax={3}
                    disabled={!dataPoint.gradeId}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {quickRemedy && (
        <PointRemedyKeyPressListeners
          handleRemedyChange={handleRemedyChange}
          activePointId={dataId}
        />
      )}
    </TableContainer>
  );
};
