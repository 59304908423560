export const waitToLoad = file =>
  new Promise((resolve, reject) => {
    const sourceImgObj = new Image();
    sourceImgObj.onload = () => {
      resolve(sourceImgObj);
    };
    const binaryData = [];
    binaryData.push(file);
    sourceImgObj.src = URL.createObjectURL(new Blob(binaryData));
  });

export const compress = (file, quality) =>
  new Promise(async (resolve, reject) => {
    const mimeType = 'image/jpeg';
    const sourceImgObj = await waitToLoad(file);
    const maxWidth = 1600;
    const maxHeight = 1600;
    const widthIsBigger = maxWidth > maxHeight;
    let natW = sourceImgObj.naturalWidth;
    let natH = sourceImgObj.naturalHeight;
    const ratio = natH / natW;

    if (widthIsBigger && natW > maxWidth) {
      natW = maxWidth;
      natH = ratio * maxWidth;
    } else if (!widthIsBigger && natH > maxHeight) {
      natW = maxHeight;
      natH = ratio * maxHeight;
    }

    const cvs = document.createElement('canvas');
    cvs.width = natW;
    cvs.height = natH;
    cvs.getContext('2d').drawImage(sourceImgObj, 0, 0, natW, natH);
    return cvs.toBlob(blob => resolve(blob), mimeType, quality / 100);
  });
