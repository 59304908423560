import React from 'react';
import { TextField } from '../../../../../components/library';
import { CategoryHeaderWrapper } from './styled';

interface NotesTextareaProps {
  value: string | null;
  setValue: React.ChangeEventHandler<HTMLTextAreaElement>;
  isDisabled?: boolean;
}

export const NotesTextarea: React.FC<NotesTextareaProps> = ({ value, setValue, isDisabled }) => {
  return (
    <div style={{ borderBottom: '1px solid lightgrey' }}>
      <CategoryHeaderWrapper>
        <h3>Additional Notes</h3>
      </CategoryHeaderWrapper>
      <TextField
        multiline
        rows={3}
        variant="outlined"
        value={value}
        onChange={setValue}
        disabled={isDisabled}
      />
    </div>
  );
};
