import React from 'react';

const Service = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3398 2.8508c-.0058-.006-1.1958 1.1704-3.5641 3.5348L9.114 4.7239l3.5406-3.5407c-1.8633-1.1215-4.257-.8338-5.809.7183a4.7839 4.7839 0 00-1.241 4.6152l.0352.1311L.091 12.1947a.3143.3143 0 000 .4424l2.7676 2.7675a.3141.3141 0 00.4423 0l5.545-5.5449.131.0352c1.6383.4365 3.3979-.0176 4.6192-1.239 1.5541-1.552 1.8418-3.9457.7437-5.8051z"
      fill="currentColor"
    />
  </svg>
);

export default Service;
