import React, { Fragment } from 'react';

const Loader = (props: { loading: boolean }) => {
  return (
    <Fragment>
      {props.loading && (
        <div className="loading-wrap">
          <div className="lds-default">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Loader;
