import { GET_TRADEUP_FILTERS, GET_TRADEUP_SORT, GET_TRADEUP_PAGE } from '../actions/types';

export default (
  state = {
    filters: {
      searchTerm: '',
      partner: '',
      type: '',
      buyer: '',
      status: '',
      includeAutoRejected: false,
    },
    sort: [
      {
        id: 'created',
        desc: true,
        page: 0,
      },
    ],
    page: 0,
  },
  action,
) => {
  switch (action.type) {
    case GET_TRADEUP_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case GET_TRADEUP_SORT: {
      return {
        ...state,
        sort: action.payload,
      };
    }
    case GET_TRADEUP_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    default:
      return state;
  }
};
