/* eslint-disable react/no-unused-state */
import React, { FC, useState, useEffect } from 'react';
import axios from '../../utils/axios';
import { API_URL } from '../../constants';
import styles from '../../styledComponents';
import MaterialTable from '@material-table/core';

const { PageWrapper } = styles;

type FeatureToggleValue = boolean | string | number | undefined;

const FeatureToggles: FC = () => {
  const initialTableState: { key: string; value: FeatureToggleValue }[] = [];
  const [{ featureToggleTable }, setFeatureToggleTable] = useState({
    featureToggleTable: initialTableState,
  });

  const loadFeatureToggles = async () => {
    const url = `${API_URL}/admin/featureToggles`;
    const response = await axios.get(url);
    if (response) {
      const featureToggleMap: {
        [key: string]: FeatureToggleValue;
      } = response.data.featureToggles;
      const featureToggleArray = Object.keys(featureToggleMap).map(key => ({
        key,
        value: featureToggleMap[key],
      }));
      setFeatureToggleTable({ featureToggleTable: featureToggleArray });
    }
  };

  useEffect(() => {
    loadFeatureToggles();
  }, []);

  return (
    <PageWrapper>
      <section>
        <MaterialTable
          columns={[
            { title: 'Key', field: 'key', editable: 'never' },
            { title: 'Value', field: 'value', editable: 'never' },
          ]}
          title="Feature Toggles"
          data={featureToggleTable}
          options={{ paging: false, sorting: true, search: false }}
        />
      </section>
    </PageWrapper>
  );
};

export default FeatureToggles;
