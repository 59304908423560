import React from 'react';
import PropTypes from 'prop-types';
import { Form, Text } from 'informed';
import ReCAPTCHA from 'react-google-recaptcha';
import '../../styles/modal.scss';

export default class ForgotPasswordModal extends React.Component {
  UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this._handleEscKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleEscKey, false);
  }

  _handleEscKey = e => {
    if (e.keyCode === 27) {
      this.props.toggleModal();
    }
  };

  render() {
    const {
      toggleModal,
      formSuccess,
      error,
      errorMsg,
      loading,
      onCaptchaChange,
      captchaRef,
    } = this.props;
    return (
      <div className="backdrop offer-decline-backdrop">
        <div className="message offer-decline-message">
          <span className="input-label-wrapper">
            <p style={{ textAlign: 'center', marginBottom: '30px' }}>
              Please enter the email associated with your account below
            </p>
            <Form className="offer-decline-form" onSubmit={formState => formSuccess(formState)}>
              <span className="input-label-wrapper offer-input-label-wrap">
                <label className="label">Email:</label>
                <Text className="text-input" field="email" />
              </span>
              <span className="input-label-wrapper offer-input-label-wrap">
                <input
                  type="submit"
                  name="save_complete"
                  value="Submit"
                  className="submit-button"
                  disabled={loading}
                />
              </span>
              {error && !errorMsg ? (
                <span>Uh oh, something went wrong</span>
              ) : (
                <span>{errorMsg}</span>
              )}
              <span className="input-label-wrapper offer-input-label-wrap">
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey="6LdzOnMUAAAAACcwKVTKy1nUEzRrIK7luGNXRm9R"
                  onChange={value => onCaptchaChange(value, 'forgotPassCaptcha')}
                />
              </span>
            </Form>
          </span>
          <button className="close-modal-button" onClick={toggleModal} disabled={loading} />
        </div>
      </div>
    );
  }
}

ForgotPasswordModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  formSuccess: PropTypes.func.isRequired,
  captchaRef: PropTypes.shape().isRequired,
  onCaptchaChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};
