import { useCallback, useEffect } from 'react';

export type ModifierKey = 'ctrl' | 'shift' | 'alt' | 'meta';
export interface UseKeyPressKeys {
  modifierKeys?: ModifierKey[];
  activationKey: string;
}

// N.B. - since this function registers a single keydown listener, there can only be one non-modifier key accepted at a time.
// this means ctrl+shift+alt+meta+N is a valid combo but A+B is not.
export const useKeyPress = (
  keys: UseKeyPressKeys,
  callback: Function,
  node: HTMLElement | Document = document,
) => {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const requiredModifierKeysPressed = !!keys.modifierKeys
        ? keys.modifierKeys.every(type => !!event[`${type}Key` as keyof KeyboardEvent])
        : true;
      if (
        keys?.activationKey?.toLowerCase() === event?.key?.toLowerCase() &&
        requiredModifierKeysPressed
      ) {
        callback(event);
      }
    },
    [keys, callback],
  );

  useEffect(() => {
    // attach the event listener (HTMLElement can't infer handler type from event name)
    node && node.addEventListener('keydown', handleKeyPress as EventListener);

    // remove the event listener (HTMLElement can't infer handler type from event name)
    return () => node && node.removeEventListener('keydown', handleKeyPress as EventListener);
  }, [handleKeyPress, node]);
};
