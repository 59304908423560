import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import HappyIcon from '@material-ui/icons/SentimentVerySatisfied';
import SadIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import CheckIcon from '@material-ui/icons/CheckCircle';

import Loader from '../../../components/loader';

import ReturnInspectionDialog from './return_inspection_dialog';
import axios from '../../../utils/axios';
import { API_URL } from '../../../constants';

const columns = [
  { id: 'id', label: 'ID' },
  { id: 'action', label: 'Action' },
  { id: 'order_id', label: 'Order ID', minWidth: 200 },
  { id: 'source', label: 'Source', minWidth: 100 },
  {
    id: 'status',
    label: 'Status',
    minWidth: 120,
  },
  {
    id: 'refunded_amount',
    label: 'Refunded Amount',
    minWidth: 100,
    format: value => `$${parseFloat(value).toFixed(2)}`,
  },
  { id: 'sku', label: 'SKU', minWidth: 100 },
  {
    id: 'title',
    label: 'Title',
    minWidth: 120,
  },
  {
    id: 'created_at',
    label: 'Created',
    minWidth: 120,
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  tableWrapper: {
    maxHeight: 407,
    overflow: 'auto',
  },
  tableCell: {
    border: 0,
  },
});

function RetrunsTable(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogImage, setDialogImage] = React.useState('');
  const [dialogNote, setDialogNote] = React.useState('');
  const [dialogId, setDialogId] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rows = props.data;

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleDialogClose() {
    setOpenDialog(false);
    setDialogImage('');
    setDialogNote('');
    setDialogId('');
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleNoteChange(e) {
    setDialogNote(e.target.value);
  }

  function handleRecordClick(id) {
    props.history.push(`/service/returns/${id}`);
  }

  async function updateStatus(returnId, status, note) {
    setLoading(true);
    try {
      await axios.put(`${API_URL}/service/returns/${returnId}`, {
        status: `inspected:${status}`,
        note,
      });
      props.onStatusChange(returnId, `inspected:${status}`);
      handleDialogClose();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function handleApprove(returnId) {
    updateStatus(returnId, 'good', dialogNote);
  }

  function handleFail(returnId) {
    updateStatus(returnId, 'bad', dialogNote);
  }

  async function handleInspection(id) {
    setLoading(true);
    try {
      const result = await axios.get(`${API_URL}/service/returns/item/${id}`);
      const details = result.data;
      setDialogImage(details.images[0]);
      setOpenDialog(true);
      setDialogId(id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function renderActionIcon(status, returnId) {
    switch (status) {
      case 'in_transit':
      case 'initiated': {
        return <EyeIcon style={{ cursor: 'pointer' }} onClick={() => handleInspection(returnId)} />;
      }
      case 'inspected:good': {
        return <HappyIcon />;
      }
      case 'inspected:bad': {
        return <SadIcon />;
      }
      case 'processed': {
        return <CheckIcon />;
      }
      default: {
        return '';
      }
    }
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            props.handleCsvDownload();
          }}
        >
          Download CSV
        </Link>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                {columns.map(column => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      onClick={column.id === 'id' ? () => handleRecordClick(row.id) : null}
                      className={classes.tableCell}
                      style={column.id === 'id' ? { cursor: 'pointer' } : { cursor: 'default' }}
                    >
                      {column.id === 'action'
                        ? renderActionIcon(row.status, row.id)
                        : column.format && typeof value === 'number'
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <ReturnInspectionDialog
        returnId={dialogId}
        imageSrc={dialogImage}
        note={dialogNote}
        open={openDialog}
        onApprove={handleApprove}
        onFail={handleFail}
        onChange={handleNoteChange}
        onClose={() => handleDialogClose()}
      />
      <Loader loading={loading} />
    </Paper>
  );
}

export default withRouter(RetrunsTable);
