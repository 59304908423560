import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FormControlLabel, Grid } from '@material-ui/core';
import {
  Modal,
  SmallModalContents,
  ModalHeader,
  ModalBody,
  Button,
  TextField,
  ErrorMessage,
  Checkbox,
  LoaderOverlay,
} from '../../../../components/library';
import { API_URL } from '../../../../constants';
import axios from '../../../../utils/axios';
import { INSPECTION_SEARCH_ROUTE } from '../InspectionSearchView';
import { BinAutocomplete } from '../../../../components/service/BinAutocomplete';
import { useRelocate, OPERATIONS_LOCATION_ID } from '../../../catalog/utils/useRelocate';
import { Bin } from '../../../../services/service/types';

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 30px;
`;

const ClickableLabel = styled(FormControlLabel)`
  &.MuiFormControl-root {
    margin-right: 0;
  }
`;

interface CreateInspectionModalProps {
  open: boolean;
  handleClose: () => void;

  sku?: string;
  serialNumber?: string;
}

export const CreateInspectionModal: React.FC<CreateInspectionModalProps> = ({
  open,
  handleClose,
}) => {
  const [sku, setSku] = useState<string>();
  const [serialNumber, setSerialNumber] = useState<string>();
  const [binTypeahead, setBinTypeahead] = useState<string>('');
  const [bin, setBin] = useState<Bin | undefined>(undefined);
  const [isReturnedItem, setIsReturnedItem] = useState<boolean>(false);
  const [isSubIdInsteadOfSku, setIsSubIdInsteadOfSku] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const history = useHistory();

  const { binsForLocation = [], error: relocateHookError } = useRelocate({
    typeaheadVal: binTypeahead,
    locationId: OPERATIONS_LOCATION_ID,
  });

  const onSubmit = async () => {
    try {
      setLoading(true);
      setError(undefined);
      // create inspection record
      const inspectionRes = await axios.post(`${API_URL}/service/inspections`, {
        sku,
        serialNumber,
        isReturnedItem,
        overrideSkuValidation: isSubIdInsteadOfSku,
      });
      if (!inspectionRes) {
        throw new Error('An unexpected error occurred.');
      }
      // relocate SKU, but only if inspection was successfully created
      // this way we don't relocate a bike flagged as stolen
      if (bin) {
        await axios.post(`${API_URL}/service/relocate`, {
          sku,
          toLocationId: OPERATIONS_LOCATION_ID,
          toBin: bin.code,
        });
      }
      history.push(`${INSPECTION_SEARCH_ROUTE}/${inspectionRes.data}`);
    } catch (err) {
      setError(err.response?.data?.message ?? 'An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal open={open} handleClose={handleClose} label={'New Service Plan'}>
        <SmallModalContents>
          <StyledHeader>
            <ModalHeader>New Service Plan</ModalHeader>
            <ClickableLabel
              control={
                <Checkbox
                  checked={isReturnedItem}
                  onChange={() => setIsReturnedItem(!isReturnedItem)}
                />
              }
              label="Returned item"
              labelPlacement="start"
            />
          </StyledHeader>
          <ModalBody>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item xs={8}>
                <TextField
                  id="sku"
                  label={isSubIdInsteadOfSku ? 'Submission ID' : 'SKU'}
                  onChange={e => setSku(e.target.value)}
                  variant="outlined"
                  value={sku}
                />
              </Grid>
              <Grid item xs={4}>
                <ClickableLabel
                  control={
                    <Checkbox
                      checked={isSubIdInsteadOfSku}
                      onChange={() => setIsSubIdInsteadOfSku(!isSubIdInsteadOfSku)}
                      tabIndex={-1}
                    />
                  }
                  label="Use Sub ID"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
            <TextField
              id="serial-number"
              label="Serial #"
              onChange={e => setSerialNumber(e.target.value)}
              variant="outlined"
              value={serialNumber}
            />
            <BinAutocomplete
              id="CreateInspectionModalBin"
              selectedBin={bin}
              options={binsForLocation}
              typeaheadVal={binTypeahead}
              onTypeaheadChange={setBinTypeahead}
              onBinChange={setBin}
            />
            <Button
              onClick={onSubmit}
              ordinality="primary"
              size="large"
              disabled={!sku || !serialNumber}
            >
              Create Inspection
            </Button>
            <ErrorMessage error={error} />
            <ErrorMessage error={relocateHookError} />
          </ModalBody>
        </SmallModalContents>
      </Modal>
      <LoaderOverlay loading={loading} />
    </>
  );
};
