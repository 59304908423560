import React, { FC, SyntheticEvent, useState } from 'react';
import './inventoryAdjustments.scss';
import fileDownload from 'js-file-download';
import { parse } from 'json2csv';
import Loader from '../../../components/loader';
import { DropzoneArea } from 'material-ui-dropzone';
import { API_URL } from '../../../constants';
import axios from '../../../utils/axios';
import { NotificationManager } from 'react-notifications';

const initialState: State = {
  loading: false,
  data: [],
  selectedFile: null,
  file: '',
  status: '',
};
interface State {
  selectedFile: File | null;
  data: [];
  loading: boolean;
  status: string;
  file: string;
}
const InventoryAdjustments: FC = () => {
  const [{ loading, selectedFile, status }, setState] = useState(initialState);
  const updateState = (state: string, value: any) => {
    setState(prevState => ({ ...prevState, [state]: value }));
  };

  const handleDownload = (e: SyntheticEvent) => {
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));
    e.preventDefault();
    const fields = ['external_id', 'date', 'sku', 'quantity', 'location', 'bin', 'amount'];
    const opts = { fields };
    const csv = parse('', opts);
    fileDownload(csv, `ia_template.csv`);
    setState(prevState => ({
      ...prevState,
      loading: false,
    }));
  };
  const handleSelect = (file: File[] | null) => {
    if (file) {
      updateState('selectedFile', file[0]);
    }
  };
  const handleUpload = async (e: SyntheticEvent) => {
    updateState('status', '');
    e.preventDefault();
    const data = new FormData();
    if (selectedFile) {
      data.append('csv', selectedFile, selectedFile.name);
    }
    const url = `${API_URL}/finance/inventoryAdjustments`;
    try {
      const res = await axios.post(url, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (res) {
        NotificationManager.success(
          `Successfully created inventory adjustment with id ${res.data}`,
        );
      }
    } catch (err) {
      const errMsg = err?.response?.data?.message ?? err.message;
      updateState('status', errMsg);
      NotificationManager.error(`Failed to create inventory adjustment`);
    }
  };

  return (
    <section className="inventory_adjustments">
      <h1>Inventory Adjustments</h1>
      <form>
        <DropzoneArea
          filesLimit={1}
          showFileNames={true}
          useChipsForPreview={true}
          acceptedFiles={['.csv']}
          onChange={file => handleSelect(file)}
        />
        <section>
          <input
            type="submit"
            name="download"
            value="Download Template"
            onClick={handleDownload}
          ></input>
          <input
            type="submit"
            className="submit"
            name="submit"
            value="Submit"
            onClick={handleUpload}
          ></input>
        </section>
      </form>
      <span>{status}</span>
      <Loader loading={loading}></Loader>
    </section>
  );
};

export default InventoryAdjustments;
