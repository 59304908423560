import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { GlobalState, Permissions } from '../../../reducers/types';
import { Autocomplete } from '@material-ui/lab';
import { ProductCategory } from '../../../services/catalog/types';
import { handleApiError } from '../utils';
import { ErrorMessage } from '../../../components/library';
import { useRouteMatch } from 'react-router-dom';
import { updateCategory } from '../../../services/catalog/services';

const ChangeCategoryButton: React.FC = () => {
  const { user } = useSelector((state: GlobalState) => state.auth);
  const productStore = useSelector((state: GlobalState) => state.product);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [value, setValue] = useState<null | ProductCategory>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState<string | string[] | undefined>(undefined);
  const match = useRouteMatch<{ productId: string }>(`/catalog/products/:productId/edit`);
  const productId = match?.params.productId;
  const userHasChangeCategoryPermission = !!user?.permissions.find(
    p => p.id === Permissions.CATALOG_CHANGE_CATEGORY,
  );

  const handleDialogClose = () => {
    setValue(null);
    setShowConfirmation(false);
    setError(undefined);
    setDialogOpen(false);
  };

  const handleDialogOpen = () => setDialogOpen(true);

  const handleChangeCategory = async () => {
    try {
      setError(undefined);
      if (value?.id && productId) {
        await updateCategory(Number(productId), value.id);
        // Reload the page if successful
        window.location.reload();
      }
    } catch (err) {
      handleApiError(err, setError);
    }
  };

  if (userHasChangeCategoryPermission) {
    return (
      <>
        <Button onClick={handleDialogOpen} variant="contained">
          Change Category
        </Button>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Change Category</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" component="div">
              <ErrorMessage error={error} />
              {!showConfirmation ? (
                <div style={{ width: 500 }}>
                  <Autocomplete
                    options={productStore.categories}
                    getOptionLabel={option => option.category?.toString()}
                    style={{ backgroundColor: '#fff' }}
                    autoHighlight
                    onChange={(_, option) => {
                      setValue(option ?? null);
                    }}
                    value={value}
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          placeholder="Change Category"
                          variant="outlined"
                          autoFocus={true}
                        />
                      );
                    }}
                  />
                </div>
              ) : (
                <div>
                  <p>Are you sure you want to change the category for this product?</p>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!showConfirmation ? (
              <Button
                variant="contained"
                onClick={() => {
                  if (value) {
                    setShowConfirmation(true);
                  }
                }}
              >
                Save
              </Button>
            ) : (
              <div>
                <Button variant="contained" onClick={handleChangeCategory}>
                  Yes
                </Button>
                <Button variant="contained" color="secondary" onClick={handleDialogClose}>
                  Cancel
                </Button>
              </div>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return null;
};

export default ChangeCategoryButton;
