import styled from 'styled-components';
import { colors } from './variables';

interface Wrapper {
  padding?: string;
  margin?: string;
  width?: string;
  height?: string;
}

interface FlexWrapper {
  flexDirection?: string;
  justifyContent?: string;
  alignContent?: string;
  alignItems?: string;
}

const AppWrapper = styled.div`
  background-color: ${colors.primary};
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  overflow: scroll;
`;

const PageWrapper = styled.main`
  width: calc(100% - 4em);
  height: 100%;
  margin: auto;
  padding: 2em;
  color: ${colors.font};
`;

const Wrapper = styled.div<Wrapper>`
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  height: ${props => props.height};
  width: ${props => props.width};
`;

const FlexWrapper = styled(Wrapper)<FlexWrapper>`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
  align-content: ${props => props.alignContent};
  align-items: ${props => props.alignItems};
`;

const FlexRow = styled(FlexWrapper)<{ flex?: string }>`
  flex: ${props => props.flex};
  align-items: center;
  padding: 1em 0;
  width: ${props => props.width};
`;

export { AppWrapper, PageWrapper, FlexWrapper, FlexRow };
