import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from '../../utils/axios';
import { API_URL } from '../../constants';
import './marketing.css';

export default class UpdateCollections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collections: [],
      groups: [],
      filterGroups: [],
      collection: '',
      collectionId: 0,
      selectedGroup: 0,
      suggestions: [],
      selectOptions: [],
    };
  }

  async componentDidMount() {
    await this.fetchCollections();
    await this.fetchGroups();
  }

  fetchCollections = async () => {
    const url = `${API_URL}/shopify/admin/collections`;
    const response = await axios.get(url);
    this.setState({ collections: response.data });
  };

  async fetchCollectionGroups(collectionId) {
    const url = `${API_URL}/shopify/admin/collectionFilterGroups?collectionId=${collectionId}`;
    const response = await axios.get(url);
    this.setState({ groups: response.data });
    return response.data;
  }

  fetchGroups = async () => {
    const url = `${API_URL}/shopify/admin/filterGroups`;
    const response = await axios.get(url);
    this.setState({ filterGroups: response.data });
  };

  handleClick = async () => {
    const groups = await this.fetchCollectionGroups(this.state.collectionId);
    this.buildSelectOption(groups);
    this.setState({ groups });
  };

  handleChange = e => {
    this.setState({ selectedGroup: e.target.value });
  };

  handleRemove = async e => {
    const id = parseInt(e.target.value, 10);
    const { groups, collectionId } = this.state;
    const newArr = groups.filter(group => group.filter_id !== id);
    const ids = newArr.map(group => [group.filter_id]);
    const body = {
      collectionId,
      filterGroupIds: ids,
    };
    try {
      const response = await axios.put(`${API_URL}/shopify/admin/collectionFilterGroups`, body);
      if (response.status < 300) {
        this.handleClick();
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleAdd = async () => {
    const { filterGroups, selectedGroup, groups, collectionId } = this.state;
    const added = filterGroups.find(group => group.id === parseInt(selectedGroup, 10));
    const ids = groups.map(group => [group.filter_id]);
    const newArray = [...ids, [added.id]];
    const body = {
      collectionId,
      filterGroupIds: newArray,
    };

    try {
      const response = await axios.put(`${API_URL}/shopify/admin/collectionFilterGroups`, body);
      if (response.status < 300) {
        this.handleClick();
      }
    } catch (error) {
      console.log(error);
    }
  };

  buildSelectOption(groups) {
    const { filterGroups } = this.state;
    const difference = filterGroups.filter(obj => !groups.some(obj2 => obj.id === obj2.filter_id));
    const selectOptions = difference.map(group => (
      <option key={group.id} value={group.id}>
        {group.name}
      </option>
    ));

    this.setState({ selectOptions });
  }

  // #region autosuggest
  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getSuggestions = value => {
    const inputValue = this.escapeRegexCharacters(value.trim().toLowerCase());
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.collections.filter(
          collection => collection.handle.toLowerCase().slice(0, inputLength) === inputValue,
        );
  };

  getSuggestionValue = suggestion => {
    this.setState({
      collectionId: suggestion.id,
      collection: suggestion.handle,
    });
    return suggestion.handle;
  };

  renderSuggestion = suggestion => <div>{suggestion.handle}</div>;

  onAutoChange = (event, { newValue }) => {
    this.setState({
      collection: newValue,
    });
  };

  onBlur = (event, { highlightedSuggestion }) => {
    if (highlightedSuggestion) {
      const { handle, id } = highlightedSuggestion;
      this.setState({ collection: handle, collectionId: id });
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  // #endregion

  render() {
    const { collection, suggestions, collectionId } = this.state;
    const inputProps = {
      placeholder: 'Type a collection for suggestions',
      value: collection,
      onChange: this.onAutoChange,
      onBlur: this.onBlur,
    };

    return (
      <section className="flex-column-center">
        <h1 className="text-centered">Collections View and Editor</h1>
        <p className="text-centered">
          Select a collection and either add or remove groups associated with it to show up in
          filters.
        </p>
        <div className="wrap" style={{ marginTop: '25px' }}>
          <div>
            <label>Search Collection:</label>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              inputProps={inputProps}
              highlightFirstSuggestion
            />
          </div>
          {collection && (
            <div>
              <p>
                {collection} <span className="small-gray-text">{collectionId || ''}</span>
              </p>
              <button className="classy-button" type="button" onClick={this.handleClick}>
                INSPECT
              </button>
            </div>
          )}
        </div>
        {this.state.selectOptions.length > 0 && (
          <span className="marketing">
            <ul className="list-wrap">
              <li>
                Group Name - <span className="small-gray-text">Sort Weight</span>
              </li>
              {this.state.groups.map((group, i) => (
                <li key={group.filter_id} className={i % 2 === 0 ? 'even-li' : 'odd-li'}>
                  {group.name} - <span className="small-gray-text">{group.sort_weight}</span>{' '}
                  <button
                    className="remove-button"
                    value={group.filter_id}
                    onClick={this.handleRemove}
                  >
                    remove
                  </button>
                </li>
              ))}
              <select
                className="text-input"
                onChange={this.handleChange}
                value={this.state.selectedGroup}
                style={{ margin: '15px 50px' }}
              >
                <option disabled={this.state.selectedGroup > 0}>Select a group to add</option>
                {this.state.selectOptions}
              </select>
              <button className="classy-button" onClick={this.handleAdd}>
                Add Group
              </button>
            </ul>
          </span>
        )}
      </section>
    );
  }
}
