import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { API_URL } from '../../../constants';
import axios from '../../../utils/axios';
import Loader from '../../../components/loader';

interface Props {
  id: number;
  handleSubmit: () => any;
  handleSuccess: () => any;
  handleError: () => any;
}

const spacing = { margin: '1rem 0' };

const PartnerReturnForm = ({ id, handleSubmit, handleSuccess, handleError }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [failReason, setFailReason] = useState('');
  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        try {
          handleSubmit();
          setIsSubmitting(true);
          await axios.post(`${API_URL}/tradeup/partners/return/${id}`, {
            failReason,
          });
          handleSuccess();
        } catch (error) {
          console.error(error);
          handleError();
        } finally {
          setIsSubmitting(false);
        }
      }}
    >
      <p style={spacing}>
        {`This submission can't be returned via the traditional ship-back method.`}
      </p>
      <p>
        Submitting this form will dispatch an email that will trigger the manual rejection process.
      </p>
      <TextField
        multiline
        rows={5}
        required
        label="Reason for rejection"
        value={failReason}
        onChange={e => setFailReason(e.target.value)}
        style={spacing}
      />
      <button type="submit" className="submit-button" disabled={isSubmitting}>
        SUBMIT
      </button>
      <Loader loading={isSubmitting} />
    </form>
  );
};

export default PartnerReturnForm;
