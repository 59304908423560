import React, { useState, useEffect } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Button, Grid, Popover } from '@material-ui/core';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import Loader from '../../components/loader';
import TextInput from '../../components/catalog/TextInput';
import ErrorBoundary from '../../components/ErrorBoundary';
import * as service from '../../services/catalog/services';
import { ProductVariantSearchResult } from '../../services/catalog/types';
import styles from '../../styledComponents/';
const { Error } = styles;

const Root = styled.div`
  margin: 2rem;
`;

const StyledLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 20px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 50px;
`;

const StyledResult = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  padding: 10px;
  width: 100%;
  cursor: pointer;
`;

const StyledActions = styled.div`
  display: flex;
  flex-direction: column;
`;

const CatalogProductUpcQueryAndView: React.FC<RouteComponentProps<{ upc?: string }>> = ({
  match,
  history,
}) => {
  const { upc: initialUpc } = match.params;

  const [upc, setUpc] = useState(initialUpc || '');
  const [loading, setLoading] = useState(false);
  const [foundPv, setFoundPv] = useState<ProductVariantSearchResult[] | undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopover, setOpenPopover] = useState('');
  const handleClick = (event: any, sku: string) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(sku);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenPopover('');
  };

  const queryForVariantByUpc = async (searchUpc: string) => {
    if (!loading && !!upc) {
      setErrorMsg('');
      setLoading(true);
      await service.fetchCatalogProductsSearch(
        { upc: searchUpc, limit: 100 },
        {
          onSuccess: pvSearchResults => {
            const foundPvByUpc = pvSearchResults.results;
            if (!!foundPvByUpc) {
              setFoundPv(foundPvByUpc);
            } else {
              setErrorMsg(`Unable to find SKU with UPC: ${searchUpc}`);
            }
          },
          catchFailure: err => {
            console.error('Search for variants by upc error:', err);
            setErrorMsg('Search failed, try again or contact Tech');
          },
        },
      );
      setLoading(false);
    }
  };

  // If upc in url params, search right away
  useEffect(() => {
    if (initialUpc) {
      queryForVariantByUpc(initialUpc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUpc]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await queryForVariantByUpc(upc);
    history.replace(`/catalog/upc/${upc}`);
  };

  return (
    <Root>
      <ErrorBoundary>
        {!foundPv && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="column"
            component="form"
            onSubmit={handleSubmit}
          >
            <h2>UPC SEARCH</h2>
            <TextInput
              id="upc"
              label="Enter UPC to search for:"
              placeholder="e.g. 123-4567-865"
              value={upc}
              onChange={e => setUpc(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={loading || !upc || !/\S/.test(upc)}
            >
              Submit
            </Button>
            <Error>{errorMsg}</Error>
          </Grid>
        )}
        {!!foundPv && (
          <>
            <StyledHeader>
              <div>
                <h2>UPC VIEW</h2>
                <h3>
                  Found {foundPv.length} product{foundPv.length === 1 ? '' : 's'} for UPC &quot;
                  {upc}&quot;
                </h3>
              </div>
              <Button to="/catalog/upc" variant="contained" size="large" component={RouterLink}>
                Search for another UPC / Exit
              </Button>
            </StyledHeader>
            <StyledLayout>
              {foundPv.map(pv => (
                <div key={pv.sku}>
                  <StyledResult
                    onClick={event => handleClick(event, pv.sku)}
                    aria-describedby={pv.sku}
                  >
                    <h4>SKU: {pv.sku}</h4>
                    <div>
                      <MoreHorizIcon />
                    </div>
                  </StyledResult>
                  <Popover
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    open={openPopover === pv.sku}
                    id={pv.sku}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    elevation={1}
                  >
                    <StyledActions>
                      <Button
                        to={`/service/relocate?sku=${pv.sku}`}
                        variant="contained"
                        size="large"
                        component={RouterLink}
                      >
                        Relocate
                      </Button>
                      <Button
                        to={`/products/createBarcode?sku=${pv.sku}`}
                        variant="contained"
                        size="large"
                        component={RouterLink}
                      >
                        Print SKU
                      </Button>
                      <Button
                        to={`/catalog/products/${pv.productId}`}
                        variant="contained"
                        size="large"
                        component={RouterLink}
                      >
                        View in Item Catalog
                      </Button>
                    </StyledActions>
                  </Popover>
                </div>
              ))}
            </StyledLayout>
          </>
        )}
        <Loader loading={loading}></Loader>
      </ErrorBoundary>
    </Root>
  );
};

export default CatalogProductUpcQueryAndView;
