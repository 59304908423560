import React from 'react';
import styled from 'styled-components';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  makeStyles,
  createStyles,
  Avatar,
  Theme,
} from '@material-ui/core';

import { Button } from '../../components/library';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

interface HorizontalFormCardProps {
  title: string;
  description: string;
  icon: object;
  link: string;
  linkText: string;
}

const HorizontalCard = styled(Card)`
  && {
    padding: ${({ theme }) => `${theme.spacing(2)}px`};
    box-sizing: border-box;
    margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
    ${props => props.theme.breakpoints.up('md')} {
      display: flex;
      padding: 0 ${({ theme }) => `${theme.spacing(2)}px`};
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const SubmitIcon = styled(ArrowForwardIcon)`
  padding-left: ${({ theme }) => `${theme.spacing(1)}px`};
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    primaryAvatar: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
  }),
);

const newTab = { target: '_blank', rel: 'noopener noreferrer' };

export const HorizontalFormCard: React.FC<HorizontalFormCardProps> = props => {
  const classes = useStyles();
  return (
    <HorizontalCard>
      <Avatar className={classes.primaryAvatar}>{props.icon}</Avatar>
      <CardContent className={classes.fullWidth}>
        <Typography variant="h6">{props.title}</Typography>
        <Typography variant="body2" color="textSecondary">
          {props.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          ordinality="secondary"
          size="medium"
          variant="outlined"
          href={props.link}
          className={classes.noWrap}
          {...newTab}
        >
          <>
            {props.linkText}
            <SubmitIcon />
          </>
        </Button>
      </CardActions>
    </HorizontalCard>
  );
};
