import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  createStyles,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  TextField,
  Snackbar,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { slideTransition } from '../../../../../../components/library/SlideTransition';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { API_URL } from '../../../../../../constants';
import axios from '../../../../../../utils/axios';
import { useSnackbar } from '../../../../../../components/library';
import { Alert } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import { FormikIntakeState } from '../../../_shared/types';
import { serviceTicketContext } from '../../../_shared';
import { pageStateContext } from '../../../../../_shared';
import { FixSerialNumber } from './FixSerialNumber';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row-reverse',
    },
    subHeader: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.primary,
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    alertLink: {
      color: theme.palette.common.white,
      textDecoration: 'underline',
    },
  }),
);

interface BikeDetailsProps {
  isSerialNumberVerified: boolean;
  isBikeStolen: boolean;
  setIsSerialNumberVerified: React.Dispatch<React.SetStateAction<boolean>>;
  setIsBikeStolen: React.Dispatch<React.SetStateAction<boolean>>;
  handleDataChange(dirty: boolean): void;
  handleIntakeSave(values: FormikIntakeState): void;
}

export const BikeDetails = ({
  isSerialNumberVerified,
  isBikeStolen,
  setIsSerialNumberVerified,
  setIsBikeStolen,
  handleDataChange,
  handleIntakeSave,
}: BikeDetailsProps) => {
  const classes = useStyles();

  // This use of snackbar in this component is a unique scenario for handling error messages
  // with special display considerations, so it has it's own state in this component.
  const { snackbarOpen, handleSnackbarOpen, handleSnackbarClose } = useSnackbar();
  const [allowUpdateStolenSerialNumber, setAllowUpdateStolenSerialNumber] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isProcessingData, setIsProcessingData] = useState<boolean>(false);

  const {
    loadingBus: { setLoading },
  } = useContext(pageStateContext);

  // service plan inspection data
  const { inspection } = useContext(serviceTicketContext)?.inspectionData;
  const inspectionSerialNumber = inspection?.serialNumber;

  // product data associated to service plan
  const { brand, model, wheelSize, build, bikeCategory, wheelTier, powerMeter, frameMaterial } =
    inspection?.itemProcurement?.sybSubmission?.items[0] ?? {};

  // formik form data
  const { values, setFieldValue, dirty } = useFormikContext<FormikIntakeState>();
  const formInspectionSerialNumber = values.inspectionSerialNumber;

  const isBikeVerified = (isSerialNumberVerified && isBikeStolen) || !!formInspectionSerialNumber;

  handleDataChange(dirty);

  // verify the service plan serial number (if one exists) with BikeIndex on initial load of the page
  useEffect(() => {
    const verifySerialNumber = async () => {
      try {
        // check whether the bike has been flagged in BikeIndex as stolen
        setLoading('Verify Bike', true);
        const result = await axios.get(
          `${API_URL}/service/bikeIndex/stolen/${formInspectionSerialNumber}`,
        );
        setLoading('Verify Bike', false);

        // if the bike has been flagged as stolen in BikeIndex
        if (result && !!result.data) {
          setIsBikeStolen(true);
          setErrorMessage(
            'THIS BIKE MAY BE STOLEN. DO NOT PROCESS THIS BIKE. STOP AND ESCALATE TO YOUR SUPERVISOR IMMEDIATELY.',
          );
          handleSnackbarOpen();
          setAllowUpdateStolenSerialNumber(false);
        }
      } catch (err) {
        if (err instanceof Error) {
          if (isProcessingData) {
            setIsProcessingData(false);
          }
          setErrorMessage(err.message);
          handleSnackbarOpen();
        }
      }
    };

    if (inspectionSerialNumber && !isSerialNumberVerified) {
      verifySerialNumber();
      setIsSerialNumberVerified(true);
    }
  }, [
    isSerialNumberVerified,
    setIsSerialNumberVerified,
    inspectionSerialNumber,
    isProcessingData,
    formInspectionSerialNumber,
    setIsBikeStolen,
    handleSnackbarOpen,
    setErrorMessage,
    setLoading,
  ]);

  return (
    <>
      <ListSubheader className={classes.subHeader}>Bike Details</ListSubheader>
      <ListItem>
        {isBikeStolen && (
          <FixSerialNumber
            isBikeStolen={isBikeStolen}
            allowUpdateStolenSerialNumber={allowUpdateStolenSerialNumber}
            setAllowUpdateStolenSerialNumber={setAllowUpdateStolenSerialNumber}
          />
        )}
        <Box my={1} width="100%" position={'relative'}>
          <TextField
            id="outlined-basic"
            label="Serial Number"
            variant="outlined"
            margin="dense"
            value={formInspectionSerialNumber}
            onChange={e => setFieldValue('inspectionSerialNumber', e.target.value)}
            helperText={isBikeVerified ? '' : 'Confirm or edit the serial number'}
            error={!isBikeVerified}
            disabled={isBikeStolen && !allowUpdateStolenSerialNumber}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {(!isBikeStolen || allowUpdateStolenSerialNumber) && (
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      color={isBikeVerified ? 'primary' : 'secondary'}
                      onClick={async () => {
                        if (!!formInspectionSerialNumber) {
                          try {
                            setIsProcessingData(true);
                            // check whether the bike has been flagged in BikeIndex as stolen
                            const result = await axios.get(
                              `${API_URL}/service/bikeIndex/stolen/${formInspectionSerialNumber}`,
                            );
                            handleIntakeSave(values);
                            setIsProcessingData(false);

                            // clear state variables to default state
                            if (isBikeStolen) {
                              setIsBikeStolen(false);
                              handleSnackbarClose();
                            }
                            if (isSerialNumberVerified) {
                              setIsSerialNumberVerified(false);
                            }

                            if (result) {
                              setIsSerialNumberVerified(true);

                              // if the bike has been flagged as stolen in BikeIndex
                              if (!!result.data) {
                                setIsBikeStolen(true);
                                setErrorMessage(
                                  'THIS BIKE MAY BE STOLEN. DO NOT PROCESS THIS BIKE. STOP AND ESCALATE TO YOUR SUPERVISOR IMMEDIATELY.',
                                );
                                handleSnackbarOpen();
                              }
                            }
                          } catch (err) {
                            if (err instanceof Error) {
                              if (isProcessingData) {
                                setIsProcessingData(false);
                              }
                              setErrorMessage(err.message);
                              handleSnackbarOpen();
                            }
                          }
                        }
                      }}
                      style={{
                        opacity: isBikeVerified ? '' : '.27',
                        display: isProcessingData ? 'none' : 'block',
                      }}
                    >
                      <CheckBoxIcon />
                    </IconButton>
                  )}
                  <CircularProgress
                    color="primary"
                    size={16}
                    style={{ display: isProcessingData ? 'block' : 'none', marginRight: '4px' }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </ListItem>
      <Divider />
      {brand && (
        <>
          <ListItem>
            <ListItemText primary={brand} secondary="Brand" className={classes.listFlex} />
          </ListItem>
          <Divider />
        </>
      )}
      {model && (
        <>
          <ListItem>
            <ListItemText primary={model} secondary="Model" className={classes.listFlex} />
          </ListItem>
          <Divider />
        </>
      )}
      {wheelSize && (
        <>
          <ListItem>
            <ListItemText primary={wheelSize} secondary="Wheel Size" className={classes.listFlex} />
          </ListItem>
          <Divider />
        </>
      )}
      {build && (
        <>
          <ListItem>
            <ListItemText primary={build} secondary="Build" className={classes.listFlex} />
          </ListItem>
          <Divider />
        </>
      )}
      {bikeCategory?.category && (
        <>
          <ListItem>
            <ListItemText
              primary={bikeCategory.category}
              secondary="Bike Category"
              className={classes.listFlex}
            />
          </ListItem>
          <Divider />
        </>
      )}
      {wheelTier && (
        <>
          <ListItem>
            <ListItemText primary={wheelTier} secondary="Wheel Tier" className={classes.listFlex} />
          </ListItem>
          <Divider />
        </>
      )}
      {powerMeter && (
        <>
          <ListItem>
            <ListItemText
              primary={powerMeter ? 'Yes' : 'No'}
              secondary="Power Meter"
              className={classes.listFlex}
            />
          </ListItem>
          <Divider />
        </>
      )}
      {frameMaterial && (
        <>
          <ListItem>
            <ListItemText
              primary={frameMaterial}
              secondary="Frame Material"
              className={classes.listFlex}
            />
          </ListItem>
          <Divider />
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={null}
        TransitionComponent={slideTransition}
      >
        <Box maxWidth="460px">
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Box>
      </Snackbar>
    </>
  );
};
