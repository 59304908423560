import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import {
  Box,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Grid,
  Theme,
} from '@mui/material';
import { AddBox } from '@material-ui/icons';

import { TrackingNumber } from './TrackingNumber';

import { TextField } from '../../../../components/library';
import { Submission } from '../../../../typeORM';
import { deleteTrackingNumbers, postTrackingNumber } from '../../sybService';
import LabelValuePairDisplay from '../LabelValuePairDisplay';

type TrackingInfoProps = {
  submission: Submission;
  submissionReceivers: string[];
  triggerRefetch: () => Promise<void>;
};

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    color: theme.palette.text.secondary,
  },
}));

export const TrackingInfo = (props: TrackingInfoProps) => {
  const { submission, submissionReceivers, triggerRefetch } = props;

  const [editing, setEditing] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [numbersToBeDeleted, setNumbersToBeDeleted] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    if (!editing) {
      setEditing(true);
      return;
    }

    if (editing && !numbersToBeDeleted.length) {
      setEditing(false);
      return;
    }

    try {
      setLoading(true);
      if (numbersToBeDeleted.length) {
        await deleteTrackingNumbers(numbersToBeDeleted);
        setEditing(false);
        await triggerRefetch();
      }
    } finally {
      setNumbersToBeDeleted([]);
      setLoading(false);
    }
  };

  const handleAddTrackingNumber = async () => {
    if (textValue === '') {
      return;
    }

    setLoading(true);
    const textValueTemp = textValue;
    try {
      setTextValue('');
      await postTrackingNumber(submission.id, textValue);
      try {
        await triggerRefetch();
      } catch {
        setLoading(false);
      }
    } catch {
      setTextValue(textValueTemp);
    } finally {
      setLoading(false);
    }
  };

  const classes = useStyles();
  return (
    <Box className="form-wrap flex-column">
      <Box display="flex" justifyContent="space-between">
        <LabelValuePairDisplay
          labelValuePairs={[
            { label: 'Transaction Id', value: submission?.fedexTransactionObjectId },
            {
              label: 'Parcel',
              value: submission?.fedexParcel,
              copy: { value: submission?.fedexParcel, hint: 'Copy Parcel #' },
            },
            {
              label: 'FedEx Tracking',
              value: submission?.fedexTrackingNumber,
              url: submission?.fedexTrackingUrlProvider,
              copy: { value: submission?.fedexTrackingNumber, hint: 'Copy FexEx Tracking Number' },
            },
            {
              label: 'Label',
              value: submission.fedexLabelUrl ? 'View' : null,
              url: submission.fedexLabelUrl,
              copy: { value: submission.fedexLabelUrl, hint: 'Copy Label URL' },
            },
          ]}
        />
      </Box>
      {!!submissionReceivers.length && <p>Received By: {submissionReceivers.join(', ')}</p>}
      {!!submission.receivedTrackingNumbers?.length && (
        <Grid container justifyContent="space-between" alignItems="start" sx={{ mb: 2 }}>
          <Grid item xs={8}>
            {submission.receivedTrackingNumbers
              .filter(trackingNumber => !numbersToBeDeleted.includes(trackingNumber.id))
              .map(trackingNumberInfo => (
                <TrackingNumber
                  key={trackingNumberInfo.id}
                  editing={editing}
                  setNumbersToBeDeleted={setNumbersToBeDeleted}
                  trackingNumberInfo={trackingNumberInfo}
                />
              ))}
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            <Button
              color={loading ? 'info' : 'success'}
              variant="contained"
              endIcon={loading && <CircularProgress color={'inherit'} size={'16px'} />}
              disableRipple
              disabled={loading}
              onClick={() => handleSave()}
            >
              {editing ? 'Save' : 'Edit'}
            </Button>
          </Grid>
        </Grid>
      )}
      <Box sx={{ mt: 2 }}>
        <TextField
          disabled={loading}
          placeholder="Add Tracking Number"
          inputProps={{ style: { backgroundColor: 'transparent' } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={loading}
                  disableRipple
                  edge="end"
                  onClick={handleAddTrackingNumber}
                >
                  <AddBox className={classes.addButton} />
                </IconButton>
              </InputAdornment>
            ),
            style: { backgroundColor: 'transparent' },
          }}
          onChange={e => setTextValue(e.target.value)}
          variant="outlined"
          value={textValue}
        />
      </Box>
    </Box>
  );
};
