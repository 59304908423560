import React, { Fragment } from 'react';

const CpoSubmission = ({ valueAtSubmissionTime, cpoSubmission }) => (
  <div className="flex-column">
    {valueAtSubmissionTime && (
      <Fragment>
        <h4>CPO Submission Details (Selling SKU back)</h4>
        <span className="three-sixty-wrap">
          <label>BUYBACK MONTH: </label>
          <span>{valueAtSubmissionTime.month}</span>
        </span>
        <span className="three-sixty-wrap">
          <label>BUYBACK AMOUNT: </label>
          <span>{valueAtSubmissionTime.amount}</span>
        </span>
        <span className="three-sixty-wrap">
          <label>SOLD DATE: </label>
          <span>{new Date(valueAtSubmissionTime.sold_date).toLocaleDateString()}</span>
        </span>
        <span className="three-sixty-wrap">
          <label>UPDATED DATE: </label>
          <span>{new Date(valueAtSubmissionTime.updated_at).toLocaleDateString()}</span>
        </span>
        <span className="three-sixty-wrap">
          <label>SUBMITTED DATE: </label>
          <span>{new Date(cpoSubmission.createdAt).toLocaleDateString()}</span>
        </span>
        <span className="three-sixty-wrap">
          <label>SUBMISSION STATE: </label>
          <span>{cpoSubmission.state}</span>
        </span>
        <span className="three-sixty-wrap">
          <label>SUBMISSION CONTACT EMAIL: </label>
          <span>{cpoSubmission.email}</span>
        </span>
      </Fragment>
    )}
  </div>
);

export default CpoSubmission;
