import axios from '../../utils/axios';
import { API_URL, TRANSITIONABLE_STATUSES } from '../../constants';
import { NotificationManager } from 'react-notifications';

export const fetchPoBrands = async () => {
  try {
    const url = `${API_URL}/products/brands`;
    const res = await axios.get(url);
    if (res) {
      const brands = res.data;
      return brands;
    }
  } catch (e) {
    console.error(e);
  }
};

export const fetchCurrentValue = async (sku: string) => {
  const url = `${API_URL}/cpo/currentValue/${sku}`;
  try {
    const response = await axios.get(url);
    return response;
  } catch (e) {
    console.error(e);
  }
};

// TODO: Used to show links to previous submissions - keep?
export const fetchPreviousPartnerSubmissions = async (email: string) => {
  try {
    const url = `${API_URL}/tradeup/partnerSubmissionsByEmail/${email}`;
    const response = await axios.get(url);
    if (response) {
      return response.data;
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

export const fetchSubmissionReceivers = async (submissionId: number) => {
  try {
    const url = `${API_URL}/tradeup/submissions/${submissionId}/submissionReceivers`;
    const response = await axios.get(url);
    if (response && response.data) {
      const names: string[] = Array.from(
        new Set(response.data.map((receiver: { name: string }) => receiver.name)),
      );
      return names;
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};

export const notifyInternalInfoMissing = (message: string) => {
  NotificationManager.error(message, 'Missing Internal Info', 10000);
};

export const notifyAdminPermissionError = () => {
  NotificationManager.error(
    'You must have admin permissions to view this page',
    'Permission Error',
    10000,
  );
};

export const notifyError = (message: string) => {
  NotificationManager.error(message, 'Error', 10000);
};

const transitionableStatuses = TRANSITIONABLE_STATUSES;
type Status = { id: number; status: string };
export const formatStatuses = (data: Array<Status>) =>
  data.filter(v => transitionableStatuses.includes(v.id));

export const deleteTrackingNumbers = async (ids: number[]) => {
  try {
    const url = `${API_URL}/tradeup/submissionReceivedTrackingNumbers`;
    const res = await axios.delete(url, { data: { ids: ids } });

    if (res) {
      return res.data;
    }
  } catch (e) {
    console.error(e);
  }
};

export const postTrackingNumber = async (submissionId: number, trackingNumber: string) => {
  try {
    const url = `${API_URL}/tradeup/submissionReceivedTrackingNumbers`;
    const res = await axios.post(url, { submissionId, trackingNumber });

    if (res) {
      return res.data;
    }
  } catch (e) {
    console.error(e);
  }
};
