const darkMode = false;

export const colors = {
  primary: darkMode ? '#212121' : '#ececec',
  secondary: darkMode ? '#121212' : '#424242',
  accent: '#4CAF50',
  section: darkMode ? '#313131' : '#FAFAFA',
  error: darkMode ? '#FFB74D' : '#DD2C00',
  warning: darkMode ? '#FF6D00' : '#DD2C00',
  success: darkMode ? '#81C784' : '#00C853',
  border: darkMode ? '#424242' : '#212121',
  font: darkMode ? '#ececec' : '#424242',
  hoverFont: darkMode ? '#424242' : '#ececec',
};
