import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const WheelInfo = ({ wheelType, wheelFamily }) => (
  <Fragment>
    <div className="form-wrap">
      <span className="input-label-wrap-status offer-display underline">
        <label className="label">Type:</label>
        <p>{wheelType}</p>
      </span>
      <span className="input-label-wrap-status doo-display underline">
        <label className="label doo-label">Family:</label>
        <p>{wheelFamily}</p>
      </span>
    </div>
  </Fragment>
);

export default WheelInfo;

WheelInfo.propTypes = {
  wheelFamily: PropTypes.string.isRequired,
  wheelType: PropTypes.string.isRequired,
};
