import { GET_NAV_EXPANDED, SET_NAV_EXPANDED } from '../actions/types';

interface NavAction {
  type: string;
  payload: any;
}

const defaultState = {
  navExpanded: true,
};

export default (state = defaultState, action: NavAction) => {
  switch (action.type) {
    case GET_NAV_EXPANDED: {
      return {
        ...state,
      };
    }
    case SET_NAV_EXPANDED: {
      return {
        ...state,
        navExpanded: action.payload,
      };
    }
    default:
      return state;
  }
};
