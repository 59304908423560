import { SW_INIT, SW_UPDATE } from '../actions/types';

export default (
  state = {
    isInitialized: false,
    isUpdated: false,
    registration: null,
  },
  action,
) => {
  switch (action.type) {
    case SW_INIT: {
      return {
        ...state,
        isInitialized: true,
      };
    }
    case SW_UPDATE: {
      return {
        ...state,
        isUpdated: true,
        registration: action.payload,
      };
    }
    default:
      return state;
  }
};
