import React, { useMemo } from 'react';
import styled from 'styled-components';
import ProductImageThumbnail from './productImages/ProductImageThumbnail';
import ProductVariantStatusFlag from './ProductVariantStatusFlag';
import { Product } from '../../services/catalog/types';
import { NewOrExistingVariant } from './utils/productVariant/types';

const TitleWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
`;

interface Props {
  sku: string;
  statusFlagId?: number | null;
  imageUrl?: string;
  productVariant: NewOrExistingVariant;
  product: Product;
}

/**
 * Builds a variant title from the ProductVariantAttributes which a Product varies
 * on, sorted by ProductVariation.displayOrder, and the condition.
 *
 * @param product
 * @param variant
 * @returns string "Black / Small / Pre-Owned"
 */
export const extractTitleFromProductVariantAttributes = (
  product: Product,
  variant: NewOrExistingVariant,
): string => {
  const values = product.productVariations
    .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
    .map(({ attributeType }) => {
      return variant?.productVariantAttributes
        ?.filter(pva => pva.attributeTypeId === attributeType.id)
        .map(pvar => pvar.value);
    });

  if (variant.condition) {
    values.push([variant.condition]);
  }

  return values.join(' / ');
};

export const useVariationValue = (product: Product, productVariant: NewOrExistingVariant) => {
  const variationValue = useMemo(
    () => extractTitleFromProductVariantAttributes(product, productVariant),
    [product, productVariant],
  );

  return variationValue;
};

const VariantTitle: React.FC<Props> = ({
  sku,
  statusFlagId,
  imageUrl,
  product,
  productVariant,
}) => {
  const variationValue = useVariationValue(product, productVariant);

  return (
    <>
      <TitleWrapper>
        <ProductImageThumbnail src={imageUrl} identifier={sku} />
        {sku}
        {!!statusFlagId && <ProductVariantStatusFlag value={statusFlagId} />} {variationValue}
      </TitleWrapper>
    </>
  );
};

export default VariantTitle;
