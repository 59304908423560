import React, { useCallback, useContext, useEffect } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withRouter, RouteComponentProps, Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Button, ButtonGroup, createStyles, makeStyles, Theme } from '@material-ui/core';
import get from 'lodash/get';
import styles from '../../../styledComponents';
import { PageHeader } from '../../../styledComponents/headers';
import styled from 'styled-components';
import ViewProductVariant, { Wrapper } from './ViewProductVariant';
import DisplayField from '../../../components/catalog/DisplayField';
import { useSelector } from 'react-redux';
import ShopifyStatusField from '../../../components/catalog/ShopifyStatusField';
import ReadyForShopifyPublish from '../../../components/catalog/ReadyForShopifyPublish';
import useProduct from '../utils/useProduct';
import { CatalogProductState } from '../../../reducers/product-reducer';
import { GlobalState, Permissions } from '../../../reducers/types';
import { Product } from '../../../services/catalog/types';
import { InspectionStatusEnum } from '../../../services/service/types';
import { InspectionStatusBadge } from '../../service/serviceTicket/InspectionStatusBadge';

import ImagesPreview from '../../../components/catalog/ImagesPreview';
import { cycleTimingsContext } from '../../service/cycleTimings/cycleTimingsContext';
import { StickyHeader } from '../../../components/library/StickyHeader/StickyHeader';

const { PageWrapper } = styles;

const VariantHeader = styled.h2`
  margin-top: 30px;
`;

const LeftAligned = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inspectionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      maxWidth: '185px',
    },
    statusContainer: {
      margin: theme.spacing(0, 1.25),
    },
    productContainer: {
      [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 650px)',
        float: 'left',
      },
    },
    noShrink: {
      flexShrink: 0,
    },
  }),
);

const NavigationAndMenu = ({
  productId,
  hasImagesPermission,
}: {
  productId: string;
  hasImagesPermission: boolean;
}) => (
  <LeftAligned>
    <ButtonGroup>
      <Button to={`/catalog/products/${productId}/edit`} variant="outlined" component={RouterLink}>
        Create Variant
      </Button>
      <Button to={`/catalog/products/${productId}/edit`} variant="outlined" component={RouterLink}>
        Edit Product
      </Button>
      {hasImagesPermission && (
        <Button
          to={`/catalog/products/${productId}/images`}
          variant="outlined"
          component={RouterLink}
        >
          Add / Edit Images
        </Button>
      )}
      <Button to="/catalog/products" variant="outlined" component={RouterLink}>
        Exit
      </Button>
    </ButtonGroup>
  </LeftAligned>
);

type GetterFunc = (productStore: CatalogProductState, product: Product) => string | undefined;

const displayItemsMap: { [key: string]: string | GetterFunc } = {
  Title: 'title',
  'Display Title': 'displayTitle',
  Description: 'description',
  Model: 'model',
  Make: 'brand.name',
  Geometry: 'geometry',
  Category: (productStore, product) =>
    productStore.categories.find(c => c.id === product.categoryId)?.category,
  Discipline: 'discipline.discipline',
  'Sub Category': 'productSubCategory.name',
  Variations: (_productStore, product) =>
    product.productVariations.map(v => v.attributeType.name).join(', '),
  'Certified Pre-Owned': (_productStore, product) => (product.certifiedPreOwned ? 'Yes' : 'No'),
  'Sell Pre-Service': (_productStore, product) => (product.sellPreService ? 'Yes' : 'No'),
  'Shopify Id': 'shopifyProductId',
};

const ViewProduct: React.FC<RouteComponentProps<{ productId: string }>> = ({ match }) => {
  const productId = match.params.productId;
  const {
    product,
    firstVariantInspection,
    shopifyStatus,
    shopifyPublishReady,
    shopifyPublishErrors,
    refreshProductData,
    error,
  } = useProduct(productId);
  const classes = useStyles();
  const { setCycleTimingSku } = useContext(cycleTimingsContext);
  const productStore = useSelector((state: GlobalState) => state.product);
  const { user } = useSelector((state: GlobalState) => state.auth);
  const location = useLocation();
  const skuParam = new URLSearchParams(location.search).get('sku');
  const userHasImagesPermission = !!user?.permissions.find(
    p => p.id === Permissions.CATALOG_UPDATE_IMAGES,
  );

  const expandedVariantRef = useCallback(
    node => {
      if (!!skuParam && node !== null) {
        node.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
    [skuParam],
  );

  let status = '';
  if (firstVariantInspection) {
    status = InspectionStatusEnum[firstVariantInspection.status];
  }

  useEffect(() => {
    if (product?.productVariants?.length) {
      setCycleTimingSku(product?.productVariants[0]?.sku);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <PageWrapper>
      {product && (
        <div>
          <PageHeader>Product Specifications</PageHeader>
          <StickyHeader
            title={product.title}
            actions={
              <NavigationAndMenu
                productId={productId}
                hasImagesPermission={userHasImagesPermission}
              />
            }
            mx={-4}
          />

          <ShopifyStatusField status={shopifyStatus} />
          <ReadyForShopifyPublish
            shopifyPublishErrors={shopifyPublishErrors}
            shopifyPublishReady={shopifyPublishReady}
            shopifyStatus={shopifyStatus}
            productId={product.id}
            onPublishSuccess={refreshProductData}
          />
          <Box className={product?.images?.length > 0 ? classes.productContainer : ''}>
            <Wrapper>
              {Object.entries(displayItemsMap).map(([key, value]) => {
                let resValue: string | null | undefined;
                if (typeof value === 'function') {
                  resValue = value(productStore, product);
                } else {
                  resValue = get(product, value);
                }
                if (resValue === null || resValue === undefined || resValue === '') {
                  return null;
                }
                return <DisplayField key={key} label={key} value={resValue} />;
              })}
            </Wrapper>
          </Box>
          <ImagesPreview images={product.images} productId={product.id} />

          {!!firstVariantInspection && (
            <Box style={{ clear: 'both' }}>
              <h3>Service Plan</h3>
              <Box className={classes.inspectionContainer}>
                <DisplayField
                  label="Drivetrain Service Plan"
                  value={firstVariantInspection.id}
                  url={`/service/service-plans/${firstVariantInspection.id}`}
                />
                <Box className={classes.statusContainer}>
                  <InspectionStatusBadge label={status} statusId={firstVariantInspection?.status} />
                </Box>
              </Box>
            </Box>
          )}
          <VariantHeader style={{ clear: 'both' }}>Variants</VariantHeader>
          {product.productVariants.map((productVariant, i) => {
            const isHighlightedVariant = skuParam === productVariant.sku;
            return (
              <ViewProductVariant
                key={productVariant.id}
                productVariant={productVariant}
                productId={product.id}
                defaultExpanded={!!skuParam ? isHighlightedVariant : i === 0}
                product={product}
                ref={isHighlightedVariant ? expandedVariantRef : null}
              />
            );
          })}
          <ReadyForShopifyPublish
            shopifyPublishErrors={shopifyPublishErrors}
            shopifyPublishReady={shopifyPublishReady}
            shopifyStatus={shopifyStatus}
            productId={product.id}
            onPublishSuccess={refreshProductData}
          />
          <NavigationAndMenu productId={productId} hasImagesPermission={userHasImagesPermission} />
        </div>
      )}
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Product not found
        </Alert>
      )}
    </PageWrapper>
  );
};

export default withRouter(ViewProduct);
