import React, { FC, useState } from 'react';
import styled from 'styled-components';
import ProductImageThumbnail from './ProductImageThumbnail';
import { useKeyPress } from '../../../utils/hooks/useKeyPress';

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const HoveredImage = styled.img<{ direction?: string }>`
  position: absolute;
  width: 25rem;
  height: auto;
  z-index: 1;
  left: 0;
  top: 3rem;
`;

const HoverableImageThumbnail: FC<{
  src?: string;
  identifier?: string;
  direction?: 'top' | undefined;
  onClick?(): void;
}> = ({ src, identifier, direction, onClick }) => {
  const [hovered, setHovered] = useState(false);
  useKeyPress({ activationKey: 'escape' }, () => (hovered ? setHovered(false) : null));

  return (
    <ImageWrapper
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ProductImageThumbnail src={src} identifier={identifier} />
      {hovered && !!src && (
        <HoveredImage
          src={src}
          alt={`${identifier} product image magnified`}
          direction={direction}
        />
      )}
    </ImageWrapper>
  );
};

export default HoverableImageThumbnail;
