import {
  GET_CATCH_RELEASE_FILTERS,
  GET_CATCH_RELEASE_SORT,
  GET_CATCH_RELEASE_PAGE,
  RESET_CATCH_RELEASE_STORE,
} from '../actions/types';

interface CatchReleaseAction {
  type: string;
  payload: any;
}

const defaultState = {
  filters: {
    submissionId: null,
    submissionStatus: null,
    buyerId: null,
    sellerInfo: null,
    item: null,
    expectedValue: null,
    classTier: null,
    disciplineId: null,
    created: null,
  },
  sort: {
    sort: null,
    sortOrder: null,
  },
  page: 0,
};

export default (state = defaultState, action: CatchReleaseAction) => {
  switch (action.type) {
    case GET_CATCH_RELEASE_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case GET_CATCH_RELEASE_SORT: {
      return {
        ...state,
        sort: action.payload,
      };
    }
    case GET_CATCH_RELEASE_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case RESET_CATCH_RELEASE_STORE: {
      return defaultState;
    }
    default:
      return state;
  }
};
