import React from 'react';
import { TextField, TextFieldProps } from '../../components/library';
import {
  default as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

interface CustomAutocompleteProps {
  inputProps: TextFieldProps;
}

type DefaultAutocompleteProps<T> = MuiAutocompleteProps<T, false, false, false>;

export type AutocompleteProps<T> = CustomAutocompleteProps &
  Omit<DefaultAutocompleteProps<T>, 'renderInput'>;

// intentional `any` - none of the config values set here depend on the type variable
// so we're instantiating this outside the render method for better performance
const filterOptions = createFilterOptions<any>({ trim: true });

export const Autocomplete = <T extends {}>({
  options,
  inputProps,
  ...muiProps
}: AutocompleteProps<T>): React.ReactElement => (
  <MuiAutocomplete<T, false, false, false>
    autoSelect
    autoHighlight
    options={options}
    {...muiProps}
    filterOptions={filterOptions}
    renderInput={(params: any) => <TextField {...params} {...inputProps} />}
  />
);
