import React, { useContext, useState, useEffect } from 'react';
import * as yup from 'yup';
import {
  Button,
  Modal,
  SmallModalContents,
  ModalBody,
  ModalHeader,
  TextField,
  Option,
  ErrorMessage,
} from '../../../../components/library';
import parseYupErrors from '../../../../utils/parseYupErrors';
import { API_URL, SubmissionStatusIds } from '../../../../constants';
import axios from '../../../../utils/axios';
import { serviceTicketContext, shouldUpdateSubmissionStatus } from '../_shared';
import { pageStateContext } from '../../../_shared';
import { updateInspection } from '../../../../services/service/services';

interface ValidationErrors {
  [key: string]: string;
}
interface EditServiceTicketModalProps {
  open: boolean;
  handleClose: () => any;
  handleSuccess: () => void; // todo: get this from context instead of prop drilling
}

const formSchema = yup.object().shape({
  sku: yup.string().required('Please enter a SKU for this item.'),
  serialNumber: yup.string().required('Please enter a serial number for this item.'),
  status: yup.number().required('Please select a new status for this inspection.'),
});

export const EditServiceTicketModal: React.FC<EditServiceTicketModalProps> = ({
  open,
  handleClose,
  handleSuccess,
}) => {
  const {
    errorBus: { setError: setSnackbarError, clearAllErrors: clearSnackbarErrors },
    loadingBus: { setLoading },
    showSuccess,
    setSuccessMessage,
  } = useContext(pageStateContext);
  const {
    inspectionData: { inspection },
    inspectionStatuses: contextInspectionStatuses,
  } = useContext(serviceTicketContext);

  const [sku, setSku] = useState<string>(inspection?.sku ?? '');
  const [serialNumber, setSerialNumber] = useState<string>(inspection?.serialNumber ?? '');
  const [statusId, setStatusId] = useState<number | undefined>(inspection?.statusId);

  const [inspectionStatuses, setInspectionStatuses] = useState<Option[]>();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (contextInspectionStatuses) {
      setInspectionStatuses(
        contextInspectionStatuses.map(status => ({ label: status.name, value: status.id })),
      );
    }
  }, [contextInspectionStatuses]);

  const validate = async () => {
    let isValid = true;
    try {
      await formSchema.validate({ sku, serialNumber, status: statusId }, { abortEarly: false });
    } catch (err) {
      isValid = false;
      const newErrors = parseYupErrors(err, { status: 'statusId' });
      setValidationErrors(newErrors);
    } finally {
      return isValid;
    }
  };

  if (!inspection) {
    return null;
  }

  return (
    <Modal open={open} handleClose={handleClose} label="edit-service-ticket-modal">
      <SmallModalContents>
        <ModalHeader>Edit details</ModalHeader>
        <ModalBody>
          {error && <ErrorMessage error={error} />}
          <TextField
            id="inspectionEditServiceTicketModalSku"
            value={sku || ''}
            onChange={e => setSku(e.target.value)}
            placeholder="SKU"
            label="SKU"
            variant="outlined"
            error={!!validationErrors.sku}
            helperText={validationErrors.sku}
          />
          <TextField
            id="inspectionEditServiceTicketModalSerialNumber"
            value={serialNumber || ''}
            onChange={e => setSerialNumber(e.target.value)}
            placeholder="Serial Number"
            label="Serial Number"
            variant="outlined"
            error={!!validationErrors.serialNumber}
            helperText={validationErrors.serialNumber}
          />
          <TextField
            id="inspectionEditServiceTicketModalStatus"
            value={statusId || ''}
            onChange={e => setStatusId(parseInt(e.target.value))}
            placeholder="Status"
            label="Status"
            variant="outlined"
            options={inspectionStatuses}
            error={!!validationErrors.statusId}
            helperText={validationErrors.statusId}
          />
          <Button
            size="large"
            ordinality="primary"
            type="submit"
            onClick={async () => {
              // successful validation means bin and statusId are defined, but TS can't infer that
              if ((await validate()) && !!sku && !!serialNumber) {
                try {
                  setLoading('EditServiceTicketModal', true);

                  // update inspection
                  await updateInspection(inspection.id, {
                    sku,
                    serialNumber,
                    statusId,
                  });

                  clearSnackbarErrors();
                  handleSuccess();

                  const performUpdate =
                    !!statusId &&
                    (await shouldUpdateSubmissionStatus(
                      statusId,
                      inspection?.itemProcurement?.sybSubmissionId,
                    ));
                  if (performUpdate) {
                    const response = await axios.put(`${API_URL}/tradeup/syb/submission`, {
                      submissionId: inspection?.itemProcurement?.sybSubmissionId,
                      statusId: SubmissionStatusIds.COMPLETED,
                    });
                    if (response) {
                      if (response.status === 200) {
                        setSuccessMessage(
                          'The service plan was updated, and the submission was completed.',
                        );
                        showSuccess();
                      } else {
                        setSnackbarError(
                          'submissionCompletion',
                          new Error(
                            'The service plan was updated, but there was an issue completing the submission.',
                          ),
                        );
                      }
                    }
                  } else {
                    setSuccessMessage('The service plan was updated.');
                    showSuccess();
                  }
                } catch (err) {
                  setError(err.response ? err.response.data.message : 'An unknown error occurred');
                } finally {
                  setLoading('EditServiceTicketModal', false);
                }
              }
            }}
          >
            Save
          </Button>
        </ModalBody>
      </SmallModalContents>
    </Modal>
  );
};
