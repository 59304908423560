import React from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { Submission } from './types';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeAlternate: { backgroundColor: theme.palette.grey[300] },
    alternate: { backgroundColor: 'inherit' },
  }),
);

type SubmissionModalProps = {
  submission: Submission;
  selected: boolean;
  handleAlternateSubmissionClick: (id: number) => void;
};

export const AlternateSubmission = ({
  submission,
  selected,
  handleAlternateSubmissionClick,
}: SubmissionModalProps) => {
  const classes = useStyles();
  return (
    <ListItem
      className={selected ? classes.activeAlternate : classes.alternate}
      button
      dense
      onClick={() => handleAlternateSubmissionClick(submission.id)}
    >
      <ListItemText
        primary={submission?.items[0]?.model}
        secondary={submission?.createdAt ? moment(submission.createdAt).format('MMMM Do YYYY') : ''}
      />
      <ListItemSecondaryAction style={{ pointerEvents: 'none' }}>
        <ArrowRightIcon />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
