import styled from 'styled-components';
import { colors } from './variables';
import cancelImg from '../images/cancel.png';
const border = `${colors.secondary} 0.1em solid`;

const Form = styled.form`
  padding: 2em;
  background-color: ${colors.section};
  width: 100%;
`;

const FancyForm = styled(Form)`
  border: ${border};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input<{
  width?: string;
  height?: string;
  fontSize?: string;
}>`
  width: ${props => props.width || '12em'};
  height: ${props => props.height || '2em;'};
  font-size: ${props => props.fontSize || '1em'};
  cursor: pointer;
  border-radius: 0.2em;
  margin: 0.5em 0;
`;

const CheckBox = styled(Input)`
  margin: 0;
  width: 1em;
  height: 1em;
`;

const TextInput = styled(Input)`
  padding: 0.15em;
  border: ${border};
  font-size: 1.2em;
  overflow: hidden;
  min-width: 6em;
  transition: border-bottom 0.2s;
  &:focus {
    border-bottom: ${colors.accent} 0.1em solid;
    transition: border-bottom 0.2s;
  }
`;
const CloseButton = styled.button`
  cursor: pointer;
  background-image: url(${cancelImg});
  background-size: cover;
  background-repeat: none;
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
`;
const Select = styled.select`
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 0.2em;
  margin: 0.5em 0;
  height: 2em;
  width: 12em;
  border: ${border};
  overflow: hidden;
  transition: border-bottom 0.2s;
  &:focus {
    border-bottom: ${colors.accent} 0.1em solid;
    transition: border-bottom 0.2s;
  }
`;

const SubmitInput = styled(Input)`
  height: 2em;
  background-color: ${colors.primary};
  color: ${colors.font};
  border: ${border};
  transition: background-color 0.2s, color 0.2s;
  &:hover:enabled {
    background-color: ${colors.secondary};
    color: ${colors.hoverFont};
    transition: background-color 0.2s, color 0.2s;
  }
`;

const LabelWrapper = styled.div<{
  flexDirection?: string;
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => props.flexDirection || 'row'};
`;

const Modal = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  padding: 50px;
`;

export {
  Form,
  FancyForm,
  Input,
  TextInput,
  SubmitInput,
  CheckBox,
  Select,
  LabelWrapper,
  Modal,
  CloseButton,
};
