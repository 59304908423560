import {
  ProductVariantComponent,
  Product,
  ProductVariant,
  UpdateProductVariantBody,
  UpdateProductVariantAttribute,
} from '../../../../services/catalog/types';
import {
  ProductVariantComponentSpecValues,
  EditProductVariantValues,
  NewOrExistingVariant,
  FrameDimensionsValues,
  ProductVariantAttributeValue,
} from './types';
import { getFrameDimensionObj, DEFAULT_WEIGHT_FIELD_VALUE, handleApiError } from '..';
import { CatalogProductState } from '../../../../reducers/product-reducer';
import { pick } from 'lodash';
import { selectVariantFieldUnitsByCategoryId } from '../categories';
import {
  updateProductVariant,
  updateProductVariantFrameDimensions,
  updateProductVariantComponentSpecifications,
  updateProductVariantSingleItemProcurement,
  createProductVariant,
} from '../../../../services/catalog/services';
import isEqual from 'react-fast-compare';

/**
 * Setup initial specifications values.
 * Array of spec attributes. Keeping the same order as the componentSpecTypes.
 * ie: Brakes[Title], Cassette[Title], Cassette[Range],...
 *
 * Accessed by Formik via:
 * - [`productVariantComponentSpecs[${cstIdx}].value`]
 *
 * We also setup the specs exactly how the PUT endpoint expects them,
 * so we can just forward these objects & values to the the API
 */
export const getVariantComponentSpecificationsInitialValues = (
  productVariantComponents: ProductVariantComponent[],
): ProductVariantComponentSpecValues[] => {
  return productVariantComponents
    .map(pvc => {
      return pvc.componentType.componentSpecificationTypes.map(cst => {
        // Find and use existing specs value
        const spec = pvc.productVariantComponentSpecifications.find(
          pvcs => pvcs.componentSpecificationTypeId === cst.id,
        );
        return {
          ...(spec?.id && { id: spec.id }), // only add id if it exists
          productVariantComponentId: pvc.id,
          componentTypeId: cst.componentTypeId,
          componentSpecificationTypeId: cst.id,
          value: spec?.value || '',
        };
      });
    })
    .flat();
};

export const getEditProductVariantInitialValues = ({
  productVariant,
  productStore,
  product: { categoryId },
}: {
  productVariant: NewOrExistingVariant;
  productStore: CatalogProductState;
  product: Product;
}): EditProductVariantValues => {
  const {
    id: _id,
    created: _created,
    modified: _modified,
    createdByUserId: _createdByUserId,
    modifiedByUserId: _modifiedByUserId,
    productVariantFrameDimensions,
    productVariantComponents,
    singleItemProcurement,
    weight,
    productVariantAttributes,
    ...rest
  } = productVariant;

  const frameDimensionObj = getFrameDimensionObj(productVariantFrameDimensions);

  const frameDimensionsValues: FrameDimensionsValues['productVariantFrameDimensions'] =
    productStore.frameDimensions.map(frameDimension => {
      const { maxValue = null, minValue = null, textValue = null, id = null } =
        frameDimensionObj[frameDimension.id] || {};
      return {
        [frameDimension.name]: {
          maxValue,
          minValue,
          textValue,
          frameDimensionId: frameDimension.id,
          id,
        },
      };
    }) || [];

  const productVariantComponentSpecs = getVariantComponentSpecificationsInitialValues(
    productVariantComponents || [],
  );

  const singleItemProcurementInitialValues = !!singleItemProcurement
    ? pick(
        singleItemProcurement,
        'holdDays',
        'id',
        'partnerSubmissionId',
        'sybSubmissionId',
        'serialNumber',
        'createPurchaseOrderStatus',
        'createdPurchaseOrderInternalId',
      )
    : {};

  //todo update this to not use old config stuff
  const shouldShowWeightUnits = selectVariantFieldUnitsByCategoryId('weight', categoryId) !== '';

  // TODO TP-4672 - these (along with color, size and wheelSize) should move to a data-driven list of attributes instead, like component specs
  const attributesMasqueradingAsVariantProperties = {
    hazmatInstructions: (productVariantAttributes ?? []).find(pva => pva.attributeTypeId == 5)
      ?.value,
    shippingWeight: (productVariantAttributes ?? []).find(pva => pva.attributeTypeId == 6)?.value,
  };

  const productVariantAttributesByTypeId: {
    [attributeTypeId: number]: ProductVariantAttributeValue;
  } = {};
  if (productVariant.productVariantAttributes?.length) {
    for (const { id: attributeTypeId } of productStore.attributeTypes) {
      const value: string | null =
        productVariant.productVariantAttributes.find(pva => pva.attributeTypeId == attributeTypeId)
          ?.value ?? null;
      productVariantAttributesByTypeId[attributeTypeId] = { attributeTypeId, value };
    }
  }

  return {
    ...rest,
    ...attributesMasqueradingAsVariantProperties,
    weight: shouldShowWeightUnits || !!weight ? weight : DEFAULT_WEIGHT_FIELD_VALUE,
    singleItemProcurement: singleItemProcurementInitialValues,
    productVariantFrameDimensions: frameDimensionsValues,
    productVariantComponentSpecs,
    conditionDescription:
      rest.conditionDescription ||
      productStore.catalogConfiguration?.conditionDescriptionTemplate ||
      '',
    productVariantAttributesByTypeId,
  };
};

export const buildProductVariantAttributesArray = (
  productVariantAttributesByTypeId:
    | {
        [attributeTypeId: number]: ProductVariantAttributeValue;
      }
    | null
    | undefined,
): UpdateProductVariantAttribute[] | undefined => {
  if (productVariantAttributesByTypeId) {
    const productVariantAttributes: UpdateProductVariantAttribute[] = Object.keys(
      productVariantAttributesByTypeId,
    )
      .map(Number)
      .map(attributeTypeId => {
        const value = productVariantAttributesByTypeId
          ? productVariantAttributesByTypeId[attributeTypeId]?.value ?? null
          : null;
        return { attributeTypeId, value };
      });
    return productVariantAttributes;
  }
  return undefined;
};

export const submitEditProductVariant = async ({
  values,
  setError,
  productStore,
  product,
  productVariant,
  initialValues,
  onSubmitSuccess,
  refreshProductData,
  handleSnackbarOpen,
}: {
  values: EditProductVariantValues;
  setError: React.Dispatch<React.SetStateAction<string | string[] | undefined>>;
  productStore: CatalogProductState;
  product: Product;
  productVariant: NewOrExistingVariant;
  initialValues: EditProductVariantValues;
  onSubmitSuccess?(productVariant?: ProductVariant): void;
  refreshProductData: () => Promise<void>;
  handleSnackbarOpen: () => void;
}) => {
  let updatedPv: ProductVariant | undefined;
  try {
    // Reset errors
    setError(undefined);

    const payloadConditionDescription =
      values.conditionDescription ===
      productStore.catalogConfiguration?.conditionDescriptionTemplate
        ? null
        : values.conditionDescription;
    const body: UpdateProductVariantBody = {
      startingPriceUsd: values.startingPriceUsd,
      currentSalePriceUsd: values.currentSalePriceUsd,
      compareAtPriceUsd: values.compareAtPriceUsd,
      promotionalPricingFlag: values.promotionalPricingFlag,
      msrpUsd: values.msrpUsd,
      costUsd: values.costUsd,
      mapPricing: values.mapPricing,
      upc: values.upc || null,
      mpn: values.mpn || null,
      qbpId: values.qbpId || null,
      year: values.year || null,
      condition: values.condition || null,
      pipelineId: values.pipelineId || null,
      statusFlagId: values.statusFlagId || null,
      // If the default has not changed or the string is empty, no need to save that to DB
      weight: values.weight === DEFAULT_WEIGHT_FIELD_VALUE || !values.weight ? null : values.weight,
      pointOfSale: values.pointOfSale ?? false,
      frameMaterial: values.frameMaterial || null,
      frameHeadset: values.frameHeadset || null,
      frameRearAxleSpacing: values.frameRearAxleSpacing || null,
      frameRearShockTravel: values.frameRearShockTravel || null,
      drivetrainBrandId: values.drivetrainBrandId || null,
      drivetrainConfiguration: values.drivetrainConfiguration || null,
      drivetrainShiftingTypeId: values.drivetrainShiftingTypeId || null,
      componentIntendedUseId: values.componentIntendedUseId || null,
      sizeClassId: values.sizeClassId || null,
      brakeType: values.brakeType || null,
      conditionDescription: payloadConditionDescription || null,
      conditionNotes: values.conditionNotes || null,
      gender: values.gender || null,
      material: values.material || null,
      apparelSleeveLength: values.apparelSleeveLength || null,
      frameRearShockTravelRange: values.frameRearShockTravelRange || null,
      tireType: values.tireType || null,
      configurationDetails: values.configurationDetails || null,
      chargerIncluded: values.chargerIncluded || null,
      mileage: values.mileage ?? null,
      keyIncluded: values.keyIncluded || null,
      tubelessCompatibility: values.tubelessCompatibility || null,
      frameRearTriangleMaterial: values.frameRearTriangleMaterial || null,
      electricTopSpeed: values.electricTopSpeed || null,
      // TODO TP-4672 - these should move to a data-driven list of attributes instead, like component specs
      color: values.color || null,
      size: values.size || null,
      wheelSize: values.wheelSize || null,
      hazmatInstructions: values.hazmatInstructions || null,
      shippingWeight: values.shippingWeight || null,
      productVariantAttributes: buildProductVariantAttributesArray(
        values.productVariantAttributesByTypeId,
      ),
      fulfillmentProvider: values.fulfillmentProvider || null,
    };

    // Already existing variant, update it, if not create a new variant
    if (productVariant.id) {
      await updateProductVariant(productVariant.id, body);

      // Update frame dimensions if changed
      if (
        !isEqual(initialValues.productVariantFrameDimensions, values.productVariantFrameDimensions)
      ) {
        await updateProductVariantFrameDimensions(
          productVariant.id,
          values.productVariantFrameDimensions,
        );
      }

      // Update component specs if changed
      if (values.productVariantComponentSpecs?.length) {
        if (
          !isEqual(initialValues.productVariantComponentSpecs, values.productVariantComponentSpecs)
        ) {
          await updateProductVariantComponentSpecifications(productVariant.id, {
            specifications: values.productVariantComponentSpecs,
          });
        }
      }

      // Update item procurement if changed
      if (!isEqual(initialValues.singleItemProcurement, values.singleItemProcurement)) {
        await updateProductVariantSingleItemProcurement(productVariant.id, {
          serialNumber: values.singleItemProcurement.serialNumber,
        });
      }
    } else {
      // Create new variant, it responds with new variant's id needed for subsequent operations
      const { data } = await createProductVariant(product.id, {
        ...body,
        createPurchaseOrder: false, //we never autoPo when adding a variant to a product
        // current sale price gets set on BE based on starting price
        currentSalePriceUsd: null,
        costUsd: body.costUsd ?? null,
      });
      updatedPv = data;
      // Remove existing ids because we cloned from existing variant
      const pvfdWithoutId = values.productVariantFrameDimensions?.map(
        ({ id: _id, ...rest }) => rest,
      );
      if (pvfdWithoutId) {
        await updateProductVariantFrameDimensions(data.id, pvfdWithoutId);
      }
      // Remove ids from component specs too
      // And set the new component type id for the new variant
      const pvcsWithoutId = values.productVariantComponentSpecs
        ?.map(({ id: _id, ...rest }) => {
          const pvc = data.productVariantComponents?.find(
            c => c.componentTypeId === rest.componentTypeId,
          )?.id;

          if (pvc) {
            return {
              ...rest,
              productVariantComponentId: pvc,
            };
          }

          // Technically not possible but better to be safe than sorry
          return;
        })
        .filter((p): p is ProductVariantComponentSpecValues => !!p);

      if (pvcsWithoutId?.length) {
        await updateProductVariantComponentSpecifications(data.id, {
          specifications: pvcsWithoutId,
        });
      }
    }

    // Recall APIs on save
    await refreshProductData();
    handleSnackbarOpen();
    onSubmitSuccess && onSubmitSuccess(updatedPv);
  } catch (err) {
    handleApiError(err, setError);
  }
};
