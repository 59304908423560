import React, { FC, useState } from 'react';
import styled from 'styled-components';

const ResponsiveImg = styled.img`
  width: auto;
  min-height: 100%;
`;

interface ProductImagePreviewProps {
  src: string;
  alt: string;
  className?: string;
  onClick?(): void;
}

const ProductImage: FC<ProductImagePreviewProps> = ({ src, alt, className, onClick }) => {
  const [isLoaded, setIsLoaded] = useState(true);

  return (
    <ResponsiveImg
      className={className}
      style={{ display: isLoaded ? 'block' : 'none' }}
      src={src}
      alt={alt}
      onLoad={() => setIsLoaded(true)}
      onClick={onClick}
    />
  );
};

export default ProductImage;
