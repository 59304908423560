import React from 'react';
import { Detail, DetailLabel } from './styled';
import { useUserName } from '../../_shared';
import { getFormattedDate } from './ServiceTicketHeader';
import {
  servicePlanOperationLabels,
  ServicePlanOperationTypes,
} from '../../../../../services/service/types';

type ServicePlanOperatorProps = {
  operatorId: number;
  operationDt: string | null;
  servicePlanOperationTypeId: ServicePlanOperationTypes;
};

export const ServicePlanOperator = (props: ServicePlanOperatorProps) => {
  const { operatorId, operationDt, servicePlanOperationTypeId } = props;

  const { userName: operatorName } = useUserName(operatorId);

  const formattedDate = operationDt ? getFormattedDate(new Date(operationDt)) : null;

  const operationLabels = servicePlanOperationLabels[servicePlanOperationTypeId];
  const emptyVal = '--';

  return (
    operationLabels && (
      <>
        <Detail>
          <DetailLabel>{operationLabels.operator}: </DetailLabel>
          {operatorName ?? emptyVal}
        </Detail>
        <Detail>
          <DetailLabel>{operationLabels.operationDate} date: </DetailLabel>
          {formattedDate ? formattedDate : emptyVal}
        </Detail>
      </>
    )
  );
};
