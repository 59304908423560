import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import { TextField, Button } from '../../../../../../components/library';
import { FormikPartsState } from '../../../_shared/types';
import { PartSources, PartStatuses, PartIdentifierTypes } from '../../../_shared/types';
import { CircledXIcon } from '../../../CircledXIcon';
import { useEditablePartsTableStyles } from '../styled';
import { entries } from 'lodash';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { isPlaceholderId, getPlaceholderId } from '../../../_shared';
import { FailedPointOption } from './types';
import { useKeyPress } from '../../../../../../utils/hooks/useKeyPress';
import { useWarnBeforeUnload } from '../useWarnBeforeUnload';

interface EditablePartsTableProps {
  pointTypeCategoryLookup: { [key: number]: string };
  failedReplacePointOptions: FailedPointOption[];
  handleDataChange(dirty: boolean): void;
}

const coreStatusOptions = [
  { label: 'With Bike', value: PartStatuses['with bike'] },
  { label: 'Installed', value: PartStatuses.installed },
];
const statusOptions = (partsOrder: boolean) => {
  return partsOrder
    ? [
        ...coreStatusOptions,
        { label: 'Ordered', value: PartStatuses.ordered },
        { label: 'Received', value: PartStatuses.received },
        { label: 'Canceled', value: PartStatuses.canceled },
      ]
    : coreStatusOptions;
};

export const EditablePartsTable: React.FC<EditablePartsTableProps> = ({
  failedReplacePointOptions,
  pointTypeCategoryLookup,
  handleDataChange,
}) => {
  const { values, setFieldValue, errors, dirty } = useFormikContext<FormikPartsState>();
  const classes = useEditablePartsTableStyles();
  useWarnBeforeUnload(dirty);
  handleDataChange(dirty);

  const handleRemoval = (id: number) => {
    if (isPlaceholderId(id)) {
      // hasn't been saved yet, delete object from state entirely
      setFieldValue(`${id}`, undefined);
    } else {
      // has already been saved, slate for soft delete by BE
      setFieldValue(`${id}.active`, false);
    }
  };
  const handleAddRow = () => {
    const newId = getPlaceholderId();
    setFieldValue(`${newId}`, { id: newId, active: true });
  };

  useKeyPress({ modifierKeys: ['shift'], activationKey: 'enter' }, handleAddRow);

  const headerCellClass = `${classes.thCell} ${classes.leftAlignedCell}`;
  return (
    <>
      <TableContainer>
        <Table className={classes.table} aria-label="inspections-table">
          <TableHead className={classes.th}>
            <TableRow className={classes.row}>
              <TableCell className={headerCellClass}>Inspection Category</TableCell>
              <TableCell className={headerCellClass}>Inspection Point</TableCell>
              <TableCell className={headerCellClass}>Source</TableCell>
              <TableCell className={headerCellClass}>Identifier Type</TableCell>
              <TableCell className={headerCellClass}>Identifier</TableCell>
              <TableCell className={headerCellClass}>QTY</TableCell>
              <TableCell className={headerCellClass}>Status</TableCell>
              <TableCell className={headerCellClass}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries(values).map(([id, part]) => {
              if (!part.active) return null;

              const isPartInstalled = part?.statusId === PartStatuses.installed;
              const hasReplaceableFailedPoints = failedReplacePointOptions.length > 0;

              return (
                <TableRow key={id} className={classes.row}>
                  {/* Inspection Category */}
                  <TableCell scope="row" className={classes.categoryCell}>
                    {part?.inspectionPointTypeId
                      ? pointTypeCategoryLookup[part.inspectionPointTypeId]
                      : '--'}
                  </TableCell>

                  {/* Inspection Point */}
                  <TableCell scope="row">
                    <TextField
                      classes={{ root: classes.fixedWidth }}
                      size="small"
                      id={`parts-inspectionPointTypeId-${id}`}
                      value={part?.inspectionPointTypeId ?? ''}
                      onChange={e => setFieldValue(`${id}.inspectionPointTypeId`, e.target.value)}
                      variant="outlined"
                      options={failedReplacePointOptions}
                      error={!hasReplaceableFailedPoints || !!errors[id]?.sourceId}
                      disabled={!hasReplaceableFailedPoints}
                      helperText={
                        hasReplaceableFailedPoints
                          ? null
                          : "There are no failed points with a remedy of 'Replace'."
                      }
                    />
                  </TableCell>

                  {/* Inspection Source */}
                  <TableCell scope="row">
                    <TextField
                      classes={{ root: classes.fixedWidth }}
                      size="small"
                      id={`parts-sourceId-${id}`}
                      value={part?.sourceId ?? ''}
                      onChange={e => setFieldValue(`${id}.sourceId`, e.target.value)}
                      variant="outlined"
                      options={[
                        { label: 'Stock', value: PartSources.stock },
                        { label: 'Bike', value: PartSources.bike },
                        { label: 'Order', value: PartSources.order },
                        { label: 'Non-Inventory', value: PartSources['non-inventory'] },
                      ]}
                      error={!!errors[id]?.sourceId}
                    />
                    {part?.sourceId === PartSources.bike && (
                      <TextField
                        classes={{ root: classes.fixedWidth }}
                        size="small"
                        id={`parts-sourceBikeSku-${id}`}
                        value={part?.sourceBikeSku ?? ''}
                        onChange={e => setFieldValue(`${id}.sourceBikeSku`, e.target.value)}
                        placeholder="Bike SKU"
                        variant="outlined"
                        error={!!errors[id]?.sourceBikeSku}
                      />
                    )}
                    {part?.sourceId === PartSources.order && (
                      <>
                        <TextField
                          classes={{ root: classes.fixedWidth }}
                          size="small"
                          id={`parts-sourceOrderNumber-${id}`}
                          value={part?.sourceOrderNumber ?? ''}
                          onChange={e => setFieldValue(`${id}.sourceOrderNumber`, e.target.value)}
                          placeholder="PO Number"
                          variant="outlined"
                          error={!!errors[id]?.sourceOrderNumber}
                        />
                        <TextField
                          classes={{ root: classes.fixedWidth }}
                          size="small"
                          id={`parts-sourceOrderDescription-${id}`}
                          value={part?.sourceOrderDescription ?? ''}
                          onChange={e =>
                            setFieldValue(`${id}.sourceOrderDescription`, e.target.value)
                          }
                          placeholder="Item Description"
                          variant="outlined"
                          error={!!errors[id]?.sourceOrderDescription}
                        />
                        <TextField
                          classes={{ root: classes.fixedWidth }}
                          size="small"
                          id={`parts-sourceOrderCost-${id}`}
                          value={part?.sourceOrderCost ?? ''}
                          onChange={e => setFieldValue(`${id}.sourceOrderCost`, e.target.value)}
                          placeholder="Cost"
                          variant="outlined"
                          type="number"
                          InputProps={{
                            startAdornment: <span style={{ paddingRight: '1rem' }}>$</span>,
                          }}
                          error={!!errors[id]?.sourceOrderCost}
                        />
                      </>
                    )}
                  </TableCell>

                  {/* Identifier Type */}
                  <TableCell scope="row">
                    <TextField
                      classes={{ root: classes.fixedWidth }}
                      size="small"
                      id={`parts-id-type-${id}`}
                      value={part?.identifierTypeId ?? ''}
                      onChange={e => setFieldValue(`${id}.identifierTypeId`, e.target.value)}
                      variant="outlined"
                      options={[
                        { label: 'UPC', value: PartIdentifierTypes.UPC },
                        { label: 'TPC SKU', value: PartIdentifierTypes.SKU },
                        { label: 'Manual', value: PartIdentifierTypes.manual },
                      ]}
                      error={!!errors[id]?.identifierTypeId}
                    />
                  </TableCell>

                  {/* Identifier */}
                  <TableCell scope="row">
                    <TextField
                      classes={{ root: classes.fixedWidth }}
                      size="small"
                      id={`parts-identifier-${id}`}
                      value={part?.identifier ?? ''}
                      onChange={e => setFieldValue(`${id}.identifier`, e.target.value)}
                      variant="outlined"
                      error={!!errors[id]?.identifier}
                    />
                  </TableCell>

                  {/* QTY */}
                  <TableCell scope="row">
                    <TextField
                      classes={{ root: classes.fixedWidth }}
                      size="small"
                      id={`parts-quantity-${id}`}
                      value={part?.quantity ?? ''}
                      onChange={e => setFieldValue(`${id}.quantity`, parseInt(e.target.value, 10))}
                      variant="outlined"
                      type="number"
                      error={!!errors[id]?.quantity}
                    />
                  </TableCell>

                  {/* Status */}
                  <TableCell scope="row">
                    <TextField
                      classes={{ root: classes.fixedWidth }}
                      size="small"
                      id={`parts-statusId-${id}`}
                      value={part?.statusId ?? ''}
                      onChange={e => setFieldValue(`${id}.statusId`, e.target.value)}
                      variant="outlined"
                      options={statusOptions(part?.sourceId === PartSources.order)}
                      error={!!errors[id]?.statusId}
                    />
                  </TableCell>

                  {/* Action */}
                  <TableCell
                    scope="row"
                    onClick={isPartInstalled ? undefined : () => handleRemoval(part.id)}
                    className={isPartInstalled ? undefined : classes.clickable}
                  >
                    <div className={classes.actionCell}>
                      {!isPartInstalled && <CircledXIcon className={classes.removeButton} />}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <Button ordinality="primary" variant="text" onClick={handleAddRow}>
          <>
            <AddCircleOutlineIcon color="primary" /> &ensp; Add Part
          </>
        </Button>
      </TableContainer>
    </>
  );
};
