import React, { useContext } from 'react';
import { pageStateContext } from '../../../../../_shared';
import axios from '../../../../../../utils/axios';
import { UseInspection } from '../../../_shared';
import { API_URL } from '../../../../../../constants';
import { InspectionStatusEnum } from '../../../../../../services/service/types';
import { Button } from '../../../../../../components/library';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

export interface RemoveRejectionModalProps {
  inspectionData: UseInspection;
  setRemoveRejectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  removeRejectionModalOpen: boolean;
}

export const RemoveRejectionModal = (props: RemoveRejectionModalProps) => {
  const { inspectionData, removeRejectionModalOpen, setRemoveRejectionModalOpen } = props;

  const { inspection, refreshInspectionData } = inspectionData;

  const {
    errorBus: { setError },
    loadingBus: { setLoading },
  } = useContext(pageStateContext);

  const handleRemoveRejection = async () => {
    const payload = {
      statusId: InspectionStatusEnum['Ready for Intake'],
      servicePlanRejectionReasons: [],
      rejectionNotes: '',
    };
    if (inspection) {
      try {
        setLoading('removeRejection', true);
        await axios.put(`${API_URL}/service/inspections/${inspection.id}`, payload);
        setRemoveRejectionModalOpen(false);
        refreshInspectionData();
      } catch (err) {
        setError('handleRejectSave', err as Error);
      } finally {
        setLoading('removeRejection', false);
      }
    }
  };

  return (
    <Dialog onClose={() => setRemoveRejectionModalOpen(false)} open={removeRejectionModalOpen}>
      <DialogTitle> Remove item from Rejection status?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure that you want to remove this item from Rejection status? Rejection data will
          be removed and the status will be updated to Ready for Intake.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setRemoveRejectionModalOpen(false)}
          ordinality="secondary"
          variant="text"
        >
          Cancel
        </Button>
        <Button onClick={handleRemoveRejection} ordinality="secondary">
          Remove Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};
