import React from 'react';
import PropTypes from 'prop-types';
const SellerInfo = ({ submissionId, sellerInfo: { name, email, phone, address, expiration } }) => (
  <fieldset className="list-wrap">
    <legend>Seller Info</legend>
    <div className="form-wrap">
      <span className="input-label-wrap-status offer-display underline">
        <label className="label">SUBMISSION ID:</label>
        <p>{submissionId}</p>
      </span>
      <span className="input-label-wrap-status doo-display underline">
        <label className="label doo-label">NAME:</label>
        <p>{name}</p>
      </span>
    </div>
    <div className="form-wrap">
      <span className="input-label-wrap-status offer-display underline">
        <label className="label">EMAIL:</label>
        <p>{email}</p>
      </span>
      <span className="input-label-wrap-status doo-display underline">
        <label className="label doo-label">PHONE:</label>
        <p>{phone}</p>
      </span>
    </div>
    <span className="input-label-wrap-status underline">
      <label className="label">ADDRESS:</label>
      <p>{address}</p>
    </span>
  </fieldset>
);

SellerInfo.propTypes = {
  submissionId: PropTypes.string.isRequired,
  sellerInfo: PropTypes.shape().isRequired,
};

export default SellerInfo;
