import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import '../styles/home.css';
import tradeupLogo from '../images/trade-up.svg';

import { INSPECTION_SEARCH_ROUTE } from './service/serviceTicket/InspectionSearchView';
import { TRADEUP_SEARCH_ROUTE } from './tradeups/SubmissionListView';

const Home = () => {
  return (
    <>
      <div className="home-wrap">
        <Link className="home-link" to="/products/360view">
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-circle"
            >
              <circle cx="12" cy="12" r="10" />
            </svg>
            <h4>360 View</h4>
            <span>View lifespan and activity of products</span>
          </section>
        </Link>
        <Link className="home-link" to={INSPECTION_SEARCH_ROUTE}>
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-settings"
            >
              <circle cx="12" cy="12" r="3" />
              <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
            </svg>
            <h4>Mechanics</h4>
            <span>Scan, repair and service bikes</span>
          </section>
        </Link>
        <Link className="home-link" to="/catalog/products">
          <section className="section-wrap">
            <PageviewOutlinedIcon style={{ height: '116px', width: '116px' }} />
            <h4>Catalog</h4>
            <span>Search Product Database</span>
          </section>
        </Link>
        <Link className="home-link" to="/catalog/upc">
          <section className="section-wrap">
            <ConfirmationNumberOutlinedIcon style={{ height: '116px', width: '116px' }} />
            <h4>UPC Search</h4>
            <span>Search + Perform tasks via UPC</span>
          </section>
        </Link>
        <Link className="home-link" to="/service/relocate">
          <section className="section-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-right"
            >
              <line x1="5" y1="12" x2="19" y2="12" />
              <polyline points="12 5 19 12 12 19" />
            </svg>
            <h4>Relocate</h4>
            <span>Relocate snowflake SKUs</span>
          </section>
        </Link>
        <Link className="home-link" to="/service/multirelocate">
          <section className="section-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-move"
            >
              <polyline points="5 9 2 12 5 15"></polyline>
              <polyline points="9 5 12 2 15 5"></polyline>
              <polyline points="15 19 12 22 9 19"></polyline>
              <polyline points="19 9 22 12 19 15"></polyline>
              <line x1="2" y1="12" x2="22" y2="12"></line>
              <line x1="12" y1="2" x2="12" y2="22"></line>
            </svg>
            <h4>Multi Relocate</h4>
            <span>Relocate multiple SKUs</span>
          </section>
        </Link>
        <Link className="home-link" to={INSPECTION_SEARCH_ROUTE}>
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-eye"
            >
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
              <circle cx="12" cy="12" r="3" />
            </svg>
            <h4>Service Plans</h4>
            <span>Service, grade and log bikes</span>
          </section>
        </Link>
        <Link className="home-link" to="/buyers">
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-dollar-sign"
            >
              <line x1="12" y1="1" x2="12" y2="23" />
              <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
            </svg>{' '}
            <h4>Buyers</h4>
            <span>Buyers landing for adding customers</span>
          </section>
        </Link>
        <Link className="home-link" to="/catalog/product-creation">
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-plus-square"
            >
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
              <line x1="12" y1="8" x2="12" y2="16" />
              <line x1="8" y1="12" x2="16" y2="12" />
            </svg>
            <h4>Add Product</h4>
            <span>View, add and SKU new products</span>
          </section>
        </Link>
        <Link className="home-link" to="/cpoVideo">
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-youtube"
            >
              <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />
              <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" />
            </svg>
            <h4>Add CPO Video</h4>
            <span>Add CPO video to respective CPO bike.</span>
          </section>
        </Link>
        <Link className="home-link" to="/marketing/updateFilters">
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-filter"
            >
              <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
            </svg>
            <h4>RAD-Filter™ Updater</h4>
            <span>Update filter categories on Shopify</span>
          </section>
        </Link>
        <Link className="home-link" to="/marketing/updateBlogs">
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-file-text"
            >
              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" y1="13" x2="8" y2="13" />
              <line x1="16" y1="17" x2="8" y2="17" />
              <polyline points="10 9 9 9 8 9" />
            </svg>
            <h4>Blogs</h4>
            <span>Update where blogs display</span>
          </section>
        </Link>
        <Link className="home-link" to={TRADEUP_SEARCH_ROUTE}>
          <section className="section-wrap">
            <img src={tradeupLogo} alt="logo" />
            <h4>Trade-Ups</h4>
            <span>One stop shop for all your tradeups</span>
          </section>
        </Link>
        <Link className="home-link" to="/netsuite/locateInventory">
          <section className="section-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-archive"
            >
              <polyline points="21 8 21 21 3 21 3 8"></polyline>
              <rect x="1" y="3" width="22" height="5"></rect>
              <line x1="10" y1="12" x2="14" y2="12"></line>
            </svg>
            <h4>Inventory Locator</h4>
            <span>Locate Inventory</span>
          </section>
        </Link>
        <Link className="home-link" to="/sops">
          <section className="section-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-book-open"
            >
              <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
              <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
            </svg>{' '}
            <h4>SOPs</h4>
            <span>View & add SOPS for all departments</span>
          </section>
        </Link>
        <Link className="home-link" to="/account/home">
          <section className="section-wrap">
            <svg
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-lock"
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
            <h4>Account</h4>
            <span>Change your password</span>
          </section>
        </Link>
        <Link className="home-link" to="/inventoryAdjustments">
          <section className="section-wrap">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="116"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-list"
            >
              <line x1="8" y1="6" x2="21" y2="6" />
              <line x1="8" y1="12" x2="21" y2="12" />
              <line x1="8" y1="18" x2="21" y2="18" />
              <line x1="3" y1="6" x2="3.01" y2="6" />
              <line x1="3" y1="12" x2="3.01" y2="12" />
              <line x1="3" y1="18" x2="3.01" y2="18" />
            </svg>
            <h4>Inventory Adjustments</h4>
            <span>Adjust Netsuite Inventory</span>
          </section>
        </Link>
      </div>
    </>
  );
};

export default Home;
