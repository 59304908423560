import { createTheme, Theme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    orange: PaletteColor;
  }
  interface PaletteOptions {
    orange: PaletteColorOptions;
  }
  interface PaletteColor {
    background?: string;
    vibrant?: string;
  }
  interface SimplePaletteColorOptions {
    background?: string;
    vibrant?: string;
  }
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    transparentize: (color: string, pct: number) => string;
  }
  interface ThemeOptions {
    transparentize?: (color: string, pct: number) => string;
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    regular: string;
    bold: string;
    condensed: string;
  }
  interface TypographyOptions {
    regular: string;
    bold: string;
    condensed: string;
  }
}

// allows ${} substitutions in styled`` declarations to infer MUI theme
declare module 'styled-components' {
  /* eslint-ignore @typescript-eslint/no-empty-interface */
  // eslint-disable-next-line
  export interface DefaultTheme extends Theme {}
}

const spacingValue = 8; // default 8px Material spacing
const white = '#FFFFFF';
const tpcGreen = '#00c160';
const black = '#000000';
export const materialTheme: Theme = createTheme({
  palette: {
    primary: {
      light: '#e7fbf1',
      main: tpcGreen,
      dark: '#009320',
      contrastText: white,
    },
    secondary: {
      light: grey[700],
      main: grey[900],
      dark: black,
      contrastText: white,
    },
    common: {
      black: black,
      white: white,
    },
    error: {
      background: '#feecea',
      light: '#ffd5d1',
      main: '#d80202',
      vibrant: '#f44236',
    },
    info: {
      light: '#c9e1f2',
      main: '#0163b0',
    },
    warning: {
      light: '#f8ebc9',
      main: '#a47100',
    },
    success: {
      background: '#edf6ed',
      light: '#c2f5de',
      main: '#01741e',
      vibrant: tpcGreen,
    },
    orange: {
      main: '#FC631C',
      background: '#ffedd5',
      light: '#ffedd5',
      dark: '#c2410c',
      contrastText: white,
    },
  },
  overrides: {
    MuiButton: {
      label: {
        fontSize: 'inherit',
      },
    },
    MuiTab: {
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: white,
        '&:hover': {
          backgroundColor: white,
        },
        '&$focused': {
          backgroundColor: white,
        },
        '&$disabled': {
          backgroundColor: white,
        },
      },
    },
    MuiInputBase: {
      input: {
        background: white,
      },
      adornedStart: {
        background: white,
      },
      adornedEnd: {
        background: white,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: white,
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '.75rem',
      },
    },
  },
  spacing: spacingValue,

  typography: {
    // duplicating `regular` as fontFamily here defaults all MUI components to use that value
    fontFamily: "'GT America Regular', sans-serif",
    // for use in components
    regular: "'GT America Regular', sans-serif",
    bold: "'GT America Bold', sans-serif",
    condensed: "'GT America Condensed Bold', sans-serif",
  },
});
