import React, { ReactNode } from 'react';
import { FormikErrors } from 'formik';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import { Button, Modal, SmallModalContents, ModalBody } from '../../../../components/library';
import { Typography } from '@material-ui/core';

const ErrorHeader = styled.div`
  font-size: 30px;
  margin-bottom: 1rem;
`;
const ErrorDescription = withTheme(styled.p`
  color: ${({ theme }) => theme.palette.error.main};
`);
const BoldPointType = styled.span`
  font-weight: bold;
`;

interface ValidationErrorModalProps {
  open: boolean;
  handleClose: () => any;
  errors: FormikErrors<{
    [key: string]: {
      [key: string]: string;
    };
  }>;
  optionalDetails?: ReactNode;
}

export const ValidationErrorModal: React.FC<ValidationErrorModalProps> = ({
  open,
  handleClose,
  errors,
  optionalDetails,
}) => {
  const parsedErrors = Object.values(errors).flatMap(errorObj =>
    errorObj ? Object.values(errorObj) : [],
  ) as string[];

  return (
    <Modal open={open} handleClose={handleClose} label="confirm-close-modal">
      <SmallModalContents>
        <ErrorHeader>Error - missing data</ErrorHeader>
        <ErrorDescription>The following items need to be completed:</ErrorDescription>
        <ModalBody>
          <ol>
            {parsedErrors.map((message: string) => {
              const [fieldName, pointType] = message.split('|');
              return fieldName && pointType ? (
                <li key={message}>
                  {fieldName} for <BoldPointType>{pointType}</BoldPointType>
                </li>
              ) : (
                <li key={message}>{fieldName}</li>
              );
            })}
          </ol>
          <Typography color="textSecondary" variant="body2">
            {optionalDetails}
          </Typography>
          <Button size="large" ordinality="secondary" onClick={handleClose}>
            close
          </Button>
        </ModalBody>
      </SmallModalContents>
    </Modal>
  );
};
