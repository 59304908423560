import { InspectionsSearchState } from './inspectionsSearchReducer';
import { CatalogProductState } from './product-reducer';

export enum Permissions {
  GENERAL = 1,
  INSPECTION_CREATE = 2,
  INSPECTION_EDIT = 3,
  ADMIN_USERS = 4,
  FINANCE_USERS = 7,
  CATALOG_UPDATE_PRICING = 14,
  CATALOG_UPDATE_IMAGES = 16,
  CATALOG_CHANGE_CATEGORY = 17,
}

type UserPermissions = {
  id: Permissions;
  name: string;
};

export type AuthState = {
  user?: {
    active: boolean;
    id: number;
    email: string;
    name: string;
    permissions: UserPermissions[];
  };
};

export type NavState = {
  navExpanded: boolean;
};

export type GlobalState = {
  product: CatalogProductState;
  auth: AuthState;
  nav: NavState;
  inspectionsSearch: InspectionsSearchState;
};
