import { useCallback, useEffect } from 'react';

export const useWarnBeforeUnload = (shouldWarn: boolean) => {
  const handler = useCallback(
    (e: BeforeUnloadEvent) => {
      if (shouldWarn) {
        console.warn('beforeunload event triggered - prompting the user to save changes first');
        e.returnValue = false;
        return false;
      }
      return true;
    },
    [shouldWarn],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, [handler]);
};
