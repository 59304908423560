import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/modal.scss';

export default class DisciplineModal extends React.Component {
  UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this._handleEscKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleEscKey, false);
  }

  _handleEscKey = e => {
    if (e.keyCode === 27) {
      this.props.toggleModal();
    }
  };

  render() {
    const { toggleModal, onSubmit, onChange, discipline, tag, error, loading } = this.props;
    return (
      <div className="backdrop offer-decline-backdrop">
        <div className="message offer-decline-message">
          <span className="input-label-wrapper">
            <form className="offer-decline-form" onSubmit={onSubmit}>
              <span className="input-label-wrapper">
                <label className="label">First Name:</label>
                <input
                  className="text-input"
                  placeholder="Discipline"
                  type="text"
                  name="discipline"
                  value={discipline}
                  onChange={onChange}
                />
              </span>
              <span className="input-label-wrapper">
                <label className="label">Tag:</label>
                <input
                  className="text-input"
                  placeholder="Tag"
                  type="text"
                  name="tag"
                  value={tag}
                  onChange={onChange}
                />
              </span>
              <input
                type="submit"
                name="save_complete"
                value="ADD"
                className="submit-button"
                disabled={loading}
              />
              {error && <span>Uh oh, something went wrong</span>}
            </form>
          </span>
          <button className="close-modal-button" onClick={toggleModal} disabled={loading} />
        </div>
      </div>
    );
  }
}

DisciplineModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  discipline: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
};
