import * as types from './types';
import * as service from '../services/catalog/services';
import * as actions from './index';
import { batch } from 'react-redux';
import { handleApiError } from '../pages/catalog/utils';

export const setProductCategories = payload => ({
  type: types.SET_CATALOG_PRODUCT_CATEGORIES,
  payload,
});

export const setProductPipelines = payload => ({
  type: types.SET_CATALOG_PRODUCT_PIPELINES,
  payload,
});

export const setProductStatusFlags = payload => ({
  type: types.SET_CATALOG_PRODUCT_STATUS_FLAGS,
  payload,
});

export const setAutosuggestFields = payload => ({
  type: types.SET_CATALOG_AUTOSUGGEST_FIELDS,
  payload,
});

export const setProductBrands = payload => ({
  type: types.SET_CATALOG_PRODUCT_BRANDS,
  payload,
});

export const setProductDisciplines = payload => ({
  type: types.SET_CATALOG_PRODUCT_DISCIPLINES,
  payload,
});

export const setProductSizeClasses = payload => ({
  type: types.SET_CATALOG_PRODUCT_SIZE_CLASSES,
  payload,
});

export const setProductSubCategories = payload => ({
  type: types.SET_CATALOG_PRODUCT_SUB_CATEGORIES,
  payload,
});

export const setProductImages = payload => ({
  type: types.SET_CATALOG_PRODUCT_IMAGES,
  payload,
});

export const setProductImagesError = payload => ({
  type: types.SET_CATALOG_PRODUCT_IMAGES_ERROR,
  payload,
});

export const setProductImagesLoading = payload => ({
  type: types.SET_CATALOG_PRODUCT_IMAGES_LOADING,
  payload,
});

export const setAttributeTypes = payload => ({
  type: types.SET_CATALOG_ATTRIBUTE_TYPES,
  payload,
});

export const setInitialItemCatalogData = payload => ({
  type: types.SET_INITIAL_ITEM_CATALOG_DATA,
  payload,
});

const _handleProductImagesError = (err, dispatch) => {
  handleApiError(err, errorMsg => {
    batch(() => {
      dispatch(actions.setProductImagesLoading(false));
      dispatch(actions.setProductImagesError(errorMsg));
    });
  });
};

export const fetchProductImages = productId => async dispatch => {
  batch(() => {
    dispatch(actions.setProductImagesLoading(true));
    dispatch(actions.setProductImagesError(undefined));
  });
  await service.fetchProductImages(productId, {
    onSuccess: response => {
      batch(() => {
        dispatch(actions.setProductImagesLoading(false));
        dispatch(actions.setProductImages(response));
      });
    },
    catchFailure: err => {
      batch(() => {
        dispatch(actions.setProductImagesLoading(false));
        dispatch(actions.setProductImagesError(true));
      });
    },
  });
};

export const uploadProductImages = payload => async dispatch => {
  const { images, productId, onSuccess } = payload;
  let s3ObjectUrls = [];

  batch(() => {
    dispatch(actions.setProductImagesLoading(true));
    dispatch(actions.setProductImagesError(undefined));
  });

  try {
    // 1) get presigned URLs for each file from Drivetrain
    const fileNames = images.map(f => f.name);
    const res = await service.getImageUploadSignedUrls(fileNames);

    // 2) PUT each file to its corresponding signedURL in S3
    if (res && res?.data) {
      const uploadPromises = images.map((img, idx) => {
        return service.uploadImageToS3(res.data[idx]?.signedUrl, img.file);
      });
      await Promise.all(uploadPromises);
      s3ObjectUrls = res.data.map(o => o.s3Url);
    }
  } catch (err) {
    return _handleProductImagesError(err, dispatch);
  }

  // 3) create DT records using the object URLs from step 1
  if (s3ObjectUrls.length) {
    try {
      let payload = {};
      if (images[0].productVariantId) {
        payload.variantImages = s3ObjectUrls.map((u, idx) => ({
          imageUrl: u,
          productVariantId: images[idx].productVariantId,
        }));
      } else {
        payload.productImages = s3ObjectUrls.map(imageUrl => ({ imageUrl }));
      }
      await service.createProductImages(productId, payload);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      return _handleProductImagesError(err, dispatch);
    }
    // 4) everything was successful - refetch
    dispatch(actions.fetchProductImages(productId));
  }
};

export const updateProductImages = payload => async dispatch => {
  const { productImages, variantImages, productId, onSuccess } = payload;
  batch(() => {
    dispatch(actions.setProductImagesLoading(true));
    dispatch(actions.setProductImagesError(undefined));
  });
  try {
    await service.updateProductImages(productId, { productImages, variantImages });
    dispatch(actions.fetchProductImages(productId));
    if (onSuccess) {
      onSuccess();
    }
  } catch (err) {
    return _handleProductImagesError(err, dispatch);
  }
};

export const setFrameDimensions = payload => ({
  type: types.SET_CATALOG_FRAME_DIMENSIONS,
  payload,
});

export const setCatalogConfiguration = payload => ({
  type: types.SET_CATALOG_CONFIGURATION,
  payload,
});

export const getCatalogConfig = payload => dispatch => {
  service.fetchCatalogConfiguration({
    onSuccess: response => {
      dispatch(actions.setCatalogConfiguration(response));
    },
  });
};

export const getAutosuggestFields = (payload = {}) => dispatch => {
  service.fetchAutosuggestFields(payload.categoryId, {
    onSuccess: response => {
      dispatch(actions.setAutosuggestFields(response));
    },
  });
};
