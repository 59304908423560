import React, { FC, useEffect, useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, InputAdornment, IconButton, OutlinedInput } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import * as actions from '../../actions';
import ErrorBoundary from '../../components/ErrorBoundary';
import Filters from './searchProducts/Filters';
import SearchResults from './searchProducts/SearchResults';
import {
  CatalogSearchProductsState,
  FilterParamKeys,
} from '../../reducers/catalogSearchProductsReducer';
import { GlobalState } from '../../reducers/types';
import { useKeyPress } from '../../utils/hooks/useKeyPress';

const Root = styled.div`
  margin: 2rem;
`;

const ItemCatalogSearchProducts: FC = () => {
  const dispatch = useDispatch();
  const { brands, categories, pipelines, statusFlags } = useSelector(
    (state: GlobalState) => state.product,
  );
  const {
    params: {
      query,
      brandId,
      pipelineId,
      productCategoryId,
      statusFlagId,
      hasImages,
      year,
      shopifyStatus,
      limit,
      offset,
    },
    results,
    loading,
    total,
  } = useSelector(
    (state: { catalogSearchProducts: CatalogSearchProductsState }) => state.catalogSearchProducts,
  );
  const [searchQuery, setSearchQuery] = useState(query);

  useEffect(() => {
    dispatch(actions.getInitialItemCatalogDataOneDispatch());
    dispatch(actions.searchCatalogProducts());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performSearch = useCallback(() => {
    if (searchQuery) {
      dispatch(actions.searchCatalogProducts({ query: searchQuery }));
    } else {
      dispatch(actions.searchCatalogProducts({ clearQuery: true }));
    }
  }, [dispatch, searchQuery]);

  const onSubmit = (e: React.FormEvent<EventTarget>): void => {
    e.preventDefault();
    performSearch();
  };
  const searchRef = React.useRef<HTMLInputElement>(null);

  const clearSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setSearchQuery('');
    dispatch(actions.searchCatalogProducts({ clearQuery: true }));
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };

  useKeyPress({ activationKey: 'escape' }, clearSearch);

  const updateFilterAndSearch = (
    param: FilterParamKeys,
    value: string | number | boolean | null,
  ) => {
    dispatch(actions.updateCatalogProductsSearchFilters(param, value));
  };

  const onClearAll = () => {
    dispatch(actions.clearCatalogProductSearchParams());
    dispatch(actions.searchCatalogProducts({ clearQuery: true }));
  };

  const onChangePage = useCallback(
    (page: number) => {
      const newOffset = page * limit;
      dispatch(actions.searchCatalogProducts({ offset: newOffset }));
    },
    [dispatch, limit],
  );

  const currentPage = offset / limit;

  return (
    <Root>
      <ErrorBoundary>
        <Grid container spacing={5}>
          <Grid
            item
            container
            xs={7}
            spacing={2}
            alignItems="center"
            justifyContent="center"
            component="form"
            onSubmit={onSubmit}
          >
            <Grid item xs={7}>
              <OutlinedInput
                id="productVariantSearchBox"
                value={searchQuery}
                placeholder="Search by SKU or Title"
                onChange={e => setSearchQuery(e.target.value)}
                style={{ backgroundColor: '#fff' }}
                autoFocus
                fullWidth
                autoComplete="off"
                inputRef={searchRef}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={clearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={5}>
              <Button variant="contained" size="large" type="submit">
                Search
              </Button>
            </Grid>
          </Grid>
          <Grid item container xs={5} alignItems="center">
            <Button
              to="/catalog/product-creation"
              variant="contained"
              size="large"
              fullWidth={true}
              component={RouterLink}
            >
              Add New
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Filters
              brandId={brandId}
              pipelineId={pipelineId}
              productCategoryId={productCategoryId}
              statusFlagId={statusFlagId}
              year={year}
              brands={brands}
              pipelines={pipelines}
              categories={categories}
              statusFlags={statusFlags}
              hasImages={hasImages}
              shopifyStatus={shopifyStatus}
              onFilterChange={updateFilterAndSearch}
              onClearAll={onClearAll}
            />
          </Grid>
          <Grid item xs={10}>
            <SearchResults
              loading={loading}
              results={results}
              pipelines={pipelines}
              total={total}
              pageSize={limit}
              page={currentPage}
              onChangePage={onChangePage}
            />
          </Grid>
        </Grid>
      </ErrorBoundary>
    </Root>
  );
};

export default ItemCatalogSearchProducts;
