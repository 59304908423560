import { createContext, Context } from 'react';
import { UseInspection, UseInspectionStatuses } from './hooks';

class ServiceTicketContext {
  inspectionData: UseInspection;
  inspectionStatuses: UseInspectionStatuses['activeStatuses'];

  constructor(init?: Partial<ServiceTicketContext>) {
    this.inspectionData = init?.inspectionData ?? new UseInspection();
    this.inspectionStatuses = init?.inspectionStatuses ?? [];
  }
}

export const serviceTicketContext: Context<ServiceTicketContext> = createContext(
  new ServiceTicketContext(),
);
