import React from 'react';

const User = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5.5C3.36.5 0 3.86 0 8c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5C15 3.86 11.64.5 7.5.5zm0 2.25c1.245 0 2.25 1.005 2.25 2.25S8.745 7.25 7.5 7.25 5.25 6.245 5.25 5 6.255 2.75 7.5 2.75zm0 10.65A5.3998 5.3998 0 013 10.985c.0225-1.4925 3-2.31 4.5-2.31 1.4925 0 4.4775.8175 4.5 2.31A5.4001 5.4001 0 017.5 13.4z"
      fill="#B1B1B1"
    />
  </svg>
);

export default User;
