import { GET_SERVICE_STATION } from '../actions/types';

export default (state = '', action) => {
  switch (action.type) {
    case GET_SERVICE_STATION: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
