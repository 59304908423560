import React from 'react';
import { default as Chip, ChipProps } from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core';

export type StatusOption = 'primary' | 'info' | 'warning' | 'error' | 'success' | 'orange';

const useStyles = makeStyles(theme => ({
  root: {
    background: ({ status }: { status: StatusOption }) =>
      status === 'primary' ? theme.palette.common.white : theme.palette?.[status]?.light,
    color: ({ status }: { status: StatusOption }) =>
      status === 'primary' ? theme.palette.common.black : theme.palette?.[status]?.dark,
    textTransform: 'uppercase',
    width: theme.spacing(20),
    borderWidth: 2,
    fontWeight: 600,
  },
}));

export interface CustomStatusBadgeProps {
  status: StatusOption;
}

export type StatusBadgeProps = ChipProps & CustomStatusBadgeProps;

export const StatusBadge: React.FC<StatusBadgeProps> = ({ status, ...muiProps }) => {
  const styles = useStyles({ status });
  return (
    <Chip
      variant={status === 'primary' ? 'outlined' : undefined}
      classes={{
        root: styles.root,
      }}
      {...muiProps}
    />
  );
};
