// TODO: TP-2800 remove this Component
import React, { Component, Fragment } from 'react';
import { NotificationManager } from 'react-notifications';
import { RouteComponentProps } from 'react-router-dom';
import Loader from '../../../components/loader';
import axios from '../../../utils/axios';
import { API_URL } from '../../../constants';
import Timer from '../../../components/timer';
import './stationLanding.scss';

export const OLD_CYCLE_TIMING_URL = '/service/old-cycle-timing';

type StationStateValues = 'completed' | 'started' | 'paused' | null;

type StationState = {
  station: string;
  stations: [];
  loading: boolean;
  sku: string;
  inspectionId: string;
  statusReport: [];
  stationState: StationStateValues;
  title: string | null;
  duration: number | null;
  stationStart: Date | null;
};

const getStationAndSkuUrl = ({ station, sku }: StationState) => {
  return `${OLD_CYCLE_TIMING_URL}/${station}?sku=${sku}`;
};

/**
 * Cycle-timing page flow:
 * This component uses browser URL routing to share state and allows the user to use native back/forward functionality.
 *
 * 1. First page - /cycle-timing
 *   - Initial page with options to pick station
 *   - When user selects station we link/route them to "/cycle-timing/:station" which is this same component
 * 2. Second page - /cycle-timing/:station
 *   - page loads with a station selected but empty search box
 *   - user must search for a SKU to start the station timing
 *   - when user searches for a SKU the page links/routes to "/cycle-timing/:station?sku=SKU1234"
 * 3. Third page - /cycle-timing/:station?sku=SKU1234
 *   - page loads with a station and SKU
 *   - the passed in station and SKU are used to search for a valid inspection
 *     - if the SKU is not valid then we show an error
 *     - New SKU & station / not started
 *       - we start the station automatically
 *       - via POST /service/startStation
 *     - Existing SKU & station / already started
 *       - we display its current time and state(pause/running)
 */
class StationLanding extends Component<RouteComponentProps<{ station?: string }>, StationState> {
  columns: any;
  constructor(props: any) {
    super(props);
    const { station } = this.props.match.params;
    const skuParam = new URLSearchParams(location.search).get('sku');
    this.state = {
      station: station ?? '',
      stations: [],
      loading: true,
      sku: skuParam ? skuParam : '',
      inspectionId: '',
      statusReport: [],
      stationState: null,
      title: null,
      duration: 0,
      stationStart: null,
    };
  }

  removeHistoryListen: () => void = () => {
    return;
  };

  componentDidMount = () => {
    // Retrieve all stations from BE - A...Z
    this.fetchStations();

    this.handlePageLoadOrChange();

    // Setup "on tab/browser close" functionality to pause a running timer
    window.addEventListener('beforeunload', this.handleLeavePage);

    // Setup page route handling and changes and warnings
    this.removeHistoryListen = this.props.history.listen(this.handlePageHistoryChange);
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleLeavePage);
    /**
     * NOTE: This is wrapped in a timeout to put it on the end of the queue
     * This is because on browser back/forward the component is un-mounting
     * before the callback from the history listener is called.
     * - https://github.com/ReactTraining/history/issues/710#issuecomment-503407519
     */
    setTimeout(() => {
      this.removeHistoryListen();
    }, 0);
  }

  handlePageHistoryChange = (location: RouteComponentProps['history']['location']) => {
    const { inspectionId, stationState, station, sku } = this.state;

    // 1. Pause existing timing if it exists
    // Setup warning on page route/link change that we paused timer
    if (inspectionId && stationState !== 'paused' && stationState !== 'completed') {
      // We need to pause to keep accurate time
      this.pauseStation();
      NotificationManager.warning(`SKU: ${sku} Station: ${station}`, 'We paused your timer', 2000);
    }

    // 2. Load new station/sku
    // If route change is triggered within the same route we're currently on
    // perform changes as if page loaded because component is not re-mounted
    if (location.pathname.startsWith(`${OLD_CYCLE_TIMING_URL}/${station}`)) {
      this.handlePageLoadOrChange();
    }
  };

  handlePageLoadOrChange = () => {
    const { station } = this.props.match.params;
    const sku = new URLSearchParams(location.search).get('sku');
    if (station && sku) {
      this.setState(
        { station, sku, stationState: null, inspectionId: '', stationStart: null },
        () => this.fetchStationDataAndStartIfNew(),
      );
    } else {
      this.setState({ sku: '', stationState: null, inspectionId: '', stationStart: null });
    }
  };

  handleError = (errMsg: string) => {
    NotificationManager.error(errMsg, 'Error', 5000, undefined, true);
  };

  handleLeavePage = () => {
    const { stationState } = this.state;

    if (stationState !== 'paused' && stationState !== 'completed') {
      this.pauseStation();
    }
  };

  fetchStations = async () => {
    try {
      const url = `${API_URL}/service/stations`;
      const response = await axios.get(url);
      if (response) this.setState({ stations: response.data });
    } catch (err) {
      this.handleError(`There was an error fetching stations`);
    } finally {
      this.setState({ loading: false });
    }
  };

  fetchStationDataAndStartIfNew = async () => {
    this.setState({ loading: true });

    const { sku, station } = this.state;
    const url = `${API_URL}/service/stationData/`;

    try {
      const res = await axios.get(url, {
        params: {
          station,
          sku,
        },
      });
      if (res && res?.data) {
        this.setState({
          inspectionId: res.data.inspectionId,
          title: res.data.title,
          stationState: res.data.state.stationState,
          duration: res.data.state.duration,
          stationStart: res.data.state.stationStart,
        });
        // Check if fresh request for station Data and start timing
        this.startStationIfNew();
      }
    } catch (e) {
      this.handleError(`There was an error fetching station data for SKU ${sku}`);
    } finally {
      this.setState({ loading: false });
    }
  };

  submitFormSearchSkuForStation = async (e: any = null) => {
    if (e) e.preventDefault();

    this.setState({ stationState: null, inspectionId: '', stationStart: null });

    // Update the url with the sku as a query param, so user can come back
    // history.listen will react to history update - handlePageLoadOrChange
    this.props.history.push(getStationAndSkuUrl(this.state));
  };

  startStationIfNew = async () => {
    const { stationState, inspectionId, station, duration } = this.state;

    const url = `${API_URL}/service/startStation`;
    const body = { inspectionId, station };
    const isNewStationStart = duration === null && stationState === null;
    if (isNewStationStart) {
      try {
        await axios.post(url, body);
      } catch (err) {
        if (err.response) {
          NotificationManager.warning(JSON.stringify(err.response.data.message), 'Warning');
        }
        console.error(err);
      }
    }
  };

  pauseStation = (reloadState = false) => {
    this.pauseOrRestartStation(reloadState);
  };

  restartStation = () => {
    this.pauseOrRestartStation(true);
  };

  pauseOrRestartStation = async (reloadState: boolean) => {
    const { inspectionId, station } = this.state;

    const url = `${API_URL}/service/pauseStation`;
    const body = { inspectionId, station };
    if (inspectionId) {
      try {
        await axios.post(url, body);
        if (reloadState) {
          this.fetchStationDataAndStartIfNew();
        }
      } catch (err) {
        this.handleError(err.response.data.message);
      }
    }
  };

  endStation = async () => {
    try {
      this.setState({ loading: true });

      const { inspectionId, station } = this.state;

      const url = `${API_URL}/service/endStation`;
      const body = {
        inspectionId,
        station,
      };
      const response = await axios.post(url, body);

      NotificationManager.info(
        `Completed work for inspection #${this.state.inspectionId} at Station ${station}`,
        'Service Complete!',
      );
      if (response) {
        this.clearTimingForm();
      }
    } catch (err) {
      this.handleError(err.response.data.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  clearTimingForm = () => {
    // "reload" form and update state based on history.listen
    this.props.history.push(`${OLD_CYCLE_TIMING_URL}/${this.state.station}`);
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  handleStationChange = (e: any) => {
    this.setState({ station: e.target.value });
    this.props.history.push(`${OLD_CYCLE_TIMING_URL}/${e.target.value}`);
  };

  handleStationClear = () => {
    this.props.history.push(`${OLD_CYCLE_TIMING_URL}`);
  };

  render() {
    const {
      loading,
      station,
      stations,
      stationStart,
      duration,
      inspectionId,
      stationState,
    } = this.state;

    /**
     * NOTE: Nuance's with how cycle times are tracked
     * - Station/SKU timer is running if:
     *   - stationState === null && duration !== null
     *     - This happens when a timing has been paused
     *   - stationState === 'started' && duration === null
     *     - This state only exists right when a station has started
     *       - POST /service/startStation
     */
    const possiblyRunning = stationState === null || stationState === 'started';

    return (
      <main className="station-inspection-wrapper">
        {station && (
          <Fragment>
            <article onClick={this.handleStationClear} className="currentStation">
              <p>STATION</p>
              <span>{station.toLocaleUpperCase()}</span>
            </article>
            <form className="sku-form text-center" onSubmit={this.submitFormSearchSkuForStation}>
              <input
                type="text"
                value={this.state.sku}
                onChange={this.handleChange}
                className="text-input"
                name="sku"
                placeholder="Search by SKU"
              />
              <input type="submit" value="submit" className="classy-button" />
              <button type="button" className="clear-button" onClick={this.clearTimingForm}>
                clear
              </button>
            </form>
          </Fragment>
        )}
        <section
          className="react-table text-center"
          style={{ marginTop: 50, display: 'flex', width: '65%' }}
        >
          {!station && (
            <span className="input-label-wrap">
              <label>Station:</label>
              <select
                id="station"
                name="station"
                className="text-input"
                value={station}
                onChange={this.handleStationChange}
              >
                <option disabled={!!station}>Select your station</option>
                {stations.map((v: any) => (
                  <option key={v.id} value={v.id}>
                    {v.id}
                  </option>
                ))}
              </select>
            </span>
          )}
          {station && inspectionId && (
            <div className="flex-column" style={{ flex: 1 }}>
              <h2>
                <Timer startDate={stationStart} seconds={duration} timerOn={possiblyRunning} />
              </h2>
              <h3>{this.state.title}</h3>
              <a
                style={{
                  textAlign: 'left',
                  textDecoration: 'underline',
                  color: '#000',
                }}
                target="_blank"
                rel="noopener noreferrer"
                href={`/service/service-plans/${inspectionId}`}
              >
                Inspection
              </a>
              <div
                className="flex-row"
                style={{ justifyContent: 'space-between', marginTop: '15px' }}
              >
                <button
                  className="classy-button"
                  disabled={stationState === 'completed'}
                  onClick={
                    stationState === 'paused' ? this.restartStation : () => this.pauseStation(true)
                  }
                >
                  {stationState === 'completed'
                    ? 'STATION COMPLETED'
                    : stationState === 'paused'
                    ? 'CONTINUE'
                    : 'PAUSE'}
                </button>
                <button
                  className="classy-button"
                  disabled={stationState === 'paused' || stationState === 'completed'}
                  onClick={this.endStation}
                >
                  COMPLETE
                </button>
              </div>
              {possiblyRunning && (
                <h5
                  style={{ textAlign: 'left' }}
                >{`*NOTE* We'll attempt to pause the timer if this page/tab is closed.`}</h5>
              )}
            </div>
          )}
        </section>
        <Loader loading={loading} />
      </main>
    );
  }
}

export default StationLanding;
