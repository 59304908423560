import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { renderCellExpand } from '../../../components/library/DataGrid/renderCellExpand';
import { StripedDataGrid } from '../../../styledComponents/datagrid';
import { formatPhone } from '../../../utils/helper';

const TupDealers = ({ dealers, history }) => (
  <Fragment>
    <StripedDataGrid
      className="react-table"
      rows={dealers}
      getTrProps={(state, rowInfo) => ({
        onClick: e => {
          history.push(`/buyers/tradeups/admin/dealerDetail/${rowInfo.original.id}`);
        },
      })}
      columns={[
        {
          headerName: 'Dealer ID',
          field: 'id',
          renderCell: renderCellExpand,
          flex: 1,
        },
        {
          headerName: 'Name',
          field: 'name',
          renderCell: renderCellExpand,
          flex: 5,
        },
        {
          headerName: 'Email',
          field: 'contact_email',
          renderCell: renderCellExpand,
          flex: 4,
        },
        {
          headerName: 'Phone',
          field: 'phone',
          flex: 3,
          renderCell: params => {
            params.value = formatPhone(params.value);
            return renderCellExpand(params);
          },
        },
        {
          headerName: 'City',
          field: 'city',
          renderCell: renderCellExpand,
          flex: 2,
        },
        {
          headerName: 'State',
          field: 'state',
          renderCell: renderCellExpand,
          flex: 2,
        },
      ]}
    />
  </Fragment>
);

export default withRouter(TupDealers);
