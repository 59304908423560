import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { DataGrid as MuiDataGrid, DataGridProps as MuiDataGridProps } from '@mui/x-data-grid';
import { DirectionsBike as BikeLogo } from '@material-ui/icons';

interface StyleProps {
  height?: string;
  width?: string;
}

type DataGridProps = MuiDataGridProps & {
  height?: string;
  width?: string;
  searchNotInitiated?: boolean;
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: (props: StyleProps) => props.height,
    width: (props: StyleProps) => props.width,
  },
  root: {
    border: 0,
    background: theme.palette.common.white,
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
    },
  },
  columnHeader: {
    fontFamily: theme.typography.condensed,
    padding: `0 ${theme.spacing(2)}px !important`,
  },
  cell: {
    padding: `0 ${theme.spacing(2)}px !important`,
  },
  filterFormDeleteIcon: {
    width: '15px;',
  },
}));

const CustomNoResultsOverlay = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    }}
  >
    <BikeLogo style={{ height: 48, width: 41, color: '#00C160' }} />
    <Typography variant="h5">We couldn&apos;t find a match</Typography>
    <Typography variant="body1">
      Try adjusting your filters or enter a new search term that is less specific
    </Typography>
  </Box>
);
const CustomNoSearchOverlay = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    }}
  >
    <BikeLogo style={{ height: 48, width: 41, color: '#00C160' }} />
    <Typography variant="h5">Start your search</Typography>
  </Box>
);

export const DataGrid: React.FC<DataGridProps> = ({ height, width, ...props }) => {
  const [paginationModel, setPaginationModel] = useState(
    props.paginationModel || { pageSize: 5, page: 0 },
  );
  const classes = useStyles({ height, width });
  return (
    <Box className={classes.wrapper}>
      <MuiDataGrid
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={props.pageSizeOptions || [5, 10, 20]}
        disableRowSelectionOnClick={true}
        {...props}
        slots={{
          noRowsOverlay: props.searchNotInitiated ? CustomNoSearchOverlay : CustomNoResultsOverlay,
          ...props.slots,
        }}
        getRowClassName={params => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        classes={{
          root: classes.root,
          columnHeader: classes.columnHeader,
          cell: classes.cell,
          filterFormDeleteIcon: classes.filterFormDeleteIcon,
        }}
      />
    </Box>
  );
};
