import React from 'react';
import { StatusBadge, StatusOption } from '../../../../components/library/StatusBadge';
import { InspectionStatusEnum } from '../../../../services/service/types';

const statusIdMap: { [key: number]: StatusOption } = {
  [InspectionStatusEnum['Ready for Intake']]: 'primary',
  [InspectionStatusEnum['Intake Started']]: 'primary',
  [InspectionStatusEnum['Ticket Reopened']]: 'primary',
  [InspectionStatusEnum['Ready for Inspection']]: 'warning',
  [InspectionStatusEnum['Inspection Started']]: 'warning',
  [InspectionStatusEnum['Ready for Parts']]: 'info',
  [InspectionStatusEnum['Ready for Line']]: 'info',
  [InspectionStatusEnum['Ready for Master Tech']]: 'info',
  [InspectionStatusEnum['Service Started']]: 'warning',
  [InspectionStatusEnum['Service Complete']]: 'success',
  [InspectionStatusEnum['Failed']]: 'error',
  [InspectionStatusEnum['Rejected']]: 'orange',
};

interface InspectionStatusBadgeProps {
  statusId: number;
  label?: string;
}

export const InspectionStatusBadge: React.FC<InspectionStatusBadgeProps> = ({
  statusId,
  label,
}) => (
  <StatusBadge
    size="small"
    status={label ? statusIdMap[statusId] : 'primary'}
    label={label || '¯\\_(ツ)_/¯'}
  />
);
