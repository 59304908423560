import React, { FC } from 'react';
import { Checkbox, FormControl, FormControlLabel, CheckboxProps } from '@material-ui/core';
import styled from 'styled-components';
import { useField } from 'formik';
import { sharedInputStyles } from './FormikTextInput';
import { CopyAndSaveFieldValueToAllVariants } from '../../services/catalog/types';

export interface FormikCheckboxProps {
  label: string;
  autoFocus?: boolean;
  disabled?: boolean;
  copyAndSaveFieldValueToAllVariants?: CopyAndSaveFieldValueToAllVariants;
}

type InputProps = FormikCheckboxProps & CheckboxProps & { name: string };

export const FieldWrapper = styled(FormControl)`
  &.MuiFormControl-root {
    ${sharedInputStyles}
    padding: 18.5px 14px;
  }
`;

const FormikCheckboxField: FC<InputProps> = ({
  name,
  label,
  // below variables are set aside so that we can pass `...props` into a dom element without warnings.
  copyAndSaveFieldValueToAllVariants: _cas,
  ...props
}) => {
  const [field] = useField(name);

  return (
    <FieldWrapper>
      <FormControlLabel
        control={<Checkbox {...field} {...props} checked={field.value ?? false} color="primary" />}
        label={label}
      />
    </FieldWrapper>
  );
};
export default FormikCheckboxField;
