import React from 'react';
import FormikAutoCompleteField from '../../../components/catalog/FormikAutoCompleteField';
import FormikTextInput from '../../../components/catalog/FormikTextInput';
import FormikCheckboxField from '../../../components/catalog/FormikCheckboxField';
import FormikEditableAutoCompleteField from '../../../components/catalog/FormikEditableAutoCompleteField';
import FormikMinMaxField from '../../../components/catalog/FormikMinMaxField';
import FormikDisplayField from '../../../components/catalog/FormikDisplayField';
import FormikAppendUnits from '../../../components/catalog/FormikAppendUnits';
import { FormikFieldConfiguration } from '../utils/formik/types';
import { omit } from 'lodash';

const FormikField: React.FC<{ field: FormikFieldConfiguration }> = ({ field }) => {
  const { valueKey } = field;
  //some components beloe use ... to spread all its input props into a child material-ui
  //component, so any camelCased properties have to be omitted from it to avoid console errors.
  //this `omitting` has to be done within the case statement after the type has been narrowed.
  switch (field.componentType) {
    case 'autocomplete':
      return <FormikAutoCompleteField {...field} name={valueKey} />;
    case 'editableAutocomplete':
      return <FormikEditableAutoCompleteField {...field} name={valueKey} />;
    case 'checkbox':
      return (
        <FormikCheckboxField
          {...omit(field, ['displayOrder', 'componentType', 'subGroup', 'valueKey'])}
          name={valueKey}
        />
      );
    case 'minMax':
      const domFieldMinMax = omit(field, [
        'displayOrder',
        'valueKey',
        'group',
        'subGroup',
        'componentType',
      ]);
      return <FormikMinMaxField {...domFieldMinMax} name={valueKey} />;
    case 'appendUnits':
      const domFieldAppend = omit(field, [
        'displayOrder',
        'valueKey',
        'group',
        'subGroup',
        'componentType',
      ]);
      return <FormikAppendUnits {...domFieldAppend} name={valueKey} />;
    case 'link':
    case 'displayText':
      return <FormikDisplayField {...field} name={valueKey} />;
    default:
      const domField = omit(field, [
        'displayOrder',
        'valueKey',
        'group',
        'subGroup',
        'componentType',
      ]);
      return <FormikTextInput {...domField} name={valueKey} />;
  }
};

export default FormikField;
