// Copied from `class-validator` types
interface ValidationError {
  target?: object;

  /**
   * Object's property that haven't pass validation.
   */
  property: string;

  /**
   * Value that haven't pass a validation.
   *
   * OPTIONAL - configurable via the ValidatorOptions.validationError.value option
   */
  value?: any;

  /**
   * Constraints that failed validation with error messages.
   */
  constraints?: {
    [type: string]: string;
  };

  /**
   * Contains all nested validation errors of the property.
   */
  children?: ValidationError[];

  /*
   * A transient set of data passed through to the validation result for response mapping
   */
  contexts?: {
    [type: string]: any;
  };
}

// Used to take class-validator nested arrays and flatten to string of errors
export const getErrorsArray = (msg: ValidationError[]): string[] => {
  const errors: string[] = [];
  function getConstraints(msg: ValidationError) {
    if (msg?.children?.length === 0 && msg.constraints && Object.keys(msg.constraints).length) {
      errors.push(...Object.values(msg.constraints));
      return;
    }

    if (msg.children && msg.children.length) {
      for (const children of msg.children) {
        getConstraints(children);
      }
    }
  }

  for (const m of msg) {
    getConstraints(m);
  }

  return errors;
};

// DEBT-116: test this
export const extractApiErrorMessage = (error: any) => {
  // If the class-validator is run it responds with error key
  // If shopify validator is running it responds with message key
  if (Array.isArray(error?.response?.data?.errors)) {
    return getErrorsArray(error.response.data.errors);
  } else if (Array.isArray(error?.response?.data?.message)) {
    return getErrorsArray(error.response.data.message);
  } else if (error?.response?.data?.message) {
    return error.response.data.message;
  } else if (typeof error === 'string') {
    return error;
  } else {
    return 'Oops! Something went wrong';
  }
};
