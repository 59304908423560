import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getUploadedImageDimensions, readFileAsync } from '../utils/fileUploads';
import { FEProductImage } from '../../../services/catalog/types';
import { uploadProductImages } from '../../../actions/product';
import DragDropFileInput from './DragDropFileInput';
import DragAndDropImages from './DragAndDropImages';
import { FullProductImage } from './ProductImagesView';

const NoImagesMsg = styled.p`
  display: block;
  margin: 3rem;
  text-align: center;
`;

interface ProductImageFile extends FEProductImage {
  file: File;
  productVariantId?: number;
}

interface AddProductImagesProps {
  preExistingProductImages: FullProductImage[];
  preExistingVariantImages: FullProductImage[];
  variantId?: number;
  limit: number;
}

// e.g. "foo.JPG" and "foo.jpeg" will be considered duplicates
const santizeFileName = (str: string) => str.toLowerCase().replace(/\.[^/.]+$/, '');

const AddProductImages: React.FC<AddProductImagesProps> = ({
  preExistingProductImages,
  preExistingVariantImages,
  variantId,
  limit,
}) => {
  const [images, setImages] = useState<ProductImageFile[]>([]);
  const [someImgsDuplicates, setSomeImgsDuplicates] = useState<boolean>(false);
  const [someImgsOversized, setSomeImgsOversized] = useState<boolean>(false);
  const { productId }: { productId: string } = useParams();
  const dispatch = useDispatch();
  const isVariant = !!variantId;
  // Only get images for that variant so multiple variants can have images
  const existingVariantImages = useMemo(
    () => preExistingVariantImages.filter(({ productVariantId }) => productVariantId === variantId),
    [preExistingVariantImages, variantId],
  );

  useEffect(() => {
    setImages([]);
    setSomeImgsDuplicates(false);
    setSomeImgsOversized(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantId]);

  const handleInputUpload = async (imgFiles: File[]) => {
    setSomeImgsDuplicates(false);
    setSomeImgsOversized(false);
    const allImages = [...images, ...preExistingProductImages, ...existingVariantImages];
    const nonDuplicateImages = imgFiles.filter(
      newImg => !allImages.find(img => santizeFileName(newImg.name) === santizeFileName(img.name)),
    );
    if (nonDuplicateImages.length !== imgFiles.length) {
      setSomeImgsDuplicates(true);
    }
    const processedImgs = await Promise.all(
      nonDuplicateImages.map(async file => {
        // max of 20MB image size
        if (20 <= file.size / 1000 / 1000) {
          return null;
        }

        const fileBlob = await readFileAsync(file);
        const imgDimensions = await getUploadedImageDimensions(fileBlob);

        // max of 4472px wide or tall
        if (imgDimensions.width > 4472 || imgDimensions.height > 4472) {
          return null;
        }

        const name = file.name.split('/').pop();
        const imgObj: ProductImageFile = {
          file: file,
          name: name ?? '',
          src: fileBlob ?? '',
          alt: name ?? '',
        };
        if (isVariant) imgObj.productVariantId = variantId;
        return imgObj;
      }),
    );
    const imgsToAdd = processedImgs.filter((img): img is ProductImageFile => !!img);
    if (imgsToAdd.length !== nonDuplicateImages.length) {
      setSomeImgsOversized(true);
    }
    if (imgsToAdd.length > 0) {
      const slotsRemaining =
        limit - (isVariant ? existingVariantImages : preExistingProductImages).length;
      setImages(existingImgs => [...existingImgs, ...imgsToAdd.slice(0, slotsRemaining)]);
    }
  };

  const handleSave = () => {
    dispatch(
      uploadProductImages({
        productId,
        images,
        onSuccess: () => {
          setImages([]);
          setSomeImgsDuplicates(false);
          setSomeImgsOversized(false);
        },
      }),
    );
  };

  const hasUploadedImages = images.length > 0;
  const maxFilesUploaded = isVariant
    ? existingVariantImages.length + images.length >= limit
    : preExistingProductImages.length + images.length >= limit;

  return (
    <div>
      {maxFilesUploaded ? (
        <p>
          You have uploaded the maximum number of files for this {isVariant ? 'variant' : 'product'}{' '}
          ({limit}).
        </p>
      ) : (
        <DragDropFileInput required handleFilesDrop={handleInputUpload} />
      )}
      {someImgsDuplicates && (
        <Alert severity="warning" style={{ marginTop: '1rem' }}>
          One or more of the images you uploaded are already associated with the product or one of
          its variants.
        </Alert>
      )}
      {someImgsOversized && (
        <Alert severity="warning" style={{ marginTop: '1rem' }}>
          <p>
            One or more of the images you uploaded are over{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://help.shopify.com/en/manual/products/product-media/product-media-types#images"
            >
              Shopify&apos;s image size limits
            </a>
            .
          </p>
        </Alert>
      )}
      <Prompt when={hasUploadedImages} message="Are you sure you want to exit without saving?" />
      {hasUploadedImages && (
        <>
          <DragAndDropImages images={images} handleChange={setImages} />
          <Button variant="outlined" onClick={handleSave}>
            SAVE
          </Button>
        </>
      )}
      {!hasUploadedImages && !maxFilesUploaded && (
        <NoImagesMsg>Drag + drop images onto the area above or click to upload.</NoImagesMsg>
      )}
    </div>
  );
};

export default AddProductImages;
