// todo: prefer Context and useReducer over Redux if you're touching one of the interfaces that
// uses these actions. if redux is still required, carve out your interface's actions into a separate file
// in this dir which gets imported by this file.
export const FETCH_AUTH = 'fetch_auth_status';
export const LOGOUT = 'logout';
export const FETCH_SURVEYS = 'fetch_surveys';
export const FETCH_SNOWFLAKE_VELOCITY = 'fetch_snowflake_velocity';
export const FETCH_VELOCITY_CSV = 'fetch_velocity_csv';
export const GET_PURCHASE_FILTERS = 'get_purchase_filters';
export const GET_PRODUCT_FILTERS = 'get_product_filters';
export const GET_PRODUCT_SORT = 'get_product_sort';
export const GET_PRODUCT_PAGE = 'get_product_page';
export const GET_CUSTOMER_SORT = 'get_customer_sort';
export const GET_CUSTOMER_PAGE = 'get_customer_page';
export const GET_CUSTOMER_FILTERS = 'get_customer_filters';
export const GET_C360_FILTERS = 'get_c360_filters';
export const GET_C360_PAGE = 'get_c360_page';
export const GET_PURCHASE_SORT = 'get_purchase_sort';
export const GET_PURCHASE_PAGE = 'get_purchase_page';
export const GET_TRADEUP_FILTERS = 'get_tradeup_filters';
export const GET_TRADEUP_SORT = 'get_tradeup_sort';
export const GET_TRADEUP_PAGE = 'get_tradeup_page';
export const GET_CATCH_RELEASE_FILTERS = 'get_catch_release_filters';
export const GET_CATCH_RELEASE_SORT = 'get_catch_release_sort';
export const GET_CATCH_RELEASE_PAGE = 'get_catch_release_page';
export const RESET_CATCH_RELEASE_STORE = 'reset_catch_release_store';
export const GET_TAG_FILTERS = 'get_tag_filters';
export const GET_TAG_PAGE = 'get_tag_page';
export const GET_SERVICE_STATION = 'get_service_station';
export const SW_INIT = 'service_worker_init';
export const SW_UPDATE = 'service_worker_update';
export const SET_PARTS_METADATA = 'set_inspection_parts_metadata';

export const SET_CATALOG_PRODUCT_CATEGORIES = 'set_catalog_product_categories';
export const SET_CATALOG_PRODUCT_BRANDS = 'set_catalog_product_brands';
export const SET_CATALOG_PRODUCT_PIPELINES = 'set_catalog_product_pipelines';
export const SET_CATALOG_PRODUCT_STATUS_FLAGS = 'set_catalog_product_status_flags';
export const SET_CATALOG_AUTOSUGGEST_FIELDS = 'set_autosuggest_fields';
export const SET_CATALOG_PRODUCT_DISCIPLINES = 'set_catalog_product_disciplines';
export const SET_CATALOG_PRODUCT_SIZE_CLASSES = 'set_catalog_product_size_classes';
export const SET_CATALOG_PRODUCT_SUB_CATEGORIES = 'set_catalog_product_sub_categories';
export const SET_CATALOG_PRODUCT_IMAGES_LOADING = 'set_catalog_product_images_loading';
export const SET_CATALOG_PRODUCT_IMAGES_ERROR = 'set_catalog_product_images_error';
export const SET_CATALOG_PRODUCT_IMAGES = 'set_catalog_product_images';
export const SET_CATALOG_FRAME_DIMENSIONS = 'set_catalog_frame_dimensions';
export const SET_CATALOG_CONFIGURATION = 'set_catalog_configuration';
export const SET_CATALOG_ATTRIBUTE_TYPES = 'set_catalog_attribute_types';
export const SET_INITIAL_ITEM_CATALOG_DATA = 'SET_INITIAL_ITEM_CATALOG_DATA';

export const SET_CATALOG_SEARCH_PRODUCTS_PARAM = 'SET_CATALOG_SEARCH_PRODUCTS_PARAM';
export const CLEAR_CATALOG_SEARCH_PRODUCTS_PARAMS = 'CLEAR_CATALOG_SEARCH_PRODUCTS_PARAMS';
export const SET_CATALOG_SEARCH_PRODUCTS_RESULTS = 'SET_CATALOG_SEARCH_PRODUCTS_RESULTS';
export const SET_CATALOG_SEARCH_PRODUCTS_LOADING = 'SET_CATALOG_SEARCH_PRODUCTS_LOADING';
export const SET_AUTH_USER = 'SET_AUTH_USER';

export const GET_NAV_EXPANDED = 'GET_NAV_EXPANDED';
export const SET_NAV_EXPANDED = 'SET_NAV_EXPANDED';

export const SET_INSPECTIONS_SEARCH_PARAM = 'SET_INSPECTIONS_SEARCH_PARAM';
export const CLEAR_INSPECTIONS_SEARCH_PARAMS = 'CLEAR_INSPECTIONS_SEARCH_PARAMS';
export const SET_INSPECTIONS_SEARCH_RESULTS = 'SET_INSPECTIONS_SEARCH_RESULTS';
export const SET_INSPECTIONS_SEARCH_LOADING = 'SET_INSPECTIONS_SEARCH_LOADING';
export const SET_INSPECTIONS_SEARCH_ERROR = 'SET_INSPECTIONS_SEARCH_ERROR';

export const SET_CYCLE_TIMING_SKU = 'SET_CYCLE_TIMING_SKU';
export const SET_CYCLE_TIMING_SUB_ID = 'SET_CYCLE_TIMING_SUB_ID';
