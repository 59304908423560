import React, { useEffect, useState, useContext, useMemo } from 'react';
import { createStyles, FormControl, FormGroup, makeStyles, Theme } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import { FormikRejectState } from '../../../_shared/types';
import { ModuleWrapper } from '../../styled';
import { CategoryHeaderWrapper } from '../styled';
import { NotesTextarea } from '../NotesTextarea';
import { RejectCheckbox } from './RejectCheckbox';
import { ServicePlanRejectionReason } from './types';
import { getServicePlanRejectionReasons } from '../../../../../../services/service/services';
import { pageStateContext } from '../../../../../_shared';
import { serviceTicketContext } from '../../../_shared';
import { getRejectionReasonsWithCheckboxValues } from './RejectModule.utils';
import { API_URL } from '../../../../../../constants';
import axios from '../../../../../../utils/axios';
import { ValidationErrorModal } from '../../../ValidationErrorModal/ValidationErrorModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
      gridGap: theme.spacing(1),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr 1fr',
      },
    },
  }),
);

interface RejectModuleProps {
  handleSuccess(): void;
}

export const RejectModule = React.forwardRef<FormikProps<FormikRejectState>, RejectModuleProps>(
  ({ handleSuccess }, ref) => {
    const classes = useStyles();

    const {
      errorBus: { setError },
      loadingBus: { setLoading },
    } = useContext(pageStateContext);

    const { inspectionData } = useContext(serviceTicketContext);
    const { inspection } = inspectionData;

    const [rejectionReasons, setRejectionReasons] = useState<ServicePlanRejectionReason[] | null>(
      null,
    );
    const [validationErrorModalOpen, setValidationErrorModalOpen] = useState<boolean>(false);

    useEffect(() => {
      const fetchRejectionReasons = async () => {
        try {
          setLoading('loadRejectionReasons', true);
          const rejectionReasons = await getServicePlanRejectionReasons();
          if (rejectionReasons && rejectionReasons.data) {
            setRejectionReasons(rejectionReasons.data);
          }
        } catch (err) {
          setError('handleRejectionReasonFailure', new Error(err?.response.data.message));
        } finally {
          setLoading('loadRejectionReasons', false);
        }
      };

      if (!rejectionReasons && inspection?.servicePlanRejectionReasons) {
        fetchRejectionReasons();
      }
    }, [setLoading, setError, rejectionReasons, inspection, handleSuccess]);

    const handleRejectSave = async (values: FormikRejectState) => {
      const reasonSelected = values.rejectionReasons.some(reason => reason.checked === true);

      if (!reasonSelected) {
        setValidationErrorModalOpen(true);
        return;
      }

      if (inspection) {
        // Format the rejection reasons payload for inspection update
        const formattedRejectionReasons: ServicePlanRejectionReason[] = values.rejectionReasons
          .filter(reason => reason.checked)
          .map(reason => ({
            servicePlanId: inspection.id,
            rejectionReasonId: reason.rejectionReasonId,
          }));
        const payload = {
          servicePlanRejectionReasons: formattedRejectionReasons,
          rejectionNotes: values.rejectionNotes,
        };

        try {
          setLoading('rejectSave', true);
          await axios.put(`${API_URL}/service/inspections/${inspection.id}`, payload);
          handleSuccess();
        } catch (err) {
          setError('handleRejectSave', new Error(err.response.data.message));
        } finally {
          setLoading('rejectSave', false);
        }
      }
    };

    const initialRejectValues: FormikRejectState = useMemo(() => {
      const rejectionReasonsWithCheckboxValues = getRejectionReasonsWithCheckboxValues(
        rejectionReasons,
        inspection?.servicePlanRejectionReasons,
      );
      return {
        rejectionReasons: rejectionReasonsWithCheckboxValues ?? [],
        rejectionNotes: inspection?.rejectionNotes ?? null,
      };
    }, [inspection, rejectionReasons]);

    return (
      <Formik<FormikRejectState>
        innerRef={ref}
        initialValues={initialRejectValues}
        enableReinitialize
        onSubmit={handleRejectSave}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <ModuleWrapper>
                <CategoryHeaderWrapper>
                  <h3>Rejection Reason</h3>
                </CategoryHeaderWrapper>
                {values.rejectionReasons?.length && (
                  <>
                    <p>Select 1 or more reasons why this item is being rejected.</p>
                    <FormControl component="fieldset">
                      <FormGroup className={classes.checkboxContainer}>
                        {values.rejectionReasons.map(reason => (
                          <RejectCheckbox
                            key={`reject-reason-${reason.rejectionReasonId}`}
                            id={reason.rejectionReasonId}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </>
                )}
                {validationErrorModalOpen && (
                  <ValidationErrorModal
                    open={validationErrorModalOpen}
                    handleClose={() => setValidationErrorModalOpen(false)}
                    errors={{
                      error: { error: 'Select 1 or more reasons why this item is being rejected.' },
                    }}
                    optionalDetails={
                      <>
                        If you want to remove this item from Rejection status, click
                        <strong> Remove Rejection</strong> instead.
                      </>
                    }
                  />
                )}
                <NotesTextarea
                  value={values.rejectionNotes}
                  setValue={e => setFieldValue('rejectionNotes', e.target.value)}
                />
              </ModuleWrapper>
            </div>
          </form>
        )}
      </Formik>
    );
  },
);
