import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export type FieldColor = 'success' | 'warning' | 'neutral' | 'danger';

interface Props {
  label: string;
  value: string | number | boolean | React.ReactNode;
  color?: FieldColor;
  url?: string;
  unit?: string;
}

export const Field = styled.div`
  margin: 10px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  line-height: 1.5em;
`;

const Link = styled(RouterLink)`
  text-decoration: underline;
`;

const Value = styled.span`
  color: ${({ color }) =>
    color === 'success'
      ? '#4caf50'
      : color === 'warning'
      ? '#ff9800'
      : color === 'neutral'
      ? '#2196f3'
      : color === 'danger'
      ? '#f44336'
      : ''};
`;

const DisplayField: React.FC<Props> = ({ label, value, color, url, unit }) => {
  return (
    <Field>
      <b>{label}</b>
      {!!url ? (
        <Link target="_blank" to={url.startsWith('http') ? { pathname: url } : url}>
          {value}
        </Link>
      ) : (
        <Value color={color}>
          {typeof value === 'boolean' ? value.toString() : value}
          {unit ? ' ' + unit : ''}
        </Value>
      )}
    </Field>
  );
};

export default DisplayField;
