import { useState, useCallback } from 'react';
import { getExportsForTesting } from '../../testing/getExportsForTesting';

const getErrorLogger = (funcName: string) => () => {
  console.error(`UseLoadingBus does not have a set ${funcName} function.`);
};

export class LoadingBus {
  loading: boolean;
  loadingStates: { [key: string]: boolean };
  setLoading: (key: string, loading: boolean) => void;

  constructor(init?: Partial<LoadingBus>) {
    this.loading = init?.loading ?? false;
    this.loadingStates = init?.loadingStates ?? {};
    this.setLoading = init?.setLoading ?? getErrorLogger('setLoading');
  }
}

export const useLoadingBus = () => {
  const [loadingStates, setLoadingStates] = useState<LoadingBus['loadingStates']>({});

  const setLoading = useCallback(
    (key: string, isLoading: boolean) => {
      setLoadingStates(oldLoadingStates => {
        return { ...oldLoadingStates, [key]: Boolean(isLoading) };
      });
    },
    [setLoadingStates],
  );

  return { loading: Object.values(loadingStates).some(val => val), loadingStates, setLoading };
};

// testing
const setupMockLoadingBus = () => {
  const setLoadingCalls: any[] = [];
  return {
    loadingBus: new LoadingBus({
      setLoading: (...args: any[]) => setLoadingCalls.push(args),
    }),
    setLoadingCalls,
  };
};

export const exportsForTestingUseLoadingBus = getExportsForTesting({ setupMockLoadingBus });

// todo: test other hooks
