import * as types from './types';
export * from './product';
export * from './catalog';
export * from './catalogSearchProducts';
export * from './inspectionsSearch';

export const getC360Filters = payload => dispatch => {
  dispatch({ type: types.GET_C360_FILTERS, payload });
};

export const getC360Page = payload => dispatch => {
  dispatch({ type: types.GET_C360_PAGE, payload });
};

export const getCustomerFilters = payload => dispatch => {
  dispatch({ type: types.GET_CUSTOMER_FILTERS, payload });
};

export const getPurchasesFilters = payload => dispatch => {
  dispatch({ type: types.GET_PURCHASE_FILTERS, payload });
};

export const getCustomerSort = payload => dispatch => {
  dispatch({ type: types.GET_CUSTOMER_SORT, payload });
};

export const getCustomerPage = payload => dispatch => {
  dispatch({ type: types.GET_CUSTOMER_PAGE, payload });
};

export const getPurchasesSort = payload => dispatch => {
  dispatch({ type: types.GET_PURCHASE_SORT, payload });
};

export const getPurchasesPage = payload => dispatch => {
  dispatch({ type: types.GET_PURCHASE_PAGE, payload });
};

export const getTradeupSort = payload => dispatch => {
  dispatch({ type: types.GET_TRADEUP_SORT, payload });
};

export const getTradeupPage = payload => dispatch => {
  dispatch({ type: types.GET_TRADEUP_PAGE, payload });
};

export const getTradeupFilters = payload => dispatch => {
  dispatch({ type: types.GET_TRADEUP_FILTERS, payload });
};

export const getCatchReleaseSort = payload => dispatch => {
  dispatch({ type: types.GET_CATCH_RELEASE_SORT, payload });
};

export const getCatchReleasePage = payload => dispatch => {
  dispatch({ type: types.GET_CATCH_RELEASE_PAGE, payload });
};

export const getCatchReleaseFilters = payload => dispatch => {
  dispatch({ type: types.GET_CATCH_RELEASE_FILTERS, payload });
};

export const resetCatchReleaseStore = () => dispatch => {
  dispatch({ type: types.RESET_CATCH_RELEASE_STORE });
};

export const getTagFilters = payload => dispatch => {
  dispatch({ type: types.GET_TAG_FILTERS, payload });
};

export const getTagPage = payload => dispatch => {
  dispatch({ type: types.GET_TAG_PAGE, payload });
};

export const fetchAuthenticated = token => async dispatch => {
  dispatch({ type: types.FETCH_AUTH, payload: token });
};

export const setUser = payload => dispatch => {
  dispatch({ type: types.SET_AUTH_USER, payload });
};

export const logout = () => async dispatch => {
  dispatch({ type: types.LOGOUT, payload: true });
};

export const getServiceStation = payload => dispatch => {
  dispatch({ type: types.GET_SERVICE_STATION, payload });
};

export const onSWInit = () => dispatch => dispatch({ type: types.SW_INIT });

export const onSWUpdate = payload => dispatch => dispatch({ type: types.SW_UPDATE, payload });

export const setPartsMetadata = payload => dispatch =>
  dispatch({
    type: types.SET_PARTS_METADATA,
    payload,
  });

export const getNavExpanded = payload => dispatch => {
  dispatch({ type: types.GET_NAV_EXPANDED, payload });
};

export const setNavExpanded = payload => dispatch => {
  dispatch({ type: types.SET_NAV_EXPANDED, payload });
};

export const setCycleTimingSku = payload => dispatch => {
  dispatch({ type: types.SET_CYCLE_TIMING_SKU, payload });
};

export const setCycleTimingSubId = payload => dispatch => {
  dispatch({ type: types.SET_CYCLE_TIMING_SUB_ID, payload });
};
