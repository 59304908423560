import React from 'react';

import { Box, Chip, makeStyles, Theme } from '@material-ui/core';

interface SubmissionChipProps {
  label: string;
  color: string;
  variant?: 'default' | 'leftBorder';
}
const useStyles = makeStyles<Theme, SubmissionChipProps>(theme => ({
  chipOverlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.primary,
    background: 'transparent',
    fontFamily: theme.typography.bold,
    textTransform: 'capitalize',
    '-webkit-font-smoothing': 'antialiased',
    // if variant is leftBorder, add a border to the left using the color prop
    borderLeft: props => (props.variant === 'leftBorder' ? `6px solid ${props.color}` : 'none'),
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: ({ color }) => color,
      opacity: 0.2,
      zIndex: -1,
    },
    '&::after': {
      content: ({ label }) => `"${label}"`,
      position: 'absolute',
      top: 0,
      // if variant is leftBorder, add a left padding to the text
      left: props => (props.variant === 'leftBorder' ? 3 : 0),
      width: '100%',
      height: '100%',
      fontFamily: theme.typography.bold,
      'text-transform': 'capitalize',
      color: ({ color }) => color,
      mixBlendMode: 'color',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

export const SubmissionChip: React.FC<SubmissionChipProps> = ({
  label,
  color,
  variant = 'default',
}) => {
  const classes = useStyles({ label, color, variant });
  return (
    <Box
      position="relative"
      overflow="hidden"
      zIndex={1}
      borderRadius={variant === 'leftBorder' ? 4 : 9999}
    >
      <Chip
        className={classes.chipOverlay}
        label={label}
        size="medium"
        style={{
          borderRadius: variant === 'leftBorder' ? 4 : 9999,
        }}
      />
    </Box>
  );
};
