import { AnyAction } from 'redux';
import * as types from '../actions/types';
import { ProductImage } from '../services/catalog/types';
import { FullProductImage } from '../pages/catalog/productImages/ProductImagesView';

export interface ProductImagesState {
  loading: boolean;
  errorMsg: string | undefined;
  productImages: FullProductImage[];
  variantImages: FullProductImage[];
}

const initialState: ProductImagesState = {
  loading: false,
  errorMsg: undefined,
  productImages: [],
  variantImages: [],
};

export default function catalogProductImagesReducer(
  state = initialState,
  action: AnyAction,
): ProductImagesState {
  const { type, payload } = action;
  switch (type) {
    case types.SET_CATALOG_PRODUCT_IMAGES_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case types.SET_CATALOG_PRODUCT_IMAGES_ERROR: {
      return {
        ...state,
        errorMsg: payload,
      };
    }
    case types.SET_CATALOG_PRODUCT_IMAGES: {
      const apiImages = payload as ProductImage[];
      const productImages: FullProductImage[] = [];
      const variantImages: FullProductImage[] = [];
      for (const img of apiImages) {
        const name = img.imageUrl.split('/').pop() ?? '';
        const fullProductImage = Object.assign({}, img, {
          name: name ?? '',
          src: img.imageUrl ?? '',
          alt: name ?? '',
        });
        if (img?.productVariantId) {
          variantImages.push(fullProductImage);
        } else {
          productImages.push(fullProductImage);
        }
      }
      return {
        ...state,
        variantImages,
        productImages,
      };
    }
    default:
      return state;
  }
}
