import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import '../styles/dynamicForm.css';

export default class MultiSelectField extends React.Component {
  handleSelectChange = (newOption, action) => {
    this.props.onSelectChange(this.props.name, newOption);
  };

  render() {
    return (
      <Select
        classNamePrefix
        className="custom-dropdown"
        closeOnSelect={false}
        isMulti
        onChange={this.handleSelectChange}
        options={this.props.options}
        removeSelected
        simpleValue
        value={this.props.value}
        placeholder={this.props.placeholder}
        // styles={this.props.styles}
      />
    );
  }
}

MultiSelectField.propTypes = {
  onSelectChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  styles: PropTypes.shape(),
  placeholder: PropTypes.string.isRequired,
};

MultiSelectField.defaultProps = {
  styles: {},
};
