import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as types from './types';
import {
  InspectionsSearchState,
  InspectionsSearchParams,
  ParamKeys,
} from '../reducers/inspectionsSearchReducer';
import * as service from '../services/service/services';
import debounce from 'lodash/debounce';

type RootState = {
  inspectionsSearch: InspectionsSearchState;
};

export const clearInspectionsSearchParams = () => ({
  type: types.CLEAR_INSPECTIONS_SEARCH_PARAMS,
});

export const setInspectionsSearchResults = (payload: {
  results: InspectionsSearchState['results'];
  total: number;
}) => ({
  type: types.SET_INSPECTIONS_SEARCH_RESULTS,
  payload,
});

export const setInspectionsSearchLoading = (payload: boolean) => ({
  type: types.SET_INSPECTIONS_SEARCH_LOADING,
  payload,
});

export const setInspectionsSearchError = (payload: string | undefined) => ({
  type: types.SET_INSPECTIONS_SEARCH_ERROR,
  payload,
});

export const searchInspections = (): ThunkAction<void, RootState, unknown, AnyAction> =>
  debounce((dispatch, getState) => {
    const {
      inspectionsSearch: {
        loading,
        params: { paginationModel, sortModel, searchQuery, inspectorIds, mechanicIds, statusIds },
      },
    } = getState();
    if (loading) return;

    dispatch(setInspectionsSearchError(undefined));
    dispatch(setInspectionsSearchLoading(true));

    service.searchInspections(
      {
        limit: paginationModel.pageSize,
        offset: paginationModel.pageSize * paginationModel.page,
        orderBy: sortModel?.[0]?.field,
        orderDirection: sortModel?.[0]?.sort,
        searchQuery,
        inspectorIds,
        mechanicIds,
        statusIds,
      },
      {
        onSuccess: response => {
          dispatch(
            setInspectionsSearchResults({ results: response.results, total: response.total }),
          );
          dispatch(setInspectionsSearchLoading(false));
        },
        catchFailure: err => {
          dispatch(setInspectionsSearchLoading(false));
          dispatch(setInspectionsSearchError(err?.message ?? 'Unable to fetch inspections'));
        },
      },
    );
  }, 1000);

export const setInspectionsSearchParam = (
  key: ParamKeys,
  value?: InspectionsSearchParams[ParamKeys],
): ThunkAction<void, RootState, unknown, AnyAction> => dispatch => {
  // set param value
  dispatch({
    type: types.SET_INSPECTIONS_SEARCH_PARAM,
    payload: { param: key, value },
  });
  // refetch table results
  dispatch(searchInspections());
};
