import { FormikFieldConfiguration, CatalogFieldConfig } from '../formik/types';
import { CatalogFieldCategoryConfig } from '../formik/types';
import { CatalogProductState } from '../../../../reducers/product-reducer';
import {
  ProductVariantComponent,
  SingleItemProcurement,
  CopyAndSaveFieldValueToAllVariants,
  AttributeType,
} from '../../../../services/catalog/types';
import { ProductFormGroups, FieldsMap, GroupedFormikFieldsMapValueProps } from '../types';
import { fromGroupEnum, EDIT_VARIANT_SUBMISSION_FIELDS } from '../fieldConfigurationData';
import {
  ProductVariantComponentSpecKeys,
  getProductVariantComponentSpecsFields,
} from '../formFieldsMap';
import { CATEGORIES_GROUPED_BY_MAJOR } from '../categories';
import { buildFormikFieldConfiguration } from '../formik/formik-fields';

/* 
Utility functions pertaining to EditProductVariantForm.
*/

const convertComponentSpecsFieldsMap = (
  componentSpecsFieldsMap: FieldsMap<
    ProductVariantComponentSpecKeys,
    GroupedFormikFieldsMapValueProps<ProductFormGroups>
  >,
): FormikFieldConfiguration[] => {
  /* Notes: 
   - the order in which keys appear in this object, drives the display order. 
   - input will always come from getProductVariantComponentSpecsFields; all spec fields
     have a componentType of either autocomplete, appendUnits, or null/text
  */
  // const subgroupsInOrderOfAppearance: { [name: string]: number } = {};
  const subgroupsInOrderOfAppearance: string[] = [];
  const group = fromGroupEnum(ProductFormGroups['Component Specifications']);
  let displayOrderCounter = 0; //zero-based so the display order matches the valueKey
  const configs: FormikFieldConfiguration[] = Object.entries(componentSpecsFieldsMap).map(
    ([valueKey, field]) => {
      const { label, subGroup: subGroupName } = field;
      if (subGroupName) {
        if (!subgroupsInOrderOfAppearance.includes(subGroupName)) {
          subgroupsInOrderOfAppearance.push(subGroupName);
        }
      }
      const subGroup = subGroupName
        ? {
            displayName: subGroupName,
            displayOrder: subgroupsInOrderOfAppearance.indexOf(subGroupName) + 1,
          }
        : undefined;
      let config: FormikFieldConfiguration;
      if (field.componentType == 'autocomplete') {
        config = {
          valueKey,
          componentType: 'autocomplete',
          label,
          group,
          subGroup,
          displayOrder: displayOrderCounter,
          options: field.options,
          labelKey: field.labelKey,
        };
      } else if (field.componentType == 'appendUnits') {
        config = {
          valueKey,
          componentType: 'appendUnits',
          label,
          group,
          subGroup,
          displayOrder: displayOrderCounter,
          unitSuffix: field.unitSuffix,
        };
      } else {
        config = {
          valueKey,
          label,
          group,
          subGroup,
          displayOrder: displayOrderCounter,
        };
      }
      displayOrderCounter = displayOrderCounter + 1;
      return config;
    },
  );

  return configs;
};

const buildFrameDimensionsFields = (
  productStore: CatalogProductState,
): FormikFieldConfiguration[] => {
  return productStore.frameDimensions.map((frameDimension, index) => ({
    valueKey: `productVariantFrameDimensions[${index}].${frameDimension.name}`,
    displayOrder: frameDimension.displayPosition,
    componentType: 'minMax',
    label: frameDimension.name,
    header: frameDimension.name,
    alwaysDisplayText: frameDimension.alwaysDisplayText,
    group: fromGroupEnum(ProductFormGroups['Frame Geometry']),
    unitSuffix: frameDimension.unitSuffix,
  }));
};

const buildEditItemProcurementFields = (
  itemProcurement: Partial<SingleItemProcurement>,
  categoryId: number,
): FormikFieldConfiguration[] => {
  const fields: FormikFieldConfiguration[] = [];

  const { sybSubmissionId, partnerSubmissionId } = itemProcurement;
  const hasSubmission = !!sybSubmissionId || !!partnerSubmissionId;
  //ideal would be to pass in the category object and check the major category so we don't have to maintain the enum
  const showSerialNumber =
    hasSubmission ||
    CATEGORIES_GROUPED_BY_MAJOR.BIKES.includes(categoryId) ||
    CATEGORIES_GROUPED_BY_MAJOR.FRAMES.includes(categoryId) ||
    CATEGORIES_GROUPED_BY_MAJOR.WHEELS.includes(categoryId);

  if (sybSubmissionId) {
    fields.push(EDIT_VARIANT_SUBMISSION_FIELDS.sybSubmission);
  }
  if (partnerSubmissionId) {
    fields.push(EDIT_VARIANT_SUBMISSION_FIELDS.partnerSubmission);
  }
  if (hasSubmission) {
    fields.push(EDIT_VARIANT_SUBMISSION_FIELDS.createPurchaseOrderStatus);
    fields.push(EDIT_VARIANT_SUBMISSION_FIELDS.purchaseOrderLink);
  }
  if (showSerialNumber) {
    fields.push(EDIT_VARIANT_SUBMISSION_FIELDS.serialNumber);
  }

  return fields;
};

//replacement for formFieldsMap.getEditProductVariantInputMap
export const getEditProductVariantFieldListConfiguration = ({
  variantId,
  productStore,
  categoryId,
  productVariantComponents,
  initialSingleItemProcurement,
  userHasPriceChangePermissions,
  varyingAttributeTypes,
  copyAndSaveFieldValueToAllVariants,
  fieldCategoryConfigs,
  skipFrameDimensions,
}: {
  variantId: number | null;
  productStore: CatalogProductState;
  categoryId: number;
  productVariantComponents: ProductVariantComponent[];
  initialSingleItemProcurement: Partial<SingleItemProcurement>;
  userHasPriceChangePermissions?: boolean;
  varyingAttributeTypes?: AttributeType[];
  copyAndSaveFieldValueToAllVariants?: CopyAndSaveFieldValueToAllVariants;
  fieldCategoryConfigs: CatalogFieldCategoryConfig[];
  skipFrameDimensions?: boolean; //todo; :|
}): FormikFieldConfiguration[] => {
  const editing = !!variantId;

  //1. "Pair up" the base field configs and category configs, so we can iterate over them together
  const mergedConfig: {
    fieldBaseConfig: CatalogFieldConfig;
    fieldCategoryConfig: CatalogFieldCategoryConfig | undefined;
  }[] = productStore.catalogFieldConfigs.map(fieldBaseConfig => {
    const fieldCategoryConfig: CatalogFieldCategoryConfig | undefined = fieldCategoryConfigs.find(
      config =>
        (!!fieldBaseConfig.productVariantPropertyName &&
          config.catalogFieldConfig.productVariantPropertyName ==
            fieldBaseConfig.productVariantPropertyName) ||
        (!!fieldBaseConfig.attributeTypeId &&
          fieldBaseConfig.attributeTypeId == config.catalogFieldConfig.attributeTypeId),
    );
    return { fieldBaseConfig, fieldCategoryConfig };
  });

  //2. Filter the config pairs down to only those that should be included for this PV
  const includeField = ({
    fieldBaseConfig,
    fieldCategoryConfig,
  }: {
    fieldBaseConfig: CatalogFieldConfig;
    fieldCategoryConfig: { included: boolean } | undefined;
  }): boolean => {
    //excludedByDefault:true indicates that this field should only appear for certain designated categories - so only include it
    //if the category config says it's included.
    //Otherwise, the field is to be included *unless* the category config says it *isn't*.
    const includedForThisCategory = fieldBaseConfig.excludedByDefault
      ? fieldCategoryConfig?.included ?? false //only include if category config says to include
      : fieldCategoryConfig?.included ?? true; //only exclude if category config says not to include

    const includedForThisMode = editing || !fieldBaseConfig.requiresEditMode;
    return includedForThisCategory && includedForThisMode;
  };

  const filteredConfig = mergedConfig.filter(includeField);

  //3. Transform the config from data into a FormikFieldConfiguration object which we can
  //use to render the component.
  const formikFieldConfig: FormikFieldConfiguration[] = filteredConfig.map(fieldMergedConfig =>
    buildFormikFieldConfiguration(fieldMergedConfig, productStore, categoryId, {
      userHasPriceChangePermissions,
      copyAndSaveFieldValueToAllVariants,
      varyingAttributeTypes,
    }),
  );

  //4. Add in other fields that aren't configured via CatalogFieldConfigs:
  //component specs, frame dimensions, and item procurement

  //bit of a hack: just type-transforming the legacy component spec data...
  //it's likely possible to rewrite this function to just return a config array
  const componentSpecsFieldMap = getProductVariantComponentSpecsFields(
    productVariantComponents,
    productStore,
    categoryId,
  );
  const componentSpecsFields: FormikFieldConfiguration[] = convertComponentSpecsFieldsMap(
    componentSpecsFieldMap,
  );

  //ideal would be to use the category object and look at major category
  const showVariantFrameDimensionsFields =
    !skipFrameDimensions &&
    [...CATEGORIES_GROUPED_BY_MAJOR.BIKES, ...CATEGORIES_GROUPED_BY_MAJOR.FRAMES].includes(
      categoryId,
    );

  const frameDimensionsFields: FormikFieldConfiguration[] = showVariantFrameDimensionsFields
    ? buildFrameDimensionsFields(productStore)
    : [];

  const singleItemProcurementFields: FormikFieldConfiguration[] = editing
    ? buildEditItemProcurementFields(initialSingleItemProcurement, categoryId)
    : [];

  return [
    ...formikFieldConfig,
    ...componentSpecsFields,
    ...frameDimensionsFields,
    ...singleItemProcurementFields,
  ];
};
