import React from 'react';
import { ListItemText, makeStyles, createStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

type CreatedEntityProps = {
  link: string;
  linkText: string;
  title: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    horizontal: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

const CreatedEntity: React.FC<CreatedEntityProps> = ({
  link,
  linkText,
  title,
}: CreatedEntityProps) => {
  const classes = useStyles();
  return (
    <ListItemText
      className={classes.horizontal}
      primary={title}
      secondary={<RouterLink to={link}>{linkText}</RouterLink>}
    />
  );
};

export default CreatedEntity;
