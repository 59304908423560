import React from 'react';
import { ImageZoom } from '../ImageZoom/ImageZoom';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export const imageIsReactElement = (image: React.ReactNode): image is React.ReactElement =>
  !!image &&
  typeof image !== 'string' &&
  typeof image !== 'number' &&
  typeof image !== 'boolean' &&
  'props' in image;

const getClickableImg = (
  imageSource: string,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  return (
    <div className={'carousel-modal-wrapper'}>
      <img src={imageSource} className={'carousel-zoom-img'} />
      <div
        className={'modal-click'}
        onClick={() => {
          setIsModalOpen(true);
        }}
      />
    </div>
  );
};

export const getImageIndex = (
  currentIndex: number,
  setImageIndex?: React.Dispatch<React.SetStateAction<number>>,
) => {
  return (index: number) => {
    if (setImageIndex && index !== currentIndex) {
      setImageIndex(index);
    }
  };
};

export const getRenderImage = (
  zoomable?: boolean,
  showModal?: boolean,
  isModalOpen?: boolean,
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>,
) => (image: React.ReactNode): JSX.Element | null => {
  if (!imageIsReactElement(image)) return null;

  const imageSource: string = image.props.children.props.src;

  if (zoomable && !showModal) {
    return <ImageZoom src={imageSource} zoomScale={1.6} className={'carousel-zoom-container'} />;
  } else if (showModal && !zoomable) {
    return setIsModalOpen && !isModalOpen ? (
      getClickableImg(imageSource, setIsModalOpen)
    ) : (
      <img src={imageSource} className={'carousel-no-zoom-img'} />
    );
  } else if (showModal && zoomable) {
    return setIsModalOpen && !isModalOpen ? (
      getClickableImg(imageSource, setIsModalOpen)
    ) : (
      <ImageZoom src={imageSource} zoomScale={2.5} className={'carousel-zoom-container'} />
    );
  } else {
    return <img src={imageSource} className={'carousel-no-zoom-img'} />;
  }
};

export const getRenderArrowPrev = (showArrows?: boolean, arrowSize?: number) => (
  decrementIndex: Function,
) => {
  if (showArrows) {
    return (
      <div
        className="arrow arrow--previous"
        onClick={() => {
          decrementIndex();
        }}
        style={{ width: arrowSize }}
      >
        <IconButton aria-label="view previous image">
          <ArrowBackIcon />
        </IconButton>
      </div>
    );
  } else {
    return null;
  }
};

export const getRenderArrowNext = (showArrows?: boolean, arrowSize?: number) => (
  incrementIndex: Function,
) => {
  if (showArrows) {
    return (
      <div
        className="arrow arrow--next"
        onClick={() => {
          incrementIndex();
        }}
        style={{ width: arrowSize }}
      >
        <IconButton aria-label="view next image">
          <ArrowForwardIcon />
        </IconButton>
      </div>
    );
  } else {
    return null;
  }
};

export const getFormatStatusText = (showStatus?: boolean) => (
  currentSlide: number,
  numSlides: number,
) => {
  if (showStatus) {
    return `${currentSlide} of ${numSlides}`;
  } else {
    return '';
  }
};
