import React, { FC, useState } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';

import { CycleTimingItem } from '../../../services/cycleTimings/types';

type CycleTimingItemsProps = {
  items: CycleTimingItem[];
  onRemoveItem: (id: number) => void;
  isTimingCompleted: boolean;
};

const getSubmissionLink = ({
  sku,
  partnerSubmissionId,
  sybSubmissionId,
  productId,
}: CycleTimingItem) => {
  if (partnerSubmissionId) {
    return (
      <span>
        Partner Submission:{' '}
        <Link to={`/tradeups/partner/${partnerSubmissionId}`} target="_blank">
          {partnerSubmissionId}
        </Link>
      </span>
    );
  } else if (sybSubmissionId) {
    return (
      <span>
        SYB Submission:{' '}
        <Link to={`/tradeups/submission/${sybSubmissionId}`} target="_blank">
          {sybSubmissionId}
        </Link>
      </span>
    );
  } else if (sku && productId) {
    return (
      <span>
        IC Product:{' '}
        <Link to={`/catalog/products/${productId}`} target="_blank">
          {productId}
        </Link>
      </span>
    );
  } else {
    return '';
  }
};

export const CycleTimingItems: FC<CycleTimingItemsProps> = ({
  items,
  onRemoveItem,
  isTimingCompleted,
}) => {
  const [dialogItem, setDialogItem] = useState<CycleTimingItem | undefined>();

  const handleClose = () => {
    setDialogItem(undefined);
  };

  const onDialogSubmit = () => {
    onRemoveItem && dialogItem && onRemoveItem(dialogItem.id);
    setDialogItem(undefined);
  };

  const disableRemoveItems = items.length <= 1 || isTimingCompleted;

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                <TableCell style={{ width: '20%' }} size="small">
                  {item.sku || item.sybSubmissionId || item.partnerSubmissionId}
                </TableCell>
                <TableCell align="left">{item.title}</TableCell>
                <TableCell align="right">{getSubmissionLink(item)}</TableCell>
                <TableCell align="right">
                  <IconButton aria-label="remove" onClick={() => setDialogItem(item)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!dialogItem && (
        <Dialog open={!!dialogItem} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Remove item from Timing</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove:
              <h4>
                {dialogItem?.sku || dialogItem?.sybSubmissionId || dialogItem?.partnerSubmissionId}{' '}
                -- {dialogItem?.title}
              </h4>
              {disableRemoveItems && (
                <h5>
                  Note: We can not remove all items from a timing and can not remove items from a
                  completed timing.
                </h5>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <Button
              onClick={onDialogSubmit}
              color="secondary"
              variant="contained"
              disabled={disableRemoveItems}
            >
              Remove Item
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
