import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  info: {
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const itemRows = [
  {
    id: 'subtotal',
    accessor: obj => (obj && obj.subtotal ? obj.subtotal.toString() : 'Not Available'),
    label: 'Subtotal',
  },
  {
    id: 'discounted_price',
    accessor: obj =>
      obj && obj.discounted_price ? obj.discounted_price.toString() : 'Not Available',
    label: 'Discounted Price',
  },
  {
    id: 'discounted_total_price',
    accessor: obj =>
      obj && obj.discounted_total_price ? obj.discounted_total_price.toString() : 'Not Available',
    label: 'Discounted Total Price',
  },
  {
    id: 'total_cart_discount_amount',
    accessor: obj =>
      obj && obj.total_cart_discount_amount
        ? obj.total_cart_discount_amount.toString()
        : 'Not Available',
    label: 'Total Cart Discount Amount',
  },
];

export default function ProcessDialog({
  onClose,
  onChange,
  onSubmit,
  open,
  formData: { refundedAmount, refundType, error },
  shopifyCalculation: { itemInfo, shippingInfo, transactionInfo },
}) {
  const classes = useStyles();

  return (
    <div className={classes.form}>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Process</DialogTitle>
        <DialogContent>
          <Typography>Item</Typography>
          <Paper className={classes.info}>
            <Grid
              container
              spacing={0}
              className={classes.section}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              {itemRows.map(k => {
                return (
                  <Grid container spacing={2} className={classes.tableRow} key={k.id}>
                    <Grid item xs={5}>
                      <Typography>
                        <strong>{k.label}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>
                        {typeof k.accessor === 'string' || typeof k.accessor === 'undefined'
                          ? `${itemInfo[k.id]}`
                          : `${k.accessor(itemInfo)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid container spacing={2} className={classes.tableRow}>
                <Grid item xs={5}>
                  <Typography>
                    <strong>Max Refundable Shipipng</strong>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography>${shippingInfo.maximum_refundable || 0.0}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.tableRow}>
                <Grid item xs={5}>
                  <Typography>
                    <strong>Max Refundable Order</strong>
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography>${transactionInfo.maximum_refundable || 0.0}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              autoFocus
              value={refundType}
              onChange={onChange}
              inputProps={{
                name: 'refundType',
              }}
              fullWidth
            >
              <MenuItem value="" />
              <MenuItem value="original">Original</MenuItem>
              <MenuItem value="store_credit">Gift Card</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              margin="dense"
              name="refundedAmount"
              label="Refund Amount"
              type="number"
              value={refundedAmount}
              onChange={onChange}
              fullWidth
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
        {error && <DialogContentText color="error">{error}</DialogContentText>}
      </Dialog>
    </div>
  );
}
