import React, { FC, useState } from 'react';
import { TextField, FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Props {
  labelKey: string;
  options: any[];
  handleChange: any;
  placeholder: string;
  name?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  value?: any;
  label?: string;
  errorOnBlur?: boolean;
}
type InputProps = Props;
const AutoCompleteField: FC<InputProps> = ({
  labelKey,
  options,
  handleChange,
  placeholder,
  autoFocus,
  name,
  disabled,
  label,
  value,
  errorOnBlur,
}) => {
  const [error, setError] = useState(false);
  const handleBlur = (e: any) => {
    const { value } = e.target;
    if (!value && value != '0') {
      setError(true);
    } else setError(false);
  };

  //Todo: Generalize styling here for form controls with styled components
  return (
    <FormControl style={{ width: 275, margin: 5 }}>
      <label>{label}</label>
      <Autocomplete
        options={options}
        getOptionSelected={() => true}
        getOptionLabel={option => option[labelKey]?.toString()}
        style={{ backgroundColor: '#fff' }}
        disabled={disabled}
        autoHighlight
        onBlur={errorOnBlur ? handleBlur : undefined}
        onChange={(event, newValue) => {
          handleChange(event.target, newValue, name);
        }}
        value={value}
        renderInput={params => (
          <TextField
            {...params}
            error={error}
            placeholder={placeholder}
            variant="outlined"
            autoFocus={autoFocus || false}
          />
        )}
      />
    </FormControl>
  );
};
export default AutoCompleteField;
