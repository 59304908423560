import React, { ReactChild, ReactChildren } from 'react';
import { default as BaseButton, ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { Theme as MuiTheme, makeStyles } from '@material-ui/core/styles';

// todo: this isn't strictly ordinality anymore, better name?
type ButtonOrdinality = 'primary' | 'secondary' | 'warning';
enum ButtonSize {
  small = 1,
  medium = 2,
  large = 3,
}

interface StyleProps {
  size: keyof typeof ButtonSize;
}
const getSharedStyles = (theme: MuiTheme) => ({
  height: ({ size }: StyleProps) => theme.spacing(ButtonSize[size] + 2.5),
  paddingLeft: ({ size }: StyleProps) => theme.spacing(ButtonSize[size] + 0.5),
  paddingRight: ({ size }: StyleProps) => theme.spacing(ButtonSize[size] + 0.5),
  fontSize: ({ size }: StyleProps) => 12 + ButtonSize[size],
  minWidth: 'max-content',
});
const containedStyles = makeStyles(theme => ({
  primary: {
    ...getSharedStyles(theme),
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  secondary: {
    ...getSharedStyles(theme),
    background: theme.palette.common.black,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.grey[700],
    },
  },
  warning: {
    ...getSharedStyles(theme),
    background: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.error.light,
    },
  },
}));
const sharedOutlinedStyles = {
  background: `rgba(0,0,0,0)`,
  borderWidth: 3,
};

// #xxxxxx + alpha channel xx
// eg. transparentize('#ffffff', .5) === '#ffffff80'
//copied from client/src/theme.ts as `transparentize` is no longer part of the MuiTheme interface in 4.12
const transparentize = (color: string, pct: number) => {
  if (pct < 0 || pct > 1 || color.length !== 7) {
    return color;
  }
  const opacity = parseInt((pct * 255).toFixed(0), 10).toString(16);
  return opacity.length === 2 ? `${color}${opacity}` : color;
};

const outlinedStyles = makeStyles(theme => ({
  primary: {
    ...getSharedStyles(theme),
    ...sharedOutlinedStyles,
    color: theme.palette.primary.main,
    '&:hover': {
      background: transparentize(theme.palette.primary.main, 0.15),
    },
    borderColor: transparentize(theme.palette.primary.main, 0.67),
  },
  secondary: {
    ...getSharedStyles(theme),
    ...sharedOutlinedStyles,
    color: theme.palette.common.black,
    '&:hover': {
      background: transparentize(theme.palette.common.black, 0.15),
    },
    borderColor: transparentize(theme.palette.common.black, 0.5),
  },
  warning: {
    ...getSharedStyles(theme),
    ...sharedOutlinedStyles,
    color: theme.palette.error.main,
    '&:hover': {
      background: transparentize(theme.palette.error.main, 0.15),
    },
    borderColor: transparentize(theme.palette.error.main, 0.67),
  },
}));
const textStyles = makeStyles(theme => ({
  primary: {
    ...getSharedStyles(theme),
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    '&:hover': {
      background: transparentize(theme.palette.primary.main, 0.15),
    },
  },
  secondary: {
    ...getSharedStyles(theme),
    padding: theme.spacing(2),
    color: theme.palette.common.black,
    '&:hover': {
      background: transparentize(theme.palette.secondary.main, 0.15),
    },
  },
  warning: {
    ...getSharedStyles(theme),
    padding: theme.spacing(2),
    color: theme.palette.error.main,
    '&:hover': {
      background: transparentize(theme.palette.error.main, 0.15),
    },
  },
}));

interface CustomButtonProps {
  ordinality: ButtonOrdinality;
  children: ReactChild | ReactChildren;
  size?: keyof typeof ButtonSize;
}

export type ButtonProps = MuiButtonProps & CustomButtonProps;

export const Button: React.FC<ButtonProps> = ({
  variant = 'contained',
  size = 'medium',
  ordinality,
  children,
  ...muiProps
}) => {
  let classes: any;

  switch (variant) {
    case 'contained':
      classes = containedStyles({ size });
      break;
    case 'outlined':
      classes = outlinedStyles({ size });
      break;
    case 'text':
      classes = textStyles({ size });
      break;
    default:
      throw new Error(`Button received an invalid variant value: ${variant}`);
  }

  return (
    <BaseButton
      variant={variant}
      classes={{
        root: classes[ordinality],
      }}
      TouchRippleProps={{
        style: { borderRadius: 0 },
      }}
      disableElevation={variant !== 'contained'}
      {...muiProps}
    >
      {children}
    </BaseButton>
  );
};
