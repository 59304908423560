import React, { useState } from 'react';
import { cycleTimingsContext } from './cycleTimingsContext';

interface CycleTimingsProviderProps {
  children: React.ReactNode;
}

export const CycleTimingsProvider: React.FC<CycleTimingsProviderProps> = ({ children }) => {
  const [cycleTimingSku, setCycleTimingSku] = useState<string>('');
  const [cycleTimingSubId, setCycleTimingSubId] = useState<string>('');

  return (
    <cycleTimingsContext.Provider
      value={{
        cycleTimingSku,
        setCycleTimingSku,
        cycleTimingSubId,
        setCycleTimingSubId,
      }}
    >
      {children}
    </cycleTimingsContext.Provider>
  );
};
