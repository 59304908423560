import React from 'react';
import ProductVariantStatusFlag from '../ProductVariantStatusFlag';

const StatusFlagOption: React.FC<{
  id: number | null;
  status: string | null;
}> = ({ id, status }) => {
  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <ProductVariantStatusFlag value={id} />
      <span style={{ marginLeft: '1rem' }}>{status}</span>
    </div>
  );
};

export default StatusFlagOption;
