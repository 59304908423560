import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function ProcessDialog({
  onClose,
  onChange,
  onSubmit,
  open,
  formData: { refundedAmount, error },
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Inspection</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              margin="dense"
              name="refundedAmount"
              label="Refund Amount"
              type="number"
              value={refundedAmount}
              onChange={onChange}
              fullWidth
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Create
          </Button>
        </DialogActions>
        {error && <DialogContentText color="error">{error}</DialogContentText>}
      </Dialog>
    </div>
  );
}
