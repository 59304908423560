import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import {
  Button,
  Modal,
  SmallModalContents,
  ModalBody,
  ModalHeader,
  TextField,
  Option,
  ErrorMessage,
} from '../../../../components/library';
import { useRelocate, OPERATIONS_LOCATION_ID } from '../../../catalog/utils/useRelocate';
import { Bin, InspectionStatusEnum } from '../../../../services/service/types';
import parseYupErrors from '../../../../utils/parseYupErrors';
import { API_URL, netsuiteFields, SubmissionStatusIds } from '../../../../constants';
import axios from '../../../../utils/axios';
import { INSPECTION_SEARCH_ROUTE } from '../InspectionSearchView';
import { serviceTicketContext, shouldUpdateSubmissionStatus } from '../_shared';
import { BinAutocomplete } from '../../../../components/service/BinAutocomplete';
import { pageStateContext } from '../../../_shared';
import { updateInspection } from '../../../../services/service/services';
import { useInventoryRecords } from '../_shared/hooks/useInventoryRecords';
import { InventoryRecord } from '../_shared/hooks/useInventoryRecords';
import { AutoBins } from './types';
import { shouldApplyAutoBin } from './RelocateModal.utils';

const HeaderSku = styled.span`
  font-weight: bold;
`;

interface ValidationErrors {
  [key: string]: string;
}
interface RelocateModalProps {
  open: boolean;
  handleClose: () => any;
  handleSuccess: () => void;
  inventoryRecords?: InventoryRecord[];
}

const formSchema = yup.object().shape({
  status: yup.number().required('Please select a new status for this inspection.'),
  bin: yup.object().required('Please select a target bin for this item.'),
});

const RECV_BIN = 'RECVHOLD1';
const INTAKE_BIN = 'BUF-INTAKE-1';
const WASH_BIN = 'BUF-WASH-1';
const SW_BIN = 'BUF-SW-1';
const CONS_BIN = 'BUF-CONS-1';
const LINE_BIN = 'BUF-LINE-1';
const OUTREACH_BIN = 'BUF-KO-OUTREACH';

const AUTO_BINS: AutoBins = [
  {
    currentStatus: InspectionStatusEnum['Intake Started'],
    updatedStatus: InspectionStatusEnum['Intake Started'],
    currentBin: INTAKE_BIN,
    updatedBin: WASH_BIN,
  },
  {
    currentStatus: InspectionStatusEnum['Intake Started'],
    updatedStatus: InspectionStatusEnum['Intake Started'],
    currentBin: RECV_BIN,
    updatedBin: WASH_BIN,
  },
  {
    currentStatus: InspectionStatusEnum['Intake Started'],
    updatedStatus: InspectionStatusEnum['Ready for Inspection'],
    currentBin: WASH_BIN,
    updatedBin: SW_BIN,
  },
  {
    currentStatus: InspectionStatusEnum['Inspection Started'],
    selectedStatus: InspectionStatusEnum['Ready for Parts'],
    updatedBin: CONS_BIN,
  },
  {
    currentStatus: InspectionStatusEnum['Inspection Started'],
    selectedStatus: InspectionStatusEnum['Ready for Line'],
    updatedBin: LINE_BIN,
  },
  {
    currentStatus: InspectionStatusEnum['Rejected'],
    updatedStatus: InspectionStatusEnum['Rejected'],
    updatedBin: OUTREACH_BIN,
  },
];

export const RelocateModal: React.FC<RelocateModalProps> = ({
  open,
  handleClose,
  handleSuccess,
  inventoryRecords,
}) => {
  const {
    errorBus,
    loadingBus: { setLoading },
  } = useContext(pageStateContext);
  const {
    inspectionData: { inspection },
    inspectionStatuses: contextInspectionStatuses,
  } = useContext(serviceTicketContext);

  const {
    errorBus: { setError: setSnackbarError, clearAllErrors: clearSnackbarErrors },
    showSuccess,
    setSuccessMessage,
  } = useContext(pageStateContext);

  const { refetchInventoryRecords } = useInventoryRecords({
    sku: inspection?.sku,
    errorBus,
  });

  const [statusId, setStatusId] = useState<number | undefined>(undefined);
  const [binTypeahead, setBinTypeahead] = useState<string>('');
  const [bin, setBin] = useState<Bin | undefined>(undefined);
  const [wipStateId, setWipStateId] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [error, setError] = useState<string>();
  const [inspectionStatuses, setInspectionStatuses] = useState<Option[]>();

  const history = useHistory();

  useEffect(() => {
    if (contextInspectionStatuses) {
      setInspectionStatuses(
        contextInspectionStatuses.map(status => ({ label: status.name, value: status.id })),
      );
    }
  }, [contextInspectionStatuses]);

  const { binsForLocation = [], wipStates = [], error: relocateHookError } = useRelocate({
    typeaheadVal: binTypeahead,
    locationId: OPERATIONS_LOCATION_ID,
  });

  const validate = async () => {
    let isValid = true;
    try {
      await formSchema.validate(
        { status: statusId, bin },
        { abortEarly: false, context: { binsForLocation } },
      );
    } catch (err) {
      isValid = false;
      const newErrors = parseYupErrors(err, { status: 'statusId' });
      setValidationErrors(newErrors);
    } finally {
      return isValid;
    }
  };

  useEffect(() => {
    AUTO_BINS.some(autoBin => {
      if (
        shouldApplyAutoBin(autoBin, inspection?.statusId, inventoryRecords?.[0]?.binCode, statusId)
      ) {
        if (autoBin.updatedStatus && !statusId) {
          setStatusId(autoBin.updatedStatus);
        }
        if (autoBin.updatedBin) {
          setBinTypeahead(autoBin.updatedBin);
        }
        return true;
      }
      return false;
    });
  }, [inspection, statusId, inventoryRecords]);

  useEffect(() => {
    binsForLocation.length === 1 && setBin(binsForLocation[0]);
  }, [binsForLocation]);

  if (!inspection) {
    return null;
  }

  return (
    <Modal open={open} handleClose={handleClose} label="relocate-modal">
      <SmallModalContents>
        <ModalHeader>
          Update location for <HeaderSku>{inspection.sku}</HeaderSku>
        </ModalHeader>
        <ModalBody>
          {relocateHookError && <ErrorMessage error={relocateHookError} />}
          {error && <ErrorMessage error={error} />}
          <TextField
            id="inspectionRelocateModalStatus"
            value={statusId || ''}
            onChange={e => setStatusId(parseInt(e.target.value))}
            placeholder="Status"
            label="Status"
            variant="outlined"
            options={inspectionStatuses}
            error={!!validationErrors.statusId}
            helperText={validationErrors.statusId}
          />
          <BinAutocomplete
            style={{ width: '100%' }}
            id="SKURelocateModalBin"
            selectedBin={bin}
            options={binsForLocation}
            typeaheadVal={binTypeahead}
            onTypeaheadChange={setBinTypeahead}
            onBinChange={setBin}
            errorMsg={validationErrors.bin}
          />
          <TextField
            id="inspectionRelocateModalWIPState"
            value={wipStateId || ''}
            onChange={e => setWipStateId(e.target.value)}
            placeholder="WIP State"
            label="WIP State"
            variant="outlined"
            options={wipStates.map((obj: any) => ({ label: obj.value, value: obj.id }))}
            error={!!validationErrors.wipState}
            helperText={validationErrors.wipState}
          />
          <Button
            size="large"
            ordinality="primary"
            type="submit"
            onClick={async () => {
              // successful validation means bin and statusId are defined, but TS can't infer that
              if ((await validate()) && !!bin && !!statusId) {
                try {
                  setLoading('RelocateModal', true);

                  // update WIP state
                  if (wipStateId) {
                    await axios.post(`${API_URL}/service/updateItem`, {
                      sku: inspection.sku,
                      fieldId: netsuiteFields.WIPSTATE,
                      value: wipStateId,
                    });
                  }

                  // relocate
                  await axios.post(`${API_URL}/service/relocate`, {
                    sku: inspection.sku,
                    toLocationId: OPERATIONS_LOCATION_ID,
                    toBin: bin.code,
                  });

                  // update inspection status
                  await updateInspection(inspection.id, { statusId });

                  const performSubmissionUpdate: boolean = await shouldUpdateSubmissionStatus(
                    statusId,
                    inspection?.itemProcurement?.sybSubmissionId,
                  );
                  if (performSubmissionUpdate) {
                    const response = await axios.put(`${API_URL}/tradeup/syb/submission`, {
                      submissionId: inspection?.itemProcurement?.sybSubmissionId,
                      statusId: SubmissionStatusIds.COMPLETED,
                    });
                    if (response) {
                      if (response.status === 200) {
                        setSuccessMessage(
                          'The service plan was saved and relocated, and the submission was completed.',
                        );
                        refetchInventoryRecords();
                        showSuccess();
                      } else {
                        setSnackbarError(
                          'submissionCompletion',
                          new Error(
                            'The service plan was saved and relocated, but there was an issue completing the submission.',
                          ),
                        );
                      }
                    }
                  } else {
                    setSuccessMessage('The service plan was saved and relocated.');
                    showSuccess();
                  }
                  clearSnackbarErrors();
                  handleSuccess();

                  const relocatedFromInspectionStarted =
                    inspection.statusId === InspectionStatusEnum['Inspection Started'] &&
                    statusId !== InspectionStatusEnum['Inspection Started'];
                  const relocatedFromIntake =
                    inspection.statusId === InspectionStatusEnum['Intake Started'];
                  const relocatedFromNonSW = [
                    InspectionStatusEnum['Ready for Master Tech'],
                    InspectionStatusEnum['Ready for Line'],
                    InspectionStatusEnum['Ready for Parts'],
                  ].includes(inspection.statusId);

                  if (relocatedFromInspectionStarted) {
                    setTimeout(() => history.push(INSPECTION_SEARCH_ROUTE), 1000);
                  } else if (relocatedFromNonSW) {
                    setTimeout(() => history.push(INSPECTION_SEARCH_ROUTE), 2000);
                  } else if (relocatedFromIntake) {
                    if (bin.code === WASH_BIN || bin.code === SW_BIN) {
                      setTimeout(() => history.push(INSPECTION_SEARCH_ROUTE), 1000);
                    }
                  }
                } catch (err) {
                  setError(err.response ? err.response.data.message : 'An unknown error occurred');
                } finally {
                  setLoading('RelocateModal', false);
                }
              }
            }}
          >
            Relocate
          </Button>
        </ModalBody>
      </SmallModalContents>
    </Modal>
  );
};
