import { combineReducers } from 'redux';
import authReducer from './auth-reducer';
import customerListReducer from './customer_list-reducer';
import c360ListReducer from './customer360_list-reducer';
import purchaseListReducer from './purchase_list-reducer';
import productListReducer from './product_list-reducer';
import tradeupReducer from './tradeup_list-reducer';
import tagListReducer from './tag_list-reducer';
import serviceStationReducer from './service_station-reducer';
import serviceWorkerReducer from './service_worker-reducer';
import inspectionPartsReducer from './inspection_parts-reducer';
import catchReleaseReducer from './catch_release-reducer';
import productReducer from './product-reducer';
import catalogSearchProductsReducer from './catalogSearchProductsReducer';
import catalogProductImagesReducer from './catalogProductImagesReducer';
import navReducer from './navReducer';
import inspectionsSearchReducer from './inspectionsSearchReducer';
import { LOGOUT } from '../actions/types';

const appReducer = combineReducers({
  auth: authReducer,
  customerList: customerListReducer,
  c360List: c360ListReducer,
  purchaseList: purchaseListReducer,
  productList: productListReducer,
  tradeupList: tradeupReducer,
  tagList: tagListReducer,
  station: serviceStationReducer,
  serviceWorker: serviceWorkerReducer,
  inspectionParts: inspectionPartsReducer,
  nav: navReducer,
  catchReleaseList: catchReleaseReducer,
  product: productReducer,
  catalogSearchProducts: catalogSearchProductsReducer,
  productImages: catalogProductImagesReducer,
  inspectionsSearch: inspectionsSearchReducer,
});

const rootReducer = (state, action) => {
  let nState = action.type === LOGOUT ? undefined : state;
  return appReducer(nState, action);
};

export default rootReducer;
