import React, { FC } from 'react';
import styles from '../../styledComponents/';
import ChangePassword from './changePassword';
const { PageWrapper, PageHeader } = styles;
const AccountHome: FC = () => {
  return (
    <PageWrapper>
      <PageHeader>Account</PageHeader>
      <ChangePassword></ChangePassword>
    </PageWrapper>
  );
};

export default AccountHome;
