import React, { useContext, useState, useMemo, useCallback } from 'react';
import { Formik, FormikProps, FormikErrors } from 'formik';
import { StaticPoints } from './StaticPoints';
import { EditablePoints } from './EditablePoints';
import { serviceTicketContext } from '../../../_shared';
import { ValidationErrorModal } from '../../../ValidationErrorModal';
import { FormikPointsState, FormikPointsErrorKeys, Grades } from '../../../_shared/types';
import { savePoints } from '../service';
import { Lookup } from '../../../_shared';
import { pageStateContext } from '../../../../../_shared';
import { ModuleWrapper } from '../../styled';
import { NotesTextarea } from '../NotesTextarea';

type PointPropertyPrettyName = {
  [key in FormikPointsErrorKeys]: string;
};
const pointPropertyPrettyNames: PointPropertyPrettyName = {
  gradeId: 'Grade',
  failRemedyId: 'Fail Remedy',
  note: 'Note',
};

const handleValidatePoints = (
  points: FormikPointsState['points'],
  pointTypeLookup: Lookup,
  handleValidationError: () => void,
) => {
  const errors: FormikErrors<FormikPointsState> = {};
  const pointsArray = Object.values(points);
  for (const point of pointsArray) {
    // currently we only fail validation in this one case, but
    // the type suports potential future validation of other fields
    if (point.gradeId === Grades.fail && !point.failRemedyId) {
      if (!errors.points) errors.points = {};
      errors.points[point.inspectionPointTypeId] = {
        // todo: see if there's a way to convince formik that these errors can be objects with
        // a property for both these values rather than string-parsing based on '|' delimiter
        failRemedyId: `${pointPropertyPrettyNames['failRemedyId']}|${
          pointTypeLookup[point.inspectionPointTypeId]
        }`,
      };
    }
  }
  if (!!errors.points) {
    handleValidationError();
  }
  return errors;
};

interface PointsModuleProps {
  editing: boolean;
  readOnly: boolean;
  hasUnsavedData: boolean;
  handleEditPoints(): void;
  handleSuccess(): void;
  handleDataChange(dirty: boolean): void;
}

export const PointsModule = React.forwardRef<FormikProps<FormikPointsState>, PointsModuleProps>(
  (
    { editing, readOnly, hasUnsavedData, handleEditPoints, handleSuccess, handleDataChange },
    ref,
  ) => {
    const {
      errorBus: { setError },
      loadingBus: { setLoading },
    } = useContext(pageStateContext);
    const { inspectionData } = useContext(serviceTicketContext);
    const {
      inspection,
      categorizedPoints,
      pointTypeLookup,
      pointTypeCategoryLookup,
    } = inspectionData;
    const [validationErrorModalOpen, setValidationErrorModalOpen] = useState<boolean>(false);

    const initialPointsValues: FormikPointsState = useMemo(() => {
      if (!inspection) {
        return { points: {}, notes: null };
      }
      return {
        points: Object.fromEntries(
          inspection.inspectionPoints.map(point => [point.inspectionPointTypeId, point]),
        ),
        notes: inspection.notes,
      };
    }, [inspection]);

    const validatePoints = useCallback(
      (values: FormikPointsState) => {
        if (!pointTypeLookup) throw new Error('cannot validate points without pointTypeLookup');
        return handleValidatePoints(values.points, pointTypeLookup, () =>
          setValidationErrorModalOpen(true),
        );
      },
      [pointTypeLookup, setValidationErrorModalOpen],
    );

    const handlePointsSave = useCallback(
      (values: FormikPointsState) => {
        if (inspection) {
          savePoints(
            {
              values,
              inspectionId: inspection.id,
              statusId: inspection.statusId,
              hasUnsavedData,
            },
            {
              setLoading: (loading: boolean) => setLoading('handlePointsSave', loading),
              setError: (error: Error | undefined) => setError('handlePointsSave', error),
              handleSuccess,
            },
          );
        }
      },
      [inspection, setLoading, setError, handleSuccess, hasUnsavedData],
    );

    if (!inspection || !categorizedPoints || !pointTypeLookup || !pointTypeCategoryLookup) {
      return null;
    }

    if (readOnly) {
      return (
        <ModuleWrapper>
          <StaticPoints
            readOnly
            inspectionPoints={inspection.inspectionPoints}
            pointTypeLookup={pointTypeLookup}
            pointTypeCategoryLookup={pointTypeCategoryLookup}
          />
        </ModuleWrapper>
      );
    }

    return (
      <Formik<FormikPointsState>
        innerRef={ref}
        initialValues={initialPointsValues}
        enableReinitialize
        onSubmit={handlePointsSave}
        validate={validatePoints}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => (
          <form onSubmit={handleSubmit}>
            {editing ? (
              <>
                <ModuleWrapper>
                  <EditablePoints
                    categorizedPoints={categorizedPoints}
                    handleDataChange={handleDataChange}
                  />
                  {validationErrorModalOpen && pointTypeLookup && (
                    <ValidationErrorModal
                      open={validationErrorModalOpen}
                      handleClose={() => setValidationErrorModalOpen(false)}
                      errors={(errors.points || {}) as any}
                      // todo: sort out any
                    />
                  )}
                </ModuleWrapper>
                <ModuleWrapper>
                  <NotesTextarea
                    value={values['notes']}
                    setValue={e => setFieldValue('notes', e.target.value)}
                  />
                </ModuleWrapper>
              </>
            ) : (
              <ModuleWrapper>
                <StaticPoints
                  readOnly={false}
                  inspectionPoints={inspection.inspectionPoints}
                  pointTypeLookup={pointTypeLookup}
                  pointTypeCategoryLookup={pointTypeCategoryLookup}
                  handleEditPoints={handleEditPoints}
                />
              </ModuleWrapper>
            )}
          </form>
        )}
      </Formik>
    );
  },
);
