/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { StripedDataGrid } from '../../../styledComponents/datagrid';
import { GridColDef } from '@mui/x-data-grid';
import { Modal } from '../../../components/library';
import { Box, IconButton } from '@material-ui/core';
import { CircledXIcon } from '../../service/serviceTicket/CircledXIcon';

const ImageHistoryTable = (props: any) => {
  const [imageModalSrc, setImageModalSrc] = useState<string | undefined>();

  const columns: GridColDef[] = [
    {
      headerName: 'Image ID',
      field: 'imageId',
      flex: 1,
    },
    {
      headerName: 'Thumbnail',
      field: 'url',
      flex: 2,
      renderCell: ({ value }) => (
        <img
          src={value}
          id={value}
          alt={value}
          onClick={() => setImageModalSrc(value as string)}
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'contain',
            cursor: 'zoom-in',
          }}
        />
      ),
    },
    {
      headerName: 'Type',
      field: 'type',
      flex: 1,
    },
    {
      headerName: 'Active',
      field: 'active',
      flex: 1,
      valueFormatter: ({ value }) => JSON.stringify(value),
    },
    {
      headerName: 'Created',
      field: 'createdAt',
      flex: 2,
      valueFormatter: ({ value }) =>
        new Date(value).toLocaleString('en-US', { timeZone: 'America/Denver' }),
    },
  ];

  return (
    <>
      <StripedDataGrid
        rows={props.history}
        rowHeight={88}
        columns={columns}
        // onRowClick={params => window.open(params.row.url, '_blank')}
      />
      {!!imageModalSrc && (
        <Modal label="view-image" open={true} handleClose={() => setImageModalSrc(undefined)}>
          <Box position="absolute" right={0} top={0} pt={0} pr={0} mt={0} mr={0}>
            <IconButton color="primary" onClick={() => setImageModalSrc(undefined)}>
              <CircledXIcon />
            </IconButton>
          </Box>
          <img alt="view large image" className="modal-image" src={imageModalSrc} />
        </Modal>
      )}
    </>
  );
};

export default ImageHistoryTable;
