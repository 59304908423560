import styled from 'styled-components';
import { DataGrid } from '../components/library/DataGrid';
import { gridClasses } from '@mui/x-data-grid';
import { alpha } from '@material-ui/core';

const HOVER_OPACITY = 0.1;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: `${alpha(theme.palette.primary.main, HOVER_OPACITY)} !important`,
    },
    '&.Mui-selected': {
      backgroundColor: `${alpha(
        theme.palette.info.main,
        theme.palette.action.selectedOpacity,
      )} !important`,
      '&:hover, &.Mui-hovered': {
        backgroundColor: `${alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
        )} !important`,
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
}));

export { StripedDataGrid };
