import * as service from '../services/catalog/services';
import { Dispatch } from 'redux';
import * as actions from './index';
import { RestResponse } from '../services/rest-utils';

export const getInitialItemCatalogDataOneDispatch = () => (dispatch: Dispatch) => {
  //Using Promise.allSettled so that if one of these fails and the rest succeed,
  //we still dispatch the ones that succeed
  Promise.allSettled([
    service.fetchBrands(),
    service.fetchProductCategories(),
    service.fetchDisciplines(),
    service.fetchPipelines(),
    service.fetchStatusFlags(),
    service.fetchSizeClasses(),
    service.fetchProductSubCategories(),
    service.fetchFrameDimensions(),
    service.fetchCatalogConfiguration(),
    service.fetchAttributeTypes(),
    service.fetchCatalogFieldConfigs(),
  ]).then(
    ([
      brandsResult,
      productCategoriesResult,
      disciplinesResult,
      pipelinesResult,
      statusFlagsResult,
      sizeClassesResult,
      productSubCategoriesResult,
      frameDimensionsResult,
      catalogConfigurationResult,
      attributeTypesResult,
      catalogFieldConfigsResult,
    ]) => {
      const resultBodyIfOk = <T>(result: PromiseSettledResult<RestResponse<T>>): T | undefined => {
        return result.status == 'fulfilled' ? result.value.body : undefined;
      };
      dispatch(
        actions.setInitialItemCatalogData({
          brands: resultBodyIfOk(brandsResult),
          categories: resultBodyIfOk(productCategoriesResult),
          disciplines: resultBodyIfOk(disciplinesResult),
          pipelines: resultBodyIfOk(pipelinesResult),
          statusFlags: resultBodyIfOk(statusFlagsResult),
          sizeClasses: resultBodyIfOk(sizeClassesResult),
          productSubCategories: resultBodyIfOk(productSubCategoriesResult),
          frameDimensions: resultBodyIfOk(frameDimensionsResult),
          catalogConfiguration: resultBodyIfOk(catalogConfigurationResult),
          attributeTypes: resultBodyIfOk(attributeTypesResult),
          catalogFieldConfigs: resultBodyIfOk(catalogFieldConfigsResult),
        }),
      );
    },
  );
};
