import { SubmissionStatusIds } from '../../../../constants';
import { getSubmission } from '../../../../services/service/services';
import { PAYMENT_READY_SP_STATUSES } from '../../../../services/service/types';

export const shouldUpdateSubmissionStatus = async (
  inspectionStatusId: number,
  submissionId: number | null,
) => {
  if (!inspectionStatusId || !submissionId) {
    return false;
  }

  if (PAYMENT_READY_SP_STATUSES.includes(inspectionStatusId)) {
    const submission = await getSubmission(submissionId);

    if (submission && submission.data) {
      // if the submission is not already in a completed status
      if (submission.data.statusId !== SubmissionStatusIds.COMPLETED) {
        return true;
      }
    }
  }

  return false;
};
