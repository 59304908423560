import { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import {
  validateSubmissionSource,
  INVALID_SUB_SOURCE,
  SYB_SUB_SOURCE,
  PARTNER_SUB_SOURCE,
} from '../../catalog/utils';
import {
  createCycleTiming,
  searchForCycleTiming,
  upsertItemsToCycleTiming,
  updateCycleTiming,
  getCycleTimingStations,
  getCycleTimingById,
} from '../../../services/cycleTimings/services';
import {
  SearchOrCreateCycleTiming,
  CycleTiming,
  Status,
} from '../../../services/cycleTimings/types';

const handleTimingServiceErrors = (err: any) => {
  console.error(err);
  if (err?.response?.data?.message) {
    NotificationManager.error(
      err?.response?.data?.message,
      err?.response?.data?.name || 'Error',
      3000,
    );
  } else {
    NotificationManager.error('Unknown error please contact IT.', 'Error', 3000);
  }
};

const searchForExistingTiming = async (searchOrCreateTiming: SearchOrCreateCycleTiming) => {
  let existingTiming: CycleTiming | undefined;

  try {
    const existingTimingRes = await searchForCycleTiming(searchOrCreateTiming);
    if (existingTimingRes) {
      existingTiming = existingTimingRes.data;
    }
  } catch (err) {
    // only log errors if not found error
    if (err?.response?.data?.name !== 'NotFoundError') {
      handleTimingServiceErrors(err);
    }
  } finally {
    return existingTiming;
  }
};

/**
 *  Notes:
 * - submissions must start with an "S" or "P" so we know where the submission lives
 *   - "S" - SYB submission - DB: tup.submissions
 *   - "P" - Partner submission - DB: bd.submissions
 */
const checkAndSetupValidItem = ({
  submissionId,
  sku,
  station,
}: {
  submissionId?: string;
  sku?: string;
  station: string;
}) => {
  const searchOrCreateTimingItem: SearchOrCreateCycleTiming = { station };
  let isInvalidSubmission: true | undefined;

  // Setup search/payload - parse submission or sku
  if (submissionId) {
    const [source, validSubmissionID] = validateSubmissionSource(submissionId);
    if (source === INVALID_SUB_SOURCE) {
      isInvalidSubmission = true;
    } else {
      if (source === SYB_SUB_SOURCE) {
        searchOrCreateTimingItem.sybSubmissionId = validSubmissionID;
      } else if (source === PARTNER_SUB_SOURCE) {
        searchOrCreateTimingItem.partnerSubmissionId = validSubmissionID;
      }
    }
  } else if (sku) {
    searchOrCreateTimingItem.sku = sku;
  }
  return { isInvalidSubmission, searchOrCreateTimingItem };
};

type SetStateString = React.Dispatch<React.SetStateAction<string>>;
const setupTimingTabs = ({
  sku,
  setSku,
  submissionId,
  setSubmissionId,
}: {
  sku: string;
  setSku: SetStateString;
  submissionId: string;
  setSubmissionId: SetStateString;
}) => [
  {
    tabLabel: 'SKU',
    id: 'skuSearchBox',
    value: sku,
    variant: 'outlined',
    placeholder: 'Search by SKU',
    onChange: (e: any) => setSku(e.target.value),
    onInit: (val: string) => setSku(val),
  },
  {
    tabLabel: 'Submission ID',
    id: 'submissionSearchBox',
    value: submissionId,
    variant: 'outlined',
    placeholder: 'Submission e.g. s12345, p1234',
    onChange: (e: any) => setSubmissionId(e.target.value),
    onInit: (val: string) => setSubmissionId(val),
  },
];

type SearchedInput = {
  sku?: string;
  submissionId?: string;
};
type CycleTimingsRouteState = { station?: string };

export const useCycleTimings = () => {
  const location = useLocation<CycleTimingsRouteState>();
  const currentStation = location.state?.station;
  const [cycleTimingId, setCycleTimingId] = useState<number | undefined>();

  const previousCycleTimingIdUrlParamRef = useRef<number | null>();
  const previousCycleTimingIdUrlParam = previousCycleTimingIdUrlParamRef.current;
  useEffect(() => {
    previousCycleTimingIdUrlParamRef.current = cycleTimingId;
  });

  const [searchedSku, setSearchedSku] = useState<string>('');
  const [searchedSubmissionId, setSearchedSubmissionId] = useState<string>('');

  const [station, setStation] = useState<string>(currentStation || '');
  const [stations, setStations] = useState<{ id: string; description: string }[] | undefined>();
  const [submissionId, setSubmissionId] = useState<string>(searchedSubmissionId || '');
  const [sku, setSku] = useState<string>(searchedSku || '');

  const initialTab = !!searchedSku ? 0 : !!searchedSubmissionId ? 1 : 0;
  const [tabValue, setTabValue] = useState(initialTab);

  const [loading, setLoading] = useState(false);
  const [cycleTiming, setCycleTiming] = useState<CycleTiming | undefined>();

  const clearSearchValues = () => {
    setSubmissionId('');
    setSku('');
  };

  const clearCurrentTiming = useCallback(() => {
    setCycleTiming(undefined);
    setCycleTimingId(undefined);
  }, []);

  const updateCurrentCycleTiming = useCallback(
    ({
      updatedCycleTiming,
      updateHistory,
      searchedInput,
    }: {
      updatedCycleTiming: CycleTiming;
      updateHistory?: boolean;
      searchedInput?: SearchedInput;
    }) => {
      if (updateHistory) {
        // Pass along searched sku or submission.
        // Used to pre-populate input if user wants to start new timing
        if (searchedInput) {
          searchedInput.sku && setSearchedSku(searchedInput.sku);
          searchedInput.submissionId && setSearchedSubmissionId(searchedInput.submissionId);
        }
        setCycleTimingId(updatedCycleTiming.id);
      } else {
        setCycleTiming(updatedCycleTiming);
      }
    },
    [],
  );

  const currentCycleTimeId = cycleTiming?.id;
  const tryToLoadExistingTiming = useCallback(
    async (cycleTimingId: number) => {
      setLoading(true);
      try {
        const existingTimingRes = await getCycleTimingById(cycleTimingId);
        if (existingTimingRes && currentCycleTimeId !== existingTimingRes.data.id) {
          updateCurrentCycleTiming({ updatedCycleTiming: existingTimingRes.data });
          setStation(existingTimingRes.data.stationId);
        }
      } catch (err) {
        handleTimingServiceErrors(err);
      } finally {
        setLoading(false);
      }
    },
    [updateCurrentCycleTiming, currentCycleTimeId],
  );

  /**
   * Setup auto pause functionality:
   * - on tab/browser close
   */

  const currentCycleTimingStatus = cycleTiming?.status;
  useEffect(() => {
    const attemptToPause = async () => {
      try {
        if (
          currentCycleTimeId &&
          (currentCycleTimingStatus === Status.STARTED ||
            currentCycleTimingStatus === Status.PAUSED)
        ) {
          const successfulUpdate = await updateCycleTiming(currentCycleTimeId, {
            status: Status.PAUSED,
          });

          if (successfulUpdate && successfulUpdate.data.status === Status.PAUSED) {
            NotificationManager.warning(
              `Timing: ${currentCycleTimeId}`,
              'We paused your timer',
              2000,
            );
          }
        }
      } catch (err) {}
    };

    window.addEventListener('beforeunload', attemptToPause);

    return () => {
      window.removeEventListener('beforeunload', attemptToPause);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCycleTimeId, currentCycleTimingStatus]);

  // Retrieve existing cycle timing by id, also handle route change with ids
  useEffect(() => {
    if (!!cycleTimingId && cycleTimingId !== previousCycleTimingIdUrlParam) {
      tryToLoadExistingTiming(cycleTimingId);
    }
  }, [cycleTimingId, previousCycleTimingIdUrlParam, tryToLoadExistingTiming]);

  // Retrieve all stations
  useEffect(() => {
    const setupStations = async () => {
      try {
        const stationsRes = await getCycleTimingStations();
        if (stationsRes) {
          setStations(stationsRes.data);
        }
      } catch (err) {
        handleTimingServiceErrors(err);
      }
    };
    setupStations();
  }, []);

  const timingTabs = setupTimingTabs({ sku, setSku, submissionId, setSubmissionId });
  const onTabChange = (_: React.ChangeEvent<{}>, newTab: any) => {
    if (newTab !== tabValue) {
      // Clear values on tab change
      clearSearchValues();
      setTabValue(newTab);
    }
  };

  const addItemToCycleTiming = async (
    searchOrCreateTimingItem: SearchOrCreateCycleTiming,
    forceNewForExistingCompletedTiming?: boolean,
  ) => {
    if (!cycleTiming) return;
    setLoading(true);
    try {
      await upsertItemsToCycleTiming(
        cycleTiming.id,
        [...cycleTiming.items, searchOrCreateTimingItem],
        forceNewForExistingCompletedTiming,
      );
      const updatedTimingRes = await getCycleTimingById(cycleTiming.id);
      if (updatedTimingRes) {
        updateCurrentCycleTiming({ updatedCycleTiming: updatedTimingRes.data });
        NotificationManager.success('Item to timing', 'Added', 3000);
      }
    } catch (err) {
      handleTimingServiceErrors(err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Flows:
   * - Branch A - No currently selected/displayed timer ( start new timer )
   * - Branch B - Current timer selected/displayed ( add to paused or running timing )
   * - Branch C - Current timer completed and displayed ( start new timing )
   *
   * - B:1 - Check if a timing exists for the submitted submission/sku & station
   * - B:2 - If timing does not exist add submission/sku to existing timing
   */
  const onFormSubmitSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    const { isInvalidSubmission, searchOrCreateTimingItem } = checkAndSetupValidItem({
      submissionId,
      sku,
      station,
    });
    if (isInvalidSubmission) {
      NotificationManager.warning(
        'ID must a number and being with "S" or "P"',
        `Invalid submission ID`,
        2500,
      );
      return;
    }

    try {
      // Branch A - No currently selected/displayed timer ( start new timer )
      if (!cycleTiming) {
        setLoading(true);
        const existingTiming = await searchForExistingTiming(searchOrCreateTimingItem);
        if (existingTiming && existingTiming.status !== Status.COMPLETED) {
          setLoading(false);
          // Pass along searched sku or submission.
          // Used to pre-populate input if user wants to start new timing
          updateCurrentCycleTiming({
            updatedCycleTiming: existingTiming,
            updateHistory: true,
            searchedInput: { sku, submissionId },
          });
        } else {
          const newTimingRes = await createCycleTiming({
            ...searchOrCreateTimingItem,
            forceNewForExistingCompletedTiming: true,
          });
          setLoading(false);
          if (newTimingRes) {
            NotificationManager.success('Timing created', 'New', 3000);
            updateCurrentCycleTiming({
              updatedCycleTiming: newTimingRes.data,
              updateHistory: true,
            });
          }
        }
      } else if (!!cycleTiming && cycleTiming.status !== Status.COMPLETED) {
        // Branch B - Current timer selected/displayed ( add to paused or running timing )
        setLoading(true);
        const existingTiming = await searchForExistingTiming(searchOrCreateTimingItem);
        if (existingTiming && existingTiming.status !== Status.COMPLETED) {
          // B:1
          setLoading(false);
          NotificationManager.error(
            'Existing uncompleted timing for item, unable to add',
            'Found',
            3000,
          );
        } else {
          // B:2
          await addItemToCycleTiming(searchOrCreateTimingItem, true);
        }
      } else if (!!cycleTiming && cycleTiming.status === Status.COMPLETED) {
        // Branch C - Current timer completed and displayed ( start new timing )
        addItemToCycleTiming(searchOrCreateTimingItem, true);
      }
    } catch (err) {
      setLoading(false);
      handleTimingServiceErrors(err);
    }
  };

  const updateTimingStatus = async (status: Status) => {
    if (!cycleTiming?.id) return;
    setLoading(true);
    let successfullyCompletedAndReRouted = false;
    try {
      const updatedTimingRes = await updateCycleTiming(cycleTiming?.id, { status });
      if (updatedTimingRes) {
        // if we successfully completed a timing clear/reset the form
        if (updatedTimingRes.data.status === Status.COMPLETED) {
          NotificationManager.success('Successfully completed timing.', 'Completed', 2000);
          clearCurrentTiming();
          successfullyCompletedAndReRouted = true;
          setLoading(false);
        } else {
          updateCurrentCycleTiming({
            updatedCycleTiming: updatedTimingRes.data,
          });
        }
      }
    } catch (err) {
      handleTimingServiceErrors(err);
    }
    if (!successfullyCompletedAndReRouted) {
      setLoading(false);
    }
  };

  const updateTimingTraining = async (isTraining: boolean) => {
    if (!cycleTiming?.id) return;
    setLoading(true);
    try {
      const updatedTimingRes = await updateCycleTiming(cycleTiming?.id, { isTraining });
      if (updatedTimingRes) {
        updateCurrentCycleTiming({
          updatedCycleTiming: updatedTimingRes.data,
        });
      }
    } catch (err) {
      handleTimingServiceErrors(err);
    } finally {
      setLoading(false);
    }
  };

  const removeTimingItem = async (id: number) => {
    // can only remove items when status is not completed
    if (!cycleTiming || cycleTiming.status === Status.COMPLETED) return;

    setLoading(true);

    // remove item by id from items
    const upsertItems = cycleTiming.items.filter(item => item.id !== id);
    if (upsertItems.length === 0) {
      NotificationManager.warning('Can not remove all items from a timing', 'Canceled', 3000);
    }

    try {
      await upsertItemsToCycleTiming(cycleTiming.id, upsertItems);

      const updatedTimingRes = await getCycleTimingById(cycleTiming.id);
      if (updatedTimingRes) {
        updateCurrentCycleTiming({ updatedCycleTiming: updatedTimingRes.data });
        NotificationManager.info('Item from timing', 'Removed', 3000);
      }
    } catch (err) {
      handleTimingServiceErrors(err);
    }
    setLoading(false);
  };

  const onSelectStation = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    if (typeof e.target.value === 'string') {
      setStation(e.target.value);
    }
  };

  const selectNewStation = () => {
    if (!cycleTiming) {
      setStation('');
    }
  };

  return {
    station,
    setStation,
    stations,
    onSelectStation,
    loading,
    selectNewStation,
    onFormSubmitSearch,
    timingTabs,
    tabValue,
    onTabChange,
    setTabValue,
    cycleTiming,
    clearCurrentTiming,
    updateTimingStatus,
    removeTimingItem,
    updateTimingTraining,
  };
};
