import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../constants';
import './cpoVideo.css';
import axios from '../../utils/axios';
import DynamicForm from '../../components/dynamic_form';
import lazerCat from '../../images/catlazer.jpg';
import notFound from '../../images/notfound.png';
import { StripedDataGrid } from '../../styledComponents/datagrid';
import { PageWrapper } from '../../styledComponents/wrappers';

export default class CpoVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoList: [],
      sku: '',
      url: '',
      success: false,
      error: false,
      message: '',
      errorMessage: '',
      requestCode: 0,
      searchTerm: '',
      paginationModel: {
        page: 0,
        pageSize: 15,
      },
    };

    this.columns = [
      {
        headerName: 'SKU',
        field: 'sku',
        renderCell: ({ value }) => <Link to={`products/360view/${value}`}>{value}</Link>,
        flex: 1,
      },
      {
        headerName: 'LINK',
        field: 'video-link',
        accessor: ({ value }) => (
          <a target="_blank" rel="noopener noreferrer" href={value}>
            {value}
          </a>
        ),
        flex: 1,
      },
      {
        headerName: 'Inspection Report',
        field: 'pdfLink',
        accessor: ({ value }) => (
          <a target="_blank" rel="noopener noreferrer" href={value}>
            PDF Report
          </a>
        ),
        flex: 1,
      },
    ];
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/cpo/getVideos`, {
        params: {
          searchTerm: this.state.searchTerm,
        },
      });
      this.setState({ videoList: response.data });
    } catch (e) {
      console.error(e);
    }
  };

  submitForm = async e => {
    e.preventDefault();
    const response = await axios.post(`${API_URL}/cpo/addVideoUrl`, {
      params: {
        sku: this.state.sku,
        url: this.state.url,
      },
    });

    if (response.status < 300) {
      this.setState({
        success: true,
        sku: '',
        url: '',
        message: response.data.message,
        requestCode: response.data.code,
      });
      this.fetchData();
    }

    if (response.status > 300) {
      this.setState({ error: true, errorMessage: response.message });
    }
  };

  handleFilterSubmit = e => {
    e.preventDefault();
    this.fetchData();
  };

  handleFilterClear = () => {
    this.setState({ searchTerm: '' }, () => this.fetchData());
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { requestCode } = this.state;
    return (
      <PageWrapper style={{ overflow: 'hidden' }} className="cpo-video-wrap">
        {this.state.success && (
          <div className="backdrop offer-decline-backdrop">
            <div className="message offer-decline-message">
              {this.state.message}
              <img
                className="success-image"
                src={requestCode ? lazerCat : notFound}
                alt="success"
              />
              <button onClick={() => this.setState({ success: false })} className="submit-button">
                Close
              </button>
            </div>
          </div>
        )}
        {this.state.error && (
          <div className="backdrop offer-decline-backdrop">
            <div className="message offer-decline-message">
              Uh oh! Error.
              <p>{this.state.errorMessage}</p>
              <button onClick={() => this.setState({ success: false })} className="submit-button">
                Close
              </button>
            </div>
          </div>
        )}
        <p>Enter a CPO Bikes SKU and Video URL below to link bike to video</p>
        <form onSubmit={this.submitForm}>
          <span>
            <label>SKU:</label>
            <input
              type="text"
              name="sku"
              value={this.state.sku}
              placeholder="e.g BMT11345"
              onChange={this.handleChange}
            />
          </span>

          <span>
            <label>URL:</label>
            <input
              type="text"
              name="url"
              value={this.state.url}
              placeholder="e.g. https://vimeo.com/309186070"
              onChange={this.handleChange}
            />
          </span>
          <button type="submit" className="submit-button">
            SUBMIT
          </button>
        </form>
        <section>
          <DynamicForm
            fields={[
              {
                placeholder: 'Search by SKU',
                type: 'text',
                name: 'searchTerm',
                label: 'Names',
                value: this.state.searchTerm,
                onChange: this.handleChange,
              },
            ]}
            onSubmit={this.handleFilterSubmit}
            onClear={this.handleFilterClear}
            onChange={this.handleChange}
          />
        </section>
        <StripedDataGrid
          columns={this.columns}
          rows={this.state.videoList}
          getRowId={row => row.sku}
          paginationModel={this.state.paginationModel}
          onPaginationModelChange={m => this.setState({ paginationModel: m })}
          pageSizeOptions={[15, 30, 60]}
        />
      </PageWrapper>
    );
  }
}
