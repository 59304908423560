import React, { useState, useMemo, useEffect, Fragment, useRef, useContext } from 'react';
import { Form, Formik } from 'formik';

import { useSelector } from 'react-redux';
import {
  ProductVariant,
  Product,
  CopyAndSaveFieldValueToAllVariants,
  AttributeType,
} from '../../../services/catalog/types';
import { EditProductVariantValues, NewOrExistingVariant } from '../utils/productVariant/types';
import { GlobalState, Permissions } from '../../../reducers/types';
import { getDuplicateUpcError } from '../utils';
import { getEditProductVariantValidation } from '../utils/validation';
import styled from 'styled-components';
import ButtonSection from './ButtonSection';
import { ErrorMessage } from '../../../components/library';
import { Snackbar, useSnackbar } from '../../../components/library/Snackbar';
import { Prompt } from 'react-router-dom';
import DuplicateUpcErrorMessage from '../DuplicateUpcErrorMessage';
import { UpcErrorProperties } from '../utils/index';
import {
  getEditProductVariantInitialValues,
  submitEditProductVariant,
} from '../utils/productVariant/form-utils';
import { getEditProductVariantFieldListConfiguration } from '../utils/productVariant/fields';
import FormikField from './FormikField';
import { fieldCategoryConfigContext } from '../utils/productVariant/field-context';
import { groupFieldListConfiguration } from '../utils/formik/formik-fields';

export interface EditProductVariantProps {
  product: Product;
  productVariant: NewOrExistingVariant;
  onCancel?(): void;
  onSubmitSuccess?(productVariant?: ProductVariant): void;
  refreshProductData: () => Promise<void>;
  copyAndSaveFieldValueToAllVariants?: CopyAndSaveFieldValueToAllVariants;
}

export const FormComponent = styled(Form)`
  border: 1px solid black;
  padding: 20px;
  margin-bottom: 20px;
`;

const EditProductVariantForm: React.FC<EditProductVariantProps> = ({
  product,
  productVariant,
  refreshProductData,
  onCancel,
  onSubmitSuccess,
  copyAndSaveFieldValueToAllVariants,
}) => {
  const [error, setError] = useState<string | string[] | undefined>(undefined);
  const [upcError, setUpcError] = useState<UpcErrorProperties>();
  const productStore = useSelector((state: GlobalState) => state.product);
  const { user } = useSelector((state: GlobalState) => state.auth);
  const { snackbarOpen, handleSnackbarOpen, handleSnackbarClose } = useSnackbar();

  const ref = useRef<HTMLDivElement | null>(null);

  const userHasPriceChangePermissions = !!user?.permissions.find(
    p => p.id === Permissions.CATALOG_UPDATE_PRICING,
  );

  const varyingAttributeTypes: AttributeType[] = useMemo(
    () => (product.productVariations ?? []).map(pvn => pvn.attributeType),
    [product],
  );

  const validationSchema = useMemo(() => {
    return getEditProductVariantValidation({
      frameDimensions: productStore.frameDimensions,
      variantType: '',
    });
  }, [productStore.frameDimensions]);

  const initialValues: EditProductVariantValues = useMemo(() => {
    return getEditProductVariantInitialValues({
      productVariant,
      productStore,
      product,
    });
  }, [productVariant, productStore, product]);

  const onSubmit = async (values: EditProductVariantValues) => {
    return await submitEditProductVariant({
      values,
      setError,
      productStore,
      product,
      productVariant,
      initialValues,
      onSubmitSuccess,
      refreshProductData,
      handleSnackbarOpen,
    });
  };

  useEffect(() => {
    const newUpcError = getDuplicateUpcError(error);
    if (upcError?.productId !== newUpcError?.productId) {
      setUpcError(newUpcError);
    }
  }, [error, upcError]);

  const { fieldCategoryConfigs } = useContext(fieldCategoryConfigContext);

  const newFormGroupedConfiguration = useMemo(() => {
    const config = getEditProductVariantFieldListConfiguration({
      variantId: productVariant.id,
      productStore,
      categoryId: product.categoryId,
      productVariantComponents: productVariant.productVariantComponents || [],
      initialSingleItemProcurement: initialValues.singleItemProcurement,
      userHasPriceChangePermissions,
      varyingAttributeTypes,
      copyAndSaveFieldValueToAllVariants,
      fieldCategoryConfigs,
    });
    return groupFieldListConfiguration(config);
  }, [
    productVariant.id,
    product.categoryId,
    productStore,
    productVariant.productVariantComponents,
    initialValues.singleItemProcurement,
    userHasPriceChangePermissions,
    varyingAttributeTypes,
    copyAndSaveFieldValueToAllVariants,
    fieldCategoryConfigs,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {({ dirty }) => (
        <FormComponent>
          <>
            <Prompt when={dirty} message="Are you sure you want to exit without saving?" />
            {newFormGroupedConfiguration.groups.map(({ displayName, fields, subGroups }) => (
              <Fragment key={displayName}>
                <h4>{displayName}</h4>
                {subGroups.length
                  ? subGroups.map(subGroup => (
                      <Fragment key={subGroup.displayName}>
                        <h5 key={subGroup.displayName}>{subGroup.displayName}</h5>
                        {subGroup.fields.map(field => (
                          <FormikField key={field.valueKey} field={field} />
                        ))}
                      </Fragment>
                    ))
                  : fields.map(field => <FormikField key={field.valueKey} field={field} />)}
              </Fragment>
            ))}
          </>
          {upcError ? (
            <DuplicateUpcErrorMessage
              msg={upcError.msg}
              upc={upcError.upc}
              productId={upcError.productId}
              sku={upcError.sku}
              ref={ref}
            />
          ) : (
            <ErrorMessage error={error} />
          )}
          <ButtonSection onCancel={onCancel} productId={product.id} />
          <Snackbar open={snackbarOpen} handleClose={handleSnackbarClose} />
        </FormComponent>
      )}
    </Formik>
  );
};

export default EditProductVariantForm;
