import axios from '../../../utils/axios';
import { fetchCatalogProductsSearch } from '../../../services/catalog/services';
import { API_URL } from '../../../constants';
import { CatalogProductSearchResponse } from '../../../services/catalog/types';

export async function validateSkuViaCatalogSearch(
  sku: string,
): Promise<{ valid: boolean; upcWarning?: string }> {
  let valid = false;
  let upcWarning: string | undefined = undefined;
  const toggledOnResponse = await axios.get(`${API_URL}/user/featureToggles/restrictMultiRelocate`);
  if (!toggledOnResponse || !toggledOnResponse?.data || toggledOnResponse.data == 'false') {
    return { valid: true };
  }
  await fetchCatalogProductsSearch(
    { sku, limit: 1 },
    {
      onSuccess: (searchResponse: CatalogProductSearchResponse) => {
        if (Array.isArray(searchResponse?.results) && searchResponse.results.length >= 1) {
          valid = true;
        }
      },
    },
  );
  if (!valid) {
    //see if it matches a upc
    await fetchCatalogProductsSearch(
      { upc: sku, limit: 2 },
      {
        onSuccess: (searchResponse: CatalogProductSearchResponse) => {
          if (Array.isArray(searchResponse?.results)) {
            if (searchResponse.results.length == 0) {
              upcWarning = 'INVALID';
            } else if (searchResponse.results.length == 1) {
              valid = true;
            } else {
              upcWarning = 'MULTIPLE_SKUS';
            }
          }
        },
      },
    );
  }
  return { valid, upcWarning };
}
