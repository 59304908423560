import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function FormDialog({
  onClose,
  onChange,
  onSubmit,
  open,
  reasons,
  blames,
  formData: { notes, reasonId, blameId, error },
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Refund/Return</DialogTitle>
        <DialogContent>
          <form>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="age-simple">Reason</InputLabel>
              <Select
                value={reasonId}
                onChange={onChange}
                inputProps={{
                  name: 'reasonId',
                }}
                fullWidth
              >
                <MenuItem value="" />
                {reasons.map(r => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="age-simple">Blame</InputLabel>
              <Select
                value={blameId}
                onChange={onChange}
                inputProps={{
                  name: 'blameId',
                }}
                fullWidth
              >
                <MenuItem value="" />
                {blames.map(r => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} fullWidth>
              <TextField
                margin="dense"
                name="notes"
                label="Notes"
                type="text"
                value={notes}
                onChange={onChange}
                multiline
                rows={5}
                fullWidth
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
        {error && <DialogContentText color="error">{error}</DialogContentText>}
      </Dialog>
    </div>
  );
}
