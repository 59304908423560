import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { AppWrapper } from './styledComponents/wrappers';
import * as actions from './actions';

import './styles/App.scss';
import './styles/react-table.css';
import './pages/service/inspectionDetailView/globalLegacyDetailView.scss';

// import routes
import PrivateRoute from './private-route';
import Home from './pages/home';
import BuyersHome from './pages/buyersHome';
import Login from './pages/login';
import Support from './pages/support';
import ResetPassword from './pages/resetPassword';
import AccountHome from './components/account/account';
import { FormsPage, FORMS_PAGE_ROUTE } from './pages/forms/FormsPage';
import { ReceivingPage, RECEIVING_PAGE_ROUTE } from './pages/receiving/ReceivingPage';
import C360List from './pages/customers/c360List';
import C360Detail from './pages/customers/c360Detail';
import ThreeSixtyListView from './pages/products/threeSixtyListView';
import ThreeSixtyDetailView from './pages/products/threeSixtyDetail/threeSixtyDetailView';
const CreateBarcode = React.lazy(() => import('./pages/products/CreateBarcode'));

import Relocator from './pages/service/relocator/SkuRelocate.tsx';
import SubmissionReturn from './pages/service/SubmissionReturnForm';
import Disciplines from './pages/buyers/disciplines';
import DisciplineFactors from './pages/buyers/discipline_factors';
import CpoVideo from './pages/customerService/cpoVideo';
import LeadsOnline from './pages/leadsOnline.tsx';

// #region Service Routes
import {
  ServiceTicketDetailView,
  INSPECTION_DETAIL_ROUTE,
} from './pages/service/serviceTicket/ServiceTicketDetailView';
import {
  InspectionSearchView,
  INSPECTION_SEARCH_ROUTE,
} from './pages/service/serviceTicket/InspectionSearchView';

import StationLanding, {
  OLD_CYCLE_TIMING_URL,
} from './pages/service/station_inspection/stationLanding';
import OldCycleTimings from './pages/service/cycleTimings/OldCycleTimings';
import CycleTimings from './pages/service/cycleTimings/CycleTimings';
import Returns from './pages/service/returns/returns';
import Return from './pages/service/returns/return';
// #endregion

// #region catalog Routes
import CatalogAddProduct from './pages/catalog/CatalogAddProductView';
import CatalogSearchProducts from './pages/catalog/CatalogSearchProductsView';
import ViewProduct from './pages/catalog/viewProduct/ViewProduct';
import EditProduct from './pages/catalog/editProduct/EditProduct';
import ProductImagesView from './pages/catalog/productImages/ProductImagesView';
import CatalogProductUpcQueryAndView from './pages/catalog/CatalogProductUpcQueryAndView';
import ShopifyCreateProduct from './pages/catalog/ShopifyCreateProduct';
//#endregion

// #region Finance Routes
import PayPal from './pages/finance/PayPalPayments';
import PayPalPaidList from './pages/finance/paypal_paid';
// #endregion

// #region Netsuite Routes
import Inventory from './pages/netsuite/inventory';
// #endregion

import { PreferencesPage } from './pages/preferences/PreferencesPage';

// #region Tradeups
import SubmissionListView, { TRADEUP_SEARCH_ROUTE } from './pages/tradeups/SubmissionListView';
import CatchReleaseListView from './pages/tradeups/CatchReleaseListView';
import PartnerDetailView from './pages/tradeups/partnerDetaiView';
import SubmissionDetailView, {
  SUBMISSION_DETAIL_ROUTE,
} from './pages/tradeups/SubmissionDetailView';
import TradeupAdmin from './pages/tradeups/tradeupAdmin/tradeupAdmin';
import PartnerSiteAdmin from './pages/tradeups/tradeupAdmin/PartnerSiteAdmin';
import DealerDetail from './pages/tradeups/tradeupAdmin/dealerDetail';
import TradeinForm from './pages/tradeups/tradeinForm';
import ClientReturnForm from './pages/tradeups/clientForms/clientForm';
// #endregion Tradeups
// Filters routes
import UpdateFilters from './pages/marketing/updateFilters';
import UpdateCollections from './pages/marketing/updateCollections';
// Admin routes
import DisplayUsers from './pages/admin/users/display_users';

import UpdateBlogs from './pages/marketing/updateBlogs';
import MultiRelocator from './pages/service/multiSkuRelocate';
import FeatureToggles from './pages/admin/featureToggles';
import InventoryAdjustments from './pages/finance/inventoryAdjustments/InventoryAdjustments';
import Loader from './components/loader';
import { PreferenceToggleView } from './components/library/PreferenceToggleView';
import { CycleTimingsProvider } from './pages/service/cycleTimings/CycleTimigsProvider';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { materialTheme } from './theme';
// #endregion
const buildRoutes = () => {
  const routes = [
    {
      path: '/',
      exact: true,
      main: () => <Home />,
    },
    {
      path: '/account/home',
      main: () => <AccountHome />,
    },
    {
      path: '/support',
      main: () => <Support />,
    },
    {
      path: FORMS_PAGE_ROUTE,
      main: () => <FormsPage />,
    },
    {
      path: '/service/submitNew',
      main: () => <InspectionSearchView openNewInspectionModalOnLoad={true} />,
    },
    {
      exact: true,
      path: INSPECTION_SEARCH_ROUTE,
      main: () => <InspectionSearchView />,
    },
    {
      path: INSPECTION_DETAIL_ROUTE,
      main: ServiceTicketDetailView,
    },
    {
      path: '/service/relocate',
      main: Relocator,
    },
    {
      path: '/service/multirelocate',
      main: () => <MultiRelocator />,
    },
    {
      path: '/service/submissionReturn',
      main: () => <SubmissionReturn />,
    },
    {
      path: '/admin/users',
      main: () => <DisplayUsers />,
    },
    {
      path: '/netsuite/locateInventory',
      main: () => <Inventory />,
    },
    {
      path: '/customer360',
      main: () => <C360List />,
      exact: true,
    },
    {
      path: '/customer360/:id',
      main: () => <C360Detail />,
    },
    {
      path: '/paypal',
      main: () => <PayPal />,
      exact: true,
    },
    {
      path: '/paypal/paid',
      main: () => <PayPalPaidList />,
      exact: true,
    },
    {
      path: '/inventoryAdjustments',
      main: () => <InventoryAdjustments />,
      exact: true,
    },
    {
      path: '/products/360view',
      exact: true,
      main: () => <ThreeSixtyListView />,
    },
    {
      path: '/products/360view/:id',
      exact: true,
      main: () => <ThreeSixtyDetailView />,
    },
    {
      path: '/products/createBarcode',
      main: CreateBarcode,
    },
    {
      path: RECEIVING_PAGE_ROUTE,
      exact: true,
      main: () => <ReceivingPage />,
    },
    {
      path: TRADEUP_SEARCH_ROUTE,
      exact: true,
      main: () => <SubmissionListView />,
    },
    {
      path: '/buyers/tradeups/catchrelease',
      exact: true,
      main: () => <CatchReleaseListView />,
    },
    {
      path: '/buyers/tradeups/admin',
      exact: true,
      main: () => <TradeupAdmin />,
    },
    {
      path: '/buyers/tradeups/partners/admin',
      exact: true,
      main: () => <PartnerSiteAdmin />,
    },
    {
      path: '/buyers/tradeups/admin/dealerDetail/:id',
      exact: true,
      main: () => <DealerDetail />,
    },
    {
      path: '/tradeups/partner/:submissionId',
      exact: true,
      main: () => <PartnerDetailView />,
    },
    {
      path: `${SUBMISSION_DETAIL_ROUTE}/:submissionId`,
      exact: true,
      main: () => <SubmissionDetailView />,
    },
    {
      path: '/buyers/disciplines',
      main: () => <Disciplines />,
    },
    {
      path: '/buyers/disciplineFactors',
      main: () => <DisciplineFactors />,
    },
    {
      path: '/buyers',
      main: () => <BuyersHome />,
    },
    {
      path: '/cpoVideo',
      main: () => <CpoVideo />,
    },
    {
      path: '/marketing/updateFilters',
      main: () => <UpdateFilters />,
    },
    {
      path: '/marketing/updateCollections',
      main: () => <UpdateCollections />,
    },
    {
      // TODO: TP-2800 remove this route and redirect
      path: '/service/stationinspection',
      main: () => <Redirect to="/service/cycle-timings" />,
    },
    {
      // TODO: TP-2800 remove this route and redirect
      path: '/service/cycle-timing/:station',
      main: () => <Redirect to="/service/cycle-timings" />,
    },
    {
      // TODO: TP-2800 remove this route and redirect
      path: '/service/cycle-timing',
      main: () => <Redirect to="/service/cycle-timings" />,
    },
    {
      // "Secret Route" in case we need to roll back
      // TODO: TP-2800 remove this route and redirect
      path: `${OLD_CYCLE_TIMING_URL}/:station`,
      main: StationLanding,
    },
    {
      // "Secret Route" in case we need to roll back
      // TODO: TP-2800 remove this route and redirect
      path: `${OLD_CYCLE_TIMING_URL}`,
      main: StationLanding,
    },
    {
      path: '/service/cycle-timings/:cycleTimingId',
      main: () => (
        <PreferenceToggleView
          localStorageKey="cycleTimingsAB"
          ToggleOffView={<OldCycleTimings />}
          ToggleOnView={<Home />}
        />
      ),
    },
    {
      path: '/service/cycle-timings',
      main: () => (
        <PreferenceToggleView
          localStorageKey="cycleTimingsAB"
          ToggleOffView={<OldCycleTimings />}
          ToggleOnView={<Home />}
        />
      ),
    },
    {
      path: '/catalog/products/:productId/edit',
      main: () => <EditProduct />,
    },
    {
      path: '/catalog/products/:productId/images',
      main: () => <ProductImagesView />,
    },
    {
      path: '/catalog/products/:productId/shopifyCreate',
      main: ShopifyCreateProduct,
    },
    {
      path: '/catalog/products/:productId',
      main: () => <ViewProduct />,
    },
    {
      path: '/catalog/products',
      main: () => <CatalogSearchProducts />,
    },
    {
      path: '/catalog/product-creation',
      main: () => <CatalogAddProduct />,
    },
    {
      path: '/catalog/upc/:upc',
      main: CatalogProductUpcQueryAndView,
    },
    {
      path: '/catalog/upc',
      main: CatalogProductUpcQueryAndView,
    },
    {
      exact: true,
      path: '/service/returns',
      main: () => <Returns />,
    },
    {
      exact: true,
      path: '/service/returns/:id',
      main: () => <Return />,
    },
    {
      path: '/marketing/updateBlogs',
      main: () => <UpdateBlogs />,
    },
    {
      exact: true,
      path: '/leadsOnline',
      main: () => <LeadsOnline />,
    },
    {
      exact: true,
      path: '/admin/featureToggles',
      main: () => <FeatureToggles />,
    },
    {
      exact: true,
      path: '/preferences',
      main: () => <PreferencesPage />,
    },
  ].map((route, i) => ({ ...route, key: i }));

  return routes;
};

class App extends Component {
  handleUpdateSW() {
    const waitingServiceWorker = this.props.SWRegistration?.waiting;

    if (waitingServiceWorker) {
      // this sends a message to the service worker, whereupon:
      // 1) the new (waiting) service worker will skip waiting and take control of all open pages
      // 2) all open pages will refresh to pick up new static assets
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  }

  render() {
    if (this.props.isSWUpdated) {
      NotificationManager.info(
        "There is a new version of Drivetrain available. Clicking here will immediately update all of your open DT tabs - please save all unfinished work before it's deleted!",
        'UPDATE AVAILABLE',
        86400, // 24 hours (react-notifications doesn't allow permanent notifications)
        this.handleUpdateSW.bind(this),
        true, // display at top if other notifications are visible
      );
    } else if (this.props.isSWInitialized) {
      NotificationManager.success(
        'Drivetrain is up to date!',
        null,
        5000,
        true, // display at top if other notifications are visible
      );
    }

    return (
      <Router>
        <AppWrapper>
          <Suspense fallback={<Loader loading />}>
            <MaterialThemeProvider theme={materialTheme}>
              <StyledThemeProvider theme={materialTheme}>
                <CycleTimingsProvider>
                  {this.props.isAuthenticated ? (
                    <PreferenceToggleView
                      localStorageKey="cycleTimingsAB"
                      ToggleOffView={null}
                      ToggleOnView={<CycleTimings />}
                    />
                  ) : null}

                  <Switch>
                    <Route path="/login/:ssoToken?" exact component={() => <Login />} />

                    <Route path="/resetPassword/:token" exact component={() => <ResetPassword />} />

                    <Route path="/internalSubmission" exact component={() => <TradeinForm />} />

                    <Route
                      path="/tradeup/failedSubmission/:token"
                      exact
                      component={() => <ClientReturnForm />}
                    />
                    {/* buildRoutes needs to be called on render */}
                    {buildRoutes().map(route => (
                      <PrivateRoute
                        key={route.key}
                        path={route.path}
                        exact={route.exact}
                        component={route.main}
                        render={route.render}
                        isAuthenticated={this.props.isAuthenticated}
                      />
                    ))}
                    {/* legacy redirects to preserve bookmarked URLs */}

                    <Redirect from="/shipping/tracking-upload" to="/shipping/mark-shipped" />

                    {/* preserve functionality of legacy '/SYB/12345' links */}
                    <Redirect
                      from="/tradeups/SYB/:submissionId"
                      to="/tradeups/submission/:submissionId"
                    />

                    {/* preserve functionality of legacy '/inspection' links */}
                    <Redirect from="/service/inspections/:id" to={INSPECTION_DETAIL_ROUTE} />
                    <Redirect from="/service/inspections" to={INSPECTION_SEARCH_ROUTE} />
                  </Switch>
                </CycleTimingsProvider>
              </StyledThemeProvider>
            </MaterialThemeProvider>
            <NotificationContainer />
          </Suspense>
        </AppWrapper>
      </Router>
    );
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isSWInitialized: PropTypes.bool.isRequired,
  isSWUpdated: PropTypes.bool.isRequired,
  SWRegistration: PropTypes.any,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth ? state.auth.isAuthenticated : false,
  isSWInitialized: state.serviceWorker.isInitialized,
  isSWUpdated: state.serviceWorker.isUpdated,
  SWRegistration: state.serviceWorker.registration,
});

export default connect(mapStateToProps, actions)(App);
