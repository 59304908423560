import { Checkbox, FormControlLabel, makeStyles, Theme, createStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { FormikRejectState } from '../../../_shared/types';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checked: {
      border: `2px solid ${theme.palette.error.vibrant}`,
      color: theme.palette.error.main,
      backgroundColor: theme.palette.error.background,
      transition: 'all 0.2s ease-in-out',
      borderRadius: '3px',
      margin: 0,
      padding: theme.spacing(0.5),
    },
    unchecked: {
      border: `2px solid ${theme.palette.grey[300]}`,
      transition: 'all 0.2s ease-in-out',
      borderRadius: '3px',
      margin: 0,
      padding: theme.spacing(0.5),
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
  }),
);

interface CheckboxProps {
  id?: number;
}

export const RejectCheckbox: React.FC<CheckboxProps> = ({ id }) => {
  const { values, setFieldValue } = useFormikContext<FormikRejectState>();
  const rejectionReasonIndex = values.rejectionReasons.findIndex(
    reason => reason.rejectionReasonId === id,
  );
  const rejectionReason = values.rejectionReasons[rejectionReasonIndex];

  const classes = useStyles();

  return (
    <FormControlLabel
      className={rejectionReason?.checked ? classes.checked : classes.unchecked}
      control={
        <Checkbox
          checked={rejectionReason?.checked}
          onChange={() =>
            setFieldValue(
              `rejectionReasons[${rejectionReasonIndex}].checked`,
              !rejectionReason?.checked,
            )
          }
          name={rejectionReason?.name}
          style={{
            color: 'inherit',
          }}
        />
      }
      label={rejectionReason?.name}
    />
  );
};
