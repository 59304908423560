import React, { useState } from 'react';
import { PartnerUser } from '../../../typeORM';
import GenericFormModal from '../../../components/modals/genericFormModal';
import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';
import { TextField, Checkbox } from '../../../components/library';
import axios from '../../../utils/axios';
import { API_URL } from '../../../constants';
import { notifyError } from '../sybService';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

interface Props {
  user?: PartnerUser;
  partners: any[];
  roles: any[];
  onClose: () => any;
  onSuccess: () => any;
}

// todo: better reusable modal component that renders children instead of form prop
const EditPartnerSiteUserModal = ({ user, roles, partners, onClose, onSuccess }: Props) => {
  const [partnerId, setPartnerId] = useState<number | null>(user?.partnerId ?? null);
  const [roleId, setRoleId] = useState<number | null>(user?.roleId ?? null);
  const [name, setName] = useState<string | null>(user?.name ?? null);
  const [email, setEmail] = useState<string | null>(user?.email ?? null);
  const [active, setActive] = useState<boolean>(!!user?.active);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <GenericFormModal
      closeModal={onClose}
      form={
        <Form
          onSubmit={async e => {
            e.preventDefault();
            setIsSuccess(false);
            setIsError(false);
            try {
              let result: any;
              if (user) {
                // update
                result = await axios.put(`${API_URL}/tradeup/partners/user/${user.id}`, {
                  roleId,
                  name,
                  email,
                  active,
                });
              } else {
                // create
                result = await axios.post(`${API_URL}/tradeup/partners/user`, {
                  partnerId,
                  roleId,
                  name,
                  email,
                });
              }
              if (result && result?.data) {
                setIsSuccess(true);
                if (onSuccess) onSuccess();
              } else {
                setIsError(true);
              }
            } catch (error) {
              if (error?.response?.status === 409) {
                notifyError(
                  `A user with the email "${email}" already exists for the selected partner.`,
                );
              }
              console.error(error);
              setIsError(true);
            }
          }}
        >
          <h4>{user ? 'Edit Partner User' : 'Create Partner User'}</h4>
          {!user && (
            <p style={{ marginBottom: '1rem' }}>
              An email with instructions to set a password will be sent to the email address
              provided.
            </p>
          )}
          {!user && (
            <Row>
              <TextField
                variant="outlined"
                select
                id="partner"
                label="Partner"
                value={partnerId ?? ''}
                onChange={e => setPartnerId(parseInt(e.target.value, 10))}
                options={partners}
                required={true}
              />
            </Row>
          )}
          <Row>
            <TextField
              variant="outlined"
              select
              id="role"
              label="Role"
              value={roleId ?? ''}
              onChange={e => setRoleId(parseInt(e.target.value, 10))}
              options={roles}
              required={true}
            />
          </Row>
          <Row>
            <TextField
              variant="outlined"
              id="name"
              label="Name"
              value={name ?? ''}
              onChange={e => setName(e.target.value)}
              required={true}
            />
          </Row>
          <Row>
            <TextField
              variant="outlined"
              id="email"
              label="Email"
              value={email ?? ''}
              onChange={e => setEmail(e.target.value)}
              required={true}
              inputProps={{
                pattern: '\\S+@\\S+.\\S+',
                title: 'A valid email address',
              }}
            />
          </Row>
          <Row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  onChange={e => setActive(e.target.checked)}
                  id="active"
                />
              }
              label="User Account Active?"
            />
          </Row>
          {/* todo: remove className in favor of reusable styled-component Button */}
          <button className="submit-button">SUBMIT</button>
        </Form>
      }
      success={isSuccess}
      error={isError}
    />
  );
};

export default EditPartnerSiteUserModal;
