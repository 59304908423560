import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MaterialTable from '@material-table/core';
import axios from '../../../utils/axios';
import { API_URL } from '../../../constants';
import EditPartnerSiteUserModal from './EditPartnerSiteUserModal';
import { PartnerUser, Partner, Role } from '../../../typeORM';
import { notifyAdminPermissionError, notifyError } from '../sybService';
import Loader from '../../../components/loader';
import { Option } from '../../../components/library/TextField';

const Wrapper = styled.div`
  // this is to hack around the width:100% declaration in /pages/marketing/marketing.css
  [class*='MTableToolbar-searchField-'].MuiTextField-root {
    max-width: 20rem;
  }
`;

interface Lookup {
  [key: number]: string;
}

const activeLookup: Lookup = {
  1: 'active',
  0: 'inactive',
};

const prettyPrintDate = (rowData: any) => new Date(rowData.created).toLocaleDateString();
const prettyPrintActiveStatus = (rowData: any) => activeLookup[rowData.active];

const PartnerSiteAdmin = () => {
  // state for list view
  const [hasAdminPermissions, setHasAdminPermissions] = useState(false);
  const [users, setUsers] = useState<PartnerUser[] | null>(null);
  const [partnersLookup, setPartnersLookup] = useState<Lookup | null>(null);
  const [rolesLookup, setRolesLookup] = useState<Lookup | null>(null);
  const [refetchKey, setRefetchKey] = useState(0);
  // state for modal <select> options
  const [partners, setPartners] = useState<Option[] | null>(null);
  const [roles, setRoles] = useState<Option[] | null>(null);
  // state for modal
  const [currentUser, setCurrentUser] = useState<PartnerUser | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    // todo: set up private routes so each page doesn't have to check / handle permissions
    const userPermissions = JSON.parse(localStorage.getItem('userPermissions') ?? '');
    const isAdmin = userPermissions?.includes(13); // tup_settings
    if (isAdmin) {
      setHasAdminPermissions(true);
      const fetchLookupData = async () => {
        try {
          const resultPartners = await axios.get(`${API_URL}/tradeup/partners`);
          const resultRoles = await axios.get(`${API_URL}/tradeup/partners/roles`);

          if (resultPartners && resultPartners?.data) {
            const partnersData = resultPartners.data as Partner[];
            const partnersLookup: Lookup = {};
            for (const p of partnersData) partnersLookup[p.id] = p.name;
            setPartnersLookup(partnersLookup);
            setPartners(partnersData.map(p => ({ label: p.name, value: p.id })));
          }

          if (resultRoles && resultRoles?.data) {
            const rolesData = resultRoles.data as Role[];
            const rolesLookup: Lookup = {};
            for (const p of rolesData) rolesLookup[p.id] = p.name;
            setRolesLookup(rolesLookup);
            setRoles(rolesData.map(r => ({ label: r.name, value: r.id })));
          }
        } catch (error) {
          console.error(error);
          notifyError('Unable to load lookup data');
        }
      };
      fetchLookupData();
    } else {
      notifyAdminPermissionError();
    }
  }, []);

  useEffect(() => {
    if (hasAdminPermissions) {
      const fetchData = async () => {
        try {
          const resultUsers = await axios.get(`${API_URL}/tradeup/partners/users`);
          if (!(resultUsers && resultUsers?.data)) {
            throw new Error(`malformed response, received ${{ resultUsers }}`);
          }
          setUsers(resultUsers.data);
        } catch (error) {
          console.error(error);
          notifyError('Unable to load users');
        }
      };
      fetchData();
    }
  }, [hasAdminPermissions, refetchKey]); // refetch data when refetchKey changes

  if (!users || !partners || !roles) {
    return <Loader loading={true} />;
  }

  return (
    <Wrapper className="flex-column-center">
      <button
        className="classy-button"
        style={{ margin: '1rem' }}
        onClick={() => {
          setCurrentUser(null);
          setIsModalOpen(true);
        }}
      >
        ADD NEW
      </button>
      {/*
        todo: the title of this table isn't fully displayed, but this table component is deprecated anyway.
        replace with the /components/library/Table component when this page is touched next.
      */}
      <MaterialTable
        title="Partner Site Users"
        onRowClick={(_, rowData) => {
          if (rowData) {
            setCurrentUser(rowData);
            setIsModalOpen(true);
          }
        }}
        data={users || []}
        columns={[
          {
            title: 'ID',
            field: 'id',
            editable: 'never',
            filtering: false,
            width: 20,
          },
          {
            title: 'PARTNER',
            field: 'partnerId',
            editable: 'never',
            lookup: partnersLookup ?? [],
          },
          {
            title: 'ROLE',
            field: 'roleId',
            editable: 'never',
            filtering: false,
            lookup: rolesLookup ?? [],
          },
          {
            title: 'NAME',
            field: 'name',
            editable: 'never',
            filtering: false,
          },
          {
            title: 'EMAIL',
            field: 'email',
            editable: 'never',
            filtering: false,
          },
          {
            title: 'CREATED',
            field: 'created',
            editable: 'never',
            filtering: false,
            render: prettyPrintDate,
          },
          {
            title: 'UPDATED',
            field: 'modified',
            editable: 'never',
            filtering: false,
            render: prettyPrintDate,
          },
          {
            title: 'ACTIVE',
            field: 'active',
            editable: 'never',
            lookup: activeLookup,
            render: prettyPrintActiveStatus,
          },
        ]}
        options={{
          filtering: true,
          searchFieldAlignment: 'right',
          headerStyle: {
            backgroundColor: 'black',
            color: 'white',
          },
        }}
      />
      {isModalOpen && (
        <EditPartnerSiteUserModal
          user={currentUser ?? undefined}
          partners={partners}
          roles={roles}
          onClose={() => setIsModalOpen(false)}
          onSuccess={() => setRefetchKey(refetchKey + 1)}
        />
      )}
    </Wrapper>
  );
};

export default PartnerSiteAdmin;
