import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

type StatusMessageProps = {
  status: 'success' | 'initiated' | 'failed';
  message: string;
  helperText?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    transparentBg: {
      backgroundColor: 'transparent',
    },
    dense: {
      padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    helperText: {
      fontSize: '12px',
      display: 'block',
    },
  }),
);

const StatusMessage: React.FC<StatusMessageProps> = ({
  status,
  message,
  helperText,
}: StatusMessageProps) => {
  const classes = useStyles();

  return (
    <>
      {status === 'initiated' ? (
        <Alert
          severity="info"
          color="warning"
          className={`${classes.dense} ${classes.transparentBg}`}
          icon={<PlayArrowIcon fontSize="inherit" />}
        >
          <span className={classes.capitalize}>{status}</span>: {message}
          <span className={classes.helperText}>{helperText}</span>
        </Alert>
      ) : status === 'success' ? (
        <Alert
          severity="success"
          className={`${classes.dense} ${classes.transparentBg}`}
          icon={<CheckIcon fontSize="inherit" />}
        >
          <span className={classes.capitalize}>{status}</span>: {message}
          <span className={classes.helperText}>{helperText}</span>
        </Alert>
      ) : status === 'failed' ? (
        <Alert severity="error" icon={<ErrorIcon fontSize="inherit" />} className={classes.dense}>
          <span className={classes.capitalize}>{status}</span>: {message}
          <span className={classes.helperText}>{helperText}</span>
        </Alert>
      ) : null}
    </>
  );
};

export default StatusMessage;
