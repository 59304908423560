import React, { FC, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';

import { API_URL } from '../../constants';
import axios from '../../utils/axios';
import LabelValuePairDisplay from './detailComponents/LabelValuePairDisplay';
import { formatPhone } from '../../utils/helper';
import { TextField } from '../../components/library';
import { InputAdornment } from '@material-ui/core';

interface State {
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  paypalEmail?: string | null;
  isEditing: boolean;
  loading: boolean;
  errorMsg: string | null;
}

interface Props {
  submissionId: number;
  sellerId: number;
  partnerSiteId?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  paypalEmail?: string | null;
  cashBasedOffer: boolean;
  callback: () => Promise<any>;
}

const SellerInfoForm: FC<Props> = (props: Props) => {
  const initialState: State = {
    firstName: props.firstName,
    lastName: props.lastName,
    phone: props.phone,
    address: props.address,
    city: props.city,
    state: props.state,
    zip: props.zip,
    paypalEmail: props.paypalEmail,
    isEditing: false,
    loading: false,
    errorMsg: null,
  };

  const [
    {
      firstName,
      lastName,
      phone,
      address,
      city,
      state,
      zip,
      paypalEmail,
      isEditing,
      loading,
      errorMsg,
    },
    setState,
  ] = useState(initialState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSellerInfoUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    setState(prevState => ({ ...prevState, loading: true }));
    // const parseNullable = (val: any) => (val ? parseInt(val, 10) : null);

    const body = {
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      phone: phone?.trim(),
      address: address?.trim(),
      city: city?.trim(),
      state: state?.trim(),
      zip: zip?.trim(),
      paypalEmail: paypalEmail?.trim(),
    };

    try {
      const partnerSiteId = props.partnerSiteId;
      const updateUrl = partnerSiteId
        ? `${API_URL}/tradeup/partners/${partnerSiteId}/submission`
        : `${API_URL}/tradeup/syb/submission/${props.submissionId}/seller/${props.sellerId}`;
      const res = await axios.put(updateUrl, body);

      if (res && res.status < 300) {
        setState(prevState => ({ ...prevState, isEditing: false }));
        setState(prevState => ({ ...prevState, loading: false }));
        setState(prevState => ({ ...prevState, errorMsg: null }));
        await props.callback();
      } else {
        setState(prevState => ({ ...prevState, editError: true }));
        setState(prevState => ({ ...prevState, isEditing: true }));
        setState(prevState => ({
          ...prevState,
          errorMsg: res ? res.data : 'There was an issue',
        }));
        setState(prevState => ({ ...prevState, loading: false }));
      }
    } catch (err) {
      setState(prevState => ({ ...prevState, editError: true }));
      setState(prevState => ({ ...prevState, isEditing: true }));
      setState(prevState => ({
        ...prevState,
        errorMsg: err,
      }));
      setState(prevState => ({ ...prevState, loading: false }));
      console.error(err);
    }
  };

  return (
    <Grid container direction="row" justifyContent="space-evenly">
      <Grid container justifyContent="space-between" alignItems="start" sx={{ mb: 2 }}>
        <Grid item xs={8}>
          {errorMsg && (
            <Typography component="span" color="error">
              {errorMsg.toString()}
            </Typography>
          )}
        </Grid>
        {!isEditing && (
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            <Button
              color={loading ? 'info' : 'success'}
              variant="contained"
              disabled={loading}
              endIcon={loading && <CircularProgress color={'inherit'} size={'16px'} />}
              onClick={() => setState(prevState => ({ ...prevState, isEditing: true }))}
            >
              {loading ? 'Loading' : 'Edit'}
            </Button>
          </Grid>
        )}
      </Grid>
      {!isEditing ? (
        <Grid container spacing={2} direction="row">
          <Grid item xs={6}>
            <LabelValuePairDisplay
              labelValuePairs={[
                { label: 'Name', value: `${props.firstName} ${lastName}` },
                { label: 'Email', value: props.email },
                { label: 'Phone', value: formatPhone(phone) },
              ]}
              gridSize={{ label: 3, value: 9 }}
            />
          </Grid>
          <Grid item xs={6}>
            <LabelValuePairDisplay
              labelValuePairs={[
                {
                  label: 'Address',
                  value: (
                    <Typography>
                      <Typography>{address}</Typography>
                      <Typography>
                        {city} {state} {zip}
                      </Typography>
                    </Typography>
                  ),
                },
                { label: 'PayPal Email', value: paypalEmail },
              ]}
              gridSize={{ label: 3, value: 9 }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} direction="row">
          <Grid container item xs={6} spacing={1.75} direction="column">
            <Grid item>
              <TextField
                name="firstName"
                value={firstName ?? ''}
                size="small"
                onChange={handleChange}
                label="First Name"
                disabled={!isEditing}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                name="lastName"
                value={lastName ?? ''}
                size="small"
                onChange={handleChange}
                label="Last Name"
                disabled={!isEditing}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                name="email"
                value={props.email ?? ''}
                size="small"
                label="Email"
                disabled
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="end">Update in Shopify</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                name="phone"
                value={phone ?? ''}
                size="small"
                onChange={handleChange}
                label="Phone"
                disabled={!isEditing}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item xs={6} spacing={1.75} direction="column">
            <Grid item>
              <TextField
                name="address"
                value={address ?? ''}
                size="small"
                onChange={handleChange}
                label="Address"
                disabled={!isEditing}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                name="city"
                value={city ?? ''}
                size="small"
                onChange={handleChange}
                label="City"
                disabled={!isEditing}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                name="state"
                value={state ?? ''}
                size="small"
                onChange={handleChange}
                label="State"
                disabled={!isEditing}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                name="zip"
                value={zip ?? ''}
                size="small"
                onChange={handleChange}
                label="Zip"
                disabled={!isEditing}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                name="paypalEmail"
                value={paypalEmail ?? ''}
                size="small"
                onChange={handleChange}
                label="Paypal Email"
                disabled={!props.cashBasedOffer || !isEditing}
                variant="outlined"
                InputProps={{
                  endAdornment: !props.cashBasedOffer && (
                    <InputAdornment position="end">No Accepted Cash Offer</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {isEditing && (
        <Grid item xs={12} sx={{ textAlign: 'right', mt: 2 }}>
          <Button
            color={loading ? 'info' : 'success'}
            variant="contained"
            disabled={loading}
            endIcon={loading && <CircularProgress color={'inherit'} size={'16px'} />}
            onClick={handleSellerInfoUpdate}
          >
            {loading ? 'Saving' : 'Save'}
          </Button>
          <Button
            color="success"
            variant="outlined"
            onClick={() => setState(() => ({ ...initialState, isEditing: false }))}
            sx={{ ml: 1 }}
          >
            Cancel
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default SellerInfoForm;
