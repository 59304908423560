import { Dispatch } from 'redux';
import { CatalogProductState } from '../../../../reducers/product-reducer';
import { CreateConditionAlternates } from '../../../../services/catalog/types';
import { getEditProductVariantFieldListConfiguration } from '../productVariant/fields';
import { getProductFieldConfig } from '../fieldConfigurationData';
import { buildFormikFieldConfiguration } from '../formik/formik-fields';
import { CatalogFieldCategoryConfig, FormikFieldConfiguration } from '../formik/types';

interface AddProductFieldListParams {
  productStore: CatalogProductState;
  fieldCategoryConfigs: CatalogFieldCategoryConfig[];
  categoryId: number; // from form state
  submissionId?: number | null; // from form state
  createConditionAlternates: boolean; // from form state
  dispatch?: Dispatch; //needed for editable autocomplete fields that need to dispatch results (so that added values are usable immediately)
}

export const getAddProductFieldListConfiguration = ({
  productStore,
  fieldCategoryConfigs,
  categoryId,
  submissionId,
  createConditionAlternates,
  dispatch,
}: AddProductFieldListParams): FormikFieldConfiguration[] => {
  //product variant fields match the Edit PV form -
  //except without frame dimension fields

  let createConditionAlternatesDisabled = true;
  if (!submissionId) {
    const category = productStore.categories.find(c => c.id === categoryId);
    createConditionAlternatesDisabled =
      (category?.createConditionAlternates ?? CreateConditionAlternates.DISABLED) ===
      CreateConditionAlternates.DISABLED;
  }

  const productFieldConfigs = getProductFieldConfig({
    mode: 'create',
    categoryId,
    submissionId,
    createConditionAlternates,
    createConditionAlternatesDisabled,
  });

  const productFormikFields = productFieldConfigs.map(fieldBaseConfig =>
    buildFormikFieldConfiguration(
      // until we data-drive the product field configs, any category-specific behavior
      // will come from code, not fieldCategoryConfigs
      { fieldBaseConfig, fieldCategoryConfig: undefined },
      productStore,
      categoryId,
      { dispatch },
    ),
  );

  const productVariantFields = getEditProductVariantFieldListConfiguration({
    variantId: null,
    productStore,
    categoryId,
    productVariantComponents: [],
    initialSingleItemProcurement: {},
    fieldCategoryConfigs,
    skipFrameDimensions: true,
    userHasPriceChangePermissions: true, // all users can set price on initial creation
  });
  return [...productFormikFields, ...productVariantFields] as any;
};

interface EditProductFieldListParams {
  productStore: CatalogProductState;
  categoryId: number;
  dispatch?: Dispatch; //needed for editable autocomplete fields that need to dispatch results (so that added values are usable immediately)
}

export const getEditProductFieldListConfiguration = ({
  productStore,
  categoryId,
  dispatch,
}: EditProductFieldListParams): FormikFieldConfiguration[] => {
  const productFieldConfigs = getProductFieldConfig({
    mode: 'edit',
    categoryId,
  });

  const productFormikFields = productFieldConfigs.map(fieldBaseConfig =>
    buildFormikFieldConfiguration(
      // until we data-drive the product field configs, any category-specific behavior
      // will come from code, not fieldCategoryConfigs
      { fieldBaseConfig, fieldCategoryConfig: undefined },
      productStore,
      categoryId,
      { dispatch },
    ),
  );

  return productFormikFields;
};
