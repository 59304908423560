import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModuleWrapper = styled.div`
  padding: 3rem 4rem;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  }
`;
