import '../../styles/account.css';
import React, { FC, useState } from 'react';
import * as services from './changePasswordService';
import styles from '../../styledComponents/';
const { FancyForm, TextInput, FlexWrapper, SubmitInput, SectionHeader, Error } = styles;
const { initialState, setPassword, validate } = services;
const ChangePassword: FC = () => {
  const [{ secret, newPassword, newPasswordConfirm, error }, setState] = useState(initialState);

  const clearState = () => {
    setState({ ...initialState });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const isValidated: string = validate(secret, newPassword, newPasswordConfirm);
    if (isValidated === 'Validated') {
      try {
        setPassword(secret, newPassword, newPasswordConfirm);
        clearState();
      } catch (e) {
        setState(prevState => ({
          ...prevState,
          error: 'The current password is invalid',
        }));
      }
    } else {
      setState(prevState => ({ ...prevState, error: isValidated }));
    }
  };

  return (
    <FancyForm acceptCharset="UTF-8" onSubmit={handleSubmit}>
      <FlexWrapper flexDirection="column" alignItems="center">
        <SectionHeader>Change Password</SectionHeader>
        <TextInput
          placeholder="Current Password"
          name="secret"
          type="password"
          value={secret}
          onChange={handleChange}
        />
        <TextInput
          placeholder="New Password"
          name="newPassword"
          type="password"
          value={newPassword}
          onChange={handleChange}
        />
        <TextInput
          placeholder="Confirm Password"
          name="newPasswordConfirm"
          value={newPasswordConfirm}
          type="password"
          onChange={handleChange}
        />
        <SubmitInput type="submit" value="Update Password" />
        <Error>{error}</Error>
      </FlexWrapper>
    </FancyForm>
  );
};
export default ChangePassword;
