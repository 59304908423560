import { GET_TAG_FILTERS, GET_TAG_PAGE } from '../actions/types';

export default (
  state = {
    filters: { searchTerm: '' },
    page: 0,
  },
  action,
) => {
  switch (action.type) {
    case GET_TAG_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case GET_TAG_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    default:
      return state;
  }
};
