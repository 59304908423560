import React from 'react';
import { Box, Typography } from '@mui/material';

// todo:
// - add Storybook stories

interface Props {
  children?: React.ReactNode;
  legend?: string;
}

export const BoxWithLabel: React.FC<Props> = ({ children, legend }) => {
  return (
    <Box
      component="section"
      className="list-wrap"
      sx={{
        position: 'relative',
        border: '1px solid',
        borderRadius: 1,
        p: 2,
        width: 'inherit',
      }}
    >
      {legend && (
        <Typography
          variant="body1"
          sx={{
            float: 'left',
            my: -2.5,
            mx: 0,
            padding: '0 .5em',
            background: '#fff',
          }}
        >
          {legend}
        </Typography>
      )}
      <Box sx={{ pt: 2 }}>{children}</Box>
    </Box>
  );
};
