import { createContext, Context } from 'react';

const getErrorLogger = (funcName: string) => () =>
  console.error(`CycleTimingsContext does not have a set ${funcName} function.`);

class CycleTimingsContext {
  cycleTimingSku: string;
  cycleTimingSubId: string;
  setCycleTimingSku: (cycleTimingSku: string) => void;
  setCycleTimingSubId: (subId: string) => void;

  constructor(init?: Partial<CycleTimingsContext>) {
    this.cycleTimingSku = init?.cycleTimingSku ?? '';
    this.cycleTimingSubId = init?.cycleTimingSubId ?? '';
    this.setCycleTimingSku = init?.setCycleTimingSku ?? getErrorLogger('setCycleTimingSku');
    this.setCycleTimingSubId = init?.setCycleTimingSubId ?? getErrorLogger('setCycleTimingSubId');
  }
}

export const cycleTimingsContext: Context<CycleTimingsContext> = createContext(
  new CycleTimingsContext(),
);
