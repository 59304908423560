import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Text, Checkbox } from 'informed';
import '../../styles/modal.scss';

const permissionStyle = {
  width: '70%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
  padding: '2px 5px',
  borderRadius: '3px',
};

export default class UserModal extends Component {
  UNSAFE_componentWillMount() {
    document.addEventListener('keydown', this._handleEscKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleEscKey, false);
  }

  _handleEscKey = e => {
    if (e.keyCode === 27) {
      this.props.toggleModal();
    }
  };

  render() {
    const { toggleModal, formSuccess, permissions, error, loading, selectedUser } = this.props;
    const initialValues = {
      ...selectedUser,
      ...(selectedUser?.permissions &&
        Object.fromEntries(selectedUser.permissions.map(p => [`permission-${p}`, true]))),
    };
    return (
      <div className="backdrop offer-decline-backdrop">
        <div className="message inner-modal">
          <Form
            className="offer-decline-form"
            onSubmit={formState => formSuccess(formState)}
            initialValues={initialValues}
          >
            <span className="input-label-wrapper" style={{ marginTop: '0' }}>
              <label className="label">Name:</label>
              <Text className="text-input" field="name" />
            </span>
            <span className="input-label-wrapper" style={{ marginTop: '10px' }}>
              <label className="label">Email:</label>
              <Text className="text-input" field="email" />
            </span>
            <span
              className="flex-column-center"
              style={{
                width: '70%',
                justifyContent: 'space-around',
                margin: '25px auto 0 auto',
              }}
            >
              <h4>Permissions:</h4>
              <div style={permissionStyle}>
                <label>Active: </label>
                <Checkbox field="active" id="active" />
              </div>
              {permissions.map((p, i) => (
                <div
                  key={p.id}
                  style={!(i % 2) ? { ...permissionStyle, background: '#e5e5e5' } : permissionStyle}
                >
                  <label>{p.name.split('_').join(' ')}:</label>
                  <Checkbox field={`permission-${p.id}`} id={`permission-${p.id}`} />
                </div>
              ))}
            </span>
            <input
              type="submit"
              name="save_complete"
              value="SUBMIT"
              className="submit-button"
              disabled={loading}
            />
            {error && <span>Uh oh, something went wrong</span>}
          </Form>
          <button className="close-modal-button" onClick={toggleModal} disabled={loading} />
        </div>
      </div>
    );
  }
}

UserModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  formSuccess: PropTypes.func.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setApi: PropTypes.func,
};

UserModal.defaultProps = {
  setApi: null,
};
