import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import axios from '../../../utils/axios';
import { API_URL, stateArray } from '../../../constants';
import TupDealers from './tupDealers';
import Loader from '../../../components/loader';
import Modal from '../../../components/modals/genericFormModal';
import { PageWrapper } from '../../../styledComponents/wrappers';

export default class TradeupAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tupDealers: [],
      shortName: '',
      body: '',
      reasonMessage: '',
      editPermissions: false,
      tupSettingsPermission: false,
      displayModal: false,
      displaySybModal: false,
      error: false,
      success: false,
      loading: false,
      loadingEdit: false,
      dealerName: '',
      dealerUrl: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      emailDealer: true,
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    this.fetchSybDealers();
    const editPermissions = JSON.parse(localStorage.getItem('userPermissions')).includes(11);
    const tupSettingsPermission = JSON.parse(localStorage.getItem('userPermissions')).includes(13);
    this.setState({ editPermissions, tupSettingsPermission, loading: false });
  };

  handleChange = e => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  fetchSybDealers = async () => {
    try {
      const res = await axios.get(`${API_URL}/tradeup/syb/getsybdealers`);
      this.setState({ tupDealers: res.data });
    } catch (err) {
      console.error(err);
    }
  };

  resetState = () => {
    this.setState({
      dealerName: '',
      dealerUrl: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      emailDealer: true,
    });
  };

  addDealer = async e => {
    e.preventDefault();
    const {
      dealerName,
      dealerUrl,
      email,
      phone,
      address,
      city,
      state,
      zip,
      emailDealer,
    } = this.state;
    const body = {
      dealerName,
      dealerUrl,
      email,
      phone,
      address,
      city,
      state,
      zip,
      emailDealer,
    };

    const url = `${API_URL}/tradeup/syb/addDealer`;
    try {
      const response = await axios.post(url, body);
      if (response.status < 300) {
        NotificationManager.info('Dealer Created', 'Success', 4000);
        this.setState({ displaySybModal: false });
        this.fetchSybDealers();
        this.resetState();
      }
    } catch (err) {
      console.error(err);
      NotificationManager.error('Error creating dealer', 'Error', 4000);
    }
  };

  buildAddDealerForm = () => {
    const {
      dealerName,
      dealerUrl,
      email,
      phone,
      address,
      city,
      state,
      zip,
      emailDealer,
    } = this.state;
    return (
      <form className="add-dealer-form" style={{ width: '100%' }} onSubmit={this.addDealer}>
        <div className="flex-row">
          <section>
            <span className="input-label-wrapper">
              <label>Dealer Name</label>
              <input
                type="text"
                className="text-input"
                value={dealerName}
                name="dealerName"
                onChange={this.handleChange}
              />
            </span>
            <span className="input-label-wrapper">
              <label>URL</label>
              <input
                type="text"
                className="text-input"
                value={dealerUrl}
                name="dealerUrl"
                onChange={this.handleChange}
              />
            </span>
            <span className="input-label-wrapper">
              <label>Email</label>
              <input
                type="text"
                className="text-input"
                value={email}
                name="email"
                onChange={this.handleChange}
              />
            </span>
            <span className="input-label-wrapper">
              <label>Phone</label>
              <input
                type="text"
                className="text-input"
                value={phone}
                name="phone"
                onChange={this.handleChange}
              />
            </span>
          </section>
          <section>
            <span className="input-label-wrapper">
              <label>Address</label>
              <input
                type="text"
                className="text-input"
                value={address}
                name="address"
                onChange={this.handleChange}
              />
            </span>
            <span className="input-label-wrapper">
              <label>City</label>
              <input
                type="text"
                className="text-input"
                value={city}
                name="city"
                onChange={this.handleChange}
              />
            </span>
            <span className="input-label-wrapper">
              <label>State</label>
              <select
                className="text-input"
                value={state}
                name="state"
                onChange={this.handleChange}
              >
                <option value="" disabled={state}>
                  Select a State
                </option>
                {stateArray.map(v => (
                  <option value={v} key={v}>
                    {v}
                  </option>
                ))}
              </select>
            </span>
            <span className="input-label-wrapper">
              <label>Zip</label>
              <input
                type="text"
                className="text-input"
                value={zip}
                name="zip"
                onChange={this.handleChange}
              />
            </span>
          </section>
        </div>
        <section>
          <span className="input-label-wrapper">
            <label>Email dealer welcome email</label>
            <input
              type="radio"
              name="email_dealer"
              value={emailDealer}
              checked={emailDealer}
              onChange={() => this.setState({ emailDealer: !emailDealer })}
            />
            <label>Send welcome email seperately</label>
            <input
              type="radio"
              name="email_dealer"
              value={emailDealer}
              checked={!emailDealer}
              onChange={() => this.setState({ emailDealer: !emailDealer })}
            />
          </span>
          <input
            style={{ display: 'block' }}
            type="submit"
            className="classy-button margin-auto margin-top"
            value="create"
          />
        </section>
      </form>
    );
  };

  render() {
    const {
      editPermissions,
      loading,
      shortName,
      body,
      tupSettingsPermission,
      reasonMessage,
      displayModal,
      success,
      error,
    } = this.state;
    return (
      <PageWrapper className="react-table" style={{ overflow: 'hidden' }}>
        <section
          ref={section => {
            this.tupDealers = section;
          }}
        >
          <h2 className="text-center">TUP/SYB Dealers</h2>
          <div className="react-table" style={{ marginBottom: '5px' }}>
            <button
              className="classy-button"
              onClick={() => this.setState({ displaySybModal: true })}
            >
              Add SYB Dealer
            </button>
          </div>
          <TupDealers dealers={this.state.tupDealers} />
        </section>
        {this.state.displaySybModal && (
          <Modal
            success={success}
            error={error}
            closeModal={() => this.setState({ displaySybModal: false })}
            form={this.buildAddDealerForm()}
          />
        )}
        <Loader loading={loading || this.state.loadingEdit} />
      </PageWrapper>
    );
  }
}
