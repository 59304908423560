import { AxiosResponse } from 'axios';
import { API_URL } from '../../constants';
import axios from '../../utils/axios';
import {
  SearchOrCreateCycleTiming,
  CycleTiming,
  CycleTimingItem,
  AddNewItemToCycleTiming,
  Status,
} from './types';

export function updateCycleTiming(
  id: number,
  updatePayload: { status?: Status; isTraining?: boolean },
): Promise<void | AxiosResponse<CycleTiming>> {
  const url = `${API_URL}/service/cycle-timings/${id}`;
  return axios.put(url, updatePayload);
}

export function createCycleTiming(
  body: SearchOrCreateCycleTiming,
): Promise<void | AxiosResponse<CycleTiming>> {
  const url = `${API_URL}/service/cycle-timings`;
  return axios.post(url, body);
}

export function searchForCycleTiming(
  params: SearchOrCreateCycleTiming,
): Promise<void | AxiosResponse<CycleTiming>> {
  const url = `${API_URL}/service/cycle-timings`;
  return axios.get(url, { params });
}

export function getCycleTimingStations(): Promise<void | AxiosResponse<
  { id: string; description: string }[]
>> {
  const url = `${API_URL}/service/stations`;
  return axios.get(url);
}

export function getCycleTimingById(
  id: string | number,
): Promise<void | AxiosResponse<CycleTiming>> {
  const url = `${API_URL}/service/cycle-timings/${id}`;
  return axios.get(url);
}

export function upsertItemsToCycleTiming(
  id: number,
  upsertItems: Array<AddNewItemToCycleTiming | CycleTimingItem>,
  forceNewForExistingCompletedTiming?: boolean,
): Promise<void | AxiosResponse<CycleTiming>> {
  const url = `${API_URL}/service/cycle-timings/${id}/items`;
  return axios.put(url, { items: upsertItems, forceNewForExistingCompletedTiming });
}
