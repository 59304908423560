import { FieldsMap, GroupedFormikFieldsMapValueProps, ProductFormGroups } from './types';
import { CatalogProductState } from '../../../reducers/product-reducer';
import { ComponentSpecificationType } from '../../../services/catalog/types';
import { CATEGORIES_GROUPED_BY_MAJOR, selectVariantFieldUnitsByCategoryId } from './categories';

export const showAllPromoTextCategories = [
  ...CATEGORIES_GROUPED_BY_MAJOR.BIKES,
  ...CATEGORIES_GROUPED_BY_MAJOR.FRAMES,
  ...CATEGORIES_GROUPED_BY_MAJOR.WHEELS,
];

export type ProductVariantComponentSpecKeys = {
  [key: string]: any;
};

export const getProductVariantComponentSpecsFields = (
  productVariantComponents: {
    componentType: {
      id: number;
      name: string;
      componentSpecificationTypes: ComponentSpecificationType[];
    };
  }[],
  productStore: CatalogProductState,
  categoryId: number | null,
): FieldsMap<
  ProductVariantComponentSpecKeys,
  GroupedFormikFieldsMapValueProps<ProductFormGroups>
> => {
  const componentTypeIdsAndNames: { [id: string]: string } = {};
  const flattenedComponentSpecTypes = productVariantComponents
    .map(pvc => {
      // collect component type [id]: name - for subGroup. ie: "Brakes", "Cassette"...
      componentTypeIdsAndNames[pvc.componentType.id] = pvc.componentType.name;
      return pvc.componentType.componentSpecificationTypes;
    })
    .flat();

  return flattenedComponentSpecTypes.reduce((accCst, cst, cstIdx) => {
    const appendUnits = selectVariantFieldUnitsByCategoryId(cst.name, categoryId);
    const hasComponentSpecificationFieldMap = !!productStore.autosuggestFields
      ?.componentSpecificationFieldMap[cst.id];
    const appendUnitsComponent = !!appendUnits && {
      componentType: 'appendUnits',
      unitSuffix: appendUnits,
    };
    const autoCompleteComponent = hasComponentSpecificationFieldMap && {
      options: productStore.autosuggestFields?.componentSpecificationFieldMap[
        cst.id
      ].values.map(v => ({ id: v.value, name: v.value })),
      labelKey: 'name',
      componentType: 'autocomplete',
    };
    return {
      ...accCst,
      [`productVariantComponentSpecs[${cstIdx}].value`]: {
        label: `${cst.name}`,
        groupBy: ProductFormGroups['Component Specifications'],
        subGroup: componentTypeIdsAndNames[cst.componentTypeId],
        ...(hasComponentSpecificationFieldMap ? autoCompleteComponent : appendUnitsComponent),
      },
    };
  }, {});
};
