import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import styled from 'styled-components';
import { Link as RouterLink, Prompt } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import {
  addProductAndVariants,
  updateProductPromotionalTexts,
} from '../../../services/catalog/services';
import { PageWrapper } from '../../../styledComponents/wrappers';
import { PageHeader } from '../../../styledComponents/headers';
import ErrorBoundary from '../../../components/ErrorBoundary';
import StatusMessage from './StatusMessage';
import CreatedEntity from './CreatedEntity';

import { Product, AdditionalEffect, Inspection } from '../../../services/catalog/types';

import { AddProductAndVariantValues } from './types';
import { ErrorMessage } from '../../../components/library';
import { handleApiError, getDuplicateUpcError } from '../utils';
import Barcode from '../../../components/barcode';
import { RECEIVING_PAGE_ROUTE } from '../../receiving/ReceivingPage';
import { buildPromotionalTextsPayload } from '../utils/buildPromotionalTextsPayload';
import DuplicateUpcErrorMessage from '../DuplicateUpcErrorMessage';
import { UpcErrorProperties } from '../utils/index';
import { addProductContext } from '../utils/productVariant/field-context';
import AddProductForm from './AddProductForm';
import { getAddProductInitialValues } from '../utils/product/form-utils';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../reducers/types';
import { getAddProductValidation } from '../utils/validation';
import ButtonSection from '../editProduct/ButtonSection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    createdLinks: {
      backgroundColor: theme.palette.grey[100],
      display: 'flex',
      flexDirection: 'column',
      paddingInline: theme.spacing(1),
      paddingBlock: theme.spacing(0),
      borderTop: `1px solid ${theme.palette.divider}`,
      '& > li:not(:first-child)': {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
    },
    successOverline: {
      color: theme.palette.success.main,
      lineHeight: '1em',
      marginBlock: theme.spacing(1),
    },
    bold: { fontFamily: theme.typography.bold },
  }),
);

export const FormComponent = styled(Form)`
  padding: 20px;
  margin-bottom: 20px;
`;

interface AddProductProps {
  onCancelForm: () => void;
}

const AddProduct: React.FC<AddProductProps> = ({ onCancelForm }) => {
  const [error, setError] = useState<string | string[] | undefined>();
  const [upcError, setUpcError] = useState<UpcErrorProperties | undefined>();
  const [promotionalTextError, setPromotionalTextError] = useState(false);
  const [createdProducts, setCreatedProducts] = useState<Product[]>([]);
  const [createdServicePlans, setCreatedServicePlans] = useState<Inspection[]>([]);
  const [additionalEffects, setAdditionalEffects] = useState<AdditionalEffect[]>();
  const [printOpen, setPrintOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const productStore = useSelector((state: GlobalState) => state.product);

  const {
    selectedCategoryId,
    initialCreatePoValue,
    selectedSubmission,
    enteredTrackingNumber,
  } = useContext(addProductContext);

  const initialValues = useMemo(
    () =>
      getAddProductInitialValues({
        selectedSubmission,
        initialCreatePoValue,
        selectedCategoryId,
        enteredTrackingNumber,
        brands: productStore.brands,
        categories: productStore.categories,
      }),
    [
      productStore,
      //should i be destructuring these? or just pass in the whole object? or spread?
      selectedSubmission,
      initialCreatePoValue,
      enteredTrackingNumber,
      selectedCategoryId,
    ],
  );

  const validationSchema = useMemo(() => getAddProductValidation(), []);

  const onSubmit = async (
    values: AddProductAndVariantValues,
    formikHelpers: FormikHelpers<AddProductAndVariantValues>,
  ) => {
    setError('');

    try {
      const addProductResponse = await addProductAndVariants({ values, selectedSubmission });
      if (addProductResponse) {
        setCreatedProducts(addProductResponse.data.createdProducts);
        setAdditionalEffects(addProductResponse.data.additionalEffects);
        setCreatedServicePlans(addProductResponse.data.createdServicePlans);

        try {
          const productId = addProductResponse.data.createdProducts[0].id;
          const promoTexts = buildPromotionalTextsPayload(values);

          await updateProductPromotionalTexts(productId, promoTexts);
        } catch (err) {
          setPromotionalTextError(true);
        }

        formikHelpers.resetForm();
      }
    } catch (err) {
      handleApiError(err, setError);
    }
  };

  const classes = useStyles();

  useEffect(() => {
    const newUpcError = getDuplicateUpcError(error);
    setUpcError((currentUpcError: UpcErrorProperties | undefined) =>
      currentUpcError?.productId !== newUpcError?.productId ? newUpcError : currentUpcError,
    );
  }, [error]);

  useEffect(() => {
    const scrollToError = () => {
      if (ref) {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }
    };
    scrollToError();
  }, [upcError]);

  return (
    <PageWrapper>
      <PageHeader>Add New Product</PageHeader>
      <ErrorBoundary>
        {upcError ? (
          <DuplicateUpcErrorMessage
            msg={upcError.msg}
            upc={upcError.upc}
            productId={upcError.productId}
            sku={upcError.sku}
            ref={ref}
          />
        ) : (
          <ErrorMessage error={error} />
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={false}
        >
          {({ dirty }) => (
            <FormComponent>
              <Prompt when={dirty} message="Are you sure you want to exit without saving?" />
              <AddProductForm />
              <ButtonSection onCancel={onCancelForm} />
            </FormComponent>
          )}
        </Formik>
      </ErrorBoundary>
      <Dialog open={!!createdProducts.length} fullWidth maxWidth="xs">
        <DialogTitle>
          <Box display="flex" flexDirection="column">
            <Typography variant="overline" className={classes.successOverline}>
              SUCCESS
            </Typography>
            <Box>
              <span className={classes.bold}>{createdProducts[0]?.title}</span> has been created.
            </Box>
          </Box>
        </DialogTitle>
        <Box>
          <Box mb={4} mx={2}>
            {createdProducts?.length && (
              <StatusMessage status="success" message="Product in Item Catalog" />
            )}
            {promotionalTextError && (
              <StatusMessage
                status="failed"
                message="Product Promotional Texts"
                helperText="Use the edit product form to retry."
              />
            )}
            {createdProducts.length > 1 && (
              <StatusMessage status="success" message="Product Alternates" />
            )}
            {additionalEffects?.map(effect => (
              <>
                <StatusMessage status={effect.outcome} message={effect.description} />
              </>
            ))}
          </Box>
          <Box className={classes.listContainer}>
            <List className={classes.createdLinks}>
              {createdProducts.map(p => {
                const variantSku = p.productVariants[0].sku;
                return (
                  <ListItem dense key={p.id}>
                    <CreatedEntity
                      link={`/catalog/products/${p.id}`}
                      title={'Product ID'}
                      linkText={`${p.id} - SKU: ${variantSku}`}
                    />
                  </ListItem>
                );
              })}
            </List>
            <List className={classes.createdLinks}>
              {createdServicePlans.map(p => {
                return (
                  <ListItem dense key={p.id}>
                    <CreatedEntity
                      link={`/service/service-plans/${p.id}`}
                      title={'Service Plan ID'}
                      linkText={`${p.id}`}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
        <DialogActions>
          {createdProducts.length && (
            <>
              <Button color="primary" variant="text" onClick={() => setPrintOpen(true)}>
                Print Label
              </Button>
              <Button
                to={`/catalog/products/${createdProducts[0].id}`}
                variant="text"
                color="secondary"
                component={RouterLink}
              >
                Close
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Dialog open={printOpen} onClose={() => setPrintOpen(false)}>
        <DialogTitle>{'Print Additional Label'}</DialogTitle>
        <DialogContent>
          {createdProducts.length && (
            <Barcode
              text={createdProducts[0].productVariants[0].sku}
              title={`${createdProducts[0].brand?.name || ''} ${createdProducts[0].model ||
                ''}`.trim()}
            />
          )}
        </DialogContent>
        <DialogActions>
          {createdProducts?.length && (
            <>
              <Button
                color="primary"
                variant="text"
                to={RECEIVING_PAGE_ROUTE}
                component={RouterLink}
              >
                Return to Receiving
              </Button>
              <Button variant="text" color="secondary" onClick={() => setPrintOpen(false)}>
                Close
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </PageWrapper>
  );
};

export default AddProduct;
