import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Nav from './components/nav/Nav';
import './styles/private-route.css';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const { navExpanded } = useSelector(state => state.nav);
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === true ? (
          <div className={navExpanded ? 'main-content-expanded' : 'main-content-collapsed'}>
            <Nav />
            <Component {...props} {...rest} />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default PrivateRoute;
