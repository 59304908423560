import { CatalogFieldCategoryConfig } from '../formik/types';
import { Context, createContext } from 'react';
import { Submission } from '../../../../services/catalog/types';

class FieldCategoryConfigContext {
  fieldCategoryConfigs: CatalogFieldCategoryConfig[];

  constructor(init?: Partial<FieldCategoryConfigContext>) {
    this.fieldCategoryConfigs = init?.fieldCategoryConfigs ?? [];
  }
}

class AddProductContext {
  selectedCategoryId?: number;
  initialCreatePoValue?: boolean;
  selectedSubmission?: Submission;
  enteredTrackingNumber?: string;
}

export const fieldCategoryConfigContext: Context<FieldCategoryConfigContext> = createContext(
  new FieldCategoryConfigContext(),
);

export const addProductContext: Context<AddProductContext> = createContext(new AddProductContext());
