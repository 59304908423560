import React from 'react';
import Modal from '../../../components/modals/modal';
import UserModal from '../../../components/modals/userModal';
import axios from '../../../utils/axios';
import { API_URL } from '../../../constants';
import { StripedDataGrid } from '../../../styledComponents/datagrid';
import { PageWrapper } from '../../../styledComponents/wrappers';

export default class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      submittSuccess: false,
      successMessage: '',
      submitError: false,
      displayModal: false,
      editModalId: null,
      createModalToggled: false,
      modalError: false,
      users: [],
      selectedUser: {},
      permissions: [],
      paginationModel: {
        pageSize: 15,
        page: 0,
      },
    };

    this.columns = [
      {
        headerName: 'Id',
        field: 'id',
        flex: 1,
      },
      {
        headerName: 'Name',
        field: 'name',
        flex: 3,
      },
      {
        headerName: 'Email',
        field: 'email',
        flex: 3,
      },
      {
        headerName: 'Active',
        field: 'active',
        flex: 1,
      },
    ];
  }

  getUserProps = async (state, id) => {
    this.setState({
      loading: true,
    });
    const resUser = await axios.get(`${API_URL}/admin/users/user/${id}`);
    const resPermissions = await axios.get(`${API_URL}/admin/users/permissions`);
    const user = resUser.data;

    this.setState({
      editModalId: id,
      selectedUser: {
        name: user.name,
        email: user.email,
        permissions: user.permissions.map(p => p.id),
        active: user.active,
      },
      permissions: resPermissions.data,
      loading: false,
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (state, instance) => {
    this.setState({ loading: true });

    // fetch your data
    const res = await axios.get(`${API_URL}/admin/users`);
    const { users, count } = res.data;
    this.setState((prevState, props) => ({
      ...prevState,
      users,
      totalCount: count,
      loading: false,
      modalLoading: false,
    }));
  };

  toggleEditModal = () => {
    this.setState({ editModalId: null });
  };

  toggleCreateModal = () => {
    this.setState({ createModalToggled: !this.state.createModalToggled });
  };

  toggleSuccessModal = () => {
    this.setState({ displayModal: !this.state.displayModal });
  };

  editFormSuccess = async (formState, phone) => {
    const { editModalId } = this.state;
    this.setState({
      modalError: false,
      modalLoading: true,
      displayModal: true,
      submittSuccess: false,
      editModalId: null,
    });
    const keys = Object.keys(formState);
    const permissions = [];
    for (let i = 0; i < keys.length; i++) {
      const nIndex = keys[i].indexOf('permission-');
      if (nIndex >= 0 && formState[keys[i]] === true) {
        permissions.push(Number.parseInt(keys[i].substring(nIndex + 'permission-'.length), 10));
      }
    }

    try {
      const res = await axios.post(`${API_URL}/admin/users/user/${editModalId}`, {
        name: formState.name,
        email: formState.email,
        permissions,
        active: formState.active,
      });
      if (res.status < 300) {
        this.setState({
          submittSuccess: true,
          successMessage: 'User updated!',
        });
        this.fetchData();
      } else if (res.status > 300) {
        this.setState({ submitError: true, submittSuccess: false });
        throw new Error('Did not update');
      } else {
        throw new Error('Did not update');
      }
    } catch (e) {
      this.setState({
        modalError: true,
      });
    }
  };

  createFormSuccess = async (formState, phone) => {
    this.setState({
      modalError: false,
      modalLoading: true,
      displayModal: true,
      submittSuccess: false,
      editModalId: null,
    });

    const keys = Object.keys(formState);

    const permissions = [];
    for (let i = 0; i < keys.length; i++) {
      const nIndex = keys[i].indexOf('permission-');
      if (nIndex >= 0 && formState[keys[i]] === true) {
        permissions.push(Number.parseInt(keys[i].substring(nIndex + 'permission-'.length), 10));
      }
    }

    try {
      const res = await axios.post(`${API_URL}/admin/users/user`, {
        name: formState.name,
        email: formState.email,
        permissions,
      });
      if (res.status < 300) {
        this.setState({
          submittSuccess: true,
          successMessage: 'User created!',
        });
        this.fetchData();
      } else if (res.status > 300) {
        this.setState({ submitError: true, submittSuccess: false });
        throw new Error('Did not update');
      } else {
        throw new Error('Did not update');
      }
    } catch (e) {
      this.setState({
        modalError: true,
      });
    }
  };

  formApiValues = () => this.state.selectedUser;

  openCreateModal = async () => {
    this.setState({ loading: true });
    const res = await axios.get(`${API_URL}/admin/users/permissions`);
    this.setState({
      loading: false,
      permissions: res.data,
    });
    this.toggleCreateModal();
  };

  render = () => (
    <PageWrapper style={{ overflow: 'hidden' }}>
      <span className="list-title-nav-wrapper">
        <button className="classy-button" onClick={this.openCreateModal}>
          Add New User
        </button>
      </span>
      {this.state.editModalId && (
        <UserModal
          formSuccess={this.editFormSuccess}
          id={this.state.editModalId}
          toggleModal={this.toggleEditModal}
          getValues={this.formApiValues}
          error={this.state.modalError}
          loading={this.state.modalLoading}
          permissions={this.state.permissions}
          selectedUser={this.state.selectedUser}
        />
      )}
      {this.state.createModalToggled && (
        <UserModal
          formSuccess={this.createFormSuccess}
          toggleModal={this.toggleCreateModal}
          error={this.state.modalError}
          loading={this.state.modalLoading}
          permissions={this.state.permissions}
        />
      )}
      {this.state.displayModal && (
        <Modal
          message={this.state.successMessage}
          onClose={this.toggleSuccessModal}
          submitted={this.state.submittSuccess}
          submitError={this.state.submitError}
        />
      )}
      <p className="count-field">Total Users: {this.state.totalCount}</p>
      <StripedDataGrid
        columns={this.columns}
        rows={this.state.users}
        loading={this.state.loading}
        paginationModel={this.state.paginationModel}
        onPaginationModelChange={m => this.setState({ paginationModel: m })}
        pageSizeOptions={[15, 30, 60]}
        onRowClick={params => this.getUserProps(this.state, params.id)}
        initialState={{
          filter: {
            filterModel: {
              items: [
                {
                  field: 'active',
                  operator: 'equals',
                  value: 'true',
                },
              ],
            },
          },
        }}
      />
    </PageWrapper>
  );
}
