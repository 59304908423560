/* eslint-disable react/display-name */
import React from 'react';
import { parse } from 'json2csv';
import MaterialTable from '@material-table/core';
import axios from '../../utils/axios';
import Loader from '../../components/loader';
import fileDownload from 'js-file-download';
import { API_URL } from '../../constants';

interface InventoryState {
  inventoryDetail: any;
  searchText: string;
  loading: boolean;
  location: number;
  warehouses: Warehouse[];
  error: string;
}

interface Warehouse {
  code: string;
  id: number;
}
interface InventoryProps {
  sku: string;
  classes: any;
}
export default class Inventory extends React.Component<InventoryProps, InventoryState> {
  constructor(props: InventoryProps) {
    super(props);
    this.state = {
      inventoryDetail: [],
      searchText: '',
      warehouses: [],
      loading: false,
      location: 1,
      error: '',
    };
  }
  componentDidMount = () => {
    this.getWarehouses();
  };

  getWarehouses = async () => {
    const res = await axios.get(`${API_URL}/service/locations`);
    if (res) {
      this.setState({ warehouses: res.data });
    }
  };

  downloadCSV = (): void => {
    this.setState({ loading: true });
    const fields = ['bin', 'sku', 'available', 'onhand'];
    const opts = { fields };
    try {
      if (this.state.inventoryDetail) {
        const csv = parse(this.state.inventoryDetail, opts);
        fileDownload(csv, `${this.state.searchText}_inventory.csv`);
      }
    } catch (err) {
      this.setState({ error: err });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleSubmit = async (e: any) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    const { searchText, location } = this.state;
    try {
      const searchArray = searchText.split(',');
      const params = {
        searchArray,
        location,
      };
      const result = await axios.get(`${API_URL}/service/locateInventory`, {
        params,
      });
      if (result) {
        this.setState({ inventoryDetail: result.data || [] });
      }
      this.setState({ error: '' });
    } catch (error) {
      this.setState({
        error: error.response ? error.response.data.message : '',
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleChange = (e: any) => {
    const { name, value } = e.target;
    e.preventDefault();
    this.setState(prevState => ({ ...prevState, [name]: value }));
  };

  render() {
    const { searchText, warehouses } = this.state;
    return (
      <main
        style={{
          marginTop: '100px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              width: '200px',
            }}
            onSubmit={this.handleSubmit}
          >
            <p style={{ marginBottom: '10px' }}>
              Enter bins below coma separated without spaces (e.g. C1,C2,C3)
            </p>
            <label style={{ marginTop: '10px' }}>Enter Bins: </label>
            <input type="text" name="searchText" onChange={this.handleChange} value={searchText} />
            <span>
              <label style={{ marginTop: '10px' }}>Select a warehouse</label>
              <select name="location" onChange={this.handleChange}>
                {warehouses.map(warehouse => {
                  return (
                    <option value={warehouse.id} key={warehouse.id}>
                      {warehouse.code}
                    </option>
                  );
                })}
              </select>
            </span>
            <input className="classy-button" type="submit" value="Submit" />
            <button
              style={{ marginTop: '15px' }}
              className="classy-button"
              onClick={this.downloadCSV}
            >
              Download CSV
            </button>
            <p>{this.state.error}</p>
          </form>
        </div>
        <MaterialTable
          columns={[
            {
              title: 'SKU',
              field: 'sku',
              editable: 'never',
            },
            {
              title: 'Bin',
              field: 'bin',
              editable: 'never',
            },
            {
              title: 'Available',
              field: 'available',
              editable: 'never',
            },
            {
              title: 'On Hand',
              field: 'onhand',
              editable: 'never',
            },
          ]}
          editable={{
            isDeletable: undefined,
            isEditable: undefined,
            onRowAdd: undefined,
            onRowUpdate: undefined,
          }}
          title="Inventory"
          data={this.state.inventoryDetail}
          options={{ paging: false, sorting: true, search: false }}
          style={{ width: '90%' }}
        />
        <Loader loading={this.state.loading} />
      </main>
    );
  }
}
