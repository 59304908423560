import { useState, useCallback } from 'react';
import { getExportsForTesting } from '../../testing/getExportsForTesting';

// if it's not an error, clear it.
type ErrorUpdate = Error | undefined | unknown;
const isErrorUpdateAnError = (err: ErrorUpdate): err is Error => err instanceof Error;

const getErrorLogger = (funcName: string) => () => {
  console.error(`UseErrorBus does not have a set ${funcName} function.`);
};

export class ErrorBus {
  hasErrors: boolean;
  errors: { [key: string]: Error };
  setError: (key: string, error: ErrorUpdate) => void;
  clearAllErrors: () => void;

  constructor(init?: Partial<ErrorBus>) {
    this.hasErrors = false;
    this.errors = init?.errors ?? {};
    this.setError = init?.setError ?? getErrorLogger('setError');
    this.clearAllErrors = init?.clearAllErrors ?? getErrorLogger('clearAllErrors');
  }
}

export const useErrorBus = (contextName: string) => {
  const [errors, setErrors] = useState<ErrorBus['errors']>({});

  const setError = useCallback(
    (key: string, error: ErrorUpdate) => {
      setErrors(oldErrors => {
        if (isErrorUpdateAnError(error)) {
          console.error(`${contextName} ${key}`, error);
          return { ...oldErrors, [key]: error };
        } else if (oldErrors[key]) {
          const newErrors = { ...oldErrors };
          delete newErrors[key];
          return newErrors;
        }
        return oldErrors;
      });
    },
    [setErrors, contextName],
  );

  const clearAllErrors = useCallback(() => setErrors({}), [setErrors]);

  return { hasErrors: Object.keys(errors).length != 0, errors, setError, clearAllErrors };
};

// testing
const setupMockErrorBus = () => {
  const setErrorCalls: any[] = [];
  return {
    errorBus: new ErrorBus({
      setError: (...args: any[]) => setErrorCalls.push(args),
    }),
    setErrorCalls,
  };
};

export const exportsForTestingUseErrorBus = getExportsForTesting({ setupMockErrorBus });
