import * as Yup from 'yup';
import { FrameDimension } from '../../../services/catalog/types';
import { AddProductAndVariantValues } from '../addProduct/types';
import { EditProductValues } from './types';

const getProductVariantFrameDimensionSchema = (frameDimensions: FrameDimension[]) =>
  frameDimensions.reduce((initial, fd) => {
    if (fd.alwaysDisplayText) {
      return {
        ...initial,
        [fd.name]: Yup.object().shape({
          textValue: Yup.string().nullable(),
        }),
      };
    }

    return {
      ...initial,
      [fd.name]: Yup.object().shape(
        {
          minValue: Yup.mixed().when('maxValue', {
            is: (value: any) => !!value,
            then: Yup.number()
              .typeError('Min value must be a number')
              .required('Min must be entered if Max is entered'),
            otherwise: Yup.mixed().nullable(),
          }),
          maxValue: Yup.mixed().when('minValue', {
            is: (value: any) => !!value,
            then: Yup.number()
              .typeError('Max value must be a number')
              .required('Max must be entered if Min is entered'),
            otherwise: Yup.mixed().nullable(),
          }),
          textValue: Yup.string().nullable(),
        },
        [['minValue', 'maxValue']],
      ),
    };
  }, {});

export const getAddProductValidation = () => {
  return Yup.object<{ [key in keyof Required<AddProductAndVariantValues>]: Yup.AnySchema }>({
    // Product Fields
    categoryId: Yup.number()
      .typeError('Must select a category')
      .required('Must select a category'),
    brandId: Yup.number()
      .typeError(
        `Please select a brand. If the correct brand is not available, please select "Unknown" and submit a helpdesk ticket.`,
      )
      .required(
        `Please select a brand. If the correct brand is not available, please select "Unknown" and submit a helpdesk ticket.`,
      ),
    title: Yup.string()
      .min(1)
      .max(255)
      .required('Internal Title is required'),
    displayTitle: Yup.string()
      .min(1)
      .max(255)
      .nullable(),
    model: Yup.string().nullable(),
    disciplineId: Yup.number().nullable(),
    createConditionAlternates: Yup.boolean(),
    conditionAlternate: Yup.string().oneOf(['A', 'B', 'New']),
    certifiedPreOwned: Yup.boolean().required(),
    description: Yup.string().nullable(),
    sellPreService: Yup.boolean(),
    enteredTrackingNumber: Yup.string().nullable(),
    productSubCategoryId: Yup.number().nullable(),

    // Product Promotional Text Fields
    headline: Yup.string().nullable(),
    longDescription: Yup.string().nullable(),
    features: Yup.string().nullable(),
    upgrades: Yup.string().nullable(),
    masterMechanicsNotes: Yup.string().nullable(),

    // Product Variant Fields
    createPurchaseOrder: Yup.boolean().required(),
    submissionId: Yup.number().nullable(),
    serialNumber: Yup.string().nullable(),
    condition: Yup.string().nullable(),
    upc: Yup.string().nullable(),
    mpn: Yup.string().nullable(),
    qbpId: Yup.string().nullable(),
    year: Yup.number()
      .positive()
      .nullable(),
    pipelineId: Yup.number().nullable(),
    statusFlagId: Yup.number().nullable(),
    frameMaterial: Yup.string().nullable(),
    frameHeadset: Yup.string().nullable(),
    frameRearAxleSpacing: Yup.string().nullable(),
    frameRearShockTravel: Yup.string().nullable(),
    drivetrainBrandId: Yup.number().nullable(),
    drivetrainConfiguration: Yup.string().nullable(),
    drivetrainShiftingTypeId: Yup.number().nullable(),
    componentIntendedUseId: Yup.number().nullable(),
    sizeClassId: Yup.number().nullable(),
    brakeType: Yup.string().nullable(),
    weight: Yup.string().nullable(),
    costUsd: Yup.number().required('Item Cost is required'),
    msrpUsd: Yup.number().nullable(),
    startingPriceUsd: Yup.number().nullable(),
    compareAtPriceUsd: Yup.number().nullable(),
    promotionalPricingFlag: Yup.boolean(),
    mapPricing: Yup.boolean(),
    gender: Yup.string().nullable(),
    material: Yup.string().nullable(),
    apparelSleeveLength: Yup.string().nullable(),
    frameRearShockTravelRange: Yup.string().nullable(),
    tireType: Yup.string().nullable(),
    configurationDetails: Yup.string().nullable(),
    chargerIncluded: Yup.string().nullable(),
    mileage: Yup.string().nullable(),
    keyIncluded: Yup.string().nullable(),
    tubelessCompatibility: Yup.string().nullable(),
    frameRearTriangleMaterial: Yup.string().nullable(),
    electricTopSpeed: Yup.string().nullable(),
    productVariantAttributesByTypeId: Yup.mixed(),
    fulfillmentProvider: Yup.string().nullable(),
  });
};

export const getEditProductVariantValidation = ({
  frameDimensions,
  variantType,
}: {
  frameDimensions: FrameDimension[];
  variantType: string;
}) => {
  const productVariantFrameDimensionsObjectShape = getProductVariantFrameDimensionSchema(
    frameDimensions,
  );

  const msg = `${variantType} is required since product has Variation Type of ${variantType} selected`;
  const requiredVariantTypeString = Yup.string()
    .typeError(msg)
    .required(msg);

  // TODO: Add type for this object with all the keys from variant values
  return Yup.object({
    wheelSize: Yup.string().nullable(),
    upc: Yup.string().nullable(),
    size:
      variantType.toLowerCase() === 'size' ? requiredVariantTypeString : Yup.string().nullable(),
    year: Yup.number()
      .positive()
      .nullable(),
    condition: Yup.string().nullable(),
    pipelineId: Yup.number().nullable(),
    frameMaterial: Yup.string().nullable(),
    frameHeadset: Yup.string().nullable(),
    frameRearAxleSpacing: Yup.string().nullable(),
    frameRearShockTravel: Yup.string().nullable(),
    drivetrainBrandId: Yup.number().nullable(),
    drivetrainConfiguration: Yup.string().nullable(),
    drivetrainShiftingTypeId: Yup.number().nullable(),
    componentIntendedUseId: Yup.number().nullable(),
    productVariantFrameDimensions: Yup.array().of(
      Yup.object().shape(productVariantFrameDimensionsObjectShape),
    ),
    sizeClassId: Yup.number().nullable(),
    brakeType: Yup.string().nullable(),
    color:
      variantType.toLowerCase() === 'color' ? requiredVariantTypeString : Yup.string().nullable(),
    conditionDescription: Yup.string().nullable(),
    conditionNotes: Yup.string().nullable(),
    msrpUsd: Yup.number().nullable(),
    startingPriceUsd: Yup.number().nullable(),
    currentSalePriceUsd: Yup.number().nullable(),
    costUsd: Yup.number().required('Item Cost is required'),
    mapPricing: Yup.boolean(),
    gender: Yup.string().nullable(),
    material: Yup.string().nullable(),
    apparelSleeveLength: Yup.string().nullable(),
    frameRearShockTravelRange: Yup.string().nullable(),
    tireType: Yup.string().nullable(),
    configurationDetails: Yup.string().nullable(),
    chargerIncluded: Yup.string().nullable(),
    mileage: Yup.string().nullable(),
    keyIncluded: Yup.string().nullable(),
    tubelessCompatibility: Yup.string().nullable(),
    frameRearTriangleMaterial: Yup.string().nullable(),
    electricTopSpeed: Yup.string().nullable(),
  });
};

export const editProductValidation = Yup.object<
  { [key in keyof Required<EditProductValues>]: Yup.AnySchema }
>({
  model: Yup.string(),
  title: Yup.string().required('Internal Title is required'),
  displayTitle: Yup.string()
    .min(1)
    .max(255)
    .nullable(),
  brandId: Yup.number()
    .typeError(
      `Please select a brand. If the correct brand is not available, please select "Unknown" and submit a helpdesk ticket.`,
    )
    .required(
      `Please select a brand. If the correct brand is not available, please select "Unknown" and submit a helpdesk ticket.`,
    ),
  disciplineId: Yup.number().nullable(),
  categoryId: Yup.number().nullable(),
  disabledCategoryId: Yup.number().nullable(),
  certifiedPreOwned: Yup.boolean().required(),
  productSubCategoryId: Yup.number().nullable(),
  variations: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
      }),
    )
    .max(2)
    .nullable(),
  description: Yup.string().nullable(),
  sellPreService: Yup.boolean(),
  headline: Yup.string().nullable(),
  longDescription: Yup.string().nullable(),
  features: Yup.string().nullable(),
  upgrades: Yup.string().nullable(),
  masterMechanicsNotes: Yup.string().nullable(),
});
