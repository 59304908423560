import styled from 'styled-components';
import { withTheme, makeStyles } from '@material-ui/core/styles';

export const CategoryHeaderWrapper = withTheme(styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 20px;
    letter-spacing: 0.5px;
  }
`);

const MIN_TABLE_WIDTH = 650;

/* POINTS */

const editablePointCategoryTableColumns = '2fr 5fr 2fr 2fr 2fr 6fr 10fr';
const staticPointsTableFailedRowColumns = '1fr 4fr 4fr 4fr 6fr';
const staticPointsTablePassedRowColumns = '1fr 4fr 4fr 10fr';
export const useEditablePointCategoryTableStyles = makeStyles(theme => ({
  table: {
    minWidth: MIN_TABLE_WIDTH,
    marginBottom: '2rem',
  },
  // points table only
  row: {
    display: 'grid',
    gridTemplateColumns: editablePointCategoryTableColumns,
  },
  th: {
    backgroundColor: theme.palette.grey[300],
  },
  thCell: {
    fontFamily: theme.typography.regular,
    fontWeight: 'bold',
  },
  centeredCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftAlignedCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  clickable: {
    cursor: 'pointer',
    // make it obvious that the whole cell can be clicked
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  radio: {
    color: theme.palette.common.black,
    '&$checked': {
      color: theme.palette.common.black,
    },
  },
  notesInput: {
    width: '100%',
  },
  statusPass: {
    backgroundColor: theme.palette.success.background,
  },
  statusFail: {
    backgroundColor: theme.palette.error.background,
  },
  statusNA: {
    backgroundColor: theme.palette.grey[200],
  },
}));
export const useStaticPointsTableStyles = makeStyles(theme => ({
  table: {
    minWidth: MIN_TABLE_WIDTH,
    marginBottom: '2rem',
  },
  // points table only
  failedRow: {
    display: 'grid',
    gridTemplateColumns: staticPointsTableFailedRowColumns,
  },
  passedRow: {
    display: 'grid',
    gridTemplateColumns: staticPointsTablePassedRowColumns,
  },
  th: {
    backgroundColor: theme.palette.grey[300],
  },
  thCell: {
    fontFamily: theme.typography.regular,
    fontWeight: 'bold',
  },
  centeredCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftAlignedCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

// correspond to Grades enum values
export const usePointStatusStyles = makeStyles(theme => ({
  pass: {
    backgroundColor: theme.palette.success.background,
  },
  fail: {
    backgroundColor: theme.palette.error.background,
  },
  na: {
    backgroundColor: theme.palette.grey[200],
  },
}));

/* PARTS */

const staticPartsTableColumns =
  'minmax(75px, 8fr) minmax(75px, 12fr) minmax(75px, 8fr) minmax(75px, 8fr) minmax(75px, 8fr) minmax(65px, 2fr) minmax(75px, 8fr)';
const dynamicPartsTableColumns =
  'minmax(80px, 17fr) minmax(75px, 24fr) minmax(75px, 16fr) minmax(75px, 16fr) minmax(75px, 16fr) minmax(65px, 4fr) minmax(75px, 16fr) minmax(75px, 4fr)';

export const useStaticPartsTableStyles = makeStyles(theme => ({
  table: {
    minWidth: MIN_TABLE_WIDTH,
    marginBottom: '2rem',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: staticPartsTableColumns,
  },
  th: {
    backgroundColor: theme.palette.grey[300],
  },
  thCell: {
    fontFamily: theme.typography.regular,
    fontWeight: 'bold',
  },
  fixedWidth: {
    flexGrow: 0,
    maxWidth: 'inherit',
  },
  leftAlignedCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

export const useEditablePartsTableStyles = makeStyles(theme => ({
  table: {
    minWidth: MIN_TABLE_WIDTH,
    marginBottom: '2rem',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: dynamicPartsTableColumns,
  },
  th: {
    backgroundColor: theme.palette.grey[300],
  },
  thCell: {
    fontFamily: theme.typography.regular,
    fontWeight: 'bold',
  },
  fixedWidth: {
    flexGrow: 0,
    maxWidth: 'inherit',
  },
  leftAlignedCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  actionCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  categoryCell: {
    paddingTop: '1.2rem',
    fontSize: '16px',
  },
  removeButton: {
    color: theme.palette.error.vibrant,
  },
  clickable: {
    cursor: 'pointer',
    // make it obvious that the whole cell can be clicked
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

/* PARTS */

const staticServiceTableColumns =
  'minmax(75px, 8fr) minmax(75px, 8fr) minmax(75px, 4fr) minmax(75px, 10fr) minmax(75px, 10fr)';
const dynamicServiceTableColumns =
  'minmax(75px, 2fr) minmax(75px, 8fr) minmax(75px, 8fr) minmax(75px, 4fr) minmax(75px, 10fr) minmax(75px, 10fr)';

export const useStaticServiceTableStyles = makeStyles(theme => ({
  table: {
    minWidth: MIN_TABLE_WIDTH,
    marginBottom: '2rem',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: staticServiceTableColumns,
  },
  th: {
    backgroundColor: theme.palette.grey[300],
  },
  thCell: {
    fontFamily: theme.typography.regular,
    fontWeight: 'bold',
  },
  fixedWidth: {
    flexGrow: 0,
    maxWidth: 'inherit',
  },
  leftAlignedCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));

export const useEditableServiceTableStyles = makeStyles(theme => ({
  table: {
    minWidth: MIN_TABLE_WIDTH,
    marginBottom: '2rem',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: dynamicServiceTableColumns,
  },
  th: {
    backgroundColor: theme.palette.grey[300],
  },
  thCell: {
    fontFamily: theme.typography.regular,
    fontWeight: 'bold',
  },
  fixedWidth: {
    flexGrow: 0,
    maxWidth: 'inherit',
  },
  centeredCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftAlignedCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  partsCell: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '1.2rem',
    width: '100%',
  },
  clickable: {
    cursor: 'pointer',
    // make it obvious that the whole cell can be clicked
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  removeButton: {
    color: theme.palette.error.vibrant,
  },
  undoRemoveButton: {
    color: theme.palette.grey[500],
  },
  partSlatedForDeletion: {
    textDecoration: 'line-through',
    color: theme.palette.grey[500],
  },
  missingPartText: {
    color: theme.palette.error.main,
    width: '100%',
  },
}));
