import React, { Fragment, useState, forwardRef, useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@material-ui/core';
import { ProductVariant, Product, SingleItemProcurement } from '../../../services/catalog/types';
import { ProductFormGroups } from '../utils/types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import DisplayField from '../../../components/catalog/DisplayField';
import { get } from 'lodash';
import { CatalogProductState } from '../../../reducers/product-reducer';
import { GlobalState } from '../../../reducers/types';
import { useSelector } from 'react-redux';
import VariantTitle, { useVariationValue } from '../VariantTitle';
import Barcode from '../../../components/barcode';
import ProductImage from '../productImages/ProductImage';
import ImageModal from '../../../components/catalog/ImageModal';
import { selectVariantFieldUnitsByCategoryId } from '../utils/categories';
import { NETSUITE_URL } from '../../../constants';

interface Props {
  productVariant: ProductVariant;
  productId: number;
  defaultExpanded?: boolean;
  product: Product;
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Details = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    display: block;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const VariantComponentWrapper = styled(Wrapper)`
  margin-left: 30px;
`;

type GetterFunc = (
  productStore: CatalogProductState,
  productVariant: ProductVariant,
) => string | undefined;

// TODO: Group these similar to how we group them in edit? via ProductFormGroups?
const displayPropertiesMap: { [key: string]: string | GetterFunc } = {
  SKU: 'sku',
  'Hold Until Date': 'holdUntilDate',
  MSRP: 'msrpUsd',
  Cost: 'costUsd',
  'MAP Item': 'mapPricing',
  'Starting Price': 'startingPriceUsd',
  'Current Sale Price': 'currentSalePriceUsd',
  'Compare At Price': 'compareAtPriceUsd',
  'Promotional Pricing Flag': 'promotionalPricingFlag',
  Year: 'year',
  'Size Class': 'sizeClass.name',
  UPC: 'upc',
  MPN: 'mpn',
  QBP_ID: 'qbpId',
  Weight: 'weight',
  Condition: 'condition',
  Pipeline: ({ pipelines }, productVariant) =>
    pipelines.find(pipeline => pipeline.id === productVariant.pipelineId)?.pipeline,
  'Point of Sale Only': 'pointOfSale',
  Gender: 'gender',
  Material: 'material',
  'Apparel sleeve length': 'apparelSleeveLength',
  'Configuration details': 'configurationDetails',
  'Condition description': 'conditionDescription',
  'Condition notes': 'conditionNotes',
  'Frame Material': 'frameMaterial',
  'Frame Headset Model': 'frameHeadset',
  'Frame Rear Axle Spacing': 'frameRearAxleSpacing',
  'Frame Rear Shock Travel': 'frameRearShockTravel',
  'Frame rear shock travel range': 'frameRearShockTravelRange',
  'Drivetrain Brand': 'drivetrainBrand.name',
  'Drivetrain Configuration': 'drivetrainConfiguration',
  'Drivetrain Shifting Type': 'drivetrainShiftingType.name',
  'Component Intended Use': 'componentIntendedUse.name',
  'Frame rear triangle material': 'frameRearTriangleMaterial',
  'Brake Type': 'brakeType',
  'Tire type': 'tireType',
  'Tubeless compatibility': 'tubelessCompatibility',
  'Charger included': 'chargerIncluded',
  Mileage: 'mileage',
  'Key included': 'keyIncluded',
  'Electric top speed': 'electricTopSpeed',
};

const displaySubmissionMap: {
  [label: string]: {
    accessKey: string;
    getLinkUrl?: (id: number | string) => string;
    shouldDisplay?: (sip: SingleItemProcurement | null) => boolean;
  };
} = {
  'Partner Submission': {
    accessKey: 'partnerSubmissionId',
    getLinkUrl: (id: number | string) => `/tradeups/partner/${id}`,
  },
  'SYB Submission': {
    accessKey: 'sybSubmissionId',
    getLinkUrl: (id: number | string) => `/tradeups/submission/${id}`,
  },
  'Serial Number': { accessKey: 'serialNumber' },
  'AutoPO Status': { accessKey: 'createPurchaseOrderStatus' },
  'PO Number': {
    accessKey: 'createdPurchaseOrderInternalId',
    getLinkUrl: (id: number | string) =>
      `${NETSUITE_URL}/app/accounting/transactions/purchord.nl?id=${id}`,
  },
};

const ViewProductVariant = forwardRef<HTMLElement, Props>(
  ({ productVariant, productId, product, defaultExpanded }, ref) => {
    const { sku, statusFlagId } = productVariant;
    const productStore = useSelector((state: GlobalState) => state.product);
    const [imageModalSrc, setImageModalSrc] = useState<string | undefined>(undefined);
    const variationValue = useVariationValue(product, productVariant);

    let barcodeTitle =
      `${product.displayTitle || product.title}${variationValue ? ` (${variationValue})` : ''}` ||
      '';

    if (productVariant.singleItemProcurement) {
      const { partnerSubmissionId, sybSubmissionId } = productVariant.singleItemProcurement;
      if (partnerSubmissionId || sybSubmissionId) {
        barcodeTitle = `${product.brand?.name || ''} ${product.model || ''}`.trim();
      }
    }

    const displayAttributesMap: { [key: string]: string | GetterFunc } = useMemo(() => {
      return productStore.attributeTypes.reduce((map, attributeType) => {
        const displayName =
          productStore.catalogFieldConfigs.find(
            config => config.attributeTypeId == attributeType.id,
          )?.label ?? attributeType.name;
        const fn: GetterFunc = ({}, { productVariantAttributes }) =>
          productVariantAttributes?.find(pva => pva.attributeTypeId == attributeType.id)?.value;
        return {
          ...map,
          [displayName]: fn,
        };
      }, {});
    }, [productStore]);

    const displayEntries = useMemo(() => {
      return [...Object.entries(displayPropertiesMap), ...Object.entries(displayAttributesMap)];
    }, [displayAttributesMap]);

    return (
      <Accordion defaultExpanded={defaultExpanded} variant="elevation" ref={ref}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Header>
            <VariantTitle
              sku={sku}
              statusFlagId={statusFlagId}
              imageUrl={productVariant.images?.[0]?.imageUrl}
              product={product}
              productVariant={productVariant}
            />
            <Button
              to={`/catalog/products/${productId}/edit?sku=${sku}`}
              variant="contained"
              component={Link}
            >
              Edit
            </Button>
          </Header>
        </AccordionSummary>
        <Details>
          <Wrapper style={{ height: '400px', overflow: 'hidden' }}>
            <Barcode text={productVariant.sku} title={barcodeTitle} />
            {productVariant.images?.[0] && (
              <div>
                <ProductImage
                  onClick={() => setImageModalSrc(productVariant.images[0].imageUrl)}
                  src={productVariant.images[0].imageUrl}
                  alt={`${sku} product image`}
                />
                <ImageModal
                  displayModal={!!imageModalSrc}
                  onClose={() => setImageModalSrc(undefined)}
                  imageSrc={imageModalSrc}
                />
              </div>
            )}
          </Wrapper>
          <Wrapper>
            {displayEntries.map(([key, value]) => {
              let resValue: string | null | undefined;
              let unit = '';
              if (typeof value === 'function') {
                resValue = value(productStore, productVariant);
              } else {
                resValue = get(productVariant, value);
              }
              if (resValue === null || resValue === undefined || resValue === '') {
                return null;
              }
              // check the CATEGORY_FIELD_UNITS map to see if a field should display units for the corresponding category
              if (typeof value === 'string') {
                unit = selectVariantFieldUnitsByCategoryId(value, product.categoryId);
              }

              return <DisplayField key={key} label={key} value={resValue} unit={unit} />;
            })}
          </Wrapper>
          {!!productVariant.singleItemProcurement && (
            <>
              <h3>{ProductFormGroups['Submission']}</h3>
              <Wrapper>
                {Object.entries(displaySubmissionMap).map(([label, subValues]) => {
                  const resValue: string | number | null | undefined = get(
                    productVariant.singleItemProcurement,
                    subValues.accessKey,
                  );
                  if (
                    subValues.shouldDisplay &&
                    !subValues.shouldDisplay(productVariant.singleItemProcurement)
                  ) {
                    return null;
                  }
                  if (resValue === null || resValue === undefined || resValue === '') {
                    return null;
                  }
                  if (subValues.getLinkUrl) {
                    return (
                      <DisplayField
                        key={subValues.accessKey}
                        label={label}
                        value={resValue}
                        url={subValues.getLinkUrl(resValue)}
                      />
                    );
                  }
                  return <DisplayField key={subValues.accessKey} label={label} value={resValue} />;
                })}
              </Wrapper>
            </>
          )}
          {!!productVariant.productVariantFrameDimensions?.length && (
            <>
              <h3>{ProductFormGroups['Frame Geometry']}</h3>
              <Wrapper>
                {productVariant.productVariantFrameDimensions.map(
                  ({ id, minValue, maxValue, frameDimension, textValue }) => {
                    let value: string | null = null;

                    if (frameDimension.alwaysDisplayText && textValue) {
                      value = textValue;
                    } else if (minValue && maxValue && minValue !== maxValue) {
                      value = `${minValue} ${frameDimension.unitSuffix} - ${maxValue} ${frameDimension.unitSuffix}`;
                    } else if (textValue) {
                      value = textValue;
                    }

                    if (value) {
                      return <DisplayField key={id} label={frameDimension.name} value={value} />;
                    }
                    return null;
                  },
                )}
              </Wrapper>
            </>
          )}
          <h3>{ProductFormGroups['Component Specifications']}</h3>
          {productVariant.productVariantComponents?.map(variantComponent => {
            if (!variantComponent.productVariantComponentSpecifications.length) {
              return null;
            }
            return (
              <Fragment key={variantComponent.id}>
                <h4>
                  <em>{variantComponent.componentType.name}</em>
                </h4>
                <VariantComponentWrapper>
                  {variantComponent.productVariantComponentSpecifications.map(
                    componentSpecification => {
                      const unit = selectVariantFieldUnitsByCategoryId(
                        componentSpecification.componentSpecificationType.name,
                        product.categoryId,
                      );
                      return (
                        <DisplayField
                          key={componentSpecification.id}
                          label={componentSpecification.componentSpecificationType.name}
                          value={componentSpecification.value}
                          unit={unit}
                        />
                      );
                    },
                  )}
                </VariantComponentWrapper>
              </Fragment>
            );
          })}
        </Details>
      </Accordion>
    );
  },
);

export default ViewProductVariant;
