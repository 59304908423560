import React from 'react';

const TpcLogoCondensed = () => (
  <svg
    version="1.1"
    width="41"
    height="24"
    viewBox="0 0 3631.59 688.17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      fill="#fff"
      points="0 184.5 495.69 184.5 495.69 687.87 706.42 687.87 706.42 184.5 1202.11 184.5 1202.11 .3 0 .3 0 184.5"
    />
    <path
      fill="#fff"
      d="M2198.57,.3h-954.82s0,0,0,0h0V687.87h210.73v-165.66h744.09c143.89,0,260.95-117.06,260.95-260.95S2342.46,.3,2198.57,.3Zm-7.49,337.7h-736.6V184.5h736.6c42.32,0,76.75,34.43,76.75,76.75s-34.43,76.75-76.75,76.75Z"
    />
    <path
      fill="#fff"
      d="M2827.34,496.3c-83.93,0-152.22-68.28-152.22-152.22,0-83.93,68.28-152.22,152.22-152.22h804.25s0-191.87,0-191.87h-804.25c-190.03,0-344.08,154.05-344.08,344.08,0,190.03,154.05,344.08,344.08,344.08h804.25s0-191.87,0-191.87h-804.25Z"
    />
  </svg>
);

export default TpcLogoCondensed;
