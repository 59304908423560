import React, { useEffect } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ImageCarousel from '../../components/library/ImageCarousel/ImageCarousel';
import {
  hasAlternateSubmissions,
  formatSubmissionForAddProductView,
} from './submission-modal-utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  capitalize,
  Chip,
  createStyles,
  Dialog,
  DialogActions,
  Divider,
  Grid,
  Grow,
  Link,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@material-ui/core';
import { Button } from '../../components/library';
import { formatPhone } from '../../utils/helper';
import { AlternateSubmission } from './AlternateSubmission';
import moment from 'moment';
import { styleStatus } from '../../pages/tradeups/helper';
import { Submission } from './types';
import { CATALOG_ADD_PRODUCT_VIEW_PAGE_ROUTE } from '../catalog/CatalogAddProductView';
import { SubmissionStatusIds } from '../../constants';
import sanitizeHtml from 'sanitize-html';
import { TransitionProps } from '@material-ui/core/transitions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        overflow: 'visible',
      },
    },
    horizontal: {
      display: 'flex',
      flex: '1, 1, 33.3%',
      flexDirection: 'row',
      alignItems: 'start',
      marginTop: theme.spacing(1),
      gap: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    secondaryDark: {
      backgroundColor: theme.palette.secondary.dark,
      color: '#A6E8C7',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
      padding: theme.spacing(2),
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    imageContainer: {
      marginLeft: theme.spacing(2),
      overflow: 'hidden',
      borderRadius: '4px',
      width: '318px',
      height: '150px',
      flexShrink: 0,
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(2),
      },
    },
    bottomDivider: {
      borderBottom: '1px solid ' + theme.palette.divider,
      paddingLeft: '0px',
      marginTop: theme.spacing(2),
      lineHeight: 2.2,
      color: theme.palette.text.primary,
    },
    status: {
      marginTop: theme.spacing(1),
    },
    colorado: {
      flexGrow: 2,
      flexDirection: 'column',
      alignItems: 'start',
      backgroundColor: '#FFF5E5',
      borderRadius: theme.spacing(0.5),
      minWidth: '200px',
      maxWidth: '350px',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'none',
        width: '100%',
      },
    },
    address: {
      flexGrow: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      minWidth: '200px',
      maxWidth: '350px',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'none',
        width: '100%',
      },
    },
    submissionDetailItem: {
      flexGrow: 1,
      flex: 1,
      width: '100%',
    },
    phone: {
      flex: 1,
      marginTop: theme.spacing(-1),
    },
    emptyValue: {
      height: '80px',
      backgroundColor: 'red',
    },
    coloradoBadge: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.light,
      marginBottom: theme.spacing(1),
    },
    fontBold: {
      fontFamily: theme.typography.bold,
    },
    inline: {
      display: 'inline',
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(10),
    },
    inlineDark: {
      display: 'inline',
      color: theme.palette.text.primary,
      marginTop: theme.spacing(10),
    },
    notesContainer: {
      backgroundColor: theme.palette.grey[50],
    },
    rightColumn: {
      backgroundColor: theme.palette.grey[100],
      display: 'flex',
      flexDirection: 'column',
    },
    pendingSubmissionAccordion: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
      overflow: 'hidden',
    },
    alternateSubmissions: {
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        overflowY: 'visible',
        height: '100%',
        color: 'red',
      },
    },
  }),
);

type SubmissionModalProps = {
  primarySubmission: Submission | null;
  alternateSubmissions: Submission[] | null;
  isModalOpen: boolean;
  trackingNumber: string | null;
  setPrimarySubmission: React.Dispatch<React.SetStateAction<Submission | null>>;
  handleClose: () => void;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
const Transition = (props: TransitionProps) => {
  return <Grow {...props} />;
};

export const SubmissionModal = ({
  primarySubmission,
  alternateSubmissions,
  isModalOpen,
  trackingNumber,
  setPrimarySubmission,
  handleClose,
}: SubmissionModalProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [tabIndex, setTabIndex] = React.useState(0);

  // Switch out the primary submission with the submission clicked in the list of pending submissions
  const handleAlternateSubmissionClick = (id: number) => {
    if (id !== primarySubmission?.id && alternateSubmissions) {
      const newPrimarySubmission = alternateSubmissions.find(submission => submission.id === id);
      if (newPrimarySubmission) {
        setPrimarySubmission(newPrimarySubmission);
      }
    }
  };

  // updates the status of the submission and navigates the user to the Add Product Page
  const handleReceiveClick = async (submission: Submission | null) => {
    if (submission && history) {
      // formats the submission object so CatalogAddProductView can consume it
      const formattedSubmission = formatSubmissionForAddProductView(submission, trackingNumber);

      history.push({
        pathname: CATALOG_ADD_PRODUCT_VIEW_PAGE_ROUTE,
        state: formattedSubmission,
      });
    }
  };

  useEffect(() => {
    if (tabIndex === 0) {
      if (!primarySubmission?.internalNote && !!primarySubmission?.notes) {
        setTabIndex(1);
      }
    } else {
      if (primarySubmission?.notes?.length === 0 && primarySubmission?.internalNote) {
        setTabIndex(0);
      }
    }
  }, [primarySubmission, tabIndex]);

  const carouselImages =
    primarySubmission?.items[0]?.images.map((img: { url?: string }) => img?.url ?? '') ?? [];
  const isColoradoSubmission = primarySubmission?.state
    ? primarySubmission?.state.toUpperCase() === 'CO'
    : false;
  const shouldDisplayAlternateSubmissions =
    primarySubmission &&
    alternateSubmissions &&
    hasAlternateSubmissions(primarySubmission, alternateSubmissions);

  return (
    <div>
      <Dialog
        open={isModalOpen}
        TransitionComponent={Transition}
        transitionDuration={400}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        style={{ overflowX: 'hidden' }}
      >
        <Box className={classes.secondaryDark}>
          <CheckCircleOutlineIcon />
          <Typography>
            Submission found for <span className={classes.fontBold}>{primarySubmission?.id}</span>
          </Typography>
        </Box>
        <Box className={classes.mainContainer}>
          <Box
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            p={3}
            flexBasis={'100%'}
          >
            <Box className={classes.titleContainer}>
              <Box width={'100%'}>
                <Typography variant="h5">{primarySubmission?.items[0]?.model}</Typography>
                {primarySubmission?.itemProcurement?.productVariants?.[0] && (
                  <Typography variant="body1" color="textSecondary">
                    SKU:{' '}
                    <a
                      className={classes.fontBold}
                      href={`/catalog/products/${primarySubmission?.itemProcurement.productVariants[0].productId}`}
                    >
                      {primarySubmission?.itemProcurement.productVariants[0].sku}
                    </a>
                  </Typography>
                )}
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Created:{' '}
                  <span className={classes.fontBold}>
                    {primarySubmission?.createdAt
                      ? moment(primarySubmission.createdAt).format('MMMM Do YYYY')
                      : ''}
                  </span>
                </Typography>
                {primarySubmission?.status && (
                  <Chip
                    className={classes.status}
                    size="small"
                    variant="outlined"
                    label={capitalize(primarySubmission.status)}
                    style={{ borderColor: styleStatus(primarySubmission?.status) }}
                  />
                )}
              </Box>
              <Box className={classes.imageContainer}>
                <ImageCarousel
                  images={carouselImages}
                  showArrows={true}
                  showThumbs={false}
                  zoomable={true}
                  showModal={true}
                />
              </Box>
            </Box>
            {/* Submission Details */}
            <List
              disablePadding
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  className={classes.bottomDivider}
                >
                  Submission Details
                </ListSubheader>
              }
            >
              <Box className={classes.horizontal}>
                <ListItem className={isColoradoSubmission ? classes.colorado : classes.address}>
                  <ListItemText
                    primary={`${primarySubmission?.firstName} ${primarySubmission?.lastName}`}
                    style={{ whiteSpace: 'pre-line' }}
                    secondary={`${primarySubmission?.address}, ${primarySubmission?.city} ${primarySubmission?.state} ${primarySubmission?.zip}`}
                  />
                  <ListItemText
                    className={classes.phone}
                    secondary={primarySubmission?.phone ? formatPhone(primarySubmission.phone) : ''}
                  />
                  {isColoradoSubmission && (
                    <Chip className={classes.coloradoBadge} size="small" label="Colorado" />
                  )}
                </ListItem>
                <ListItem className={classes.submissionDetailItem}>
                  {!primarySubmission?.id && <Box className={classes.emptyValue} />}
                  <ListItemText
                    primary={
                      <Link
                        component={RouterLink}
                        to={`/tradeups/submission/${primarySubmission?.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {primarySubmission?.id}
                      </Link>
                    }
                    style={{ whiteSpace: 'pre-line' }}
                    secondary="Submission ID"
                  />
                </ListItem>
                <ListItem className={classes.submissionDetailItem}>
                  {!primarySubmission?.fedexTrackingNumber && (
                    <Box className={classes.emptyValue} />
                  )}
                  <ListItemText
                    primary={primarySubmission?.fedexTrackingNumber}
                    secondary="Tracking Number"
                  />
                </ListItem>
              </Box>
            </List>
            {/* Bike Details */}
            <List
              disablePadding
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  className={classes.bottomDivider}
                >
                  Bike Details
                </ListSubheader>
              }
            >
              <Box px={2} pt={1}>
                <Grid container spacing={1} alignItems={'flex-start'}>
                  <ListItem dense component={Grid} item xs={4}>
                    <ListItemText
                      classes={{ primary: classes.inline, secondary: classes.inlineDark }}
                      primary="Brand: "
                      secondary={primarySubmission?.items[0]?.brand}
                    />
                  </ListItem>
                  <ListItem dense component={Grid} item xs={4}>
                    <ListItemText
                      classes={{ primary: classes.inline, secondary: classes.inlineDark }}
                      primary="Model: "
                      secondary={primarySubmission?.items[0]?.model}
                    />
                  </ListItem>
                  <ListItem dense component={Grid} item xs={4}>
                    <ListItemText
                      classes={{ primary: classes.inline, secondary: classes.inlineDark }}
                      primary="Size: "
                      secondary={primarySubmission?.items[0]?.size}
                    />
                  </ListItem>
                </Grid>
              </Box>
              <Box px={2} py={0}>
                <Grid container spacing={1} alignItems={'flex-start'}>
                  <ListItem dense component={Grid} item xs={4}>
                    <ListItemText
                      classes={{ primary: classes.inline, secondary: classes.inlineDark }}
                      primary="SN: "
                      secondary={primarySubmission?.serialNumber}
                    />
                  </ListItem>
                  <ListItem dense component={Grid} item xs={4}>
                    <ListItemText
                      classes={{ primary: classes.inline, secondary: classes.inlineDark }}
                      primary="Year: "
                      secondary={primarySubmission?.items[0]?.year}
                    />
                  </ListItem>
                  <ListItem dense component={Grid} item xs={4}>
                    <ListItemText
                      classes={{ primary: classes.inline, secondary: classes.inlineDark }}
                      primary="Frame Material: "
                      secondary={primarySubmission?.items[0]?.frameMaterial}
                    />
                  </ListItem>
                </Grid>
              </Box>
            </List>
            <Box pt={2}>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  ordinality={'secondary'}
                  size="large"
                  fullWidth
                >
                  Back
                </Button>
                <Button
                  disabled={
                    primarySubmission?.statusId !== SubmissionStatusIds.PENDING &&
                    primarySubmission?.statusId !== SubmissionStatusIds.EXPIRED
                  }
                  onClick={() => handleReceiveClick(primarySubmission)}
                  endIcon={<CheckIcon />}
                  variant="contained"
                  ordinality={'primary'}
                  fullWidth
                  size="large"
                >
                  {primarySubmission?.statusId !== SubmissionStatusIds.PENDING &&
                  primarySubmission?.statusId !== SubmissionStatusIds.EXPIRED
                    ? primarySubmission?.status ?? ''
                    : `Receive ${primarySubmission.type !== 'bike' ? primarySubmission.type : ''}`}
                </Button>
              </DialogActions>
            </Box>
          </Box>
          <Box className={classes.rightColumn} flexBasis={'320px'} flexShrink={0}>
            <Tabs
              value={tabIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_e, newValue) => setTabIndex(newValue)}
            >
              {primarySubmission?.internalNote && <Tab label="Internal Notes" />}
              {primarySubmission?.notes && primarySubmission?.notes.length > 0 && (
                <Tab label="External Notes" />
              )}
            </Tabs>
            <Divider />
            <Box overflow="auto" maxHeight={440}>
              <TabPanel value={tabIndex} index={0}>
                <Typography variant="body2" gutterBottom>
                  {primarySubmission?.internalNote}
                </Typography>
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                {primarySubmission?.notes?.map((note, index) => (
                  <Box key={index}>
                    <Typography variant="overline" gutterBottom>
                      {new Date(note.createdAt).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(note.entry) }}></span>
                    </Typography>

                    <Box my={1}>
                      <Divider />
                    </Box>
                  </Box>
                ))}
              </TabPanel>
            </Box>
            <Divider />
            {shouldDisplayAlternateSubmissions && (
              <Box position="relative" flexGrow={1} mx={1}>
                <Accordion
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    overflow: 'hidden',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandLessIcon style={{ color: 'white' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.pendingSubmissionAccordion}
                  >
                    <Typography variant="subtitle2" color="inherit">
                      Seller&apos;s Other Pending Submissions
                    </Typography>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails style={{ maxHeight: '360px', overflow: 'auto', padding: 0 }}>
                    <List style={{ width: '100%' }}>
                      {shouldDisplayAlternateSubmissions &&
                        alternateSubmissions?.map((submission: Submission, index: number) => {
                          return (
                            <AlternateSubmission
                              key={`${submission.id}-${index}`}
                              submission={submission}
                              handleAlternateSubmissionClick={handleAlternateSubmissionClick}
                              selected={submission?.id === primarySubmission?.id}
                            />
                          );
                        })}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};
