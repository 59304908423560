import React from 'react';
import InnerImageZoom from 'react-inner-image-zoom';
import './ImageZoom.css';

// height and width must both be specified, otherwise use custom className
// documentation for InnerImageZoom: https://github.com/laurenashpole/react-inner-image-zoom

interface ZoomProps {
  src: string;
  height?: number;
  width?: number;
  zoomScale?: number;
  className?: string;
}

export type ImageZoomProps = ZoomProps;

export const ImageZoom: React.FC<ImageZoomProps> = ({
  src,
  height,
  width,
  className,
  zoomScale,
}) => {
  return (
    <InnerImageZoom
      src={src}
      height={height}
      width={width}
      hasSpacer={true}
      hideHint={true}
      zoomScale={zoomScale}
      className={className}
    />
  );
};
