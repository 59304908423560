import React from 'react';
import PropTypes from 'prop-types';

const shippingInfo = ({ shippingInfo: { label, tracking, trackingUrl } }) => (
  <fieldset className="list-wrap dealer-title-list-wrap">
    <legend>Shipping Info</legend>
    <div className="shipping-wrap">
      <span className="input-label-wrapper">
        <label className="label">Tracking Number</label>
        <span className="value dealer-value">
          <a href={trackingUrl}>{tracking || 'N/A'}</a>
        </span>
      </span>
      <span className="input-label-wrapper">
        <label className="label">Shipping Label</label>
        <span className="value dealer-value">
          <a target="_blank" rel="noopener noreferrer" href={label}>
            {label ? 'View Label' : 'N/A'}
          </a>
        </span>
      </span>
    </div>
  </fieldset>
);

export default shippingInfo;

shippingInfo.propTypes = {
  label: PropTypes.string,
  tracking: PropTypes.string,
  trackingUrl: PropTypes.string,
  shippingInfo: PropTypes.shape().isRequired,
};

shippingInfo.defaultProps = {
  label: '',
  tracking: '',
  trackingUrl: '',
};
