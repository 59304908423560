import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ProductImage, FEProductImage } from '../../../services/catalog/types';
import { fetchProductImages } from '../../../actions/product';
import { ProductImagesState } from '../../../reducers/catalogProductImagesReducer';
import styles from '../../../styledComponents';
import EditProductImages from './EditProductImages';
import AddProductImages from './AddProductImages';
import Loader from '../../../components/loader';
import useProduct from '../utils/useProduct';
import VariantTitle from '../VariantTitle';

const { PageWrapper, HeaderWrapper, SectionHeader } = styles;

const SpacedSectionHeader = styled(SectionHeader)`
  margin-top: 3rem;
`;

const VariantRow = styled.div`
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export interface FullProductImage extends ProductImage, FEProductImage {}

const MAX_PRODUCT_PHOTOS = 30;

const ProductImagesView = () => {
  const { productId } = useParams<{ productId: string }>();
  const {
    product,
    error: productError,
    loading: productLoading,
    shopifyStatus: shopifyStatus,
  } = useProduct(productId);
  const {
    productImages,
    variantImages,
    errorMsg,
    loading: productImagesLoading,
  }: ProductImagesState = useSelector((state: any) => state.productImages);
  const dispatch = useDispatch();
  const location = useLocation();
  const skuParam = new URLSearchParams(location.search).get('sku');
  const editingVariant = !!skuParam;
  let variantId: number | undefined = undefined;
  if (editingVariant && product) {
    variantId = product.productVariants.filter(v => v.sku === skuParam)?.[0]?.id;
  }

  useEffect(() => {
    dispatch(fetchProductImages(productId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (productError && !product) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Product not found
      </Alert>
    );
  }

  return (
    <PageWrapper>
      {errorMsg !== undefined && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMsg || 'Unable to fetch data'}
        </Alert>
      )}
      <Loader loading={productLoading || productImagesLoading} />
      <HeaderWrapper>
        <span>edit {editingVariant ? 'variant' : 'product'} images</span>
        <Button to={`/catalog/products/${productId}`} variant="contained" component={Link}>
          View Product
        </Button>
      </HeaderWrapper>
      <h4>
        <b>Product ID: </b>
        {productId}
        {editingVariant && (
          <>
            {' '}
            <Link to={`/catalog/products/${productId}/images/edit`}>(edit images for product)</Link>
          </>
        )}
      </h4>
      {editingVariant && (
        <h4>
          <b>Variant SKU: </b>
          {skuParam}
        </h4>
      )}
      <EditProductImages
        productImages={
          editingVariant
            ? variantImages.filter(({ productVariantId }) => productVariantId === variantId)
            : productImages
        }
        editingVariant={editingVariant}
        shopifyStatus={shopifyStatus || ''}
      />
      <SpacedSectionHeader>Add Images</SpacedSectionHeader>
      <AddProductImages
        preExistingProductImages={productImages}
        preExistingVariantImages={variantImages}
        variantId={variantId}
        limit={editingVariant ? 1 : MAX_PRODUCT_PHOTOS}
      />
      {!editingVariant && (
        <>
          <SpacedSectionHeader>Variants</SpacedSectionHeader>
          {product?.productVariants.map(variant => {
            const imageUrl = variantImages.find(img => img.productVariantId === variant.id)
              ?.imageUrl;
            return (
              <VariantRow key={variant.sku}>
                <VariantTitle
                  sku={variant.sku}
                  imageUrl={imageUrl}
                  product={product}
                  productVariant={variant}
                />
                <Button
                  to={`/catalog/products/${productId}/images/edit?sku=${variant.sku}`}
                  variant="contained"
                  component={Link}
                >
                  Edit
                </Button>
              </VariantRow>
            );
          })}
        </>
      )}
    </PageWrapper>
  );
};

export default ProductImagesView;
