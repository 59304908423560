import React from 'react';
import PropTypes from 'prop-types';
import MultiSelectField from './multi_select_field';
import '../styles/dynamicForm.css';
import { Tooltip } from '@material-ui/core';

const multiSelectStyle = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#00c160' : styles.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #00c160' : styles.boxShadow,
    width: 400,
    ':hover': {
      borderColor: '#00c180',
    },
  }),
  option: styles => ({
    ...styles,
    backgroundColor: 'white',
    width: 400,
    ':hover': {
      backgroundColor: '#00c160',
    },
  }),
  menu: styles => ({
    ...styles,
    width: 400,
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: 'black',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#fff',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#fff',
    ':hover': {
      // backgroundColor: 'red',
      color: '#00c160',
    },
  }),
};

const Field = ({
  type,
  name,
  value,
  onChange,
  label = null,
  placeholder = null,
  options = [],
  tooltip = null,
}) => {
  let comp = null;
  let compLabel = null;
  if (label) {
    compLabel = <label htmlFor={name}>{label}</label>;
  }
  if (type === 'text') {
    comp = (
      <input
        className="filter-input"
        type="text"
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
    );
  } else if (type === 'select') {
    comp = (
      <select placeholder={placeholder} name={name} onChange={onChange} value={value}>
        <option value="">{placeholder}</option>
        {options.map(o => (
          <option key={o.value} value={o.value}>
            {o.display}
          </option>
        ))}
      </select>
    );
  } else if (type === 'multi-select') {
    comp = (
      <MultiSelectField
        classNamePrefix
        className="multi-select-field"
        name={name}
        placeholder={placeholder}
        value={value}
        options={options}
        onSelectChange={onChange}
        styles={multiSelectStyle}
      />
    );
  } else if (type === 'checkbox') {
    comp = (
      <div>
        {!!tooltip ? (
          <Tooltip title={tooltip || 'abc'} arrow>
            {compLabel}
          </Tooltip>
        ) : (
          compLabel
        )}
        <input
          style={{ WebkitAppearance: 'checkbox', width: 'initial' }}
          type="checkbox"
          className="checkbox"
          name={name}
          checked={value}
          onChange={onChange}
        />
      </div>
    );
  }

  return <div className="dynamic-wrapper">{comp}</div>;
};

Field.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  tooltip: PropTypes.string,
};

Field.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
  type: '',
  name: '',
  value: {},
  flagField: false,
  tooltip: '',
};

const DynamicForm = ({ onChange, onSubmit, onClear, fields }) => {
  const controlledFields = [];
  const flagFields = [];
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    if (field.flagField) {
      flagFields.push(
        <Field
          key={i}
          type={field.type}
          name={field.name}
          value={field.value}
          label={field.label}
          onChange={field.onChange || onChange}
          placeholder={field.placeholder}
          options={field.options}
          tooltip={field.tooltip}
        />,
      );
    } else {
      controlledFields.push(
        <Field
          key={i}
          type={field.type}
          name={field.name}
          value={field.value}
          label={field.label}
          onChange={field.onChange || onChange}
          placeholder={field.placeholder}
          options={field.options}
          tooltip={field.tooltip}
        />,
      );
    }
  }

  return (
    <div className="wrapper">
      <form className="custom-field" onSubmit={onSubmit}>
        <div className="rightSide">
          <span className="flag-fields">{flagFields}</span>
          <span className="filter-buttons-wrapper">
            <input
              className="filter-buttons clear-button"
              type="button"
              onClick={onClear}
              name="reset"
              value="Clear Filters"
            />
            <input
              className="filter-buttons filter-button"
              type="submit"
              name="filter"
              value="SEARCH"
            />
          </span>
        </div>
        <span className="search-wrap">{controlledFields}</span>
      </form>
    </div>
  );
};

DynamicForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default DynamicForm;
