import React, { FC, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import AddProductSearch, { OnSubmitSuccess } from './addProduct/AddProductSearch';
import CreateProductAlternate from './addProduct/CreateProductAlternate';
import AddProduct from './addProduct/AddProduct';
import * as actions from '../../actions';
import { useDispatch } from 'react-redux';
import ErrorBoundary from '../../components/ErrorBoundary';
import { Submission } from '../../services/catalog/types';
import { LocationState } from './utils/types';
import { addProductContext } from './utils/productVariant/field-context';

export const CATALOG_ADD_PRODUCT_VIEW_PAGE_ROUTE = '/catalog/product-creation';

const CatalogAddProduct: FC = () => {
  let newSubmission;
  let newDrivetrainBikeCategoryId;
  let newEnteredTrackingNumber;
  const location = useLocation<LocationState>();
  if (location) {
    const { submission, drivetrainBikeCategoryId, enteredTrackingNumber } =
      (location.state as LocationState) || {};
    newSubmission = submission;
    newDrivetrainBikeCategoryId = drivetrainBikeCategoryId;
    newEnteredTrackingNumber = enteredTrackingNumber;
  }

  const [showForm, setShowForm] = useState(!!newSubmission);
  const [selectedSubmission, setSelectedSubmission] = useState<Submission | undefined>(
    newSubmission,
  );
  const [createPo, setCreatePo] = useState<boolean | undefined>(!!newSubmission);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>(
    newDrivetrainBikeCategoryId,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getInitialItemCatalogDataOneDispatch());
  }, [dispatch]);

  const onSubmitSuccess: OnSubmitSuccess = ({ submission, createPoValue, categoryId }) => {
    if (submission) {
      setSelectedSubmission(submission);
      setCreatePo(createPoValue);
    }
    if (categoryId) {
      setSelectedCategoryId(categoryId);
    }
    setShowForm(true);
  };

  const onAddProductCancel = () => {
    setShowForm(false);
    setSelectedSubmission(undefined);
    setSelectedCategoryId(undefined);
  };

  return (
    <div>
      <ErrorBoundary>
        {!showForm && (
          <>
            <AddProductSearch onSubmitSuccess={onSubmitSuccess} />
            <CreateProductAlternate />
            <Grid item container xs={6} spacing={2} alignItems="center" justifyContent="center">
              <Button
                to="/catalog/products"
                variant="contained"
                size="large"
                component={RouterLink}
              >
                Back to Catalog Search
              </Button>
            </Grid>
          </>
        )}
        {showForm && (
          <addProductContext.Provider
            value={{
              selectedCategoryId,
              selectedSubmission,
              initialCreatePoValue: !!selectedSubmission ? createPo : undefined,
              enteredTrackingNumber: newEnteredTrackingNumber,
            }}
          >
            <AddProduct onCancelForm={onAddProductCancel} />
          </addProductContext.Provider>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default CatalogAddProduct;
