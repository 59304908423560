import { Offer } from '../../typeORM';

export enum CreateConditionAlternates {
  DISABLED = 'disabled',
  ENABLED_DEFAULT_TRUE = 'enabled default true',
  ENABLED_DEFAULT_FALSE = 'enabled default false',
}
export interface ProductCategory {
  id: number;
  category: string;
  classification: string;
  majorCategory: string;
  createConditionAlternates: CreateConditionAlternates;
}
export interface Brand {
  id: number;
  name: string;
  alternateNames: string[] | null;
  description: string | null;
  drivetrainBrand: boolean;
}
export interface Pipeline {
  id: number;
  pipeline: string;
}

export interface StatusFlag {
  id: number;
  status: string;
}

export interface Discipline {
  id: number;
  discipline: string;
}

export interface SizeClass {
  id: number;
  name: string | null;
}

export interface ProductSubCategory {
  id: number;
  productCategoryId: number;
  name: string;
}

export interface VariationType {
  id: number;
  name: string;
}

export interface AttributeType {
  id: number;
  name: string;
  activeForVariations: boolean;
}

export interface ProductVariation {
  id: number;
  productId: number;
  displayOrder: number;
  attributeType: AttributeType;
}

export interface ComponentIntendedUse {
  id: number;
  name: string;
}

export interface DrivetrainShiftingType {
  id: number;
  name: string;
}

export interface FrameDimension {
  id: number;
  name: string;
  unitSuffix: string;
  displayPosition: number;
  diagramLetter: string | null;
  alwaysDisplayText: boolean;
}

export interface ProductVariantFrameDimensions {
  id: number;
  productVariantId: number;
  frameDimension: FrameDimension;
  frameDimensionId: number;
  minValue: number | null;
  maxValue: number | null;
  textValue: string | null;
}

export interface ComponentSpecificationType {
  id: number;
  componentTypeId: number;
  name: string;
  displayPosition: number;
}

export interface ComponentType {
  id: number;
  name: string;
  componentSpecificationTypes: ComponentSpecificationType[];
}

export interface ProductVariantComponentSpecification {
  id: number;
  productVariantComponentId: number;
  componentTypeId: number;
  componentSpecificationType: ComponentSpecificationType;
  componentSpecificationTypeId: number;
  value: string;
}

export interface ProductVariantComponent {
  id: number;
  productVariantId: number;
  componentType: ComponentType;
  componentTypeId: number;
  active: boolean;
  productVariantComponentSpecifications: ProductVariantComponentSpecification[];
}

//note: these are returned from Catalog API with just attributeTypeId and value
export interface ProductVariantAttribute {
  id?: number;
  attributeType?: AttributeType;
  attributeTypeId: number;
  productVariantId?: number;
  value: string;
}

interface SubmissionSeller {
  address: string | null;
  city: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  paypalEmail: string | null;
  phone: string | null;
  state: string | null;
  zip: string | null;
}

export interface Image {
  url?: string | null | undefined;
}

export interface BikeCategory {
  category: string | null | undefined;
}

export interface ReceivedTrackingNumber {
  trackingNumber?: string | null;
}

export interface Note {
  id: string;
  type: string;
  entry: string;
  createdAt: string;
}

// TODO: Update to use Offers
export interface Submission {
  id: number;
  eligible: boolean;
  ineligibilityReason?: string;
  source: number;
  expectedValue: number | null;
  defaultStartingPrice?: number | null;
  seller: SubmissionSeller;
  statusId?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  serialNumber?: string | null;
  fedexTrackingNumber?: string | null;
  receivedTrackingNumbers?: ReceivedTrackingNumber[];
  internalNote?: string | null;
  notes?: Note[];
  activeOfferGroup: {
    id: number;
    offers: Offer[];
  };
  items: [
    {
      build: string | null;
      buildCategory: string | null;
      wheelTier: string | null;
      powerMeter: string | null;
      frameMaterial: string | null;
      brand: string | null;
      categoryId: number | null;
      disciplineId: number | null;
      model: string | null;
      msrp: number | null;
      pipelineId: number | null;
      serialNumber: string | null;
      size: string | null;
      title: string | null;
      wheelSize: string | null;
      year: number | null;
      images: Image[];
      bikeCategory: BikeCategory;
    },
  ];
}

export enum ShopifyProductStatus {
  active = 'active',
  draft = 'draft',
  archived = 'archived',
}

export interface ProductVariantSearchResult {
  title: string;
  productId: number;
  productVariantId: number;
  brand: string;
  brandId: number;
  pipelineId: number | null;
  statusFlagId: number | null;
  year: number | null;
  size: string | null;
  wheelSize: string | null;
  sku: string;
  categoryId: number;
  category?: string;
  imageUrl?: string;
  shopifyStatus?: ShopifyProductStatus;
  model: string;
}

export interface CatalogProductVariantSearchParams {
  query?: string;
  upc?: string;
  sku?: string;
  brandId?: null | number;
  pipelineId?: null | number;
  productCategoryId?: null | number;
  statusFlagId?: null | number;
  hasImages?: null | boolean;
  shopifyStatus?: null | ShopifyProductStatus;
  year?: null | number;
  model?: null | string;
  sizeClassId?: null | number;
  limit?: number;
  offset?: number;
}

export type FilterParams = Pick<
  CatalogProductVariantSearchParams,
  | 'brandId'
  | 'pipelineId'
  | 'productCategoryId'
  | 'statusFlagId'
  | 'year'
  | 'hasImages'
  | 'shopifyStatus'
>;

export interface CatalogProductSearchResponse {
  limit: number;
  offset: number;
  total: number;
  results: ProductVariantSearchResult[];
}

export interface Product {
  id: number;
  title: string;
  displayTitle: string | null;
  model: null | string;
  modelDescription: string | null;
  categoryId: number;
  productCreated: string;
  brand: Brand;
  discipline: Discipline | null;
  productVariants: ProductVariant[];
  brandId: number;
  disciplineId: null | number;
  productSubCategory: ProductSubCategory | null;
  productSubCategoryId: number | null;
  productVariations: ProductVariation[];
  certifiedPreOwned: boolean;
  description: string | null;
  images: ProductImage[];
  sellPreService: boolean;
  headline?: string;
  longDescription: string | null;
  features: string | null;
  upgrades?: string;
  masterMechanicsNotes?: string;
}

export interface ProductVariant {
  id: number;
  productId: number;
  sku: string;
  displayOrder: null | number;
  msrpUsd: null | number;
  costUsd: number;
  startingPriceUsd: null | number;
  currentSalePriceUsd: null | number;
  compareAtPriceUsd: null | number;
  promotionalPricingFlag: boolean;
  upc: string;
  mpn: string | null;
  qbpId: string | null;
  year: null | number;
  condition: null | string;
  pipelineId: null | number;
  statusFlagId: number | null;
  weight: string | null;
  pointOfSale?: boolean;
  frameMaterial: string | null;
  frameHeadset: string | null;
  frameRearAxleSpacing: string | null;
  frameRearShockTravel: string | null;
  drivetrainBrandId: number | null;
  drivetrainBrand: Brand | null;
  drivetrainConfiguration: string | null;
  drivetrainShiftingTypeId: number | null;
  drivetrainShiftingType: DrivetrainShiftingType | null;
  componentIntendedUseId: number | null;
  componentIntendedUse: ComponentIntendedUse | null;
  sizeClassId: number | null;
  sizeClass: SizeClass | null;
  brakeType: string | null;
  productVariantFrameDimensions: ProductVariantFrameDimensions[] | null;
  productVariantComponents: ProductVariantComponent[] | null;
  productVariantAttributes: ProductVariantAttribute[] | null;
  singleItemProcurement: SingleItemProcurement | null;
  conditionDescription: string | null;
  conditionNotes: string | null;
  gender: string | null;
  material: string | null;
  apparelSleeveLength: string | null;
  frameRearShockTravelRange: string | null;
  tireType: string | null;
  configurationDetails: string | null;
  chargerIncluded: string | null;
  mileage: string | null;
  keyIncluded: string | null;
  tubelessCompatibility: string | null;
  frameRearTriangleMaterial: string | null;
  electricTopSpeed: string | null;
  mapPricing: boolean;
  images: ProductImage[];
  created: string;
  modified: string;
  createdByUserId: number;
  modifiedByUserId: number;
  // TODO TP-4672 - these should move to a data-driven list of attributes instead, like component specs
  color: string | null;
  size: null | string;
  wheelSize: null | string;
  fulfillmentProvider: null | string;
}

export type SingleItemProcurement = {
  holdDays: number;
  created: string;
  receivedAt: string;
  receivedByUserId: number;
  id: number;
  partnerSubmissionId: number | null;
  sybSubmissionId: number | null;
  sybSubmission?: Submission | null;
  serialNumber: string | null;
  productVariantId: number;
  productVariants?: ProductVariant[];
};

export interface ProductImage {
  id: number;
  productId: number;
  productVariantId: number;
  imageUrl: string;
  displayOrder: number;
  active: boolean;
  created: Date;
  modified: Date;
  createdByUserId: number;
  modifiedByUserId: number;
}

export interface FEProductImage {
  name: string;
  src: string;
  alt: string;
}

export interface UpdateProductImagesImage {
  id: number;
  active?: boolean;
}
export interface UpdateVariantImagesImage extends UpdateProductImagesImage {
  productVariantId: number;
}

export interface CreateProductImagesImage {
  imageUrl: string;
}
export interface CreateVariantImagesImage extends CreateProductImagesImage {
  productVariantId: number;
}

export interface ProductImageSignedUrlResponse {
  signedUrl: string;
  s3Url: string;
}

export interface AlternateEligibilityBody {
  error: string;
  eligible: boolean;
  newSku: string;
  alternate: string;
}

export interface UpdateProductBody {
  title: string;
  displayTitle: string | null;
  brandId: number;
  model: string | null;
  disciplineId: number | null;
  productSubCategoryId?: number | null;
  variationTypeIds: number[]; //note: actually an array of attributeTypeIds
  certifiedPreOwned: boolean;
  description: string | null;
  sellPreService: boolean;
}

interface UpdateProductVariantComponentSpecification {
  id?: number;
  productVariantComponentId: number;
  componentTypeId: number;
  componentSpecificationTypeId: number;
  value: string;
}
export interface UpdateProductVariantComponentSpecifications {
  specifications: UpdateProductVariantComponentSpecification[];
}

export interface UpdateProductVariantSingleItemProcurement {
  serialNumber?: string | null;
}

export interface UpdateProductVariantAttribute {
  attributeTypeId: number;
  value: string | null;
}

export interface UpdateProductVariantBody {
  displayOrder?: number | null;
  startingPriceUsd?: number | null;
  currentSalePriceUsd?: number | null;
  compareAtPriceUsd?: number | null;
  promotionalPricingFlag?: boolean;
  msrpUsd?: number | null;
  costUsd?: number | null;
  mapPricing?: boolean;
  condition?: string | null;
  year?: number | null;
  upc?: string | null;
  mpn?: string | null;
  qbpId?: string | null;
  pipelineId?: number | null;
  statusFlagId?: number | null;
  weight?: string | null;
  pointOfSale?: boolean;
  frameMaterial?: string | null;
  frameHeadset?: string | null;
  frameRearAxleSpacing?: string | null;
  frameRearShockTravel?: string | null;
  drivetrainBrandId?: number | null;
  drivetrainConfiguration?: string | null;
  drivetrainShiftingTypeId?: number | null;
  componentIntendedUseId?: number | null;
  sizeClassId?: number | null;
  brakeType?: string | null;
  conditionDescription?: string | null;
  conditionNotes?: string | null;
  gender?: string | null;
  material?: string | null;
  apparelSleeveLength?: string | null;
  frameRearShockTravelRange?: string | null;
  tireType?: string | null;
  configurationDetails?: string | null;
  chargerIncluded?: string | null;
  mileage?: string | null;
  keyIncluded?: string | null;
  tubelessCompatibility?: string | null;
  frameRearTriangleMaterial?: string | null;
  electricTopSpeed?: string | null;
  // TODO TP-4672 - these should move to a data-driven list of attributes instead, like component specs
  color?: string | null;
  size?: string | null;
  wheelSize?: string | null;
  hazmatInstructions?: string | null;
  shippingWeight?: string | null;
  productVariantAttributes?: UpdateProductVariantAttribute[];
  fulfillmentProvider?: string | null;
}

export interface CreateProductVariantBody extends UpdateProductVariantBody {
  createPurchaseOrder: boolean;
  submissionId?: number | null;
  source?: number | null;
  costUsd: number | null;
  serialNumber?: string | null;
  holdDays?: number | null;
}

export interface CreateProductBody {
  categoryId: number;
  brandId: number;
  title: string;
  displayTitle: string | null;
  model?: string | null;
  disciplineId?: number | null;
  createConditionAlternates?: boolean;
  applyPricingToConditionAlternate?: 'A' | 'B' | 'New';
  variants: CreateProductVariantBody[];
  certifiedPreOwned: boolean;
  description: string | null;
  sellPreService: boolean;
  enteredTrackingNumber?: string | null;
  productSubCategoryId?: number | null;
}

export interface UpdatePVFrameDimensions {
  id: number | null;
  minValue: number | null;
  maxValue: number | null;
  textValue: string | null;
  frameDimensionId: number;
}

export enum ShopifyStatus {
  NotInShopify = 'NOT IN SHOPIFY',
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
}

export interface ProductShopifyStatus {
  status: ShopifyStatus;
}

export type UserError = { field: string; message: string };

export interface ShopifyStatusChangeResponse {
  productUpdate: {
    product?: {
      id: string;
      status: ShopifyStatus;
    };
    userErrors: UserError[];
  };
}

export interface Inspection {
  created: string;
  id: number;
  inspectionParts: any[];
  inspectionPartsOrders: object;
  inspectionPoints: any[];
  inspectorId: number;
  inspectorName: string;
  isTubeless: boolean | null;
  itemIdentifier: string; // should be sku
  mechanicId: number;
  mechanicName: string;
  mechanicNotes: string | null;
  mechanicTime: number | null;
  mechanicTimestamp: string;
  notes: string;
  serviceLevel: string; // possible value is "CPO"
  serviceLevelId: number;
  status: number;
}

export type InspectionProduct = Pick<Product, 'id' | 'displayTitle' | 'brand' | 'model'>;

// NOTE: Currently allowed fields are indicated in the db field config
// - client/src/pages/catalog/utils/categories.ts
export type CopyAndSaveFieldValueToAllVariants = (
  field: string,
  value: string | number | null,
) => void;

export interface AdditionalEffect {
  outcome: 'success' | 'initiated' | 'failed';
  description: string;
}

export interface ProductPromotionalPayload {
  promotionalTextTypeId: number;
  content: { text: string };
  parsedContent?: string;
}

export interface ProductPromotionalText extends ProductPromotionalPayload {
  id: number;
  productId: number;
  createdByUserId: number;
  modifiedByUserId: number;
}
