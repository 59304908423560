/* 
Hard-coded field configuration data for variants in item catalog.
Intent is to replace this with data from the api.
For more information on this refactor: https://theproscloset.atlassian.net/wiki/spaces/TECH/pages/2508652695/IC+Variant+Form+rewrite 
*/

import { CatalogFieldConfig, FormikFieldConfiguration } from './formik/types';
import { ProductFormGroups } from './types';
import { CatalogProductState } from '../../../reducers/product-reducer';
import { displayCase, YEAR_OPTIONS } from '.';
import { cloneDeep } from 'lodash';
import StatusFlagOption from '../editProduct/StatusFlagOption';
import ProductVariantStatusFlag from '../ProductVariantStatusFlag';
import { NETSUITE_URL } from '../../../constants';
import { AutoCompleteEditabilityProps } from '../../../components/catalog/FormikEditableAutoCompleteField';
import { createBrand, fetchBrands } from '../../../services/catalog/services';
import { Dispatch } from 'redux';
import { setProductBrands } from '../../../actions';
import { Brand } from '../../../services/catalog/types';
import ChangeCategoryButton from '../editProduct/ChangeCategoryButton';
import { showAllPromoTextCategories } from './formFieldsMap';

//to be replaced with display order values in data, but this is meant to match the de facto
//group order in the old form
export const editProductVariantGroupDisplayOrderMap: { [group in ProductFormGroups]: number } = {
  Submission: 1,
  'Product Information': 2,
  'Pricing Information': 3,
  'Variant Specifications': 4,
  'Frame Information': 5,
  'Bike Information': 6,
  'E-Bike Information': 7,
  'Frame Geometry': 8,
  'Component Specifications': 9,
  'Condition Details': 999, //to be removed
};

/* option-set constants exported for use in temporary fieldConfigurationData; 
   goal is to load these from the db (somehow)
*/
export const conditionOptions = [
  { id: 'Pre-Owned', name: 'Pre-Owned' },
  { id: 'New', name: 'New' },
  { id: 'New With Warranty', name: 'New With Warranty' },
  { id: 'New - Open Box', name: 'New - Open Box' },
];

export const componentIntendedUseOptions = [
  { id: null, name: 'N/A' },
  { id: 1, name: 'Mountain' },
  { id: 2, name: 'Road' },
  { id: 3, name: 'Both' },
];

/**
 * NOTE: drivetrain shifting types mirrored from DB
 * - drivetrain_shifting_types
 *
 * If you update this list make sure to update the DB!
 */
export const shiftingTypeOptions = [
  { id: null, name: 'N/A' },
  { id: 1, name: 'Electronic' },
  { id: 2, name: 'Mechanical' },
  { id: 3, name: 'None' },
  { id: 4, name: 'Mechanical/Electronic' },
  { id: 5, name: 'Wireless Only' },
];

export const keyIncludedOptions = [
  { id: null, name: 'N/A' },
  { id: 'yes', name: 'Yes' },
  { id: 'no', name: 'No' },
];
export const chargerIncludedOptions = cloneDeep(keyIncludedOptions);

export const tireTypeOptions = [
  { id: null, name: 'N/A' },
  { id: 'Clincher', name: 'Clincher' },
  { id: 'Tubeless', name: 'Tubeless' },
  { id: 'Tubular', name: 'Tubular' },
];

export const fromGroupEnum = (
  groupEnum: ProductFormGroups,
): {
  displayName: string;
  displayOrder: number;
} => ({
  displayName: groupEnum,
  displayOrder: editProductVariantGroupDisplayOrderMap[groupEnum],
});

/* 
The idea here is for each of these objects to contain additional configuration for certain fields, layered on top of the configuration
that comes from data.  This allows us to retain certain one-off customizations without implementing a data model for them immediately.
In some cases we will be able to migrate them to data - whereupon we can add that configuration to the CatalogFieldConfig table and type, load
it from the API, and remove it from here.

Each distinct type and object corresponds with a particular componentType, and will be applied only to field configurations that have that
type specified in data.  Fields with other component types (or no component types) will default to simple text.
*/

/* Decision log - re: the optional `categoryId` parameter in `optionsFromStore` below.
    context:
      The productSubCategoryId field needs to do something weird - populate its list of options based on the category currently selected on the form.  
      This means the "folding in" of this auto-complete info needs access to that category id, which it currently does not.
    options:
      1. drill the category down there, and add an 'optionsFromCategoryAndStore' which will not work well if we don't remember to pass down category.
        fairly easy to pass down category tbh...
      2. nuke this and replace it with a function that returns FormikFieldConfiguration, without sharing buildFormikFieldConfiguration with EditPV
      3. replace the subCategory field's options with a little shim after we've converted to Formik objects
      4. make the subcategory dropdown its own component that gets the category id from Formik context
    decision:
      going with option 1 above, because it's simple enough and is easy
    consequences:
      I'll add some comments in appropriate places to notify readers that this is a one-off pattern.
      buildAutocompleteFormikConfig will always pass in categoryId, but all `optionsFromStore` functions except one will ignore it.
      
*/

type AdditionalAutocompleteConfiguration = {
  labelKey: string;
  OptionComponent?: React.ComponentType<any>;
  AdornmentComponent?: React.ComponentType<any>;
  editability?: (dispatch: Dispatch<any>) => AutoCompleteEditabilityProps; //only applicable to editableAutocomplete components
  SubComponent?: React.ComponentType<any>;
  multiple?: boolean;
  filterSelectedOptions?: boolean;
} & (
  | { options: object[]; optionsFromStore?: undefined }
  | {
      options?: undefined;
      optionsFromStore: (productStore: CatalogProductState, categoryId?: number) => object[];
    }
);

export const autoCompleteAdditionalConfiguration: {
  [valueKey: string]: AdditionalAutocompleteConfiguration;
} = {
  drivetrainBrandId: {
    labelKey: 'name',
    optionsFromStore: productStore => [
      { id: null, name: 'N/A' },
      ...productStore.brands.filter(brand => !!brand.drivetrainBrand),
    ],
  },
  drivetrainShiftingTypeId: {
    labelKey: 'name',
    options: shiftingTypeOptions,
  },
  tireType: {
    labelKey: 'name',
    options: tireTypeOptions,
  },
  chargerIncluded: {
    labelKey: 'name',
    options: chargerIncludedOptions,
  },
  keyIncluded: {
    labelKey: 'name',
    options: keyIncludedOptions,
  },
  sizeClassId: {
    labelKey: 'name',
    optionsFromStore: productStore => [{ id: null, name: 'N/A' }, ...productStore.sizeClasses],
  },
  year: {
    labelKey: 'name',
    options: [{ id: null, name: 'N/A' }, ...YEAR_OPTIONS],
  },
  pipelineId: {
    labelKey: 'pipeline',
    optionsFromStore: productStore => [{ id: null, pipeline: 'N/A' }, ...productStore.pipelines],
  },
  condition: {
    labelKey: 'name',
    options: conditionOptions,
  },
  statusFlagId: {
    labelKey: 'status',
    optionsFromStore: productStore => [{ id: null, status: 'N/A' }, ...productStore.statusFlags],
    OptionComponent: StatusFlagOption,
    AdornmentComponent: ProductVariantStatusFlag,
  },
  componentIntendedUseId: {
    labelKey: 'name',
    options: componentIntendedUseOptions,
  },
  brandId: {
    labelKey: 'name',
    optionsFromStore: productStore => productStore.brands,
    editability: dispatch => ({
      useDialog: true,
      handleDialogSubmit: async ({ value, setValue, setDialogOpen, setDialogError }) => {
        setDialogError('');
        try {
          const { data } = await createBrand(value);
          await fetchBrands({
            onSuccess: response => {
              dispatch(setProductBrands(response));
            },
          });

          setValue(data.id);
          setDialogOpen(false);
        } catch (error) {
          setDialogError(error?.response?.data?.message ?? 'Oops! Something went wrong!');
        }
      },
      filterStringify: (option: Brand) =>
        `${option.name} ${option.alternateNames?.join(' ') ?? ''}`,
    }),
  },
  categoryId: {
    labelKey: 'category',
    optionsFromStore: productStore => productStore.categories,
  },
  // used when editing a product - does not need to map to state object,
  // as the field's value itself never changes; the ChangeCategoryButton
  // opens a modal, the user submits to update the category, then the whole page reloads.
  disabledCategoryId: {
    labelKey: 'category',
    optionsFromStore: productStore => productStore.categories,
    SubComponent: ChangeCategoryButton,
  },
  disciplineId: {
    labelKey: 'discipline',
    optionsFromStore: productStore => [
      { id: null, discipline: 'N/A' },
      ...productStore.disciplines,
    ],
  },
  productSubCategoryId: {
    labelKey: 'name',
    optionsFromStore: (productStore, categoryId) => [
      { id: null, name: 'N/A' },
      ...(categoryId && productStore.productSubCategories[categoryId]
        ? productStore.productSubCategories[categoryId]
        : []),
    ],
  },
  variations: {
    labelKey: 'name',
    optionsFromStore: productStore =>
      productStore.attributeTypes
        .filter(attributeType => attributeType.activeForVariations)
        .map(({ id, name }) => ({ id, name: displayCase(name) })),
    multiple: true,
    filterSelectedOptions: true,
  },
};

type SubmissionFields = {
  sybSubmission: FormikFieldConfiguration;
  partnerSubmission: FormikFieldConfiguration;
  serialNumber: FormikFieldConfiguration;
  createPurchaseOrderStatus: FormikFieldConfiguration;
  purchaseOrderLink: FormikFieldConfiguration;
};

/* Submission fields - not data driven; plucked from this map at runtime based on state of product */
export const EDIT_VARIANT_SUBMISSION_FIELDS: SubmissionFields = {
  sybSubmission: {
    valueKey: 'singleItemProcurement.sybSubmissionId',
    componentType: 'link',
    label: 'SYB Submission',
    getLinkUrl: (id: number | string) => `/tradeups/submission/${id}`,
    group: fromGroupEnum(ProductFormGroups['Submission']),
    displayOrder: 1,
  },
  partnerSubmission: {
    valueKey: 'singleItemProcurement.partnerSubmissionId',
    componentType: 'link',
    label: 'Partner Submission',
    getLinkUrl: (id: number | string) => `/tradeups/partner/${id}`,
    group: fromGroupEnum(ProductFormGroups['Submission']),
    displayOrder: 2,
  },
  serialNumber: {
    valueKey: 'singleItemProcurement.serialNumber',
    componentType: 'text',
    label: 'Serial Number',
    group: fromGroupEnum(ProductFormGroups['Submission']),
    displayOrder: 3,
  },
  createPurchaseOrderStatus: {
    valueKey: 'singleItemProcurement.createPurchaseOrderStatus',
    componentType: 'displayText',
    label: 'Purchase Order Status',
    group: fromGroupEnum(ProductFormGroups['Submission']),
    displayOrder: 4,
  },
  purchaseOrderLink: {
    valueKey: 'singleItemProcurement.createdPurchaseOrderInternalId',
    componentType: 'link',
    label: 'Purchase Order Number',
    getLinkUrl: (id: number | string) =>
      `${NETSUITE_URL}/app/accounting/transactions/purchord.nl?id=${id}`,
    group: fromGroupEnum(ProductFormGroups['Submission']),
    displayOrder: 5,
  },
};

// could and eventually should come from catalog field config / productStore
export const getProductFieldConfig = ({
  mode,
  categoryId,
  submissionId,
  createConditionAlternates, // form state value
  createConditionAlternatesDisabled,
}: {
  mode: 'create' | 'edit';
  categoryId: number;
  submissionId?: number | null;
  createConditionAlternates?: boolean;
  createConditionAlternatesDisabled?: boolean;
}): CatalogFieldConfig[] => {
  const defaultConfig: CatalogFieldConfig = {
    productVariantPropertyName: null,
    attributeTypeId: null,
    componentType: 'text',
    catalogFieldConfigGroup: fromGroupEnum(ProductFormGroups['Product Information']),
    dataType: null,
    disabled: false,
    entryLines: null,
    excludedByDefault: false,
    placeholder: null,
    requiresEditMode: false,
    updateRequiresPermissionId: null,
    fullWidth: false,
    displayOrder: 0,
    label: '',
  };
  const baseProductFields: CatalogFieldConfig[] = [
    {
      ...defaultConfig,
      _productPropertyName: 'title',
      label: 'Internal Title',
      fullWidth: true,
      // autoFocus: true  TODO somehow
      displayOrder: 1,
    },
    {
      ...defaultConfig,
      _productPropertyName: 'displayTitle',
      label: 'Display Title',
      fullWidth: true,
      displayOrder: 2,
    },
    {
      ...defaultConfig,
      _productPropertyName: 'model',
      label: 'Model',
      displayOrder: 3,
    },
    {
      ...defaultConfig,
      _productPropertyName: 'brandId',
      label: 'Brand',
      placeholder: 'Select a Brand',
      componentType: 'editableAutocomplete',
      displayOrder: 4,
    },
    {
      ...defaultConfig,
      _productPropertyName: mode == 'edit' ? 'disabledCategoryId' : 'categoryId',
      componentType: 'autocomplete',
      label: 'Category*',
      placeholder: 'Select a Category',
      displayOrder: 5,
      disabled: mode === 'edit',
    },
    {
      ...defaultConfig,
      _productPropertyName: 'disciplineId',
      componentType: 'autocomplete',
      label: 'Discipline',
      placeholder: 'Select a Discipline',
      displayOrder: 6,
    },
    {
      ...defaultConfig,
      _productPropertyName: 'productSubCategoryId',
      componentType: 'autocomplete',
      label: 'Sub Category',
      displayOrder: 7,
    },
    // note: there's no reason this *has* to be an edit-only field, but it's not supported
    // in the create product api at present.
    {
      ...defaultConfig,
      _productPropertyName: 'variations',
      componentType: 'autocomplete',
      label: 'Variation Types',
      displayOrder: 8,
      requiresEditMode: true,
    },
    {
      ...defaultConfig,
      _productPropertyName: 'certifiedPreOwned',
      componentType: 'checkbox',
      label: 'Certified Pre-Owned',
      displayOrder: 9,
    },
  ];

  const basePromotionalTextFields: CatalogFieldConfig[] = [
    {
      ...defaultConfig,
      _productPropertyName: 'longDescription',
      label: 'Long Description (What We Love)',
      entryLines: 5,
      fullWidth: true,
      displayOrder: 11,
    },
    {
      ...defaultConfig,
      _productPropertyName: 'features',
      label: 'Features',
      entryLines: 5,
      fullWidth: true,
      placeholder: 'Enter one line per bullet, without any dashes or bullet characters',
      displayOrder: 12,
    },
  ];

  const bikePromotionalTextFields: CatalogFieldConfig[] = [
    {
      ...defaultConfig,
      _productPropertyName: 'headline',
      label: 'Headline',
      fullWidth: true,
      displayOrder: 10,
    },
    {
      ...defaultConfig,
      _productPropertyName: 'upgrades',
      label: 'Upgrades',
      entryLines: 5,
      fullWidth: true,
      placeholder: 'Enter one line per bullet, without any dashes or bullet characters',
      displayOrder: 13,
    },
    {
      ...defaultConfig,
      _productPropertyName: 'masterMechanicsNotes',
      label: 'Master Mechanics Notes',
      entryLines: 5,
      fullWidth: true,
      placeholder: 'Enter one line per bullet, without any dashes or bullet characters',
      displayOrder: 14,
    },
  ];

  const conditionAlternateFields: CatalogFieldConfig[] =
    mode === 'edit'
      ? []
      : [
          {
            ...defaultConfig,
            componentType: 'checkbox',
            label: 'Create Condition Product Alternates',
            _productPropertyName: 'createConditionAlternates',
            // note that createConditionAlternatesDisabled is entirely category-driven
            disabled: !!createConditionAlternatesDisabled,
            displayOrder: 15,
          },
        ];
  if (mode === 'create' && createConditionAlternates) {
    conditionAlternateFields.push({
      ...defaultConfig,
      componentType: 'autocomplete',
      label: 'Apply Pricing to Condition Alternate',
      autocompleteOptions: ['New', 'A', 'B'],
      placeholder: 'Select a Condition',
      _productPropertyName: 'conditionAlternate',
      displayOrder: 99,
      catalogFieldConfigGroup: fromGroupEnum(ProductFormGroups['Pricing Information']),
    });
  }

  const includedBaseProductFields =
    mode === 'edit' ? baseProductFields : baseProductFields.filter(f => !f.requiresEditMode);

  const submissionFields: CatalogFieldConfig[] =
    mode === 'create' && submissionId
      ? [
          {
            ...defaultConfig,
            catalogFieldConfigGroup: fromGroupEnum(ProductFormGroups['Submission']),
            _productPropertyName: 'submissionId',
            label: 'Submission Id',
            disabled: true,
            displayOrder: 1,
          },
          {
            ...defaultConfig,
            catalogFieldConfigGroup: fromGroupEnum(ProductFormGroups['Submission']),
            _productPropertyName: 'serialNumber',
            label: 'Serial Number',
            placeholder: 'e.g. zX1y182871',
            displayOrder: 2,
          },
          {
            ...defaultConfig,
            catalogFieldConfigGroup: fromGroupEnum(ProductFormGroups['Submission']),
            _productPropertyName: 'createPurchaseOrder',
            componentType: 'checkbox',
            label: 'Create Purchase Order',
            displayOrder: 3,
          },
        ]
      : [];

  return [
    ...includedBaseProductFields,
    ...basePromotionalTextFields,
    ...(showAllPromoTextCategories.includes(categoryId) ? bikePromotionalTextFields : []),
    ...conditionAlternateFields,
    ...submissionFields,
  ];
};
