import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { FailRemedies, FailedPointOption } from '../../../_shared';
import { FEInspectionPoint, InspectionPart } from '../../../../../../services/service/types';
import { useStaticServiceTableStyles } from '../styled';

interface StaticServiceTableProps {
  failedPoints: FEInspectionPoint[];
  failedPointOptions: FailedPointOption[];
  pointTypeCategoryLookup: { [key: number]: string };
  partsByPointId: { [key: number]: InspectionPart[] };
}

export const StaticServiceTable: React.FC<StaticServiceTableProps> = ({
  failedPoints,
  failedPointOptions,
  pointTypeCategoryLookup,
  partsByPointId,
}) => {
  const classes = useStaticServiceTableStyles();

  if (!failedPoints) return null;

  const headerCellClass = `${classes.thCell} ${classes.leftAlignedCell}`;

  return (
    <>
      <TableContainer>
        <Table className={classes.table} aria-label="inspections-table">
          <TableHead className={classes.th}>
            <TableRow className={classes.row}>
              <TableCell className={headerCellClass}>Category</TableCell>
              <TableCell className={headerCellClass}>Inspection Point</TableCell>
              <TableCell className={headerCellClass}>Remedy</TableCell>
              <TableCell className={headerCellClass}>Inspector Note</TableCell>
              <TableCell className={headerCellClass}>Parts</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {failedPoints.map(point => {
              const pointType = failedPointOptions.find(
                option => option.value === point.inspectionPointTypeId,
              );
              return (
                <TableRow key={point.inspectionPointTypeId} className={classes.row}>
                  {/* Inspection Category */}
                  <TableCell scope="row">
                    {pointTypeCategoryLookup[point.inspectionPointTypeId]}
                  </TableCell>

                  {/* Inspection Point */}
                  <TableCell scope="row">{pointType?.label ?? 'shrug'}</TableCell>

                  {/* Point Remedy */}
                  <TableCell scope="row">
                    {!!point.failRemedyId ? FailRemedies[point.failRemedyId] : 'shrug'}
                  </TableCell>

                  {/* Notes */}
                  <TableCell scope="row">{point.note}</TableCell>

                  {/* Parts */}
                  <TableCell scope="row">
                    {partsByPointId[point.inspectionPointTypeId]?.map(part => (
                      <div key={part.id}>
                        {part.identifier}
                        {part.quantity > 1 ? ` (x${part.quantity})` : null}
                      </div>
                    ))}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
