import { AnyAction } from 'redux';
import { GridSortModel } from '@material-ui/data-grid';
import {
  SET_INSPECTIONS_SEARCH_PARAM,
  SET_INSPECTIONS_SEARCH_RESULTS,
  SET_INSPECTIONS_SEARCH_LOADING,
  SET_INSPECTIONS_SEARCH_ERROR,
  CLEAR_INSPECTIONS_SEARCH_PARAMS,
} from '../actions/types';

import { InspectionSearchResult } from '../services/service/types';
import { GridPaginationModel } from '@mui/x-data-grid';
import { PAGE_SIZE } from '../constants';

export interface InspectionsSearchParams {
  limit: number;
  offset: number;
  paginationModel: GridPaginationModel;
  sortModel: GridSortModel;

  searchQuery?: string;
  inspectorIds: number[];
  mechanicIds: number[];
  statusIds: number[];
}

export interface InspectionsSearchState {
  params: InspectionsSearchParams;
  results: InspectionSearchResult[];
  loading: boolean;
  error: string | undefined;
  total: number;
}

export type ParamKeys = keyof InspectionsSearchParams;
export type FilterParamKeys = keyof Pick<
  InspectionsSearchParams,
  'sortModel' | 'searchQuery' | 'inspectorIds' | 'mechanicIds' | 'statusIds'
>;

const initialParams = {
  limit: PAGE_SIZE,
  offset: 0,
  paginationModel: { page: 0, pageSize: PAGE_SIZE },
  sortModel: [],
  inspectorIds: [],
  mechanicIds: [],
  statusIds: [],
  searchQuery: '',
};

const initialState: InspectionsSearchState = {
  params: initialParams,
  results: [],
  loading: false,
  error: undefined,
  total: 0,
};

export default function catalogSearchProductsReducer(
  state = initialState,
  action: AnyAction,
): InspectionsSearchState {
  const { type, payload } = action;
  switch (type) {
    case SET_INSPECTIONS_SEARCH_PARAM: {
      return {
        ...state,
        params: {
          ...state.params,
          // reset page index when new data is loaded
          paginationModel: { ...state.params.paginationModel, page: 0 },
          [payload.param]: payload.value,
        },
      };
    }
    case CLEAR_INSPECTIONS_SEARCH_PARAMS: {
      return {
        ...state,
        params: {
          ...state.params,
          ...initialParams,
        },
      };
    }
    case SET_INSPECTIONS_SEARCH_RESULTS: {
      return {
        ...state,
        results: payload.results,
        total: payload.total,
      };
    }
    case SET_INSPECTIONS_SEARCH_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case SET_INSPECTIONS_SEARCH_ERROR: {
      return {
        ...state,
        error: payload,
      };
    }
    default:
      return state;
  }
}
