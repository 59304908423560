import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Inspection,
  InspectionPointTypeCategory,
  FEInspectionCategory,
  FEInspectionPoint,
} from '../../../../../services/service/types';
import { FailRemedies, Grades } from '../types';
import {
  getInspection,
  getInspectionPointCategories,
} from '../../../../../services/service/services';
import { getExportsForTesting } from '../../../../../testing/getExportsForTesting';
import { noop } from 'lodash';
import { ErrorBus } from '../../../../_shared/useErrorBus';
import { LoadingBus } from '../../../../_shared/useLoadingBus';

export interface FailedPointOption {
  label: string;
  value: number;
}
export interface Lookup {
  [key: number]: string;
}

export class UseInspection {
  inspection?: Inspection;
  categorizedPoints?: FEInspectionCategory[];
  failedPointOptions?: FailedPointOption[];
  // TP-3709: remove failedReplacePointOptions after conflicting PRs are merged
  failedReplacePointOptions?: FailedPointOption[];
  pointTypeLookup?: Lookup;
  pointTypeCategoryLookup?: Lookup;
  refreshInspectionData: () => void;

  constructor(init?: Partial<UseInspection>) {
    this.inspection = init?.inspection ?? undefined;
    this.categorizedPoints = init?.categorizedPoints ?? undefined;
    this.failedPointOptions = init?.failedPointOptions ?? undefined;
    this.failedReplacePointOptions = init?.failedReplacePointOptions ?? undefined;
    this.pointTypeLookup = init?.pointTypeLookup ?? undefined;
    this.pointTypeCategoryLookup = init?.pointTypeCategoryLookup ?? undefined;
    this.refreshInspectionData = init?.refreshInspectionData ?? noop;
  }
}

const prepareCategoriesForDisplay = (
  categories: InspectionPointTypeCategory[],
  inspectionData: Inspection,
) => {
  const categoriesForDisplay: FEInspectionCategory[] = [];
  for (const category of categories) {
    const points = [];

    for (const pointType of category.inspectionPointTypes) {
      const matchingDataPoint = inspectionData.inspectionPoints.find(
        point => point.inspectionPointTypeId === pointType.id,
      );
      // might not have matching data points for some legacy inspections
      // (wherein the point type was created after the inspection was)
      if (matchingDataPoint) {
        points.push(
          new FEInspectionPoint({
            ...pointType,
            ...matchingDataPoint,
          }),
        );
      }
    }
    categoriesForDisplay.push({ ...category, points });
  }
  return categoriesForDisplay;
};

const prepareDerivedData = (categorizedPoints: FEInspectionCategory[]) => {
  const failedPointOptions: FailedPointOption[] = [];
  const failedReplacePointOptions: FailedPointOption[] = [];
  const pointTypeLookup: Lookup = {};
  const pointTypeCategoryLookup: Lookup = {};

  for (const category of categorizedPoints) {
    for (const point of category.points) {
      if (!pointTypeLookup[point.inspectionPointTypeId]) {
        pointTypeLookup[point.inspectionPointTypeId] = point.description;
      }
      pointTypeCategoryLookup[point.inspectionPointTypeId] = category.category;

      if (point.gradeId === Grades.fail) {
        failedPointOptions.push({ label: point.description, value: point.inspectionPointTypeId });

        if (point.failRemedyId === FailRemedies.replace) {
          failedReplacePointOptions.push({
            label: point.description,
            value: point.inspectionPointTypeId,
          });
        }
      }
    }
  }

  return {
    failedPointOptions,
    failedReplacePointOptions,
    pointTypeLookup,
    pointTypeCategoryLookup,
  };
};

interface UseInspectionProps {
  inspectionId: string;
  setError: ErrorBus['setError'];
  setLoading: LoadingBus['setLoading'];
}

const busKey = 'useInspection';

const useInspection = ({
  inspectionId,
  setError,
  setLoading,
}: UseInspectionProps): UseInspection => {
  const [inspection, setInspection] = useState<UseInspection['inspection']>();
  const [categories, setCategories] = useState<InspectionPointTypeCategory[]>();

  const refetchInspection = useCallback(() => {
    setError(busKey, undefined);
    try {
      // inspection request
      const inspectionLoadingKey = `${busKey} -> getInspection`;
      setLoading(inspectionLoadingKey, true);
      getInspection(inspectionId, {
        onSuccess: inspectionData => setInspection(inspectionData),
        catchFailure: error => setError(busKey, error),
        handleFinally: () => setLoading(inspectionLoadingKey, false),
      });

      // inspection point categories request
      const pointCategoriesLoadingKey = `${busKey} -> getInspectionPointCategories`;
      setLoading(pointCategoriesLoadingKey, true);
      getInspectionPointCategories({
        onSuccess: setCategories,
        catchFailure: error => setError(busKey, error),
        handleFinally: () => setLoading(pointCategoriesLoadingKey, false),
      });
    } catch (error) {
      setError(busKey, error);
    }
  }, [setLoading, inspectionId, setError]);

  useEffect(refetchInspection, [refetchInspection]);

  const categorizedPoints: UseInspection['categorizedPoints'] = useMemo(
    () =>
      inspection && categories ? prepareCategoriesForDisplay(categories, inspection) : undefined,
    [inspection, categories],
  );

  const derivedData:
    | {
        failedPointOptions: FailedPointOption[];
        failedReplacePointOptions: FailedPointOption[];
        pointTypeLookup: Lookup;
        pointTypeCategoryLookup: Lookup;
      }
    | undefined = useMemo(
    () => (categorizedPoints ? prepareDerivedData(categorizedPoints) : undefined),
    [categorizedPoints],
  );

  return {
    inspection,
    categorizedPoints,

    ...derivedData,

    refreshInspectionData: refetchInspection,
  };
};

export { useInspection };

export const exportsForTesting = getExportsForTesting({
  prepareCategoriesForDisplay,
  prepareDerivedData,
});
