import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function DialogConfirm({ onClose, onConfirm, open }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are about to fail this inspection.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            No
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function FormDialog({
  onClose,
  onApprove,
  onFail,
  onChange,
  open,
  imageSrc,
  note,
  returnId,
}) {
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [noteError, setNoteError] = React.useState(false);

  function handleFail() {
    setNoteError(false);
    if (!note) {
      setNoteError(true);
    } else {
      setOpenConfirm(true);
    }
  }

  function handleConfirmClose() {
    setOpenConfirm(false);
  }

  function handleConfirmFail() {
    onFail(returnId);
    setOpenConfirm(false);
  }

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Inspection</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ width: '100%' }}
          >
            <img
              src={imageSrc}
              alt="product"
              style={{ minWidth: 150, maxWidth: 400, height: 'auto' }}
            />
          </Grid>
          <TextField
            error={noteError}
            autoFocus
            margin="dense"
            name="note"
            label="Note"
            type="text"
            value={note}
            onChange={onChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="default">
            Cancel
          </Button>
          <Button onClick={() => handleFail(returnId)} color="secondary">
            Fail
          </Button>
          <Button onClick={() => onApprove(returnId)} color="primary">
            Approve
          </Button>
        </DialogActions>
      </Dialog>
      <DialogConfirm
        open={openConfirm}
        onClose={handleConfirmClose}
        onConfirm={handleConfirmFail}
      />
    </div>
  );
}
