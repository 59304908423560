import { pick } from 'lodash';
import { ProductPromotionalPayload } from '../../../services/catalog/types';
import { AddProductAndVariantValues, AllProductValues } from '../addProduct/types';

const promotionalTextIds = {
  headline: 1,
  longDescription: 2,
  features: 3,
  upgrades: 4,
  masterMechanicsNotes: 5,
};

const leadingNewlines = /^(\n){1,}/;
const trailingNewLines = /(\n){1,}$/;
let isValidated = false;
let trimmedText: string;

export const removeLeadingAndTrailingChars = (text: string) => {
  const trimmedLeadingText = text.replace(leadingNewlines, '');
  const trimmedLeadingAndTrailingText = trimmedLeadingText.replace(trailingNewLines, '');
  if (trimmedLeadingAndTrailingText?.length === text?.length) {
    isValidated = true;
  }
  return trimmedLeadingAndTrailingText.trim();
};

export const cleanupNewlines = (text: string | null | undefined) => {
  if (!text) {
    return '';
  }
  trimmedText = text ?? '';

  while (!isValidated && trimmedText?.length > 0) {
    trimmedText = removeLeadingAndTrailingChars(trimmedText);
  }

  isValidated = false; // reset the for the next promoTypeText
  return trimmedText;
};

export const buildPromotionalTextsPayload = (
  productValues: AllProductValues | AddProductAndVariantValues,
): ProductPromotionalPayload[] => {
  const promotionalTexts = pick(productValues, Object.keys(promotionalTextIds));

  const productPromotionalTextPayload = [];

  for (const promoTextType in promotionalTexts) {
    productPromotionalTextPayload.push({
      promotionalTextTypeId: promotionalTextIds[promoTextType as keyof typeof promotionalTextIds],
      content: {
        text: cleanupNewlines(promotionalTexts[promoTextType as keyof typeof promotionalTextIds]),
      },
    });
  }

  return productPromotionalTextPayload;
};
