import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

// these props are defined by material-table's internal data structure
interface Props {
  onFilterChanged: (rowId: string, value: any) => void;
  columnDef: any;
}

const FilterMultiSelect = ({ columnDef, onFilterChanged }: Props) => {
  const [selected, setSelected] = useState(
    columnDef?.defaultFilter ? columnDef.defaultFilter : ([] as string[]),
  );

  const handleChangeMultiple = (e: any) => {
    const { value } = e.target;
    const updatedValue = value.includes('CLEAR_ALL') ? [] : value;
    setSelected(updatedValue);
    onFilterChanged(columnDef.tableData.id, updatedValue);
  };

  return (
    <Select
      multiple
      value={selected}
      onChange={handleChangeMultiple}
      style={{ width: '100%' }}
      renderValue={(selected: any) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {selected.map((value: string) => (
            <Chip key={value} label={columnDef.lookup[value]} />
          ))}
        </div>
      )}
    >
      <MenuItem value="CLEAR_ALL" style={{ color: 'silver' }}>
        -- clear all --
      </MenuItem>
      {Object.keys(columnDef.lookup).map((key: string) => (
        <MenuItem value={key} key={key}>
          {columnDef.lookup[key]}
        </MenuItem>
      ))}
    </Select>
  );
};

export default FilterMultiSelect;
