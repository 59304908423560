import React, { useState } from 'react';
import styled from 'styled-components';
import styles from '../../styledComponents';
import { PreferenceSwitch } from './PreferenceSwitch';
import { Permissions } from '../../reducers/types';
import { Button } from '../../components/library';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';

const { PageWrapper, PageHeader } = styles;

const PreferencesPageWrapper = styled(PageWrapper)`
  width: 60%;
  margin: 0 auto;
`;
const PreferencesHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
`;
const PreferenceTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0.5rem;
`;
const PreferenceRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[400]};

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.palette.grey[400]};
  }
`;
const PreferenceDescription = styled.div`
  color: ${({ theme }) => theme.palette.grey[700]};
`;

const preferences: {
  title: string;
  description: string;
  localStorageKey: string;
  forceReload?: boolean;
  permissions: Permissions[];
}[] = [
  {
    title: 'Cycle Timing Widget Beta',
    description: 'Enable the new cycle timing widget.',
    localStorageKey: 'cycleTimingsAB',
    forceReload: true,
    permissions: [Permissions.GENERAL],
  },
];

export const PreferencesPage = () => {
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [cacheBust, setCacheBust] = useState<number>(0);
  const userPermissions: Permissions[] = JSON.parse(
    localStorage.getItem('userPermissions') as string,
  );
  const preferencesForUser = preferences.filter(
    prefConfig => !!userPermissions?.some(perm => prefConfig.permissions.includes(perm)),
  );
  const userHasPreferences = !!preferencesForUser.length;

  const handleClearCache = () => {
    try {
      setHasError(false);
      const perms = localStorage.getItem('userPermissions') as string;
      const auth = JSON.parse(localStorage.getItem('persist:root') as string)?.auth;
      localStorage.clear();
      localStorage.setItem('userPermissions', perms);
      localStorage.setItem('persist:root', JSON.stringify({ auth }));
      setCacheBust(prevCacheBust => prevCacheBust + 1);
    } catch {
      setHasError(true);
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  return (
    <PreferencesPageWrapper>
      <PreferencesHeaderRow>
        <PageHeader>Preferences</PageHeader>
        <Tooltip title="Reset all preferences and search params throughout Drivetrain">
          <div>
            {/*
              wrapping div is necessary because Tooltip children need to be able to hold a ref;
              our Library Button component is functional so can't
            */}
            <Button ordinality="secondary" variant="outlined" onClick={handleClearCache}>
              Clear Drivetrain Cache
            </Button>
          </div>
        </Tooltip>
      </PreferencesHeaderRow>
      {userHasPreferences &&
        preferencesForUser.map(p => (
          <PreferenceRow key={p.localStorageKey}>
            <span>
              <PreferenceTitle>{p.title}</PreferenceTitle>
              <PreferenceDescription>{p.description}</PreferenceDescription>
            </span>
            <span>
              <PreferenceSwitch
                localStorageKey={p.localStorageKey}
                cacheBust={cacheBust}
                forceReload={p.forceReload}
              />
            </span>
          </PreferenceRow>
        ))}
      {!userHasPreferences && 'There are currently no preferences available for you to set.'}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={hasError ? 'error' : 'success'}>
          {hasError
            ? 'There was an error clearing the cache. Please clear your cache via the browser instead.'
            : 'Cache was successfully cleared'}
        </Alert>
      </Snackbar>
    </PreferencesPageWrapper>
  );
};
