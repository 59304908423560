import { ServicePlanRejectionReason as RejectionReason } from '../../../../../../services/service/types';
import { ServicePlanRejectionReason, ServicePlanRejectionReasonCheckbox } from './types';

export const getRejectionReasonsWithCheckboxValues = (
  allRejectionReasons: RejectionReason[] | null,
  servicePlanRejectionReasons: ServicePlanRejectionReason[] | undefined,
) => {
  if (!allRejectionReasons || !servicePlanRejectionReasons) {
    return [];
  }

  const rejectionReasonsWithCheckboxValues: ServicePlanRejectionReasonCheckbox[] = allRejectionReasons.map(
    reason => {
      const matchingServicePlanReason = servicePlanRejectionReasons.find(
        spReason => reason.id === spReason.rejectionReasonId,
      );

      return {
        rejectionReasonId: reason?.id,
        name: reason?.name,
        checked: !!matchingServicePlanReason, // true or false based on selected rejection reasons
      };
    },
  );

  return rejectionReasonsWithCheckboxValues;
};
