import React, { Component } from 'react';
import { API_URL } from '../../constants';
import { NavLink } from 'react-router-dom';
import axios from '../../utils/axios';
import { StripedDataGrid } from '../../styledComponents/datagrid';
import { PageWrapper } from '../../styledComponents/wrappers';

export default class PaypalPaidList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentsMade: [],
      success: true,
      false: true,
      loading: false,
      paginationModel: {
        page: 0,
        pageSize: 15,
      },
    };

    this.columns = [
      {
        headerName: 'PO Number',
        field: 'po_number',
        renderCell: ({ value, row }) => {
          return (
            <span
              style={row.transaction_status === 'SUCCESS' ? { color: 'green' } : { color: 'red' }}
            >
              {value}
            </span>
          );
        },
        flex: 1,
      },
      {
        headerName: 'Email',
        field: 'email',
        flex: 3,
      },
      {
        headerName: 'Transaction ID',
        field: 'transaction_id',
        flex: 2,
      },
      {
        headerName: 'Status',
        field: 'transaction_status',
        flex: 1.5,
      },
      {
        headerName: 'Batch',
        field: 'paypal_batch_id',
        flex: 2,
      },
      {
        headerName: 'Created',
        field: 'created_at',
        valueFormatter: ({ value }) =>
          new Date(value).toLocaleDateString('en-US', { timeZone: 'America/Denver' }),
        flex: 1,
      },
    ];
  }

  componentDidMount() {
    this.fetchPaidStatus();
  }

  fetchPaidStatus = async () => {
    this.setState({ loading: true });
    const url = `${API_URL}/finance/paypalPayouts`;

    try {
      const response = await axios.get(url);
      this.setState({ paymentsMade: response.data });
    } catch (e) {
      this.setState({ error: true });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <PageWrapper style={{ overflow: 'hidden' }}>
        <h2 style={{ textAlign: 'center' }}>PayPal Paid List</h2>
        <div className="react-table" style={{ marginBottom: '10px' }}>
          <NavLink
            style={{
              color: '#000',
              border: '1px solid',
              padding: '3px 10px',
              margin: '0',
            }}
            className="nav-item"
            to="/paypal"
          >
            Awaiting Payments
          </NavLink>
        </div>
        <StripedDataGrid
          columns={this.columns}
          rows={this.state.paymentsMade}
          loading={this.state.loading}
          getRowId={row => `${row.transaction_id}-${Math.floor(Math.random() * 10000)}`}
          paginationModel={this.state.paginationModel}
          onPaginationModelChange={m => this.setState({ paginationModel: m })}
          pageSizeOptions={[15, 30, 60]}
        />
      </PageWrapper>
    );
  }
}
