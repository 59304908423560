import React, { useState, useEffect, useCallback } from 'react';
import { SubmissionModal } from './SubmissionModal';
import { Link as RouterLink } from 'react-router-dom';
import { API_URL, SubmissionStatusIds } from '../../constants';
import axios from '../../utils/axios';
import { Submission } from './types';
import {
  Box,
  Typography,
  Paper,
  TextField,
  makeStyles,
  createStyles,
  Theme,
  Link,
} from '@material-ui/core';
import { isLookupSubmissionDisabled } from './submission-modal-utils';
import { Button } from '../../components/library';
import { useKeyPress } from '../../utils/hooks/useKeyPress';
import { Snackbar, useSnackbar } from '../../components/library/Snackbar';
import { TRADEUP_SEARCH_ROUTE } from '../tradeups/SubmissionListView';

export const RECEIVING_PAGE_ROUTE = '/receiving';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginInline: 'auto',
      maxWidth: '400px',
      width: '100%',
      marginTop: theme.spacing(8),
    },
    submissionLookup: {
      borderTop: `6px solid ${theme.palette.primary.main}`,
    },
    submissionLink: {
      color: theme.palette.text.secondary,
    },
  }),
);

export const ReceivingPage = () => {
  const classes = useStyles();
  const { snackbarOpen, handleSnackbarOpen, handleSnackbarClose } = useSnackbar();

  const [primarySubmission, setPrimarySubmission] = useState<Submission | null>(null);
  const [alternateSubmissions, setAlternateSubmissions] = useState<Submission[] | null>(null);
  const [isSubmissionFetched, setIsSubmissionFetched] = useState<boolean>(false);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [submissionId, setSubmissionId] = useState('');
  const [showTrackingNumber, setShowTrackingNumber] = useState(true);
  const [showSubId, setShowSubId] = useState(false);
  const [hasTrackingNumber, setHasTrackingNumber] = useState(false);
  const [hasSubmissionId, setHasSubmissionId] = useState(false);
  const [showSubmissionIdError, setShowSubmissionIdError] = useState(false);
  const [showTrackingNumberError, setShowTrackingNumberError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const fetchSubmissions = useCallback(
    async ({ submissionId, params }) => {
      const url = `${API_URL}/tradeup/v2/submissions`;
      const baseUrl = submissionId ? url + `/${submissionId}` : url;
      try {
        const res = await axios.get(baseUrl, { params: { ...params, loadItemProcurement: true } });

        if (!res) {
          return;
        }

        // check to see if there is at least one submission using the provided tracking number, if not, throw an error
        if (
          hasTrackingNumber &&
          Array.isArray(res?.data) &&
          !res.data.length &&
          !primarySubmission
        ) {
          if (setShowTrackingNumberError) {
            setShowTrackingNumberError(true);
            handleSnackbarOpen();
          }
          return;
        }

        // check to see if there is a submission using the provided submission ID, if not, throw an error
        if (hasSubmissionId && !Object.keys(res?.data).length && !primarySubmission) {
          if (setShowSubmissionIdError) {
            setShowSubmissionIdError(true);
            handleSnackbarOpen();
          }
          return;
        }

        // Update state for primary submission or pending submissions when there is data returned from api
        if ((res?.data[0] || res?.data) && !primarySubmission?.sellerId && !isSubmissionFetched) {
          setPrimarySubmission(hasTrackingNumber && !hasSubmissionId ? res?.data[0] : res?.data);
          setIsSubmissionFetched(true);
          setIsModalOpen(true);
        } else if (!alternateSubmissions) {
          const newAlternateSubmissions = res?.data ?? [];

          // sort pending submissions in descending order by createdAt date
          newAlternateSubmissions.sort(
            (first: { createdAt: string }, second: { createdAt: string }) =>
              new Date(second?.createdAt).getTime() - new Date(first?.createdAt).getTime(),
          );
          setAlternateSubmissions(newAlternateSubmissions);
        }
      } catch (e) {
        if (!primarySubmission) {
          if (setShowTrackingNumberError && hasTrackingNumber) {
            setShowTrackingNumberError(true);
          }
          if (setShowSubmissionIdError && hasSubmissionId) {
            setShowSubmissionIdError(true);
          }
          handleSnackbarOpen();
        }
      }
    },
    [
      hasSubmissionId,
      hasTrackingNumber,
      primarySubmission,
      isSubmissionFetched,
      alternateSubmissions,
      setShowTrackingNumberError,
      setShowSubmissionIdError,
      handleSnackbarOpen,
    ],
  );

  const handleClickOpen = async () => {
    const isTrackingNumberDifferent: boolean =
      hasTrackingNumber && trackingNumber !== primarySubmission?.fedexTrackingNumber;

    const isSubmissionIdDifferent: boolean =
      hasSubmissionId && (submissionId ?? '') !== primarySubmission?.id.toString();

    const hasTrackingNumberError: boolean = !showSubId && showTrackingNumberError;

    // only fetch new data if the tracking number input value is different than the
    // state primarySubmission fedexTrackingNumber and there isn't a tracking number error
    if (isTrackingNumberDifferent && !hasTrackingNumberError) {
      fetchSubmissions({ params: { trackingNumber } });
    } else if (isSubmissionIdDifferent) {
      // only fetch new data if the submission ID input value is different than the primarySubmission.id in state
      fetchSubmissions({ submissionId });
    } else if (
      //if the tracking number or the submission id hasn't changed since last submit,
      // then reopen the modal with existing submission without fetching again
      (!!primarySubmission && !isTrackingNumberDifferent && !hasTrackingNumberError) ||
      (!!primarySubmission && hasTrackingNumberError && !isSubmissionIdDifferent)
    ) {
      setIsModalOpen(true);
    }
  };

  // close the submission modal
  const handleClose = () => {
    setIsModalOpen(false);
  };

  // manage tracking number and submission id inputs
  const handleInput = (e: any) => {
    const { name, value } = e.currentTarget;
    let trackingNumber = value;

    // scanned tracking numbers can be longer than the 12 characters we have stored in tup.submissions,
    // so we need to use the last 12 characters
    if (name === 'trackingNumber' && value?.length > 12) {
      trackingNumber = value.substr(-12);
    }

    // set state based on input
    if (name === 'trackingNumber') {
      setTrackingNumber(trackingNumber);
    } else if (name === 'submissionId') {
      setSubmissionId(value);
    }

    // clear any error state on inputs
    if (showTrackingNumberError && name === 'trackingNumber' && !trackingNumber?.length) {
      setShowTrackingNumberError(false);
    }
    if (showSubmissionIdError && name === 'submissionId') {
      setShowSubmissionIdError(false);
    }
  };

  const resetErrors = () => {
    setShowSubmissionIdError(false);
    setShowTrackingNumberError(false);
  };

  const resetState = () => {
    setTrackingNumber('');
    setSubmissionId('');
    setHasTrackingNumber(false);
    setHasSubmissionId(false);
    resetErrors();
  };

  const handleOnClick = () => {
    setShowSubId(!showSubId);
    setShowTrackingNumber(!showTrackingNumber);
    resetState();
  };

  // manage hasTrackingNumber - tech debt TP-3981
  useEffect(() => {
    if (!hasTrackingNumber && trackingNumber.length) {
      setHasTrackingNumber(true);
    } else if (hasTrackingNumber && !trackingNumber.length) {
      setHasTrackingNumber(false);
    }
  }, [trackingNumber, hasTrackingNumber]);

  // manage hasSubmissionId - tech debt TP-3981
  useEffect(() => {
    if (!hasSubmissionId && submissionId.length) {
      setHasSubmissionId(true);
    } else if (hasSubmissionId && !submissionId.length) {
      setHasSubmissionId(false);
    }
  }, [submissionId, hasSubmissionId]);

  // Fetch all of the seller's alternate submissions
  useEffect(() => {
    if (primarySubmission?.sellerId && !isSubmissionFetched) {
      fetchSubmissions({
        params: {
          sellerId: primarySubmission.sellerId,
          statusId: SubmissionStatusIds.PENDING,
        },
      });
    }
  }, [primarySubmission, alternateSubmissions, fetchSubmissions, isSubmissionFetched]);

  // Manage input field value changes - tech debt TP-3981
  useEffect(() => {
    // Check to see if the user has modified the transaction id input after they have submitted the request,
    // if so, it resets the submission state values
    const hasTrackingNumberChanged: boolean =
      isSubmissionFetched &&
      !!trackingNumber &&
      !showSubId &&
      !showTrackingNumberError &&
      trackingNumber !== primarySubmission?.fedexTrackingNumber &&
      !isModalOpen;

    // Check to see if the user has modified the submission id input after they have submitted the request,
    // if so, it resets the submission state values
    const hasSubmissionIdChanged: boolean =
      isSubmissionFetched &&
      !!submissionId &&
      hasSubmissionId &&
      (submissionId ?? '') !== primarySubmission?.id.toString() &&
      !isModalOpen;

    // check to see if the transaction id was set to empty when there is a submission id,
    // this would happen if there was ever a transaction id that wasn't valid
    const hasNoTrackingNumberWhenSubmissionIdExists =
      !trackingNumber && !showSubId && showTrackingNumberError && submissionId;

    if (
      hasTrackingNumberChanged ||
      hasSubmissionIdChanged ||
      hasNoTrackingNumberWhenSubmissionIdExists
    ) {
      setPrimarySubmission(null);
      setAlternateSubmissions(null);
      setIsSubmissionFetched(false);
    }
  }, [
    hasSubmissionId,
    hasTrackingNumber,
    primarySubmission,
    showSubId,
    showTrackingNumberError,
    trackingNumber,
    isSubmissionFetched,
    submissionId,
    isModalOpen,
  ]);

  // Reset state values
  useEffect(() => {
    if (!trackingNumber && showSubId && showTrackingNumberError) {
      setTrackingNumber('');
      setSubmissionId('');
      setHasTrackingNumber(false);
      setHasSubmissionId(false);
      resetErrors();
    }
  }, [trackingNumber, showSubId, showTrackingNumberError]);

  useKeyPress({ activationKey: 'enter' }, handleClickOpen);

  return (
    <Box className={classes.container}>
      <Typography variant="h4" gutterBottom>
        Receiving
      </Typography>
      <Paper>
        <Box
          p={4}
          display="flex"
          flexDirection="column"
          gridGap="16px"
          className={classes.submissionLookup}
        >
          <div>
            <Typography variant="h5">Submission Lookup</Typography>
            <Typography variant="body1">Scan the barcode of the received submission.</Typography>
          </div>
          <Box minHeight="110px">
            {showTrackingNumber && (
              <TextField
                margin="normal"
                autoFocus
                id="tracking-number"
                label="Tracking Number"
                variant="outlined"
                color="secondary"
                name="trackingNumber"
                onChange={handleInput}
                value={trackingNumber}
                error={showTrackingNumberError}
                helperText={
                  showTrackingNumberError
                    ? 'Tracking number not found. Please enter the submission ID.'
                    : ''
                }
              />
            )}
            <Box>
              {(showSubId || showTrackingNumberError) && (
                <TextField
                  margin="normal"
                  id="submission-id"
                  label="Submission ID"
                  variant="outlined"
                  color="secondary"
                  autoFocus
                  name="submissionId"
                  onChange={handleInput}
                  value={submissionId}
                  error={showSubmissionIdError}
                  helperText={
                    showSubmissionIdError
                      ? 'Submission ID not found. Please enter a valid submission ID.'
                      : ''
                  }
                />
              )}
              <Link
                className={classes.submissionLink}
                component={RouterLink}
                to={TRADEUP_SEARCH_ROUTE}
              >
                Find Submission ID
              </Link>
            </Box>
          </Box>
          <Box>
            <Button
              disabled={isLookupSubmissionDisabled(
                showTrackingNumberError,
                hasTrackingNumber,
                hasSubmissionId,
              )}
              variant="contained"
              fullWidth
              color="primary"
              onClick={handleClickOpen}
              ordinality={'primary'}
              size="large"
              name="Lookup"
            >
              Lookup Submission
            </Button>
          </Box>
          {showSubId ? (
            <Button
              name="trackingNumber"
              fullWidth
              variant="text"
              ordinality="primary"
              onClick={handleOnClick}
            >
              Enter Tracking Number
            </Button>
          ) : (
            <Button
              name="submissionId"
              fullWidth
              variant="text"
              ordinality="primary"
              onClick={handleOnClick}
            >
              Enter Submission ID
            </Button>
          )}
        </Box>
      </Paper>
      <SubmissionModal
        primarySubmission={primarySubmission}
        alternateSubmissions={alternateSubmissions}
        isModalOpen={isModalOpen}
        trackingNumber={showTrackingNumberError ? null : trackingNumber}
        setPrimarySubmission={setPrimarySubmission}
        handleClose={handleClose}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        severity={'error'}
        message={'There was an issue loading submission data'}
        autoHideDuration={3000}
      />
    </Box>
  );
};
