import { CatalogFieldCategoryConfig } from './formik/types';
import { useState, useEffect } from 'react';
import { fetchCatalogFieldCategoryConfigs } from '../../../services/catalog/services';

interface UseFieldCategoryConfig {
  fieldCategoryConfigs: CatalogFieldCategoryConfig[];
}

export const useFieldCategoryConfig = (categoryId: number | undefined): UseFieldCategoryConfig => {
  const [fieldCategoryConfigs, setFieldCategoryConfigs] = useState<CatalogFieldCategoryConfig[]>(
    [],
  );
  useEffect(() => {
    if (categoryId) {
      fetchCatalogFieldCategoryConfigs(categoryId, {
        onSuccess: response => {
          setFieldCategoryConfigs(response);
        },
      });
    }
  }, [categoryId]);
  return { fieldCategoryConfigs };
};
