import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import newLogo from '../images/new-logo.svg';
import { API_URL } from '../constants';
import axios from '../utils/axios';

const headerStyle = {
  background: '#000',
  height: '75px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const formStyle = {
  display: 'flex',
  width: '80%',
  margin: '0 auto',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '25px',
};

const inputWrap = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: '15px',
};

const logoStyle = {
  height: '50px',
};

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      password: '',
      passwordConfirm: '',
      token: '',
      lowerCase: false,
      upperCase: false,
      match: false,
      length: false,
      number: false,
      specialChar: false,
      success: false,
      error: false,
      tokenExpired: false,
    };
  }

  componentDidMount() {
    const { token } = this.props.match.params;
    this.checkToken(token);
    this.setState({ token });
  }

  checkToken = async token => {
    const url = `${API_URL}/auth/checkToken?q=${token}`;
    try {
      await axios.get(url);
    } catch (e) {
      this.setState({ tokenExpired: true });
    }
  };

  submitReset = async e => {
    e.preventDefault();
    this.setState({ error: false });
    const url = `${API_URL}/auth/passwordResetSubmit`;
    try {
      const response = await axios.post(url, {
        q: this.state.token,
        password: this.state.password,
        confirmPassword: this.state.passwordConfirm,
      });
      if (response.status < 300) {
        this.setState({ success: true });
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      } else {
        this.setState({ error: true });
      }
    } catch (err) {
      this.setState({ error: true });
    }
  };

  disabled = () => {
    const { valid, password, passwordConfirm } = this.state;
    return !!(!password || !passwordConfirm || !valid);
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validatePassword = e => {
    if (e.nativeEvent.data === ' ') {
      return false;
    }
    this.handleChange(e);
    this.passwordMatch(e);
    const { value } = e.target;

    if (value.length >= 8) {
      // check length
      this.setState({ length: true });
    } else {
      this.setState({ length: false });
    }

    if (/\d/.test(value)) {
      // check has number
      this.setState({ number: true });
    } else {
      this.setState({ number: false });
    }

    if (/\W|_/g.test(value)) {
      // check has special char
      this.setState({ specialChar: true });
    } else {
      this.setState({ specialChar: false });
    }

    if (/[a-z]/.test(value)) {
      this.setState({ lowerCase: true });
    } else {
      this.setState({ lowerCase: false });
    }

    if (/[A-Z]/.test(value)) {
      this.setState({ upperCase: true });
    } else {
      this.setState({ upperCase: false });
    }

    if (
      value.length >= 8 &&
      /\d/.test(value) &&
      /\W|_/g.test(value) &&
      /[a-z]/.test(value) &&
      /[A-Z]/.test(value) &&
      this.state.match
    ) {
      this.setState({ valid: true });
    } else {
      this.setState({ valid: false });
    }
    return null;
  };

  passwordMatch = e => {
    if (e.nativeEvent.data === ' ') {
      return false;
    }
    this.handleChange(e);
    const { password, passwordConfirm } = this.state;

    if (password === e.target.value) {
      this.setState({ match: true }, () => {
        if (this.state.length && this.state.number && this.state.specialChar) {
          this.setState({ valid: true });
        }
      });
    } else if (password === '' || e.target.value === '' || passwordConfirm === '') {
      this.setState({ match: false, valid: false });
    } else {
      this.setState({ match: false, valid: false });
    }
    return null;
  };

  render() {
    const {
      password,
      passwordConfirm,
      length,
      number,
      specialChar,
      success,
      error,
      tokenExpired,
      lowerCase,
      upperCase,
    } = this.state;
    return (
      <div>
        <header style={headerStyle}>
          <img style={logoStyle} src={newLogo} alt="logo" />
        </header>
        {tokenExpired && (
          <div className="flex-column-center" style={formStyle}>
            <p>Your password reset token has expired!</p>
            <p>Please go through the reset password process again.</p>
          </div>
        )}
        {!success && !tokenExpired && (
          <form onSubmit={this.submitReset} style={formStyle}>
            <span style={inputWrap} className="input-label-wrap">
              <label>NEW PASSWORD:</label>
              <input
                type="password"
                value={password}
                onChange={this.validatePassword}
                name="password"
                className="text-input"
              />
            </span>
            <span style={inputWrap} className="input-label-wrap">
              <label>CONFIRM:</label>
              <input
                type="password"
                value={passwordConfirm}
                onChange={this.passwordMatch}
                name="passwordConfirm"
                className="text-input"
                style={this.state.match ? { background: 'rgba(0, 255, 0, 0.2)' } : {}}
              />
            </span>
            <p style={{ marginTop: '0', color: '#777' }} className="italic-small">
              Password must be at least
              <span style={length ? { color: '#00c160' } : { color: '#000', fontWeight: 'bold' }}>
                {' '}
                8 characters
              </span>
              ,
              <span
                style={lowerCase ? { color: '#00c160' } : { color: '#000', fontWeight: 'bold' }}
              >
                {' '}
                1 lowercase letter
              </span>
              ,
              <span
                style={upperCase ? { color: '#00c160' } : { color: '#000', fontWeight: 'bold' }}
              >
                {' '}
                1 uppercase letter
              </span>
              ,
              <span style={number ? { color: '#00c160' } : { color: '#000', fontWeight: 'bold' }}>
                {' '}
                1 number
              </span>
              ,
              <span
                style={specialChar ? { color: '#00c160' } : { color: '#000', fontWeight: 'bold' }}
              >
                {' '}
                1 special character{' '}
              </span>
              and
              <span
                style={
                  this.state.match ? { color: '#00c160' } : { color: '#000', fontWeight: 'bold' }
                }
              >
                {' '}
                match
              </span>
              .
            </p>
            <button
              type="submit"
              className="submit-button"
              onClick={this.submitReset}
              disabled={this.disabled()}
            >
              RESET
            </button>
            {error && (
              <p>There was an error resetting your password. Try again or resend the email.</p>
            )}
          </form>
        )}
        {success && (
          <div className="flex-column-center" style={{ marginTop: '25px' }}>
            <p>Your password has been reset!</p>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ResetPassword);
