import React, { useEffect, useRef, useState } from 'react';
import { Box, Fade, Typography, useTheme } from '@material-ui/core';
export type StickyHeaderProps = {
  icon?: React.ReactElement;
  iconColor?: string;
  title: React.ReactElement | string;
  subtitle?: React.ReactElement | string;
  description?: React.ReactElement | string;
  actions?: React.ReactElement;
  sticky?: boolean;
  size?: 'default' | 'large';
  px?: number;
  mx?: number;
  maxWidth?: number;
  descriptionStickyOnly?: boolean;
  actionsStickyOnly?: boolean;
  subtitleStickyOnly?: boolean;
};

const StickyOnly: React.FC<{
  stickyOnly: boolean;
  pinned: boolean;
  childElement: React.ReactElement | undefined;
}> = ({ stickyOnly, pinned = false, childElement }) => {
  return (
    <>
      {stickyOnly ? (
        <Fade in={pinned}>
          <div>{childElement}</div>
        </Fade>
      ) : (
        <>{childElement}</>
      )}
    </>
  );
};

export const StickyHeader: React.FC<StickyHeaderProps> = ({
  icon,
  iconColor,
  title,
  subtitle,
  description,
  actions,
  sticky = true,
  size,
  px,
  mx,
  maxWidth,
  descriptionStickyOnly = false,
  actionsStickyOnly = false,
  subtitleStickyOnly = false,
}) => {
  const ref = useRef(null);
  const [pinned, setPinned] = useState(false);

  useEffect(() => {
    const headerElement = ref.current;

    const observer = new IntersectionObserver(
      ([e]) => {
        setPinned(!e.isIntersecting);
      },
      { threshold: 1 },
    );

    if (headerElement) {
      observer.observe(headerElement);
    }
  }, []);

  const theme = useTheme();
  return (
    <Box
      {...(sticky ? { position: 'sticky', top: -1, zIndex: 10 } : {})}
      display="flex"
      flexDirection="column"
      bgcolor={pinned ? theme.palette.common.white : 'inherit'}
      style={{ transition: 'all 0.1s ease-in-out' }}
      boxShadow={pinned ? 4 : 0}
      mx={mx ?? 0}
    >
      <div ref={ref}>
        <Box
          px={px ?? 4}
          py={2}
          display="flex"
          alignItems="center"
          gridGap={theme.spacing(1)}
          justifyContent="space-between"
          maxWidth={maxWidth ?? undefined}
          mx="auto"
        >
          <Box display="flex" alignItems="center" gridGap={theme.spacing(2)}>
            {icon && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={theme.spacing(7)}
                width="auto"
                minWidth={theme.spacing(7)}
                p={1}
                borderRadius={4}
                my={-1}
                color={iconColor || theme.palette.primary.dark}
                position="relative"
                overflow="hidden"
              >
                {icon}
                <Box
                  position="absolute"
                  width="100%"
                  height="100%"
                  bgcolor={iconColor || theme.palette.primary.main}
                  style={{ opacity: 0.2 }}
                ></Box>
              </Box>
            )}
            <Box
              display="flex"
              {...(size === 'large'
                ? { flexDirection: 'row', gridGap: theme.spacing(2), alignItems: 'center' }
                : { flexDirection: 'column' })}
            >
              {title && (
                <Typography
                  variant={size === 'large' ? 'h4' : 'h6'}
                  component="h1"
                  style={{ fontFamily: theme.typography.bold, lineHeight: '1em' }}
                >
                  {title}
                </Typography>
              )}
              {subtitle && (
                <StickyOnly
                  stickyOnly={subtitleStickyOnly}
                  pinned={pinned}
                  childElement={
                    <Typography variant={icon ? 'subtitle2' : 'subtitle1'} color="textSecondary">
                      {subtitle}
                    </Typography>
                  }
                />
              )}
            </Box>
          </Box>
          <Box display="flex" alignItems="center" gridGap={theme.spacing(2)}>
            {description && (
              <StickyOnly
                stickyOnly={descriptionStickyOnly}
                pinned={pinned}
                childElement={
                  <Typography variant="body1" color="textSecondary">
                    {description}
                  </Typography>
                }
              />
            )}
            {actions && (
              <StickyOnly stickyOnly={actionsStickyOnly} pinned={pinned} childElement={actions} />
            )}
          </Box>
        </Box>
      </div>
    </Box>
  );
};
