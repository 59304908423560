import React, { Component, Fragment } from 'react';
import Autosuggest from 'react-autosuggest';
import PhoneInput from 'react-phone-number-input/basic-input';
import 'react-phone-number-input/style.css';
import axios from '../../utils/axios';
import { API_URL } from '../../constants';
import { compress } from '../../utils/compress';
import ImageUpload from './imageUploads';
import './tradeinForm.scss';

export default class TradeinForm extends Component {
  constructor(props) {
    super(props);

    this.mapRef = {};

    this.state = {
      brand: '',
      brands: [],
      description: '',
      nonDriveSideLoadStatus: 0,
      driveSideLoadStatus: 0,
      email: '',
      model: '',
      firstName: '',
      lastName: '',
      phone: '',
      photo1: '',
      photo2: '',
      photo1Hover: false,
      photo2Hover: false,
      success: false,
      error: false,
      suggestions: [],
      zip: '',
      zipError: false,
      customerZip: '',
    };
  }

  componentDidMount() {
    this.fetchBrands();
  }

  fetchBrands = async () => {
    const url = `${API_URL}/tradeup/syb/brands`;
    const response = await axios.get(url);
    this.setState({
      brands: response.data,
    });
  };

  submitForm = async e => {
    e.preventDefault();
    this.setState({ error: false });
    const {
      brand,
      model,
      description,
      photo1,
      photo2,
      firstName,
      lastName,
      email,
      phone,
      zip,
    } = this.state;
    const data = {
      brand,
      model,
      description,
      photo1,
      photo2,
      firstName,
      lastName,
      email,
      phone,
      zip,
    };

    const url = `${API_URL}/tradeup/internalSubmission`;
    try {
      const response = await axios.post(url, data);
      if (response.status < 300) {
        this.setState({ success: true });
      } else {
        this.setState({ error: true });
      }
    } catch (error) {
      this.setState({ error: true });
      console.error(error);
    }
  };

  handleChange = e => {
    const { id, value } = e.target;

    this.resetValidation(id);
    this.setState({ [id]: value });
  };

  resetState = () => {
    this.setState({
      brand: '',
      description: '',
      driveSideLoadStatus: 0,
      email: '',
      emailError: false,
      model: '',
      nonDriveSideLoadStatus: 0,
      firstName: '',
      lastName: '',
      phone: '',
      photo1: '',
      photo2: '',
      photo1Hover: false,
      photo2Hover: false,
      success: false,
      error: false,
      zip: '',
    });
  };

  // #region validation

  disableButton = () => {
    const {
      brand,
      model,
      description,
      photo1,
      photo2,
      firstName,
      lastName,
      email,
      phone,
      zip,
    } = this.state;

    return !!(
      !brand ||
      !model ||
      !description ||
      !photo1 ||
      !photo2 ||
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !zip
    );
  };

  resetValidation(id) {
    const { emailError, zipError } = this.state;
    if (id === 'email' && emailError) {
      this.setState({ emailError: false });
    }
    if (id === 'zip' && zipError) {
      this.setState({ zipError: false });
    }
  }

  validateEmail(email) {
    if (email.length !== 0) {
      const re = /\S+@\S+\.\S+/;
      const test = re.test(email);
      if (!test) {
        this.setState({ emailError: true });
      }
    }
  }

  validateZip(zip) {
    if (zip.length !== 0) {
      const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      const test = re.test(zip);
      if (!test) {
        this.setState({ zipError: true });
      }
    }
  }

  // #endregion

  // #region Image Handling

  dragIn = e => {
    const { name } = e.target;
    this.setState({
      [`${name}Hover`]: true,
    });
  };

  dragOut = e => {
    const { name } = e.target;
    this.setState({
      [`${name}Hover`]: false,
    });
  };

  handleDrag = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  handleImageDrop = async e => {
    const fileType = e.target.type;
    let urlToStore;
    const { name } = e.target;
    const file = e.target.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    reader.onload = ev => {
      urlToStore = ev.target.result;
    };

    const result = await axios.get(`${API_URL}/tradeup/signedUrl?file-type=${fileType}`);
    const { signedRequest, url } = result.data;
    const compressedFile = await compress(file, 100, 1200);
    const res = await this.makeS3Request(signedRequest, compressedFile, name);
    this.setState({ [name]: url });
  };

  makeS3Request = (signedUrl, file, side) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = data => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve();
          } else {
            console.error('err', xhr.statusText);
            reject(
              new Error('panic!', {
                status: xhr.status,
                statusText: xhr.statusText,
              }),
            );
          }
        }
      };

      xhr.upload.onprogress = e => {
        if (side === 'photo1') {
          const total = (e.loaded / e.total) * 100;
          this.setState({ driveSideLoadStatus: total });
        }
        if (side === 'photo2') {
          const total = (e.loaded / e.total) * 100;
          this.setState({ nonDriveSideLoadStatus: total });
        }
      };

      xhr.open('PUT', signedUrl);
      xhr.send(file);
    });
  // #endregion

  // #region auto suggest function
  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getSuggestions = value => {
    const inputValue = this.escapeRegexCharacters(value.trim().toLowerCase());
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.brands.filter(
          brand => brand.name.toLowerCase().slice(0, inputLength) === inputValue,
        );
  };

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => <div>{suggestion.name}</div>;

  onAutoChange = (event, { newValue }) => {
    this.setState({
      brand: newValue,
    });
  };

  onBlur = (event, { highlightedSuggestion }) => {
    if (highlightedSuggestion) {
      const { name } = highlightedSuggestion;
      this.setState({ brand: name });
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };
  // #endregion

  render() {
    const {
      model,
      brand,
      description,
      suggestions,
      firstName,
      lastName,
      email,
      zip,
      phone,
      driveSideLoadStatus,
      photo1,
      customerZip,
      nonDriveSideLoadStatus,
      photo2,
      error,
      success,
    } = this.state;

    const inputProps = {
      placeholder: 'Type a brand, e.g. Specialized',
      value: brand,
      onChange: this.onAutoChange,
      onBlur: this.onBlur,
    };
    return (
      <main style={{ marginBottom: '35px' }}>
        {!success && (
          <Fragment>
            <form className="tradein-wrap" onSubmit={this.submitForm}>
              <section className="bike-info-wrap flex-column-center">
                <h3 className="form-section">Bike Info:</h3>
                <div className="label-wrap">
                  <label htmlFor="brand">Brand:</label>
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    highlightFirstSuggestion
                  />
                </div>
                <div className="label-wrap">
                  <label htmlFor="model">Model:</label>
                  <input
                    className="input"
                    id="model"
                    type="text"
                    placeholder="e.g. Mach 429 SL"
                    value={model}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="label-wrap">
                  <label htmlFor="description">Description:</label>
                  <textarea
                    className="input"
                    id="description"
                    type="text"
                    placeholder="Enter a detailed description of the condition of the bike and any upgrades."
                    value={description}
                    onChange={this.handleChange}
                  />
                </div>
              </section>

              <section className="seller-info-wrap flex-column-center">
                <h3 className="form-section">Photo Upload:</h3>
                <span className="form-section gray-small">
                  Please upload two photos of your entire bike, one drive side and one non-drive
                  side.
                </span>
                <section className="flex-row-adjacent">
                  <ImageUpload
                    clearImage={() => this.setState({ photo1: '', driveSideLoadStatus: 0 })}
                    handleImageDrop={this.handleImageDrop}
                    handleDrag={this.handleDrag}
                    dragIn={this.dragIn}
                    dragOut={this.dragOut}
                    photoHover={this.state.photo1Hover}
                    loadStatus={driveSideLoadStatus}
                    photoUrl={photo1}
                    name="photo1"
                  />
                  <ImageUpload
                    clearImage={() => this.setState({ photo2: '', nonDriveSideLoadStatus: 0 })}
                    handleImageDrop={this.handleImageDrop}
                    handleDrag={this.handleDrag}
                    dragIn={this.dragIn}
                    dragOut={this.dragOut}
                    photoHover={this.state.photo2Hover}
                    loadStatus={nonDriveSideLoadStatus}
                    photoUrl={photo2}
                    name="photo2"
                  />
                </section>
              </section>
              <section className="seller-info-wrap flex-column-center">
                <h3 className="form-section">Seller Info:</h3>
                <section className="flex-row-adjacent">
                  <div className="label-wrap">
                    <label htmlFor="firstName">First Name:</label>
                    <input
                      className="input"
                      id="firstName"
                      type="text"
                      placeholder="e.g. Taylor"
                      value={firstName}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="label-wrap">
                    <label htmlFor="lastName">Last Name:</label>
                    <input
                      className="input"
                      id="lastName"
                      type="text"
                      placeholder="e.g. Smith"
                      value={lastName}
                      onChange={this.handleChange}
                    />
                  </div>
                </section>
                <div className="label-wrap">
                  <label htmlFor="email">
                    Email:
                    {this.state.emailError && (
                      <span className="invalid">
                        {' '}
                        Invalid email: for example - example@mail.com
                      </span>
                    )}
                  </label>
                  <input
                    className="input"
                    id="email"
                    type="text"
                    placeholder="e.g. taylorSmith@gmail.com"
                    value={email}
                    onBlur={() => this.validateEmail(email)}
                    onChange={this.handleChange}
                  />
                </div>

                <section className="flex-row-adjacent">
                  <div className="label-wrap" style={{ flex: 2 }}>
                    <label htmlFor="phone">Phone:</label>
                    <PhoneInput
                      id="phone"
                      value={phone}
                      onChange={value => this.setState({ phone: value })}
                      placeholder="e.g. (123) 456-7891"
                      country="US"
                      className="input"
                    />
                  </div>
                  <div className="label-wrap" style={{ flex: 1 }}>
                    <label htmlFor="zip">
                      Zip:
                      {this.state.zipError && <span className="invalid">Invalid: e.g. 80301</span>}
                    </label>
                    <input
                      className="input"
                      id="zip"
                      type="text"
                      placeholder="e.g. 80301"
                      value={zip}
                      onBlur={() => this.validateZip(zip)}
                      onChange={this.handleChange}
                    />
                  </div>
                </section>
              </section>
              <button
                type="submit"
                className="submit-btn"
                onClick={this.submitForm}
                disabled={this.disableButton()}
              >
                GET QUOTE
              </button>
              {error && (
                <div className="flex-column-center">
                  <p>There was an error submitting your bike for Trade-UP.</p>
                  <p>Please try again and/or contact customer service.</p>
                  <button type="button" onClick={this.resetState}>
                    <span className="italic-small">Reset?</span>
                  </button>
                </div>
              )}
            </form>
          </Fragment>
        )}
        {success && (
          <section className="tradein-wrap bike-info-wrap flex-column-center response-message-wrap">
            <h3>Success!</h3>

            <p className="client-message regular-gray text-center">
              Thank you for submitting your
              <strong>
                {' '}
                {brand} {model}
              </strong>
              !
            </p>
            <p className="client-message client-message-confirm regular-gray text-center">
              Our buyers are reviewing your submission.
            </p>
            <strong className="margin-35-top">Have another bike to submit?</strong>
            <button type="button" className="submit-btn" onClick={this.resetState}>
              SUBMIT AGAIN
            </button>

            <p
              style={{ marginTop: '50px' }}
              className="client-message client-message-confirm regular-gray text-center"
            >
              If you have any questions or concerns, please reach out to the experts at
              <br />
              <a style={{ color: '#00c160' }} href="mailto: selling@theproscloset.com">
                selling@theproscloset.com
              </a>
            </p>
          </section>
        )}
      </main>
    );
  }
}
