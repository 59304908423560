import styled from 'styled-components';

export const DetailsWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'sku edit'
    'leftcol rightcol';
  gap: ${({ theme }) => theme.spacing(3)}px;
  margin-top: ${({ theme }) => theme.spacing(5)}px;
`;

export const EditButtonWrapper = styled.div`
  grid-area: edit;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SkuDisplay = styled.div`
  font-size: 36px;
  font-family: ${({ theme }) => theme.typography.bold};
  letter-spacing: 1px;
  grid-area: sku;
`;

export const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  grid-area: leftcol;
`;

export const RightColContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const RightColWrapper = styled.div`
  display: grid;
  justify-content: end;
  grid-area: rightcol;
`;

export const DetailLabel = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.grey[600]};
  margin-right: ${({ theme }) => theme.spacing(0.5)}px;
  white-space: nowrap;
`;

export const Detail = styled.div`
  flex-basis: 2rem;
`;

export const WidthConstrainedDetail = styled(Detail)`
  max-width: ${({ theme }) => theme.spacing(70)}px;
`;
