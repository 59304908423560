import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export default function useLocalStorageState<VALUE>(
  lsKey: string,
  defaultValue: VALUE,
): [VALUE, Dispatch<SetStateAction<VALUE>>] {
  const [value, setValue] = useState<VALUE>(() => {
    const lsValue = window.localStorage.getItem(lsKey);
    return lsValue !== null ? (JSON.parse(lsValue) as VALUE) : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(lsKey, JSON.stringify(value));
  }, [lsKey, value]);

  return [value, setValue];
}
