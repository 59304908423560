import React from 'react';
import { Select, MenuItem, ListItemText, SelectProps } from '@material-ui/core';
import { Checkbox } from './Checkbox';

export interface MultiSelectOption {
  id: number;
  name: string;
}

interface CustomMultiSelectProps {
  options: MultiSelectOption[];
  selectedOptions: number[];
}

export type MultiSelectProps = CustomMultiSelectProps & SelectProps;

export const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  selectedOptions,
  ...muiProps
}) => {
  return (
    <Select
      variant="outlined"
      value={selectedOptions}
      renderValue={selected =>
        (selected as any[]).map(id => options.find(o => o.id === id)?.name).join(', ')
      }
      multiple
      // prevent box from jumping around as items are selected
      MenuProps={{ getContentAnchorEl: null }}
      {...muiProps}
    >
      {options.map(({ id, name }) => {
        return (
          <MenuItem key={id} value={id}>
            <Checkbox checked={selectedOptions.includes(id)} />
            <ListItemText primary={name} />
          </MenuItem>
        );
      })}
    </Select>
  );
};
