export function readFileAsync(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    // TS definitions for onload cover all the readAs* methods
    // so we have to narrow to exclude ArrayBuffer
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function getUploadedImageDimensions(imgBlob: string) {
  return new Promise<{ height: number; width: number }>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.naturalWidth, height: img.naturalHeight });
    img.onerror = reject;
    img.src = imgBlob;
  });
}
