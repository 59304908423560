import React, { FC } from 'react';
import styled from 'styled-components';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

const FileDropZone = styled.div`
  height: 300px;
  width: 100%;
  background-color: #ffffff;
  border: 5px dashed #cccccc;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 5rem;

  &:hover {
    background-color: #cccccc;
    border-color: #aaaaaa;
  }
`;

const FileInput = styled.input`
  cursor: inherit; // Firefox doesn't inherit correctly otherwise
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

interface Props {
  handleFilesDrop: (files: File[]) => void;
  required: boolean;
}

const DragDropFileInput: FC<Props> = ({ handleFilesDrop, required = false }) => {
  const handleDrag = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      handleFilesDrop(Array.from(e.target.files));
    }
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    if (e.dataTransfer.files?.length) {
      handleFilesDrop(Array.from(e.dataTransfer.files));
    }
  };

  return (
    <FileDropZone>
      <PhotoLibraryIcon fontSize="inherit" />
      <FileInput
        onDrop={handleDrop}
        onChange={handleChange}
        onDragOver={handleDrag}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        type="file"
        multiple={true}
        accept="image/jpeg"
        required={required}
      />
    </FileDropZone>
  );
};

export default DragDropFileInput;
