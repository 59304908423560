import React, { FC } from 'react';
import styled from 'styled-components';
import ImageIcon from '@material-ui/icons/Image';
import ProductImage from './ProductImage';

const Image = styled(ProductImage)`
  max-height: 3rem;
`;

const ProductImageThumbnail: FC<{ src?: string; identifier?: string }> = ({ src, identifier }) => {
  return !!src ? (
    <Image src={src} alt={`${identifier} product image`} />
  ) : (
    <ImageIcon fontSize="large" />
  );
};

export default ProductImageThumbnail;
