import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { default as MaterialBreadcrumbs } from '@material-ui/core/Breadcrumbs';

export interface BreadcrumbsProps {
  previousPageName: string | ReactNode;
  previousPageHref: string;
  currentPageName: string | ReactNode;
  separator?: string | ReactNode;
}

const useSeparatorClasses = makeStyles(theme => ({
  separator: {
    color: theme.palette.grey[300],
    padding: '0 0.5rem',
  },
}));

const commonStyles = `
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  font-size: 12px;
`;

const StyledLink = styled.a`
  ${commonStyles}
  text-decoration: none;
  color: ${({ theme }) => theme.palette.common.black};

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledCurrentPage = styled.div`
  ${commonStyles}
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  previousPageName,
  previousPageHref,
  currentPageName,
  separator = '|',
}) => {
  const separatorClasses = useSeparatorClasses();
  return (
    <MaterialBreadcrumbs separator={separator} aria-label="breadcrumb" classes={separatorClasses}>
      <StyledLink href={previousPageHref}>{previousPageName}</StyledLink>
      <StyledCurrentPage>{currentPageName}</StyledCurrentPage>
    </MaterialBreadcrumbs>
  );
};
