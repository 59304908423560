import React, { ReactNode } from 'react';
import { default as BaseModal } from '@material-ui/core/Modal';
import { Box, makeStyles, createStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export interface ModalProps {
  open: boolean;
  handleClose: () => void;
  label: string;
  children: ReactNode;
  maxWidth?: string | number;
  margin?: string | number;
  padding?: string | number;
}

export const SmallModalContents = styled.div`
  padding: 50px;
  width: 500px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalHeader = styled.div`
  font-size: 30px;
  padding-bottom: 30px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    baseModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      overflowY: 'auto',
      position: 'relative',
    },
    actionBack: {
      color: theme.palette.primary.main,
    },
  }),
);

export const Modal: React.FC<ModalProps> = ({
  open,
  handleClose,
  label,
  children,
  maxWidth,
  margin,
  padding,
}) => {
  const classes = useStyles();
  return (
    <BaseModal
      className={classes.baseModal}
      open={open}
      onClose={handleClose}
      aria-label={label}
      style={{ padding: padding ?? '32px' }}
    >
      <Box
        className={classes.paper}
        maxWidth={maxWidth}
        maxHeight="100%"
        width={maxWidth !== undefined ? '100%' : 'auto'}
        margin={margin ?? '16px'}
        padding={'0px 40px'}
      >
        {children}
      </Box>
    </BaseModal>
  );
};
