import React, { useState } from 'react';
import { Offer, OfferGroup, SubmissionOfferStatus } from '../../../typeORM';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  makeStyles,
} from '@material-ui/core';
import { VisibilityOutlined } from '@material-ui/icons';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { offerTypesConfig } from '../../../constants';

export const offerTableStyles = makeStyles((theme: Theme) => ({
  table: {
    marginBottom: '2rem',
  },
  th: {
    backgroundColor: theme.palette.grey[300],
  },
  thCell: {
    fontFamily: theme.typography.regular,
    fontWeight: 'bold',
  },
}));

const OfferGroupDialog = (params: GridCellParams) => {
  const [dialogOffers, setDialogOffers] = useState<Offer[] | undefined>();
  const classes = offerTableStyles();
  const handleClose = () => {
    setDialogOffers(undefined);
  };

  const offerGroup: OfferGroup = params.value as OfferGroup;
  if (offerGroup && offerGroup.offers) {
    const offerPaymentPending = offerGroup.offers.find(
      offer => offer.offerStatus === SubmissionOfferStatus.PAYMENT_PENDING,
    );
    const offerPaymentPaid = offerGroup.offers.find(
      offer => offer.offerStatus === SubmissionOfferStatus.PAID,
    );
    return (
      <>
        <Button
          aria-label="open"
          variant="text"
          onClick={() => setDialogOffers(offerGroup.offers)}
          endIcon={<VisibilityOutlined />}
        >
          View Offers ({offerGroup.id})
        </Button>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={!!dialogOffers}
          onClose={handleClose}
          aria-labelledby="offer-details"
        >
          <DialogTitle>Offer Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead className={classes.th}>
                    <TableRow>
                      <TableCell>Offer</TableCell>
                      <TableCell>Amount USD</TableCell>
                      <TableCell>Available To Seller</TableCell>
                      <TableCell>Status</TableCell>
                      {offerPaymentPending && <TableCell>Est. Payment Date</TableCell>}
                      {offerPaymentPaid && <TableCell>Payment Date</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {offerGroup.offers.map(offer => (
                      <>
                        <TableRow key={offer.id}>
                          <TableCell>{`${offerTypesConfig[offer.offerTypeName].label}`}</TableCell>
                          <TableCell>${offer.amountUsd as number}</TableCell>
                          <TableCell>{offer.availableToSeller ? 'Yes' : 'No'}</TableCell>
                          <TableCell>{offer.offerStatus}</TableCell>
                          {(offerPaymentPending || offerPaymentPaid) && (
                            <TableCell>
                              {offerPaymentPaid && offer.paymentDate
                                ? new Date(offer.paymentDate).toLocaleString('en-US', {
                                    timeZone: 'America/Denver',
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                  })
                                : offer.estimatedPaymentDate &&
                                  new Date(offer.estimatedPaymentDate).toLocaleString('en-US', {
                                    timeZone: 'America/Denver',
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                  })}
                            </TableCell>
                          )}
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContentText>
            <DialogActions>
              <Button onClick={handleClose} variant="contained">
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </>
    );
  }
  return;
};

export default OfferGroupDialog;
