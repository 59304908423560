import React from 'react';
import axios from '../../utils/axios';

import { API_URL } from '../../constants';
import './discipline_factors.css';

export default class DisciplineFactors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disciplineId: null,
      classId: null,
      factorArray: Array(17).fill(0),
      months: Array(17)
        .fill()
        .map((x, i) => i + 1),
      classes: [],
      disciplines: [],
    };
  }

  async componentDidMount() {
    const classes = (await axios.get(`${API_URL}/buyers/classes`)).data;
    const disciplines = (await axios.get(`${API_URL}/buyers/disciplines`)).data;

    this.setState({
      classes,
      disciplines,
    });
  }

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value }, async () => {
      if (this.state.classId && this.state.disciplineId) {
        let factors = (
          await axios.get(`${API_URL}/buyers/factors`, {
            params: {
              classId: this.state.classId,
              disciplineId: this.state.disciplineId,
            },
          })
        ).data;
        factors = factors && factors.length ? factors.map(f => f.factor) : Array(17).fill(0);
        this.setState({
          factorArray: factors,
        });
      }
    });
  };

  handleArrayChange = (e, m) => {
    const { value } = e.target;
    const newFactorArray = this.state.factorArray.slice();
    newFactorArray[m] = value ? parseFloat(value) : 0;

    this.setState({
      factorArray: newFactorArray,
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { disciplineId, classId, factorArray } = this.state;
    await axios.post(`${API_URL}/buyers/depreciationTier`, {
      disciplineId,
      classId,
      factorArray,
    });
  };

  render() {
    const { classes, disciplines, months } = this.state;
    return (
      <div>
        <span>
          <label>Class</label>
          <select defaultValue="" name="classId" onChange={this.handleChange}>
            <option value="" disabled>
              Select class
            </option>
            {classes.map(c => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
        </span>
        <span>
          <label>Discipline</label>
          <select defaultValue="" name="disciplineId" onChange={this.handleChange}>
            <option value="" disabled>
              Select discipline
            </option>
            {disciplines.map(d => (
              <option key={d.id} value={d.id}>
                {d.discipline}
              </option>
            ))}
          </select>
        </span>
        <div className="factorVector">
          <h3>Month Factor Vector</h3>
          {months.map(m => (
            <span key={m} className="monthFactor">
              <label>{m + 1}</label>
              <input
                type="number"
                className="factor"
                min="0.00"
                step="0.01"
                value={this.state.factorArray[m - 1]}
                onChange={e => this.handleArrayChange(e, m - 1)}
              />
            </span>
          ))}
        </div>
        <button tabIndex={-1} className="route-button" onClick={this.handleSubmit}>
          <div className="route-wrapper">
            <span className="button-text">Submit</span>
          </div>
        </button>
      </div>
    );
  }
}
