import styled from 'styled-components';
import { colors } from './variables';
const PageHeader = styled.h1`
  text-transform: capitalize;
`;
const SectionHeader = styled.h2`
  border-bottom: ${colors.accent} 0.1em solid;
`;

const HeaderWrapper = styled(PageHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export { PageHeader, SectionHeader, HeaderWrapper };
