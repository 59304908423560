import React from 'react';
import { ErrorMessage } from '../../components/library';
import { Box } from '@material-ui/core';
import { UpcErrorProperties } from './utils/index';

const DuplicateUpcErrorMessage = React.forwardRef<HTMLDivElement, UpcErrorProperties>(
  ({ msg, upc, productId, sku }, ref) => {
    return (
      <ErrorMessage error={msg} ref={ref}>
        <Box>
          {msg}
          {upc && (
            <span>
              {' '}
              - View product{' '}
              <a
                id={`${sku}`}
                target={'_blank'}
                rel="noopener noreferrer"
                href={`/catalog/products/${productId}`}
              >
                {productId}
              </a>
            </span>
          )}
        </Box>
      </ErrorMessage>
    );
  },
);

export default DuplicateUpcErrorMessage;
