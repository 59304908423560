import React from 'react';
import FlagIcon from '@material-ui/icons/Flag';

const flagColorMap: { [key: number]: string } = {
  1: '#A70000',
  2: '#C79745',
  3: '#008300',
  4: '#1223FF',
};

const ProductVariantStatusFlag: React.FC<{ value: number | null }> = ({ value }) => {
  return <FlagIcon style={{ color: value ? flagColorMap[value] ?? '' : '' }} />;
};

export default ProductVariantStatusFlag;
