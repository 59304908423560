import React, { useState } from 'react';
import styles from '../../styledComponents';
import { Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { handleApiError } from './utils';
import { ErrorMessage } from '../../components/library';
import { createShopifyFullExport } from '../../services/catalog/services';
import { Snackbar, useSnackbar } from '../../components/library/Snackbar';

const { PageWrapper } = styles;

const ShopifyCreateProduct: React.FC = () => {
  const { productId } = useParams<{ productId?: string }>();
  const [error, setError] = useState<string | string[] | undefined>();
  const { snackbarOpen, handleSnackbarOpen, handleSnackbarClose } = useSnackbar();

  const onClick = async () => {
    try {
      await createShopifyFullExport(Number(productId));
      handleSnackbarOpen();
    } catch (err) {
      handleApiError(err, setError);
    }
  };

  return (
    <PageWrapper>
      <ErrorMessage error={error} />
      <Snackbar open={snackbarOpen} handleClose={handleSnackbarClose} />
      <h2>Create Product in Shopify</h2>
      <Button variant="contained" onClick={onClick}>
        Create Product {productId}
      </Button>
    </PageWrapper>
  );
};

export default ShopifyCreateProduct;
