import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import { Ul } from '../catalog/ReadyForShopifyPublish';

interface Props {
  error?: string | string[];
  style?: React.CSSProperties;
  children?: React.ReactNode;
  ref?: React.RefObject<HTMLDivElement>;
}

export const ErrorMessage = React.forwardRef<HTMLDivElement, Props>(
  ({ error, style = undefined, children }, ref) => {
    if (!error) {
      return null;
    }
    return (
      <Alert ref={ref} severity="error" style={style}>
        <AlertTitle>Error</AlertTitle>
        {Array.isArray(error) ? (
          <Ul>
            {error.map(e => (
              <li key={e}>{e}</li>
            ))}
          </Ul>
        ) : children ? (
          <Box>{children}</Box>
        ) : (
          <Box style={{ wordBreak: 'break-word' }}>{error}</Box>
        )}
      </Alert>
    );
  },
);
