import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { Checkbox } from '../../../../../../components/library';
import { FEInspectionPoint, InspectionPart } from '../../../../../../services/service/types';
import { CircledXIcon } from '../../../CircledXIcon';
import UndoIcon from '@material-ui/icons/Replay';
import { FailedPointOption, FailRemedies, FormikServiceState } from '../../../_shared';

interface EditableServiceTableProps {
  failedPointOptions: FailedPointOption[];
  pointTypeCategoryLookup: { [key: number]: string };
  partsByPointId: { [key: number]: InspectionPart[] };
  filteredInspectionPoints: FEInspectionPoint[];
  values: FormikServiceState;
  setFieldValue: (field: string, value: any) => void;
  clickablePartClass: string;
  classes: { [key: string]: string };
}

export const EditableServiceTableRows: React.FC<EditableServiceTableProps> = ({
  failedPointOptions,
  pointTypeCategoryLookup,
  partsByPointId,
  filteredInspectionPoints,
  values,
  setFieldValue,
  clickablePartClass,
  classes,
}: EditableServiceTableProps) => {
  return (
    <>
      {filteredInspectionPoints.map(point => {
        const pointType = failedPointOptions.find(
          option => option.value === point.inspectionPointTypeId,
        );
        const partsForPoint = partsByPointId[point.inspectionPointTypeId];

        // TP-3741: allow users to complete service points even if they should have a part
        // (but continue to display the message that it's missing parts)
        const pointIsMissingParts =
          point.failRemedyId === FailRemedies.replace && !partsForPoint?.length;

        const isPointComplete = values.points[point.inspectionPointTypeId];

        return (
          <TableRow key={point.inspectionPointTypeId} className={classes.row}>
            {/* Complete Status (Done) */}
            <TableCell
              className={`${classes.clickable} ${classes.centeredCell}`}
              scope="row"
              onClick={() =>
                setFieldValue(`points[${point.inspectionPointTypeId}]`, !isPointComplete)
              }
            >
              <Checkbox
                checked={isPointComplete}
                onChange={e =>
                  setFieldValue(`points[${point.inspectionPointTypeId}]`, e.target.checked)
                }
              />
            </TableCell>

            {/* Inspection Category */}
            <TableCell className={classes.leftAlignedCell} scope="row">
              {pointTypeCategoryLookup[point.inspectionPointTypeId]}
            </TableCell>

            {/* Inspection Point */}
            <TableCell className={classes.leftAlignedCell} scope="row">
              {pointType?.label ?? 'shrug'}
            </TableCell>

            {/* Point Remedy */}
            <TableCell className={classes.leftAlignedCell} scope="row">
              {!!point.failRemedyId ? FailRemedies[point.failRemedyId] : 'shrug'}
            </TableCell>

            {/* Notes */}
            <TableCell className={classes.leftAlignedCell} scope="row">
              {point.note}
            </TableCell>

            {/* Parts */}
            <TableCell className={classes.partsCell} scope="row">
              {pointIsMissingParts ? (
                <span className={classes.missingPartText}>Missing replacement part</span>
              ) : (
                partsForPoint?.map(part => {
                  const isPartSlatedForDeletion = values.deletedPartIds.includes(part.id);
                  return (
                    <div
                      key={part.id}
                      className={clickablePartClass}
                      onClick={() => {
                        const oldParts = [...values.deletedPartIds];
                        if (isPartSlatedForDeletion) {
                          // remove it from the delete list
                          setFieldValue(
                            'deletedPartIds',
                            oldParts.filter(id => id !== part.id),
                          );
                        } else {
                          // add it to the delete list
                          oldParts.push(part.id);
                          setFieldValue('deletedPartIds', oldParts);
                        }
                      }}
                    >
                      {isPointComplete ? null : (
                        <>
                          {isPartSlatedForDeletion ? (
                            <UndoIcon className={classes.undoRemoveButton} />
                          ) : (
                            <CircledXIcon className={classes.removeButton} />
                          )}
                          &ensp;
                        </>
                      )}
                      <span
                        className={
                          isPartSlatedForDeletion ? classes.partSlatedForDeletion : undefined
                        }
                      >
                        {part.identifier}
                        {part.quantity > 1 ? ` (x${part.quantity})` : null}
                      </span>
                    </div>
                  );
                })
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
