import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { pick } from 'lodash';
import { updateProductImages } from '../../../actions/product';
import DragAndDropImages from './DragAndDropImages';
import { FullProductImage } from './ProductImagesView';

const NoImagesMsg = styled.p`
  margin: 1rem 0 2rem;
`;

const EditProductImages: React.FC<{
  productImages: FullProductImage[];
  editingVariant: boolean;
  shopifyStatus: string;
}> = ({ productImages, editingVariant, shopifyStatus }) => {
  const [images, setImages] = useState<FullProductImage[]>(productImages);
  const [dirty, setDirty] = useState(false);
  const [deletedImages, setDeletedImages] = useState<FullProductImage[]>([]);
  const { productId }: { productId: string } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    setImages(productImages);
    setDirty(false);
  }, [productImages]);

  const handleSetImages = (images: FullProductImage[]) => {
    setImages(images);
    setDirty(true);
  };

  const handleSave = async () => {
    const allImages = [
      ...images.map(img => pick(img, ['id', 'imageUrl', 'productVariantId'])),
      ...deletedImages.map(img => ({
        ...pick(img, ['id', 'imageUrl', 'productVariantId']),
        active: false,
      })),
    ];
    const imageKey = editingVariant ? 'variantImages' : 'productImages';
    dispatch(
      updateProductImages({
        productId,
        [imageKey]: allImages,
        onSuccess: () => setDeletedImages([]),
      }),
    );
  };

  return (
    <div>
      {images.length > 0 ? (
        <DragAndDropImages
          images={images}
          handleChange={handleSetImages}
          handleDelete={setDeletedImages}
          shopifyStatus={shopifyStatus}
        />
      ) : (
        <NoImagesMsg>
          {deletedImages.length > 0
            ? 'No images to display. Be sure to save your changes to delete images permanently.'
            : `No images have been added to this ${editingVariant ? 'variant' : 'product'}.`}
        </NoImagesMsg>
      )}
      {(dirty || deletedImages.length > 0) && (
        <Button onClick={handleSave} variant="outlined">
          SAVE
        </Button>
      )}
    </div>
  );
};

export default EditProductImages;
