import React from 'react';
import axios from '../../utils/axios';
import { API_URL } from '../../constants';
import DisciplineModal from './disciplineModal';
import { StripedDataGrid } from '../../styledComponents/datagrid';
import { PageWrapper } from '../../styledComponents/wrappers';

export default class Disciplines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalDisciplineToggled: false,
      disciplineError: false,
      disciplineErrorMsg: '',
      disciplineLoading: false,
      discipline: '',
      tag: '',
      disciplineRecords: [],
      paginationModel: {
        pageSize: 15,
        page: 0,
      },
    };

    this.columns = [
      {
        headerName: 'ID',
        field: 'id',
        flex: 1,
      },
      {
        headerName: 'Discipline',
        field: 'discipline',
        flex: 2,
      },
      {
        headerName: 'Tag',
        field: 'tag_name',
        flex: 2,
      },
    ];
  }

  async componentDidMount() {
    const records = (await axios.get(`${API_URL}/buyers/disciplines`)).data;
    this.setState({ disciplineRecords: records });
  }

  onChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  clearState = () => {
    this.setState({
      discipline: '',
      tag: '',
    });
  };

  onSubmitDiscipline = async evt => {
    evt.preventDefault();
    this.setState({
      disciplineError: false,
      disciplineErrorMsg: '',
      disciplineLoading: true,
    });

    try {
      const { discipline, tag } = this.state;

      await axios.post(`${API_URL}/buyers/disciplines`, {
        discipline,
        tag,
      });
      this.toggleDisciplineModal();
      this.clearState();
      const records = (await axios.get(`${API_URL}/buyers/disciplines`)).data;
      this.setState({ disciplineRecords: records });
    } catch (e) {
      console.error(e);
      if (e.response && e.response.status === 403) {
        this.setState({ disciplineErrorMsg: e.response.data });
      }
      this.setState({ disciplineError: true });
    }
    this.setState({ disciplineLoading: false });
  };

  toggleDisciplineModal = e => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      modalDisciplineToggled: !this.state.modalDisciplineToggled,
    });
  };

  render() {
    const {
      modalDisciplineToggled,
      disciplineError,
      disciplineErrorMsg,
      disciplineLoading,
      discipline,
      tag,
      disciplineRecords,
    } = this.state;
    return (
      <PageWrapper style={{ overflow: 'hidden' }}>
        <h1 style={{ textAlign: 'center' }}>DISCIPLINES</h1>
        <div className="react-table">
          <span className="list-title-nav-wrapper">
            <button tabIndex={-1} className="route-button" onClick={this.toggleDisciplineModal}>
              <div className="route-wrapper">
                <span className="button-text">ADD NEW</span>
              </div>
            </button>
          </span>
          <StripedDataGrid
            columns={this.columns}
            rows={disciplineRecords}
            noDataText="Nothing here..."
            paginationModel={this.state.paginationModel}
            onPaginationModelChange={m => this.setState({ paginationModel: m })}
            pageSizeOptions={[15, 30, 60]}
          />
        </div>
        {modalDisciplineToggled && (
          <DisciplineModal
            toggleModal={this.toggleDisciplineModal}
            onSubmit={this.onSubmitDiscipline}
            onChange={this.onChange}
            loading={disciplineLoading}
            error={disciplineError}
            errorMsg={disciplineErrorMsg}
            discipline={discipline}
            tag={tag}
          />
        )}
      </PageWrapper>
    );
  }
}
