import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/home.css';
import tradeupLogo from '../images/trade-up.svg';
import adminupLogo from '../images/adminup.png';
import { TRADEUP_SEARCH_ROUTE } from './tradeups/SubmissionListView';

const BuyersHome = () => (
  <div>
    <div className="home-wrap">
      <Link className="home-link" to="/buyers/autoPO">
        <section className="section-wrap">
          <svg
            width="116"
            height="116"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-activity"
          >
            <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" />
          </svg>
          <h4>AUTO PO</h4>
          <span>Automatic Purchase Orders</span>
        </section>
      </Link>
      <Link className="home-link" to={TRADEUP_SEARCH_ROUTE}>
        <section className="section-wrap">
          <img src={tradeupLogo} alt="logo" />
          <h4>TRADE-UPS</h4>
          <span>One stop shop for all your tradeups</span>
        </section>
      </Link>
      <Link className="home-link" to="/buyers/tradeups/admin">
        <section className="section-wrap">
          <img src={adminupLogo} alt="logo" />
          <h4>TRADE-UPS ADMIN</h4>
          <span>Trade Ups admin controls</span>
        </section>
      </Link>
    </div>
  </div>
);

export default BuyersHome;
