import React from 'react';
import { Grades } from '../../_shared/types';
import PassIcon from '@material-ui/icons/CheckCircle';
import DefaultIcon from '@material-ui/icons/IndeterminateCheckBox';
import NaIcon from '@material-ui/icons/Remove';
import { CircledXIcon } from '../../CircledXIcon';
import { makeStyles } from '@material-ui/core';

interface InspectionPointStatusIconProps {
  gradeId: Grades | null;
}

const useStyles = makeStyles(theme => ({
  pass: {
    color: theme.palette.success.vibrant,
  },
  fail: {
    color: theme.palette.error.vibrant,
  },
  na: {
    color: theme.palette.grey[500],
  },
  default: {
    color: theme.palette.grey[500],
  },
}));

export const InspectionPointStatusIcon: React.FC<InspectionPointStatusIconProps> = ({
  gradeId,
}) => {
  const classes = useStyles();
  switch (gradeId) {
    case Grades.pass:
      return <PassIcon classes={{ root: classes.pass }} />;
    case Grades.fail:
      return <CircledXIcon classes={{ root: classes.fail }} />;
    case Grades.na:
      return <NaIcon classes={{ root: classes.na }} />;
    default:
      return <DefaultIcon classes={{ root: classes.default }} />;
  }
};
