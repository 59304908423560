import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from '../../utils/axios';
import { API_URL } from '../../constants';
import { FormatDate } from '../../utils/helper';
import './customer360.css';
import trash from '../../images/trash.svg';

const rowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  height: '45px',
  alignItems: 'center',
  boxSizing: 'border-box',
};

const buildStyle = state => {
  return {
    border: `5px solid ${state ? '#00c160' : 'red'}`,
    margin: 0,
    padding: 0,
    borderRadius: '50%',
    height: 0,
    width: 0,
    display: 'inline-block',
  };
};

class C360DetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: 0,
      customer: {},
      savedSearches: [],
      collections: [],
      savedSearchLink: '',
      addError: false,
      addSuccess: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ customerId: id });
    this.fetchCustomer(id);
    this.fetchSavedSearches(id);
    this.fetchCollections();
  }

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  fetchCustomer = async id => {
    const url = `${API_URL}/customers/getCustomerDetail/${id}`;
    try {
      const response = await axios.get(url);
      this.setState({ customer: response.data[0] });
    } catch (e) {
      console.log(e);
    }
  };

  fetchCollections = async () => {
    const url = `${API_URL}/customers/getCollections`;
    try {
      const response = await axios.get(url);
      this.setState({ collections: response.data });
    } catch (e) {
      console.log(e);
    }
  };

  fetchSavedSearches = async id => {
    const url = `${API_URL}/shopify/savedSearches/${id}?loadInactive=true`;
    try {
      const response = await axios.get(url);
      this.setState({ savedSearches: response.data });
    } catch (e) {
      console.log(e);
    }
  };
  // #region add savedSearches

  submitNewSavedSearch = async e => {
    e.preventDefault();
    const { savedSearchLink } = this.state;
    const { refinements, minPrice, maxPrice } = this.getParamsFromURL(savedSearchLink);
    const collectionId = this.getCollectionIdFromURL(savedSearchLink);

    const data = {
      id: parseInt(this.state.customerId, 10),
      url: savedSearchLink,
      collectionId,
      refinements,
      minPrice,
      maxPrice,
      internal: true,
    };

    try {
      const url = `${API_URL}/shopify/savedSearches`;
      const response = await axios.post(url, data);
      if (response.status < 300) {
        this.fetchSavedSearches(this.state.customerId);
        this.setState({ savedSearchLink: '' });
      } else {
        this.setState({
          addError: true,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ loading: false });
    }
  };

  getParamsFromURL(url) {
    if (url.indexOf('?') < 0) {
      return null;
    }
    const parsedUrl = new URL(url);

    let minPrice = undefined;
    let maxPrice = undefined;
    const refinements = {};
    for (const [key, value] of parsedUrl.searchParams.entries()) {
      if (key === 'minPrice') {
        minPrice = parseFloat(value);
      } else if (key === 'maxPrice') {
        maxPrice = parseFloat(value);
      } else if (value.includes(',')) {
        refinements[key] = value.split(',');
      } else {
        refinements[key] = value;
      }
    }

    return {
      refinements,
      minPrice,
      maxPrice,
    };
  }

  getCollectionIdFromURL(url) {
    const collection = url.split('collections/')[1];
    const collectionName = collection.split('?')[0];
    return this.state.collections.find(v => v.handle === collectionName).id;
  }

  // #endregion

  deleteSavedSearch = async savedSearch => {
    const url = `${API_URL}/shopify/savedSearches/${savedSearch.id}`;
    try {
      const response = await axios.delete(url);
      if (response.status < 300) {
        this.fetchSavedSearches(this.state.customerId);
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { customer, savedSearchLink, savedSearches } = this.state;
    return (
      <main className="three-sixty-detail-wrap">
        <ul className="react-table">
          {Object.entries(customer).map(v => (
            <li style={{ marginBottom: '5px' }} key={v[0]}>
              <label className="label">{v[0]}:</label>
              {v[1]}
            </li>
          ))}
        </ul>
        <h3 className="text-center">Saved Search (product alert) List</h3>
        <form className="react-table flex-space-between" onSubmit={this.submitNewSavedSearch}>
          <input
            placeholder="PLP link e.g. https://www.theproscloset.com/collections/seatposts?seatpostDropperTravel=N%2FA&seatpostDiameter=27.2mm"
            type="text"
            name="savedSearchLink"
            className="text-input full-width"
            onChange={this.handleChange}
            value={savedSearchLink}
          />
          <input
            type="submit"
            value="Add Alert"
            className="submit-button"
            disabled={!(this.state.savedSearchLink.length > 10) || this.state.loading}
          />
        </form>
        <table className="react-table basic-table">
          <tbody>
            <tr>
              <th>Title</th>
              <th>Created Date</th>
            </tr>
            {savedSearches.map(v => (
              <tr key={v.createdAt}>
                <td style={rowStyle}>
                  <a href={v.url} target="_blank" rel="noopener noreferrer">
                    <span style={buildStyle(v.active)} /> {v.title}
                  </a>
                  {v.active === 1 && (
                    <button
                      style={{ border: 'none', cursor: 'pointer' }}
                      onClick={() => this.deleteSavedSearch(v)}
                    >
                      <img style={{ height: '30px', margin: 0 }} src={trash} />
                    </button>
                  )}
                </td>
                <td>
                  {' '}
                  <span className="small-gray-text">{v.internal ? 'in ' : 'ex '}</span>
                  {FormatDate(v.createdAt)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    );
  }
}

export default withRouter(C360DetailView);
