import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditProductVariantForm, { EditProductVariantProps } from './EditProductVariantForm';
import VariantTitle from '../VariantTitle';

const EditProductVariant: React.FC<EditProductVariantProps & {
  expanded?: boolean;
  onAccordionClick: (sku: string, isExpanded: boolean) => void;
}> = ({
  product,
  productVariant,
  expanded,
  refreshProductData,
  onAccordionClick,
  copyAndSaveFieldValueToAllVariants,
}) => {
  const { id, sku, statusFlagId } = productVariant;

  return (
    <Accordion onChange={(_e, isExpanded) => onAccordionClick(sku, isExpanded)} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
      >
        <VariantTitle
          sku={sku}
          statusFlagId={statusFlagId}
          imageUrl={productVariant.images?.[0]?.imageUrl}
          product={product}
          productVariant={productVariant}
        />
      </AccordionSummary>
      {expanded && (
        <AccordionDetails>
          <EditProductVariantForm
            product={product}
            productVariant={productVariant}
            refreshProductData={refreshProductData}
            copyAndSaveFieldValueToAllVariants={copyAndSaveFieldValueToAllVariants}
          />
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default EditProductVariant;
