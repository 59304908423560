import React, { FC, useState } from 'react';
import {
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import * as service from '../../../services/catalog/services';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Loader from '../../../components/loader';
import CircleSuccess from '../../../components/icons/circleSuccess';
import { handleApiError } from '../utils';

import { Product } from '../../../services/catalog/types';

const ALTERNATE_TYPES = [
  { type: 'A', value: 'A' },
  { type: 'B', value: 'B' },
  { type: 'C', value: 'C' },
  { type: 'Q', value: 'Q' },
];

const SuccessLink = styled(RouterLink)`
  text-decoration: underline;
`;
const PPad = styled.p`
  padding: 1em;
`;

const TextFieldCustom = withStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'white',
    },
  },
})(TextField);

const FormControlCustom = withStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'white',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white',
    },
  },
})(FormControl);

const CreateProductAlternate: FC = () => {
  const [sku, setSku] = useState('');
  const [alternate, setAlternate] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [errorMsgCreate, setErrorMsgCreate] = useState<string | string[] | undefined>('');
  const [loading, setLoading] = useState(false);
  const [confirmDialogSku, setConfirmDialogSku] = useState('');
  const [createdProduct, setCreatedProduct] = useState<Product | undefined>();

  const handleSkuChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSku(e.target.value);
  };

  const handleTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setAlternate(e.target.value as string);
  };

  const handleClose = () => {
    setConfirmDialogSku('');
  };

  const onDialogConfirm = async () => {
    setErrorMsgCreate('');
    setLoading(true);
    try {
      const response = await service.createProductAlternate(sku, alternate);
      if (response?.data) {
        setCreatedProduct(response.data);
      }
    } catch (err) {
      handleApiError(err, setErrorMsgCreate);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMsg('');
    setErrorMsgCreate('');
    setLoading(true);
    try {
      const response = await service.checkProductAlternateCreationEligibility(sku, alternate);
      if (response?.data.eligible) {
        setConfirmDialogSku(response.data.newSku);
      } else if (response?.data.error) {
        setErrorMsg(response.data.error);
      } else {
        // should not get here
        setErrorMsg('Error attempting to validate alternate creation');
      }
    } catch (err) {
      setErrorMsg('Error attempting to validate alternate creation');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} style={{ width: 600, border: '2px solid black', margin: 10 }}>
        <ErrorBoundary>
          <h4 style={{ textAlign: 'center' }}>Create Product Alternate</h4>
          <div
            style={{
              display: 'flex',
              padding: 20,
              paddingBottom: 0,
              justifyContent: 'space-between',
            }}
          >
            <TextFieldCustom
              variant="filled"
              id="sku"
              label="SKU"
              placeholder="e.g. AHW47010405"
              value={sku}
              onChange={handleSkuChange}
              style={{ width: 275 }}
            />
            <FormControlCustom variant="filled" style={{ width: 275 }}>
              <InputLabel id="type-label">Alternate To Create</InputLabel>
              <Select labelId="type-label" id="type" value={alternate} onChange={handleTypeChange}>
                {ALTERNATE_TYPES.map(t => (
                  <MenuItem key={t.value} value={t.value}>
                    {t.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControlCustom>
          </div>
          <div
            style={{
              display: 'flex',
              padding: 20,
              justifyContent: 'space-between',
            }}
          >
            <Button type="submit" variant="contained" disabled={!sku || !alternate}>
              SUBMIT
            </Button>
          </div>
          {errorMsg && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMsg}
            </Alert>
          )}
          <Loader loading={loading}></Loader>
        </ErrorBoundary>
      </form>
      <Dialog open={!!confirmDialogSku} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Confirm the product alternate you would like to create
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6">New SKU to be created:</Typography>
          <Typography variant="h5">
            <b>{confirmDialogSku}</b>
          </Typography>
          <br />
          <br />
          <Typography variant="h6">Copied from SKU:</Typography>
          <Typography variant="h5">
            <b>{sku}</b>
          </Typography>
          {errorMsgCreate && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMsgCreate}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onDialogConfirm} color="primary">
            Create Alternate
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!createdProduct}>
        <DialogTitle>
          <CircleSuccess /> Success!
        </DialogTitle>
        <DialogContent>
          {!!createdProduct && (
            <DialogContentText id="alert-dialog-description">
              <p>
                <em>{`"${createdProduct.title}" `}</em>has been created.
              </p>
              {createdProduct && <PPad>Product alternate created.</PPad>}
              <PPad>
                View Product:{' '}
                <div key={createdProduct.id}>
                  <SuccessLink to={`/catalog/products/${createdProduct.id}`}>
                    Product ID: {createdProduct.id} - SKU: {createdProduct.productVariants[0].sku}
                  </SuccessLink>
                </div>
              </PPad>
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {createdProduct && (
            <Button
              to={`/catalog/products/${createdProduct.id}`}
              variant="contained"
              component={RouterLink}
            >
              Done
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateProductAlternate;
