import React, { FC } from 'react';
import { FormControl } from '@material-ui/core';
import styled from 'styled-components';
import { useField } from 'formik';
import { sharedInputStyles } from './FormikTextInput';
import DisplayField from './DisplayField';
import { CopyAndSaveFieldValueToAllVariants } from '../../services/catalog/types';

export type InputProps = {
  label: string;
  getLinkUrl?: (id: number | string) => string;
  placeholder?: string;
  copyAndSaveFieldValueToAllVariants?: CopyAndSaveFieldValueToAllVariants;
};

export const FieldWrapper = styled(FormControl)`
  &.MuiFormControl-root {
    ${sharedInputStyles}
  }
`;

const FormikDisplayField: FC<InputProps & { name: string }> = ({
  name,
  label,
  getLinkUrl,
  placeholder = '',
}) => {
  const [field] = useField(name);

  const url = getLinkUrl ? getLinkUrl(field.value) : undefined;

  return (
    <FieldWrapper>
      <DisplayField label={label} value={field.value || placeholder} url={url} />
    </FieldWrapper>
  );
};
export default FormikDisplayField;
