import React, { FC, useState, useMemo } from 'react';
import AutoCompleteField from '../../../components/catalog/AutoCompleteField';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import TextInput from '../../../components/catalog/TextInput';
import { useSelector } from 'react-redux';
import * as service from '../../../services/catalog/services';
import { Submission } from '../../../services/catalog/types';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Loader from '../../../components/loader';
import { Alert, AlertTitle } from '@material-ui/lab';
import { validateSubmissionSource, INVALID_SUB_SOURCE } from '../utils';

export type OnSubmitSuccess = ({
  submission,
  createPoValue,
  categoryId,
}: {
  submission?: Submission;
  createPoValue?: boolean;
  categoryId?: number;
}) => void;
interface AddProductSearchProps {
  onSubmitSuccess: OnSubmitSuccess;
}

type InitialState = {
  categoryId?: number | null;
  submissionId: string;
  createPoValue: boolean;
  loading: boolean;
  errorMsg: string;
};

const AddProductSearch: FC<AddProductSearchProps> = ({ onSubmitSuccess }) => {
  const productStore = useSelector((state: any) => state.product);

  const categories = useMemo(() => productStore.categories ?? [], [productStore]);

  const [{ categoryId, submissionId, createPoValue, loading, errorMsg }, setState] = useState<
    InitialState
  >({
    submissionId: '',
    createPoValue: false,
    loading: false,
    errorMsg: '',
  });

  const handleCategoryChange = (
    _e: React.ChangeEvent<HTMLInputElement>,
    value: { id: number } | null,
  ) => {
    setState(prevState => ({ ...prevState, categoryId: value ? value.id : value, errorMsg: '' }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, id } = e.target;
    setState(prevState => ({
      ...prevState,
      [name || id]: value,
      errorMsg: '',
    }));
  };

  const handleCreatePoValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setState(prevState => ({
      ...prevState,
      createPoValue: checked,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (submissionId) {
        const [source, id] = validateSubmissionSource(submissionId);
        if (source === INVALID_SUB_SOURCE) {
          setState(prevState => ({
            ...prevState,
            errorMsg: 'Please enter a submission that starts with S or P',
          }));
          return;
        }
        setState(prevState => ({ ...prevState, loading: true }));
        await service.fetchSubmission(id, source, {
          onSuccess: (result: Submission[]) => {
            if (!result?.[0]?.eligible) {
              setState(prevState => ({
                ...prevState,
                errorMsg: `This submission is not eligible to add products - ${result?.[0]
                  ?.ineligibilityReason ?? 'unknown reason'}`,
              }));
              return;
            }
            onSubmitSuccess({ submission: result[0], createPoValue });
          },
          catchFailure: () => {
            setState(prevState => ({
              ...prevState,
              errorMsg: `Could not retrieve submission with id ${submissionId}`,
            }));
          },
        });
        setState(prevState => ({ ...prevState, loading: false }));
      } else if (categoryId) {
        onSubmitSuccess({ categoryId });
      }
    } catch (e) {
      console.error(e);
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: 600, border: '2px solid black', margin: 10 }}>
      <ErrorBoundary>
        <h4 style={{ textAlign: 'center' }}>Add New Product</h4>
        <div
          style={{
            display: 'flex',
            padding: 20,
            paddingBottom: 0,
            justifyContent: 'space-between',
          }}
        >
          <TextInput
            id="submissionId"
            label="Submission"
            placeholder="e.g. s12345, p1234"
            disabled={!!categoryId}
            onChange={handleChange}
          />
          <AutoCompleteField
            name="category"
            options={categories}
            label="Category"
            labelKey="category"
            placeholder="Select a Category"
            handleChange={handleCategoryChange}
            disabled={!!submissionId}
          />
        </div>
        <div
          style={{
            display: 'flex',
            paddingLeft: 25,
            justifyContent: 'space-between',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleCreatePoValueChange}
                disabled={!submissionId}
                checked={createPoValue}
                color="primary"
              />
            }
            label="Create Purchase Order"
          />
        </div>
        <div
          style={{
            display: 'flex',
            padding: 20,
            justifyContent: 'space-between',
          }}
        >
          <Button type="submit" variant="contained" disabled={!submissionId && !categoryId}>
            SUBMIT
          </Button>
        </div>
        {errorMsg && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMsg}
          </Alert>
        )}
        <Loader loading={loading}></Loader>
      </ErrorBoundary>
    </form>
  );
};

export default AddProductSearch;
