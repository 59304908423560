import { TextInputProps } from '../../../../components/catalog/FormikTextInput';
import { CatalogProductState } from '../../../../reducers/product-reducer';
import {
  AttributeType,
  CopyAndSaveFieldValueToAllVariants,
} from '../../../../services/catalog/types';
import {
  CatalogFieldConfig,
  FormikFieldConfiguration,
  FormikFieldGroup,
  GroupedFormikFieldListConfiguration,
} from '../formik/types';
import { CatalogFieldCategoryConfig } from './types';
import { autoCompleteAdditionalConfiguration } from '../fieldConfigurationData';
import { Dispatch } from 'redux';

const valueKeyForConfig = (catalogFieldConfig: CatalogFieldConfig): string => {
  return (
    catalogFieldConfig._productPropertyName ??
    catalogFieldConfig.productVariantPropertyName ??
    `productVariantAttributesByTypeId[${catalogFieldConfig.attributeTypeId}].value`
  );
};

const buildAutocompleteFormikConfig = (
  catalogFieldConfig: CatalogFieldConfig,
  categoryAutocompleteOptions: string[] | undefined | null,
  categoryId: number,
  productStore: CatalogProductState,
  disabled: boolean,
  highlight: boolean,
  copyAndSaveFieldValueToAllVariants?: CopyAndSaveFieldValueToAllVariants,
  dispatch?: Dispatch, //needed for editable autocomplete fields that need to dispatch results (so that added values are usable immediately)
): FormikFieldConfiguration & { componentType: 'autocomplete' | 'editableAutocomplete' } => {
  /* 
    3 potential places for autocomplete options to come from, in priority order
    1. 'additional' configuration - this is for when the options are defined in code, productStore, etc
    2. fieldCategoryConfig - this is when we have data driven options specific to the product category
    3. fieldConfigData - this is when the options are defined in the base field config data
  */
  const valueKey = valueKeyForConfig(catalogFieldConfig);
  const additionalConfig = autoCompleteAdditionalConfiguration[valueKey];
  if (additionalConfig) {
    const { labelKey } = additionalConfig;

    const combinedConfig: FormikFieldConfiguration & {
      componentType: 'autocomplete' | 'editableAutocomplete';
    } = {
      ...catalogFieldConfig,
      valueKey,
      group: catalogFieldConfig.catalogFieldConfigGroup,
      componentType: 'autocomplete',
      labelKey,
      options:
        additionalConfig.options ?? additionalConfig.optionsFromStore(productStore, categoryId),
      OptionComponent: additionalConfig.OptionComponent,
      AdornmentComponent: additionalConfig.AdornmentComponent,
      disabled,
      highlight,
      copyAndSaveFieldValueToAllVariants,
      placeholder: catalogFieldConfig.placeholder ?? undefined,
      SubComponent: additionalConfig.SubComponent,
      multiple: additionalConfig.multiple,
      filterSelectedOptions: additionalConfig.filterSelectedOptions,
    };
    if (
      catalogFieldConfig.componentType == 'editableAutocomplete' &&
      dispatch &&
      additionalConfig.editability
    ) {
      return {
        ...combinedConfig,
        componentType: 'editableAutocomplete',
        ...additionalConfig.editability(dispatch),
      };
    } else {
      return combinedConfig;
    }
  } else {
    const options: string[] =
      categoryAutocompleteOptions ?? catalogFieldConfig.autocompleteOptions ?? [];
    return {
      ...catalogFieldConfig,
      valueKey,
      group: catalogFieldConfig.catalogFieldConfigGroup,
      componentType: 'autocomplete',
      labelKey: 'name',
      options: options.map((value: string) => ({
        id: value,
        name: value,
      })),
      disabled,
      highlight,
      copyAndSaveFieldValueToAllVariants,
      placeholder: catalogFieldConfig.placeholder ?? undefined,
    };
  }
};

export const buildFormikFieldConfiguration = (
  mergedConfig: {
    fieldBaseConfig: CatalogFieldConfig;
    fieldCategoryConfig: CatalogFieldCategoryConfig | undefined;
  },
  productStore: CatalogProductState,
  categoryId: number,
  formStateOptions?: {
    userHasPriceChangePermissions?: boolean;
    varyingAttributeTypes?: AttributeType[];
    copyAndSaveFieldValueToAllVariants?: CopyAndSaveFieldValueToAllVariants;
    dispatch?: Dispatch;
  },
): FormikFieldConfiguration => {
  const { fieldBaseConfig, fieldCategoryConfig } = mergedConfig;
  const {
    userHasPriceChangePermissions,
    varyingAttributeTypes,
    copyAndSaveFieldValueToAllVariants,
    dispatch,
  } = formStateOptions ?? {};

  const userLacksPermission =
    !!fieldBaseConfig.updateRequiresPermissionId && !userHasPriceChangePermissions;

  //relevant for edit-pv only
  const highlight = !!(varyingAttributeTypes ?? []).find(
    attributeType =>
      fieldBaseConfig.attributeTypeId == attributeType.id ||
      fieldBaseConfig.productVariantPropertyName == attributeType.name, //necessary?
  );

  const copyAndSaveIfAllowed = fieldCategoryConfig?.allowCopyAndSave
    ? copyAndSaveFieldValueToAllVariants
    : undefined;

  const disabled = fieldBaseConfig.disabled || userLacksPermission;

  //destructuring here rather than using the spread operator when building config objects
  //below because typescript isn't handling the type union well.
  const { displayOrder, label, placeholder, fullWidth, componentType } = fieldBaseConfig;

  const valueKey = valueKeyForConfig(fieldBaseConfig);
  const group = fieldBaseConfig.catalogFieldConfigGroup;

  const hasConfiguredOptions =
    ((fieldBaseConfig.autocompleteOptions?.length ||
      fieldCategoryConfig?.autocompleteOptions?.length) ??
      0) > 0;

  if (
    componentType == 'autocomplete' ||
    componentType == 'editableAutocomplete' ||
    hasConfiguredOptions
  ) {
    return {
      ...buildAutocompleteFormikConfig(
        fieldBaseConfig,
        fieldCategoryConfig?.autocompleteOptions,
        categoryId,
        productStore,
        disabled,
        highlight,
        copyAndSaveIfAllowed,
        dispatch,
      ),
    };
  } else if ((componentType ?? 'text') == 'text') {
    //apply optional data-driven parameters applicable to text fields.
    //It looks like I could use the ... operator here but typescript doesn't allow it;
    //I'm guessing the issue has to do with the fact that FormikFieldConfiguration includes
    //a big type union
    const formikFieldConfig: FormikFieldConfiguration & TextInputProps = {
      valueKey,
      displayOrder,
      group,
      label,
      placeholder: placeholder ?? undefined,
      type: fieldBaseConfig.dataType ?? undefined,
      fullWidth,
      rows: fieldBaseConfig.entryLines ?? undefined,
      copyAndSaveFieldValueToAllVariants: copyAndSaveIfAllowed,
      disabled,
      highlight,
    };
    if (fieldCategoryConfig?.appendUnitSuffix) {
      return {
        ...formikFieldConfig,
        componentType: 'appendUnits',
        unitSuffix: fieldCategoryConfig.appendUnitSuffix,
      };
    } else {
      return formikFieldConfig;
    }
  } else {
    switch (componentType) {
      case 'checkbox':
        return {
          valueKey,
          displayOrder,
          group,
          label,
          componentType,
          disabled,
          copyAndSaveFieldValueToAllVariants: copyAndSaveIfAllowed,
        };
      case 'text':
      case 'link':
      case 'displayText':
        const fieldConfigWithComponentType: FormikFieldConfiguration = {
          valueKey,
          displayOrder,
          group,
          label,
          componentType,
          disabled,
          highlight,
        };
        return fieldConfigWithComponentType;
      default:
        const formikFieldConfig: FormikFieldConfiguration = {
          valueKey,
          displayOrder,
          group,
          label,
          disabled,
          highlight,
          copyAndSaveFieldValueToAllVariants: copyAndSaveIfAllowed,
        };
        return formikFieldConfig;
    }
  }
};

export const groupFieldListConfiguration = (
  fields: FormikFieldConfiguration[],
): GroupedFormikFieldListConfiguration => {
  //this function will re-sort within each group and subgroup at the end; I'm not sure yet whether I want the
  //order to be stored per-group or globally.  (per group potentially easier to maintain)
  const groupByDisplayName: {
    [groupDisplayName: string]: FormikFieldGroup & {
      subGroupByDisplayName: { [subGroupDisplayName: string]: FormikFieldGroup };
    };
  } = {};
  for (const field of fields) {
    if (!groupByDisplayName[field.group.displayName]) {
      groupByDisplayName[field.group.displayName] = {
        displayName: field.group.displayName,
        displayOrder: field.group.displayOrder,
        fields: [],
        subGroupByDisplayName: {},
      };
    }
    const group = groupByDisplayName[field.group.displayName];
    if (field.subGroup) {
      if (!group.subGroupByDisplayName[field.subGroup.displayName]) {
        group.subGroupByDisplayName[field.subGroup.displayName] = {
          displayName: field.subGroup.displayName,
          displayOrder: field.subGroup.displayOrder,
          fields: [],
        };
      }
      group.subGroupByDisplayName[field.subGroup.displayName].fields.push(field);
    } else {
      groupByDisplayName[field.group.displayName].fields.push(field);
    }
  }

  //In the returned object, the groups, subgroups, and fields themselves all need to be
  //sorted by displayOrder
  const displayOrderSort = (a: { displayOrder: number }, b: { displayOrder: number }) =>
    a.displayOrder - b.displayOrder;

  return {
    groups: Object.values(groupByDisplayName)
      .map(grp => ({
        ...grp,
        subGroups: Object.values(grp.subGroupByDisplayName)
          .map(subgroup => ({
            ...subgroup,
            fields: subgroup.fields.sort(displayOrderSort),
          }))
          .sort(displayOrderSort),
        fields: grp.fields.sort(displayOrderSort),
      }))
      .sort(displayOrderSort),
  };
};
