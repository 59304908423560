import { updateInspection } from '../../../../services/service/services';
import { InspectionStatusEnum } from '../../../../services/service/types';

// exported for testing
export const getAutoTransitionStatusId = (statusId: number): number | undefined => {
  switch (statusId) {
    case InspectionStatusEnum['Ready for Intake']:
      return InspectionStatusEnum['Intake Started'];
    case InspectionStatusEnum['Ready for Inspection']:
      return InspectionStatusEnum['Inspection Started'];
    default:
      return undefined;
  }
};

export const autoTransitionServicePlanStatus = async (
  inspectionId: number,
  statusId: number,
): Promise<boolean> => {
  const newStatusId = getAutoTransitionStatusId(statusId);
  if (newStatusId) {
    const result = await updateInspection(inspectionId, { statusId: newStatusId });
    if (!result || (result && !result.data)) {
      throw new Error(
        `received an unexpected result attempting to transition status from ${InspectionStatusEnum[statusId]} to ${InspectionStatusEnum[newStatusId]}`,
      );
    }
    return true;
  }
  return false;
};
