import React from 'react';
import { default as BaseTab, TabProps as MuiTabProps } from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core';

interface CustomTabProps {
  activeTab?: boolean;
}

export type TabProps = CustomTabProps & MuiTabProps;

const useStyles = makeStyles(theme => ({
  active: {
    color: theme.palette.primary.main,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    fontWeight: 700,
    minWidth: '100%',
  },
  default: {
    color: theme.palette.common.black,
    borderBottom: `3px solid ${theme.palette.common.black}`,
    opacity: 0.6,
    fontWeight: 700,
    minWidth: '100%',
  },
}));

export const Tab: React.FC<TabProps> = ({ activeTab = false, ...muiProps }) => {
  const classes = useStyles();
  return <BaseTab className={activeTab ? classes.active : classes.default} {...muiProps} />;
};
