import React from 'react';
import styled from 'styled-components';
import styles from '../../styledComponents';
import { Typography } from '@material-ui/core';
import { Computer, Build, EmojiObjects } from '@material-ui/icons';
import { HorizontalFormCard } from './HorizontalFormCard';

export const FORMS_PAGE_ROUTE = '/forms';

const { PageWrapper, PageHeader } = styles;

const FormsPageWrapper = styled(PageWrapper)`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ theme }) => `${theme.spacing(96)}px`};
`;

const SectionTitle = styled(Typography)`
  && {
    margin-top: ${({ theme }) => `${theme.spacing(4)}px`};
    margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
    display: block;
  }
`;

const FormsInnerContent = styled.div`
  margin: ${({ theme }) => `${theme.spacing(2)}px`};
`;

export const FormsPage = () => {
  return (
    <FormsPageWrapper>
      <FormsInnerContent>
        <PageHeader>Tickets & Requests</PageHeader>
        <SectionTitle variant="overline" color="textSecondary">
          Technology
        </SectionTitle>
        <HorizontalFormCard
          title="Help Desk Tickets"
          description="Submit bug reports, IT requests, and data updates to the tech team."
          icon={<Computer />}
          link="https://theproscloset.atlassian.net/servicedesk/customer/portal/1"
          linkText="Submit a Ticket"
        />
        <SectionTitle variant="overline" color="textSecondary">
          Operations
        </SectionTitle>
        <HorizontalFormCard
          title="Work Order / Tools Request"
          description="Use this form to submit all facility requests."
          icon={<Build />}
          link="https://forms.monday.com/forms/cde17d2203b9c462987003f1c9572d12?r=use1"
          linkText="Submit a Request"
        />
        <HorizontalFormCard
          title="Innovation Ideas"
          description="Submit ideas to the innovation committee."
          icon={<EmojiObjects />}
          link="https://forms.monday.com/forms/8617ed7737727b9334ffb4760981ee08?r=use1"
          linkText="Submit an Idea"
        />
      </FormsInnerContent>
    </FormsPageWrapper>
  );
};
