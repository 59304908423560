import React from 'react';

const Admin = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.1025 10.5058l1.5322 2.6506a.7658.7658 0 001.0464.2796l1.0694-.6175a6.185 6.185 0 001.4517.8519v1.2242a.7663.7663 0 00.766.766h3.0643a.7663.7663 0 00.7661-.766v-1.2242a6.195 6.195 0 001.4517-.8519l1.0694.6175c.3654.2107.8358.0843 1.0465-.2796l1.5321-2.6506a.7691.7691 0 00-.2796-1.0465l-1.0511-.6074a5.8844 5.8844 0 00-.0015-1.7038l1.051-.6075c.3647-.2106.4911-.6818.2797-1.0464l-1.5322-2.6506a.766.766 0 00-1.0464-.2796l-1.0694.6174a6.1558 6.1558 0 00-1.451-.8518V1.1054a.766.766 0 00-.766-.766H5.9675a.766.766 0 00-.766.766v1.2242a6.2004 6.2004 0 00-1.4517.8518L2.681 2.564a.7652.7652 0 00-1.0464.2796L.1025 5.4942a.7691.7691 0 00.2796 1.0464l1.051.6075a5.8858 5.8858 0 000 1.7038l-1.051.6075c-.3646.2106-.491.6817-.2796 1.0464zm7.3971-5.57c1.69 0 3.0643 1.3743 3.0643 3.0642 0 1.69-1.3743 3.0643-3.0643 3.0643-1.6899 0-3.0642-1.3744-3.0642-3.0643 0-1.69 1.3743-3.0643 3.0642-3.0643z"
      fill="#B1B1B1"
    />
  </svg>
);

export default Admin;
