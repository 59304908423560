import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { NotificationManager } from 'react-notifications';
import fileDownload from 'js-file-download';

import ReturnTable from './return_table';
import ReturnCreateDialog from './return_create_dialog';
import Loading from '../../../components/loader';

import axios from '../../../utils/axios';
import { API_URL } from '../../../constants';

const searchAPI = (searchTerm, source, status, reason, blame) =>
  axios.get(
    `${API_URL}/service/returns?searchTerm=${searchTerm}&source=${source}&status=${status}&reason=${reason}&blame=${blame}`,
  );
//Usage of this debounce library is deprecated - can be replaced with lodash's debounce function
const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 500);

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: '#d3d3d3',
    },
  },
  tableWrap: {
    padding: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    verticalAlign: 'baseline',
  },
  fab: {
    margin: theme.spacing(1),
    verticalAlign: 'baseline',
  },
});

class Returns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      returns: [],
      searchTerm: '',
      source: '',
      status: '',
      reason: '',
      blame: '',
      dialogOpen: false,
      returnReasons: [],
      returnBlames: [],
      dialogForm: {
        orderId: '',
        source: '',
        reasonId: '',
        sku: '',
        quantity: 0,
        generateShippingLabel: false,
      },
    };
  }

  async fetchReturns() {
    const results = await axios.get(`${API_URL}/service/returns`);
    this.setState({ returns: results.data });
  }

  async componentDidMount() {
    await this.fetchReturns();
    const reasonsRes = await axios.get(`${API_URL}/service/returnReasons`);
    const blamesRes = await axios.get(`${API_URL}/service/returnBlames`);
    this.setState({
      returnReasons: reasonsRes.data,
      returnBlames: blamesRes.data,
    });
  }

  handleChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      async () => {
        const { searchTerm, source, status, reason, blame } = this.state;
        const result = await searchAPIDebounced(searchTerm, source, status, reason, blame);
        this.setState({ returns: result.data });
      },
    );
  };

  handleAutosuggestChange = name => (event, { newValue }) => {
    this.setState({
      dialogForm: {
        ...this.state.dialogForm,
        [name]: newValue,
      },
    });
  };

  handleDialogChange = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({
      dialogForm: {
        ...this.state.dialogForm,
        [e.target.name]: value,
      },
    });
  };

  handleStatusChange = (returnId, status) => {
    this.setState({
      returns: this.state.returns.map(r => (r.id === returnId ? { ...r, status } : r)),
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
      dialogForm: {
        orderId: '',
        source: '',
        reasonId: '',
        sku: '',
        quantity: 0,
        generateShippingLabel: false,
        error: '',
      },
    });
  };

  handleReturnCreate = async () => {
    this.setState(prevState => ({
      loading: true,
      dialogForm: { ...prevState.dialogForm, error: '' },
    }));
    try {
      await axios.post(`${API_URL}/service/returns`, {
        ...this.state.dialogForm,
      });
      await this.fetchReturns();
      NotificationManager.info('Return Created');
      this.handleDialogClose();
    } catch (error) {
      console.log(error.response);
      if (error.response && error.response.status >= 400 && error.response.status < 500) {
        this.setState(prevState => ({
          dialogForm: { ...prevState.dialogForm, error: error.response.data },
        }));
      }
    } finally {
      this.setState({ loading: false });
    }
  };

  handleCsvDownload = async () => {
    this.setState({
      loading: true,
    });
    const { searchTerm, source, status, reason, blame } = this.state;

    try {
      const resp = await axios.get(
        `${API_URL}/service/returnsCsv?searchTerm=${searchTerm}&source=${source}&status=${status}&reason=${reason}&blame=${blame}`,
      );
      fileDownload(resp.data, 'returns.csv');
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      source,
      searchTerm,
      status,
      reason,
      blame,
      returns,
      dialogOpen,
      dialogForm,
      returnReasons,
      returnBlames,
    } = this.state;
    return (
      <div>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <form>
              <FormControl className={classes.formControl}>
                <TextField
                  name="searchTerm"
                  label="Search..."
                  className={classes.textField}
                  value={searchTerm}
                  onChange={e => this.handleChange(e)}
                  margin="normal"
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-simple">Source</InputLabel>
                <Select
                  value={source}
                  onChange={e => this.handleChange(e)}
                  inputProps={{
                    name: 'source',
                  }}
                >
                  <MenuItem value="" />
                  <MenuItem value="ebay">Ebay</MenuItem>
                  <MenuItem value="shopify">Shopify</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-simple">Status</InputLabel>
                <Select
                  value={status}
                  onChange={e => this.handleChange(e)}
                  inputProps={{
                    name: 'status',
                  }}
                >
                  <MenuItem value="" />
                  <MenuItem value="initiated">Initiated</MenuItem>
                  <MenuItem value="in-transit">In Transit</MenuItem>
                  <MenuItem value="inspected">Inspected</MenuItem>
                  <MenuItem value="processed">Processed</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-simple">Reason</InputLabel>
                <Select
                  value={reason}
                  onChange={e => this.handleChange(e)}
                  inputProps={{
                    name: 'reason',
                  }}
                >
                  <MenuItem value="" />
                  {returnReasons.map(rr => (
                    <MenuItem value={rr.id} key={rr.id}>
                      {rr.reason}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-simple">Blame</InputLabel>
                <Select
                  value={blame}
                  onChange={e => this.handleChange(e)}
                  inputProps={{
                    name: 'blame',
                  }}
                >
                  <MenuItem value="" />
                  {returnBlames.map(rr => (
                    <MenuItem value={rr.id} key={rr.id}>
                      {rr.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </form>
          </Grid>
          <Grid item>
            <Fab
              color="primary"
              aria-label="add"
              className={classes.fab}
              style={{ borderRadius: '50%' }}
              onClick={() => this.setState({ dialogOpen: true })}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
        <div className={classes.tableWrap}>
          <ReturnTable
            data={returns}
            onStatusChange={this.handleStatusChange}
            handleCsvDownload={this.handleCsvDownload}
          />
        </div>
        <ReturnCreateDialog
          open={dialogOpen}
          formData={dialogForm}
          reasons={returnReasons}
          onClose={this.handleDialogClose}
          onChange={this.handleDialogChange}
          onAutosuggestChange={this.handleAutosuggestChange}
          onReturnCreate={this.handleReturnCreate}
        />
        <Loading loading={this.state.loading} />
      </div>
    );
  }
}

export default withStyles(styles)(Returns);
