import React, { FC, ReactNode, useEffect, useState } from 'react';

import {
  Box,
  Collapse,
  createStyles,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import LaunchIcon from '@material-ui/icons/Launch';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import Draggable from 'react-draggable';
import useLocalStorageState from '../../../utils/hooks/useLocalStorageState';

interface FloatingWindowProps {
  title: ReactNode | string;
  children: ReactNode;
  closedText?: ReactNode | string;
  status?: 'success' | 'info' | 'error' | 'warning' | 'default';
}

const WINDOW_WIDTH = 360;
// References the expanded nav width set in nav.css
const WINDOW_WIDTH_NAV = 300;

const options = [
  {
    label: 'Bottom',
    value: 'bottom',
    icon: <VerticalAlignBottomIcon fontSize="small" />,
  },
  {
    label: 'Windowed',
    value: 'float',
    icon: <LaunchIcon fontSize="small" />,
  },
  {
    label: 'Fullscreen',
    value: 'fullscreen',
    icon: <FullscreenIcon fontSize="small" />,
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    window: {
      position: 'fixed',
      zIndex: 13,
      transition: `width ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}, box-shadow ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    },

    // Window states
    open: {
      '& $window': {
        boxShadow: theme.shadows[12],
      },
      '& $expandIcon': {
        transform: 'rotate(0deg)',
      },
      '& $closedText': {
        opacity: 0,
      },
    },
    closed: {
      '& $window': {
        boxShadow: theme.shadows[2],
      },
      '& $expandIcon': {
        transform: 'rotate(180deg)',
      },
      '& $closedText': {
        opacity: 1,
      },
    },

    // Window Positions
    fullscreen: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(20px) grayscale(100%)',
      zIndex: 100,
    },
    bottom: {
      '& $window': {
        bottom: 0,
      },
    },
    float: {
      '& $window': {
        boxShadow: theme.shadows[12],
      },
    },

    navPinned: {
      '& $title': {
        fontSize: '12px',
      },
    },

    // Window Toolbar
    title: {
      transition: `font-size ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    },

    expandIcon: {
      transition: 'transform 0.2s ease-in-out',
    },

    closedText: {
      transition: `opacity ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}}`,
    },

    // Window Styles

    // create handle to be referenced as child in following styles
    handle: {
      display: 'block',
    },

    draggableHandle: {
      cursor: 'move',
      position: 'absolute',
      top: 0,
      left: 0,
      width: `calc(100% - ${theme.spacing(6)}px) `,
      height: `${theme.spacing(6)}px`,
      zIndex: 1,
    },

    success: {
      '& $handle': {
        background: `linear-gradient( 90deg, ${theme.palette.primary.dark}, ${theme.palette.grey[900]})`,
      },
    },

    info: {
      '& $handle': {
        background: `linear-gradient( 90deg, ${theme.palette.info.dark}, ${theme.palette.grey[900]})`,
      },
    },
    error: {
      '& $handle': {
        background: `linear-gradient( 90deg, ${theme.palette.error.dark}, ${theme.palette.grey[900]})`,
      },
    },
    warning: {
      '& $handle': {
        background: `linear-gradient( 90deg, ${theme.palette.warning.dark}, ${theme.palette.grey[900]})`,
      },
    },
    default: {
      '& $handle': {
        background: `linear-gradient( 90deg, ${theme.palette.secondary.dark}, ${theme.palette.grey[900]})`,
      },
    },
  }),
);

const FloatingWindow: FC<FloatingWindowProps> = ({ title, children, closedText, status }) => {
  const [windowExpanded, setWindowExpanded] = useLocalStorageState<boolean>(
    'windowExpanded',
    false,
  );
  const [position, setPosition] = useState('bottom' || 'float' || 'fullscreen');
  const [navPinned, setNavPinned] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(WINDOW_WIDTH);
  const [windowX, setWindowX] = useState(0);
  const [windowY, setWindowY] = useState(0);
  const [relativeHorizontalPosition, setRelativeHorizontalPosition] = useState(0.5);

  const initialMouseState = {
    x: null,
    y: null,
  };

  const [mouseState, setMouseState] = React.useState<{
    x: null | number;
    y: null | number;
  }>(initialMouseState);

  useEffect(() => {
    if (windowX === 0) {
      setWindowWidth(WINDOW_WIDTH_NAV);
      setNavPinned(true);
    } else {
      setWindowWidth(WINDOW_WIDTH);
      setNavPinned(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, windowX]);

  useEffect(() => {
    setPosition(options[selectedIndex].value);
    if (selectedIndex === 1) {
      setWindowExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  // Keep window relatively positioned when window is resized
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    setWindowX((dimensions.width - windowWidth) * relativeHorizontalPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions.width]);

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    setWindowX((window.innerWidth - windowWidth) / 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  return (
    <Box
      className={`${windowExpanded ? classes.open : classes.closed} ${
        position === 'bottom'
          ? classes.bottom
          : position === 'float'
          ? classes.float
          : classes.fullscreen
      } ${status ? classes[status] : classes.default} ${navPinned ? classes.navPinned : ''}`}
      width={windowWidth}
    >
      <Draggable
        bounds="body"
        handle="strong"
        position={{ x: windowX, y: windowY }}
        {...(position === 'bottom' && {
          axis: 'x',
          position: { x: windowX, y: 0 },
          onStop: (_e, d) => {
            setRelativeHorizontalPosition(d.x / (window.innerWidth - windowWidth));
            setWindowX(d.x);
          },
        })}
        {...(position === 'float' && {
          axis: 'both',
          position: { x: windowX, y: windowY },
          onStop: (_e, d) => {
            setWindowX(d.x);
            setWindowY(d.y);
          },
        })}
        {...(position === 'fullscreen' && {
          axis: 'none',
          position: { x: 0, y: 0 },
        })}
      >
        <Box display="flex" flexDirection="column" width={windowWidth} className={classes.window}>
          <strong
            onContextMenu={e => {
              e.preventDefault();
              setMouseState({
                x: e.clientX - 2,
                y: e.clientY - 4,
              });
            }}
            className={classes.draggableHandle}
          ></strong>
          <Box position="absolute" right="0" top="0" zIndex={1} color="#ccc">
            <IconButton
              style={{ cursor: 'pointer' }}
              disableRipple
              color="inherit"
              onClick={() => setWindowExpanded(!windowExpanded)}
            >
              <ExpandMoreIcon className={classes.expandIcon} />
            </IconButton>
          </Box>
          <Box position="relative">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={3}
              width="100%"
              className={classes.handle}
              style={{ cursor: 'move' }}
            >
              <Typography
                variant="body2"
                style={{
                  margin: 0,
                  color: 'white',
                  lineHeight: 0,
                }}
                className={classes.title}
              >
                {title}
              </Typography>
            </Box>
            <Box
              position="absolute"
              left={0}
              px={2}
              zIndex={1}
              display="flex"
              top={0}
              bottom={0}
              alignItems="center"
              className={classes.closedText}
            >
              {closedText}
            </Box>
          </Box>

          <Menu
            keepMounted
            open={mouseState.y !== null}
            onClose={() => setMouseState(initialMouseState)}
            anchorReference="anchorPosition"
            anchorPosition={
              mouseState.y !== null && mouseState.x !== null
                ? { top: mouseState.y, left: mouseState.x }
                : undefined
            }
          >
            {options.map((option, index) => (
              <MenuItem
                key={option.label}
                selected={index === selectedIndex}
                onClick={() => {
                  setSelectedIndex(index);
                  setMouseState(initialMouseState);
                }}
                style={{ textTransform: 'capitalize' }}
              >
                <ListItemIcon style={{ minWidth: 32 }}>{option.icon}</ListItemIcon>
                <ListItemText> {option.label}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
          <Collapse in={windowExpanded}>{children}</Collapse>
        </Box>
      </Draggable>
    </Box>
  );
};

export default FloatingWindow;
