import { FormikValues } from 'formik';
import { TextInputProps } from '../../../components/catalog/FormikTextInput';
import { AutoCompleteProps } from '../../../components/catalog/FormikAutoCompleteField';
import { VariationType } from '../../../services/catalog/types';
import { FormikMinMaxFieldProps } from '../../../components/catalog/FormikMinMaxField';
import { FormikAppendUnitsProps } from '../../../components/catalog/FormikAppendUnits';
import { EditableAutoCompleteProps } from '../../../components/catalog/FormikEditableAutoCompleteField';
import { Submission } from '../../../services/catalog/types';
import { FormikCheckboxProps } from '../../../components/catalog/FormikCheckboxField';
import { CheckboxProps, TextFieldProps } from '@material-ui/core';
import { InputProps } from '../../../components/catalog/FormikDisplayField';

export type FormikFieldsMapValueProps =
  | (AutoCompleteProps & { componentType: 'autocomplete' })
  | (EditableAutoCompleteProps & { componentType: 'editableAutocomplete' })
  | (FormikCheckboxProps & CheckboxProps & { componentType: 'checkbox' })
  | (TextInputProps & { componentType: 'text' })
  | (FormikMinMaxFieldProps & { componentType: 'minMax' })
  | (FormikAppendUnitsProps & { componentType: 'appendUnits' })
  | (InputProps & { componentType: 'link' | 'displayText' })
  | (TextInputProps & TextFieldProps & { componentType?: never });

export type GroupedFormikFieldsMapValueProps<E> = FormikFieldsMapValueProps & {
  groupBy: E;
  subGroup?: string;
};

//an object whose keys are the keys of V (or any string, if V is not specified)
//and whose values, by default, are InputMapValues
export type FieldsMap<V = FormikValues, I = FormikFieldsMapValueProps> = {
  [key in keyof V]: I;
};

export interface EditProductValues {
  model: string | null;
  brandId: number;
  title: string;
  displayTitle: string | null;
  disciplineId: number | null;
  productSubCategoryId: number | null;
  variations: VariationType[] | null; //note: these are essentially attribute types
  categoryId: number | null;
  certifiedPreOwned: boolean;
  description: string | null;
  sellPreService: boolean;
  longDescription: string | null;
  features: string | null;
  headline?: string;
  upgrades?: string;
  masterMechanicsNotes?: string;
  disabledCategoryId?: number | null;
}

/**
 * NOTE: These Group titles are used in view, add, and edit, but not all of them.
 */
export enum ProductFormGroups {
  'Submission' = 'Submission',
  'Product Information' = 'Product Information',
  'Variant Specifications' = 'Variant Specifications',
  'Bike Information' = 'Bike Information',
  'Frame Information' = 'Frame Information',
  'Frame Geometry' = 'Frame Geometry',
  'Pricing Information' = 'Pricing Information',
  'Component Specifications' = 'Component Specifications',
  'E-Bike Information' = 'E-Bike Information',
  'Condition Details' = 'Condition Details',
}

export type GroupedValues<V> = {
  [key in ProductFormGroups]: FieldsMap<V, GroupedFormikFieldsMapValueProps<ProductFormGroups>> & {
    subGroups?: {
      [subGroupName: string]: FieldsMap<V, GroupedFormikFieldsMapValueProps<ProductFormGroups>>;
    };
  };
};

export interface LocationState {
  submission: Submission;
  drivetrainBikeCategoryId?: number;
  enteredTrackingNumber?: string;
}

export enum promotionalTextType {
  headline = 1,
  longDescription = 2,
  features = 3,
  upgrades = 4,
  masterMechanicsNotes = 5,
}
