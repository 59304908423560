import React, { useContext, useCallback, useMemo, useState } from 'react';
import { Formik, FormikProps, FormikErrors } from 'formik';
import { EditablePartsTable } from './EditablePartsTable';
import { StaticPartsTable } from './StaticPartsTable';
import { ServiceTicketModuleEditButton } from '../ServiceTicketModuleEditButton';
import { CategoryHeaderWrapper } from '../styled';
import { serviceTicketContext } from '../../../_shared';
import { ValidationErrorModal } from '../../../ValidationErrorModal';
import {
  FormikPartsState,
  FormikPartsErrorKeys,
  PartSources,
  mandatoryFormPartFields,
} from '../../../_shared/types';
import { saveParts } from '../service';
import { Lookup } from '../../../_shared';
import { pageStateContext } from '../../../../../_shared';
import { ModuleWrapper } from '../../styled';

type PartPropertyPrettyName = {
  [key in FormikPartsErrorKeys]: string;
};
const partPropertyPrettyNames: PartPropertyPrettyName = {
  inspectionPointTypeId: 'Inspection Point Type',
  sourceId: 'Source',
  sourceBikeSku: 'Source Bike SKU',
  sourceOrderNumber: 'Source Order Number',
  sourceOrderDescription: 'Source Order Description',
  sourceOrderCost: 'Source Order Cost',
  identifier: 'Identifier',
  identifierTypeId: 'Identifier Type',
  quantity: 'Quantity',
  statusId: 'Status',
};

// todo: unit test this
const handleValidateParts = (
  values: FormikPartsState,
  pointTypeLookup: Lookup,
  handleValidationError: () => void,
) => {
  const errors: FormikErrors<FormikPartsState> = {};
  const parts = Object.values(values);
  for (const part of parts) {
    let requiredValues: FormikPartsErrorKeys[];
    switch (part.sourceId) {
      case PartSources.bike:
        requiredValues = [...mandatoryFormPartFields, 'sourceBikeSku'];
        break;
      case PartSources.order:
        requiredValues = [
          ...mandatoryFormPartFields,
          'sourceOrderCost',
          'sourceOrderDescription',
          'sourceOrderNumber',
        ];
        break;
      default:
        requiredValues = mandatoryFormPartFields;
    }
    const errorEntries = requiredValues
      .map(key => {
        if (part[key] === undefined || part[key] === null || part[key] === '') {
          // todo: see if there's a way to convince formik that these errors can be objects with
          // a property for both these values rather than string-parsing based on '|' delimiter
          const fieldName = partPropertyPrettyNames[key as FormikPartsErrorKeys];
          const pointType =
            pointTypeLookup[part.inspectionPointTypeId] ?? '(No field type selected)';
          const str = `${fieldName}|${pointType}`;
          const x = [[key], str];
          return x;
        } else {
          return undefined;
        }
      })
      .filter((entry): entry is [FormikPartsErrorKeys, string] => entry !== undefined);
    const errorsForPart = Object.fromEntries(errorEntries);
    if (Object.values(errorsForPart).some(v => v !== undefined)) {
      errors[part.id] = errorsForPart;
    }
  }

  if (Object.keys(errors)?.length > 0) {
    handleValidationError();
  }

  return errors;
};

interface PartsModuleProps {
  editing: boolean;
  readOnly: boolean;
  hasUnsavedData: boolean;
  handleEditParts(): void;
  handleSuccess(): void;
  handleDataChange(dirty: boolean): void;
}

export const PartsModule = React.forwardRef<FormikProps<FormikPartsState>, PartsModuleProps>(
  (
    { editing, readOnly, handleEditParts, handleSuccess, handleDataChange, hasUnsavedData },
    ref,
  ) => {
    const {
      errorBus: { setError },
      loadingBus: { setLoading },
    } = useContext(pageStateContext);
    const { inspectionData } = useContext(serviceTicketContext);
    const {
      inspection,
      failedReplacePointOptions,
      pointTypeCategoryLookup,
      pointTypeLookup,
    } = inspectionData;

    const [validationErrorModalOpen, setValidationErrorModalOpen] = useState<boolean>(false);

    const initialPartsValues: FormikPartsState = useMemo(() => {
      if (!inspection) return {};
      return Object.fromEntries(inspection.inspectionParts.map(part => [part.id, part]));
    }, [inspection]);

    const handlePartsSave = useCallback(
      (values: FormikPartsState) => {
        if (inspection) {
          saveParts(
            { values, inspectionId: inspection.id, statusId: inspection.statusId, hasUnsavedData },
            {
              setLoading: (loading: boolean) => setLoading('handlePartsSave', loading),
              setError: (error: Error | undefined) => setError('handlePartsSave', error),
              handleSuccess,
            },
          );
        }
      },
      [inspection, setLoading, setError, handleSuccess, hasUnsavedData],
    );

    const validateParts = useCallback(
      (values: FormikPartsState) => {
        if (!pointTypeLookup) throw new Error('cannot validate parts without pointTypeLookup');
        return handleValidateParts(values, pointTypeLookup, () =>
          setValidationErrorModalOpen(true),
        );
      },
      [pointTypeLookup, setValidationErrorModalOpen],
    );

    if (!inspection || !failedReplacePointOptions || !pointTypeCategoryLookup) {
      return null;
    }

    if (readOnly) {
      return (
        <ModuleWrapper>
          <CategoryHeaderWrapper>
            <h3>Parts</h3>
          </CategoryHeaderWrapper>
          <StaticPartsTable
            inspectionParts={inspection.inspectionParts}
            failedReplacePointOptions={failedReplacePointOptions}
            pointTypeCategoryLookup={pointTypeCategoryLookup}
          />
        </ModuleWrapper>
      );
    }

    return (
      <Formik<FormikPartsState>
        innerRef={ref}
        initialValues={initialPartsValues}
        enableReinitialize
        onSubmit={handlePartsSave}
        validate={validateParts}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <ModuleWrapper>
              <CategoryHeaderWrapper>
                <h3>Parts</h3>
                {!editing && (
                  <ServiceTicketModuleEditButton onClick={handleEditParts} moduleName="Parts" />
                )}
              </CategoryHeaderWrapper>
              {editing ? (
                <>
                  <EditablePartsTable
                    failedReplacePointOptions={failedReplacePointOptions}
                    pointTypeCategoryLookup={pointTypeCategoryLookup}
                    handleDataChange={handleDataChange}
                  />
                  {validationErrorModalOpen && pointTypeLookup && (
                    <ValidationErrorModal
                      open={validationErrorModalOpen}
                      handleClose={() => setValidationErrorModalOpen(false)}
                      errors={errors}
                    />
                  )}
                </>
              ) : (
                <StaticPartsTable
                  inspectionParts={inspection.inspectionParts}
                  failedReplacePointOptions={failedReplacePointOptions}
                  pointTypeCategoryLookup={pointTypeCategoryLookup}
                />
              )}
            </ModuleWrapper>
          </form>
        )}
      </Formik>
    );
  },
);
