/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import {
  getImageIndex,
  getRenderImage,
  getRenderArrowNext,
  getRenderArrowPrev,
  getFormatStatusText,
} from './ImageCarousel.utils';
import { Modal } from '../Modal';
import { Box, IconButton, createStyles, makeStyles, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import './ImageCarousel.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      position: 'absolute',
      top: '24px',
      left: '50px',
      zIndex: 12,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    iconButton: {
      backgroundColor: theme.palette.common.white,
    },
    actionBack: {
      color: theme.palette.primary.main,
    },
  }),
);

const arrowSize = 48;

interface CarouselProps {
  images: string[];
  showArrows?: boolean;
  showThumbs?: boolean;
  showStatus?: boolean;
  showModal?: boolean;
  emulateTouch?: boolean;
  swipeable?: boolean;
  zoomable?: boolean;
  useKeyboardArrows?: boolean;
  thumbWidth?: number;
  width?: string | number;
}

export type ImageCarouselProps = CarouselProps;

export const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  showArrows,
  showThumbs,
  showStatus,
  showModal,
  emulateTouch,
  swipeable,
  thumbWidth,
  width,
  zoomable,
  useKeyboardArrows,
}) => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const renderModalImage = useCallback(getImageIndex(imageIndex, setImageIndex), [imageIndex]);
  const renderImage = useCallback(
    getRenderImage(zoomable, showModal, isModalOpen, setIsModalOpen),
    [isModalOpen],
  );
  const renderArrowPrev = useCallback(getRenderArrowPrev(showArrows, arrowSize), [showArrows]);
  const renderArrowNext = useCallback(getRenderArrowNext(showArrows, arrowSize), [showArrows]);
  const formatStatusText = useCallback(getFormatStatusText(showStatus), [showStatus]);

  const settings = {
    showArrows: showArrows,
    showThumbs: showThumbs ?? true,
    infiniteLoop: true,
    showStatus: showStatus ?? false,
    showIndicators: false,
    emulateTouch: emulateTouch ?? true,
    swipeable: swipeable ?? true,
    thumbWidth: thumbWidth ?? 56,
    width: width ?? '100%',
    useKeyboardArrows: useKeyboardArrows ?? true,
    onChange: renderModalImage,
    renderItem: renderImage,
    renderArrowPrev: renderArrowPrev,
    renderArrowNext: renderArrowNext,
    statusFormatter: formatStatusText,
  };

  const modalSettings = {
    ...settings,
    showThumbs: true,
    showStatus: false,
    width: '100%',
    selectedItem: imageIndex,
  };

  return (
    <>
      {showModal && isModalOpen && (
        <Modal
          open={isModalOpen}
          label={'Image Carousel Modal'}
          handleClose={() => setIsModalOpen(false)}
          margin={'0px'}
        >
          <>
            <div className={classes.actionContainer}>
              <div onClick={() => setIsModalOpen(false)}>
                <IconButton className={classes.iconButton} aria-label="view previous image">
                  <CloseIcon className={classes.actionBack} />
                </IconButton>
              </div>
            </div>
            <Box display={'flex'} maxHeight={'calc(100vh - 64px)'}>
              <Carousel {...settings} {...modalSettings}>
                {images.map((img, index) => {
                  return (
                    <div key={img + index}>
                      <img key={img + index} className="carousel-image" src={img} />
                    </div>
                  );
                })}
              </Carousel>
            </Box>
          </>
        </Modal>
      )}
      <Carousel {...settings}>
        {images.map((img, index) => {
          return (
            <div key={img + index}>
              <img key={img + index} className="carousel-image" src={img} />
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default ImageCarousel;
