import React from 'react';

const TradeUps = () => (
  <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.1429 6.7143v10.7143H0l3.75 4.2857 3.75-4.2857H5.3571V6.7143H2.143zM11.25.2857L7.5 4.5714h2.1429v10.7143h3.2142V4.5714H15L11.25.2857z"
      fill="currentColor"
    />
  </svg>
);

export default TradeUps;
