import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { FEProductImage, ShopifyStatus } from '../../../services/catalog/types';
import { Button } from '@material-ui/core';
import ProductImage from './ProductImage';
import { Tooltip } from '@mui/material';

const ReorderableLi = styled.li<{ isHovered?: boolean }>`
  position: relative;
  display: flex;
  height: 8rem;
  max-width: 12rem;
  cursor: move;

  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    ${({ isHovered }) =>
      isHovered &&
      `content: '';
      background-color: #00c160;
    `}
  }
`;

const ReorderableSpacerLi = styled(ReorderableLi)`
  cursor: auto;
  padding: 1rem;
  min-height: 100%;

  &:after {
    left: calc(1rem - 2px);
    width: 4px;
  }
`;

const FileList = styled.ol`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;

  & ${ReorderableLi} {
    .name,
    .index,
    .delete {
      position: absolute;
      padding: 0.2rem;
      color: white;
      line-height: 24px;
    }

    .index {
      text-align: center;
      min-width: 1.5rem;
      background: rgba(0, 0, 0, 0.7);
      top: 0;
      left: 0;
    }

    .name {
      text-align: right;
      background: rgba(0, 0, 0, 0.7);
      bottom: 0;
      right: 0;
    }

    .delete {
      text-align: center;
      top: 0;
      right: 0;
      cursor: pointer;
      z-index: 1;
      background: rgba(200, 0, 0, 0.7);

      &:hover {
        background: rgba(200, 0, 0, 1);
      }

      &.disabled {
        background: rgba(0, 0, 0, 0.3);
        cursor: not-allowed;
        .icon {
          opacity: 0.5;
        }
      }

      .icon {
        display: block;
      }
    }
  }
`;

const ClearButton = styled(Button)`
  margin-right: 1rem;
`;

const parseDomStringAsNumber = (s: string | undefined) => {
  if (!s) return undefined;
  const parsed = Number(s);
  return isNaN(parsed) ? undefined : parsed;
};

interface DragAndDropProps {
  images: FEProductImage[];
  handleChange: Function;
  handleDelete?: Function;
  shopifyStatus?: string;
}

const DragAndDropImages: React.FC<DragAndDropProps> = ({
  images,
  handleChange,
  handleDelete,
  shopifyStatus,
}) => {
  const [currDragIdx, setCurrDragIdx] = useState<number>();
  const [hoverIdx, setHoverIdx] = useState<number>();

  if (images.length === 0) {
    return null;
  }

  const handleClearImages = () => {
    if (handleDelete) {
      handleDelete((deletedImages: any[]) => deletedImages.concat(images));
    }
    handleChange([]);
  };

  const handleDeleteImage = (e: React.MouseEvent<HTMLElement>) => {
    const elWithIdx = e?.currentTarget?.parentNode as HTMLElement;
    e.stopPropagation();
    if (elWithIdx) {
      const imageIdx = parseDomStringAsNumber(elWithIdx.dataset.index);
      if (imageIdx !== undefined) {
        const updatedImages = [...images];
        const deletedImage = updatedImages.splice(imageIdx, 1)[0];
        handleChange(updatedImages);
        if (handleDelete) {
          handleDelete((deletedImages: FEProductImage[]) => [...deletedImages, deletedImage]);
        }
      }
    }
  };

  const handleDragStart = (e: React.DragEvent<HTMLLIElement>) => {
    setCurrDragIdx(parseDomStringAsNumber(e.currentTarget.dataset.index));
  };

  const handleDragEnd = () => {
    setCurrDragIdx(undefined);
    setHoverIdx(undefined);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLIElement>) => {
    e.preventDefault();
    // other instances of this component on the same page will appear as valid targets
    // unless we first ensure the "drag" event originated from an el from this instance
    if (currDragIdx === undefined) return;
    const isSpacer = !!e.currentTarget.dataset.spacerIndex;
    const idx = parseDomStringAsNumber(e.currentTarget.dataset[isSpacer ? 'spacerIndex' : 'index']);
    setHoverIdx(idx);
  };

  const handleDrop = (e: React.DragEvent<HTMLLIElement>) => {
    e.preventDefault();
    const isSpacer = !!e.currentTarget.dataset.spacerIndex;
    const currDropIdx = parseDomStringAsNumber(
      e.currentTarget.dataset[isSpacer ? 'spacerIndex' : 'index'],
    );

    if (currDragIdx === undefined || currDropIdx === undefined || currDragIdx === currDropIdx) {
      return;
    }

    let newImgs: FEProductImage[] = [];
    if (isSpacer) {
      // rebuild array with dragged el at new index + shifted indices for others
      const targetIdx = Math.ceil(currDropIdx);
      images.forEach((img, idx) => {
        if (idx === targetIdx) newImgs.push(images[currDragIdx]);
        if (idx !== currDragIdx) newImgs.push(img);
      });
      // handle final spacer
      if (targetIdx === images.length) newImgs.push(images[currDragIdx]);
    } else {
      newImgs = [...images];
      // swap indices of dragged el + dropped el
      [newImgs[currDragIdx], newImgs[currDropIdx]] = [newImgs[currDropIdx], newImgs[currDragIdx]];
    }

    handleChange(newImgs);
    setHoverIdx(undefined);
  };

  return (
    <Fragment>
      <FileList>
        {images.map((img, idx) => {
          const preElSpacerIdx = idx - 0.5;
          const postElSpacerIdx = idx + 0.5;
          const currentlyDragging = currDragIdx !== undefined;
          const draggedElMatchesBefore = currDragIdx === idx - 1;
          const draggedElMatchesAfter = currDragIdx === idx;
          const isFinalIdx = idx === images.length - 1;

          const isPreElSpacerInteractive =
            currentlyDragging && !draggedElMatchesBefore && !draggedElMatchesAfter;
          const isPostElSpacerInteractive = currentlyDragging && !draggedElMatchesAfter;
          return (
            <Fragment key={img.name}>
              {/* insert spacer rendered before each image in the list */}
              {isPreElSpacerInteractive ? (
                <ReorderableSpacerLi
                  data-spacer-index={preElSpacerIdx}
                  onDragEnd={handleDragEnd}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  isHovered={preElSpacerIdx === hoverIdx}
                />
              ) : (
                <ReorderableSpacerLi />
              )}
              {/* image li */}
              <ReorderableLi
                data-index={idx}
                draggable={true}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                isHovered={idx === hoverIdx}
              >
                <span className="name">{img.name.replace(/\.[^/.]+$/, '')}</span>
                <span className="index">{idx + 1}</span>

                {/* if product is published and there is only one image, don't show delete button */}

                {!(shopifyStatus === ShopifyStatus.Active && images.length === 1) ? (
                  <div className="delete" onClick={handleDeleteImage}>
                    <CloseIcon classes={{ root: 'icon' }} />
                  </div>
                ) : (
                  <Tooltip
                    title="Unable to delete last image from a published Shopify product."
                    placement="right"
                    arrow
                  >
                    <div className="delete disabled">
                      <CloseIcon classes={{ root: 'icon' }} />
                    </div>
                  </Tooltip>
                )}

                <ProductImage src={img.src} alt={img.alt} />
              </ReorderableLi>
              {/* insert spacer rendered at the end of the list */}
              {isFinalIdx && isPostElSpacerInteractive && (
                <ReorderableSpacerLi
                  data-spacer-index={postElSpacerIdx}
                  onDragEnd={handleDragEnd}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  isHovered={postElSpacerIdx === hoverIdx}
                />
              )}
              {isFinalIdx && !isPostElSpacerInteractive && <ReorderableSpacerLi />}
            </Fragment>
          );
        })}
      </FileList>
      {images.length > 1 && (
        <ClearButton onClick={handleClearImages} variant="outlined">
          clear all
        </ClearButton>
      )}
    </Fragment>
  );
};

export default DragAndDropImages;
