import { GET_PURCHASE_FILTERS, GET_PURCHASE_SORT, GET_PURCHASE_PAGE } from '../actions/types';

export default (
  state = {
    filters: {
      searchTerm: '',
      isPaid: false,
      isCompleted: false,
      paymentType: '',
      customers: [],
    },
    sort: [
      {
        id: 'created',
        desc: true,
        page: 0,
      },
    ],
    page: 0,
  },
  action,
) => {
  switch (action.type) {
    case GET_PURCHASE_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case GET_PURCHASE_SORT: {
      return {
        ...state,
        sort: action.payload,
      };
    }
    case GET_PURCHASE_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    default:
      return state;
  }
};
