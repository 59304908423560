import React, { useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import useLocalStorageState from '../../utils/hooks/useLocalStorageState';

interface PreferenceSwitchProps {
  localStorageKey: string;
  cacheBust: number;
  forceReload?: boolean;
}

export const PreferenceSwitch: React.FC<PreferenceSwitchProps> = ({
  localStorageKey,
  cacheBust,
  forceReload,
}) => {
  const [isChecked, setIsChecked] = useLocalStorageState<boolean>(localStorageKey, false);

  useEffect(() => {
    setIsChecked(JSON.parse(window.localStorage.getItem(localStorageKey) as string));
  }, [cacheBust, setIsChecked, localStorageKey]);

  return (
    <Switch
      color="primary"
      checked={isChecked}
      onChange={e => {
        setIsChecked(e.target.checked);
        {
          forceReload && window.location.reload();
        }
      }}
    />
  );
};
