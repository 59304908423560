// This component allows users to add free form text to auto complete
// The code is similar to FormikAutoCompleteField but making a sperate component for readability
import React, { FC, useMemo, useState } from 'react';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FieldWrapper } from './FormikTextInput';
import { useField, FormikHelpers } from 'formik';
import { AutoCompleteProps } from './FormikAutoCompleteField';
import { ErrorMessage } from '../library';

type HandleDialogSubmitArgs = {
  value: string;
  setValue: FormikHelpers<number>['setValues'];
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogError: React.Dispatch<React.SetStateAction<string>>;
};

export type AutoCompleteEditabilityProps = {
  useDialog?: boolean;
  handleDialogSubmit?(args: HandleDialogSubmitArgs): void;
  filterStringify?(option: unknown): string;
};

export type EditableAutoCompleteProps = AutoCompleteProps & AutoCompleteEditabilityProps;

const FormikEditableAutoCompleteField: FC<EditableAutoCompleteProps & { name: string }> = ({
  labelKey,
  options,
  placeholder,
  autoFocus,
  name,
  disabled,
  label,
  highlight,
  handleDialogSubmit,
  useDialog,
  filterStringify,
  fullWidth,
}) => {
  const [field, meta, { setValue, setTouched }] = useField(name);
  const [diaglogOpen, setDialogOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState('');
  const [dialogError, setDialogError] = useState('');

  const filter = useMemo(
    () =>
      createFilterOptions({
        stringify: filterStringify ? filterStringify : undefined,
      }),
    [filterStringify],
  );

  const value = useMemo(() => options.find(o => o.id === field.value) || field.value?.toString(), [
    options,
    field,
  ]);

  const handleClose = () => {
    setDialogValue('');
    setValue('');
    setDialogOpen(false);
  };

  const onDialogSubmit = () => {
    handleDialogSubmit &&
      handleDialogSubmit({ value: dialogValue, setValue, setDialogOpen, setDialogError });
  };

  return (
    <>
      <FieldWrapper highlight={highlight} fullWidth={fullWidth}>
        <label>{label}</label>
        <Autocomplete
          options={options}
          onFocus={() => setTouched(true)}
          forcePopupIcon
          freeSolo
          getOptionLabel={option => {
            if (typeof option === 'string') {
              return option;
            }

            if (option.inputValue) {
              return option.inputValue;
            }

            return option[labelKey]?.toString();
          }}
          style={{ backgroundColor: '#fff' }}
          disabled={disabled}
          autoHighlight
          onChange={(_, option) => {
            if (option && option.inputValue) {
              if (useDialog) {
                setDialogOpen(true);
                setDialogValue(option.inputValue);
              } else {
                setValue(option.inputValue, true);
              }
            } else {
              setValue(option?.id || '', true);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                [labelKey]: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          value={value}
          renderOption={option => option[labelKey]}
          onBlur={field.onBlur}
          renderInput={params => (
            <TextField
              {...params}
              error={meta.touched && !!meta.error}
              placeholder={placeholder}
              helperText={meta.touched && meta.error}
              variant="outlined"
              autoFocus={autoFocus || false}
              onBlur={field.onBlur}
            />
          )}
        />
      </FieldWrapper>
      <Dialog open={diaglogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add a new {label}</DialogTitle>
        <DialogContent>
          {/* This can in future come from a prop but for not this general message might work */}
          <DialogContentText>Double check spelling and capitalization</DialogContentText>
          <ErrorMessage error={dialogError} />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={dialogValue}
            onChange={event => setDialogValue(event.target.value)}
            label={labelKey}
            type="text"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onDialogSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default FormikEditableAutoCompleteField;
