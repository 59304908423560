import React from 'react';
import PropTypes from 'prop-types';

const DealerInfo = ({
  dealerInfo: { dealerName, dealerEmail, dealerAddress, dealerCity, dealerPhone, dealerZip },
}) => (
  <fieldset className="dealer-wrap list-wrap">
    <legend>Dealer Info</legend>
    <div className="form-wrap">
      <span className="input-label-wrap-status offer-display">
        <label className="label doo-label">SHOP NAME: </label>
        <p className="dealer-value">{dealerName}</p>
      </span>
      <span className="input-label-wrap-status doo-display">
        <label className="label doo-label">CONTACT: </label>
        <p className="dealer-value">{dealerEmail}</p>
      </span>
    </div>
    <div className="form-wrap">
      <span className="input-label-wrap-status offer-display">
        <label className="label doo-label">ADDRESS: </label>
        <p className="dealer-value">{dealerAddress}</p>
      </span>
      <span className="input-label-wrap-status doo-display">
        <label className="label doo-label">CITY: </label>
        <p className="dealer-value">{dealerCity}</p>
      </span>
    </div>
    <div className="form-wrap">
      <span className="input-label-wrap-status offer-display">
        <label className="label doo-label">PHONE:</label>
        <p className="dealer-value">{dealerPhone}</p>
      </span>
      <span className="input-label-wrap-status doo-display">
        <label className="label doo-label">ZIPCODE: </label>
        <p className="dealer-value">{dealerZip}</p>
      </span>
    </div>
  </fieldset>
);

export default DealerInfo;

DealerInfo.propTypes = {
  dealerInfo: PropTypes.shape().isRequired,
  dealerName: PropTypes.string,
  dealerEmail: PropTypes.string,
  dealerAddress: PropTypes.string,
  dealerCity: PropTypes.string,
  dealerPhone: PropTypes.string,
  dealerZip: PropTypes.string,
};

DealerInfo.defaultProps = {
  dealerName: '',
  dealerEmail: '',
  dealerAddress: '',
  dealerCity: '',
  dealerPhone: '',
  dealerZip: '',
};
