import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { API_URL, idStates } from '../../../constants';
import axios from '../../../utils/axios';
import tpcLogo from '../../../images/rebrand-logo.svg';
import Loader from '../../../components/loader';
// These components will be replaced with Echelon once DT starts using Echelon
import { Box, Typography, useTheme, OutlinedInput, NativeSelect, Button } from '@mui/material';

const ClientReturnForm = props => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  const [submission, setSubmission] = useState({
    items: [
      {
        SubmissionId: null,
        brand: null,
        condition: null,
        Images: [{ url: null }],
      },
    ],
  });
  const [addressInput, setAddress] = useState(null);
  const [cityInput, setCity] = useState(null);
  const [stateInput, setState] = useState(null);
  const [zipInput, setZip] = useState(null);

  useEffect(() => {
    const fetchSubmission = async () => {
      const { token } = props.match.params;
      try {
        const url = `${API_URL}/tradeup/failedSubmission`;
        const response = await axios.get(url, { params: { token } });
        if (response.data === 'confirmed') {
          NotificationManager.info('Address confirmed', 'Success', 7000);
          setConfirmed(true);
        } else {
          const { address, city, state, zip } = response.data;
          setSubmission(response.data);
          setAddress(address);
          setCity(city);
          setState(state);
          setZip(zip);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchSubmission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    const body = {
      address: addressInput,
      city: cityInput,
      state: stateInput,
      zip: zipInput,
      token: props.match.params.token,
    };
    try {
      setLoading(true);
      const url = `${API_URL}/tradeup/failedSubmission`;
      const response = await axios.post(url, body);
      if (response.status < 300) {
        NotificationManager.info('Succesfully submitted return address', 'Success', 7000);
        setSuccess(true);
      }
    } catch (err) {
      console.error(err);
      NotificationManager.error('Error submitting address', 'Error', 7000);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (!submission?.items?.[0]?.SubmissionId && !confirmed) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mx: 'auto',
          p: 4,
          mt: 2,
          backgroundColor: 'white',
          maxWidth: theme => theme.breakpoints.values.md,
        }}
      >
        <Box
          sx={{
            maxWidth: 300,
          }}
        >
          <img alt="The Pro's Closet logo" src={tpcLogo} />
        </Box>
        <Loader loading={loading} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        mx: 'auto',
        p: 4,
        mt: 2,
        backgroundColor: 'white',
        maxWidth: theme => theme.breakpoints.values.md,
      }}
    >
      <Box
        sx={{
          maxWidth: 300,
        }}
      >
        <img alt="The Pro's Closet logo" src={tpcLogo} />
      </Box>
      {confirmed ? (
        <section className="react-table shadow" style={{ minHeight: 200 }}>
          <p>Thank you for confirming your return shipping address.</p>
        </section>
      ) : (
        <Fragment>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: 'Bradford LL',
                }}
              >
                Submission #: {submission.items[0].SubmissionId}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Denim',
                }}
              >
                Brand: {submission.items[0].condition}
                <br />
                Condition: {submission.items[0].condition}
                <br />
                {!success ? (
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    Unfortunately we are not able to accept your bike due to damage our team found
                    during the inspection process. Please confirm your shipping address so we can
                    generate a shipping label to send you back your item(s).
                  </Box>
                ) : (
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    Thanks for submitting your return address! We will generate a shipping label and
                    send you your item back.
                  </Box>
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: 'auto',
                maxWidth: 400,
                maxHeight: 400,
                objectFit: 'contain',
              }}
              component="img"
              src={submission.items[0].Images[0].url}
              alt="driveside photo"
            ></Box>
          </Box>
          <Box>
            {!confirmed ? (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                    maxWidth: 400,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 0.5,
                      }}
                    >
                      Address:
                    </Typography>
                    <OutlinedInput
                      disabled={success}
                      placeholder="e.g. 2845 29th St"
                      type="text"
                      name="address"
                      value={addressInput}
                      onChange={e => setAddress(e.target.value)}
                      variant="outlined"
                      size="small"
                      sx={{
                        borderRadius: 0,
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'black',
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 0.5,
                      }}
                    >
                      City:
                    </Typography>
                    <OutlinedInput
                      disabled={success}
                      placeholder="e.g. Boulder"
                      type="text"
                      name="city"
                      value={cityInput}
                      onChange={e => setCity(e.target.value)}
                      variant="outlined"
                      size="small"
                      sx={{
                        borderRadius: 0,
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'black',
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 0.5,
                      }}
                    >
                      State:
                    </Typography>
                    <NativeSelect
                      disabled={success}
                      value={stateInput}
                      onChange={e => setState(e.target.value)}
                      variant="outlined"
                      size="small"
                      sx={{
                        py: 1,
                        px: 1.75,
                        borderRadius: 0,
                        outline: '1px solid rgba(0, 0, 0, 0.23)',
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'black',
                        },
                        '& select': {
                          pb: 0,
                        },
                        // on focus add black border
                        '&.Mui-focused': {
                          outline: '2px solid black',
                        },
                        // hide before and after
                        '&:before': {
                          display: 'none',
                        },
                        '&:after': {
                          display: 'none',
                        },
                      }}
                    >
                      {idStates.map(v => (
                        <option key={v} value={v}>
                          {v}
                        </option>
                      ))}
                    </NativeSelect>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 0.5,
                      }}
                    >
                      Zip:
                    </Typography>
                    <OutlinedInput
                      disabled={success}
                      placeholder="e.g. 80304"
                      type="text"
                      name="zip"
                      value={zipInput}
                      onChange={e => setZip(e.target.value)}
                      variant="outlined"
                      size="small"
                      sx={{
                        borderRadius: 0,
                        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'black',
                        },
                      }}
                    />
                  </Box>
                  <Box>
                    <Button
                      disabled={success}
                      type="submit"
                      variant="outlined"
                      color="inherit"
                      sx={{
                        borderRadius: 9999,
                        textTransform: 'none',
                        minWidth: 100,
                        mt: 1,
                      }}
                    >
                      Submit
                    </Button>
                    {error && (
                      <Typography
                        sx={{
                          color: 'error.main',
                          mt: 1,
                        }}
                        variant="body1"
                      >
                        There was an error updating your shipping address, please try again or
                        contact support for help.
                      </Typography>
                    )}
                  </Box>
                </Box>
              </form>
            ) : (
              <Typography variant="body1">Thank you for confirming your address.</Typography>
            )}
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default withRouter(ClientReturnForm);
