import React from 'react';

const Paypal = () => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9203 6.5324c.1188.553.1041 1.2238-.0393 2.0145-.5717 2.9251-2.433 4.3867-5.582 4.3867h-.4342a.654.654 0 00-.4361.163.7075.7075 0 00-.2348.4195l-.0403.1856-.5431 3.4172-.0207.1483a.6932.6932 0 01-.2426.4184.6541.6541 0 01-.439.1631H4.43a.3883.3883 0 01-.3251-.1473.4493.4493 0 01-.0884-.3566c.0599-.3664.1453-.9213.2622-1.659.115-.7367.2024-1.2906.2623-1.659.0599-.3683.1473-.9213.2671-1.655.1189-.7348.2083-1.2868.2662-1.6551.0324-.2436.1758-.3644.4253-.3644h1.2927c.8771.0127 1.652-.056 2.3328-.2073 1.1512-.2573 2.0961-.7308 2.8347-1.4232.6728-.6257 1.1816-1.436 1.5323-2.4291.1591-.4616.2721-.9007.3457-1.3142.0059-.0403.0138-.0649.0246-.0727.0078-.0108.0216-.0138.0344-.0108a.374.374 0 01.0609.0344c.5147.3909.8388.9242.9626 1.603zM13.223 3.7468c0 .7042-.1513 1.4812-.4568 2.3318-.5274 1.5342-1.5195 2.5715-2.983 3.1117-.7446.2642-1.5736.4007-2.49.4174 0 .006-.2956.007-.888.007l-.8869-.007c-.66 0-1.048.3144-1.166.9469-.0127.0521-.2926 1.7975-.8397 5.2343-.0079.0649-.0472.1002-.1189.1002H.4814a.464.464 0 01-.3624-.162.461.461 0 01-.113-.3831L2.2966.8118a.77.77 0 01.271-.4744.771.771 0 01.5098-.1866h5.9072c.224 0 .5451.0432.9616.1287.4204.0825.7868.1905 1.1031.3153.7052.269 1.2435.6757 1.6157 1.215.3723.5422.558 1.1855.558 1.937z"
      fill="#B1B1B1"
    />
  </svg>
);

export default Paypal;
