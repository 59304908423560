import { GET_C360_FILTERS, GET_C360_PAGE } from '../actions/types';

export default (
  state = {
    filters: {
      searchTerm: '',
    },
    page: 0,
  },
  action,
) => {
  switch (action.type) {
    case GET_C360_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case GET_C360_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
