import React from 'react';

const Logout = () => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.706.1765H1.7648C.7942.1765.0001.9706.0001 1.9412v14.1176c0 .9706.7941 1.7647 1.7647 1.7647H9.706c.9706 0 1.7647-.7941 1.7647-1.7647v-1.7647H9.706v1.7647H1.7648V1.9412H9.706v1.7647h1.7647V1.9412c0-.9706-.7941-1.7647-1.7647-1.7647z"
      fill="currentColor"
    />
    <path
      d="M8.7444 12.1676l-1.2442 1.2442L3.0885 9l4.4117-4.4118 1.2442 1.2441-2.2765 2.2853h8.5323v1.7647H6.4679l2.2765 2.2853z"
      fill="currentColor"
    />
  </svg>
);

export default Logout;
