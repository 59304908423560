import Service from './svgs/Service';
import TradeUps from './svgs/TradeUps';
import ItemCatalog from './svgs/ItemCatalog';
import ThreeSixty from './svgs/ThreeSixty';
import Intake from './svgs/Intake';
import { TRADEUP_SEARCH_ROUTE } from '../../pages/tradeups/SubmissionListView';

interface SubNav {
  parent: string;
  name: string;
  to: string;
}

interface NavItemObj {
  icon: any;
  to?: string;
  displayTitle?: string;
  subNav?: SubNav[];
}

interface NavItem {
  [key: string]: NavItemObj;
}

const navItems: NavItem = {
  service: {
    icon: Service,
    subNav: [
      {
        parent: 'service',
        name: 'Cycle Timing',
        to: '/service/cycle-timings',
      },
      {
        parent: 'service',
        name: 'Service Plans',
        to: '/service/service-plans',
      },
      {
        parent: 'service',
        name: 'New Service Plan',
        to: '/service/submitNew',
      },
      {
        parent: 'service',
        name: 'CPO Videos',
        to: '/cpoVideo',
      },
    ],
  },
  tradeups: {
    icon: TradeUps,
    subNav: [
      {
        parent: 'tradeups',
        name: 'Tradeups',
        to: TRADEUP_SEARCH_ROUTE,
      },
      {
        parent: 'tradeups',
        name: 'Catch & Release',
        to: '/buyers/tradeups/catchrelease',
      },
      {
        parent: 'tradeups',
        name: 'Tradeups Admin',
        to: '/buyers/tradeups/admin',
      },
      {
        parent: 'tradeups',
        name: 'Partner Site Admin',
        to: '/buyers/tradeups/partners/admin',
      },
      {
        parent: 'tradeups',
        name: 'Leads Online',
        to: '/leadsOnline',
      },
    ],
  },
  360: {
    icon: ThreeSixty,
    subNav: [
      {
        parent: '360',
        name: 'Product 360',
        to: '/products/360view',
      },
      {
        parent: '360',
        name: 'Customer 360',
        to: '/customer360',
      },
    ],
  },
  intake: {
    icon: Intake,
    subNav: [
      {
        parent: 'intake',
        name: 'Buyers',
        to: '/buyers',
      },
      {
        parent: 'intake',
        name: 'Create Barcode',
        to: '/products/createBarcode',
      },
      {
        parent: 'intake',
        name: 'Receiving',
        to: '/receiving',
      },
    ],
  },
  itemCatalog: {
    icon: ItemCatalog,
    displayTitle: 'Item Catalog',
    subNav: [
      {
        parent: 'item catalog',
        name: 'Add Product',
        to: '/catalog/product-creation',
      },
      {
        parent: 'item catalog',
        name: 'Search',
        to: '/catalog/products',
      },
      {
        parent: 'item catalog',
        name: 'UPC Search',
        to: '/catalog/upc',
      },
    ],
  },
};

export default navItems;
