import React, { ReactElement } from 'react';
import { Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { FileCopy } from '@material-ui/icons';

interface LabelValuePairDisplayProps {
  labelValuePairs: {
    label: string;
    value?: string | number | ReactElement | null;
    url?: string | null;
    copy?: { value: string | null; hint: string };
  }[];
  gridSize?: { label: number; value: number };
}

const LabelValuePairDisplay = ({ labelValuePairs, gridSize }: LabelValuePairDisplayProps) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-end"
      sx={{ border: '1px solid #ccc', borderRadius: 1 }}
    >
      <Grid container direction="column" item xs={12}>
        {labelValuePairs.map((pair, index) => {
          return (
            <Grid
              key={`${pair.value}-${pair.label}`}
              item
              container
              direction="row"
              sx={{
                borderTop: index === 0 ? '' : '1px solid #ccc',
                '&:hover': {
                  backgroundColor: 'rgba(0, 193, 96, .1)',
                  '& .headerCell': {
                    backgroundColor: 'rgba(0, 193, 96, .1)',
                  },
                },
              }}
            >
              <Grid
                item
                className="headerCell"
                xs={gridSize?.label ?? 5}
                sx={{
                  backgroundColor: '#eee',
                  textAlign: 'end',
                  px: '5px',
                  py: '10px',
                }}
              >
                <Typography
                  component="label"
                  sx={{
                    display: 'block',
                    width: '100%',
                  }}
                >
                  {pair.label}:
                </Typography>
              </Grid>
              <Grid
                item
                xs={gridSize?.value ?? 7}
                sx={{
                  px: '5px',
                  py: '10px',
                  overflowWrap: 'break-word',
                }}
              >
                {typeof pair.value !== 'string' && typeof pair.value !== 'string' ? (
                  pair.value
                ) : pair.url ? (
                  <Typography component="span">
                    <Link href={pair.url} target="_blank">
                      {pair.value}
                    </Link>
                  </Typography>
                ) : (
                  <Typography component="span">{pair.value ?? ''}</Typography>
                )}
                {pair.value && pair.copy && pair.copy.value && (
                  <Tooltip title={pair.copy.hint}>
                    <IconButton
                      size="small"
                      sx={{ ml: 1 }}
                      onClick={() => {
                        navigator.clipboard.writeText(pair.copy?.value as string);
                      }}
                    >
                      <FileCopy fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default LabelValuePairDisplay;
