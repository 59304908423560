import { DEFAULT_WEIGHT_FIELD_VALUE } from '..';
import {
  Brand,
  CreateConditionAlternates,
  ProductCategory,
  Submission,
} from '../../../../services/catalog/types';
import { getAcceptedOffer } from '../../../tradeups/helper';
import { AddProductAndVariantValues } from '../../addProduct/types';
import { selectVariantFieldUnitsByCategoryId } from '../categories';

interface GetInitialValuesParams {
  selectedSubmission?: Submission;
  initialCreatePoValue?: boolean;
  selectedCategoryId?: number;
  enteredTrackingNumber?: string;
  brands: Brand[];
  categories: ProductCategory[];
}

export const getAddProductInitialValues = ({
  selectedSubmission,
  initialCreatePoValue,
  selectedCategoryId,
  enteredTrackingNumber,
  brands,
  categories,
}: GetInitialValuesParams): AddProductAndVariantValues => {
  const submissionItem = selectedSubmission?.items[0];
  const acceptedOffer = getAcceptedOffer(selectedSubmission?.activeOfferGroup.offers);
  const { id: brandId } = brands.find(brand => brand.name === submissionItem?.brand) || {};
  const categoryId = submissionItem?.categoryId || selectedCategoryId || 0;
  const { createConditionAlternates } = categories.find(c => c.id === categoryId) || {};

  const createConditionAlternatesInitialValue = !!selectedSubmission?.id
    ? false
    : createConditionAlternates === CreateConditionAlternates.ENABLED_DEFAULT_TRUE;

  //todo update this to not use old config stuff
  const shouldShowWeightUnits = selectVariantFieldUnitsByCategoryId('weight', categoryId) !== '';

  return {
    createPurchaseOrder: !!initialCreatePoValue,
    submissionId: selectedSubmission?.id || null,
    enteredTrackingNumber: enteredTrackingNumber || null,
    categoryId: selectedCategoryId || submissionItem?.categoryId || null,
    brandId: brandId || null,
    disciplineId: submissionItem?.disciplineId || null,
    certifiedPreOwned: false,
    description: null,
    sellPreService: false,
    model: submissionItem?.model || null,
    condition: !!selectedSubmission?.id ? 'Pre-Owned' : null,
    title: submissionItem?.title || '',
    displayTitle: submissionItem?.title || null,
    upc: '',
    mpn: '',
    qbpId: '',
    createConditionAlternates: createConditionAlternatesInitialValue,
    year: submissionItem?.year || null,
    pipelineId: submissionItem?.pipelineId || null,
    statusFlagId: null,
    serialNumber: submissionItem?.serialNumber || '',
    costUsd: acceptedOffer?.amountUsd ?? null,
    startingPriceUsd:
      selectedSubmission?.defaultStartingPrice ?? selectedSubmission?.expectedValue ?? null,
    compareAtPriceUsd:
      selectedSubmission?.defaultStartingPrice ?? selectedSubmission?.expectedValue ?? null,
    promotionalPricingFlag: false,
    msrpUsd: submissionItem?.msrp || null,
    mapPricing: false,
    conditionAlternate: 'New',
    weight: shouldShowWeightUnits ? null : DEFAULT_WEIGHT_FIELD_VALUE,
    frameMaterial: null,
    frameHeadset: null,
    frameRearAxleSpacing: null,
    frameRearShockTravel: null,
    drivetrainBrandId: null,
    drivetrainConfiguration: null,
    drivetrainShiftingTypeId: null,
    componentIntendedUseId: null,
    sizeClassId: null,
    brakeType: null,
    gender: null,
    material: null,
    apparelSleeveLength: null,
    frameRearShockTravelRange: null,
    tireType: null,
    configurationDetails: null,
    chargerIncluded: null,
    mileage: null,
    keyIncluded: null,
    tubelessCompatibility: null,
    frameRearTriangleMaterial: null,
    electricTopSpeed: null,
    longDescription: null,
    features: null,
    fulfillmentProvider: null,
  };
};
