import React, { Component } from 'react';
import prettyMs from 'pretty-ms';

type Props = {
  startDate: Date | string | null | number;
  timerOn: boolean;
  seconds: number | null;
};

type TimerState = {
  duration: number;
  on: boolean;
};

export default class Timer extends Component<Props, TimerState> {
  timer = 0;
  constructor(props: any) {
    super(props);
    this.state = {
      duration: 0,
      on: false,
    };
  }

  componentDidMount() {
    this.initTimer();
  }

  componentDidUpdate() {
    const { timerOn } = this.props;
    if (timerOn && !this.state.on) {
      this.startTimer();
      this.setState({ on: true });
    } else if (!timerOn && this.state.on) {
      this.stopTimer();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  secondsSinceDate(
    startDate = this.props.startDate,
    seconds: number | null = this.props.seconds,
  ): number {
    if (!seconds) {
      const todaysDate = new Date(new Date().toISOString());
      if (startDate) {
        const timeStart = new Date(startDate);
        const difference = Math.abs(timeStart.getTime() - todaysDate.getTime());
        return difference;
      }
    } else {
      return seconds * 1000;
    }
    return 0;
  }

  initTimer = (): void => {
    const { seconds } = this.props;
    const startingTime = seconds ? seconds * 1000 : this.secondsSinceDate();

    this.setState({
      duration: startingTime,
    });
  };

  startTimer = () => {
    let startTime = this.secondsSinceDate();
    if (this.props.timerOn) {
      const intervalId = setInterval(() => {
        const updatedTime = (startTime += 1000);
        this.setState({
          duration: updatedTime,
        });
      }, 1000);
      // TS infers intervalId as NodeJS.Timeout instead of window.timeout,
      // which it thinks is incompatible with number. This happens in some IDEs and not others,
      // for unknown reasons.
      this.timer = intervalId as any;
    }
  };

  stopTimer = () => {
    clearInterval(this.timer);
    this.setState({ on: false });
  };

  render() {
    const { duration } = this.state;
    return (
      <span>
        {duration < 1000
          ? '0:00'
          : prettyMs(duration, { secondsDecimalDigits: 0, colonNotation: true })}
      </span>
    );
  }
}
