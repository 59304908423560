import { useState, useEffect } from 'react';
import { getInspectionProduct } from '../../../../../services/catalog/services';
import { InspectionProduct } from '../../../../../services/catalog/types';

interface UseInspectionProduct {
  product?: InspectionProduct;
  error?: Error;
}

export const useInspectionProduct = (
  productVariantId: number | undefined,
): UseInspectionProduct => {
  const [product, setProduct] = useState<InspectionProduct | undefined>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (productVariantId) {
      getInspectionProduct(productVariantId, {
        onSuccess: product => {
          if (product) {
            setProduct(product);
          }
        },
        catchFailure: error => {
          if (error.response?.status === 404) {
            setError(
              new Error(
                `Unable to find a product in Item Catalog with variant ID ${productVariantId}`,
              ),
            );
          } else {
            setError(error);
          }
        },
      });
    }
  }, [productVariantId]);

  return {
    product,
    error,
  };
};
