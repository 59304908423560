import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.tooltip + 1,
      color: '#fff',
    },
  }),
);

interface LoaderOverlayProps {
  loading: boolean;
}

export const LoaderOverlay: React.FC<LoaderOverlayProps> = ({ loading }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
