import React from 'react';
import { Accordion, AccordionSummary } from '@material-ui/core';
import VariantTitle from '../VariantTitle';
import { Product, ProductVariant } from '../../../services/catalog/types';

const ReorderableVariantAccordion: React.FC<{
  product: Product;
  productVariant: ProductVariant;
}> = ({ product, productVariant }) => {
  const { id, sku } = productVariant;

  return (
    <Accordion expanded={false} style={{ width: '100%' }}>
      <AccordionSummary aria-controls={`panel-${id}-content`} id={`panel-${id}-header`}>
        <VariantTitle
          sku={sku}
          imageUrl={productVariant.images?.[0]?.imageUrl}
          product={product}
          productVariant={productVariant}
        />
      </AccordionSummary>
    </Accordion>
  );
};

export default ReorderableVariantAccordion;
