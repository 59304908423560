import React, { FC } from 'react';
import {
  FormControl,
  TextField as MaterialTextField,
  FormHelperText,
  Button,
} from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { useField } from 'formik';
import styled, { css } from 'styled-components';
import { CopyAndSaveFieldValueToAllVariants } from '../../services/catalog/types';

export interface TextInputProps {
  helperText?: string;
  label: string;
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
  multiline?: boolean;
  rows?: number;
  highlight?: boolean;
  fullWidth?: boolean;
  copyAndSaveFieldValueToAllVariants?: CopyAndSaveFieldValueToAllVariants;
}

type InputProps = TextInputProps & TextFieldProps & { name: string };

export const sharedInputStyles = css`
  width: 275px;
  margin: 5px;
`;

export const CopyAndSaveButton = styled(Button)`
  &&& {
    text-transform: none;
  }
`;

export const FieldWrapper = styled(FormControl).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['highlight', 'fullWidth'].includes(prop) && defaultValidatorFn(prop),
})<{ highlight?: boolean; fullWidth?: boolean }>`
  &.MuiFormControl-root {
    ${sharedInputStyles}
    ${({ fullWidth }) => (fullWidth ? 'width: 100%;' : '')}
  }

  &.MuiFormControl-root fieldset {
    ${({ highlight }) => (!!highlight ? 'border-color: #ff9800' : '')};
  }
`;

const TextField = styled(MaterialTextField)`
  .MuiInputBase-inputMultiline {
    resize: ${({ multiline }) => (multiline ? 'vertical' : 'none')};
  }
`;

const FormikTextInput: FC<InputProps> = ({
  name,
  helperText,
  label,
  highlight,
  fullWidth,
  type,
  copyAndSaveFieldValueToAllVariants,
  placeholder,
  rows,
  ...rest
}) => {
  const [field, meta] = useField(name);

  return (
    <FieldWrapper highlight={highlight} fullWidth={fullWidth}>
      <label>{label}</label>
      <TextField
        style={{ backgroundColor: '#fff' }}
        variant="outlined"
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error}
        {...field}
        value={typeof field.value === 'number' ? field.value : field.value || ''}
        // We want all text fields to be expandable,
        // not removing prop to override this if we want to disable that for some fields
        // Textareas cannot be numbers so if it's number type make it textfield
        multiline={type === 'number' ? false : true}
        type={type}
        placeholder={placeholder}
        onWheel={
          type === 'number'
            ? event => {
                if (event.target instanceof HTMLElement) event.target.blur();
              }
            : undefined
        }
        rows={rows}
        {...rest}
      />
      <FormHelperText>{helperText}</FormHelperText>
      {!!copyAndSaveFieldValueToAllVariants && (
        <CopyAndSaveButton
          size="small"
          variant="contained"
          onClick={() => copyAndSaveFieldValueToAllVariants(name, field.value)}
        >
          Copy and save value to all variants
        </CopyAndSaveButton>
      )}
    </FieldWrapper>
  );
};

export default FormikTextInput;
