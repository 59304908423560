import axios from '../../utils/axios';
import { API_URL } from '../../constants';

interface State {
  secret: string;
  newPassword: string;
  newPasswordConfirm: string;
  error: string;
}

const initialState: State = {
  secret: '',
  newPassword: '',
  newPasswordConfirm: '',
  error: '',
};

const validate = (secret: string, newPassword: string, newPasswordConfirm: string) => {
  if (!secret || !newPassword || !newPasswordConfirm) {
    return 'All fields are required';
  }
  if (newPasswordConfirm !== newPassword) {
    return 'The passwords do not match';
  }
  if (newPassword.length < 8) {
    return 'Passwords must be atleast 8 characters';
  }
  return 'Validated';
};

const setPassword = async (secret: string, newPassword: string, newPasswordConfirm: string) => {
  const url = `${API_URL}/account/changePassword`;
  const data = {
    password: secret,
    newPassword: newPassword,
    newPasswordConfirm: newPasswordConfirm,
  };
  await axios.post(url, data);
};

export { initialState, validate, setPassword };
