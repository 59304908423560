import React from 'react';
import { useFormikContext } from 'formik';
import { FEInspectionCategory } from '../../../../../../services/service/types';
import { EditablePointCategoryTable } from './EditablePointCategoryTable';
import { useWarnBeforeUnload } from '../useWarnBeforeUnload';
import { FormikPointsState } from '../../../_shared/types';

interface EditablePointsProps {
  categorizedPoints: FEInspectionCategory[];
  handleDataChange(dirty: boolean): void;
}

export const EditablePoints: React.FC<EditablePointsProps> = ({
  categorizedPoints,
  handleDataChange,
}) => {
  const { dirty } = useFormikContext<FormikPointsState>();
  useWarnBeforeUnload(dirty);
  handleDataChange(dirty);
  return (
    <>
      {categorizedPoints.map(category => (
        <EditablePointCategoryTable key={category.category} category={category} />
      ))}
    </>
  );
};
