/* eslint-disable no-alert */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { API_URL } from '../../../constants';
import axios from '../../../utils/axios';
import GenericModal from '../../../components/modals/genericFormModal';

const statusStyle = {
  color: '#fff',
  width: '25%',
  textAlign: 'center',
  height: '40px',
  lineHeight: '40px',
  minWidth: '150px',
  padding: '0 5px',
  fontFamily: 'GT America Condensed Bold, sans-serif',
  fontSize: '12px',
  letterSpacing: '1px',
};
class StatusDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submissionId: 0,
      buyers: [],
      buyerId: '',
      declineReason: '',
      declineReasonId: 0,
      declineMessage: '',
      declineReasons: [],
      displayOfferModal: true,
      displayDeclineModal: false,
      displayCompleteModal: false,
      updatedMessage: '',
      error: false,
      success: false,
      inputError: {
        buyerId: false,
        salePrice: false,
        status: false,
        declineReason: false,
      },
      // AUTO PO
      autoPo: {
        modalAutoPOToggled: false,
        brands: [],
        formErrorMsg: '',
        error: false,
        errorMsg: '',
        autopoLoading: false,
        trackingNumber: '',
        submissionId: '',
        buyerEmail: '',
        email: '',
        paypalEmail: '',
        phone: '',
        name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        categoryId: -1,
        year: '',
        size: '',
        wheelSize: '',
        brand: '',
        model: '',
        msrp: '',
        title: '',
        style: '',
        pipeline: '',
        serial: '',
        cost: '',
        expectedValue: '',
        classification: '',
        success: false,
        successMsg: '',
        dealer: {},
        sku: '',
        svPoStatus: '',
        source: 0,
      },
    };
  }

  setOfferValues = api => {
    const { offerStatus } = this.props;
    const { buyers } = this.state;
    const currentBuyer = buyers.find(buyer => offerStatus.buyerId === buyer.buyerId);
    api.setValues({
      offer: offerStatus.offerPrice,
      salePrice: offerStatus.expectedPrice,
      buyerId: currentBuyer ? currentBuyer.buyerId : '',
    });
  };

  renderStatusDisplay = status => {
    switch (status) {
      case 'Initiated':
      case 'Resubmitted':
        return (
          <span style={{ ...statusStyle, backgroundColor: '#086BE2' }}>{status.toUpperCase()}</span>
        );
      case 'Completed':
        return (
          <span style={{ ...statusStyle, backgroundColor: '#00C160' }}>{status.toUpperCase()}</span>
        );
      case 'Accepted':
        return (
          <span style={{ ...statusStyle, backgroundColor: '#00C160' }}>{status.toUpperCase()}</span>
        );
      case 'Declined(user)':
      case 'Declined(buyer)':
      case 'Expired':
        return (
          <span style={{ ...statusStyle, backgroundColor: '#ed1c24' }}>{status.toUpperCase()}</span>
        );
      case 'Proposed':
        return <span style={{ ...statusStyle, backgroundColor: '#F7941D' }}>SENT</span>;
      default:
        return <span style={{ ...statusStyle, backgroundColor: 'gray' }}>{status}</span>;
    }
  };

  renderSignedDeclarationDisplay = signature =>
    signature ? (
      <span style={{ ...statusStyle, backgroundColor: '#00C160' }}>SIGNED</span>
    ) : (
      <span style={{ ...statusStyle, backgroundColor: '#ed1c24' }}>UNSIGNED</span>
    );

  completeForm = () => (
    <form className="offer-decline-form">
      <span className="qbp-input-label-wrapper offer-input-label-wrap">
        <label className="label">External Notes: </label>
        <textarea
          className="text-input"
          name="externalNote"
          id="externalNote"
          value={this.props.externalNote}
        />
      </span>
      <span className="qbp-input-label-wrapper offer-input-label-wrap">
        <label className="label">Internal Notes:</label>
        <textarea
          placeholder="Not visible to seller and shop"
          className="text-input"
          name="internalNote"
          id="internalNote"
          value={this.props.internalNote}
        />
      </span>
      {!this.props.offerStatus.digitalSignature && (
        <p className="doo-error">Declaration of ownership not yet signed</p>
      )}
    </form>
  );

  declineForm = () => (
    <form className="offer-decline-form">
      <span className="qbp-input-label-wrapper offer-input-label-wrap">
        <label className="label">Assigned Buyer Name: </label>
        <select className="text-input" id="buyerId" name="buyerId" value={this.state.buyerId}>
          <option value="" disabled={this.state.buyerId}>
            Select Buyer Name
          </option>
          {this.state.buyers.map(x => (
            <option key={x.buyer_id} value={x.buyer_id}>
              {x.name}
            </option>
          ))}
        </select>
      </span>
      <span className="qbp-input-label-wrapper offer-input-label-wrap">
        <label className="label">Decline Reason: </label>
        <select
          onChange={this.updateReason}
          className="text-input"
          name="declineReason"
          id="declineReason"
          value={this.state.declineReasonId}
        >
          <option value="" disabled={this.state.declineReasonId}>
            Select Reason
          </option>
          {this.state.declineReasons.map(x => (
            <option key={x.id} value={x.id}>
              {x.short_name}
            </option>
          ))}
        </select>
      </span>
      <span className="qbp-input-label-wrapper offer-input-label-wrap">
        <label className="label">Notes to Seller:</label>
        <textarea
          placeholder="Ex. We're high in stock, come back in 3 months"
          className="text-input"
          id="expandedReason"
          value={this.state.declineMessage}
          name="declineMessage"
        />
      </span>
    </form>
  );

  updateForm = () => (
    <form className="offer-decline-form" onSubmit={this.submitForm}>
      <span className="qbp-input-label-wrapper offer-input-label-wrap">
        <label className="label">Assigned Buyer Name: </label>
        <select
          className="select-input text-input"
          name="buyerId"
          id="buyerId"
          value={this.state.buyerId}
          onChange={this.handleChange}
        >
          <option value="" disabled={this.state.buyerId}>
            Select Buyer
          </option>
          {this.state.buyers.map(buyer => (
            <option key={buyer.buyer_id} value={buyer.buyer_id}>
              {buyer.name}
            </option>
          ))}
        </select>
      </span>
      <span className="qbp-input-label-wrapper offer-input-label-wrap">
        <label className="label">Expected Sale Price:</label>
        <input
          type="text"
          placeholder="$0.00"
          className="text-input"
          name="salePrice"
          id="salePrice"
          onChange={this.props.handleChangeExpected}
          value={this.props.salePrice}
          onBlur={this.handleExpectedSaleBlur}
        />
      </span>
      <span className="qbp-input-label-wrapper offer-input-label-wrap">
        <label className="label">Offer Price:</label>
        <input
          type="text"
          placeholder="$ 0.00"
          className="text-input"
          name="offer"
          onChange={this.props.handleModalChange}
          id="offer"
          value={isNaN(this.props.offer) || Math.sign(this.props.offer) < 1 ? 0 : this.props.offer}
        />
      </span>
      <span className="qbp-input-label-wrapper offer-input-label-wrap">
        <label className="label">External Notes: </label>
        <textarea
          id="externalNote"
          className="text-input"
          name="externalNote"
          onChange={this.props.handleModalChange}
          value={this.props.externalNote}
        />
      </span>
      <span className="qbp-input-label-wrapper offer-input-label-wrap">
        <label className="label">Internal Notes:</label>
        <textarea
          id="internalNote"
          className="text-input"
          name="internalNote"
          placeholder="Not visible to seller and shop"
          onChange={this.props.handleModalChange}
          value={this.props.internalNote}
        />
      </span>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <button
          type="submit"
          name="save_only"
          className="submit-button"
          onClick={e => this.submitForm(e, 'save_only')}
        >
          JUST SAVE
        </button>
        <button
          type="submit"
          name="save_send"
          className="submit-button"
          onClick={e => this.submitForm(e, 'save_send')}
        >
          SAVE & SEND OFFER
        </button>
      </div>
    </form>
  );

  render() {
    const {
      displayOfferModal,
      displayDeclineModal,
      displayCompleteModal,
      error,
      updatedMessage,
    } = this.state;
    const { offerStatus, submissionId } = this.props;
    return (
      <fieldset className="status-form-wrap list-wrap partner-detail-section">
        <legend>Submission: {submissionId}</legend>
        <div className="status-wrap">
          <div className="form-wrap">
            <span className="input-label-wrap-status offer-display">
              <label className="label">OFFER STATUS:</label>
              {this.renderStatusDisplay(offerStatus.status)}
            </span>
            <span className="input-label-wrap-status doo-display">
              <label className="label doo-label">DECLARATION OF OWNERSHIP:</label>
              {this.renderSignedDeclarationDisplay(offerStatus.digitalSignature)}
            </span>
          </div>
          <div className="form-wrap">
            <span className="input-label-wrapper underline offer-display">
              <label className="label">Assigned Buyer: </label>
              <p>{offerStatus.buyer || 'Unassigned'}</p>
            </span>
            <span className="input-label-wrapper underline doo-display">
              <label className="label">Original Offer Date: </label>
              <p>{offerStatus.firstOfferDate}</p>
            </span>
          </div>

          <div className="form-wrap">
            <span className="input-label-wrapper underline offer-display">
              <label className="label">Offer Price: </label>
              <p>${offerStatus.offerPrice || '0.00'}</p>
            </span>
            <span className="input-label-wrapper underline doo-display">
              <label className="label">Expected Sale Price: </label>
              <p>${offerStatus.expectedPrice || '0.00'}</p>
            </span>
          </div>
          <div className="form-wrap">
            <span className="input-label-wrapper underline offer-display">
              <label className="label">Submission Date:</label>
              <p>{offerStatus.created}</p>
            </span>
            <span className="input-label-wrapper underline doo-display">
              <label className="label">Preferred Shop Contact:</label>
              <p>{offerStatus.preferredEmail ? offerStatus.preferredEmail : 'N/A'}</p>
            </span>
          </div>
          <div style={{ textAlign: 'left' }}>
            <label className="label">Notes:</label>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default withRouter(StatusDisplay);

StatusDisplay.propTypes = {
  handleModalChange: PropTypes.func.isRequired,
  handleChangeExpected: PropTypes.func.isRequired,
  buyerId: PropTypes.number.isRequired,
  salePrice: PropTypes.number.isRequired,
  offer: PropTypes.number.isRequired,
  externalNote: PropTypes.string.isRequired,
  internalNote: PropTypes.string.isRequired,
};
