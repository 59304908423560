import React from 'react';
import PropTypes from 'prop-types';
import driveSideLogo from '../../images/drive-side-photo.svg';
import nonDriveSideLogo from '../../images/non-drive-side-photo.svg';
import licenseSuccess from '../../images/license-upload-success.svg';

const ImageUpload = props => (
  <div className={`dropzone ${props.photoHover ? 'hovered' : ''}`}>
    {props.loadStatus === 100 && (
      <button
        className="close-button"
        style={{ position: 'absolute', right: 0 }}
        type="button"
        onClick={props.clearImage}
      >
        X
      </button>
    )}
    <input
      onDrop={props.handleImageDrop}
      onChange={props.handleImageDrop}
      onDragOver={props.handleDrag}
      onDragEnter={props.dragIn}
      onDragLeave={props.dragOut}
      name={props.name}
      className="file-input"
      type="file"
      multiple={false}
    />
    {props.loadStatus < 100 && (
      <img src={props.name === 'photo1' ? driveSideLogo : nonDriveSideLogo} alt="driveside" />
    )}
    {props.name !== 'image' && props.photoUrl && props.loadStatus === 100 && (
      <img src={props.photoUrl} alt="user upload" className="image-preview" />
    )}
    {props.name === 'image' && props.loadStatus === 100 && (
      <img
        src={licenseSuccess}
        alt="user id upload success"
        className="image-preview"
        style={{ width: '30%', minWidth: '150px' }}
      />
    )}
    <span className="progress-wrapper">
      <div className="progress-bar">
        <div className="inner-progress-bar" style={{ width: `${props.loadStatus}%` }} />
      </div>
    </span>
  </div>
);

ImageUpload.propTypes = {
  photoHover: PropTypes.bool.isRequired,
  handleImageDrop: PropTypes.func.isRequired,
  handleDrag: PropTypes.func.isRequired,
  dragIn: PropTypes.func.isRequired,
  dragOut: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  loadStatus: PropTypes.number.isRequired,
  photoUrl: PropTypes.string.isRequired,
  clearImage: PropTypes.func.isRequired,
};

export default ImageUpload;
