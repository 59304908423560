import { useState, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import * as serviceService from '../../../services/service/services';
import { Bin, WIPState, Location } from '../../../services/service/types';

export const OPERATIONS_LOCATION_ID = 121; // 'LOUISVILLE : OPERATIONS'

export function useRelocate({
  typeaheadVal,
  locationId,
}: {
  typeaheadVal?: string;
  locationId?: number;
}) {
  const [binsForLocation, setBinsForLocation] = useState<Bin[]>([]);
  const [locations, setLocations] = useState<Location[]>();
  const [wipStates, setWipStates] = useState<WIPState[]>();
  const [error, setError] = useState<string>();

  // fetch enum data that won't change based on locationId
  useEffect(() => {
    serviceService.getWIPStates({
      onSuccess: res => {
        setWipStates(res);
      },
      catchFailure: err => setError(err?.message ?? 'Unable to fetch WIP states'),
    });
    serviceService.getServiceLocations({
      onSuccess: res => {
        setLocations(res);
      },
      catchFailure: err => setError(err?.message ?? 'Unable to fetch service locations'),
    });
  }, []);

  // (re)fetch bin data based on locationId and typeahead
  // only fetches when typeaheadVal is truthy to prevent loading all bins for a location
  const refetchBins = useMemo(
    () =>
      debounce(({ locationId, typeaheadVal, setBinsForLocation, setError }) => {
        if (typeaheadVal && locationId) {
          serviceService.getBinsByLocation(locationId, typeaheadVal, {
            onSuccess: res => setBinsForLocation(res),
            catchFailure: err =>
              setError(err?.message ?? 'Unable to fetch bins for selected location'),
          });
        }
      }, 500),
    [],
  );
  useEffect(() => {
    refetchBins({ locationId, typeaheadVal, setBinsForLocation, setError });
  }, [locationId, typeaheadVal, refetchBins]);

  return {
    locations,
    binsForLocation,
    wipStates,
    error,
  };
}
