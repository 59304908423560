import { SET_PARTS_METADATA } from '../actions/types';

export default (
  state = {
    identifierTypes: [],
    sources: [],
    statuses: [],
  },
  action,
) => {
  switch (action.type) {
    case SET_PARTS_METADATA: {
      return {
        ...state,
        identifierTypes: action.payload.types,
        statuses: action.payload.statuses,
        sources: action.payload.sources,
      };
    }
    default: {
      return state;
    }
  }
};
