import React, { useState } from 'react';
import { Inspection, ServicePlanOperationTypes } from '../../../../../services/service/types';
import { Collapse, Box } from '@material-ui/core';
import { Button } from '../../../../../components/library/Button';
import { ServicePlanOperator } from './ServicePlanOperator';
import { SingleItemProcurement } from '../../../../../services/catalog/types';

export type ServicePlanOperator = {
  id: number;
  servicePlanId: number;
  servicePlanOperationTypeId: number;
  inspection: Inspection;
  userId: number;
  servicePlanOperationType: ServicePlanOperationTypes;
  operationDt: string | null;
};

type ServicePlanOperatorsListProps = {
  operators?: ServicePlanOperator[];
  itemProcurement?: SingleItemProcurement;
};

export const ServicePlanOperatorsList = (props: ServicePlanOperatorsListProps) => {
  const { itemProcurement, operators } = props;

  const [showCollapse, setShowCollapse] = useState(false);

  const orderedOperators = operators?.sort((a, b) => {
    return new Date(b.operationDt ?? 0).getTime() - new Date(a.operationDt ?? 0).getTime();
  });

  const allOperators = itemProcurement?.receivedByUserId
    ? [
        ...(orderedOperators || []),
        {
          operationDt: itemProcurement.receivedAt,
          servicePlanOperationTypeId: 0,
          userId: itemProcurement.receivedByUserId,
        } as ServicePlanOperator,
      ]
    : orderedOperators;

  if (!allOperators) return null;

  const recentOperators = allOperators.slice(0, 2);
  const operatorsToBeCollapsed = allOperators.slice(2);

  return (
    <>
      {recentOperators && (
        <Box display="flex" flexDirection="column" alignItems="end">
          {recentOperators.map(operator => (
            <ServicePlanOperator
              operatorId={operator.userId}
              operationDt={operator.operationDt}
              servicePlanOperationTypeId={operator.servicePlanOperationTypeId}
              key={operator.id}
            />
          ))}
        </Box>
      )}
      {operatorsToBeCollapsed && (
        <Collapse in={showCollapse}>
          <Box display="flex" flexDirection="column" alignItems="end">
            {operatorsToBeCollapsed.map(operator => (
              <ServicePlanOperator
                operatorId={operator.userId}
                operationDt={operator.operationDt}
                servicePlanOperationTypeId={operator.servicePlanOperationTypeId}
                key={operator.id}
              />
            ))}
          </Box>
        </Collapse>
      )}
      {operatorsToBeCollapsed.length > 0 && (
        <Button
          ordinality="primary"
          onClick={() => setShowCollapse(showCollapse => !showCollapse)}
          size="small"
          variant="text"
        >
          {showCollapse ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </>
  );
};
