import { GET_CUSTOMER_FILTERS, GET_CUSTOMER_PAGE, GET_CUSTOMER_SORT } from '../actions/types';

export default (
  state = {
    filters: {
      searchTerm: '',
      customers: [],
    },
    sort: [
      {
        id: 'created',
        desc: true,
        page: 0,
      },
    ],
    page: 0,
  },
  action,
) => {
  switch (action.type) {
    case GET_CUSTOMER_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case GET_CUSTOMER_SORT: {
      return {
        ...state,
        sort: action.payload,
      };
    }
    case GET_CUSTOMER_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    default:
      return state;
  }
};
