import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import LabelValuePairDisplay from './LabelValuePairDisplay';

const extractTireTypeAndDescription = value => {
  // tireType may eventually be broken out into its own DB field
  // for now we're mostly concerned with ensuring the tireType makes it in front of buyers if provided
  // to avoid duplication check for it and remove it if found
  const regex = /([| ]+)?tire type: (Tubeless|Tubular|Clincher|I\'m not sure)/;
  const description = value?.replace(regex, '');
  const tireType = value.match(regex)?.[2];
  return { description, tireType };
};

const GearInfo = props => {
  const { make, imageGallery, type, displayModal, itemIndex, model } = props;
  const { description, tireType } = extractTireTypeAndDescription(type);
  return (
    <>
      <Typography variant="h6">Item # {itemIndex + 1}</Typography>
      <Grid container direction="row" justifyContent="space-evenly">
        <Grid container item xs={4} direction="column">
          <LabelValuePairDisplay
            labelValuePairs={[
              { label: 'Tire Type', value: tireType },
              { label: 'Brand', value: make },
              { label: 'Model', value: model },
              { label: 'Description', value: description },
            ]}
            gridSize={{ label: 4, value: 8 }}
          />
        </Grid>
        <Grid item xs={8} className="image-wrap">
          <Carousel showThumbs={false}>
            {imageGallery.map((image, idx) => (
              <div key={image.url} onClick={() => displayModal(idx, itemIndex)}>
                <img alt={`gear image ${idx}`} className="bike-image" src={image.url} />
              </div>
            ))}
          </Carousel>
        </Grid>
      </Grid>
    </>
  );
};

GearInfo.propTypes = {
  make: PropTypes.string.isRequired,
  imageGallery: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  displayModal: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
  msrp: PropTypes.any,
  model: PropTypes.any,
  type: PropTypes.any,
};

export default GearInfo;
