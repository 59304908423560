import { createContext, Context } from 'react';
import { ErrorBus } from './useErrorBus';
import { LoadingBus } from './useLoadingBus';

const getErrorLogger = (funcName: string) => () =>
  console.error(`PageStateContext does not have a set ${funcName} function.`);

class PageStateContext {
  errorBus: ErrorBus;
  loadingBus: LoadingBus;
  showSuccess: () => void;
  setSuccessMessage: (value: string | undefined) => void;

  constructor(init?: Partial<PageStateContext>) {
    this.errorBus = init?.errorBus ?? new ErrorBus();
    this.loadingBus = init?.loadingBus ?? new LoadingBus();
    this.showSuccess = init?.showSuccess ?? getErrorLogger('showSuccess');
    this.setSuccessMessage = init?.setSuccessMessage ?? getErrorLogger('setSuccessMessage');
  }
}

export const pageStateContext: Context<PageStateContext> = createContext(new PageStateContext());
