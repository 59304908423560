//@ts-expect-error because typescript-eslint now requires a description; I don't actually know why you should expect an error.  Probably something to do with us not wanting to import the entire deprecated/enormous moment library?
import moment from 'moment-business-time';

moment.locale('en', {
  workinghours: {
    0: null,
    1: ['00:00:00', '24:00:00'],
    2: ['00:00:00', '24:00:00'],
    3: ['00:00:00', '24:00:00'],
    4: ['00:00:00', '24:00:00'],
    5: ['00:00:00', '24:00:00'],
    6: null,
  },
  holidays: [
    // New Year
    '*-01-01',
    // Independence Day
    '*-07-04',
    // Memorial Day
    '2023-05-29',
    '2024-05-27',
    '2025-05-26',
    // Labor Day
    '2022-09-05',
    '2023-09-04',
    '2024-09-02',
    '2025-09-01',
    // Thanksgiving
    '2022-11-24',
    '2023-11-23',
    '2024-11-28',
    '2025-11-27',
    // Christmas
    '*-12-25',
  ],
});

// moment accepts any garbage input string you give it but defaults to Date.now().
// ideally we would throw an error on that kind of input and write tests for that behavior,
// but for now we'll just have some wrong date math if moment receives something invalid.
export const calculateHoursSinceDate = (date: string) => {
  return moment(new Date()).workingDiff(moment(date), 'hours', true);
};

export const convertHoursToUnitsOfTime = (rawHours: number) => {
  if (rawHours < 0 || isNaN(rawHours)) {
    throw new Error(`convertHoursToUnitsOfTime received invalid input: ${rawHours}`);
  }

  const numberOfHours = Math.abs(rawHours);
  const days = Math.floor(numberOfHours / 24);
  const remainder = numberOfHours % 24;
  const hours = Math.floor(remainder);
  const minutes = Math.floor(60 * (remainder - hours));

  return { days, hours, minutes };
};
