import { getExportsForTesting } from '../../../../../testing/getExportsForTesting';
import {
  FormikPointsState,
  FormikPartsState,
  FormikServiceState,
  PartStatuses,
} from '../../_shared/types';
import { isPlaceholderId } from '../../_shared';
import { omit } from 'lodash';
import { InspectionPart } from '../../../../../services/service/types';
import { extractApiErrorMessage } from '../../../../utils';
import { updateInspection } from '../../../../../services/service/services';

/* POINTS */

const preparePointsValuesForSave = (values: FormikPointsState['points']) => {
  // only persist the points with data
  return Object.values(values)
    .filter(point => !!point?.gradeId)
    .map(dataPoint => ({
      ...dataPoint,
      gradeId: dataPoint.gradeId || null,
      failRemedyId: dataPoint.failRemedyId || null,
      note: dataPoint.note || null,
    }));
};
const savePoints = async (
  data: {
    values: FormikPointsState;
    inspectionId: number;
    statusId: number;
    hasUnsavedData: boolean;
  },
  callbacks: {
    setLoading: (isLoading: boolean) => void;
    setError: (error: Error | undefined) => void;
    handleSuccess: () => void;
  },
) => {
  callbacks.setLoading(true);
  const points = preparePointsValuesForSave(data.values.points);

  try {
    const response = await updateInspection(data.inspectionId, {
      points,
      notes: data.values.notes,
      statusId: data.statusId,
      hasUpdatedInspectionData: data.hasUnsavedData, // account for changes made to points when service plan is in INSPECTION STARTED status
    });

    if (response && response.data) {
      callbacks.setError(undefined);
      callbacks.handleSuccess();
    } else {
      throw new Error('Saving point values unexpectedly returned no response data');
    }
  } catch (error) {
    callbacks.setError(new Error(extractApiErrorMessage(error)));
  } finally {
    callbacks.setLoading(false);
  }
};

/* PARTS */

const preparePartsValuesForSave = (values: FormikPartsState) => {
  // only persist the points with data
  return Object.values(values).map(val => (isPlaceholderId(val.id) ? omit(val, ['id']) : val));
};
const saveParts = async (
  data: {
    values: FormikPartsState;
    inspectionId: number;
    statusId: number;
    hasUnsavedData: boolean;
  },
  callbacks: {
    setLoading: (isLoading: boolean) => void;
    setError: (error: Error | undefined) => void;
    handleSuccess: () => void;
  },
) => {
  callbacks.setLoading(true);
  const parts = preparePartsValuesForSave(data.values);

  try {
    const response = await updateInspection(data.inspectionId, {
      parts,
      statusId: data.statusId,
      hasUpdatedPartsData: data.hasUnsavedData,
    });
    if (response && response.data) {
      callbacks.setError(undefined);
      callbacks.handleSuccess();
    } else {
      throw new Error('Saving part values unexpectedly returned no response data');
    }
  } catch (error) {
    callbacks.setError(new Error(extractApiErrorMessage(error)));
  } finally {
    callbacks.setLoading(false);
  }
};

/* SERVICE */

// todo: unit test this method
const prepareServiceDataForSave = (values: FormikServiceState, parts: InspectionPart[]) => {
  const { mechanicNotes, points, deletedPartIds } = values;

  const partsToDelete = deletedPartIds.map(id => ({ id, active: false }));
  const partsToInstall = Object.entries(points)
    .filter(([_, complete]) => complete)
    .flatMap(([pointTypeId]) =>
      parts
        .filter(part => part.inspectionPointTypeId === parseInt(pointTypeId, 10))
        .map(part => ({ id: part.id, statusId: PartStatuses.installed })),
    );

  return {
    mechanicNotes,
    points: Object.entries(points).map(([pointTypeId, complete]) => ({
      inspectionPointTypeId: parseInt(pointTypeId, 10),
      complete,
    })),
    parts: [...partsToInstall, ...partsToDelete],
  };
};

const saveServiceData = async (
  data: {
    values: FormikServiceState;
    parts: InspectionPart[];
    inspectionId: number;
    statusId: number;
    hasUnsavedData: boolean;
  },
  callbacks: {
    setLoading: (isLoading: boolean) => void;
    setError: (error: Error | undefined) => void;
    handleSuccess: () => void;
  },
) => {
  callbacks.setLoading(true);
  const serviceData = prepareServiceDataForSave(data.values, data.parts);
  const payload = {
    ...serviceData,
    statusId: data.statusId,
    hasUpdatedServiceData: data.hasUnsavedData,
  };
  try {
    const response = await updateInspection(data.inspectionId, payload);
    if (response && response.data) {
      callbacks.setError(undefined);
      callbacks.handleSuccess();
    } else {
      throw new Error('Saving part values unexpectedly returned no response data');
    }
  } catch (error) {
    callbacks.setError(new Error(extractApiErrorMessage(error)));
  } finally {
    callbacks.setLoading(false);
  }
};

/* EXPORTS */

export { savePoints, saveParts, saveServiceData };

export const exportsForTesting = getExportsForTesting({
  preparePointsValuesForSave,
  preparePartsValuesForSave,
});
