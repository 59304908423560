export enum Status {
  STARTED = 'started',
  PAUSED = 'paused',
  COMPLETED = 'completed',
}

export interface SearchOrCreateCycleTiming {
  station: string;
  sku?: string;
  sybSubmissionId?: number;
  partnerSubmissionId?: number;
  forceNewForExistingCompletedTiming?: boolean;
}

export type AddNewItemToCycleTiming = Omit<SearchOrCreateCycleTiming, 'station'>;

export interface CycleTimingItem {
  sku?: string | null;
  sybSubmissionId?: number | null;
  partnerSubmissionId?: number | null;
  id: number;
  title: string;
  productId?: number; // only exists for sku items
  createdByUserId: number;
}

export interface CycleTiming {
  id: number;
  startedAtUtc: string;
  stationId: string;
  createdByUserId: number;
  modifiedByUserId: number;
  status: Status;
  isTraining: boolean;
  durationSeconds?: number | null;
  items: CycleTimingItem[];
}
