import React, { FC, useMemo } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FieldWrapper, CopyAndSaveButton } from './FormikTextInput';
import { useField } from 'formik';
import { CopyAndSaveFieldValueToAllVariants } from '../../services/catalog/types';

export interface AutoCompleteProps {
  options: any[];
  label: string;
  placeholder?: string;
  autoFocus?: boolean;
  labelKey: string;
  disabled?: boolean;
  highlight?: boolean;
  OptionComponent?: React.ComponentType<any>;
  AdornmentComponent?: React.ComponentType<any>;
  fullWidth?: boolean;
  copyAndSaveFieldValueToAllVariants?: CopyAndSaveFieldValueToAllVariants;
  SubComponent?: React.ComponentType<any>;
  multiple?: boolean;
  filterSelectedOptions?: boolean;
}

const FormikAutoCompleteField: FC<AutoCompleteProps & { name: string }> = ({
  labelKey,
  options,
  OptionComponent,
  AdornmentComponent,
  placeholder,
  autoFocus,
  name,
  disabled,
  label,
  highlight,
  fullWidth,
  copyAndSaveFieldValueToAllVariants,
  SubComponent,
  multiple,
  filterSelectedOptions,
}) => {
  const [field, meta, { setValue, setTouched }] = useField(name);

  /**
   * NOTE: Material UI requires referential equality for controlled autocomplete components.
   * So we need to make sure the exact value/option pair match.
   */
  const [value, allOptions] = useMemo(() => {
    // if multiple, field value is an array
    if (multiple) return [field.value || [], options];

    const foundValue = options.find(o => o.id === field.value);
    if (foundValue) return [foundValue, options];

    // value exists but doesn't match the options we want the user to use
    // so make a temporary value that will go away when they pick a valid value
    if (field.value) {
      const newValue = { id: field.value, name: field.value };
      return [newValue, [newValue, ...options]];
    }

    // Need to create an "empty" value
    const emptyValue = { id: null, [labelKey]: '' };
    return [emptyValue, [emptyValue, ...options]];
  }, [options, field, labelKey, multiple]);

  return (
    <FieldWrapper highlight={highlight} fullWidth={fullWidth}>
      <label>{label}</label>
      <Autocomplete
        autoSelect
        options={allOptions}
        onFocus={() => setTouched(true)}
        onBlur={field.onBlur}
        getOptionLabel={option => option[labelKey]?.toString()}
        getOptionSelected={multiple ? (option, value) => option.id === value.id : undefined}
        renderOption={OptionComponent ? option => <OptionComponent {...option} /> : undefined}
        style={{ backgroundColor: '#fff' }}
        disabled={disabled}
        autoHighlight
        onChange={(_, option) => {
          setValue(multiple ? option : option?.id || '', true);
        }}
        value={value}
        renderInput={params => {
          if (AdornmentComponent) {
            params.InputProps.startAdornment = (
              <>
                <InputAdornment position="start">
                  <AdornmentComponent value={meta.value} />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            );
          }
          return (
            <TextField
              {...params}
              error={meta.touched && !!meta.error}
              placeholder={placeholder}
              helperText={meta.touched && meta.error}
              variant="outlined"
              autoFocus={autoFocus || false}
              onBlur={field.onBlur}
            />
          );
        }}
        multiple={multiple}
        filterSelectedOptions={filterSelectedOptions}
      />
      {!!copyAndSaveFieldValueToAllVariants && (
        <CopyAndSaveButton
          size="small"
          variant="contained"
          onClick={() => copyAndSaveFieldValueToAllVariants(name, field.value)}
        >
          {`Copy [${label}]'s value and save to all variants`}
        </CopyAndSaveButton>
      )}
      {!!SubComponent && <SubComponent />}
    </FieldWrapper>
  );
};
export default FormikAutoCompleteField;
