import React from 'react';
import PencilIcon from '@material-ui/icons/Edit';
import { Button } from '../../../../../components/library';

interface ServiceTicketModuleEditButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  moduleName: string;
}

export const ServiceTicketModuleEditButton: React.FC<ServiceTicketModuleEditButtonProps> = ({
  onClick,
  moduleName,
}) => (
  <Button ordinality="primary" variant="text" onClick={onClick}>
    <>
      <PencilIcon /> &ensp; Edit {moduleName}
    </>
  </Button>
);
