import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import * as service from '../services/catalog/services';
import * as types from './types';

import {
  CatalogSearchProductsState,
  ParamKeys,
  FilterParamKeys,
} from '../reducers/catalogSearchProductsReducer';

type RootState = {
  catalogSearchProducts: CatalogSearchProductsState;
};

export const clearCatalogProductSearchParams = () => ({
  type: types.CLEAR_CATALOG_SEARCH_PRODUCTS_PARAMS,
});

export const setCatalogProductsSearchParam = (
  key: ParamKeys,
  value?: string | number | boolean | null,
) => ({
  type: types.SET_CATALOG_SEARCH_PRODUCTS_PARAM,
  payload: { param: key, value },
});

export const setCatalogProductsSearchResults = (payload: {
  results: CatalogSearchProductsState['results'];
  total: number;
}) => ({
  type: types.SET_CATALOG_SEARCH_PRODUCTS_RESULTS,
  payload,
});

export const setCatalogProductsSearchLoading = (payload: boolean) => ({
  type: types.SET_CATALOG_SEARCH_PRODUCTS_LOADING,
  payload,
});

export const searchCatalogProducts = ({
  query: newSearchQuery,
  offset: newOffset,
  clearQuery = false,
}: {
  query?: string;
  offset?: number;
  clearQuery?: boolean;
} = {}): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch, getState) => {
  const {
    catalogSearchProducts: { params, loading },
  } = getState();

  if (loading) return;

  dispatch(setCatalogProductsSearchLoading(true));

  /**
   * Since we allow clearing query and searching for items it's hard to keep local state -> redux in sync
   * Ideally there is one source of truth for everything and not a mix and match.
   * This condition adds a flag to explicity allow empty query if since filters trigger doesn't have access to state without writing more ugly code
   */
  if (!newSearchQuery && clearQuery) {
    dispatch(setCatalogProductsSearchParam('query', newSearchQuery));
    dispatch(setCatalogProductsSearchParam('offset', 0));
  } else if (newSearchQuery && newSearchQuery !== params.query) {
    dispatch(setCatalogProductsSearchParam('query', newSearchQuery));
    dispatch(setCatalogProductsSearchParam('offset', 0));
  }

  if (newOffset !== undefined && newOffset !== params.offset) {
    dispatch(setCatalogProductsSearchParam('offset', newOffset));
  }

  // retrieve up to date params
  const {
    catalogSearchProducts: { params: currentParams },
  } = getState();

  service.fetchCatalogProductsSearch(currentParams, {
    onSuccess: response => {
      dispatch(
        setCatalogProductsSearchResults({ results: response.results, total: response.total }),
      );
      dispatch(setCatalogProductsSearchLoading(false));
    },
    catchFailure: () => {
      // TODO: handle errors/failures for search
      dispatch(setCatalogProductsSearchLoading(false));
    },
  });
};

export const updateCatalogProductsSearchFilters = (
  key: FilterParamKeys,
  value: string | number | boolean | null,
): ThunkAction<void, RootState, unknown, AnyAction> => dispatch => {
  // update changed filter
  dispatch(setCatalogProductsSearchParam(key, value));
  // reset pagination
  // TODO: may need to compare filter changes before doing this
  dispatch(setCatalogProductsSearchParam('offset', 0));
  // perform search
  dispatch(searchCatalogProducts());
};
