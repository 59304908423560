import React from 'react';
import '../styles/support.css';

const Support = () => (
  <div className="support-wrapper">
    <h2 className="form-section-title">Contact Us</h2>
    <div className="label-value-wrapper">
      <label className="label">Phone:</label>
      <span className="value">312-123-5555</span>
    </div>
    <div className="label-value-wrapper">
      <label className="label">Email:</label>
      <span className="value">partners@theproscloset.com</span>
    </div>
  </div>
);

export default Support;
