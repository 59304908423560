import { AnyAction } from 'redux';

import {
  SET_CATALOG_SEARCH_PRODUCTS_PARAM,
  SET_CATALOG_SEARCH_PRODUCTS_RESULTS,
  SET_CATALOG_SEARCH_PRODUCTS_LOADING,
  CLEAR_CATALOG_SEARCH_PRODUCTS_PARAMS,
} from '../actions/types';

import {
  ProductVariantSearchResult,
  FilterParams,
  ShopifyProductStatus,
} from '../services/catalog/types';

export interface CatalogSearchProductsParams {
  query: string;
  brandId: null | number;
  pipelineId: null | number;
  productCategoryId: null | number;
  statusFlagId: null | number;
  year: null | number;
  hasImages: null | boolean;
  shopifyStatus: null | ShopifyProductStatus;
  limit: number;
  offset: number;
}
export interface CatalogSearchProductsState {
  params: CatalogSearchProductsParams;
  results: ProductVariantSearchResult[];
  loading: boolean;
  total: number;
}

export type ParamKeys = keyof CatalogSearchProductsParams;
export type FilterParamKeys = keyof FilterParams;

const initialParams = {
  brandId: null,
  pipelineId: null,
  productCategoryId: null,
  statusFlagId: null,
  year: null,
  hasImages: null,
  shopifyStatus: null,
  limit: 20,
  offset: 0,
};

const initialState: CatalogSearchProductsState = {
  params: { query: '', ...initialParams },
  results: [],
  loading: false,
  total: 0,
};

export default function catalogSearchProductsReducer(
  state = initialState,
  action: AnyAction,
): CatalogSearchProductsState {
  const { type, payload } = action;
  switch (type) {
    case SET_CATALOG_SEARCH_PRODUCTS_PARAM: {
      return {
        ...state,
        params: {
          ...state.params,
          [payload.param]: payload.value,
        },
      };
    }
    case CLEAR_CATALOG_SEARCH_PRODUCTS_PARAMS: {
      return {
        ...state,
        params: {
          ...state.params,
          ...initialParams,
        },
      };
    }
    case SET_CATALOG_SEARCH_PRODUCTS_RESULTS: {
      return {
        ...state,
        results: payload.results,
        total: payload.total,
      };
    }
    case SET_CATALOG_SEARCH_PRODUCTS_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    default:
      return state;
  }
}
