import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import axios from '../../utils/axios';
import { FormatMoney } from '../../utils/helper';
import { API_URL } from '../../constants';
import DynamicForm from '../../components/dynamic_form';
import { getC360Filters, getC360Page } from '../../actions';
import { StripedDataGrid } from '../../styledComponents/datagrid';
import { GridPagination, GridToolbarContainer } from '@mui/x-data-grid';
import SearchIcon from '@material-ui/icons/Search';
import { Button, TextField } from '../../components/library';
import { Box, CircularProgress, Grid, InputAdornment } from '@mui/material';
import { PageWrapper } from '../../styledComponents/wrappers';

//TODO: [TP-840] DT - C360X - Improve Search Capability

const buildStyle = state => {
  return {
    border: `5px solid ${state === 'enabled' ? '#00c160' : 'red'}`,
    margin: 0,
    padding: 0,
    borderRadius: '50%',
    height: 0,
    width: 0,
    display: 'inline-block',
  };
};
class C360List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paginationModel: { page: 0, pageSize: 25 },
      searchNotInitiated: true,
      customers: [],
      filters: { searchTerm: '' },
      loading: false,
      totalCount: 0,
    };
  }

  componentDidMount() {
    this.fetchCustomers();
  }

  CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ justifyContent: 'right' }}>
        <GridPagination sx={{ mx: 4 }} />
      </GridToolbarContainer>
    );
  }

  columns = [
    {
      headerName: 'Name',
      field: 'name',
      renderCell: params => (
        <div>
          {params.value} <div style={buildStyle(params.row.state)} />
        </div>
      ),
      flex: 2,
    },
    {
      headerName: 'Email',
      field: 'email',
      flex: 3,
    },
    {
      headerName: 'State',
      field: 'state',
      flex: 1,
    },
    {
      headerName: 'Created',
      renderCell: params => {
        return new Date(params.value).toLocaleDateString('en-US', { timeZone: 'America/Denver' });
      },
      field: 'created_at',
      flex: 1,
    },
  ];

  fetchCustomers = debounce(async () => {
    this.setState({ loading: true });
    const url = `${API_URL}/customers/getShopifyCustomers`;

    try {
      const response = await axios.get(url, {
        params: {
          page: this.state.paginationModel.page,
          searchTerm: this.state.filters.searchTerm,
        },
      });
      if (response.status < 300) {
        this.setState({
          customers: response.data.customers,
          totalCount: response.data.count,
          pages: response.data.pages,
          searchNotInitiated: false,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  }, 600);

  // Filter functions

  handleFilterChange = e => {
    const { name, value } = e.target;
    this.setState({
      filters: { ...this.state.filters, [name]: value },
    });
    this.fetchCustomers();
  };

  handleFilterSubmit = e => {
    e.preventDefault();
    this.setState({
      paginationModel: { ...this.state.paginationModel, page: 0 },
    });
    this.fetchCustomers();
  };

  handleFilterClear = e => {
    e.preventDefault();
    this.setState({
      paginationModel: { ...this.state.paginationModel, page: 0 },
      filters: { ...this.state.filters, searchTerm: '' },
    });
  };
  // End of filter functions

  render() {
    return (
      <PageWrapper style={{ overflow: 'hidden' }}>
        <Grid container direction="row" spacing={2} justifyContent="space-evenly" sx={{ my: 2 }}>
          <Grid item xs={8}>
            <TextField
              size="small"
              style={{ flexGrow: 1, flexBasis: '250px' }}
              name="searchTerm"
              value={this.state.filters.searchTerm}
              onChange={this.handleFilterChange}
              placeholder="Search by dealer name/email"
              autoFocus
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>{' '}
          <Grid item xs={4}>
            <Button
              style={{ flexGrow: 1, flexBasis: '100px' }}
              ordinality="secondary"
              disabled={this.state.loading}
              variant="contained"
              endIcon={this.state.loading && <CircularProgress color={'inherit'} size={'16px'} />}
              onClick={this.handleFilterSubmit}
            >
              Search
            </Button>
            <Button
              style={{ flexGrow: 1, flexBasis: '100px' }}
              ordinality="secondary"
              disabled={this.state.loading}
              variant="text"
              endIcon={this.state.loading && <CircularProgress color={'inherit'} size={'16px'} />}
              onClick={this.handleFilterClear}
            >
              Clear Filters
            </Button>
          </Grid>
        </Grid>
        <StripedDataGrid
          height={!!!this.state.customers.length ? '30rem' : 'auto'}
          searchNotInitiated={this.state.searchNotInitiated}
          columns={this.columns}
          rows={this.state.customers}
          rowCount={this.state.totalCount}
          loading={this.state.loading}
          pagination
          paginationMode="server"
          paginationModel={this.state.paginationModel}
          onPaginationModelChange={paginationModel => {
            this.setState({ paginationModel: paginationModel });
            this.fetchCustomers();
          }}
          pageSizeOptions={[25]}
          onRowClick={params => window.open(`/customer360/${params.row.id}`)}
          disableColumnSorting={true}
          disableDensitySelector={true}
          disableColumnFilter={true}
          disableColumnMenu={true}
          hideFooter={true}
          slots={{
            toolbar: this.CustomToolbar,
          }}
        />
      </PageWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  storeFilters: filters => dispatch(getC360Filters(filters)),
  storePage: page => dispatch(getC360Page(page)),
});

const mapStateToProps = state => ({
  filters: state.c360List.filters,
  page: state.c360List.page,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(C360List));

C360List.propTypes = {
  storeFilters: PropTypes.func.isRequired,
  storePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  filters: PropTypes.any.isRequired,
};
