import { useState, useEffect } from 'react';
import { getInspectionStatuses } from '../../../../../services/service/services';
import { InspectionStatus } from '../../../../../services/service/types';
import { ErrorBus } from '../../../../_shared/useErrorBus';

// todo: consume inspectionStatuses in InspectionSearchView
export interface UseInspectionStatuses {
  inspectionStatuses?: InspectionStatus[];
  activeStatuses?: InspectionStatus[];
  error?: Error;
}

interface UseInspectionStatusesProps {
  errorBus: ErrorBus;
}

const busKey = 'useInspectionStatuses';

const useInspectionStatuses = ({ errorBus }: UseInspectionStatusesProps): UseInspectionStatuses => {
  const [inspectionStatuses, setInspectionStatuses] = useState<
    UseInspectionStatuses['inspectionStatuses']
  >();
  const [activeStatuses, setActiveStatuses] = useState<UseInspectionStatuses['activeStatuses']>();
  const { setError } = errorBus;

  useEffect(() => {
    setError(busKey, undefined);
    getInspectionStatuses({
      onSuccess: data => {
        setInspectionStatuses(data);
        setActiveStatuses(data.filter(status => !!status.active));
      },
      catchFailure: error => setError(busKey, error),
    });
  }, [setError]);

  return {
    inspectionStatuses,
    activeStatuses,
  };
};

export { useInspectionStatuses };
