import React, { useState } from 'react';
import axios from '../../utils/axios';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { Button } from '../../components/library';
import { stateArray, API_URL } from '../../constants';
import { ErrorMessage } from '../../components/library/ErrorMessage';

interface ConfirmAddressModalProps {
  initialAddress?: string | null;
  initialCity?: string | null;
  initialEmail?: string | null;
  initialFirstName?: string | null;
  initialLastName?: string | null;
  initialPhone?: string | null;
  initialState?: string | null;
  initialZip?: string | null;
  submissionId: number;
  triggerRefetch: () => Promise<void>;
}

export const ConfirmAddressModal = (props: ConfirmAddressModalProps) => {
  const {
    initialAddress,
    initialCity,
    initialEmail,
    initialFirstName,
    initialLastName,
    initialPhone,
    initialState,
    initialZip,
    submissionId,
    triggerRefetch,
  } = props;

  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState(initialAddress);
  const [city, setCity] = useState(initialCity);
  const [email, setEmail] = useState(initialEmail);
  const [error, setError] = useState();
  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [phone, setPhone] = useState(initialPhone);
  const [state, setState] = useState(initialState);
  const [zip, setZip] = useState(initialZip);

  const theme = useTheme();

  const handleSubmit = async () => {
    const body = {
      firstName: firstName,
      lastName: lastName,
      address: address,
      city: city,
      state: state,
      zip: zip,
      phone: phone,
      email: email,
      submissionId: submissionId,
    };
    try {
      const url = `${API_URL}/tradeup/failedSubmissionAddress`;
      await axios.post(url, body);
      triggerRefetch();
    } catch (err) {
      setError(err.response ? err.response.data.message : 'An unknown error occurred');
    } finally {
      setOpen(false);
    }
  };

  return (
    <>
      <Box>
        <Button ordinality="primary" onClick={() => setOpen(true)}>
          Confirm Address
        </Button>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Confirm Address</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
            <Box display="flex" gridGap={theme.spacing(2)}>
              <TextField
                label="First Name"
                placeholder="John"
                variant="outlined"
                defaultValue={initialFirstName}
                onChange={e => setFirstName(e.target.value)}
                fullWidth
              />
              <TextField
                label="Last Name"
                placeholder="Doe"
                variant="outlined"
                defaultValue={initialLastName}
                onChange={e => setLastName(e.target.value)}
                fullWidth
              />
            </Box>
            <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
              <Box display="flex" gridGap={theme.spacing(2)}>
                <TextField
                  label="Phone Number"
                  placeholder="555-555-5555"
                  variant="outlined"
                  defaultValue={initialPhone}
                  onChange={e => setPhone(e.target.value)}
                  fullWidth
                />
                <TextField
                  label="Email"
                  placeholder="123@email.com"
                  variant="outlined"
                  defaultValue={initialEmail}
                  onChange={e => setEmail(e.target.value)}
                  fullWidth
                />
              </Box>
            </Box>
            <Divider />
            <Box display="flex" gridGap={theme.spacing(2)}>
              <TextField
                label="Address"
                placeholder="123 Main St."
                variant="outlined"
                defaultValue={initialAddress}
                onChange={e => setAddress(e.target.value)}
                fullWidth
              />
              <TextField
                label="City"
                placeholder="Springfield"
                variant="outlined"
                defaultValue={initialCity}
                onChange={e => setCity(e.target.value)}
                fullWidth
              />
            </Box>
            <Box display="flex" gridGap={theme.spacing(2)}>
              <Autocomplete
                options={stateArray}
                defaultValue={initialState}
                fullWidth
                onInputChange={(_e, selectedValue) => {
                  setState(selectedValue);
                }}
                renderInput={params => (
                  <TextField {...params} label="State" variant="outlined" fullWidth />
                )}
              />
              <TextField
                label="Zip Code"
                placeholder="12345"
                variant="outlined"
                defaultValue={initialZip}
                onChange={e => setZip(e.target.value)}
                fullWidth
              />
            </Box>
            <Box>
              <Typography variant="body2" color="textSecondary">
                Confirming the address will generate a shipping label and update the submission
                status.
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: theme.spacing(3) }}>
          <Button onClick={() => setOpen(false)} ordinality="secondary" variant="text">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()} ordinality="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {error && <ErrorMessage error={error} />}
    </>
  );
};
