import { useState, useEffect } from 'react';
import { getUser } from '../../../../../services/service/services';

interface UseUserName {
  userName?: string;
  loading: boolean;
  error?: Error;
}

const useUserName = (userId: number | null): UseUserName => {
  const [userName, setUserName] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (userId) {
      const getUserName = async () => {
        setLoading(true);
        try {
          getUser(userId, {
            onSuccess: user => {
              setUserName(user.name);
              setLoading(false);
            },
            catchFailure: setError,
          });
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };

      getUserName();
    }
  }, [userId]);

  return {
    userName,
    loading,
    error,
  };
};

export { useUserName };
