import { AxiosError, AxiosRequestConfig } from 'axios';
import axios from '../utils/axios';

export interface SuccessRestResponse<BODY> {
  body: BODY;
  errorMessage: null;
  success: true;
}

export interface ErrorRestResponse {
  body: undefined;
  errorMessage: string;
  success: false;
}

export type RestResponse<BODY> = SuccessRestResponse<BODY> | ErrorRestResponse;

export function successResponse<BODY>(body: BODY): SuccessRestResponse<BODY> {
  return { body, errorMessage: null, success: true };
}

export function errorResponse(errorMessage: string): ErrorRestResponse {
  return { body: undefined, errorMessage, success: false };
}

const getErrorResponseMessage = (url: string) =>
  `get request to url ${url} failed; axios did not return response`;

export interface CallbackOptions<V> {
  onSuccess?: (body: V) => void;
  catchFailure?: (axiosException: AxiosError) => void;
  handleFinally?: () => void;
}

export async function axiosGet<V>(
  url: string,
  options?: {
    axiosOptions?: AxiosRequestConfig;
  } & CallbackOptions<V>,
): Promise<RestResponse<V>> {
  return axiosGetWithCallbacks(url, options?.axiosOptions, { ...options });
}

export async function axiosGetWithCallbacks<V>(
  url: string,
  axiosOptions?: AxiosRequestConfig,
  callbackOptions?: CallbackOptions<V>,
): Promise<RestResponse<V>> {
  try {
    const axiosResponse = await axios.get(url, axiosOptions);
    if (axiosResponse) {
      const restResponse: RestResponse<V> = successResponse(axiosResponse.data);
      if (callbackOptions?.onSuccess) {
        callbackOptions.onSuccess(restResponse.body);
      }
      return restResponse;
    } else {
      if (callbackOptions?.catchFailure) {
        const fabricatedError: AxiosError = new Error(getErrorResponseMessage(url)) as AxiosError;
        fabricatedError.config = axiosOptions || {};
        callbackOptions.catchFailure(fabricatedError);
      }
      return errorResponse(getErrorResponseMessage(url));
    }
  } catch (e) {
    if (callbackOptions?.catchFailure) {
      callbackOptions.catchFailure(e);
      return errorResponse(getErrorResponseMessage(url));
    } else {
      throw e;
    }
  } finally {
    if (callbackOptions?.handleFinally) {
      callbackOptions.handleFinally();
    }
  }
}
