/* eslint-disable react/prop-types */
import React from 'react';
import { styleStatus } from '../helper';
import { StripedDataGrid } from '../../../styledComponents/datagrid';
import { GridColDef } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  {
    headerName: 'ID',
    field: 'id',
    width: 80,
  },
  {
    headerName: 'Offer State',
    field: 'status',
    flex: 2,
    renderCell: ({ value }) => (
      <span>
        <span style={{ color: styleStatus(value), margin: '0 5px' }}>&#x25cf;</span>
        {value}
      </span>
    ),
  },
  {
    headerName: 'Item',
    field: 'item',
    flex: 2,
  },
  {
    headerName: 'Dealer',
    field: 'dealerName',
    flex: 2,
  },
  {
    headerName: 'Partner',
    field: 'partnerName',
    flex: 2,
  },
  {
    headerName: 'Created',
    field: 'created',
    flex: 2,
    valueFormatter: ({ value }) =>
      new Date(value).toLocaleString('en-US', {
        timeZone: 'America/Denver',
      }),
  },
];

const PreviousSubmissionsTable = (props: any) => (
  <StripedDataGrid
    rows={props.previousSubmissions}
    columns={columns}
    onRowClick={params => {
      let endpoint = '';
      const partnerName = params.row.partnerName ?? '';
      switch (partnerName) {
        case 'SYB':
          endpoint = 'syb/';
          break;
        case '':
          endpoint = 'syb/';
          break;
        case undefined:
          endpoint = 'syb/';
          break;
        default:
          endpoint = 'partner/';
      }
      window.open(`/tradeups/${endpoint}${params.id}`, '_blank');
    }}
  />
);

export default PreviousSubmissionsTable;
