export function bifurcateArray<ArrayType>(
  array: ArrayType[],
  f: (el: ArrayType, idx?: number, arr?: ArrayType[]) => boolean,
): [ArrayType[], ArrayType[]] {
  return array.reduce(
    ([T, F]: [ArrayType[], ArrayType[]], currVal, idx, arr) => {
      // add to truthy array
      if (f(currVal, idx, arr)) return [[...T, currVal], F];
      // add to falsy array
      else return [T, [...F, currVal]];
    },
    [[], []],
  );
}
