import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Item } from '../../typeORM';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  CircularProgress,
  createFilterOptions,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';

import { API_URL, builds } from '../../constants';
import axios from '../../utils/axios';
import LabelValuePairDisplay from './detailComponents/LabelValuePairDisplay';
import { FormatMoney } from '../../utils/helper';
import { Checkbox, TextField } from '../../components/library';
import { useKeyPress } from '../../utils/hooks/useKeyPress';

interface State extends Partial<Item> {
  submissionType: string;
  catalogProductId?: number;
  isEditing: boolean;
  loading: boolean;
  errorMsg: string | null;
}

interface Props {
  item: Item;
  submissionId: number;
  submissionType: string;
  catalogProductId?: number;
  brands: { id: number; name: string }[];
  pipelines: { id: number; pipeline: string }[];
  disciplines: { id: number; discipline: string }[];
  categories: any[];
  callback: () => Promise<any>;
}

const InternalInfoForm: FC<Props> = (props: Props) => {
  const initialState: State = {
    year: props.item.year,
    confirmedBrand: props.item.confirmedBrand,
    confirmedModel: props.item.confirmedModel,
    msrp: props.item.msrp,
    size: props.item.size,
    wheelSize: props.item.wheelSize,
    build: props.item.build,
    pipelineId: props.item.pipelineId,
    disciplineId: props.item.disciplineId,
    categoryId: props.item.categoryId,
    wheelTier: props.item.wheelTier,
    buildTier: props.item.buildTier,
    powerMeter: props.item.powerMeter,
    frameMaterial: props.item.frameMaterial,
    submissionId: props.item.submissionId,
    submissionType: props.submissionType,
    catalogProductId: props.catalogProductId,
    isEditing: false,
    loading: false,
    errorMsg: null,
  };

  const [
    {
      year,
      confirmedBrand,
      confirmedModel,
      msrp,
      size,
      wheelSize,
      build,
      pipelineId,
      disciplineId,
      categoryId,
      wheelTier,
      buildTier,
      powerMeter,
      frameMaterial,
      submissionId,
      submissionType,
      catalogProductId,
      isEditing,
      loading,
      errorMsg,
    },
    setState,
  ] = useState(initialState);

  const validateYear = (input?: string | number | null) =>
    !!input && !!!input?.toString().match(/^20[1-2][0-9]$/);

  const [validYear, setValidYear] = useState<boolean>(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const fieldsRequiredToMakeProposal = ['year', 'confirmedBrand', 'confirmedModel', 'disciplineId'];

  const handleInternalBikeUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    setState(prevState => ({ ...prevState, loading: true }));
    const parseNullable = (val: any) => (val ? parseInt(val, 10) : null);

    // basic validation
    if (validateYear(year)) {
      setValidYear(false);
      setState(prevState => ({ ...prevState, loading: false }));
      return;
    }

    const body = {
      year: parseNullable(year),
      size,
      wheelSize,
      build,
      brand: confirmedBrand ?? null,
      model: confirmedModel,
      msrp,
      // todo: reevaluate nullable strings for internal* state values here
      pipelineId: parseNullable(pipelineId),
      disciplineId: parseNullable(disciplineId),
      categoryId: parseNullable(categoryId),
      wheelTier,
      buildTier,
      powerMeter,
      frameMaterial,
    };

    try {
      const res = await axios.post(
        `${API_URL}/tradeup/syb/submissionBikeInfo/${submissionId}`,
        body,
      );
      if (res && res.status < 300) {
        setState(prevState => ({ ...prevState, isEditing: false }));
        setState(prevState => ({ ...prevState, loading: false }));
        setState(prevState => ({ ...prevState, errorMsg: null }));
        await props.callback();
      } else {
        setState(prevState => ({ ...prevState, editError: true }));
        setState(prevState => ({ ...prevState, isEditing: true }));
        setState(prevState => ({
          ...prevState,
          errorMsg: res ? res.data : 'There was an issue',
        }));
        setState(prevState => ({ ...prevState, loading: false }));
      }
    } catch (err) {
      setState(prevState => ({ ...prevState, editError: true }));
      setState(prevState => ({ ...prevState, isEditing: true }));
      setState(prevState => ({
        ...prevState,
        errorMsg: err,
      }));
      setState(prevState => ({ ...prevState, loading: false }));
      console.error(err);
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
  });

  useKeyPress({ activationKey: 'option+enter' }, handleInternalBikeUpdate);

  return (
    <Grid container direction="row" justifyContent="space-evenly">
      <Grid container justifyContent="space-between" alignItems="start" sx={{ mb: 2 }}>
        <Grid item xs={3}>
          {!!catalogProductId && (
            <>
              <Typography component="label">{`Item Catalog Product: `}</Typography>
              <Typography component="span">
                <Link to={`/catalog/products/${catalogProductId}`} target="_blank">
                  {catalogProductId}
                </Link>
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          {errorMsg && (
            <Typography component="span" color="error">
              {errorMsg.toString()}
            </Typography>
          )}
        </Grid>
        {!isEditing && (
          <Grid item xs={3} sx={{ textAlign: 'right' }}>
            <Button
              color={loading ? 'info' : 'success'}
              disabled={loading}
              variant="contained"
              endIcon={loading && <CircularProgress color={'inherit'} size={'16px'} />}
              onClick={() => setState(prevState => ({ ...prevState, isEditing: true }))}
            >
              {loading ? 'Loading' : 'Edit'}
            </Button>
          </Grid>
        )}
      </Grid>
      {!isEditing ? (
        <Grid container spacing={2} direction="row">
          <Grid item xs={4}>
            <LabelValuePairDisplay
              labelValuePairs={[
                { label: 'Year', value: year },
                { label: 'Brand', value: confirmedBrand },
                { label: 'Model', value: confirmedModel },
                { label: 'Size', value: size },
                { label: 'Wheel Size', value: wheelSize },
              ]}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelValuePairDisplay
              labelValuePairs={[
                { label: 'MSRP', value: FormatMoney(msrp) },
                { label: 'Build', value: build },
                {
                  label: 'Pipeline',
                  value: props.pipelines.find(pipeline => pipeline.id === pipelineId)?.pipeline,
                },
                {
                  label: 'Discipline',
                  value: props.disciplines.find(discipline => discipline.id === disciplineId)
                    ?.discipline,
                },
                {
                  label: submissionType === 'bike' ? 'Bike Category' : 'Frame Category',
                  value: props.categories.find(category => category.id === categoryId)?.category,
                },
              ]}
            />
          </Grid>
          <Grid item xs={4}>
            <LabelValuePairDisplay
              labelValuePairs={[
                { label: 'Wheel Tier', value: wheelTier },
                { label: 'Build Tier', value: buildTier },
                { label: 'Frame Material', value: frameMaterial },
                { label: 'Power Meter', value: powerMeter ? 'Yes' : 'No' },
              ]}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} direction="row">
          <Grid container item xs={4} spacing={1.75} direction="column">
            <>
              <Grid item>
                <TextField
                  name="year"
                  value={year ?? ''}
                  size="small"
                  onChange={(e: any) => {
                    const value = e.target.value;
                    setState(prevState => ({ ...prevState, year: value }));
                  }}
                  onBlur={(e: any) => {
                    const value = e.target.value;
                    if (validateYear(value)) {
                      setValidYear(false);
                    } else {
                      setValidYear(true);
                    }
                  }}
                  error={!validYear}
                  helperText={!validYear ? 'Enter a valid year' : undefined}
                  label="Year"
                  disabled={!isEditing}
                  variant="outlined"
                  required={fieldsRequiredToMakeProposal.includes('year')}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  autoSelect
                  onChange={(_, option) => {
                    setState(prevState => ({
                      ...prevState,
                      confirmedBrand: (option as { id: string; name: string })?.name,
                    }));
                  }}
                  options={props.brands}
                  filterOptions={filterOptions}
                  value={props.brands.find(o => o.name === confirmedBrand) || null}
                  getOptionLabel={option => (option as { id: string; name: string })?.name || ''}
                  autoHighlight
                  disabled={!isEditing}
                  size="small"
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      name="confirmedBrand"
                      {...params}
                      variant="outlined"
                      label="Brand"
                      required={fieldsRequiredToMakeProposal.includes('confirmedBrand')}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <TextField
                  name="confirmedModel"
                  value={confirmedModel ?? ''}
                  label="Model"
                  size="small"
                  disabled={!isEditing}
                  onChange={handleChange}
                  variant="outlined"
                  required={fieldsRequiredToMakeProposal.includes('confirmedModel')}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  autoSelect
                  onChange={(_, option) => {
                    setState(prevState => ({ ...prevState, size: (option as string) ?? null }));
                  }}
                  options={['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL']}
                  filterOptions={filterOptions}
                  value={size}
                  autoHighlight
                  disabled={!isEditing}
                  size="small"
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      name="size"
                      {...params}
                      variant="outlined"
                      label="Size"
                      required={fieldsRequiredToMakeProposal.includes('size')}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  autoSelect
                  onChange={(_, option) => {
                    setState(prevState => ({
                      ...prevState,
                      wheelSize: (option as string) ?? null,
                    }));
                  }}
                  options={['26"', '27.5"', '29"', '650C', '700C']}
                  filterOptions={filterOptions}
                  value={wheelSize}
                  autoHighlight
                  disabled={!isEditing}
                  size="small"
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      name="wheelSize"
                      {...params}
                      variant="outlined"
                      label="Wheel Size"
                      required={fieldsRequiredToMakeProposal.includes('wheelSize')}
                    />
                  )}
                />
              </Grid>
            </>
          </Grid>
          <Grid container item xs={4} spacing={1.75} direction="column">
            <Grid item>
              <TextField
                name="msrp"
                value={msrp ?? ''}
                size="small"
                onChange={handleChange}
                label="MSRP"
                disabled={!isEditing}
                variant="outlined"
                required={fieldsRequiredToMakeProposal.includes('msrp')}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                autoSelect
                onChange={(_, option) => {
                  setState(prevState => ({ ...prevState, build: option }));
                }}
                options={builds}
                value={build}
                autoHighlight
                disabled={!isEditing}
                size="small"
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    name="build"
                    {...params}
                    variant="outlined"
                    label="Build"
                    required={fieldsRequiredToMakeProposal.includes('build')}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                autoSelect
                onChange={(_, option) => {
                  setState(prevState => ({ ...prevState, pipelineId: option?.id }));
                }}
                options={props.pipelines}
                getOptionLabel={option => option?.pipeline || ''}
                value={props.pipelines.find(pipeline => pipeline.id === pipelineId)}
                autoHighlight
                disabled={!isEditing}
                size="small"
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    name="pipelineId"
                    {...params}
                    variant="outlined"
                    label="Pipeline"
                    required={fieldsRequiredToMakeProposal.includes('pipelineId')}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                autoSelect
                onChange={(_, option) => {
                  setState(prevState => ({ ...prevState, disciplineId: option?.id }));
                }}
                options={props.disciplines}
                getOptionLabel={option => option?.discipline || ''}
                value={props.disciplines.find(discipline => discipline.id === disciplineId) || null}
                autoHighlight
                disabled={!isEditing}
                size="small"
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    name="disciplineId"
                    {...params}
                    variant="outlined"
                    label="Bike Discipline"
                    required={fieldsRequiredToMakeProposal.includes('disciplineId')}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                autoSelect
                onChange={(_, option) => {
                  setState(prevState => ({ ...prevState, categoryId: option?.id }));
                }}
                options={props.categories}
                getOptionLabel={option => option?.category ?? ''}
                value={props.categories.find(category => category.id === categoryId) || ''}
                autoHighlight
                disabled={!isEditing}
                size="small"
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    name="categoryId"
                    {...params}
                    variant="outlined"
                    label={`${submissionType === 'bike' ? 'Bike' : 'Frame'} Category`}
                    required={fieldsRequiredToMakeProposal.includes('categoryId')}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container item xs={4} spacing={2} direction="column">
            <Grid item>
              <Autocomplete
                autoSelect
                onChange={(_, option) => {
                  setState(prevState => ({
                    ...prevState,
                    wheelTier: (option as string) ?? null,
                  }));
                }}
                options={['1 Carbon', '2 Carbon', '3 Alloy', '4 Alloy']}
                filterOptions={filterOptions}
                value={wheelTier}
                autoHighlight
                disabled={!isEditing}
                size="small"
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    name="wheelTier"
                    {...params}
                    variant="outlined"
                    label="Wheel Tier"
                    required={fieldsRequiredToMakeProposal.includes('wheelTier')}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                autoSelect
                onChange={(_, option) => {
                  setState(prevState => ({ ...prevState, buildTier: option }));
                }}
                options={['1', '2', '3', '4']}
                value={buildTier}
                autoHighlight
                disabled={!isEditing}
                size="small"
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    name="buildTier"
                    {...params}
                    variant="outlined"
                    label="Build Tier"
                    required={fieldsRequiredToMakeProposal.includes('buildTier')}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                autoSelect
                onChange={(_, option) => {
                  setState(prevState => ({ ...prevState, frameMaterial: option }));
                }}
                options={['Carbon', 'Aluminum', 'Titanium', 'Steel']}
                value={frameMaterial}
                autoHighlight
                disabled={!isEditing}
                size="small"
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    name="frameMaterial"
                    {...params}
                    variant="outlined"
                    label="Frame Material"
                    required={fieldsRequiredToMakeProposal.includes('frameMaterial')}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!powerMeter}
                    onChange={e => {
                      const { checked } = e.target;
                      setState(prevState => ({
                        ...prevState,
                        powerMeter: checked,
                      }));
                    }}
                    name="powerMeter"
                    color="primary"
                  />
                }
                name="powerMeter"
                label="Power Meter"
                labelPlacement="start"
                disabled={!isEditing}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {isEditing && (
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Button
            color={loading ? 'info' : 'success'}
            disabled={loading}
            variant="contained"
            endIcon={loading && <CircularProgress color={'inherit'} size={'16px'} />}
            onClick={handleInternalBikeUpdate}
          >
            {loading ? 'Saving' : 'Save'}
          </Button>
          <Button
            color="success"
            variant="outlined"
            onClick={() => setState(() => ({ ...initialState, isEditing: false }))}
            sx={{ ml: 1 }}
          >
            Cancel
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default InternalInfoForm;
