import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useStaticPartsTableStyles } from '../styled';
import { InspectionPart } from '../../../../../../services/service/types';
import { FailedPointOption } from './types';
import { PartSources, PartStatuses, PartIdentifierTypes } from '../../../_shared/types';

interface StaticPartsTableProps {
  inspectionParts: InspectionPart[];
  failedReplacePointOptions: FailedPointOption[];
  pointTypeCategoryLookup: { [key: number]: string };
}

export const StaticPartsTable: React.FC<StaticPartsTableProps> = ({
  inspectionParts,
  failedReplacePointOptions,
  pointTypeCategoryLookup,
}) => {
  const classes = useStaticPartsTableStyles();

  const headerCellClass = `${classes.thCell} ${classes.leftAlignedCell}`;

  return (
    <>
      <TableContainer>
        <Table className={classes.table} aria-label="inspections-table">
          <TableHead className={classes.th}>
            <TableRow className={classes.row}>
              <TableCell className={headerCellClass}>Inspection Category</TableCell>
              <TableCell className={headerCellClass}>Inspection Point</TableCell>
              <TableCell className={headerCellClass}>Source</TableCell>
              <TableCell className={headerCellClass}>Identifier Type</TableCell>
              <TableCell className={headerCellClass}>Identifier</TableCell>
              <TableCell className={headerCellClass}>QTY</TableCell>
              <TableCell className={headerCellClass}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inspectionParts.map(part => {
              if (!part.active) return null;

              // todo: how to handle missing point type values? it's conceivable that we might have an active part
              // for a point that's since had its remedy changed...
              const pointType = failedReplacePointOptions.find(
                option => option.value === part.inspectionPointTypeId,
              );

              return (
                <TableRow key={part.id} className={classes.row}>
                  {/* Inspection Category */}
                  <TableCell scope="row">
                    {pointTypeCategoryLookup[part.inspectionPointTypeId]}
                  </TableCell>

                  {/* Inspection Point */}
                  <TableCell scope="row">{pointType?.label ?? 'shrug'}</TableCell>

                  {/* Inspection Source */}
                  <TableCell scope="row">{PartSources[part.sourceId]}</TableCell>

                  {/* Identifier Type */}
                  <TableCell scope="row">{PartIdentifierTypes[part.identifierTypeId]}</TableCell>

                  {/* Identifier */}
                  <TableCell scope="row">{part.identifier}</TableCell>

                  {/* QTY */}
                  <TableCell scope="row">{part.quantity}</TableCell>

                  {/* Status */}
                  <TableCell scope="row">{PartStatuses[part.statusId]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
