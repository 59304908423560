import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { InspectionPointStatusIcon } from './InspectionPointStatusIcon';
import { Grades } from '../../_shared';
import {
  Tooltip,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';

interface InspectionCriteriaTooltipProps {
  passCriteria: string;
  failCriteria: string;
}

const useStyles = makeStyles(theme => ({
  pass: {
    backgroundColor: theme.palette.success.background,
  },
  fail: {
    backgroundColor: theme.palette.error.background,
  },
  cardTooltip: {
    padding: theme.spacing(1),
    maxWidth: theme.spacing(60),
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[12],
    borderRadius: theme.shape.borderRadius,
  },
}));

export const InspectionCriteriaTooltip: React.FC<InspectionCriteriaTooltipProps> = props => {
  const classes = useStyles();

  if (!(props.passCriteria || props.failCriteria)) {
    return null;
  }

  return (
    <Tooltip
      placement="right"
      classes={{
        tooltip: classes.cardTooltip,
      }}
      arrow
      enterDelay={300}
      enterTouchDelay={0}
      leaveTouchDelay={15000}
      disableFocusListener
      title={
        <React.Fragment>
          <List>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar variant="rounded" className={classes.pass}>
                  <InspectionPointStatusIcon gradeId={Grades.pass} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Pass" secondary={props.passCriteria} />
            </ListItem>
            <Divider variant="middle" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar variant="rounded" className={classes.fail}>
                  <InspectionPointStatusIcon gradeId={Grades.fail} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Fail" secondary={props.failCriteria} />
            </ListItem>
          </List>
        </React.Fragment>
      }
    >
      <IconButton disableRipple tabIndex={-1}>
        <InfoIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
