import {
  Box,
  Divider,
  IconButton,
  Paper,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import Draggable from 'react-draggable';
import ImageCarousel from '../library/ImageCarousel/ImageCarousel';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloseIcon from '@material-ui/icons/Close';
import useLocalStorageState from '../../utils/hooks/useLocalStorageState';
import { useSelector } from 'react-redux';
import { GlobalState, Permissions } from '../../reducers/types';
import { Button } from '../library';
import { ProductImage } from '../../services/catalog/types';
import { Link } from 'react-router-dom';

interface ImagesPreviewProps {
  images: ProductImage[];
  productId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    noUnderline: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    fixed: {
      position: 'relative',
    },
    draggable: {
      position: 'sticky',
    },
    topBar: {
      backgroundColor: theme.palette.grey[100],
      transition: 'background-color 0.2s ease-in-out',
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
  }),
);

const ImagesPreview: React.FC<ImagesPreviewProps> = ({ images, productId = [] }) => {
  const [grow, setGrow] = useLocalStorageState<boolean>('growCarousel', false);
  const [drag, setDrag] = useLocalStorageState<boolean>('draggableCarousel', false);
  const [carouselKey, setCarouselKey] = React.useState(0);

  const { user } = useSelector((state: GlobalState) => state.auth);
  const userHasImagesPermission = !!user?.permissions.find(
    p => p.id === Permissions.CATALOG_UPDATE_IMAGES,
  );

  const productImages = images.map(image => image.imageUrl);

  const classes = useStyles();

  if (productImages.length === 0 && userHasImagesPermission) {
    return (
      <Link to={`/catalog/products/${productId}/images`} className={classes.noUnderline}>
        <Button
          variant="outlined"
          ordinality="primary"
          className={classes.button}
          endIcon={<AddIcon />}
        >
          Add Images
        </Button>
      </Link>
    );
  } else if (productImages.length > 0) {
    return (
      <Draggable disabled={!drag} position={drag ? undefined : { x: 0, y: 0 }}>
        <Box
          className={drag ? classes.draggable : classes.fixed}
          display="flex"
          maxWidth={'650px'}
          width="100%"
          height={grow ? 'auto' : '340px'}
          top="0px"
          zIndex={drag ? 1 : 0}
          style={
            drag
              ? { transform: 'translate(0, 0)' }
              : {
                  transition: '.3s ease',
                  mixBlendMode: 'multiply',
                  backgroundColor: 'transparent',
                }
          }
        >
          <Paper
            elevation={drag ? 8 : 0}
            variant={drag ? 'elevation' : 'outlined'}
            style={{
              overflow: 'hidden',
              transition: '.3s ease',
            }}
          >
            <Box
              height="40px"
              width="100%"
              display="flex"
              alignItems="center"
              padding={1}
              justifyContent="space-between"
              className={classes.topBar}
            >
              <Box ml={1}>Images</Box>
              <Box>
                <IconButton
                  onClick={() => {
                    setGrow(!grow);
                    // Update key to force rerender of Image Carousel component
                    setCarouselKey(carouselKey + 1);
                  }}
                  size="small"
                >
                  {grow ? <RemoveIcon /> : <AddIcon />}
                </IconButton>

                <IconButton onClick={() => setDrag(!drag)} size="small">
                  {drag ? <CloseIcon /> : <OpenInNewIcon />}
                </IconButton>
              </Box>
            </Box>
            <Divider />
            <Box display="flex" height={grow ? '100%' : '300px'}>
              <ImageCarousel
                key={carouselKey}
                images={productImages}
                showArrows
                zoomable={grow}
                showModal={!grow}
                swipeable={false}
              />
            </Box>
          </Paper>
        </Box>
      </Draggable>
    );
  } else {
    return null;
  }
};

export default ImagesPreview;
