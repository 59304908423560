import React from 'react';

const ItemCatalog = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 .5v3.75h3.75V.5H0zm5.8594 0v3.75H15V.5H5.8594zM0 6.125v3.75h3.75v-3.75H0zm5.8594 0v3.75H15v-3.75H5.8594zM0 11.75v3.75h3.75v-3.75H0zm5.8594 0v3.75H15v-3.75H5.8594z"
      fill="currentColor"
    />
  </svg>
);

export default ItemCatalog;
