import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import axios from '../../../utils/axios';
import { API_URL } from '../../../constants';

class DealerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      dealer: {},
      dealerName: '',
      dealerUrl: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      active: false,
    };
  }

  componentDidMount() {
    this.fetchDealerDetail();
  }

  fetchDealerDetail = async () => {
    const { id } = this.state;
    const url = `${API_URL}/tradeup/syb/getdealer`;
    try {
      const response = await axios.get(url, { params: { id } });
      const { data } = response;
      this.setState({
        dealer: data,
        dealerName: data.name,
        dealerUrl: data.url,
        email: data.contact_email,
        phone: data.phone,
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        active: data.active,
      });
      console.log(response.data);
    } catch (err) {
      NotificationManager.error('Error fetching dealer', 'Error', 10000);
    }
  };

  editDealer = async e => {
    e.preventDefault();
    const {
      dealerName,
      dealerUrl,
      email,
      phone,
      address,
      city,
      state,
      zip,
      active,
      id,
    } = this.state;
    const body = {
      dealerName,
      dealerUrl,
      email,
      phone,
      address,
      city,
      state,
      zip,
      active,
      id,
    };
    const url = `${API_URL}/tradeup/syb/updateDealer`;

    try {
      const response = await axios.post(url, body);
      console.log(response);
      if (response.status < 300) {
        this.fetchDealerDetail();
        NotificationManager.info('Dealer updated', 'Success', 4000);
      }
    } catch (err) {
      console.error(err);
      NotificationManager.error('Error updation dealer', 'Error', 10000);
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { name, token } = this.state.dealer;
    const { dealerName, dealerUrl, email, phone, address, city, state, zip, active } = this.state;
    return (
      <main className="react-table dealer-detail-wrap">
        <h2>{name}</h2>
        <form
          className="shadow flex-row dealerDetail"
          style={{ border: '1px solid' }}
          onSubmit={this.editDealer}
        >
          <div>
            <span className="input-label-wrapper">
              <label>Dealer Name:</label>
              <input
                name="dealerName"
                className="text-input"
                onChange={this.handleChange}
                value={dealerName}
              />
            </span>
            <span className="input-label-wrapper">
              <label>Dealer Url:</label>
              <input
                name="dealerUrl"
                className="text-input"
                onChange={this.handleChange}
                value={dealerUrl}
              />
            </span>
            <span className="input-label-wrapper">
              <label>Email:</label>
              <input
                name="email"
                className="text-input"
                onChange={this.handleChange}
                value={email}
              />
            </span>
            <span className="input-label-wrapper">
              <label>Phone:</label>
              <input
                name="phone"
                className="text-input"
                onChange={this.handleChange}
                value={phone}
              />
            </span>
            <section className="text-center margin-top">
              <label>Active:</label>
              <input
                name="active"
                type="checkbox"
                checked={active}
                onChange={() => this.setState({ active: !active })}
              />
            </section>
          </div>
          <div>
            <span className="input-label-wrapper">
              <label>Address:</label>
              <input
                name="address"
                className="text-input"
                onChange={this.handleChange}
                value={address}
              />
            </span>
            <span className="input-label-wrapper">
              <label>City:</label>
              <input name="city" className="text-input" onChange={this.handleChange} value={city} />
            </span>
            <span className="input-label-wrapper">
              <label>State:</label>
              <input
                name="state"
                className="text-input"
                onChange={this.handleChange}
                value={state}
              />
            </span>
            <span className="input-label-wrapper">
              <label>Zip:</label>
              <input name="zip" className="text-input" onChange={this.handleChange} value={zip} />
            </span>
          </div>

          <input className="classy-button" value="Save Edits" type="submit" />
        </form>
        <section
          className="shadow"
          style={{ border: '1px solid', margin: '25px 0', padding: '0 35px' }}
        >
          <h4>Code for Dealer</h4>
          <p>
            This code can be placed on the dealers site and is unique to <strong>{name}</strong>
          </p>
          <p>
            The token for this dealer is:<strong>{token}</strong>
          </p>
          <p>NOTE: This is NOT a private token and is used for dealer identification only.</p>

          <code>
            {`<div class="iframe-container"><iframe title="tpc trade up" src=https://tradeup.theproscloset.com/dealer-submission/form/${token} width="100%" height="600" allowfullscreen></iframe></div>`}
          </code>
        </section>
      </main>
    );
  }
}

export default withRouter(DealerDetail);
