import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { FormikServiceState } from '../../../_shared/types';
import { FailRemedies, FailedPointOption } from '../../../_shared';
import {
  FEInspectionPoint,
  InspectionPart,
  InspectionStatusEnum,
} from '../../../../../../services/service/types';
import { useEditableServiceTableStyles } from '../styled';
import { useWarnBeforeUnload } from '../useWarnBeforeUnload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { bifurcateArray } from '../../../../../../utils/bifurcateArray';
import { EditableServiceTableRows } from './EditableServiceTableRows';

interface EditableServiceTableProps {
  failedPoints: FEInspectionPoint[];
  failedPointOptions: FailedPointOption[];
  pointTypeCategoryLookup: { [key: number]: string };
  partsByPointId: { [key: number]: InspectionPart[] };
  handleDataChange(dirty: boolean): void;
  statusId: number;
}

export const EditableServiceTable: React.FC<EditableServiceTableProps> = ({
  failedPoints,
  failedPointOptions,
  pointTypeCategoryLookup,
  partsByPointId,
  handleDataChange,
  statusId,
}) => {
  const classes = useEditableServiceTableStyles();
  const { values, setFieldValue, dirty } = useFormikContext<FormikServiceState>();

  const filteredInspectionPoints = useMemo(() => {
    if (statusId === InspectionStatusEnum['Ready for Master Tech']) {
      const masterTechFilter = [7, 9, 21, 41, 42, 54, 55];

      return bifurcateArray(
        failedPoints,
        x =>
          !(
            masterTechFilter.includes(
              failedPointOptions.find(option => option.value === x.inspectionPointTypeId)?.value ||
                0,
            ) || x.failRemedyId === FailRemedies.na
          ),
      );
    }
    return [failedPoints, []];
  }, [failedPointOptions, failedPoints, statusId]);

  useWarnBeforeUnload(dirty);
  handleDataChange(dirty);

  if (!failedPoints) return null;

  const headerCellClass = `${classes.thCell} ${classes.leftAlignedCell}`;
  const clickablePartClass = `${classes.actionCell} ${classes.clickable}`;

  return (
    <>
      <TableContainer>
        <Table className={classes.table} aria-label="inspections-table">
          <TableHead className={classes.th}>
            <TableRow className={classes.row}>
              <TableCell className={headerCellClass}>Done</TableCell>
              <TableCell className={headerCellClass}>Category</TableCell>
              <TableCell className={headerCellClass}>Inspection Point</TableCell>
              <TableCell className={headerCellClass}>Remedy</TableCell>
              <TableCell className={headerCellClass}>Inspector Note</TableCell>
              <TableCell className={headerCellClass}>Parts</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <EditableServiceTableRows
              filteredInspectionPoints={filteredInspectionPoints[0]}
              pointTypeCategoryLookup={pointTypeCategoryLookup}
              failedPointOptions={failedPointOptions}
              partsByPointId={partsByPointId}
              values={values}
              setFieldValue={setFieldValue}
              classes={classes}
              clickablePartClass={clickablePartClass}
            />
            {filteredInspectionPoints[1].length > 0 && (
              <>
                <Accordion variant="outlined" defaultExpanded>
                  <Box className={classes.th}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Additional Failed Inspection Service
                    </AccordionSummary>
                  </Box>
                  <AccordionDetails>
                    <Box>
                      <EditableServiceTableRows
                        filteredInspectionPoints={filteredInspectionPoints[1]}
                        pointTypeCategoryLookup={pointTypeCategoryLookup}
                        failedPointOptions={failedPointOptions}
                        partsByPointId={partsByPointId}
                        values={values}
                        setFieldValue={setFieldValue}
                        classes={classes}
                        clickablePartClass={clickablePartClass}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
