import React from 'react';

import fecha from 'fecha';
import styled from 'styled-components';

import { nullableParseInt } from '../conversionHelpers';
import { notifyInternalInfoMissing } from '../sybService';

import { Button, Snackbar } from '../../../components/library';
import {
  Buyer,
  Status,
  DeclineReason,
  Submission,
  Offer,
  ReactiveSettings,
  CategoryStatus,
  BrakeType,
  SubmissionOfferTypes,
} from '../../../typeORM';
import {
  PARTNER_VOUCHER_SITE_IDS,
  TRADEUP_ONLY_SITE_IDS,
  offerTypesConfig,
  SubmissionStatusIds,
  OfferField,
  UpdatedOffer,
  TRANSITIONABLE_STATUSES,
  OfferType,
  DEFAULT_MINIMUM_SALE_PRICE_MULTIPLIER,
  DEFAULT_MAXIMUM_SALE_PRICE_MULTIPLIER,
} from '../../../constants';
import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  calculateOfferAmountUsd,
  getAcceptedOffer,
  styleCategoryStatus,
  styleStatus,
} from '../helper';
import { SubmissionChip } from '../SubmissionChip';

const DECLINE_REASON_BLACKLIST = [10, 11, 12, 14];

const ResponsiveGrid = styled(Box)(({ theme }) => ({
  '& > *': {
    padding: theme.spacing(8),
  },
  gridTemplateAreas: `
    'buyer'
    'offer'
    'communication'
    `,
  [theme.breakpoints.up('lg')]: {
    gridTemplateAreas: `
      'buyer communication'
      'offer communication'
      `,
  },
}));

const CommunicationSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  gridArea: 'communication',
}));

const BuyerSection = styled(Box)(({ theme }) => ({
  gridArea: 'buyer',
  display: 'flex',
  flexDirection: 'column',
  gridGap: theme.spacing(2),
  borderBottom: `solid 1px ${theme.palette.grey[100]}`,
}));

const ModalFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
  backgroundColor: theme.palette.common.white,
  position: 'sticky',
  bottom: 0,
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  gridGap: theme.spacing(1),
  zIndex: 999,
}));

const BoldTypography = styled(Typography)(({ theme }) => ({
  // use important to override font-family set by MUI Typography
  fontFamily: `${theme.typography.bold} !important`,
}));

const BoldChip = styled(Chip)(({ theme }) => ({
  // use important to override font-family set by MUI Chip
  fontFamily: `${theme.typography.bold} !important`,
}));

const InlineWrap = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Row = styled.div`
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: column;
`;

const roadBikeDisciplineIds = [1, 2, 3];

interface OfferUpdateProps {
  handleSuccess: () => any;
  handleError: () => any;
  handleSnackbarOpen: () => void;
  handleSnackbarClose: () => void;
  snackbarOpen: boolean;
  putSubmission: (body: any) => Promise<any>;
  triggerRefetch: () => void;
  closeModal: () => any;
  setLoading: (loading: boolean) => any;
  disciplineLabel: string | null | undefined;
  submission: Pick<
    Submission,
    | 'id'
    | 'type'
    | 'buyer'
    | 'statusId'
    | 'declineReasonId'
    | 'expectedValue'
    | 'preAmendmentReason'
    | 'brakeType'
    | 'bikeDisciplineId'
    | 'internalNote'
    | 'isTradein'
    | 'externalSubmission'
    | 'partnerSiteId'
    | 'items'
    | 'categoryStatus'
    | 'activeOfferGroup'
  >;
  // lookups
  buyers: Buyer[];
  statuses: Status[];
  declineReasons: DeclineReason[];
  reactiveSettings: ReactiveSettings;
}

interface OfferUpdateState {
  isCalcDisabled: boolean;
  isAmendEnabled: boolean;
  blockEmail: boolean;
  validDeclineReasons: DeclineReason[];
  isTradein: boolean;
  // data values
  expectedValue: number | null;
  canUpdateOffers: boolean;
  preAmendmentReason: string | null;
  isPartnerOfferOnly: boolean;
  buyerId: number | null;
  statusId: number | null;
  declineReasonId: number | null;
  externalNote: string;
  internalNote: string;
  brakeType: BrakeType;
  errorMessage: string;
  categoryStatus: CategoryStatus;
  isBrakeTypeDisabled: boolean;
  isSubmissionPartnerVoucherEligible: boolean;
  isSubmissionTradeupOnly: boolean;
  offerFields: { [key: string]: OfferField };
}

class OfferUpdateForm extends React.Component<OfferUpdateProps, OfferUpdateState> {
  acceptedOffer = getAcceptedOffer(this.props.submission.activeOfferGroup?.offers ?? undefined);
  offerTypes: { [key: string]: OfferType } = offerTypesConfig;

  basisOfferType = this.props.reactiveSettings.basisOfferType;

  enableSingleOfferType = Object.keys(this.offerTypes).find(key =>
    this.offerTypes[key].enableSingleOption(this.props.submission.partnerSiteId),
  );

  defaultAvailableToSeller = Object.keys(this.offerTypes).filter(key =>
    this.offerTypes[key].defaultAvailableToSeller(
      this.props.reactiveSettings,
      this.props.submission.partnerSiteId,
    ),
  );

  enabledOfferTypes = () =>
    this.enableSingleOfferType
      ? [this.enableSingleOfferType]
      : Object.keys(this.offerTypes).filter(
          key =>
            !!!this.props.reactiveSettings.hideInAdmin[key as SubmissionOfferTypes] ||
            !!this.state.offerFields[key].disabledOfferWithAmount,
        );

  constructor(props: OfferUpdateProps) {
    super(props); // todo: necessary?
    this.state = {
      isAmendEnabled: !!props.submission.preAmendmentReason,
      blockEmail: false,
      validDeclineReasons: this.getValidDeclineReasons(props.submission.statusId),
      isTradein: props.submission.isTradein ?? false,
      // data values
      expectedValue: nullableParseInt(props.submission.expectedValue),
      canUpdateOffers: this.validSubmissionStatusForOffer(props.submission.statusId),
      offerFields: Object.keys(this.offerTypes).reduce(
        (result: { [key: string]: OfferField }, key: string) => {
          const matchingOffer =
            props.submission.activeOfferGroup?.offers &&
            props.submission.activeOfferGroup.offers?.length > 0
              ? props.submission.activeOfferGroup?.offers.filter(
                  (offer: Offer) => offer.offerTypeName === key,
                )[0]
              : null;
          const amountUsd = matchingOffer?.amountUsd ?? null;
          const availableToSeller =
            matchingOffer?.availableToSeller ??
            offerTypesConfig[key].defaultAvailableToSeller(
              this.props.reactiveSettings,
              props.submission.partnerSiteId,
            );
          const disabledOfferWithAmount =
            !!this.props.reactiveSettings.hideInAdmin[key as SubmissionOfferTypes] && !!amountUsd;
          result[key] = {
            amountUsd,
            availableToSeller,
            disabledOfferWithAmount,
            consignorSplitPct: matchingOffer?.consignorSplit
              ? Math.round(matchingOffer.consignorSplit * 10000) / 100
              : undefined,
          };
          return result;
        },
        {},
      ),
      preAmendmentReason: props.submission.preAmendmentReason,
      buyerId: props.submission.buyer?.id ?? null,
      statusId: props.submission.statusId,
      declineReasonId: props.submission.declineReasonId,
      externalNote: '',
      internalNote: props.submission.internalNote ?? '',
      brakeType: props.submission.brakeType ?? BrakeType.NA,
      errorMessage: '',
      categoryStatus: props.submission.categoryStatus ?? CategoryStatus.UNKNOWN,
      isBrakeTypeDisabled: props.submission.items?.[0]?.disciplineId
        ? !roadBikeDisciplineIds.includes(props.submission.items?.[0]?.disciplineId)
        : true,
      isSubmissionPartnerVoucherEligible:
        !!props.submission.partnerSiteId &&
        PARTNER_VOUCHER_SITE_IDS.includes(props.submission.partnerSiteId),
      isCalcDisabled:
        !!props.submission.partnerSiteId &&
        PARTNER_VOUCHER_SITE_IDS.includes(props.submission.partnerSiteId)
          ? true
          : false,
      isSubmissionTradeupOnly:
        !!props.submission.partnerSiteId &&
        TRADEUP_ONLY_SITE_IDS.includes(props.submission.partnerSiteId),
      isPartnerOfferOnly:
        !!props.submission.partnerSiteId &&
        PARTNER_VOUCHER_SITE_IDS.includes(props.submission.partnerSiteId),
    };
  }

  convertOfferFieldsToOffers = (offerFields: { [key: string]: OfferField }) =>
    Object.keys(offerFields).reduce((result: UpdatedOffer[], offerKey) => {
      if (offerFields[offerKey].amountUsd && this.enabledOfferTypes().includes(offerKey)) {
        const consignmentProperties =
          offerTypesConfig[offerKey as SubmissionOfferTypes].consignmentProperties;
        const estimatedPaymentRangeMultipliers = consignmentProperties && {
          minimumSalePriceMultiplier:
            consignmentProperties(this.props.reactiveSettings)?.minimumSalePriceMultiplier ??
            DEFAULT_MINIMUM_SALE_PRICE_MULTIPLIER,
          maximumSalePriceMultiplier:
            consignmentProperties(this.props.reactiveSettings)?.maximumSalePriceMultiplier ??
            DEFAULT_MAXIMUM_SALE_PRICE_MULTIPLIER,
        };
        const consignorSplit = offerFields[offerKey].consignorSplitPct
          ? Math.round((offerFields[offerKey].consignorSplitPct ?? 0) * 100) / 10000
          : null;
        result.push({
          offerTypeName: offerKey as SubmissionOfferTypes,
          amountUsd: offerFields[offerKey].amountUsd as number,
          availableToSeller: offerFields[offerKey].availableToSeller,
          offerPaymentTermsId: offerTypesConfig[offerKey].paymentTermsId || 1,
          consignorSplit,
          estimatedPaymentRangeMultipliers: estimatedPaymentRangeMultipliers ?? null,
        });
      }
      return result;
    }, []);

  formatDate = (date: string) => fecha.format(new Date(date), 'h:mm a, MMM Do, YYYY');

  handleAvailableToSellerToggle = (
    _e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string,
  ) => {
    this.setState(prevState => ({
      offerFields: {
        ...prevState.offerFields,
        [key]: {
          ...prevState.offerFields[key],
          availableToSeller: !prevState.offerFields[key].availableToSeller,
        },
      },
    }));
  };

  setConsignmentAmount = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string,
  ) => {
    const { value } = e.target;
    const parsedValue = Math.round(parseFloat(value) * 100) / 100;
    return this.setState(prevState => ({
      offerFields: {
        ...prevState.offerFields,
        [key]: {
          ...prevState.offerFields[key],
          consignorSplitPct: parsedValue,
        },
      },
    }));
  };

  calculateOffers = (value: string | number, key: string) => {
    if (!this.state.canUpdateOffers) {
      return 'You can only update offers when setting Submission to Proposed, Extended or Evaluated';
    }
    const parsedValue = parseFloat(value as string) || null;
    if (!this.state.isCalcDisabled && key === this.basisOfferType) {
      const basisAmountUsd = parsedValue ? parsedValue : 0;
      // basisOffertype updated and calc is not disabled - calculate the other offers
      return this.setState(prevState => ({
        offerFields: Object.keys(this.state.offerFields).reduce((acc, offerKey) => {
          const amountUsd =
            parsedValue === null
              ? null
              : calculateOfferAmountUsd(
                  offerKey as SubmissionOfferTypes,
                  basisAmountUsd,
                  this.props.reactiveSettings,
                  this.enabledOfferTypes().includes(offerKey),
                );
          const consignmentProperties = offerTypesConfig[offerKey].consignmentProperties;
          let consignorSplit: number | null = null;
          let consignorSplitPct: number | null = null;
          if (consignmentProperties && this.state.expectedValue) {
            consignorSplit =
              consignmentProperties(this.props.reactiveSettings).defaultConsignorSplit ??
              Number((basisAmountUsd / this.state.expectedValue).toPrecision(4)); // matches db precision, but might change if it needs to be presentable,
            consignorSplitPct = Math.round(consignorSplit * 10000) / 100;
          }

          return {
            ...acc,
            [offerKey]: {
              ...prevState.offerFields[offerKey],
              amountUsd: amountUsd,
              consignorSplitPct,
            },
          };
        }, prevState.offerFields as { [key: string]: OfferField }),
      }));
    } else {
      return this.setState(prevState => ({
        offerFields: {
          ...prevState.offerFields,
          [key]: {
            ...prevState.offerFields[key],
            amountUsd: parsedValue,
          },
        },
      }));
    }
  };

  checkMissingInternalInfo = (statusId?: number) => {
    const idToCompare = statusId ?? this.props.submission.statusId;
    if (
      this.props.submission.type !== 'gear' &&
      [2, 15].includes(idToCompare) // 'pending', 'evaluated'
    ) {
      const item = this.props.submission.items[0];
      const { year, confirmedBrand, confirmedModel, disciplineId } = item;
      return !year || !confirmedBrand || !confirmedModel || !disciplineId;
    }
    return false;
  };

  // disable offerUpdateForm submit if internal status-specific data prerequisites aren't met
  checkCanSubmit = (
    statusId?: number,
    emailBlocked?: boolean,
    offerFields?: { [key: string]: OfferField },
  ) => {
    if (!!!emailBlocked && (!statusId || !TRANSITIONABLE_STATUSES.includes(statusId))) {
      return true;
    } else if (
      !!!emailBlocked &&
      statusId &&
      [
        SubmissionStatusIds.PROPOSED,
        SubmissionStatusIds.EXTENDED,
        SubmissionStatusIds.EVALUATED,
      ].includes(statusId)
    ) {
      const offerTooHigh =
        this.state.expectedValue &&
        Object.values(this.state.offerFields).filter(
          offer => (offer.amountUsd ?? 0) > (this.state.expectedValue ?? 0),
        ).length > 0;
      const missingInternalInfo = this.checkMissingInternalInfo(statusId);

      const availableOffers =
        offerFields &&
        Object.values(offerFields).find(
          (offerField: OfferField) =>
            offerField.amountUsd && offerField.amountUsd > 0 && offerField.availableToSeller,
        );

      return !this.state.expectedValue || offerTooHigh || missingInternalInfo || !!!availableOffers;
    }

    return false;
  };

  validSubmissionStatusForOffer = (statusId: number) => {
    return [
      SubmissionStatusIds.PROPOSED,
      SubmissionStatusIds.EVALUATED,
      SubmissionStatusIds.EXTENDED,
    ].includes(statusId);
  };

  getValidDeclineReasons = (statusId: number) => {
    return this.props.declineReasons
      .filter(r => r.submissionStatusId === statusId)
      .sort((a, b) => a.weight - b.weight);
  };

  getOfferFields() {
    return (
      <Box
        mb={2}
        mt={4}
        display="grid"
        gridRowGap={8}
        gridColumnGap={12}
        gridTemplateColumns="repeat(auto-fit, minmax(245px,1fr))"
      >
        {this.offerTypes &&
          Object.keys(this.offerTypes).map(key => {
            const offerType = this.offerTypes[key];
            const isChecked = this.state.offerFields[key]?.availableToSeller ? true : false;
            const value: number | null = this.state.offerFields[key].amountUsd;
            const consignorSplitPct: number | null | undefined = this.state.offerFields[key]
              .consignorSplitPct;
            return this.enabledOfferTypes().includes(key) ? (
              <Box m={2} display="grid" gridRowGap={8} key={`${key}-inputgroup`}>
                <TextField
                  key={`${key}-input`}
                  variant="outlined"
                  placeholder={offerType.placeholder}
                  disabled={!this.state.canUpdateOffers}
                  onChange={e => this.calculateOffers(e.target.value, key)}
                  onFocus={e =>
                    e.target.addEventListener(
                      'wheel',
                      function(e) {
                        e.preventDefault();
                      },
                      { passive: false },
                    )
                  }
                  value={value ?? ''}
                  name={key}
                  type="number"
                  label={offerType.label}
                  error={
                    !!this.props.reactiveSettings.hideInAdmin[key as SubmissionOfferTypes] &&
                    this.enabledOfferTypes().length > 1
                  }
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end">
                        {offerType.toggleable && (
                          <Switch
                            size="small"
                            color="primary"
                            checked={isChecked}
                            disabled={!this.state.canUpdateOffers}
                            onChange={e => this.handleAvailableToSellerToggle(e, key)}
                          />
                        )}
                        {this.basisOfferType === key && (
                          <>
                            <Typography variant="body2">
                              {value && this.state.expectedValue
                                ? `${((1 - value / this.state.expectedValue) * 100).toFixed(2)}%`
                                : ''}
                            </Typography>
                          </>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                {offerType.consignmentProperties && (
                  <TextField
                    key={`${key}-consignorSplit-input`}
                    variant="outlined"
                    disabled={!this.state.canUpdateOffers}
                    placeholder={offerType.placeholder}
                    onChange={e => this.setConsignmentAmount(e, key)}
                    onFocus={e =>
                      e.target.addEventListener(
                        'wheel',
                        function(e) {
                          e.preventDefault();
                        },
                        { passive: false },
                      )
                    }
                    value={consignorSplitPct ?? 'N/A'}
                    name={`${key}-consignorSplit`}
                    type="number"
                    label="Consignor Split"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      endAdornment: !!consignorSplitPct && !!this.state.expectedValue && (
                        <InputAdornment position="end">
                          <Typography variant="body2">
                            ${((this.state.expectedValue * consignorSplitPct) / 100).toFixed(2)}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Box>
            ) : (
              <></>
            );
          })}
      </Box>
    );
  }

  // todo: disableSubmit calculation
  render() {
    const validStatuses = this.state.isPartnerOfferOnly
      ? this.props.statuses.filter(s => !['Evaluated', 'Extended'].includes(s.status))
      : this.props.statuses;

    return (
      <form
        style={{ width: '100%' }}
        onSubmit={async (e: React.FormEvent, claiming = false) => {
          e.preventDefault();
          const {
            buyerId,
            declineReasonId,
            statusId,
            preAmendmentReason,
            brakeType,
            categoryStatus,
            expectedValue,
            externalNote,
            internalNote,
            isTradein,
            offerFields,
          } = this.state;

          const offers = this.convertOfferFieldsToOffers(offerFields);

          const blockedEmail = claiming || this.state.blockEmail;
          this.props.setLoading(true);
          try {
            const body = {
              buyer: buyerId,
              declineReasonId,
              note: externalNote,
              internalNote,
              statusId: blockedEmail ? null : statusId,
              preAmendmentReason,
              brakeType,
              categoryStatus,
              expectedValue,
              submissionId: this.props.submission.id,
              isTradein,
              offers,
            };
            // todo: remove "as number" with convenience values
            const res = await this.props.putSubmission(body);
            if (res && res.status < 300) {
              this.props.handleSuccess();
              this.props.triggerRefetch();
              this.props.closeModal();
            } else {
              this.props.handleError();
            }
          } catch (err) {
            this.props.handleError();
            console.error(err);
          } finally {
            this.props.setLoading(false);
          }
        }}
      >
        <Snackbar
          open={this.props.snackbarOpen}
          handleClose={this.props.handleSnackbarClose}
          message={this.state.errorMessage}
          severity={'error'}
        />
        <ResponsiveGrid display="grid">
          <BuyerSection>
            <Box>
              <BoldTypography variant="h6" gutterBottom>
                Buyer Info
              </BoldTypography>
            </Box>
            <Box display="flex" gridGap={16}>
              <FormControl variant="outlined" style={{ maxWidth: '50%' }}>
                <InputLabel id="buyer">Buyer</InputLabel>
                <Select
                  labelId="buyer"
                  onChange={e =>
                    this.setState({
                      buyerId: nullableParseInt(e.target.value as string),
                    })
                  }
                  name="buyer"
                  value={this.state.buyerId || ''}
                  label="Buyer"
                >
                  {this.props.buyers.map(v => (
                    <MenuItem key={v.id} value={v.id}>
                      {v.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" style={{ maxWidth: '50%' }}>
                <InputLabel id="status">Status</InputLabel>
                <Select
                  labelId="status"
                  onChange={e => {
                    const statusId = nullableParseInt(e.target.value as string);
                    if (!statusId) return false;

                    const missingInternalInfo = this.checkMissingInternalInfo(statusId);
                    if (missingInternalInfo) {
                      const type = this.props.submission.type;
                      const label = `Internal ${type.charAt(0).toUpperCase() +
                        type.substr(1)} Info`;
                      const matchedStatus = this.props.statuses.find(s => s.id === statusId);

                      notifyInternalInfoMissing(
                        `${label} must be set before transitioning to "${matchedStatus?.status}"`,
                      );

                      return false;
                    }

                    return this.setState({
                      statusId,
                      declineReasonId: null,
                      validDeclineReasons: this.getValidDeclineReasons(statusId),
                      canUpdateOffers:
                        this.validSubmissionStatusForOffer(statusId) && !!this.state.expectedValue,
                    });
                  }}
                  name="statusId"
                  value={this.state.statusId ?? ''}
                  label="Status"
                >
                  {validStatuses.map(v => (
                    <MenuItem key={v.id} value={v.id}>
                      <span
                        style={{
                          backgroundColor: styleStatus(v.status),
                          margin: '0 10px 0 0',
                          fontSize: '32px',
                          borderRadius: '50%',
                          width: 8,
                          height: 8,
                          display: 'inline-block',
                        }}
                      />
                      {v.status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {this.state.statusId == 6 && (
              <Row>
                <InlineWrap>
                  <label className="label">In House Trade-In:</label>
                  <input
                    name="isTradein"
                    type="checkbox"
                    checked={this.state.isTradein}
                    onChange={() => this.setState({ isTradein: !this.state.isTradein })}
                  />
                </InlineWrap>
              </Row>
            )}

            <Box display="flex" alignItems="center" gridGap={8}>
              <Typography variant="body2">Category Status: </Typography>
              <SubmissionChip
                color={styleCategoryStatus(this.state.categoryStatus)}
                label={this.state.categoryStatus}
              />
            </Box>
          </BuyerSection>

          <Box display="flex" flexDirection="column" gridGap={16} gridArea="offer">
            <Box>
              <BoldTypography variant="h6" gutterBottom>
                Offer Info
              </BoldTypography>
            </Box>

            <Box display="flex" flexDirection={'column'}>
              <Box display="flex" gridGap={16} alignItems="center">
                <Box maxWidth={200}>
                  <TextField
                    variant="outlined"
                    placeholder="Expected sale value"
                    onChange={e => {
                      const parsedValue = parseFloat(e.target.value) || null;
                      this.setState({
                        expectedValue: parsedValue,
                        canUpdateOffers:
                          !!this.state.statusId &&
                          this.validSubmissionStatusForOffer(this.state.statusId) &&
                          !!parsedValue,
                      });
                    }}
                    onBlur={() => {
                      const offerTooHigh =
                        Object.values(this.state.offerFields).filter(
                          offer => (offer.amountUsd ?? 0) > (this.state.expectedValue ?? 0),
                        ).length > 0;
                      if (offerTooHigh) {
                        window.confirm('Expected Sale Price cannot be lower than the offer value.');
                      }
                    }}
                    onFocus={e =>
                      e.target.addEventListener(
                        'wheel',
                        function(e) {
                          e.preventDefault();
                        },
                        { passive: false },
                      )
                    }
                    value={this.state.expectedValue ?? ''}
                    type="number"
                    name="expectedValue"
                    label="Expected Sale Price"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Box>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isCalcDisabled}
                        onChange={() =>
                          this.setState({ isCalcDisabled: !this.state.isCalcDisabled })
                        }
                      />
                    }
                    label={<Typography variant="body2">Disable Calculation</Typography>}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isAmendEnabled}
                        onChange={() => {
                          if (this.state.isAmendEnabled) {
                            this.setState({ isAmendEnabled: false, preAmendmentReason: null });
                          } else {
                            this.setState({ isAmendEnabled: true });
                          }
                        }}
                      />
                    }
                    label={<Typography variant="body2">Pre-Amendment</Typography>}
                  />
                </FormGroup>
              </Box>
              <Box display="flex" width="100%" gridGap={16}>
                {!this.state.isBrakeTypeDisabled && (
                  <FormControl variant="outlined" style={{ maxWidth: 200, marginTop: '16px' }}>
                    <InputLabel id="brakeType">Brake Type</InputLabel>

                    <NativeSelect
                      id="brakeType"
                      disabled={this.state.isBrakeTypeDisabled}
                      onChange={e => {
                        this.setState({ brakeType: e.currentTarget.value as BrakeType });
                      }}
                      value={this.state.brakeType ?? 'n/a'}
                      input={<OutlinedInput label="Brake Type" />}
                    >
                      <option value="n/a">N/A</option>
                      <option value="rim">Rim</option>
                      <option value="disc">Disc</option>
                    </NativeSelect>
                  </FormControl>
                )}

                <Collapse in={this.state.isAmendEnabled} style={{ width: '100%' }}>
                  <Box maxWidth="400px" width="100%" mt={2}>
                    <FormControl variant="outlined">
                      <InputLabel id="demo-simple-select-outlined-label">
                        Pre-Amendment Reason
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.preAmendmentReason ?? ''}
                        onChange={event => {
                          this.setState({ preAmendmentReason: event.target.value as string });
                        }}
                        label="Pre-Amendment Reason"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={'Front wheel'}>Front wheel</MenuItem>
                        <MenuItem value={'Rear wheel'}>Rear wheel</MenuItem>
                        <MenuItem value={'Wheelset'}>Wheelset</MenuItem>
                        <MenuItem value={'Carbon frame'}>Carbon Frame</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Collapse>
              </Box>
            </Box>

            {this.getOfferFields()}
            {this.acceptedOffer && (
              <Box display="flex" alignItems="center" gridGap={8}>
                <Typography variant="body2">Selected Offer: </Typography>
                <BoldChip
                  style={{
                    textTransform: 'uppercase',
                  }}
                  size="small"
                  label={this.acceptedOffer.offerTypeName}
                />
              </Box>
            )}
          </Box>

          <CommunicationSection>
            <Box
              display="flex"
              gridGap={16}
              flexDirection="column"
              // visually hide white input backgrounds on gray background
              style={{ mixBlendMode: 'multiply' }}
            >
              <Box>
                <BoldTypography variant="h6" gutterBottom>
                  Communication
                </BoldTypography>
              </Box>
              <FormControl variant="outlined">
                <InputLabel id="declineReason">Canned Responses</InputLabel>
                <Select
                  labelId="declineReason"
                  name="declineReason"
                  value={this.state.declineReasonId ?? ''}
                  required={
                    !!this.state.statusId && DECLINE_REASON_BLACKLIST.includes(this.state.statusId)
                  }
                  onChange={e => {
                    const selectedValue = nullableParseInt(e.target.value as string);
                    const declineReason = this.props.declineReasons.find(
                      v => v.id == selectedValue,
                    );
                    if (declineReason?.id) {
                      this.setState({
                        declineReasonId: declineReason.id,
                        externalNote: declineReason.reason,
                      });
                    }
                  }}
                  label="Canned Responses"
                  inputProps={{
                    style: {
                      backgroundColor: 'transparent !important',
                    },
                  }}
                >
                  {this.state.validDeclineReasons.map(r => (
                    <MenuItem key={r.id} value={r.id}>
                      {r.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                multiline
                onChange={e => this.setState({ externalNote: e.currentTarget.value })}
                value={this.state.externalNote}
                name="externalNote"
                placeholder="Select a Canned Response above to populate this field"
                minRows={10}
                label="External Note"
              />

              <TextField
                variant="outlined"
                multiline
                onChange={e => this.setState({ internalNote: e.currentTarget.value })}
                value={this.state.internalNote}
                name="internalNote"
                placeholder="Add an internal note"
                minRows={3}
                label="Internal Note"
              />

              <Row>
                <InlineWrap>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.blockEmail}
                        onChange={() => this.setState({ blockEmail: !this.state.blockEmail })}
                      />
                    }
                    label={<Typography variant="body2">Block Email </Typography>}
                  />
                </InlineWrap>
                <Typography variant="body2" color="textSecondary">
                  (Status will not be updated regardless of your changes)
                </Typography>
              </Row>
            </Box>
          </CommunicationSection>
        </ResponsiveGrid>
        <ModalFooter>
          <Button ordinality="secondary" variant="text" onClick={() => this.props.closeModal()}>
            Close
          </Button>
          <Button
            disabled={this.checkCanSubmit(
              this.state.statusId ?? undefined,
              this.state.blockEmail,
              this.state.offerFields,
            )}
            ordinality="primary"
            type="submit"
          >
            SUBMIT
          </Button>
        </ModalFooter>
      </form>
    );
  }
}

export default OfferUpdateForm;
