import React, { useState, useRef, useMemo, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FormikProps } from 'formik';

import { Breadcrumbs } from '../../../../components/library';
import { INSPECTION_SEARCH_ROUTE } from '../InspectionSearchView';
import { ServiceTicketHeader } from './ServiceTicketHeader';
import {
  PointsModule,
  PartsModule,
  ServiceModule,
  IntakeModule,
  RejectModule,
} from './ServiceTicketModules';
import { RelocateModal } from '../RelocateModal';

import { useInspection, useInspectionStatuses, InspectionModule } from '../_shared';
import { withPageState, pageStateContext } from '../../../_shared';
import { serviceTicketContext } from '../_shared/serviceTicketContext';
import {
  FormikPartsState,
  FormikPointsState,
  FormikServiceState,
  FormikIntakeState,
  FormikRejectState,
} from '../_shared/types';
import { InspectionStatusEnum } from '../../../../services/service/types';

import { PageWrapper } from './styled';
import { useInventoryRecords } from '../_shared/hooks/useInventoryRecords';
import { autoTransitionServicePlanStatus } from './ServiceTicketDetailView.utils';

import { Box } from '@material-ui/core';
import { ServicePlanFooter } from './ServiceTicketModules/IntakeModule/ServicePlanFooter';
import { RemoveRejectionModal } from './ServiceTicketModules/RejectModule/RemoveRejectionModal';
import axios from '../../../../utils/axios';
import { API_URL } from '../../../../constants';
import { cycleTimingsContext } from '../../cycleTimings/cycleTimingsContext';

export const INSPECTION_DETAIL_ROUTE = '/service/service-plans/:id';

const getModuleConfigFromInspectionStatus = (inspectionStatusId: number) => {
  if (
    // Intake Phase
    [InspectionStatusEnum['Ready for Intake'], InspectionStatusEnum['Intake Started']].includes(
      inspectionStatusId,
    )
  ) {
    return {
      renderedModules: [InspectionModule.INTAKE],
      activeModuleOnLoad: InspectionModule.INTAKE,
    };
  } else if (
    // Inspection Phase
    [
      InspectionStatusEnum['Ticket Reopened'],
      InspectionStatusEnum['Ready for Inspection'],
      InspectionStatusEnum['Inspection Started'],
    ].includes(inspectionStatusId)
  ) {
    return {
      renderedModules: [InspectionModule.POINTS],
      activeModuleOnLoad: InspectionModule.POINTS,
    };
  } else if (
    // Consumables Phase
    [
      InspectionStatusEnum['Ready for Parts'],
      // todo: implement this status
      // InspectionStatusEnum['Parts Started'],
    ].includes(inspectionStatusId)
  ) {
    return {
      renderedModules: [InspectionModule.PARTS, InspectionModule.POINTS],
      activeModuleOnLoad: InspectionModule.PARTS,
    };
  } else if (
    // Service Phase
    [
      InspectionStatusEnum['Ready for Line'],
      InspectionStatusEnum['Ready for Master Tech'],
      InspectionStatusEnum['Service Started'],
    ].includes(inspectionStatusId)
  ) {
    return {
      renderedModules: [InspectionModule.SERVICE, InspectionModule.PARTS, InspectionModule.POINTS],
      activeModuleOnLoad: InspectionModule.SERVICE,
    };
  } else if (
    // Out of Workflow
    [InspectionStatusEnum['Failed'], InspectionStatusEnum['Service Complete']].includes(
      inspectionStatusId,
    )
  ) {
    return {
      renderedModules: [InspectionModule.SERVICE, InspectionModule.PARTS, InspectionModule.POINTS],
      activeModuleOnLoad: undefined,
      readOnlyModules: true,
    };
  } else if (
    // Reject Phase
    [InspectionStatusEnum['Rejected']].includes(inspectionStatusId)
  ) {
    return {
      renderedModules: [InspectionModule.REJECT],
      activeModuleOnLoad: InspectionModule.REJECT,
    };
  } else {
    throw new Error(
      `Unable to determine modules to display for inspection status "${InspectionStatusEnum[inspectionStatusId]}" (ID ${inspectionStatusId}). Defaulting to all modules.`,
    );
  }
};

const ServiceTicketDetail: React.FC = () => {
  const { id }: { id: string } = useParams();

  const {
    errorBus,
    errorBus: { setError, clearAllErrors },
    loadingBus: { setLoading },
    showSuccess,
  } = useContext(pageStateContext);

  // inspection data
  const inspectionData = useInspection({
    inspectionId: id,
    setError: setError,
    setLoading: setLoading,
  });
  const { inspection, refreshInspectionData } = inspectionData;
  const { activeStatuses } = useInspectionStatuses({ errorBus });
  const { inventoryRecords, refetchInventoryRecords } = useInventoryRecords({
    sku: inspection?.sku,
    errorBus,
  });

  // todo: warn about loss of data when switching active modules
  const [activeModule, setActiveModule] = useState<InspectionModule | undefined>();
  const [shouldRelocateAfterSave, setShouldRelocateAfterSave] = useState<boolean>(false);
  const [shouldRejectAfterSave, setShouldRejectAfterSave] = useState<boolean>(false);
  const [relocateModalOpen, setRelocateModalOpen] = useState<boolean>(false);
  const [removeRejectionModalOpen, setRemoveRejectionModalOpen] = useState<boolean>(false);
  const [unsavedData, setUnsavedData] = useState<boolean>(false);
  const [autoStatusTransitionAttempted, setAutoStatusTransitionAttempted] = useState<boolean>(
    false,
  );
  const [isSerialNumberVerified, setIsSerialNumberVerified] = useState<boolean>(false);
  const [isBikeStolen, setIsBikeStolen] = useState<boolean>(false);

  const { renderedModules, activeModuleOnLoad, readOnlyModules = false } = useMemo(() => {
    if (!!inspection?.statusId) {
      try {
        return getModuleConfigFromInspectionStatus(inspection?.statusId);
      } catch (error) {
        setError('moduleConfig', error);
      }
    }
    return {
      renderedModules: [InspectionModule.SERVICE, InspectionModule.POINTS, InspectionModule.PARTS],
      activeModuleOnLoad: undefined,
      readOnlyModules: false,
    };
  }, [inspection, setError]);

  const { setCycleTimingSku } = useContext(cycleTimingsContext);

  useEffect(() => {
    if (inspection?.sku) {
      setCycleTimingSku(inspection?.sku || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspection]);

  // TP-3500 obviate this useEffect by only passing props to view from container after all data is fetched
  useEffect(() => {
    setActiveModule(activeModuleOnLoad);
  }, [activeModuleOnLoad]);

  useEffect(() => {
    // because we need to wait for the initial fetch to return *before* checking whether the status needs transitioning
    // we have to manually set and check a flag instead of using a first-render-only useEffect
    if (inspection && !autoStatusTransitionAttempted) {
      const transitionStatusIfNecessary = async () => {
        try {
          const wasTransitioned = await autoTransitionServicePlanStatus(
            inspection.id,
            inspection.statusId,
          );
          setAutoStatusTransitionAttempted(true);
          if (wasTransitioned) {
            refreshInspectionData();
          }
        } catch (err) {
          setError('autoTransitionServicePlanStatus', err);
        }
      };

      transitionStatusIfNecessary();
    }
  }, [inspection, autoStatusTransitionAttempted, setError, refreshInspectionData]);

  const pointsModuleRef = useRef<FormikProps<FormikPointsState>>(null);
  const partsModuleRef = useRef<FormikProps<FormikPartsState>>(null);
  const serviceModuleRef = useRef<FormikProps<FormikServiceState>>(null);
  const intakeModuleRef = useRef<FormikProps<FormikIntakeState>>(null);
  const rejectModuleRef = useRef<FormikProps<FormikRejectState>>(null);

  const handleReject = useCallback(async () => {
    const payload = {
      statusId: InspectionStatusEnum['Rejected'],
    };
    if (inspection) {
      try {
        setLoading('rejectSave', true);
        await axios.put(`${API_URL}/service/inspections/${inspection.id}`, payload);
        refreshInspectionData();
      } catch (err) {
        setError('handleRejectSave', err as Error);
      } finally {
        setLoading('rejectSave', false);
      }
    }
  }, [inspection, setError, setLoading, refreshInspectionData]);

  // callbacks and memos
  const handleSuccess = useCallback(() => {
    clearAllErrors();
    showSuccess();
    refreshInspectionData();
    refetchInventoryRecords();
    if (shouldRelocateAfterSave) {
      setRelocateModalOpen(true);
    }
    if (shouldRejectAfterSave) {
      handleReject();
    }
  }, [
    clearAllErrors,
    showSuccess,
    refreshInspectionData,
    handleReject,
    refetchInventoryRecords,
    shouldRejectAfterSave,
    shouldRelocateAfterSave,
  ]);

  if (!inspection) {
    return null;
  }

  return (
    <serviceTicketContext.Provider
      value={{
        inspectionData,
        inspectionStatuses: activeStatuses,
      }}
    >
      <PageWrapper>
        {/* header */}
        <Box paddingX="4rem" paddingTop="3rem">
          <Breadcrumbs
            previousPageName="Service Plans"
            previousPageHref={INSPECTION_SEARCH_ROUTE}
            currentPageName="Service Plan Detail"
          />
        </Box>
        <ServiceTicketHeader
          // todo: ErrorBoundaries - itemProcurement is non-nullable on the FE
          // but if the data ever were null it would bork unrelated parts
          inventoryRecords={inventoryRecords}
          unsavedData={unsavedData}
          isBikeStolen={isBikeStolen}
        />
        {/* Modules */}
        {renderedModules.map(module => {
          // Handle the case where a submission does not have an item or item procurement record
          if (module === InspectionModule.INTAKE && !inspection?.itemProcurement) {
            module = InspectionModule.POINTS;
          }

          switch (module) {
            case InspectionModule.INTAKE:
              return (
                <IntakeModule
                  key={module}
                  ref={intakeModuleRef}
                  isSerialNumberVerified={isSerialNumberVerified}
                  isBikeStolen={isBikeStolen}
                  setIsBikeStolen={setIsBikeStolen}
                  hasUnsavedData={unsavedData}
                  setIsSerialNumberVerified={setIsSerialNumberVerified}
                  handleSuccess={handleSuccess}
                  handleDataChange={setUnsavedData}
                />
              );
            case InspectionModule.POINTS:
              return (
                <PointsModule
                  key={module}
                  ref={pointsModuleRef}
                  editing={activeModule === InspectionModule.POINTS}
                  readOnly={readOnlyModules}
                  hasUnsavedData={unsavedData}
                  handleEditPoints={() => setActiveModule(InspectionModule.POINTS)}
                  handleSuccess={handleSuccess}
                  handleDataChange={setUnsavedData}
                />
              );
            case InspectionModule.PARTS:
              return (
                <PartsModule
                  key={module}
                  ref={partsModuleRef}
                  editing={activeModule === InspectionModule.PARTS}
                  readOnly={readOnlyModules}
                  handleEditParts={() => setActiveModule(InspectionModule.PARTS)}
                  handleSuccess={handleSuccess}
                  handleDataChange={setUnsavedData}
                  hasUnsavedData={unsavedData}
                />
              );
            case InspectionModule.SERVICE:
              return (
                <ServiceModule
                  key={module}
                  ref={serviceModuleRef}
                  editing={activeModule === InspectionModule.SERVICE}
                  readOnly={readOnlyModules}
                  handleEditService={() => setActiveModule(InspectionModule.SERVICE)}
                  handleSuccess={handleSuccess}
                  handleDataChange={setUnsavedData}
                  hasUnsavedData={unsavedData}
                />
              );
            case InspectionModule.REJECT:
              return (
                <RejectModule key={module} ref={rejectModuleRef} handleSuccess={handleSuccess} />
              );
          }
        })}
        {!readOnlyModules && (
          <ServicePlanFooter
            activeModule={activeModule}
            isBikeStolen={isBikeStolen}
            isSerialNumberVerified={isSerialNumberVerified}
            partsModuleRef={partsModuleRef}
            pointsModuleRef={pointsModuleRef}
            serviceModuleRef={serviceModuleRef}
            intakeModuleRef={intakeModuleRef}
            rejectModuleRef={rejectModuleRef}
            setRemoveRejectionModalOpen={setRemoveRejectionModalOpen}
            setShouldRelocateAfterSave={setShouldRelocateAfterSave}
            setShouldRejectAfterSave={setShouldRejectAfterSave}
          />
        )}
        {relocateModalOpen && (
          <RelocateModal
            open={relocateModalOpen}
            inventoryRecords={inventoryRecords}
            handleClose={() => {
              setRelocateModalOpen(false);
              setShouldRelocateAfterSave(false);
            }}
            handleSuccess={() => {
              refreshInspectionData();
              setRelocateModalOpen(false);
              setShouldRelocateAfterSave(false);
            }}
          />
        )}
        {removeRejectionModalOpen && (
          <RemoveRejectionModal
            inspectionData={inspectionData}
            removeRejectionModalOpen={removeRejectionModalOpen}
            setRemoveRejectionModalOpen={setRemoveRejectionModalOpen}
          />
        )}
      </PageWrapper>
    </serviceTicketContext.Provider>
  );
};

export const ServiceTicketDetailView = withPageState(ServiceTicketDetail, 'Service Ticket');
