import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useStaticPointsTableStyles, usePointStatusStyles, CategoryHeaderWrapper } from '../styled';
import { InspectionPoint } from '../../../../../../services/service/types';
import { FailRemedies, Grades } from '../../../_shared/types';
import { InspectionPointStatusIcon } from '../InspectionPointStatusIcon'; // todo: move into this dir?
import { ServiceTicketModuleEditButton } from '../ServiceTicketModuleEditButton';

interface StaticPointsProps {
  readOnly: boolean;
  inspectionPoints: InspectionPoint[];
  pointTypeLookup: { [key: number]: string };
  pointTypeCategoryLookup: { [key: number]: string };
  handleEditPoints?(): void;
}

interface FailedPoint extends InspectionPoint {
  gradeId: Grades.fail;
  failRemedyId: NonNullable<InspectionPoint['failRemedyId']>;
}

interface PassedPoint extends InspectionPoint {
  gradeId: Grades.pass;
  failRemedyId: NonNullable<InspectionPoint['failRemedyId']>;
}

export const StaticPoints: React.FC<StaticPointsProps> = ({
  readOnly,
  inspectionPoints,
  pointTypeLookup,
  pointTypeCategoryLookup,
  handleEditPoints,
}) => {
  const classes = useStaticPointsTableStyles();
  const statusCellClasses = usePointStatusStyles();
  const headerCellClass = `${classes.thCell} ${classes.leftAlignedCell}`;

  const pointsByRemedy = useMemo(() => {
    const failedPoints: FailedPoint[] = [];
    const passedPoints: PassedPoint[] = [];
    for (const point of inspectionPoints) {
      if (point.gradeId === Grades.fail) {
        failedPoints.push(point as FailedPoint);
      } else if (point.gradeId === Grades.pass) {
        passedPoints.push(point as PassedPoint);
      }
    }
    return { failedPoints, passedPoints };
  }, [inspectionPoints]);

  return (
    <>
      <TableContainer>
        <>
          <CategoryHeaderWrapper>
            <h3>Failed Points of Inspection</h3>
            {!readOnly && handleEditPoints && (
              <ServiceTicketModuleEditButton onClick={handleEditPoints} moduleName="Points" />
            )}
          </CategoryHeaderWrapper>
          <Table className={classes.table} aria-label="inspections-table">
            <TableHead className={classes.th}>
              <TableRow className={classes.failedRow}>
                <TableCell className={headerCellClass}>Status</TableCell>
                <TableCell className={headerCellClass}>Inspection Category</TableCell>
                <TableCell className={headerCellClass}>Inspection Point</TableCell>
                <TableCell className={headerCellClass}>Remedy</TableCell>
                <TableCell className={headerCellClass}>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pointsByRemedy.failedPoints.map(failedPoint => {
                const pointTypeLabel =
                  pointTypeLookup[failedPoint.inspectionPointTypeId] ?? 'shrug';

                const statusCellClass = failedPoint.gradeId
                  ? statusCellClasses[Grades[failedPoint.gradeId] as keyof typeof statusCellClasses]
                  : '';

                return (
                  <TableRow key={failedPoint.inspectionPointTypeId} className={classes.failedRow}>
                    {/* Status */}
                    <TableCell className={`${classes.centeredCell} ${statusCellClass}`}>
                      <InspectionPointStatusIcon gradeId={failedPoint.gradeId} />
                    </TableCell>

                    {/* Inspection Category */}
                    <TableCell scope="row" className={classes.leftAlignedCell}>
                      {pointTypeCategoryLookup[failedPoint.inspectionPointTypeId]}
                    </TableCell>

                    {/* Inspection Point */}
                    <TableCell scope="row" className={classes.leftAlignedCell}>
                      {pointTypeLabel}
                    </TableCell>

                    {/* Remedy */}
                    <TableCell scope="row" className={classes.leftAlignedCell}>
                      {FailRemedies[failedPoint.failRemedyId]}
                    </TableCell>

                    {/* Notes */}
                    <TableCell scope="row" className={classes.leftAlignedCell}>
                      {failedPoint.note}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
        <>
          <CategoryHeaderWrapper>
            <h3>Passed Points of Inspection</h3>
          </CategoryHeaderWrapper>
          <Table className={classes.table} aria-label="inspections-table">
            <TableHead className={classes.th}>
              <TableRow className={classes.passedRow}>
                <TableCell className={headerCellClass}>Status</TableCell>
                <TableCell className={headerCellClass}>Inspection Category</TableCell>
                <TableCell className={headerCellClass}>Inspection Point</TableCell>
                <TableCell className={headerCellClass}>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pointsByRemedy.passedPoints.map(passedPoint => {
                const pointTypeLabel =
                  pointTypeLookup[passedPoint.inspectionPointTypeId] ?? 'shrug';

                const statusCellClass = passedPoint.gradeId
                  ? statusCellClasses[Grades[passedPoint.gradeId] as keyof typeof statusCellClasses]
                  : '';

                return (
                  <TableRow key={passedPoint.inspectionPointTypeId} className={classes.passedRow}>
                    {/* Status */}
                    <TableCell className={`${classes.centeredCell} ${statusCellClass}`}>
                      <InspectionPointStatusIcon gradeId={passedPoint.gradeId} />
                    </TableCell>

                    {/* Inspection Category */}
                    <TableCell scope="row">
                      {pointTypeCategoryLookup[passedPoint.inspectionPointTypeId]}
                    </TableCell>

                    {/* Inspection Point */}
                    <TableCell scope="row">{pointTypeLabel}</TableCell>

                    {/* Notes */}
                    <TableCell scope="row">{passedPoint.note}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      </TableContainer>
    </>
  );
};
