import React, { useContext, useCallback, useMemo } from 'react';
import { Formik, FormikProps } from 'formik';
import { EditableServiceTable } from './EditableServiceTable';
import { saveServiceData } from '../service';
import { serviceTicketContext, Grades } from '../../../_shared';
import { FormikServiceState } from '../../../_shared/types';
import { CategoryHeaderWrapper } from '../styled';
import { ServiceTicketModuleEditButton } from '../ServiceTicketModuleEditButton';
import { StaticServiceTable } from './StaticServiceTable';
import { NotesTextarea } from '../NotesTextarea';

import { InspectionPart } from '../../../../../../services/service/types';
import { pageStateContext } from '../../../../../_shared';

import { ModuleWrapper } from '../../styled';

interface ServiceModuleProps {
  editing: boolean;
  readOnly: boolean;
  hasUnsavedData: boolean;
  handleEditService(): void;
  handleSuccess(): void;
  handleDataChange(dirty: boolean): void;
}

export const ServiceModule = React.forwardRef<FormikProps<FormikServiceState>, ServiceModuleProps>(
  (
    { editing, readOnly, handleEditService, handleSuccess, handleDataChange, hasUnsavedData },
    ref,
  ) => {
    const {
      errorBus: { setError },
      loadingBus: { setLoading },
    } = useContext(pageStateContext);
    const { inspectionData } = useContext(serviceTicketContext);
    const {
      inspection,
      pointTypeLookup,
      categorizedPoints,
      failedPointOptions,
      pointTypeCategoryLookup,
    } = inspectionData;

    const failedPoints = useMemo(() => {
      if (!categorizedPoints) return [];
      return categorizedPoints.flatMap(category => {
        return category.points.filter(point => point.gradeId === Grades.fail);
      });
    }, [categorizedPoints]);

    const partsByPointId = useMemo(() => {
      if (!inspection) return {};
      const pointIndexedParts: { [key: number]: InspectionPart[] } = {};
      for (const part of inspection.inspectionParts) {
        if (pointIndexedParts[part.inspectionPointTypeId]) {
          pointIndexedParts[part.inspectionPointTypeId].push(part);
        } else {
          pointIndexedParts[part.inspectionPointTypeId] = [part];
        }
      }
      return pointIndexedParts;
    }, [inspection]);

    const initialServiceValues: FormikServiceState = useMemo(() => {
      return {
        mechanicNotes: inspection?.mechanicNotes || null,
        points: !!failedPoints
          ? Object.fromEntries(
              failedPoints.map(point => [point.inspectionPointTypeId, point.complete]),
            )
          : {},
        deletedPartIds: [],
      };
    }, [failedPoints, inspection]);

    const handleServiceSave = useCallback(
      async (values: FormikServiceState) => {
        if (inspection) {
          saveServiceData(
            {
              values,
              parts: inspection.inspectionParts,
              inspectionId: inspection.id,
              statusId: inspection.statusId,
              hasUnsavedData,
            },
            {
              setLoading: (loading: boolean) => setLoading('handleServiceSave', loading),
              setError: (error: Error | undefined) => setError('handleServiceSave', error),
              handleSuccess,
            },
          );
        }
      },
      [inspection, setLoading, setError, handleSuccess, hasUnsavedData],
    );

    if (
      !inspection ||
      !pointTypeLookup ||
      !categorizedPoints ||
      !failedPointOptions ||
      !pointTypeCategoryLookup
    ) {
      return null;
    }

    if (readOnly) {
      return (
        <>
          <ModuleWrapper>
            <CategoryHeaderWrapper>
              <h3>Failed Inspection Service</h3>
            </CategoryHeaderWrapper>
            <StaticServiceTable
              failedPoints={failedPoints}
              failedPointOptions={failedPointOptions}
              pointTypeCategoryLookup={pointTypeCategoryLookup}
              partsByPointId={partsByPointId}
            />
          </ModuleWrapper>
        </>
      );
    }

    const categoryHeaderWrapper = (
      <CategoryHeaderWrapper>
        <h3>Failed Inspection Service</h3>
        {!editing && (
          <ServiceTicketModuleEditButton onClick={handleEditService} moduleName="Service" />
        )}
      </CategoryHeaderWrapper>
    );

    return (
      <Formik<FormikServiceState>
        innerRef={ref}
        initialValues={initialServiceValues}
        enableReinitialize
        onSubmit={handleServiceSave}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            {editing ? (
              <>
                <ModuleWrapper>
                  <NotesTextarea
                    value={values['mechanicNotes']}
                    setValue={e => setFieldValue('mechanicNotes', e.target.value)}
                  />
                </ModuleWrapper>
                <ModuleWrapper>
                  {categoryHeaderWrapper}
                  <EditableServiceTable
                    failedPoints={failedPoints}
                    failedPointOptions={failedPointOptions}
                    pointTypeCategoryLookup={pointTypeCategoryLookup}
                    partsByPointId={partsByPointId}
                    handleDataChange={handleDataChange}
                    statusId={inspection.statusId}
                  />
                </ModuleWrapper>
              </>
            ) : (
              <ModuleWrapper>
                {categoryHeaderWrapper}
                <StaticServiceTable
                  failedPoints={failedPoints}
                  failedPointOptions={failedPointOptions}
                  pointTypeCategoryLookup={pointTypeCategoryLookup}
                  partsByPointId={partsByPointId}
                />
              </ModuleWrapper>
            )}
          </form>
        )}
      </Formik>
    );
  },
);
