import { MultiSelectOption } from '../../../../components/library/MultiSelect';
import { InspectionPoint, InspectionPart } from '../../../../services/service/types';
import { ServicePlanRejectionReasonCheckbox } from '../../serviceTicket/ServiceTicketDetailView/ServiceTicketModules/RejectModule/types';

export enum Grades {
  pass = 1,
  fail = 2,
  na = 3,
}

export enum FailRemedies {
  service = 1,
  replace = 2,
  na = 3,
}

export enum PartSources {
  stock = 1,
  bike = 2,
  order = 3,
  'non-inventory' = 4,
}

export enum PartStatuses {
  'with bike' = 1,
  installed = 2,
  ordered = 3,
  received = 4,
  canceled = 5,
}

export enum PartIdentifierTypes {
  UPC = 1,
  SKU = 2,
  manual = 3,
}

export enum InspectionModule {
  POINTS = 'points',
  PARTS = 'parts',
  SERVICE = 'service',
  REJECT = 'reject',
  INTAKE = 'intake',
}

// Formik intake types
export interface FormikIntakeState {
  inspectionSerialNumber: string | null;
  intakeNotes: string | null;
}
export type FormikIntakeErrorKeys = any;

// Formik reject types
export interface FormikRejectState {
  rejectionReasons: ServicePlanRejectionReasonCheckbox[];
  rejectionNotes: string | null;
}

// Formik inspection point types
export interface FormikPointsState {
  points: {
    [key: string]: InspectionPoint;
  };
  notes: string | null;
}
export type FormikPointsErrorKeys = keyof Partial<
  Pick<InspectionPoint, 'gradeId' | 'failRemedyId' | 'note'>
>;

// Formik inspection part types
export interface FormikPartsState {
  [key: string]: InspectionPart;
}
export type FormikPartsErrorKeys = keyof Partial<
  Pick<
    InspectionPart,
    | 'inspectionPointTypeId'
    | 'sourceId'
    | 'sourceBikeSku'
    | 'sourceOrderNumber'
    | 'sourceOrderDescription'
    | 'sourceOrderCost'
    | 'identifier'
    | 'identifierTypeId'
    | 'quantity'
    | 'statusId'
  >
>;
export type MandatoryFormPartFields = keyof Partial<
  Pick<
    InspectionPart,
    | 'inspectionPointTypeId'
    | 'sourceId'
    | 'identifierTypeId'
    | 'identifier'
    | 'quantity'
    | 'statusId'
  >
>;
export const mandatoryFormPartFields = [
  'inspectionPointTypeId',
  'sourceId',
  'identifierTypeId',
  'identifier',
  'quantity',
  'statusId',
] as MandatoryFormPartFields[];

// Formik service/mechanic types
export interface FormikServiceState {
  mechanicNotes: string | null;
  points: {
    [key: number]: boolean;
  };
  deletedPartIds: number[];
}

// inspection status select option
export interface InspectionStatusOption extends MultiSelectOption {
  active: boolean;
}
