import React, { Component } from 'react';
import PropTypes from 'prop-types';
import spinner from '../../images/Rolling-1s-200px.gif';
import '../../styles/modal.scss';

class Modal extends Component {
  render() {
    const { submitError, submitted, onClose, message, buildPdf } = this.props;
    return (
      <div className="backdrop">
        <div className="message">
          {!submitError ? (
            <div>
              <p className="modal-title">
                {!submitted ? 'Uploading' : message || 'Form submitted!'}
              </p>
              {submitted ? (
                <div className="check-wrap">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="96"
                    height="96"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-check-circle"
                  >
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                    <polyline points="22 4 12 14.01 9 11.01" />
                  </svg>
                  <button className="submit-button" onClick={onClose}>
                    OK!
                  </button>
                  {buildPdf && (
                    <button className="submit-button print-pdf" onClick={buildPdf}>
                      Print/View PDF
                    </button>
                  )}
                </div>
              ) : (
                <span>
                  <img alt="Loading..." src={spinner} />
                  <button className="submit-button" onClick={onClose}>
                    CLOSE
                  </button>
                </span>
              )}
            </div>
          ) : (
            <div className="check-wrap">
              <p className="modal-title">Uh oh! There was an error.</p>
              <p className="modal-title">{message}</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="96"
                height="96"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x-circle"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="15" y1="9" x2="9" y2="15" />
                <line x1="9" y1="9" x2="15" y2="15" />
              </svg>
              <button className="submit-button" onClick={onClose}>
                CLOSE
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
  submitError: PropTypes.bool.isRequired,
  message: PropTypes.node,
};

Modal.defaultProps = {
  message: '',
};

export default Modal;
