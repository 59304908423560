import React, { useEffect, useState, useCallback } from 'react';
import { LoaderOverlay, Snackbar, useSnackbar } from '../../components/library';
import { Alert, AlertTitle } from '@material-ui/lab';
import { pageStateContext } from './pageStateContext';

import { useErrorBus } from './useErrorBus';
import { useLoadingBus } from './useLoadingBus';

interface PageStateContainerProps {
  contextName: string;
  children: React.ReactNode;
}

export const PageStateContainer: React.FC<PageStateContainerProps> = ({
  contextName,
  children,
}) => {
  // buses
  const errorBus = useErrorBus(contextName);
  const loadingBus = useLoadingBus();
  const { hasErrors, errors } = errorBus;
  const { loading } = loadingBus;

  const {
    snackbarOpen: errorSnackbarOpen,
    handleSnackbarOpen: handleErrorSnackbarOpen,
    handleSnackbarClose: handleErrorSnackbarClose,
  } = useSnackbar();
  const {
    snackbarOpen: successSnackbarOpen,
    handleSnackbarOpen: handleSuccessSnackbarOpen,
    handleSnackbarClose: handleSuccessSnackbarClose,
  } = useSnackbar();

  const [successMessage, setSuccessMessage] = useState<string | undefined>();

  const onSuccessClose = useCallback(() => {
    setSuccessMessage(undefined);
    handleSuccessSnackbarClose();
  }, [setSuccessMessage, handleSuccessSnackbarClose]);

  useEffect(() => {
    if (hasErrors) handleErrorSnackbarOpen();
  }, [hasErrors, handleErrorSnackbarOpen]);

  return (
    <pageStateContext.Provider
      value={{
        errorBus,
        loadingBus,
        showSuccess: handleSuccessSnackbarOpen,
        setSuccessMessage,
      }}
    >
      <LoaderOverlay loading={loading} />
      {/* content */}
      {children}

      {/* errors */}
      {hasErrors && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={errorSnackbarOpen}
          handleClose={handleErrorSnackbarClose}
          severity="error"
          autoHideDuration={null}
        >
          <Alert onClose={handleErrorSnackbarClose} severity="error">
            <AlertTitle>Error</AlertTitle>
            {Object.values(errors).map((error, idx) => (
              <div key={`error-${idx}-${error.message}`}>{error.message}</div>
            ))}
          </Alert>
        </Snackbar>
      )}

      {/* success */}
      <Snackbar open={successSnackbarOpen} handleClose={onSuccessClose} message={successMessage} />
    </pageStateContext.Provider>
  );
};

export const withPageState = (Component: React.FC, contextName: string) => ({ ...props }) => (
  <PageStateContainer contextName={contextName}>
    <Component {...props} />
  </PageStateContainer>
);
