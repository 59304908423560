import {
  SET_CATALOG_PRODUCT_BRANDS,
  SET_CATALOG_PRODUCT_CATEGORIES,
  SET_CATALOG_PRODUCT_DISCIPLINES,
  SET_CATALOG_PRODUCT_PIPELINES,
  SET_CATALOG_PRODUCT_STATUS_FLAGS,
  SET_CATALOG_PRODUCT_SIZE_CLASSES,
  SET_CATALOG_PRODUCT_SUB_CATEGORIES,
  SET_CATALOG_FRAME_DIMENSIONS,
  SET_CATALOG_CONFIGURATION,
  SET_CATALOG_AUTOSUGGEST_FIELDS,
  SET_CATALOG_ATTRIBUTE_TYPES,
  SET_INITIAL_ITEM_CATALOG_DATA,
} from '../actions/types';
import { CatalogFieldConfig } from '../pages/catalog/utils/formik/types';
import {
  Brand,
  ProductCategory,
  Pipeline,
  StatusFlag,
  Discipline,
  SizeClass,
  ProductSubCategory,
  FrameDimension,
  AttributeType,
} from '../services/catalog/types';
interface ProductAction {
  type: string;
  payload: any;
}

export type ProductSubCategoriesObj = { [productCategoryId: number]: ProductSubCategory[] };
export interface AutosuggestFieldsObj {
  componentSpecificationFieldMap: { [key: string]: ComponentSpecificationFieldMap };
}

export interface ComponentSpecificationFieldMap {
  values: ComponentSpecificationFieldMapValue[];
}

export interface ComponentSpecificationFieldMapValue {
  value: string;
}

//note: in practice, values for this come from the DB and have a lot more potential keys than are specified here;
//the consuming code just uses Object.entries which converts them to strings. hence the class cast.
//this will be removed when all the options are data driven.
export interface ProductVariantFieldMap {
  gender: ComponentSpecificationFieldMap;
  brakeType: ComponentSpecificationFieldMap;
  frameMaterial: ComponentSpecificationFieldMap;
  drivetrainConfiguration: ComponentSpecificationFieldMap;
}

export type CatalogConfiguration = {
  conditionDescriptionTemplate?: string;
};

export interface CatalogProductState {
  categories: ProductCategory[];
  pipelines: Pipeline[];
  statusFlags: StatusFlag[];
  disciplines: Discipline[];
  brands: Brand[];
  sizeClasses: SizeClass[];
  productSubCategories: ProductSubCategoriesObj;
  frameDimensions: FrameDimension[];
  catalogConfiguration: CatalogConfiguration;
  autosuggestFields: AutosuggestFieldsObj | null;
  attributeTypes: AttributeType[];
  catalogFieldConfigs: CatalogFieldConfig[];
}

const initialState: CatalogProductState = {
  categories: [],
  pipelines: [],
  statusFlags: [],
  disciplines: [],
  brands: [],
  sizeClasses: [],
  productSubCategories: {},
  frameDimensions: [],
  catalogConfiguration: {},
  autosuggestFields: null,
  attributeTypes: [],
  catalogFieldConfigs: [],
};

const transformSubCategoryPayload = (payload: ProductSubCategory[]): ProductSubCategoriesObj => {
  return payload.reduce((initial, subCategory) => {
    const productCategoryId = subCategory.productCategoryId;
    return {
      ...initial,
      [productCategoryId]: [...(initial[productCategoryId] || []), subCategory],
    };
  }, {} as ProductSubCategoriesObj);
};

export default (state = initialState, action: ProductAction): CatalogProductState => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_ITEM_CATALOG_DATA: {
      return {
        ...state,
        brands: payload.brands ?? state.brands,
        categories: payload.categories ?? state.categories,
        disciplines: payload.disciplines ?? state.disciplines,
        pipelines: payload.pipelines ?? state.pipelines,
        statusFlags: payload.statusFlags ?? state.statusFlags,
        sizeClasses: payload.sizeClasses ?? state.sizeClasses,
        productSubCategories: payload.productSubCategories
          ? transformSubCategoryPayload(payload.productSubCategories)
          : state.productSubCategories,
        frameDimensions: payload.frameDimensions ?? state.frameDimensions,
        catalogConfiguration: payload.catalogConfiguration ?? state.catalogConfiguration,
        attributeTypes: payload.attributeTypes ?? state.attributeTypes,
        catalogFieldConfigs: payload.catalogFieldConfigs ?? state.catalogFieldConfigs,
      };
    }
    case SET_CATALOG_PRODUCT_CATEGORIES: {
      return {
        ...state,
        categories: payload,
      };
    }
    case SET_CATALOG_PRODUCT_DISCIPLINES: {
      return {
        ...state,
        disciplines: payload,
      };
    }
    case SET_CATALOG_AUTOSUGGEST_FIELDS: {
      return {
        ...state,
        autosuggestFields: payload,
      };
    }
    case SET_CATALOG_PRODUCT_PIPELINES: {
      return {
        ...state,
        pipelines: payload,
      };
    }
    case SET_CATALOG_PRODUCT_STATUS_FLAGS: {
      return {
        ...state,
        statusFlags: payload,
      };
    }
    case SET_CATALOG_PRODUCT_BRANDS: {
      return {
        ...state,
        brands: payload,
      };
    }
    case SET_CATALOG_PRODUCT_SIZE_CLASSES: {
      return {
        ...state,
        sizeClasses: payload,
      };
    }
    case SET_CATALOG_PRODUCT_SUB_CATEGORIES: {
      return {
        ...state,
        productSubCategories: transformSubCategoryPayload(payload as ProductSubCategory[]),
      };
    }
    case SET_CATALOG_FRAME_DIMENSIONS: {
      return {
        ...state,
        frameDimensions: payload,
      };
    }
    case SET_CATALOG_CONFIGURATION: {
      return {
        ...state,
        catalogConfiguration: payload,
      };
    }
    case SET_CATALOG_ATTRIBUTE_TYPES: {
      return {
        ...state,
        attributeTypes: payload,
      };
    }
    default:
      return state;
  }
};
