/* eslint-disable react/prop-types */
import React from 'react';
import { StripedDataGrid } from '../../../styledComponents/datagrid';
import { GridColDef } from '@mui/x-data-grid';
import { renderCellExpand } from '../../../components/library/DataGrid/renderCellExpand';

const columns: GridColDef[] = [
  {
    headerName: 'Item ID',
    field: 'itemId',
    flex: 2,
  },
  {
    headerName: 'Brand',
    field: 'brand',
    renderCell: renderCellExpand,
    flex: 2,
  },
  {
    headerName: 'Model',
    field: 'model',
    renderCell: renderCellExpand,
    flex: 2,
  },
  {
    headerName: 'Condition',
    field: 'condition',
    renderCell: renderCellExpand,
    flex: 2,
  },
  {
    headerName: 'Description',
    field: 'description',
    renderCell: renderCellExpand,
    flex: 6,
  },
  {
    headerName: 'Created',
    field: 'createdAt',
    flex: 3,
    renderCell: renderCellExpand,
    valueGetter: ({ value }: { value: string }) =>
      new Date(value).toLocaleString('en-US', { timeZone: 'America/Denver' }),
  },
];

const ItemHistoryTable = (props: any) => <StripedDataGrid rows={props.history} columns={columns} />;

export default ItemHistoryTable;
